// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .optionTag {
  padding: 6px 8px;
  font-size: 10px;
  font-weight: 600;
  background-color: #e1e8ed;
}
:host .ngbDropdown-disabled {
  opacity: 0.3;
  pointer-events: none;
}

::ng-deep .drop-down-select-option {
  max-width: 222px;
  max-height: 260px;
  overflow-y: auto;
}
::ng-deep .items-size {
  font-size: 12px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/additional-charges-drop-down-select/additional-charges-drop-down-select.component.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,yBAAA;AAAR;AAGI;EACI,YAAA;EACA,oBAAA;AADR;;AAKI;EACI,gBAAA;EACA,iBAAA;EACA,gBAAA;AAFR;AAII;EACI,0BAAA;AAFR","sourcesContent":[":host {\n    .optionTag {\n        padding: 6px 8px;\n        font-size: 10px;\n        font-weight: 600;\n        background-color: #e1e8ed;\n    }\n\n    .ngbDropdown-disabled { \n        opacity: .3;\n        pointer-events: none; \n    } \n}\n::ng-deep {\n    .drop-down-select-option {\n        max-width: 222px;\n        max-height: 260px;\n        overflow-y: auto;\n    }\n    .items-size{\n        font-size: 12px !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
