// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .table-border {
  border: 1px solid #e5e7eb;
  border-radius: 4px;
}
:host .save-btn {
  padding: 8px 60px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/drivers/driver-details/driver-driving-experience/driver-driving-experience.component.scss"],"names":[],"mappings":"AAEI;EACI,yBAAA;EACA,kBAAA;AADR;AAGI;EACI,iBAAA;AADR","sourcesContent":["@use \"common/var\" as *;\n:host {\n    .table-border {\n        border: 1px solid $divider;\n        border-radius: 4px;\n    }\n    .save-btn {\n        padding: 8px 60px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
