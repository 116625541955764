import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@app/@shared/datatable';
import { HelperService } from '@app/shared/services/helper.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '@app/shared/services/app.service';
import { debounceTime } from 'rxjs/operators';
import { OutsourceCompanyService } from '@app/shared/services/outsource-company.service';
import { OutsourceCompany } from '@app/models/outsource-company.model';
import { Clipboard } from '@angular/cdk/clipboard';
import { ListTabsComponent } from '@app/@shared/list-tabs/list-tabs.component';
import { flatMap, map, omitBy } from 'lodash';
import { statesFilterOptions } from '@app/data/state';
import { AddressService } from '@app/shared/services/address.service';

@UntilDestroy()
@Component({
  selector: 'app-outsource-company-list',
  templateUrl: './outsource-company-list.component.html',
  styleUrls: ['./outsource-company-list.component.scss'],
  host: {
    '[class.page-container]': "true"
  }
})

export class OutsourceCompanyListComponent implements OnInit, OnDestroy {

  @ViewChild('datatable') datatable: DatatableComponent;

  @Input() listTabs: ListTabsComponent;

  search = {
    SearchTerm: "",
    Page: 1,
    ItemsPerPage: 20,
    SortBy: "DEFAULT",
    SortDirection: "Descending",
    States: [],
    Countries: [],
    Cities: [],
    Zip: []
  }

  stateOptions = statesFilterOptions
  zipCode: number;
  city: string;

  selectedOutsourceCompanyIds: any;
  searchTerm: string;
  lastCopiedText: string;
  countryOptions: any;

  constructor(
    private outsourceCompanyService: OutsourceCompanyService,
    private helperService: HelperService,
    private appService: AppService,
    public activatedRoute: ActivatedRoute,
    public addressService: AddressService,
    public clipboard: Clipboard,
    public router: Router,
  ) { }

  getData = (request) => {
    const filters = omitBy(this.search, (value) => value === null || value === 'null')
    request = Object.assign(
      filters,
      request
    );
    this.outsourceCompanyService.cancelPendingRequestGetAll();
    return this.outsourceCompanyService.getAllWithCancelRequest(request);
  }

  ngOnInit() {
    this.addressService.getCountries().then((countries: any) => {
      this.countryOptions = countries?.map(state => {
        return {
          name: state.name,
          value: state.countryId, // or any other value you want to assign
          selected: false // or any other boolean value based on your logic
        };
      });
    })

    this.appService.$searchTerm
      .pipe(
        untilDestroyed(this),
        debounceTime(500)
      )
      .subscribe((text) => {
        this.searchTerm = text;
        this.search.SearchTerm = text;
        this.datatable.refresh(true);
      })

    if (this.activatedRoute?.snapshot?.queryParams?.id) {
      this.listTabs.navigateOpenTab(this.activatedRoute.snapshot.queryParams.id, '/app/outsource');
    }
  }

  ngOnDestroy() {
    this.appService.searchTerm = ''
  }

  reloadDatatable() {
    if (this.zipCode) {
      this.search.Zip = [this.zipCode]
    } else {
      delete this.search.Zip
    }
    if (this.city) {
      this.search.Cities = [this.city]
    } else {
      delete this.search.Cities
    }
    this.datatable.refresh(true);
  }

  stateSelectionUpdated(values) {
    this.search.States = flatMap(values?.map(r => r.value));
    this.datatable.refresh(true);
  }

  countrySelectionUpdated(values) {
    this.search.Countries = flatMap(values?.map(r => r.name));
    this.datatable.refresh(true);
  }

  handleRowClick(row) {
    this.listTabs.openTab(row.id);
  }

  handleRowRightClick(row) {
    const url = `/app/outsource/edit/${row.id}`;
    window.open(url, '_blank');
  }

  setSelectOutsourceCompanies(event) {
    this.selectedOutsourceCompanyIds = event
  }

  deleteOutsource(row: OutsourceCompany) {
    this.outsourceCompanyService.bulkDelete(row?.id).then(() => {
      this.listTabs.closeTab(`${row?.id}`);
      this.datatable.refresh();
      this.helperService.successMessage("Outsource successfully deleted")
    })
  }

  /**
   * Delete Multiple Outsources
   */
  deleteOutsources() {
    const ids = this.datatable.selection.selected;

    ids?.map((id) => {
      this.listTabs.closeTab(`${id}`);
    })

    this.outsourceCompanyService.bulkDelete(ids).then(() => {
      this.datatable.refresh();
      this.helperService.successMessage("Outsource successfully deleted")
    });
  }

  copyTextClass = (type) => {
    let classes
    if (type == 'body') {
      classes = 'p-0'
    }
    return classes
  }

  copyToClipboard(event, text: string) {
    event.preventDefault();
    event.stopPropagation();
    this.clipboard.copy(text)
    this.lastCopiedText = text;
  }

}