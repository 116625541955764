import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SavedSearch } from '@app/models/saved-search.model';
import { CrudService } from './core/crud.service';
import { OrderListFilters } from '@app/models/order.model';
import moment from "moment"
import { CustomerService } from './customer.service';
import { pick } from 'lodash';
import { firstValueFrom } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class SavedSearchService extends CrudService<SavedSearch>{

    protected basePath = 'SavedSearch'

    constructor(
        protected http: HttpClient,
        protected customerService: CustomerService,
    ) {
        super(http)
    }

    /**
     * Map response data for saved search filters
     * 
     * @param response 
     * @returns 
     */
    mapResponse(response: SavedSearch) {
        let data: any = {};
        try {
            data = JSON.parse(response.data)
        } catch (error) {
            console.log('Error while JSON parsing saved search filter data', error);
        }
        if (data.DateRange?.length == 2) {
            data.Date = {
                start: moment(data.DateRange[0], 'ddd MMM DD YYYY').toDate(),
                end: moment(data.DateRange[1], 'ddd MMM DD YYYY').toDate()
            }
            delete data.DateRange
        }
        if (typeof data.Date == 'string') {
            data.Date = moment(data.Date, 'ddd MMM DD YYYY').toDate()
        }
        // Customer filter map response
        if (data.Customer) {
            data.Customers = Array.isArray(data.Customer) ? data.Customer : [data.Customer];
        }
        // Driver filter map response
        if (data.Driver) {
            data.Drivers = Array.isArray(data.Driver) ? data.Driver : [data.Driver];
        }
        response.data = data;
        return response;
    }

    /**
     * Map request data for saved search filters
     * 
     * @param request 
     * @returns 
     */
    mapRequest(request: any): SavedSearch {
        const payload = request.data as OrderListFilters
        const data: any = pick(payload, [
            'SearchTerm',
            'OrderStatus',
            'TripStatus',
            'Date',
            'DateRange',
            'orderType',
            'orderPortStatus',
            // New fields for filters
            'Customers',
            'Drivers',
            'FromAddress',
            'ToAddress',
            'attention',
            'paymentStatus',
            'Sources'
        ]);
        if (payload.Date?.start && payload.Date?.end) {
            data.DateRange = [moment(payload.Date.start).format("ddd MMM DD YYYY"), moment(payload.Date.end).format("ddd MMM DD YYYY")]
        }
        if (typeof payload.Date == 'string' || payload.Date instanceof Date) {
            data.Date = moment(payload.Date).format("ddd MMM DD YYYY")
        }
        request.data = JSON.stringify(data)
        return request;
    }

    /**
     * Delete saved filters
     * 
     * @param id 
     * @returns 
     */
    delete(id: string | number): Promise<Object> {
        return firstValueFrom(this.http.delete(`${this.basePath}`, { params : { id } }))
    }
}
