import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@app/@shared/datatable';

@Component({
  standalone: false,
  selector: 'app-driver-payment',
  templateUrl: './driver-payment.component.html',
  styleUrls: ['./driver-payment.component.scss']
})
export class DriverPaymentComponent implements OnInit {

  title = 'Payment'
  @ViewChild('datatable') datatable: DatatableComponent
  constructor() { }

  ngOnInit(): void {
  }

  getData = (request?: any) => {
    return null
  }

}
