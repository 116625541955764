import {  Component, Input, OnInit } from '@angular/core';
import { OrderItemStop } from '@app/models/order-item-stop.model';
import { OrderStop, OrderStopTypeEnum } from '@app/models/order-stop.model';
import { Order } from '@app/models/order.model';
import { HelperService } from '@app/shared/services/helper.service';
import { OrderService } from '@app/shared/services/order.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { chain, cloneDeep, find, findIndex, map, omit } from 'lodash';
import { EditDeliveryDialogComponent } from '../../edit-delivery-dialog/edit-delivery-dialog.component';
import { AddLocationComponent } from '../../../add-edit-order/add-location/add-location.component';

@Component({
  standalone: false,
  selector: 'app-edit-delivery-item-dialog',
  templateUrl: './edit-delivery-item-dialog.component.html',
  styleUrls: ['./edit-delivery-item-dialog.component.scss']
})

export class EditDeliveryItemDialogComponent implements OnInit {

  @Input() orderStop: OrderStop = {}
  @Input() order: Order = {}

  orderItemStops?: OrderItemStop[]

  get isAllItemAdded() {
    return this.orderItemStops.filter((orderItemStop) => orderItemStop.quantity != orderItemStop.maxQuantity)?.length === 0
  };

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private helperService: HelperService,
    private orderService: OrderService,
  ) {
  }

  ngOnInit(): void {
    this.orderItemStops = chain(this.order.orderStops)
      .cloneDeep()
      .filter((item) => {
        return item.orderStopType === OrderStopTypeEnum.PICKUP && item.isActive
      })
      .map((item) => {
        const orderStops = cloneDeep(item.orderItemStops)
          .filter((orderItemStop) => orderItemStop.isActive)
          .map((orderItemStop) => {
            const qty = this.orderService.getPickedQty(this.order, orderItemStop.orderItem.orderItemId);
            const currentOrderStopQty = this.orderService.getPickedQty(this.order, orderItemStop.orderItem.orderItemId, this.orderStop.orderStopId);
            orderItemStop.pickedQuantity = qty - currentOrderStopQty;
            orderItemStop.maxQuantity = orderItemStop.quantity - (qty - currentOrderStopQty);
            orderItemStop.quantity = currentOrderStopQty;
            orderItemStop.orderItemStopId = currentOrderStopQty;

            orderItemStop.orderStop = this.orderStop;
            orderItemStop.orderStopId = this.orderStop.orderStopId;
            return orderItemStop;
          })
        return orderStops;
      })
      .flatten()
      .value()

  }

  addAllItemStop() {
    this.orderItemStops = this.orderItemStops?.map(orderItemStop => ({
      ...orderItemStop,
      quantity: orderItemStop.maxQuantity
    }));
  }

  handleItemChange(orderItemStop: OrderItemStop) {
    const index = findIndex(this.orderStop.orderItemStops, { orderItemStopId: orderItemStop.orderItemStopId })
    if (index >= 0) {
      this.orderStop.orderItemStops.splice(index, 1, orderItemStop)
    }
  }

  async saveAndClose() {
    try {
      var warehouseForPickupDeliveryStopIds = await this.save();

      if (warehouseForPickupDeliveryStopIds && Object.keys(warehouseForPickupDeliveryStopIds).length > 0) {
        const activeModal = this.modalService.open(AddLocationComponent, {
          scrollable: true,
          size: "lg",
        });
        activeModal.componentInstance.order = this.order;
        activeModal.componentInstance.warehouseForPickupDeliveryStopIds = warehouseForPickupDeliveryStopIds;

        activeModal.result.then(
          async (result) => {
            if (result) {
              await this.saveAndClose();
            }
          },
          () => { }
        );
      }
      else {
        this.activeModal.close();
      }

    } catch (error) {
      this.helperService.errorMessage(error)
    }
  }


  async save() {
    this.helperService.isLoading = true
    try {

      for (let index = 0; index < this.orderItemStops.length; index++) {
        const orderItemStop = this.orderItemStops[index];
        const selectedItem = find(this.orderStop.orderItemStops, ({ orderItem }) => orderItem.orderItemId === orderItemStop.orderItem.orderItemId);
        if (selectedItem) {
          this.orderService.updateOrderItemStopQuantity(this.order, selectedItem, orderItemStop.quantity)
        } else {
          if (orderItemStop.quantity > 0) {
            this.orderStop.orderItemStops.push(omit(cloneDeep(orderItemStop), 'orderItemStopId'))
          }
        }


      }

      let orderStops = this.order?.orderStops;
      const index = findIndex(this.order?.orderStops, { orderStopId: this.orderStop?.orderStopId })
      if (index >= 0) {
        orderStops.splice(index, 1, this.orderStop)
      } else {
        orderStops.push(this.orderStop)
      }

      this.order.orderStops = orderStops
      await this.orderService.saveOrder(this.order);
      this.helperService.isLoading = false
      return true
    } catch (error) {
      var warehouseForPickupDeliveryStopIds = error?.error.data?.warehouseForPickupDeliveryStopIds;
      var otherMessage = error?.error?.otherMessages[0]?.message;

      if (otherMessage && warehouseForPickupDeliveryStopIds && Object.keys(warehouseForPickupDeliveryStopIds).length) {
        this.helperService.addToast(error?.error?.otherMessages[0]?.message, 'Select Hub', 'warning');
      }
      else {
        this.helperService.errorMessage(error)
      }

      this.helperService.isLoading = false
      return warehouseForPickupDeliveryStopIds;
    }
  }


  addEditOrderStop() {
    this.activeModal.close();
    const activeModal = this.modalService.open(EditDeliveryDialogComponent, {
      scrollable: true,
      size: "xl",
      windowClass: 'custom-style'
    });
    activeModal.componentInstance.order = this.order;
    activeModal.componentInstance.orderStop = cloneDeep(this.orderStop);
  }

  close() {
    this.activeModal.close();
  }

}
