import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: false,
    name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {
    transform(size: number, extension = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']) {
        const i = Math.floor(Math.log(size) / Math.log(1024))
        return `${parseFloat((size / Math.pow(1024, i)).toFixed(2))} ${extension[i]}`
    }
}
