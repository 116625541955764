import { Component, Input, OnInit } from '@angular/core';
import { Customer } from '@app/models/customer.model';
import { CustomerService } from '@app/shared/services/customer.service';
import { HelperService } from '@app/shared/services/helper.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'lodash';

@Component({
    standalone: false,
    selector: 'app-customer-marge',
    templateUrl: './customer-marge.component.html',
    styleUrls: ['./customer-marge.component.scss']
})
export class CustomerMargeComponent implements OnInit {

    @Input() customers: Customer[];
    childCustomers: Customer[] = []
    parentCustomer: Customer = null
    mergeCustomerIds: number[] = []
    view: 'parent' | 'child' = 'parent'

    constructor(
        public activeModal: NgbActiveModal,
        private helperService: HelperService,
        private customerService: CustomerService,
    ) { }

    handleSelectParent(customer: Customer) {
        this.mergeCustomerIds = []
        this.childCustomers = []
        this.parentCustomer = customer

        this.childCustomers = this.customers.filter(({ customerId, customerName, mainAddress, firstEmail, phone }) => {
            return (customerId !== customer?.customerId) &&
                ((customerName && customerName === customer?.customerName) || (mainAddress && mainAddress?.fullAddress === customer?.mainAddress?.fullAddress) ||
                    (firstEmail && firstEmail === customer.firstEmail) || (phone && phone === customer.phone))
        })
        this.childCustomers.map((item: any) => {
            item.checked = false
            return item
        })

    }
    onSelectChildCustomer(event) {
        this.mergeCustomerIds = map((this.childCustomers as any[]).filter(({ checked = false }) => checked), 'customerId')
    }

    async handleMarge() {
        if (this.parentCustomer?.customerId && this.mergeCustomerIds?.length > 0) {
            try {
                await this.customerService.mergeCustomers({
                    parentCustomerId: this.parentCustomer?.customerId,
                    customerIds: this.mergeCustomerIds,
                })
                this.close({ mergeCustomerIds: this.mergeCustomerIds, parentCustomerId: this.parentCustomer?.customerId });
            } catch (error) {
                this.helperService.errorMessage(error)
            }
        }

    }

    close(data = null) {
        this.activeModal.close(data);
    }

    ngOnInit() {
    }

}
