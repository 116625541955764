import {
    Component,
    OnInit,
    ViewChild,
    ChangeDetectorRef,
} from '@angular/core';
import { HelperService } from '@app/shared/services/helper.service';
import { ExpensesDateSelectorComponent } from '../../expenses-date-selector/expenses-date-selector.component';
import { NewExpenseService } from '@app/shared/services/new-expense.service';
import { NewExpense, ExpenseType, ExpenseSubtype } from '@app/models/expense.model';
import { TruckService } from '@app/shared/services/truck.service';

@Component({
    selector: 'app-office-expenses',
    templateUrl: './office-expenses.component.html',
    styleUrls: ['./office-expenses.component.scss'],
})
export class OfficeExpensesComponent implements OnInit {
    @ViewChild('expensesDateSelectorComponent')
    expensesDateSelectorComponent: ExpensesDateSelectorComponent;

    expenses: NewExpense[] = [];
    averagePerTruck: number = 0;
    numberOfTrucks: number = 0;

    totalExpenses: number = 0;

    isLoading = false;
    submitLoading = false;

    constructor(
        private helperService: HelperService,
        private expenseService: NewExpenseService,
        private truckService: TruckService,
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.onDateChange();
        this.loadNumberOfTrucks();
        this.cdr.detectChanges();
    }

    loadNumberOfTrucks() {
        const date = this.expensesDateSelectorComponent.date;

        // Assuming the backend can provide the number of active trucks for a given month
        this.truckService
            .getActiveTrucksCountByMonth(date)
            .then((count: number) => {
                this.numberOfTrucks = count;
                this.calculateAveragePerTruck();
            })
            .catch((error) => {
                this.helperService.errorMessage(error);
            });
    }

    onDateChange() {
        this.loadOfficeExpenses();
        this.loadNumberOfTrucks();
    }

    loadOfficeExpenses() {
        this.isLoading = true;
        const date = this.expensesDateSelectorComponent.date;

        this.expenseService.getOfficeExpenses(date)
            .then((expenses: any[]) => {
                this.expenses = expenses.map(expense => ({
                    ...expense,
                    isEditing: false,
                    isNew: false,
                }));

                this.calculateTotalExpenses();
            })
            .catch((error) => {
                this.helperService.errorMessage(error);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    addExpenseCategory() {
        this.expenses.push({
            expenseId: 0,
            date: null,
            name: '',
            amount: 0,
            type: ExpenseType.Office,
            subtype: ExpenseSubtype.OfficeOther,
            isEditing: true,
            isNew: true,
            validationError: null,
        });
    }

    toggleEditExpense(expense: NewExpense) {
        expense.isEditing = !expense.isEditing;
    }

    removeExpenseCategory(index: number) {
        this.expenses.splice(index, 1);
        this.calculateTotalExpenses();
    }

    calculateTotalExpenses() {
        this.totalExpenses = this.expenses.reduce((total, expense) => total + (Number(expense.amount) || 0), 0);
        this.calculateAveragePerTruck();
    }

    calculateAveragePerTruck() {
        if (this.numberOfTrucks > 0) {
            this.averagePerTruck = this.totalExpenses / this.numberOfTrucks;
        } else {
            this.averagePerTruck = 0;
        }
    }

    saveOfficeExpenses() {
        this.submitLoading = true;

        const hasValidationErrors = this.expenses.some(
            expense => expense.validationError
        );

        if (hasValidationErrors) {
            this.helperService.errorMessage('Please fix validation errors before saving.');
            this.submitLoading = false;
            return;
        }

        const date = this.expensesDateSelectorComponent.date;
        const expensesToSave: NewExpense[] = this.expenses.map(expense => ({
            date: date,
            name: expense.name || 'Other',
            subtype: expense.subtype,
            amount: expense.amount,
            type: ExpenseType.Office,
            expenseId: expense.expenseId,
        }));

        this.expenseService
            .saveOfficeExpenses(expensesToSave)
            .then(() => {
                this.helperService.successMessage('Office expenses saved successfully.');
                this.expenses.forEach(exp => exp.isEditing = false);
                this.expenses.forEach(exp => exp.isNew = false);
                this.loadOfficeExpenses();
            })
            .catch((error) => {
                this.helperService.errorMessage(error);
            })
            .finally(() => {
                this.submitLoading = false;
            });
    }

    onExpenseNameChange(expense: NewExpense) {
        if (this.isNameDuplicate(expense)) {
            expense.validationError = 'This expense name already exists.';
        } else {
            expense.validationError = null;
        }
    }

    hasValidationErrors(): boolean {
        return this.expenses.some(expense => expense.validationError);
    }

    deleteExpense(expense: NewExpense, index: number) {
        if (!expense.isNew) {
            const date = this.expensesDateSelectorComponent.date;
            this.expenseService.deleteExpense(expense, date)
                .then(() => {
                    this.helperService.successMessage('Expense deleted successfully.');
                    this.expenses.splice(index, 1);
                    this.calculateTotalExpenses();
                })
                .catch((error) => {
                    this.helperService.errorMessage(error);
                });
        }
        else {
            this.expenses.splice(index, 1);
        }
    }

    getDeleteMessage(expense: NewExpense): string {
        const name = expense.name && expense.name.trim() ? expense.name : 'this expense';
        return `Are you sure you want to delete ${name}?`;
      }

    private isNameDuplicate(expense: NewExpense): boolean {
        const allNames = this.expenses
            .filter(e => e !== expense)
            .map(e => e.name.toLowerCase());

        return allNames.includes(expense.name.toLowerCase());
    }
}
