import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface AlertData {
  title?: string;
  message?: string;
  closeText?: string;
}

@Component({
  standalone: false,
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  @Input() data: AlertData;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {

  }
}
