import { Component, Input } from '@angular/core';
import { BaseModel } from '@models/base.model';
import { UtilService } from '@app/services/util.service';

@Component({
    standalone: false,
    selector: 'app-user-info',
    templateUrl: './user-info.component.html',
    styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent {
    @Input() entity: BaseModel;
    @Input() ellipsis = 10;
    constructor(public util: UtilService) {
    }
}
