import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { toParams } from 'src/utils/to-params';
import { ApiPaginationResponse, ApiResponse, PaginationRequest } from '@app/models/core/base';
import { User } from '@app/models/user.model';
import { firstValueFrom } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class SettingService {
    basePath = "Settings";

    constructor(
        private http: HttpClient
    ) {

    }

    getUsers(request?: PaginationRequest) {
        return firstValueFrom(this.http.get<ApiPaginationResponse<User>>(`${this.basePath}/Users`, { params: toParams(request) }))
            .then(({ data }) => data);
    }

    createUser(request) {
        return firstValueFrom(this.http.post(`${this.basePath}/Users/`, request))
    }

    updateUser(id: string | number, request) {
        return firstValueFrom(this.http.put(`${this.basePath}/Users/${id}`, request))
    }

    enableUser(id: string) {
        return firstValueFrom(this.http.put(`${this.basePath}/Users/${id}/enable`, {}))
    }

    disableUser(id: string) {
        return firstValueFrom(this.http.put(`${this.basePath}/Users/${id}/disable`, {}))
    }

    deleteUser(id: string | number){
        return firstValueFrom(this.http.delete(`${this.basePath}/Users/${id}`))
    }

    inviteUser(id: string) {
        return firstValueFrom(this.http.post(`${this.basePath}/Users/${id}/Invite`, {})).then(((data) => data));
    }

    // Telegram Api Start

    telegramSendCode(request) {
        return firstValueFrom(this.http.post(`${this.basePath}/Telegram/sendCode`, request))
    }

    telegramSignIn(request) {
        return firstValueFrom(this.http.post(`${this.basePath}/Telegram/SignIn`, request))
    }

    telegramSendMessage(request) {
        return firstValueFrom(this.http.post(`${this.basePath}/Telegram/SendMessageBulk`, request))
    }

    getUserPrompts(request?) {
        return firstValueFrom(this.http.get<ApiPaginationResponse<any>>('UserPrompts', { params: toParams(request) }))
            .then((({ data }) => data));
    }

    getApiVersion() {
        return firstValueFrom(this.http.get<ApiResponse<string>>(`${this.basePath}/Version`))
            .then(({ data }) => data);
    }

    // Telegram Api End
}
