import { Directive, TemplateRef } from '@angular/core';

@Directive({
    standalone: false,
    selector: '[repeaterTemplate]',
    host: {}
})
export class RepeaterTemplate {
    constructor(public template: TemplateRef<any>) { }
}
