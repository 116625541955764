// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .size-sm {
  height: 18px;
  width: 18px;
  font-size: 8px;
}
:host .dndPlaceholder {
  height: 80px;
  margin: 6px;
  padding: 6px;
  background: #f9fafb;
  opacity: 0.8;
  width: 132px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  white-space: normal;
  font-size: 10px;
  border: 1px dashed #e5e7eb;
  border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/routes/route-unassign-stop-icon/route-unassign-stop-icon.component.scss"],"names":[],"mappings":"AAEI;EACI,YAAA;EACA,WAAA;EACA,cAAA;AADR;AAII;EACI,YAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,mBAAA;EACA,eAAA;EACA,0BAAA;EACA,kBAAA;AAFR","sourcesContent":["@use \"common/var\" as *;\n:host {\n    .size-sm {\n        height: 18px;\n        width: 18px;\n        font-size: 8px;\n    }\n\n    .dndPlaceholder {\n        height: 80px;\n        margin: 6px;\n        padding: 6px;\n        background: #f9fafb;\n        opacity: 0.8;\n        width: 132px;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        flex-direction: column;\n        white-space: normal;\n        font-size: 10px;\n        border: 1px dashed $divider;\n        border-radius: 4px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
