import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Attachment } from '@app/models/attachment.model';
import { Company } from '@app/models/company.model';
import { InvoiceLine, LineType } from '@app/models/invoice-line.model';
import { Invoice, PaymentMethodType, TnxStatus } from '@app/models/invoice.model';
import { AccountingService } from '@app/shared/services/accounting.service';
import { AuthService } from '@app/shared/services/auth/auth.service';
import { HelperService } from '@app/shared/services/helper.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, has, sumBy } from 'lodash';
import { DocumentViewerComponent } from '@app/@shared/document-viewer/document-viewer.component';
import { DocumentViewMode } from '@app/models/document.model';
import { DocumentService } from '@app/shared/services/document.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImagesService } from '@app/shared/services/images.service';
import mime from 'mime';
import { saveAs } from 'file-saver';
import { environment } from '@environments/environment';
import { EmailType } from '@app/models/sent-email.model';
import { ReceivePaymentComponent } from '../add-payment/receive-payment.component';
import { ListTabsComponent } from '@app/@shared/list-tabs/list-tabs.component';
import { filterAndSortSentEmailsByEmailType } from '@app/@shared/utils';

@UntilDestroy()
@Component({
  selector: 'app-accounting-invoice-preview',
  templateUrl: './accounting-invoice-preview.component.html',
  styleUrls: ['./accounting-invoice-preview.component.scss'],
})
export class AccountingInvoicePreviewComponent implements OnInit {

  invoice: Invoice = {
    balance: 0
  };
  PaymentMethodType = PaymentMethodType
  company: Company;
  invoiceItems: InvoiceLine[];
  invoiceFees: InvoiceLine[];
  totalInvoiceFees: number = 0.00;
  totalInvoiceItems: number = 0.00;
  orderAttachments: Attachment[];
  invoiceAttachments: Attachment[];
  loading: boolean = true;
  isEdit: boolean = false;

  imageUrl = environment.imagePreviewUrl
  isHeaderFixed: boolean = true;
  isUserAdminOrAccounting = false;

  @Input() listTabs: ListTabsComponent;

  private _data: Invoice;
  @Input()
  public get data(): Invoice {
    return this._data;
  }
  public set data(value: Invoice) {
    this._data = value;
    this.invoice = value;
    if (this.invoice) {
      this.getInvoiceAttachments(this.invoice?.invoiceId);
      this.getInvoiceSentEmails(this.invoice?.invoiceId)
      if (this.invoice?.lines) {
        this.invoiceFees = filter(this.invoice.lines, { lineType: LineType.Charge, isActive: true });
        this.invoiceItems = filter(this.invoice.lines, { lineType: LineType.Item, isActive: true });
      }  
      if (this.invoiceItems?.length === 0) {
        this.invoiceItems = []
      }
      if (this.invoiceFees?.length === 0) {
        this.invoiceFees = []
      }

      this.totalInvoiceFees = sumBy(this.invoiceFees, 'amount')
      this.totalInvoiceItems = sumBy(this.invoiceItems, item => item?.amount * item?.quantity);

      if (this.invoice?.orderId != null) {
        this.getOrderAttachments(this.invoice.orderId);
      }
      this.loading = false
    }
  }

  @Output() dataChange = new EventEmitter<Invoice>();
  @Output() onEdit = new EventEmitter<boolean>();

  private _tabExtraData: any;
  @Input()
  public get tabExtraData(): any {
    return this._tabExtraData;
  }
  public set tabExtraData(value: any) {
    this._tabExtraData = value;
    if (value?.isEdit) {
      this.isEdit = value?.isEdit
    }
  }

  get isEligibleForApproval(): boolean {
    return (this.invoice?.status === 'Draft' || this.invoice?.status === 'Open') &&
      this.invoice?.transactionPaymentStatus !== 'Paid' &&
      this.invoice?.transactionPaymentStatus !== 'PartialPaid' &&
      this.isUserAdminOrAccounting
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private accountingService: AccountingService,
    private authService: AuthService,
    private helperService: HelperService,
    private documentService: DocumentService,
    private ngbModal: NgbModal,
    private router: Router,
    private imagesService: ImagesService,
  ) {
    const isFixed = localStorage.getItem('invoice-preview.isHeaderFixed')
    this.isHeaderFixed = isFixed !== 'no';
  }

  ngOnInit(): void {
    this.authService.$user
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.company = data?.company;
        this.isUserAdminOrAccounting = ['COMPANY_ADMIN', 'GLOBAL_ADMIN', 'Accounting'].some((role) =>
            data?.roles.includes(role)
        );
      })

    // this.activatedRoute.queryParams
    //   .pipe(untilDestroyed(this))
    //   .subscribe((queryParams) => {
    //     if (queryParams?.isEdit) {
    //       this.router.navigate(['/app/accounting'], {
    //         queryParams: {
    //           isEdit: null,
    //         },
    //         queryParamsHandling: 'merge'
    //       });
    //       this.isEdit = true          
    //     }        
    //   })


  }

  async getInvoiceSentEmails(invoiceId){
    this.invoice.sentEmails = await this.accountingService.getSentEmails(invoiceId);
  }

  toggleEdit(isEdit) {
    const tabId = this.listTabs.activeTab;
    this.listTabs.updateTabExtra(tabId, { isEdit })
    this.isEdit = isEdit;
  }

  updateTabData(updatedData) {
    this.loading = true;
    this.getInvoice(updatedData?.invoiceId, updatedData?.nextReferenceData?.id, updatedData?.nextReferenceData?.tnxType);
    this.toggleEdit(false);
  }

  editInvoice() {
    this.toggleEdit(true);
  }

  toggleHeaderPin() {
    this.isHeaderFixed = !this.isHeaderFixed
    localStorage.setItem('invoice-preview.isHeaderFixed', this.isHeaderFixed ? 'yes' : 'no')
  }

  invoicePreviousNextRedirect({ id, referenceData }) {
    if (id) {
      this.loading = true;
      this.getInvoice(id, referenceData?.id, referenceData?.tnxType);
    }
  }

  getInvoice(invoiceId, referenceId, tnxType?: any) {
    let request = {};
    let invoiceFiltersStr = localStorage.getItem('invoice.filters');
    if (invoiceFiltersStr)
      request = JSON.parse(invoiceFiltersStr);
    else
      request = { byCustomer: true }

    if (referenceId)
      request = { ...request, referenceId }

    if (tnxType)
      request = { ...request, tnxType }

    if(!has(request, 'includeOrder')) 
      request = { ...request, includeOrder: true}

    this.accountingService.getInvoice(invoiceId, request)
      .then((res) => {
        this.invoice = res;
        this.dataChange.emit(res);
        if (this.invoice) {
          this.getInvoiceAttachments(this.invoice?.invoiceId);
          this.getInvoiceSentEmails(this.invoice?.invoiceId);
          if (this.invoice?.appliedPayments?.length > 0) {
            this.invoice.appliedPayments = this.invoice?.appliedPayments?.filter((appliedPayment) => appliedPayment?.payment?.status === TnxStatus.Paid);
          }
          if (this.invoice?.lines) {
            this.invoiceFees = filter(this.invoice.lines, { lineType: LineType.Charge, isActive: true });
            this.invoiceItems = filter(this.invoice.lines, { lineType: LineType.Item, isActive: true });
          }
          if (this.invoiceItems?.length === 0) {
            this.invoiceItems = []
          }
          if (this.invoiceFees?.length === 0) {
            this.invoiceFees = []
          }

          this.totalInvoiceFees = sumBy(this.invoiceFees, 'amount')
          this.totalInvoiceItems = sumBy(this.invoiceItems, item => item?.amount * item?.quantity);

          if (this.invoice?.orderId != null) {
            this.getOrderAttachments(this.invoice.orderId);
          }
          this.loading = false
        } else {
          this.loading = false
          this.router.navigateByUrl('app/accounting');
          this.helperService.errorMessage("Invoice not found.")
        }
      }).catch((error) => {
        this.loading = false
        this.helperService.errorMessage(error)
      })
  }

  async getInvoiceAttachments(invoiceId) {
    try {
      this.invoiceAttachments = await this.imagesService.getListByInvoiceAttachments(invoiceId);
    } catch (error) {
      this.helperService.errorMessage(error)
    }
  }

  async getOrderAttachments(orderId) {
    try {
      this.orderAttachments = await this.imagesService.getListByOrderAttachments(orderId);
    } catch (error) {
      this.helperService.errorMessage(error)
    }
  }

  invoiceDownload() {
    if (this.invoice.invoiceId) {
      this.accountingService.exportInvoices([this.invoice.invoiceId]).then((data: any) => {
        const blob = new Blob([data.fileData], { type: 'application/text' })
        saveAs(blob, 'export.iif');
        this.router.navigate(['/app/accounting'], { queryParams: { isReloadAccounting: true } });
        this.loading = true
        this.getInvoice(this.invoice?.invoiceId, this.invoice?.referenceId, this.invoice?.tnxType);
      }).catch((error) => {
        this.helperService.errorMessage(error)
      })
    }
  }

  deleteInvoice(event) {
    this.accountingService.deleteInvoice(this.invoice?.invoiceId).then(() => {
      this.router.navigate(['/app/accounting'], { queryParams: { closeTabId: this.invoice?.invoiceId } })
      this.helperService.successMessage("Invoice successfully deleted")
    }).catch((error) => {
      this.helperService.errorMessage(error);
    });
  }

  viewAttachment(attachment) {
    this.helperService.isLoading = true
    this.imagesService.downloadAttachment(attachment).then((res) => {
      const blob = new Blob([res], { type: mime.getType(attachment.name) });
      const fileURL = window.URL.createObjectURL(blob);
      this.helperService.isLoading = false
      window.open(fileURL, '_blank');
    }).catch((error) => {
      this.helperService.isLoading = false
      this.helperService.errorMessage(error)
    })
  }

  downloadAttachment(event, attachment) {
    event.preventDefault();
    event.stopPropagation();
    this.imagesService.downloadAttachment(attachment).then((res) => {
      saveAs(new Blob([res]), attachment.name);
    }).catch((error) => {
      this.helperService.errorMessage(error)
    })
  }

  handleApproveOrUnApproveInvoice(type) {
    this.accountingService
      .invoiceApproveOrUnApprove(this.invoice.invoiceId, type)
      .then((res) => {
        this.invoice.status = type;
        this.helperService.successMessage(
          `Invoice #${this.invoice.invoiceId} has been successfully ${type === 'Approved' ? 'Approved' : 'disapproved'}.`
        );
      })
      .catch((error) => {
        this.helperService.errorMessage(
          `Failed to ${type === 'Approved' ? 'approve' : 'disapprove'} invoice #${this.invoice.invoiceId}.`
        );
      });
  }

  async showInvoice() {
    const activeModal = this.ngbModal.open(DocumentViewerComponent, {
      scrollable: true,
      size: "xl",
    });

    const content = await this.documentService.downloadInvoice(this.invoice?.invoiceId, DocumentViewMode.Html)

    if (!content?.changingThisBreaksApplicationSecurity) {
      activeModal.componentInstance.noResultsFound = true;
    }

    let invoiceSentEmails = await this.accountingService.getSentEmails(this.invoice?.invoiceId, { suggestCustomerPastSentEmails: true }) || [];
    invoiceSentEmails = await filterAndSortSentEmailsByEmailType(invoiceSentEmails, EmailType?.Invoice);

    activeModal.componentInstance.content = content;
    activeModal.componentInstance.entity = this.invoice;

    if (invoiceSentEmails?.length > 0) {
      activeModal.componentInstance.emails = invoiceSentEmails?.map(se => ({ email: se?.to, type: se?.emailType, label: se?.to }));
      activeModal.componentInstance.selectedEmail = invoiceSentEmails?.filter(x => x?.isSelected).map(rse => rse?.to);
      activeModal.componentInstance.showCustomEmails = true;
    }
    else {
      activeModal.componentInstance.showEmail = true;
    }

    activeModal.result.then(
      async (result) => {
        if (result) {
          if (result.type == "sendEmail") {
            this.documentService
              .emailInvoice(
                result?.entity?.invoiceId,
                result?.email
              )
              .then(() => {
                if (this.invoice?.invoiceId) {
                  this.loading = true
                  this.router.navigate(['/app/accounting'], { queryParams: { isReloadAccounting: true } });
                  this.getInvoice(this.invoice?.invoiceId, this.invoice?.referenceId, this.invoice?.tnxType);
                  this.helperService.successMessage(
                    "The invoice has been sent"
                  );
                }
              })
              .catch((error) => {
                this.helperService.errorMessage(error);
              });
          } else {
            this.documentService
              .downloadInvoice(
                result?.entity?.invoiceId,
                DocumentViewMode.Pdf
              )
              .then((file) => {
                saveAs(
                  file,
                  `Invoice-${result?.entity?.invoiceId}.pdf`
                );
              });
          }
        }
      },
      () => { }
    );
  }

  async payInvoice() {
    const activeModal = this.ngbModal.open(ReceivePaymentComponent,
      {
        modalDialogClass: 'service_type_modal customer-payment-modal'
      });
    if (this.invoice?.customer) {
      activeModal.componentInstance.customerId = this.invoice?.customer?.customerId
      activeModal.componentInstance.customerName = this.invoice?.customer?.customerName
      activeModal.componentInstance.invoiceId = this.invoice?.invoiceId
    }
    activeModal.result.then(
      (result) => {
        if (result) {
          this.router.navigate(['/app/accounting'], { queryParams: { isReloadAccounting: true } });
          if (this.invoice?.invoiceId) {
            this.loading = true
            this.getInvoice(this.invoice?.invoiceId, this.invoice?.referenceId);
          }
        }
      },
      () => { }
    );
  }

}
