import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { navigation, NavItem } from '../navigation/navigation';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { User } from '@app/models/user.model';
import { AuthService } from '@app/shared/services/auth/auth.service';
import { NotificationsService } from '@app/shared/services/notifications.service';
import { TenantCompanyList } from '@app/models/company.model';
import { CompanyService } from '@app/shared/services/company.service';
import { AppService } from '@app/shared/services/app.service';

@UntilDestroy()
@Component({
  standalone: false,
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit, OnDestroy {

  showSearchBar: boolean = true;
  icon: string;
  title: string;

  @Input() collapsed = false;
  @Output('collapseToggle') collapseToggle = new EventEmitter()
  routerUrl: string;
  user: User;
  activeNavItem: NavItem;
  notifications: any;
  companies: TenantCompanyList[];
  selectedCompany: TenantCompanyList = {
    companyId: null,
    companyName: ''
  };


  public get searchTerm(): string {
    return this.appService.searchTerm;
  }
  public set searchTerm(value: string) {
    this.appService.searchTerm = value
  }

  constructor(
    private router: Router,
    private authService: AuthService,
    private notificationsService: NotificationsService,
    private appService: AppService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {

    // this.companyService.getAll({
    //   page: 1,
    //   itemsPerPage: 10
    // }).then((res) => {
    //   this.companies = res?.list
    // })

    // this.companyService.$selectedCompany
    //   .pipe(untilDestroyed(this))
    //   .subscribe((selectedCompany) => {
    //     this.selectedCompany = selectedCompany;
    //   })



    this.routerUrl = this.router.url;

    this.router.events
      .pipe(untilDestroyed(this))
      .subscribe(event => {
        // if (event instanceof NavigationStart) {
        //   const url = event.url;
        //   const whiteListUrl = [
        //     '/app/quotes',
        //     '/app/orders',
        //     '/app/dispatch',
        //     '/app/customers',
        //     '/app/accounting',
        //     '/app/drivers',
        //     '/app/settings/users',
        //     '/app/settings/fleet',
        //     '/app/settings/locations',
        //     '/app/settings/charge-types',
        //     '/app/settings/shipment-types',
        //     '/app/settings/activity-log'
        //   ]

        //   if (whiteListUrl.indexOf(url) >= 0) {
        //     this.showSearchBar = true
        //   } else {
        //     this.showSearchBar = false
        //   }

        // }

        if (event instanceof NavigationEnd) {
          this.routerUrl = event.url;
          this.updateActiveNav();
        }
      });

    this.authService.$user
      .pipe(untilDestroyed(this))
      .subscribe(user => {
        this.user = user
        // if (this.user?.id) {
        //   this.notificationsService.getAll({ userId: this.user.id }).then((res) => {
        //     this.notifications = res
        //   })
        // }
      });

    this.updateActiveNav()
  }

  ngOnDestroy() {
    this.appService.searchTerm = ''
  }

  updateActiveNav(): void {
    const allNavItems = navigation

    const item = allNavItems.find(navItem => navItem.path === this.routerUrl);
    if (item) {
      this.activeNavItem = item;
    }

    this.activeNavItem = allNavItems.find(navItem => this.routerUrl.startsWith(navItem.path));
  }

  onNavToggle() {
    this.collapseToggle.emit(!this.collapsed)
  }

  logout() {
    this.authService.logout();
  }
}
