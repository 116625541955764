import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDate, NgbDateParserFormatter, NgbDatepicker, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from "moment"

export interface IDateRange {
    start: Date | string | NgbDate | null;
    end: Date | string | NgbDate | null;
}

export enum DateRangeKeyEnum {
    TODAY = 'Today',
    YESTERDAY = 'Yesterday',
    THIS_WEEK = 'This week',
    LAST_WEEK = 'Last week',
    THIS_MONTH = 'This month',
    LAST_MONTH = 'Last month',
    THIS_YEAR = 'This Year',
    LAST_YEAR = 'Last Year',
    ALL_TIMES = 'All Times',
}

@Component({
    standalone: false,
    selector: 'ngx-date-range-picker',
    templateUrl: './date-range-picker.component.html',
    styleUrls: ['./date-range-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateRangePickerComponent),
            multi: true
        }
    ],
    encapsulation: ViewEncapsulation.Emulated
})
export class DateRangePickerComponent implements OnInit {

    /**
     *
     * `NgbDatepicker` is meant to be displayed inline on a page or put inside a popup.
     */
    @ViewChild(NgbDatepicker, { static: true }) ngbDatepicker: NgbDatepicker;

    @Output() onDateChanged: EventEmitter<IDateRange> = new EventEmitter<IDateRange>();

    /**
     * show or hide default ranges, show by default
     */
    @Input() showRange: boolean = true;

    /**
     * Define ngb bootstrap date ranges configuration
     */
    @Input() ranges: any[] = [];

    hoveredDate: NgbDate | null = null;
    startDate: NgbDate;
    endDate: NgbDate | null = null;
    selection = new SelectionModel<string>(false);
    /**
     * 
     */
    private _value: IDateRange;
    get value(): IDateRange {
        return this._value;
    }
    set value(values: IDateRange) {
        this._value = values;
        if ((values.start && values.end) || (!values.start && !values.end)) {
            this.onChange(values);
        }
        this.onTouched();
    }

    onChange = (value: IDateRange) => { };
    onTouched = () => { };

    constructor(
        public readonly formatter: NgbDateParserFormatter
    ) { }

    ngOnInit() { }

    writeValue(value: IDateRange) {
        if (value?.start && value?.end) {
            const { start, end } = value;

            const startDate = moment(start).toDate();
            this.startDate = new NgbDate(startDate.getFullYear(), startDate.getMonth() + 1, startDate.getDate());

            const endDate = moment(end).toDate();
            this.endDate = new NgbDate(endDate.getFullYear(), endDate.getMonth() + 1, endDate.getDate());

            this.updateCalenderRange(
                this.parseDate(this.startDate).startOf('day'),
                this.parseDate(this.endDate).startOf('day')
            );
        } else {
            this.startDate = null;
            this.endDate = null;
        }
    }

    registerOnChange(fn: (value: IDateRange) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }


    /**
    * If hovered on dates
    * 
    * @param date 
    * @returns 
    */
    dateFormat(date: NgbDateStruct | null): string {        
        if (date) {
            const formattedYear = (date.year % 100).toString().padStart(2, '0');
            const formattedMonth = date.month.toString().padStart(2, '0');
            const formattedDate = date.day.toString().padStart(2, '0');
            return `${formattedMonth}/${formattedDate}/${formattedYear}`;
          }
          return '';
    }




    /**
     * On date selection
     * 
     * @param date 
     */
    onDateSelection(date: NgbDate) {
        if (!this.startDate && !this.endDate) {
            this.startDate = date;
        } else if (this.startDate && !this.endDate && date && (date.after(this.startDate) || date.equals(this.startDate))) {
            this.endDate = date;

            const start = this.parseDate(this.startDate).startOf('day');
            const end = this.parseDate(this.endDate).startOf('day');

            this.updateCalenderRange(start, end);
        } else {
            this.endDate = null;
            this.startDate = date;
        }
    }

    /**
     * On dates changed and emit event
     */
    onDatesUpdated() {
        let start = null;
        let end = null;

        start = this.startDate ? moment()
            .set('D', this.startDate.day)
            .set('month', this.startDate.month - 1)
            .set('year', this.startDate.year)
            .startOf('day')
            .toDate() : null;

        end = this.endDate ? moment()
            .set('D', this.endDate.day)
            .set('month', this.endDate.month - 1)
            .set('year', this.endDate.year)
            .startOf('day')
            .toDate() : null;

        this.value = { start, end };
        this.onDateChanged.emit({ start: this.startDate, end: this.endDate });
    }

    /**
     * Is date already selected
     * 
     * @param date 
     */
    isDateSelected(date: NgbDate) {
        return date.equals(this.startDate) || (this.endDate && date.equals(this.endDate)) || this.isInside(date) || this.isHovered(date);
    }

    /**
     * listen range click event on date range picker
     * @param range
     */
    rangeClicked(range: any) {
        if (range) {
            const { value, title } = range;
            if (!this.selection.isSelected(title)) {
                this.selection.toggle(title);

                const [start, end] = value;
                this.writeValue({
                    start: start ? start.toDate() : null,
                    end: end ? end.toDate() : null
                });

                this.onDatesUpdated();
                this.updateCalenderBasedOnSelectedDate(this.ngbDatepicker);
            }
        }
    }

    /**
     * Function to update the displayed month / year based on the selected date
     * 
     * @param datepicker 
     */
    updateCalenderBasedOnSelectedDate(datepicker: NgbDatepicker) {
        if (this.startDate) {
            const { year, month } = this.startDate;
            datepicker.navigateTo({ year: year, month: month });
        }
    }

    /**
     * Update calender pre selected range
     * 
     * @param startDate 
     * @param endDate 
     */
    updateCalenderRange(startDate: moment.Moment, endDate: moment.Moment) {
        this.ranges.forEach((range) => {
            const { title, value } = range;
            const [start, end] = value;

            /**
             * If range matched pre selected range
             */
            if (startDate.isSame(moment(start).startOf('day')) && endDate.isSame(moment(end).startOf('day'))) {
                if (!this.selection.isSelected(title)) {
                    this.selection.toggle(title);
                }
            } else {
                this.selection.deselect(title);
            }
        });
    }

    /**
     * Cleared date selection
     */
    clearDateSelection() {
        this.writeValue({
            start: null,
            end: null
        });
        this.selection.clear();
        this.onDatesUpdated();        
    }

    /**
     * If hovered on dates
     * 
     * @param date 
     * @returns 
     */
    isHovered(date: NgbDate) {
        return this.startDate && !this.endDate && this.hoveredDate && date.after(this.startDate) && date.before(this.hoveredDate);
    }

    /**
     * Check if dates are inside start and end dat
     * 
     * @param date 
     * @returns 
     */
    isInside(date: NgbDate) {
        return this.endDate && date.after(this.startDate) && date.before(this.endDate);
    }

    /**
     * Check if dates are in the range
     * 
     * @param date 
     * @returns 
     */
    isRange(date: NgbDate): boolean {
        return date.equals(this.startDate) || (this.endDate && date.equals(this.endDate)) || this.isInside(date) || this.isHovered(date);
    }

    /**
     * Parse Date
     * 
     * @param date 
     * @param format 
     * @returns 
     */
    parseDate(date: NgbDate): moment.Moment {
        if (date) {
            const mDate = moment().set('y', date.year).set('M', date.month - 1).set('D', date.day);
            return mDate;
        }
    }
}
