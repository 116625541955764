import { Component, Input, OnInit } from '@angular/core';
import { Address } from '@app/models/address.model';
import { AddressService } from '@app/shared/services/address.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    standalone: false,
    selector: 'app-info-order-dialog',
    templateUrl: './info-order-dialog.component.html',
    styleUrls: ['./info-order-dialog.component.scss']
})
export class InfoOrderDialogComponent implements OnInit {

    content: string;

    /*
	* Getter & Setter for dynamic address instructions
	*/
	_address: Address;
	get address(): Address {
		return this._address;
	}
	@Input() set address(address: Address) {
        this._address = address;
        this.content = this.address.instructions;
	}

    constructor(
        private readonly activeModal: NgbActiveModal,
        private readonly addressService: AddressService
    ) {}

    ngOnInit(): void {}

    /**
     * Save address instructions and close the dialog
     */
    async save() {
        try {
            this.address.instructions = this.content;

            const { addressId } = this.address;
            await this.addressService.update(addressId, this.address);
        } catch (error) {
            console.log('Error while updating customer primary address');
        } finally {
            this.close();
        }
    }

    /**
     * Close active dialog
     */
    close() {
        this.address.instructions = this.content;
        this.activeModal.close();
    }
}
