import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { FormValidateDirective } from '@app/@shared/form-validate/form-validate.directive';
import { isNotEmpty } from '@app/@shared/utils';
import { customerEmailTypes } from '@app/data/customer';
import { stateOptions } from '@app/data/state';
import { EntityType } from '@app/models/entity-type.model';
import { ImageUploadTypeEnum } from '@app/models/image.model';
import { OutsourceCompany, OutsourceCompanyFinanceDiscountTypeEnum, OutsourceCompanyFinanceTypeOfWorkEnum } from '@app/models/outsource-company.model';
import { HelperService } from '@app/shared/services/helper.service';
import { OutsourceCompanyService } from '@app/shared/services/outsource-company.service';
import { ValidationError, array, number, object, string } from 'yup';
import { TypeOfTrucksService } from '@app/shared/services/type-of-truck.service';
import { AddressService } from '@app/shared/services/address.service';
import { includes } from 'lodash';
import { EnumFlagService } from '@app/shared/services/enum-flag.service';
import { Country } from '@app/models/address.model';
@Component({
  standalone: false,
  selector: 'app-create-outsource-company',
  templateUrl: './create-outsource-company.component.html',
  styleUrls: ['./create-outsource-company.component.scss'],
  host: {
    '[class.page-container]': "true"
  }
})
export class CreateOutsourceCompanyComponent implements OnInit {

  ImageUploadTypeEnum = ImageUploadTypeEnum;
  states = stateOptions;
  customerEmailTypes = customerEmailTypes;
  returnUrl: string;
  typeOfTrucks: any;

  isCostFixed: boolean = false
  isCostDistance: boolean = false
  isCostHourly: boolean = false
  isCostPallets: boolean = false

  enumObj = OutsourceCompanyFinanceTypeOfWorkEnum;

  outsourceCompanyFinanceDiscount = [
    {
      name: "Fix",
      value: OutsourceCompanyFinanceDiscountTypeEnum.Value,
    },
    {
      name: "Percentage",
      value: OutsourceCompanyFinanceDiscountTypeEnum.Percent,
    }
  ]

  outsourceCompany: OutsourceCompany = {
    address: {
      entityType: EntityType.OutsourceCompany,
      name: "Main",
      addressLine1: "",
      city: "",
      state: "",
      countryId: null,
      zip: "",
      houseOrOffice: "",
      street: "",
      gate: ""
    },
    companyContact: {
      isPrimary: true,
      email: '',
      phone: null,
      phoneCountryCode: '1',
      phoneCountryIso: 'US',
    },
    companyEmails: [
      {
        isActive: true,
        emailType: null,
        email: '',
      }
    ],
    companyFinances: [
      {
        typeOfWork: null,
        freeWaitingTimeMinutes: "",
        discount: null,
        discountType: OutsourceCompanyFinanceDiscountTypeEnum.Value,
        notes: "",
      }
    ],
    companyTypeOfTrucks: [
      {
        typeOfTruckId: null,
        park: null
      }
    ],
    outsourceCompanyName: '',
    phone: null,
		phoneCountryCode: '1',
		phoneCountryIso: 'US',
  }

  validationSchema = object().shape({
    outsourceCompanyName: string().label("Company Name").trim().required().max(256),
    phoneNumber: string().label("Company Phone").nullable().required(),
    address: object().shape({
      addressLine1: string().trim().label('House/Office').required().max(256),
      addressLine2: string().trim().label('Street').required().max(256),
      city: string().trim().label('City').required().max(256),
      state: string().label('State').required(),
      country: number().label('Country').nullable().required(),
      zip: string().trim().label('Zip Code').label('Zip code').required().max(32),
    }),
    companyEmails: array().of(
      object().shape({
        email: string().label('Email').required().email().max(256),
        emailType: string().nullable(),
      })
    ).test('unique-emails', 'Emails must be unique', function (arr: any[]) {
      const path = this.path;
      let errors = [];
      let valueCount = arr.reduce((acc, obj) => {
        acc[obj.email] = (acc[obj.email] || 0) + 1;
        return acc;
      }, {});
      arr.forEach((obj, index) => {
        if (obj.email && valueCount[obj.email] > 1) {
          const error = this.createError({ message: `Email is duplicated`, path: `${path}[${index}].email` });
          errors.push(error);
        }
      });
      if (errors?.length > 0) {
        return new ValidationError(errors);
      }
      return true;
    }),
    companyContact: object().shape({
      firstName: string().label('First Name').nullable().max(256),
      lastName: string().label('Last Name').nullable().max(256),
      email: string().label('Email').nullable().email().max(256),
      location: string().trim().label('Address').max(256),
      city: string().trim().label('City').max(256),
      cellNumber: string().label('Cell Number').nullable(),
      contactNumber: string().label('Company Phone').nullable(),
      state: string().label('State'),
      zip: string().trim().label('Zip Code').label("Zip code").max(32),
    }).test('unique-values', 'Value must be unique', function (companyContact: any) {
      const companyName = this.parent?.outsourceCompanyName;
      const customerContactName = `${companyContact?.firstName} ${companyContact?.lastName}`;
      const phone = this.parent?.phoneNumber;
      const emails = (this.parent?.companyEmails || []).map((item: any) => item.email);
      const contactNumber = companyContact?.contactNumber;
      const email = companyContact?.email;
      const errors: any[] = [];
      if (companyName && companyName === customerContactName) {
        errors.push(
          this.createError({ message: 'Company Name is duplicated', path: `outsourceCompanyName` }),
          this.createError({ message: 'First Name is duplicated', path: `${this.path}.firstName` }),
          this.createError({ message: 'Last Name is duplicated', path: `${this.path}.lastName` })
        );
      } else if (companyName && companyName === companyContact?.firstName) {
        errors.push(
          this.createError({ message: 'Company Name is duplicated', path: `outsourceCompanyName` }),
          this.createError({ message: 'First Name is duplicated', path: `${this.path}.firstName` })
        );
      }
      if (emails.length > 0) {
        emails.forEach((e, i) => {
          if (email && email === e) {
            errors.push(
              this.createError({ message: 'Email is duplicated', path: `companyEmails[${i}].email` }),
              this.createError({ message: 'Email is duplicated', path: `${this.path}.email` })
            );
          }
        });
      }
      if (phone && phone === contactNumber) {
        errors.push(
          this.createError({ message: 'Duplicate of company phone and contact number detected.', path: 'phoneNumber' }),
          this.createError({ message: 'Duplicate of contact number and company phone detected.', path: `${this.path}.contactNumber` }),
        );
      }
      return errors.length > 0 ? new ValidationError(errors) : true;
    }),
    companyFinances: array().of(
      object().shape({
        discount: number().label("Discount").nullable().required(),
        freeWaitingTimeMinutes: string().label("Free Waiting Time").nullable().required(),
        typeOfWork: number().label("Type of Work").nullable().required(),
      })
    ),
    companyTypeOfTrucks: array().of(
      object().shape({
        park: number().label("park").nullable().required(),
        typeOfTruckId: number().label("Type of Truck").nullable().label("Type of Truck").required(),
      })
    ),
  });

  outsourceCompanyFinanceTypeOfWorksAndCost = [
    {
      title: "Fixed",
      value: OutsourceCompanyFinanceTypeOfWorkEnum.Fixed
    },
    {
      title: "Distance",
      value: OutsourceCompanyFinanceTypeOfWorkEnum.Distance
    },
    {
      title: "Hourly",
      value: OutsourceCompanyFinanceTypeOfWorkEnum.Hourly
    }
  ]

  public snapshot$: Data = this.activatedRoute.snapshot;
  countries: Country[];

  constructor(
    private enumFlagService: EnumFlagService,
    public outsourceCompanyService: OutsourceCompanyService,
    public helperService: HelperService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public addressService: AddressService,
    private readonly location: Location,
    private typeOfTrucksService: TypeOfTrucksService
  ) { }

  ngOnInit() {
    this.typeOfTrucksService.getAll()
      .then(res => {
        this.typeOfTrucks = res;
      }).catch((error) => {
        this.helperService.errorMessage(error)
      })

    this.addressService.getCountries()
      .then(res => {
        this.countries = res;
      }).catch((error) => {
        this.countries = [];
        this.helperService.errorMessage(error)
      })
  }

  ngAfterViewInit() {
    this.returnUrl = this.snapshot$.queryParams.returnUrl;
    this.patchUsingLocationState(this.location.getState());
  }

  async costFieldsVisible(values) {
    const selectedCostFields: number[] = this.enumFlagService.getFlagArrayForFlagNumberFomEnum(values, this.enumObj)
    this.isCostFixed = await includes(selectedCostFields, this.enumObj.Fixed);
    this.isCostDistance = await includes(selectedCostFields, this.enumObj.Distance);
    this.isCostHourly = await includes(selectedCostFields, this.enumObj.Hourly);
    this.isCostPallets = await includes(selectedCostFields, this.enumObj.Pallets);
  }

  async handleSubmit(form: FormValidateDirective) {
    try {
      if (form.isValid) {

        var res: any = await this.outsourceCompanyService.validateOutsourceCompany(this.outsourceCompany);
        form.errors = {};

        if (res.existedCompanyName)
          form.errors['outsourceCompanyName'] = ["Company name is not unique"]
        if (res.existedPhone)
          form.errors['phoneNumber'] = ["Company Phone is not unique"]
        if (res.existedEmailIndex?.length > 0) {
          form.errors['companyEmails'] = [];

          for (const i of res.existedEmailIndex) {
            form.errors['companyEmails'][i] = { email: ["Email is not unique"] }
          }
        }

        if (Object.keys(form.errors).length > 0) {
          form.errors$.next(form.errors);
        }
        else {
          try {
            const outsourceCompany = await this.outsourceCompanyService.create(this.outsourceCompany);
            this.helperService.successMessage('Outsource Details Successfully Created.');
            if (outsourceCompany?.id) {

              const redirect = this.returnUrl ? [this.returnUrl] : ['/app/outsource'];
              // If return callback URL find redirect there or redirect on customer edit page
              this.router.navigate(redirect, {
                queryParams: {
                  ...(this.returnUrl ? { outsourceId: outsourceCompany?.id } : { id: outsourceCompany?.id })
                }
              });
            }
          } catch (error) {
            this.helperService.errorMessage(error);
          }
        }
      }
    } catch (error) {
      this.helperService.errorMessage(error);
    }
  }

  /**
   * GET location old state & patch form value
   * We are using such functionality for create new customer from order create/update page
   *
   * @param state
   */
  patchUsingLocationState(state: any) {
    const { firstName, lastName, customerName } = state || new Object();

    if (isNotEmpty(customerName)) { this.outsourceCompany.outsourceCompanyName = customerName; }
    if (isNotEmpty(firstName)) { this.outsourceCompany.companyContact.firstName = firstName; }
    if (isNotEmpty(lastName)) { this.outsourceCompany.companyContact.lastName = lastName; }
  }

}