import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Driver, DriverEmploymentEnum, DriverPayoutEnum } from '@app/models/driver.model';
import { cloneDeep } from 'lodash';
import { object } from 'yup';

@Component({
  standalone: false,
  selector: 'app-driver-settings',
  templateUrl: './driver-settings.component.html',
  styleUrls: ['./driver-settings.component.scss']
})
export class DriverSettingsComponent implements OnInit {

  title = 'Settings'
  settingAmount: boolean;
  settingAmountEnable: boolean = true;
  isEdit: boolean = false
  initialDriver: Driver;
  validationSchema = object().shape({})

  private _driver: Driver = {
    quickBooksClassName: '',
    telegramUserId: '',
  }

  @Input() isLoading: boolean = false;

  @Input()
  public get driver(): Driver {
    return this._driver;
  }
  public set driver(value: Driver) {
    this._driver = cloneDeep(value);
    this.initialDriver = cloneDeep(value);
  }

  DriverEmploymentEnum = DriverEmploymentEnum
  DriverPayoutEnum = DriverPayoutEnum

  @Output() driverChange = new EventEmitter()

  constructor(
  ) {
  }

  ngOnInit(): void {
  }

  discardChanges() {
    this.isEdit = false
    this.driver = this.initialDriver
  }

  editDriverSettings() {
    this.isEdit = true;
  } 

  onSubmit(f) {
    if(f.isValid && this.isEdit) {
        try {
          this.driverChange.emit(this.driver);
          this.isEdit = false;
        } catch (error) {
          this.isEdit = true;
        }
    }
  }

}
