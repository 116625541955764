import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneCodeExtensionInputComponent } from './phone-code-extension-input/phone-code-extension-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TypeaheadSelectModule } from '../typeahead-select/typeahead-select.module';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { DirectivesModule } from '../directives/directives.module';


@NgModule({
  declarations: [
    PhoneCodeExtensionInputComponent,
  ],
  exports: [
    PhoneCodeExtensionInputComponent,
  ],
  imports: [
    CommonModule,
    DirectivesModule,
    FormsModule,
    TypeaheadSelectModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule
  ]
})
export class PhoneCodeExtensionInputModule { }
