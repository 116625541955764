import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Customer } from '@app/models/customer.model';
import { DateRange } from '@app/shared/components/form/date-range-picker/date-range-picker.component';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class FiltersComponent implements OnInit {

  @Input() filters: any = {};
  @Output() filtersChange = new EventEmitter<any>();

  typeOptions = [
    { name: 'Invoice', value: 'Invoice', selected: false },
    { name: 'Payment', value: 'Payment', selected: false }
  ];

  statusOptions = [
    { name: 'Draft', value: 'Draft', selected: false },
    { name: 'Approved', value: 'Approved', selected: false },
    { name: 'Pending', value: 'Pending', selected: false }
  ];

  isYesChecked: boolean;
  isNoChecked: boolean;
  selectedDateRange: DateRange;  
  selectedCustomer: Customer;

  constructor(
  ) { }

  ngOnInit(): void {
  }

  applyFilter() {
    if (this.selectedCustomer?.customerId) {
      this.filters.Customers = [this.selectedCustomer.customerId];
    } else {
      this.filters.Customers = []
    }
    if (this.selectedDateRange?.start && this.selectedDateRange?.end){
      this.filters.DateRange = [this.selectedDateRange.start, this.selectedDateRange.end]
    }else{
      this.filters.DateRange = []
    }
    this.filtersChange.emit(this.filters);
  }

  clearSearch(load) {
    this.filters = {
      Page: 1,
      ItemsPerPage: 15,
      SortBy: 'Date',
      SortDirection: 'Descending',
    };
    this.selectedCustomer = null;
    this.selectedDateRange = null;
    this.isYesChecked = false;
    this.isNoChecked = false;

    this.yesChecked();
    this.noChecked();
    this.applyFilter();
  }

  yesChecked() {
    if (this.isYesChecked) {
      this.filters.inQb = true;
      this.isNoChecked = false;
    } else {
      delete this.filters?.inQb
    }

  }

  noChecked() {
    if (this.isNoChecked) {
      this.filters.inQb = false;
      this.isYesChecked = false;
    } else {
      delete this.filters?.inQb
    }
  }
  
  filterTnxTypesSelection(values) {
    if (values?.length > 0) {
      this.filters.TnxTypes = values?.map(r => r.value);
    } else {
      delete this.filters?.TnxTypes
    }
  }

  filterStatusSelection(values) {
    if (values?.length > 0) {
      this.filters.TnxStatus = values?.map(r => r.value);
    } else {
      delete this.filters?.TnxStatus
    }
  }

}
