import { ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNotEmpty } from '@app/@shared/utils';
import { Customer, CustomerSearchRequest } from '@app/models/customer.model';
import { AccountingService } from '@app/shared/services/accounting.service';
import { CustomerService } from '@app/shared/services/customer.service';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { from, merge, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, map, switchMap, take, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
    standalone: false,
    selector: 'app-customer-select',
    templateUrl: './customer-select.component.html',
    styleUrls: ['./customer-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CustomerSelectComponent),
            multi: true
        }
    ],
    encapsulation: ViewEncapsulation.Emulated,
})
export class CustomerSelectComponent implements OnInit {

    @ViewChild('instance', { static: true }) instance: NgbTypeahead;

    loading: boolean;
    disabled: boolean;
    filterRequest: CustomerSearchRequest = {
        Page: 1,
        ItemsPerPage: 20,
        SearchTerm: '',
    }
    customers: Customer[] = [];
    page: number = 1;

    focus$ = new Subject<string>();
    click$ = new Subject<string>();
    scrollEvent$ = new Subject();
    loadMore$ = new Subject();


    @Input() popupClass: string;
    @Input() initialLoading: boolean = false;
    @Input() options: Partial<Customer>[];
    @Input() disableAutocomplete: boolean = false;
    @Input() placeholder: string;
    @Input() type: 'customer' | 'accounting';


    @Output() onChangedTerm = new EventEmitter<string>();
    @Output() customerChange = new EventEmitter<Customer>();

    onChange = (value: string) => { };
    onTouched = () => { };

    private _value: string | any = null;
    get value(): string | any {
        return this._value;
    }
    set value(updateValue: string | any) {
        this._value = updateValue;
        this.cdr.detectChanges();

        this.onChange(updateValue);
        this.onTouched();
    }

    constructor(
        private renderer: Renderer2,
        private el: ElementRef,
        private readonly cdr: ChangeDetectorRef,
        private readonly accountingService: AccountingService,
        private readonly customerService: CustomerService,
    ) { }

    ngOnInit(): void {
        this.scrollEvent$
            .pipe(
                debounceTime(200),
                untilDestroyed(this),
            )
            .subscribe(() => {
                this.onScrollEnd();
            });
    }

    clearValue() {
        this.value = null;
        this.customerChange.emit(this.value);
    }

    /**
     * 
     * @param text$ 
     * @returns 
     */
    search = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(
            debounceTime(400),
            distinctUntilChanged()
        );

        const inputFocusWithClosedPopup$ = this.focus$.pipe(
            filter(() => (Boolean(this.options) || this.initialLoading || isNotEmpty(this.customers)) && !this.instance.isPopupOpen()),
            map(() => '')
        );

        return merge(debouncedText$, inputFocusWithClosedPopup$, this.loadMore$).pipe(
            tap((term: string) => this.onChangedTerm.emit(term)),
            switchMap(term => {
                if (this.options?.length > 0) {
                    let data: Partial<Customer>[] = [];
                    if (!term) {
                        data = this.options
                    } else {
                        data = this.options.filter((v) => {
                            return v.customerName?.toLowerCase().indexOf(term?.toLowerCase()) > -1 ||
                                v.phone?.toLowerCase().indexOf(term?.toLowerCase()) > -1 ||
                                v.firstEmail?.toLowerCase().indexOf(term?.toLowerCase()) > -1
                        })
                    }
                    return of(data);
                } else {
                    if (this.disableAutocomplete) {
                        return of([]);
                    }
                    this.loading = true;

                    this.filterRequest = {
                        ...this.filterRequest,
                        ...(this.filterRequest.SearchTerm !== term ? { Page: 1 } : {}),
                        SearchTerm: (typeof this.value === 'object') ? this.value?.customerName : term,
                    }


                    const request = this.getCustomers(this.filterRequest);

                    return from(request).pipe(
                        take(1),
                        map(({ list }) => {
                            return this.filterRequest.Page == 1 ? list : [...this.customers, ...list]
                        }),
                        tap((customers) => this.customers = customers),
                        finalize(() => this.loading = false),
                        catchError(() => of(this.customers)),
                    )
                }
            }),
            tap(() => {
                this.listenDropdownScroll();
            }),
            untilDestroyed(this)
        );
    }

    getCustomers(filter?: Partial<CustomerSearchRequest>) {
        let request = {
            ...this.filterRequest,
            ...filter,
        }
        if (this.type == 'accounting') {
            return this.accountingService.getAccountingCustomers(request)
        } else {
            this.customerService.cancelPendingRequestGetAll()
            return this.customerService.getAllWithCancelRequest(request)
        }
    }

    // Custom result formatter to display the item in the result
    formatter = (customer: Customer) => customer ? customer.customerName : null;

    onItemSelect($event) {
        $event.preventDefault();
        this.value = $event.item;
        this.customerChange.emit(this.value);
    }

    writeValue(value: string) {
        this._value = value;
        this.cdr.detectChanges();
    }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    isNotEmptyObject(obj: Record<string, any>): boolean {
        return obj && isNotEmpty(obj);
    }

    isLastItem(item: any) {
        return this.customers && this.customers.length && item === this.customers[this.customers.length - 1];
    }

    listenDropdownScroll() {
        // A slight delay to wait for the dropdown to be rendered
        setTimeout(() => {
            const dropdown = document.querySelector('ngb-typeahead-window.customer-select');
            if (dropdown) {
                this.renderer.listen(dropdown, 'scroll', this.checkScroll.bind(this));
            }
        }, 100);
    }

    checkScroll(event: any) {
        const dropdownElement = event.target;
        const offset = 50;
        if (dropdownElement.scrollTop + dropdownElement.clientHeight >= dropdownElement.scrollHeight - offset) {
            this.scrollEvent$.next(true);
        }
    }

    onScrollEnd() {
        this.filterRequest.Page++;
        this.loadMore$.next(this.value);
    }
}
