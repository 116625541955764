import { AfterViewInit, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgModel, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiPaginationData } from '@app/models/core/base';
import { Customer, CustomerSearchRequest } from '@app/models/customer.model';
import { OutsourceCompany } from '@app/models/outsource-company.model';
import { AccountingService } from '@app/shared/services/accounting.service';
import { CompanyService } from '@app/shared/services/company.service';
import { CustomerService } from '@app/shared/services/customer.service';
import { OutsourceCompanyService } from '@app/shared/services/outsource-company.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { from, of } from 'rxjs';
import { catchError, debounceTime, switchMap, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  standalone: false,
  selector: 'app-customer-dropdown',
  templateUrl: './customer-dropdown.component.html',
  styleUrls: ['./customer-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomerDropdownComponent),
      multi: true
    }
  ],
  //encapsulation: ViewEncapsulation.Emulated
})
export class CustomerDropdownComponent implements OnInit, AfterViewInit, OnDestroy {

  search: string;
  customers: Customer[] | OutsourceCompany[];
  searchingCustomer: boolean;
  disabled: boolean;
  hasMorePage: boolean = false;
  page: number = 1;

  @ViewChild('dropdown') dropdown: NgbDropdown;
  @ViewChild('searchInput') searchInput: NgModel;

  onChange = (value: Customer | OutsourceCompany) => { };
  onTouched = () => { };

  @Input() selectedTemplate: TemplateRef<any>;
  @Input() listItemTemplate: TemplateRef<any>;
  @Input() type: 'customer' | 'accounting' | 'outsourceCompany' = 'customer';
  @Input() dropDownType: 'normal' | 'custom' = 'normal';
  @Input() initialLoad: boolean;
  @Input() canCreate: boolean;
  @Input() placeholder: string;
  @Output() customerChange = new EventEmitter<any>();
  defaultTenantCompanyName: string;

  private _value: Customer | OutsourceCompany;
  get value(): Customer | OutsourceCompany {
    return this._value;
  }
  set value(updateValue: Customer | OutsourceCompany) {
    if (typeof updateValue === 'object') {
      this._value = updateValue;
    } else {
      this.getCustomerById(updateValue).then((obj) => {
        this._value = obj;
      })
    }

    this.onChange(updateValue);
    this.onTouched();
  }

  constructor(
    private accountingService: AccountingService,
    private companyService: CompanyService,
    private customerService: CustomerService,
    private outsourceCompanyService: OutsourceCompanyService,
    private router: Router,
  ) { }

  getPlaceholder() {
    const defaultPlaceholder = 'Select ' + (this.placeholder ?? 'Customer')
    if (this.type === 'outsourceCompany') {
      return this.defaultTenantCompanyName || defaultPlaceholder;
    }
    return defaultPlaceholder;
  }

  ngOnInit(): void {
    this.companyService.$selectedCompany
      .pipe(untilDestroyed(this))
      .subscribe((selectedCompany) => {
        this.defaultTenantCompanyName = selectedCompany?.companyName;
      })
  }

  createCustomer(): void {
    this.router.navigate(['/app/customers/add'], {
      queryParams: {
        returnUrl: this.router.url
      }
    })
  }

  ngAfterViewInit() {
    this.searchInput.valueChanges
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.searchingCustomer = true;
          this.customers = [];
        }),
        debounceTime(500),
        switchMap(term => {
          this.page = 1;
          const request = this.getCustomers({
            SearchTerm: term ?? []
          })
          return from(request).pipe(
            catchError(() => of([]))
          )
        }),
      ).subscribe((val) => {
        // nothing
      })

    if (this.initialLoad) {
      this.getCustomers()
    }
  }

  loadMoreCustomer() {
    this.page++;
    this.getCustomers()
  }

  async getCustomerById(id: string) {
    if (id) {
      return await this.customerService.get(id)
    } else {
      return null
    }
  }

  async getCustomers(filter?: Partial<CustomerSearchRequest>) {
    this.searchingCustomer = true
    let request = {
      Page: this.page,
      ItemsPerPage: 20,
      ...filter,
    }
    let resp: Promise<ApiPaginationData<Customer | OutsourceCompany>>;
    if (this.type == 'accounting') {
      resp = this.accountingService.getAccountingCustomers(request)
    } else if (this.type == 'outsourceCompany') {
      this.outsourceCompanyService.cancelPendingRequestGetAll()
      resp = this.outsourceCompanyService.getAllWithCancelRequest(request)
    } else {
      this.customerService.cancelPendingRequestGetAll()
      resp = this.customerService.getAllWithCancelRequest(request)
    }

    await resp.then((resp) => {
      const { list, totalCount, itemsPerPage } = resp;
      this.hasMorePage = totalCount > (this.page * itemsPerPage);
      if (this.page > 1) {
        this.customers = this.type !== "outsourceCompany" ? (this.customers as Customer[]).concat(list) : (this.customers as OutsourceCompany[]).concat(list);
      } else {
        this.customers = list;
      }
    }).finally(() => {

      this.searchingCustomer = false
    })
    return resp;
  }

  selectedCustomers(customer) {
    this.value = customer;
    this.dropdown.close();
    this.customerChange.emit(this.value);
  }

  writeValue(value: Customer | OutsourceCompany) {
    if (typeof value === 'object') {
      this._value = value
    } else {
      this.getCustomerById(value).then((data) => {
        this._value = data
      })
    }
  }

  registerOnChange(fn: (value: Customer | OutsourceCompany) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  clearValue(): void {
    this.value = null;
    this.customerChange.emit(this.value);
    this.getCustomers()
  }
  ngOnDestroy(): void {
    // nothing
  }

}
