import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { removeBinding } from '@app/@shared/utils';
import { stateOptions } from '@app/data/state';
import { Zone } from '@app/models/zone.model';
import { HelperService } from '@app/shared/services/helper.service';
import { ZoneService } from '@app/shared/services/zone.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { UtilService } from 'src/app/services/util.service';
import { number, object, string } from 'yup';

@Component({
  standalone: false,
  selector: 'app-zone-details',
  templateUrl: './zone-details.component.html',
  styleUrls: ['./zone-details.component.scss']
})

export class ZoneDetailsComponent implements OnInit {
  /*
	* Getter & Setter for Zone
	*/
	private _zone: Zone = {
    name: null,
    city: null,
    state: null,
    zipCodeRange: null,
  };
	get zone(): Zone {
		return this._zone;
	}
	@Input() set zone(value: Zone) {
		this._zone = removeBinding(value);
	}


  states = stateOptions;
  isSaving = false

  validationSchema = object().shape({
    name: string().nullable().required(),
    zipCodeRange: number().label('Zip Code').nullable().required(),
  });

  constructor(
    private zoneService: ZoneService,
    public ngbActiveModal: NgbActiveModal,
    public helperService: HelperService,
  ) { }

  ngOnInit() {
  }

  onSubmit(f) {
    if (f.isValid) {
      this.isSaving = true;
      if (this.zone?.zoneId && this.zone?.zoneId != null) {
        this.zoneService.update(this.zone?.zoneId, this.zone).then((res) => {
          this.helperService.successMessage("Zone successfully updated.");
          this.isSaving = false;
          this.ngbActiveModal.close(res);

        }).catch((error) => {
          this.helperService.errorMessage(error)
          this.isSaving = false;
        });
      } else {
        this.zoneService.create(this.zone).then((res) => {
          this.helperService.successMessage("Zone successfully created.");
          this.isSaving = false;
          this.ngbActiveModal.close(res);
        }).catch((error) => {
          this.helperService.errorMessage(error)
          this.isSaving = false;
        });
      }
    }
  }

  close() {
    this.ngbActiveModal.close();
  }



}
