import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HelperService } from '@app/shared/services/helper.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TermsAndConditionsService } from '@app/shared/services/terms-and-conditions.service';
import { object, string } from 'yup';
import { TermsAndCondition } from '@app/models/terms-and-condition.model';
import { slugify } from 'src/utils/slugify';


@UntilDestroy()
@Component({
    standalone: false,
    selector: 'app-add-terms-conditions-dialog',
    templateUrl: './add-terms-conditions-dialog.component.html',
    styleUrls: ['./add-terms-conditions-dialog.component.scss'],
})
export class AddTermsConditionsDialogComponent {

    request: TermsAndCondition = {
        name: "",
        content: " ", 
    }

    validationSchema = object().shape({
        name: string().nullable().required(),
    });

    constructor(
        public ngbActiveModal: NgbActiveModal,
        public helperService: HelperService,
        public termsAndConditionsService: TermsAndConditionsService,
    ) {
    }

    async save(f) {
        if (f.isValid){
            this.request.type = slugify(this.request.name);
            this.termsAndConditionsService.create(this.request).then((res) => {
                this.helperService.successMessage("Terms and Conditions successfully created")
                this.ngbActiveModal.close(res);
            }).catch((error) => {
                console.log(error)
                this.helperService.errorMessage(error);
            })
        } 
    }

    close() {
        this.ngbActiveModal.close();
    }

}
