import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  standalone: false,
  selector: 'app-copy-details',
  templateUrl: './copy-details.component.html',
  styleUrls: ['./copy-details.component.scss']
})
export class CopyDetailsComponent implements OnInit {
  @Input() title: string
  @Input() text: string
  @Input() messages: string[];

  isGroup?: boolean;

  content: any
  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.copyMessage(this.text);
    this.content = this.getMessage(this.text.split('\n'));
  }

  getMessage(message) {
    if (this.messages) {
      return this.messages;
    }
    let text = '';
    if (Array.isArray(message)) {
      message.forEach(item => {
        text += item + '<br>';
      });
    } else if (typeof message === 'object' && Array.isArray(message.messages)) {
      // This usually means that the entire error response object was sent in
      message.messages.forEach(item => {
        text += item + '<br>';
      });
    } else if (typeof message === 'object' && message !== null) {
      // This is usually a .net object validation
      Object.keys(message).forEach(item => {
        text += message[item] + '<br>';
      });
    } else {
      text = message;
    }
    return text;
  }

  copyMessage(text) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  GetValueWithComme(val, addComme = true) {
    if (val) {
      return val + (addComme ? ',' : '');
    }
    return '';
  }

  close() {
    this.activeModal.close();
  }

  onSubmit() {
    this.activeModal.close(true);
  }



}
