import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@app/@shared/datatable';

@Component({
  standalone: false,
  selector: 'app-driver-history',
  templateUrl: './driver-history.component.html',
  styleUrls: ['./driver-history.component.scss']
})
export class DriverHistoryComponent implements OnInit {
  title = 'Trip History'
  @ViewChild('datatable') datatable: DatatableComponent
  constructor() { }

  ngOnInit(): void {

  }


  getData = (request?: any) => {
    return null
  }

}
