import { Directive, Input, Output, HostListener, EventEmitter } from '@angular/core';

import { DialogService } from '../dialog.service';
import { ConfirmComponent, ConfirmData } from '../confirm/confirm.component';

@Directive({
    standalone: false,
    selector: '[confirmDialog]'
})
export class ConfirmDirective {

    data: ConfirmData = {
        yesText: 'Yes',
        noText: 'Cancel',
    };

    @Input() set message(value) {
        this.data.message = value;
    }

    @Input() set title(value) {
        this.data.title = value;
    }

    @Input() set yesText(value) {
        this.data.yesText = value;
    }

    @Input() set noText(value) {
        this.data.noText = value;
    }


    @Output() confirm = new EventEmitter<any>();
    @Output() decline = new EventEmitter<any>();

    constructor(private dialogService: DialogService) {

    }


    @HostListener('click', ['$event']) onClick($event) {
        $event.stopPropagation();
        this.data.callback = (result) => {
            const confirm = result ? true : false;
            if (confirm) {
                this.confirm.emit();
            } else {
                this.decline.emit();
            }
        };

        this.dialogService.open(ConfirmComponent, this.data, { size: 'sm' });
    }

}
