// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
}
:host .customer-list {
  display: flex;
  flex-direction: column;
}
:host .list-group {
  overflow: auto;
  flex: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/accounting/customer-list/customer-list.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;AACJ;AAEE;EACE,cAAA;EACA,OAAA;AAAJ","sourcesContent":[":host {\n  display: flex;\n  flex-direction: column;\n\n  .customer-list {\n    display: flex;\n    flex-direction: column;\n  }\n\n  .list-group {\n    overflow: auto;\n    flex: 1;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
