import { Component, Input, OnInit } from '@angular/core';
import { Order } from '@app/models/order.model';

@Component({
  standalone: false,
  selector: 'app-order-billing',
  templateUrl: './order-billing.component.html',
  styleUrls: ['./order-billing.component.scss']
})
export class OrderBillingComponent implements OnInit {

  @Input() order: Order;
  paymentMethod: string = "CreditCard"
  totalCost: number = 0;
  markupAmount: number = 0;
  subTotal: number = 0;
  discountAmount: number = 0;
  totalPrice: number = 0;

  constructor(
  ) { }

  ngOnInit(): void {
    if (this.order?.costLines?.length > 0) {
      this.totalCost = this.order?.costLines?.filter(line => !line?.isOverwritten)?.map(item => item?.totalCost)?.reduce((prev?, next?) => prev + next)
    }
    this.markupAmount = this.order?.markupType === 'Value' ? Number(this.order?.markupValue) : (this.totalCost * (this.order?.markupValue / 100));
    this.discountAmount = this.order?.discountType === 'Value' ? Number(this.order?.discountValue) : Number((this.totalCost + this.markupAmount) * (this.order?.discountValue / 100));
    this.totalPrice = Number(this.totalCost) + Number(this.markupAmount) - Number(this.discountAmount);
  }


}
