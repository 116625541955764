import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { OrderStopItemModule } from '../order-stop-item/order-stop-item.module';
import { FileUploadModule } from '@app/@shared/file-upload/file-upload.module';
import { PortStatusModule } from '@app/@shared/port-status/port-status.module';
import { CustomerSelectModule } from '@app/@shared/customer-select/customer-select.module';
import { CheckboxGroupModule } from '@app/@shared/checkbox-group/checkbox-group.module';
import { FormValidateModule } from '@app/@shared/form-validate/form-validate.module';
import { TypeaheadSelectModule } from '@app/@shared/typeahead-select/typeahead-select.module';
import { FormPhoneInputModule } from '@app/@shared/forms';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { EditOrderStopAddressDialogComponent } from './edit-order-stop-address-dialog.component';
import { PhoneExtensionInputModule } from '@app/@shared/phone-extension-input/phone-extension-input.module';


@NgModule({
  declarations: [
    EditOrderStopAddressDialogComponent
  ],
  exports: [
    EditOrderStopAddressDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    OrderStopItemModule,
    FileUploadModule,
    PortStatusModule,
    CustomerSelectModule,
    CheckboxGroupModule,
    FormValidateModule,
    TypeaheadSelectModule,
    FormPhoneInputModule,
    NgbDropdownModule,
    PhoneExtensionInputModule,
  ]
})
export class EditOrderStopAddressDialogModule { }