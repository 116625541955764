import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PricingModelService } from '@app/shared/services/pricing-model.service';
import { AddMileageChargeRequest, MileageCharge } from '@app/models/pricing-model.model';

@Component({
  selector: 'app-add-mileage-charge-modal',
  templateUrl: './add-mileage-charge-modal.component.html',
  styleUrls: ['./add-mileage-charge-modal.component.scss']
})
export class AddMileageChargeModalComponent implements OnInit {
  @Input() existingMileageCharges: MileageCharge[] = [];
  @Input() pricingModelId: number;

  request: AddMileageChargeRequest = {
    minMileage: null,
    maxMileage: null,
    priceForPallet1: null,
    priceForPallet2: null
  };

  minMileageError: string = '';
  maxMileageError: string = '';
  priceForPallet1Error: string = '';
  priceForPallet2Error: string = '';

  constructor(
    public activeModal: NgbActiveModal,
    private pricingModelService: PricingModelService
  ) { }

  ngOnInit() {
  }

  onSave() {
    this.minMileageError = '';
    this.maxMileageError = '';
    this.priceForPallet1Error = '';
    this.priceForPallet2Error = '';

    if (this.request.minMileage >= this.request.maxMileage) {
      this.minMileageError = "Min Mileage must be less than Max Mileage.";
      this.maxMileageError = "Max Mileage must be greater than Min Mileage.";
      return;
    }

    if (this.validateOverlap()) {
      return;
    }

    if (this.request.priceForPallet1 <= 0) {
      this.priceForPallet1Error = "Price must be greater than zero.";
      return;
    }

    if (this.request.priceForPallet2 <= 0) {
      this.priceForPallet2Error = "Price must be greater than zero.";
      return;
    }

    this.pricingModelService.addMileageCharge(this.pricingModelId, this.request)
      .then((updatedPricingModel) => {
        this.activeModal.close(updatedPricingModel);
      })
      .catch((error) => {
        if (error.error && error.error.Message) {
          this.minMileageError = error.error.Message;
        } else {
          this.minMileageError = "An error occurred while adding the mileage charge.";
        }
      });
  }

  onClose() {
    this.activeModal.dismiss('cancel');
  }

  validateOverlap(): boolean {
    let hasOverlap = false;

    for (const existing of this.existingMileageCharges) {
      if (this.request.minMileage >= existing.minMileage && this.request.minMileage <= existing.maxMileage ||
        this.request.minMileage <= existing.minMileage && this.request.maxMileage >= existing.maxMileage
      ) {
        this.minMileageError = "This mileage range overlaps with an existing range.";
        hasOverlap = true;
      }

      if (this.request.maxMileage >= existing.minMileage && this.request.maxMileage <= existing.maxMileage ||
        this.request.minMileage <= existing.minMileage && this.request.maxMileage >= existing.maxMileage
      ) {
        this.maxMileageError = "This mileage range overlaps with an existing range.";
        hasOverlap = true;
      }

      if (hasOverlap) {
        break;
      }
    }
    return hasOverlap;
  }
}
