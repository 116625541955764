// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: 100%;
  width: 100%;
}
:host .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

::ng-deep .pdf-viewer-modal .modal-content,
::ng-deep .pdf-viewer-modal .modal-body {
  padding: 0;
  background-color: transparent;
  position: unset;
}
::ng-deep .pdf-viewer-modal .modal-dialog {
  max-width: 90vw;
  position: unset;
}
::ng-deep .pdf-viewer-modal .modal-action-header {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  padding: 10px;
  z-index: 9;
}
::ng-deep .pdf-viewer-modal .modal-action-header .dialog-icon {
  font-size: 28px;
  cursor: pointer;
  margin-right: 10px;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/files-viewer-modal/pdf-viewer/pdf-viewer.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,WAAA;AACJ;AAAI;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;AAER;;AAEI;;EAEI,UAAA;EACA,6BAAA;EACA,eAAA;AACR;AAEI;EACI,eAAA;EACA,eAAA;AAAR;AAEI;EACI,kBAAA;EACA,MAAA;EACA,QAAA;EACA,aAAA;EACA,aAAA;EACA,UAAA;AAAR;AACQ;EACI,eAAA;EACA,eAAA;EACA,kBAAA;EACA,WAAA;AACZ","sourcesContent":[":host {\n    height: 100%;\n    width: 100%;\n    .modal-header {\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n    }\n}\n::ng-deep .pdf-viewer-modal {\n    .modal-content,\n    .modal-body {\n        padding: 0;\n        background-color: transparent;\n        position: unset;\n    }\n\n    .modal-dialog {\n        max-width: 90vw;\n        position: unset;\n    }\n    .modal-action-header {\n        position: absolute;\n        top: 0;\n        right: 0;\n        display: flex;\n        padding: 10px;\n        z-index: 9;\n        .dialog-icon {\n            font-size: 28px;\n            cursor: pointer;\n            margin-right: 10px;\n            color: #fff;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
