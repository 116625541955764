import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiPaginationResponse, ApiResponse, ListRequest } from '@app/models/core/base';
import { toParams } from 'src/utils/to-params';
import { Driver } from '@app/models/driver.model';
import { toFormData } from 'src/utils/to-form-data';
import { BehaviorSubject, firstValueFrom, map, Observable, Subject, takeUntil } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class DriverService {

    private basePath = 'Drivers'
    private cancelRequestGetAll$ = new Subject<void>();
    private readonly _refreshDriverList = new BehaviorSubject<void>(null);

    constructor(
        private http: HttpClient
    ) {

    }

    public get refreshDriverList$(): Observable<void> {
        return this._refreshDriverList.asObservable();
    }

    public refreshDriverList(): void {
        this._refreshDriverList.next(null);
    }

    uploadImage(request?: any) {
        return firstValueFrom(this.http.post('Images/upload', toFormData(request)))
    }

    get(id: string | number) {
        return firstValueFrom(this.http.get<ApiResponse<Driver>>(`${this.basePath}/${id}`))
            .then((({ data }) => data));
    }

    getAll(request?: ListRequest) {
        return firstValueFrom(
            this.http.get<ApiPaginationResponse<Driver>>(`${this.basePath}`, { params: toParams(request) })
                .pipe(
                    takeUntil(this.cancelRequestGetAll$),
                    map(({ data }) => data)
                )
        );
    }

    cancelPendingRequestGetAll() {
        // Call this method to cancel pending requests
        this.cancelRequestGetAll$.next();
    }

    create(request?: ListRequest) {
        return firstValueFrom(this.http.post<ApiResponse<Driver>>(`${this.basePath}`, request))
            .then((({ data }) => data));
    }

    update(id: string | number, request?: ListRequest) {
        return firstValueFrom(this.http.put<ApiResponse<Driver>>(`${this.basePath}/${id}`, request))
            .then((({ data }) => data));
    }

    delete(id: string | number) {
        return firstValueFrom(this.http.delete(`${this.basePath}/${id}`))
    }

    permanentDelete(id: string | number) {
        return firstValueFrom(this.http.delete(`${this.basePath}/Permanent/${id}`))
    }

    restore(id: string | number) {
        return firstValueFrom(this.http.put(`${this.basePath}/Restore/${id}`, {}))
    }

    bulkDelete(ids: any) {
        return firstValueFrom(this.http.delete(`${this.basePath}`, { body: ids }))
    }

}
