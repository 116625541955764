import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { RepeaterComponent } from '@app/@shared/repeater/repeater/repeater.component';
import { EntityTypes } from '@app/models/entity-type.model';
import { OrderItemStop } from '@app/models/order-item-stop.model';
import { OrderItem, OrderItemPackedInEnum } from '@app/models/order-item.model';
import { OrderStop, OrderStopTypeEnum } from '@app/models/order-stop.model';
import { Order } from '@app/models/order.model';
import { PickupTypeEnum } from '@app/models/pickup-type';
import { UtilService } from '@app/services/util.service';
import { HelperService } from '@app/shared/services/helper.service';
import { OrderService } from '@app/shared/services/order.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { chain, cloneDeep, filter, find, keyBy, round, some, sumBy } from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@UntilDestroy()
@Component({
	selector: 'app-order-stop-item',
	templateUrl: './order-stop-item.component.html',
	styleUrls: ['./order-stop-item.component.scss'],
	host: {
		'class': 'card order-item-stop-card'
	}
})
export class OrderStopItemComponent implements OnInit {
	/**
	 * Repeater Component
	 */
	@ViewChild('repeater', { static: false }) repeater: RepeaterComponent;

	private _orderItemStop: OrderItemStop = {};
	EntityTypes = EntityTypes;

	deliveries: OrderStop[];
	orderItemStops: OrderItemStop[] = [];
	orderItem: OrderItem;
	updates$ = new Subject();


	maxQuantity: number;

	orderItemStopDefaultValue = {
		quantity: this.orderItemStop.quantity,
		orderStopId: 0
	}

	@Input() isQuantityButtonDisabled: boolean = false;
	@Input() isDisplayUnallocatedQty: boolean = false;
	@Input() attachmentIcon: boolean = false;
	@Input() selectDelivery: boolean = true;
	@Input() type: 'pickup' | 'delivery' = 'pickup';

	deliveryPickupAddress: string;

	/*
	* Getter & Setter for ORDER
	*/
	private _order: Order;
	unallocatedQty: number;
	isDeliveryItemsCollapse: boolean = true;

	get order(): Order {
		return this._order;
	}

	@Input() set order(value: Order) {
		//this._order = cloneDeep(value);
		this._order = value;
		this.setDeliveries();
	}

	/*
	* Getter & Setter for ORDER STOP
	*/
	private _orderStop: OrderStop;

	public get orderStop(): OrderStop {
		return this._orderStop;
	}

	@Input() public set orderStop(value: OrderStop) {
		this._orderStop = value;
	}

	/*
	* Getter & Setter for ORDER ITEM STOP
	*/
	public get orderItemStop(): OrderItemStop {
		return this._orderItemStop;
	}

	@Input() public set orderItemStop(value: OrderItemStop) {
		this._orderItemStop = value;
		this.orderItem = value.orderItem;
		this.orderItemStopDefaultValue.quantity = this.orderItemStop.quantity;
		this.unallocatedQty = this.orderItemStop.maxQuantity - this.orderItemStop.quantity;
		this.setOrderItemStops();
	}

	@Output() onChange = new EventEmitter<OrderItemStop>()

	constructor(
		private readonly orderService: OrderService,
		private readonly helperService: HelperService,
		public util: UtilService,
	) { }


	get orderItemTags() {
		const tags = []
		if (this.orderItem?.isFragile) {
			tags.push('Fragile')
		}
		if (this.orderItem?.isHazardous) {
			tags.push('Hazardous')
		}
		if (this.orderItem?.isRefrigerated) {
			tags.push('Refrigerated')
		}
		if (this.orderItem?.isOverweight) {
			tags.push('Overweight')
		}
		if (this.orderItem?.isOther) {
			tags.push('Other')
		}
		return tags;
	}

	get boxSize() {
		return filter([this.orderItem?.formatedMeasurements || this.orderItem?.sizeTypeName, this.getItemsPackedIn(), this.getFullLoadPalletSize(), this.getOrderItemWeight()]).join(' | ')
	}

	orderItemTypeImg(orderItemType: PickupTypeEnum) {
		switch (orderItemType) {
			case PickupTypeEnum.BOXES_CRATES:
				return '/assets/images/b_and_c.png'
			case PickupTypeEnum.PALLETS:
				return '/assets/images/pallet.png'
			case PickupTypeEnum.FULL_LOAD:
				return '/assets/images/full_load.png'
			case PickupTypeEnum.OTHER:
				return '/assets/images/pickup-type-otherhand.png'
			default:
				return '/assets/images/b_and_c.png'
		}
	}

	getItemsPackedIn() {	
		if (this.orderItem?.orderItemType === PickupTypeEnum.FULL_LOAD) {			
			if (this.orderItem?.itemsPackedIn === OrderItemPackedInEnum.BOX_AND_CRATES) {
				return "Boxes & Crates"
			} else if (this.orderItem?.itemsPackedIn === OrderItemPackedInEnum.BOXES) {
				return "Boxes"
			} else if (this.orderItem?.itemsPackedIn === OrderItemPackedInEnum.PALLETS) {
				return "Pallets"
			}
		}
	}

	getFullLoadPalletSize() {
		if (this.orderItem?.orderItemType === PickupTypeEnum.FULL_LOAD && this.orderItem?.fullLoadPalletSize) {
			return this.util?.sizeTypes.get(this.orderItem?.fullLoadPalletSize)?.text;
		}
	}

	getOrderItemWeight() {
		if (this.orderItem?.wight) {
			const weight = this.orderItem?.wight;
			const qty = this.orderItemStop?.quantity;
			const totalQty = this.orderService.getTotalQuantity(this.order, this.orderItem?.orderItemId);
			const formattedUnitOfMeasure = this.orderItem?.formattedUnitOfMeasure || 'kg'
			if (qty > 0 && totalQty > 0 && weight > 0) {
				return `${round(weight / totalQty * qty, 2)} ${formattedUnitOfMeasure}`;
			} else {
				return `${weight} ${formattedUnitOfMeasure}`;
			}
		}
	}

	ngOnInit(): void {
		if (this.orderStop.orderStopType === OrderStopTypeEnum.DELIVERY) {
			this.maxQuantity = this.orderService.getMaxQuantityToPick(this.order, this.orderItem.orderItemId, this.orderStop.orderStopId);
		} else {
			this.maxQuantity = null
		}
		this.updates$.pipe(
			untilDestroyed(this),
			debounceTime(500)
		).subscribe(() => {
			if (this.orderItemStop.quantity == 0) {
				this.orderItemStop.isActive = false
			}
			this.onChange.emit(this.orderItemStop);
		})

		if (!this.selectDelivery) {
			const pickOrderStops = filter(this.order.orderStops, { orderStopType: OrderStopTypeEnum.PICKUP })
			if (pickOrderStops?.length > 0) {
				const addressPickupStop = find(pickOrderStops, (stop) => {
					return some(stop.orderItemStops, { orderItemId: this.orderItem?.orderItemId })
				})
				this.deliveryPickupAddress = addressPickupStop?.address?.fullAddress
			}
		}
	}

	toggleDeliveryItemsCollapse() {
		this.isDeliveryItemsCollapse = !this.isDeliveryItemsCollapse;
	}

	handleQtyChange(qty) {
		try {
			if (this.isDisplayUnallocatedQty && this.orderItemStop?.maxQuantity >= qty) {
				this.unallocatedQty = this.orderItemStop?.maxQuantity - qty;
			}
			this.orderService.updateOrderItemStopQuantity(this.order, this.orderItemStop, qty);
			this.updates$.next(true);
		} catch (error) {
			console.log(error);
			this.helperService.errorMessage(error)
		}



		// const diff = qty - this.orderItemStop.quantity;

		// if (diff < 0 && this.type === 'pickup') {
		// 	const deliveries = this.orderStop.orderItemStops // .filter(({ orderStop }) => orderStop.orderStopType === OrderStopTypeEnum.DELIVERY);
		// 	// console.log(this.orderStop.orderItemStops);
		// 	if (deliveries?.length > 0) {

		// 		//	console.log('new qty', deliveries[0].quantity, diff, { deliveries, diff, type: this.type });

		// 		// this.orderService.updateQtyToOrderStop(
		// 		// 	this.order,
		// 		// 	deliveries[0].orderStop.orderStopId,
		// 		// 	this.orderItemStop.orderItem.orderItemId,
		// 		// 	deliveries[0].quantity + diff
		// 		// );
		// 	}

		//	this.orderService.updateOrderItemStopQuantity(this.order, delivery, delivery.quantity + diff);

		// }
	}

	setDeliveries() {
		this.deliveries = this.order.orderStops.filter(
			(stop: OrderStop) => stop.isActive
		).filter(
			(stop) => stop.orderStopType === OrderStopTypeEnum.DELIVERY
		);
	}

	setOrderItemStops() {

		this.setDeliveries();
		const orderStopById = keyBy(this.deliveries, 'orderStopId');
		this.orderItemStops = chain(this.order.orderStops)
			.filter((orderStop) => {
				return orderStop.isActive && orderStop.orderStopType === OrderStopTypeEnum.DELIVERY
			})
			.map((orderStop) => {
				return orderStop.orderItemStops.filter((({ orderItem }) => orderItem.orderItemId === this.orderItem.orderItemId))
			})
			.flatten()
			.map((item) => {
				item.orderStop.address = orderStopById[item.orderStop.orderStopId]?.address
				return item;
			})
			.value();
	}

	changeItemQty(qty: any, orderStopItem: OrderItemStop) {
		if (orderStopItem.orderStop?.orderStopId) {
			try {
				this.orderService.updateOrderItemStopQuantity(this.order, orderStopItem, qty);
				this.updates$.next(true);
			} catch (error) {
				this.helperService.errorMessage(error)
			}
			// await this.orderService.saveOrder(this.order);
		} else if (qty == 0 && this.orderItemStops?.length > 1) {
			const index = this.orderItemStops.indexOf(orderStopItem);
			this.repeater.remove(index);
		}
	}

	/**
	 * Move order stop quantity
	 * 
	 * @param event 
	 * @param orderItemStop 
	 */
	async moveQty(event: any, orderItemStop: any) {
		this.helperService.isLoading = true;
		try {
			const newStopId = event.target.value;
			const qty = orderItemStop.quantity;
			if (orderItemStop?.orderStopId) {
				this.orderService.updateQtyToOrderStop(
					this.order,
					orderItemStop.orderStopId,
					this.orderItemStop.orderItem.orderItemId,
					0
				);
			}
			this.orderService.creteOrderStopItemIfNotExists(this.order, newStopId, this.orderItemStop)
			this.orderService.addQtyToOrderStop(this.order, newStopId, this.orderItemStop.orderItem.orderItemId, qty);
			await this.orderService.saveOrder(this.order);
		} catch (error) {
			console.log('Error while move quantity for order stop item', error);
			this.helperService.errorMessage(error);
		} finally {
			this.helperService.isLoading = false;
		}
	}

	/**
	 * Show / Hide Button
	 */
	showAddButton(): boolean {
		const totalQuantity = this.orderItemStop.quantity;
		return this.calculateTotalQuantity() < totalQuantity;
	}

	/**
	 * Calculate occupied delivery quantity SUM
	 */
	calculateTotalQuantity(): number {
		return sumBy(this.orderItemStops, (item) => item.orderStopId !== 0 ? item.quantity : 0)
	}

	/**
	 * Get maximum quantity allowed for order stop items
	 * 
	 * @returns 
	 */
	getRemainingQty(): number {
		return (this.orderItemStop.quantity) - this.calculateTotalQuantity();
	}


	/**
	 * Get maximum quantity for order stop item
	 */
	getMaximumQuantity(item: OrderItemStop): number {
		return Math.max(this.getRemainingQty() + item.quantity, item.quantity);
	}


	addDeliveryItem() {
		if (this.deliveries?.length > this.orderItemStops?.length) {
			this.repeater.defaultValue = {
				orderStopId: 0,
				quantity: this.getRemainingQty()
			}
			this.repeater.push();
		}
	}
}                 
