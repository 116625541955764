import { Component, OnInit } from '@angular/core';
import { plans } from '@app/data/plan';
@Component({
    standalone: false,
    selector: 'app-plan',
    templateUrl: './plan.component.html',
    styleUrls: ['./plan.component.scss']
})

export class PlanComponent implements OnInit {
    request: any = {
        subscriptionPlanId: 2,
    };
    selectPlan: any;
    planDetails = plans;

    constructor() {
    }
    
    ngOnInit() {
    }

    setPlan(id) {
        if (id != 0) {
            this.request.subscriptionPlanId = id;
            this.selectPlan = this.planDetails.find(res => res.subscriptionPlanId == this.request.subscriptionPlanId)
        }
        else {
            delete this.request.subscriptionPlanId;
        }
    }


}
