import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, NgForm } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { capitalize, get, lowerCase } from 'lodash';
import { FormValidateDirective } from '../form-validate.directive';

@UntilDestroy()
@Component({
  standalone: false,
  selector: 'app-form-input-error',
  templateUrl: './form-input-error.component.html',
  styleUrls: ['./form-input-error.component.scss'],
  providers: [{ provide: ControlContainer, useExisting: FormValidateDirective }]
})
export class FormInputErrorComponent implements OnInit{

  @Input() name
  @Input() textClass = null // For Font size to set default class

  errors: string[]
  ngForm: NgForm;


  constructor(private formValidateDirective: FormValidateDirective) { }

  ngOnInit(): void {

    this.ngForm = this.formValidateDirective.ngForm

    this.formValidateDirective.errors$
      .pipe(untilDestroyed(this))
      .subscribe((errors) => {
        this.errors = get(errors, this.name, [])
      })

  }

  capitalize(str) {
    return capitalize(lowerCase(str));
}

  get control(){
    return this.ngForm?.controls[this.name] as FormControl<any>
  }

}
