import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { stateOptions } from '@app/data/state';
import { Address, Country } from '@app/models/address.model';
import { Customer, PipelineEnum } from '@app/models/customer.model';
import { EntityType } from '@app/models/entity-type.model';
import { AddressService } from '@app/shared/services/address.service';
import { HelperService } from '@app/shared/services/helper.service';
import { find } from 'lodash';


export interface AddressItem extends Address {
  isView?: boolean
  isEdit?: boolean
  isDelete?: boolean
}

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss'],
})
export class LocationsComponent implements OnInit {

  PipelineEnum = PipelineEnum;
  private _customer: Customer;
  editingLocation = null;
  states = stateOptions;
  locations: AddressItem[] = [];
  countries: Country[];

  @Input()
  public get customer(): Customer {
    return this._customer;
  }
  public set customer(value: Customer) {
    this._customer = value;
    this.locations = value.addresses
  }

  @Output() customerChange = new EventEmitter()

  constructor(
    private addressService: AddressService,
    private helperService: HelperService,
  ) {

  }

  ngOnInit(): void {
    this.addressService.getCountries().then(res => {
			this.countries = res;
		}).catch((error) => {
			this.countries = [];
			this.helperService.errorMessage(error)
		})
  }

  addLocation() {
    var entityType = this.customer.addresses?.some(x => x.entityType == EntityType.Customer) ? EntityType.OrderStop : EntityType.Customer;

    this.locations.splice(0, 0, {
      isView: true,
      isEdit: true,
      state: null,
      isActive: true,
      entityType: entityType,
      customerId: this.customer.customerId,
      pipeline: PipelineEnum.PROSPECT,
    })
  }

  cancelViewLocation(index) {
    const address = this.locations[index];
    if (address && address.addressId) {
      address.isView = false;
      address.isEdit = false;
      const oldAddress = find(this.customer.addresses, { addressId: address.addressId })
      this.locations.splice(index, 1, oldAddress);
    } else {
      this.locations.splice(index, 1);
    }
  }

  viewLocation(index) {
    this.locations[index].isView = true
  }

  saveLocation(index) {
    const location = this.locations[index];

    if (location && location?.addressId) {
      this.addressService.update(location.addressId, location)
        .then((data) => {
          location.isView = false;
          location.isEdit = false;
          this.locations.splice(index, 1, data);
          this.customerChange.emit(true);
        }).catch((error) => {
          this.helperService.errorMessage(error)
        })
    } else {
      this.addressService.create(location)
        .then((data) => {
          location.isView = false;
          location.isEdit = false;
          this.locations.splice(index, 1, data);
          this.customerChange.emit(true);
        }).catch((error) => {
          this.helperService.errorMessage(error)
        })
    }
  }

  deleteLocation(index) {
    const location = this.locations[index]
    if (location) {
      this.addressService.delete(location.addressId)
        .then(() => {
          this.locations.splice(index, 1);
          this.customerChange.emit(true);
        }).catch((error) => {
          this.helperService.errorMessage(error)
        })
    } else {
      this.locations.splice(index, 1);
    }

  }

}