import { Component } from '@angular/core';
import { HelperService } from '@app/shared/services/helper.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import moment from "moment"

export interface AttemptDialogResult {
    isAttempt: boolean;
    reason?: string;
    ReAttemptDate?: Date;
}

@Component({
    standalone: false,
    selector: 'app-attempt-dialog',
    templateUrl: './attempt-dialog.component.html',
    styleUrls: ['./attempt-dialog.component.scss']
})
export class AttemptDialogComponent {
    attemptReasonShown = false;
    attemptReason = '';
    reattemptDate = null;
    startDateTime: string;
    endDateTime: string;
    waitingTime: number;

    constructor(
        public ngbActiveModal: NgbActiveModal,
        private helperService: HelperService,
    ) {
    }

    calculateTimeDifference(): number {
        if (this.startDateTime && this.endDateTime) {
            const arrival = moment(this.startDateTime);
            const departure = moment(this.endDateTime);
            const diff = moment.duration(departure.diff(arrival));
            if (diff.asMilliseconds() > 0) {
                return diff.asMilliseconds()
            }
        }
        return 0
    }

    handleChangeDateTime($event: any): void {
        this.waitingTime = this.calculateTimeDifference()
    }

    waitingTimeValidationCheck(): boolean {
        const calculatedDiff = this.calculateTimeDifference();
        if (typeof this.waitingTime !== 'number' || calculatedDiff < this.waitingTime) {
            this.helperService.errorMessage("The Waiting time cannot exceed the time between start datetime and end datetime. Please enter a value less than or equal to the available date times.");
            return false
        }
        return true
    }

    close() {
        this.ngbActiveModal.close();
    }

    noClicked() {
        this.ngbActiveModal.close({ isConfirmed: true, value: { isAttempt: false } })
    }

    yesClicked() {
        this.attemptReasonShown = true;
    }

    cancelClicked() {
        this.ngbActiveModal.close({ isConfirmed: false })
    }

    get isValid(): boolean {
        return this.attemptReason.trim() === '';
    }

    okClicked() {
        const isValid = this.waitingTimeValidationCheck()
        if (isValid) {
            this.ngbActiveModal.close({
                isConfirmed: true,
                value: { isAttempt: true, reason: this.attemptReason, ReAttemptDate: this.reattemptDate, waitingTime: this.waitingTime / 1000 }
            })
        }
    }
}
