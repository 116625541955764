import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { Observable } from 'rxjs/internal/Observable';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DataApiResponse, handleApiError, ListResponse } from '@app/helpers/api';
import { environment } from '@environments/environment';
import { catchError, map } from 'rxjs/operators';
import { UtilService } from '@app/services/util.service';
import { RouteBoxGroup } from '@models/route-box-group.model';
import { Route } from '@models/route.model';
import { RouteItemDisplayStatus, RouteItemStop } from '@models/route-item-stop.model';
import { RouteBoxUIGroup } from '@app/helpers/route-box-ui-group';
import { Truck } from '@models/truck.model';
import { RouteBox } from '@models/route-box.model';
import { AttemptDialogResult } from '@app/@shared/unassigned-trips/attempt-dialog/attempt-dialog.component';

export enum RouteCommand {
    Toggle,
    SetupMap,
    ScrollLeft,
    ScrollRight,
}

@Injectable({ providedIn: 'root' })
export class RouteService {
    private routesTrigger: Subject<RouteCommand> = new Subject<RouteCommand>();
    private reloadUnassignedSubject = new Subject<boolean>();
    private reloadRoutesSubject = new Subject<boolean>();
    private editRouteSubject = new Subject<Route>();
    private assignStopToRouteSubject = new Subject<{ routeId: number, stop: RouteBoxUIGroup }>();
    private assignStopsToRouteSubject = new Subject<{ routeId: number, stop: RouteBoxUIGroup[] }>();
    private currentTruckSubject = new Subject<Truck>();
    private currentRouteBoxSubject = new Subject<RouteBox>();

    private unassignedStopsSubject = new BehaviorSubject<RouteBoxGroup[]>([]);

    constructor(
        private http: HttpClient,
        private util: UtilService,
    ) {
    }

    get currentTruckSubject$(): Observable<Truck> {
        return this.currentTruckSubject.asObservable();
    }

    get currentRouteBoxSubject$(): Observable<RouteBox> {
        return this.currentRouteBoxSubject.asObservable();
    }

    setCurrentTruck(truck: Truck) {
        this.currentTruckSubject.next(truck);
    }

    setCurrentRouteBox(routeBox: RouteBox) {
        this.currentRouteBoxSubject.next(routeBox);
    }

    get stopToRouteAssigned$(): Observable<{ routeId: number, stop: RouteBoxUIGroup }> {
        return this.assignStopToRouteSubject.asObservable();
    }

    get stopsToRouteAssigned$(): Observable<{ routeId: number, stop: RouteBoxUIGroup[] }> {
        return this.assignStopsToRouteSubject.asObservable();
    }

    assignStopToRoute(routeId: number, stop: RouteBoxUIGroup) {
        this.assignStopToRouteSubject.next({ routeId, stop });
    }

    assignStopsToRoute(routeId: number, stop: RouteBoxUIGroup[]) {
        this.assignStopsToRouteSubject.next({ routeId, stop });
    }

    get unassignedStops$(): Observable<RouteBoxGroup[]> {
        return this.unassignedStopsSubject.asObservable();
    }

    get routesTriggered$(): Observable<RouteCommand> {
        return this.routesTrigger.asObservable();
    }

    get reloadUnassigned$(): Observable<boolean> {
        return this.reloadUnassignedSubject.asObservable();
    }

    get reloadRoutes$(): Observable<boolean> {
        return this.reloadRoutesSubject.asObservable();
    }

    get editRoute$(): Observable<Route> {
        return this.editRouteSubject.asObservable();
    }

    public editRoute(route: Route) {
        this.editRouteSubject.next(route);
    }

    public sendRouteCommand(routeCommand: RouteCommand) {
        this.routesTrigger.next(routeCommand);
    }

    public reloadUnassigned() {
        this.reloadUnassignedSubject.next(true);
    }

    public reloadRoutes() {
        this.reloadRoutesSubject.next(true);
    }

    getUnassignedRouteItems(search: { StopType?: string; }) {
        return this.http.get<DataApiResponse<RouteBoxGroup[]>>(
            this.util.getUrlWithParams(`${environment.apiBaseUrl}Routes/unassigned`, search)
        ).pipe(map(data => {
            data.data.forEach(rbg => {
                delete rbg.pickupRouteBox?.orderItem?.orderItemStops;
                delete rbg.dropOffRouteBox?.orderItem?.orderItemStops;
            })
            if (search.StopType == null) {
                this.unassignedStopsSubject.next(data.data);
            }
            return data.data;
        }),
            catchError(handleApiError));
    }

    moveToUnassigned(stopGroups: RouteBoxGroup[], attempt: AttemptDialogResult) {
        return this.http.put<DataApiResponse<any>>(
            `${environment.apiBaseUrl}Routes/MoveToUnassigned`, { attempt, stopGroups }
        ).pipe(map(data => {
            return data.data;
        }),
            catchError(handleApiError));
    }

    getRoutesWithItems(search) {
        return this.http.get<DataApiResponse<ListResponse<Route[]>>>(
            this.util.getUrlWithParams(`${environment.apiBaseUrl}Routes/routesWithItems`, search)
        ).pipe(map(data => {
            for (const route of data.data.list) {
                let index = 1;
                for (const group of route.routeStopGroups) {
                    for (const stop of group.routeItemStops) {
                        stop.index = index;
                        index++;
                    }
                }
            }
            return data.data;
        }),
            catchError(handleApiError));

    }

    getActiveRoutes() {
        return this.http.get<DataApiResponse<Route[]>>(
            `${environment.apiBaseUrl}Routes/active`,
        ).pipe(map(data => {
            return data.data;
        }),
            catchError(handleApiError));
    }

    processNewPickupStop(stop, items, selectedRoute: Route) {
        // find group to add this stop to
        let group = selectedRoute.routeStopGroups.find(x => x.date === stop.date);
        if (!group) {
            group = {
                date: stop.date,
                routeItemStops: []
            }
            selectedRoute.routeStopGroups.push(group);
        }

        // create route item group (its used to group by order)
        const routeItemStop: RouteItemStop = {
            routeItems: [],
            // for front end (until refresh)
            type: stop.stopType,
            displayStatus: RouteItemDisplayStatus.OnTime,
            toPickUp: stop.stopType === 'Pickup',
            dateType: stop.dateType,
            date: stop.date,
            timeType: stop.timeType,
            time: stop.time,
            address: {
                fullAddress: stop.fullAddress
            }
        };

        // add all selected items
        items.forEach(item => {
            routeItemStop.routeItems.push({
                orderItemStop: {
                    orderItemStopId: (stop.stopType === 'Pickup' ? item.routePickupOrderItemStopId : item.routeDeliveryOrderItemStopId)
                },
                date: stop.date,
                time: stop.time,
                quantity: item.pickQuantity,
                orderItem: item
            });
        });
        group.routeItemStops.push(routeItemStop);
    }
}
