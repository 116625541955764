import { Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
@Component({
  	standalone: false,
	selector: 'app-pdf-viewer',
	templateUrl: './pdf-viewer.component.html',
	styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit, OnChanges {

	readonly ZOOM_STEP = 0.25;
	readonly MAX_ZOOM = 5;
	readonly MIN_ZOOM = 0.5;

	zoomLevel = 1;
	fileUrl: string;

	@Input() fileData: Blob | string;
	@Input() height: string = 'calc(76vh - 0px)';

	constructor(
		public activeModal: NgbActiveModal,
		private modalService: NgbModal
	) { }

	ngOnInit(): void {
		console.log('PdfViewerModalComponent');
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['fileData']) {
			const file = changes['fileData'].currentValue;
			if (file instanceof Blob) {
				this.convertBlobToBase64(file).then(base64 => {
					this.fileUrl = base64;
				});
			} else {
				this.fileUrl = file;
			}
		}
	}

	async convertBlobToBase64(blob: Blob): Promise<string> {
		let binary = '';
		const uint8Array = new Uint8Array(await blob.arrayBuffer());
		const len = uint8Array.byteLength;
		for (let i = 0; i < len; i++) {
			binary += String.fromCharCode(uint8Array[i]);
		}
		return `data:application/pdf;base64,` + btoa(binary);
	}

	zoomIn() {
		this.zoomLevel = Math.min(this.zoomLevel + this.ZOOM_STEP, this.MAX_ZOOM);
	}

	zoomOut() {
		this.zoomLevel = Math.max(this.zoomLevel - this.ZOOM_STEP, this.MIN_ZOOM);
	}

	resetZoom() {
		this.zoomLevel = 1;
	}

	openModal(content: TemplateRef<any>) {
		this.zoomLevel = 1;
		 this.modalService.open(content, {
			ariaLabelledBy: 'modal-basic-title',
			centered: true,
			windowClass: `pdf-viewer-modal`
		})
	}
}