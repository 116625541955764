import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { NgModel } from '@angular/forms';
import { FormValidateDirective } from '@app/@shared/form-validate/form-validate.directive';
import { customerEmailTypes } from '@app/data/customer';
import { stateOptions } from '@app/data/state';
import { Country } from '@app/models/address.model';
import { Customer, PipelineEnum, SourceEnum } from '@app/models/customer.model';
import { ImageUploadTypeEnum } from '@app/models/image.model';
import { AddressService } from '@app/shared/services/address.service';
import { CustomerService } from '@app/shared/services/customer.service';
import { HelperService } from '@app/shared/services/helper.service';
import { SettingService } from '@app/shared/services/setting.service';
import { cloneDeep, orderBy } from 'lodash';
import { ValidationError, array, boolean, object, string } from 'yup';

@Component({
  standalone: false,
  selector: 'app-company-information',
  templateUrl: './company-information.component.html',
  styleUrls: ['./company-information.component.scss']
})
export class CompanyInformationComponent implements OnInit {

  defaultEmailItem = {
    isActive: true,
    emailType: null,
    email: '',
  }

  @ViewChildren(NgModel) inputControls!: QueryList<NgModel>;
  isEdit: boolean = false

  private _customer: Customer;
  customerEmailTypes = customerEmailTypes;
  states = stateOptions;
  users: any[];
  ImageUploadTypeEnum = ImageUploadTypeEnum;
  PipelineEnum = PipelineEnum;
  SourceEnum = SourceEnum;
  isEmailItemsCollapse: boolean = true;
  isSubmitting = false;

  validationSchema = object().shape({
    companyName: string()
      .max(256)
      .when([], {
        is: () => this.isEdit,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable()
      }),
    companyNumber: string().when([], {
      is: () => this.isEdit,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable()
    }),
    customerEmails: array().of(
      object().shape({
        email: string().label('Email').email().max(256).when([], {
          is: () => this.isEdit,
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.nullable()
        }),
        emailType: string().nullable(),
        isActive: boolean().default(true),
      })
    ).compact() // Removes null/undefined values before processing
      .test('unique-emails', 'Emails must be unique', function (arr) {
        if (!arr || arr.length === 0) return true;

        const emailSet = new Map();
        let errors: ValidationError[] = [];

        arr.forEach((obj, index) => {
          if (obj.isActive && obj.email) {
            const emailLower = obj.email.toLowerCase();
            if (emailSet.has(emailLower)) {
              errors.push(
                this.createError({
                  message: 'Email is duplicated',
                  path: `customerEmails[${index}].email`,
                })
              );
            } else {
              emailSet.set(emailLower, true);
            }
          }
        });

        if (errors.length > 0) {
          return new ValidationError(errors);
        }
        return true;
      }),
    addressDetails: string().nullable(),
    companyLocation: string().label('Company Location').nullable().max(256),
    city: string().label('City').nullable().max(256),
    zip: string().label('Zip Code').label("Zip code").nullable().max(32),
  });

  countries: Country[];

  @Output() customerChange = new EventEmitter()
  initialCustomer: Customer;

  @Input()
  public get customer(): Customer {
    return this._customer;
  }
  public set customer(value: Customer) {
    this._customer = cloneDeep(value);
    this.initialCustomer = cloneDeep(value);
    if (!this._customer.customerEmails) {
      this._customer.customerEmails = []
    } else {
      this._customer.customerEmails = this._customer.customerEmails?.filter((res) => res.isActive === true);
    }
    if (!this._customer.mainAddress) {
      this._customer.mainAddress = {}
    }
    if (!this._customer.addresses) {
      this._customer.addresses = [{ entityType: 1, name: 'Main' }]
    }
    if (!this._customer.paymentTermsInDays) {
      this._customer.paymentTermsInDays = 0;
    }
  }

  constructor(
    private settingService: SettingService,
    private addressService: AddressService,
    public customerService: CustomerService,
    public helperService: HelperService,
  ) {
  }


  ngOnInit(): void {

    this.addressService.getCountries().then(res => {
			this.countries = res;
		}).catch((error) => {
      this.countries = [];
			this.helperService.errorMessage(error)
		})

    this.settingService.getUsers().then(data => {
      this.users = orderBy(data?.list, [user => user?.firstName?.toLowerCase()], ['asc']);
    }).catch(error => {

    });
  }

  mainAddress(): any {
    const mainAddress = this.customer?.addresses?.find(x => x?.entityType === 1);

    if (!mainAddress) {
      const newAddress = {
        entityType: 1,
        name: `${this.customer.customerName} Main`,
        addressLine1: null,
        city: null,
        state: null,
        zip: null,
        addressDetails: null,
        customerId: this.customer?.customerId
      };

      if (!this.customer?.addresses) {
        this.customer.addresses = [];
      }

      this.customer.addresses.push(newAddress);
      return newAddress;
    } else {
      return mainAddress;
    }
  }

  deleteCustomerEmail(item) {
    item.isActive = false;
  }

  discardChanges(form: FormValidateDirective) {
    this.isEdit = false
    this.isEmailItemsCollapse = true;
    this.customer = cloneDeep(this.initialCustomer)
    form.reset(this.customer)
  }

  editCompanyInformation() {
    this.isEdit = true;
    this.isEmailItemsCollapse = false;
  }

  toggleEmailItemsCollapse() {
		this.isEmailItemsCollapse = !this.isEmailItemsCollapse;
	}

  async handleSubmit(form:FormValidateDirective) {
    if (form.isValid && this.isEdit) {
      this.isSubmitting = true;
      form.errors = {};

      const request = {
        ...this.customer,
        customerEmails: this.customer?.customerEmails.filter((email)=> email?.isActive) || []
      }
      const res = await this.customerService.validateCustomer(request);
      if (res.existedName)
        form.errors['companyName'] = ["Company name is not unique"]
      if (res.existedPhone)
        form.errors['companyPhone'] = ["Company phone is not unique"]
      if (res.existedEmailIndex?.length > 0) {
        form.errors['customerEmails'] = [];
        for (const i of res.existedEmailIndex) {
          form.errors['customerEmails'][i] = { email: ["Email is not unique"] }
        }
      }

      if (Object.keys(form.errors).length > 0) {
        this.isSubmitting = false;
        form.errors$.next(form.errors);
        this.inputControls.forEach(control => control.control.markAsTouched());
      } else {
        try {
          this.customerChange.emit(this.customer)
          this.isEdit = false;
          this.isEmailItemsCollapse = true;
          this.isSubmitting = false;
        } catch (error) {
          this.isEdit = true;
          this.isEmailItemsCollapse = false;
          this.isSubmitting = false;
        }
      }
    }
  }

}
