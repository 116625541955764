import { Address } from '@models/address.model';
import { RouteItem, RouteItemStatus } from '@models/route-item.model';
import { OrderStop } from '@models/order-stop.model';
import { ShipmentType } from '@models/shipment-type.model';
import { Customer } from '@models/customer.model';
import { BaseModel } from '@models/base.model';
import { ChargeType } from '@models/charge-type.model';
import { OrderPriorityEnum } from './order.model';
import { CustomerContact } from './customer-contact.model';

export enum RouteItemType {
    Pickup = 'Pickup',
    DeliveryToTransfer = 'DeliveryToTransfer',
    PickupFromTransfer = 'PickupFromTransfer',
    Delivery = 'Delivery',
}

export enum RouteItemDisplayStatus {
    OnTime = 'OnTime',
    PickedUp = 'PickedUp',
    PickedUpFromTransfer = 'PickedUpFromTransfer',
    Late = 'Late',
    Delivered = 'Delivered',
    DeliveredToTransfer = 'DeliveredToTransfer',
}

export const stopStatusTypes: Record<RouteItemDisplayStatus, { class: string; text: string }> = {
	[RouteItemDisplayStatus.OnTime]: { class: 'on-time', text: 'On Time' },
	[RouteItemDisplayStatus.PickedUp]: { class: 'picked-up', text: 'Picked Up' },
	[RouteItemDisplayStatus.PickedUpFromTransfer]: { class: 'picked-up', text: 'Picked Up (T)' },
	[RouteItemDisplayStatus.Late]: { class: 'late', text: 'Late' },
	[RouteItemDisplayStatus.Delivered]: { class: 'delivered', text: 'Delivered' },
	[RouteItemDisplayStatus.DeliveredToTransfer]: { class: 'delivered', text: 'Delivered (T)' },
};

export interface RouteItemStop extends BaseModel {
    orderId?: number;
    orderNumber?: string;
    customer?: Customer;
    customerContact?: CustomerContact;
    priority?: OrderPriorityEnum;
    sortOrder?: number;
    referenceNumber?: string;
    type?: RouteItemType;
    status?: 0 | 1; /*RouteItemStatus;*/
    address?: Address,
    dateType?: string;
    date?: string;
    groupDate?: string;
    timeType?: string;
    time?: string;
    transferStop?:any;
    shipmentType?: ShipmentType;
    routeItems?: RouteItem[];
    arrivalDateTime?: string | Date;
    departureDateTime?: string | Date;
    confirmedWithoutAssignItems?: boolean;
    toPickUp?: boolean;
    assignPickup?: boolean;
    assignDelivery?: boolean;
    assignTransfer?: boolean;
    getTransferRouteItem?: RouteItem,
    displayStatus?: RouteItemDisplayStatus;
    orderStop?: OrderStop;
    orderStopId?: number;
    departureTime?: number | string;
    arrivalTime?: number | string;
    arrivalDate?: number | Date;
    waitingTime?: string;
    freeWaitingTime?: number;
    charges?: ChargeType[];
    index?: number;
    // Frontend only properties
    dateToDisplay?: string;
    isPopupOpen?: boolean;
    unallocatedItems?: any[];
}