import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Driver } from '@app/models/driver.model';
import { UtilService } from '@app/services/util.service';
import { DriverService } from '@app/shared/services/driver.service';

@Component({
  standalone: false,
  selector: 'app-driver-professional-details',
  templateUrl: './driver-professional-details.component.html',
  styleUrls: ['./driver-professional-details.component.scss']
})
export class DriverProfessionalDetailsComponent implements OnInit {

  private _driver: Driver;

  title = 'Professional Details'
  driverProfessionalDetailsForm: FormGroup;
  driverId: number;
  submitLoading: boolean;
  isLoadingResults: boolean;
  selectedDateRange: Date

  @Output() driverChange = new EventEmitter()

  @Input() isLoading: boolean = false;
  @Input()
  public get driver(): Driver {
    return this._driver;
  }
  public set driver(value: Driver) {
    this._driver = value
    // this.patchForm(value)
    if (value?.licenseExpires) {
      this.driver.licenseExpires = new Date(value.licenseExpires);
    }
  }


  constructor(
    public driverService: DriverService,
    private formBuilder: FormBuilder,
    public util: UtilService,
  ) {


  }

  ngOnInit(): void {
    this.driverProfessionalDetailsForm = this.formBuilder.group({

    });
  }

  patchForm(driver) {
    this.driverProfessionalDetailsForm.patchValue({

    })
  }

  onSubmit() {
    if (this.driverProfessionalDetailsForm.valid) {
      this.driverChange.emit({
        ...this.driver,
        ...this.driverProfessionalDetailsForm.value,
      })
    }
  }

}
