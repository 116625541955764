import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface ConfirmData {
    title?: string;
    message?: string;
    yesText?: string;
    noText?: string;
}

@Component({
    standalone: false,
    selector: 'app-warning-confirmation-dialog',
    templateUrl: './warning-confirmation-dialog.component.html',
    styleUrls: ['./warning-confirmation-dialog.component.scss']
})
export class WarningConfirmationDialogComponent implements OnInit {

    @Input() data: ConfirmData;

    constructor (
        public readonly activeModal: NgbActiveModal
    ) {}

    ngOnInit() {
    }

}
