import { Directive, HostListener, Input } from "@angular/core";

@Directive({
    standalone: false,
    selector: "[onlyDigits]",
})
export class OnlyDigitsDirective {
    @Input() maxLength: number = 15; // Default maxLength to 15
    @Input() allowedCharacters: string = "+()- "; // Characters to allow in addition to digits

    private get allowedRegex(): RegExp {
        // Create a dynamic regex based on allowed characters
        const escapedChars = this.allowedCharacters.replace(
            /[-\/\\^$*+?.()|[\]{}]/g,
            "\\$&"
        ); // Escape special characters
        return new RegExp(`^[0-9${escapedChars}]*$`);
    }

    // Prevent disallowed characters on keypress and enforce maxLength
    @HostListener("keypress", ["$event"])
    onKeyPress(event: KeyboardEvent): void {
        const inputElement = event.target as HTMLInputElement;

        // Only apply to elements with the 'only-digits-input' class
        if (!inputElement.classList.contains("only-digits-input")) {
            return;
        }

        const currentLength = inputElement.value.length;

        // Prevent input if maxLength is reached
        if (currentLength >= this.maxLength) {
            event.preventDefault();
            return;
        }

        // Allow digits and allowed characters
        const inputChar = event.key;
        if (!this.allowedRegex.test(inputChar)) {
            event.preventDefault();
        }
    }

    // Handle paste events to allow only digits and allowed characters, respecting maxLength
    @HostListener("paste", ["$event"])
    onPaste(event: ClipboardEvent): void {
        const clipboardData =
            event.clipboardData || (window as any).clipboardData;
        const pastedData = clipboardData.getData("text");

        // Allow only digits and allowed characters in the pasted content
        const cleanedData = Array.from(pastedData)
            .filter((char: any) => this.allowedRegex.test(char))
            .join("");

        // Prevent the default paste behavior
        event.preventDefault();

        // Get the input element
        const inputElement = event.target as HTMLInputElement;

        // Calculate the new value
        const currentValue = inputElement.value;
        const selectionStart = inputElement.selectionStart ?? 0;
        const selectionEnd = inputElement.selectionEnd ?? 0;

        const newValue =
            currentValue.slice(0, selectionStart) +
            cleanedData +
            currentValue.slice(selectionEnd);

        // Truncate the value to maxLength
        const truncatedValue = newValue.slice(0, this.maxLength);

        // Set the updated value and trigger Angular form update
        inputElement.value = truncatedValue;
        inputElement.dispatchEvent(new Event("input"));
    }
}