import { Component, OnInit } from '@angular/core';

export interface accidentRecord {
  isEdit?: boolean,
  isDelete?: boolean,
  dateOfAccident?: Date,
  natureOfAccident?: string,
  injuries?: number,
  fatalities?: number,
  chemicalSpills?: boolean,
}
export interface trafficConvictionForFeiture {
  isEdit?: boolean,
  isDelete?: boolean,
  dateOfConviction?: Date,
  violation?: string,
  stateOfViolation?: string,
  penalty?: string,
}

@Component({
  standalone: false,
  selector: 'app-driver-driving-experience',
  templateUrl: './driver-driving-experience.component.html',
  styleUrls: ['./driver-driving-experience.component.scss']
})
export class DriverDrivingExperienceComponent implements OnInit {

  title = 'Driving Experience'
  constructor() { }

  accidentRecords: accidentRecord[] = [];
  trafficConvictionForFeitures: trafficConvictionForFeiture[] = [];

  ngOnInit(): void {
  }

  addAccidentRecord() {
    this.accidentRecords.splice(0, 0, {
      isEdit: true,
      dateOfAccident: null,
      natureOfAccident: '',
      injuries: 0,
      fatalities: 0,
      chemicalSpills: false,
    })
  }

  editAccidentRecord(index) {
    this.accidentRecords[index].isEdit = true
  }

  cancelAccidentRecord(index) {
    this.accidentRecords.splice(index, 1);
  }

  deleteAccidentRecord(index) {
    this.accidentRecords.splice(index, 1);
  }

  saveAccidentRecord(index) {
    const accidentRecord = this.accidentRecords[index];
  }


  addTrafficConvictionForFeiture() {
    this.trafficConvictionForFeitures.splice(0, 0, {
      isEdit: true,
      dateOfConviction: null,
      violation: '',
      stateOfViolation: '',
      penalty: '',
    })
  }

  editTrafficConvictionForFeiture(index) {
    this.trafficConvictionForFeitures[index].isEdit = true
  }

  cancelTrafficConvictionForFeiture(index) {
    this.trafficConvictionForFeitures.splice(index, 1);
  }

  deleteTrafficConvictionForFeiture(index) {
    this.trafficConvictionForFeitures.splice(index, 1);
  }

  saveTrafficConvictionForFeiture(index) {
    const trafficConvictionForFeiture = this.trafficConvictionForFeitures[index];
  }

  	/**
	 * On submit driver driving experience form
	 * 
	 * @param form 
	 */
	onSubmit(form) {
		
	}
}
