import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { UtilService } from 'src/app/services/util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { HelperService } from '@app/shared/services/helper.service';

@Component({
  standalone: false,
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  errorMessage: any = null;
  resetPasswordForm: FormGroup;
  showPassword = false;
  showConfirmPassword = false;


  constructor(
    private api: ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private helperService: HelperService
  ) { }

  ngOnInit() {
    this.resetPasswordForm = this.formBuilder.group({
      Password: ['', Validators.compose(
        [
          Validators.minLength(8),
          Validators.required,
          //CustomValidator.strongPassword(),
        ]
      )],
      ConfirmPassword: ['', [Validators.required, confirmPasswordValidator]],
      UserName: ['', [Validators.required]],
      Token: ['', [Validators.required]],
    });

    if (this.activatedRoute.snapshot.queryParams.Email) {
      this.resetPasswordForm.patchValue({ UserName: decodeURI(this.activatedRoute.snapshot.queryParams.Email) })
    }

    if (this.activatedRoute.snapshot.queryParams.Token) {
      this.resetPasswordForm.patchValue({ Token: decodeURI(this.activatedRoute.snapshot.queryParams.Token) })
    }

    // Remove params
    this.router.navigate([], {
      queryParams: {
        Email: null,
        Token: null,
      },
      queryParamsHandling: 'merge'
    });
  }


  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
  toggleShowConfirmPassword() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  onSubmit() {
    this.api.AuthResetPassword(this.resetPasswordForm.value, success => {
      this.router.navigate(['/auth/login']);
      this.helperService.successMessage("Reset password successfully")
    }, error => {
      this.helperService.errorMessage(error)
    });
  }

}


/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

  if (!control.parent || !control) {
    return null;
  }

  const password = control.parent.get('Password');
  const passwordConfirm = control.parent.get('ConfirmPassword');

  if (!password || !passwordConfirm) {
    return null;
  }

  if (passwordConfirm.value === '') {
    return null;
  }

  if (password.value === passwordConfirm.value) {
    return null;
  }

  return { passwordsNotMatching: true };
};
