import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LicenseType } from '@app/models/license-type';
import { CrudService } from './core/crud.service';

@Injectable({ providedIn: 'root' })
export class LicenseTypeService extends CrudService<LicenseType> {

    basePath = 'LicenseTypes'
    constructor(
        protected http: HttpClient
    ) {
        super(http)
    }
}

