import { NgModule } from '@angular/core';
import { EditOrderStopDateAndTimeDialogComponent } from './edit-order-stop-date-and-time-dialog.component';
import { SharedModule } from '@app/shared/shared.module';
import { CommonModule } from '@angular/common';

@NgModule({
	declarations: [
		EditOrderStopDateAndTimeDialogComponent
	],
	exports: [
		EditOrderStopDateAndTimeDialogComponent
	],
	imports: [
		SharedModule,
		CommonModule
	]
})
export class EditOrderStopDateAndTimeDialogModule { }