import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validator,
  ValidationErrors,
  AbstractControl,
  NgModel,
} from '@angular/forms';
import { splitPhoneNumberAndExtension } from '@app/@shared/utils';

@Component({
  standalone: false,
  selector: 'app-phone-iso-code-extension-input',
  templateUrl: './phone-iso-code-extension-input.component.html',
  styleUrls: ['./phone-iso-code-extension-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneIsoCodeExtensionInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PhoneIsoCodeExtensionInputComponent),
      multi: true,
    },
  ],
})
export class PhoneIsoCodeExtensionInputComponent implements OnInit, ControlValueAccessor, Validator {
  formControl: AbstractControl | null = null
  initPhoneData: any;
  isInitialized = false;
  childValidationErrors: ValidationErrors | null = null;
  // Inputs
  @Input() name?: string; // Enter name of the input field, is required for show error
  @Input() phonePlaceholder = 'Enter phone number';
  @Input() extensionPlaceholder = 'Extension';
  @Input() phoneLabel = 'Phone';
  @Input() extensionLabel = 'Extension';
  @Input() disabled: boolean = false;
  @Input() phoneCountryIso = 'US'
  @Input() phoneCountryCode = '1'
  @Input() phoneNumber = null;
  @Input() extensionNumber = null;

  // Outputs
  @Output() phoneNumberChange = new EventEmitter<string>();
  @Output() extensionNumberChange = new EventEmitter<string>();
  @Output() phoneCountryCodeChange = new EventEmitter<any>();
  @Output() phoneCountryIsoChange = new EventEmitter<any>();

  // ViewChild
  @ViewChild('phoneInput') phoneInput: NgModel;

  private onChange: (value: string) => void = () => { };
  private onTouched: () => void = () => { };

  constructor(
  ) { }

  ngOnInit(): void {
  }

  // Write value from the parent component
  writeValue(value: string): void {
    const { phone, extension } = splitPhoneNumberAndExtension(value)
    this.phoneNumber = phone || '';
    this.extensionNumber = extension || '';  
    this.phoneCountryIso = this.phoneCountryIso || 'US'
    this.phoneCountryCode = this.phoneCountryCode || '1'
  }

  clearValue() {
    const value = null
    this.phoneNumber = value;
    this.extensionNumber = value;
    this.phoneCountryCode = '1';
    this.phoneCountryIso = 'US';
    this.onChange(value);
    this.phoneNumberChange.emit(value);
    this.extensionNumberChange.emit(value);
    this.phoneCountryCodeChange.emit(this.phoneCountryCode);
    this.phoneCountryIsoChange.emit(this.phoneCountryIso);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  // Custom validate method to integrate with Angular Forms
  validate(control: AbstractControl): ValidationErrors | null {
    this.formControl = control; // Save reference to the form control
    this.handleValidation(); // Perform initial validation
    return control?.errors
  }

  handleValidationChange(errors) {// Call handleValidation whenever child validation changes
    this.childValidationErrors = errors;
    this.handleValidation(); 
  }

  performParentValidation(): ValidationErrors | null {
    if (this.phoneNumber || this.extensionNumber) {
      if (!this.phoneNumber && this.extensionNumber) {
        return { invalidPhone: 'Phone number is required when an extension is provided' };
      }
      if (this.phoneNumber && !this.phoneCountryCode) {
        return { invalidPhone: 'The phone country code is required.' };
      }
    }
    return null;
  }
  handleValidation(): void {
    const parentErrors = this.performParentValidation();

    const combinedErrors = {
      ...parentErrors,
      ...(this.childValidationErrors || {})
    };

    if (this.formControl) {
      this.formControl.setErrors(Object.keys(combinedErrors).length > 0 ? combinedErrors : null);
    }
  }

  handleChangeExtensionNumber($event) {
    this.extensionNumber = $event
    this.extensionNumberChange.emit($event)
    this.handleValueChange()
  }

  handleChangePhoneNumber($event) {
    this.phoneNumber = $event
    this.phoneNumberChange.emit($event)
    this.handleValueChange()
    setTimeout(() => {
      this.isInitialized = true;
    }, 0)
  }
  handlePhoneCountryCodeChange(val) {
    this.phoneCountryCode = val
    this.phoneCountryCodeChange.emit(val)
  }
  handlePhoneCountryIsoChange(val) {
    this.phoneCountryIso = val
    this.phoneCountryIsoChange.emit(val)
  }

  // Handle combined value change
  private handleValueChange(): void {
    const value = (this.phoneNumber ? this.phoneNumber : '') + (this.extensionNumber ? ` x ${this.extensionNumber}` : '');
    this.onChange(value);
    if (this.isInitialized) {
      this.onTouched();
    }
  }
}