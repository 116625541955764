import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { FormValidateDirective } from '@app/@shared/form-validate/form-validate.directive';
import { isNotEmpty, titleCase } from '@app/@shared/utils';
import { customerEmailTypes } from '@app/data/customer';
import { stateOptions } from '@app/data/state';
import { Customer, PipelineEnum } from '@app/models/customer.model';
import { ImageUploadTypeEnum } from '@app/models/image.model';
import { CustomerService } from '@app/shared/services/customer.service';
import { HelperService } from '@app/shared/services/helper.service';
import { SettingService } from '@app/shared/services/setting.service';
import { omit, orderBy } from 'lodash';
import { ValidationError, array, object, string } from 'yup';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerInformationDialogComponent } from '../customer-details/customer-information-dialog/customer-information-dialog-component';
import { Country } from '@app/models/address.model';
import { AddressService } from '@app/shared/services/address.service';

@Component({
	selector: 'app-create-customer',
	templateUrl: './create-customer.component.html',
	styleUrls: ['./create-customer.component.scss'],
	host: {
		'[class.page-container]': "true"
	}
})
export class CreateCustomerComponent implements OnInit {

	ImageUploadTypeEnum = ImageUploadTypeEnum;
	states = stateOptions;
	customerEmailTypes = customerEmailTypes;
	PipelineEnum = PipelineEnum;

	users: any[];
	returnUrl: string;
	customer: Customer = {
		addresses: [{
			entityType: 1,
			name: 'Main',
			addressLine1: '',
			countryId: null,
			city: '',
			state: '',
			zip: ''
		}],
		customerContacts: [{
			isPrimary: true,
			email: ''
		}],
		customerEmails: [{
			isActive: true,
			emailType: null,
			email: '',
		}],
		isEntrepreneur: false,
		phone: null,
		phoneExtension: null,
		pipeline: PipelineEnum.CUSTOMER,
		paymentTermsInDays: 0
	}

	commonCustomerContactsFields = {
		email: string().label('Email').nullable().email().max(256),
		location: string().trim().label('Address').max(256),
		city: string().trim().label('City').max(256),
		cellNumber: string().label('Cell Number').nullable(),
		contactNumber: string().label('Company Phone').nullable(),
		state: string().label('State'),
		zip: string().trim().label('Zip Code').label("Zip code").max(32),
	}

	validationSchema = object().shape({
		companyName: string().when('isEntrepreneur', {	
			is: true,
			then: (schema) => schema.trim().max(256),
			otherwise: (schema) => schema.trim().required().max(256),
		}),
		companyPhone: string().when('isEntrepreneur', {
			is: true,
			then: (schema) => schema.nullable(),
			otherwise: (schema) => schema.nullable().required()
		}),
		addresses: array().when('isEntrepreneur', {
			is: true,
			then:(schema) => schema.of(
				object().shape({
					addressLine1: string().trim().label('Company Location').max(256),
					city: string().trim().label('City').max(256),
					state: string().label('State'),
					zip: string().trim().label('Zip Code').label('Zip code').max(32),
				})
			),
			otherwise:(schema) => schema.of(
				object().shape({
					addressLine1: string().trim().label('Company Location').required().max(256),
					city: string().trim().label('City').required().max(256),
					state: string().label('State').required(),
					zip: string().trim().label('Zip Code').label('Zip code').required().max(32),
				})
			)
		}),
		customerEmails: array().when('isEntrepreneur', {
			is: true,
			then: (schema) => schema.of(
				object().shape({
					email: string().label('Email').nullable().email().max(256),
					emailType: string().nullable(),
				})
			),
			otherwise: (schema) => schema.of(
				object().shape({
					email: string().label('Email').required().email().max(256),
					emailType: string().nullable(),
				})
			)
		}).test('unique-emails', 'Emails must be unique', function (arr: any[]) {
			const path = this.path;
			let errors = [];
			let valueCount = arr.reduce((acc, obj) => {
				acc[obj.email] = (acc[obj.email] || 0) + 1;
				return acc;
			}, {});
			arr.forEach((obj, index) => {
				if (obj.email && valueCount[obj.email] > 1) {
					const error = this.createError({ message: `Email is duplicated`, path: `${path}[${index}].email` });
					errors.push(error);
				}
			});
			if (errors?.length > 0) {
				return new ValidationError(errors);
			}
			return true;
		}),
		customerContacts: array().when('isEntrepreneur', {
			is: true,
			then: (schema) => schema.of(
				object().shape({
					firstName: string().label('First Name').nullable().required().max(256),
					lastName: string().label('Last Name').nullable().required().max(256),
					...this.commonCustomerContactsFields
				})
			),
			otherwise: (schema) => schema.of(
				object().shape({
					firstName: string().label('First Name').nullable().max(256),
					lastName: string().label('Last Name').nullable().max(256),
					...this.commonCustomerContactsFields
				})
			)
		}).test('unique-values', 'Value must be unique', function (arr: any[]) {
			const companyName = this.parent?.companyName;
			const customerContactName = `${arr[0]?.firstName} ${arr[0]?.lastName}`;
			const companyPhone = this.parent?.companyPhone;
			const emails = (this.parent?.customerEmails || []).map((item: any) => item.email);
			const contactNumber = arr[0]?.contactNumber;
			const email = arr[0]?.email;
			const errors: any[] = [];
			if (companyName && companyName === customerContactName) {
				errors.push(
					this.createError({ message: 'Company Name is duplicated', path: `companyName` }),
					this.createError({ message: 'First Name is duplicated', path: `${this.path}[0].firstName` }),
					this.createError({ message: 'Last Name is duplicated', path: `${this.path}[0].lastName` })
				);
			} else if (companyName && companyName === arr[0]?.firstName) {
				errors.push(
					this.createError({ message: 'Company Name is duplicated', path: `companyName` }),
					this.createError({ message: 'First Name is duplicated', path: `${this.path}[0].firstName` })
				);
			}
			if (emails.length > 0) {
				emails.forEach((e, i) => {
					if (email && email === e) {
						errors.push(
							this.createError({ message: 'Email is duplicated', path: `customerEmails[${i}].email` }),
							this.createError({ message: 'Email is duplicated', path: `${this.path}[0].email` })
						);
					}
				});
			}
			if (companyPhone && companyPhone === contactNumber) {
				errors.push(
					this.createError({ message: 'Duplicate of company phone and contact number detected.', path: 'companyPhone' }),
					this.createError({ message: 'Duplicate of contact number and company phone detected.', path: `${this.path}[0].contactNumber` }),
				);
			}
			return errors.length > 0 ? new ValidationError(errors) : true;
		}),
	});

	get isDisplayAsterisk(): string {
		return !this.customer?.isEntrepreneur ? '*' : ''
	}

	countries: Country[];

	public snapshot$: Data = this.activatedRoute.snapshot;
	companyName: string;

	constructor(
		public customerService: CustomerService,
		public helperService: HelperService,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		private settingService: SettingService,
		private readonly location: Location,
		public addressService: AddressService,
		private modalService: NgbModal
	) { }

	async ngOnInit(): Promise<void> {

		this.addressService.getCountries().then(res => {
			this.countries = res;
		}).catch((error) => {
			this.countries = [];
			this.helperService.errorMessage(error)
		})

		try {
			const users = await this.settingService.getUsers();
			this.users = orderBy(users?.list, [user => user?.firstName?.toLowerCase()], ['asc']);
		} catch (error) {
			console.log('Error while retrieving settings users', error);
			this.helperService.errorMessage(error);
		}
	}

	ngAfterViewInit() {
		this.returnUrl = this.snapshot$.queryParams.returnUrl;
		this.patchUsingLocationState(this.location.getState());
	}

	generateAutomaticCompanyName() {
		if (this.customer.isEntrepreneur && (!this.customer.customerName || this.customer.customerName.startsWith('Individual'))) {
			let companyName = `Individual ${titleCase(this.customer?.customerContacts[0]?.firstName)} ${titleCase(this.customer?.customerContacts[0]?.lastName?.charAt(0))}.`;
			const pickRandomLetterForName = this.pickRandomLetter(`${this.customer?.customerContacts[0]?.firstName}${this.customer?.customerContacts[0]?.lastName?.charAt(0)}`)
			this.companyName = `${companyName || ''} ${titleCase(pickRandomLetterForName || '')}`;
		}
	}
	private setContactName(contact, customerName, number = 1) {
		contact.firstName = `Contact ${number}`;
		contact.lastName = customerName?.substring(0, 20);
		return contact
	}

	private pickRandomLetter(name) {
		const randomIndex = Math.floor(Math.random() * name.length);
		return name[randomIndex];
	}

	async handleSubmit(form: FormValidateDirective) {
		try {
			if (this.customer.isEntrepreneur && (
				(!this.customer?.customerName && this.customer?.customerContacts[0]?.firstName && this.customer?.customerContacts[0]?.lastName)
				||
				(this.customer?.customerName?.startsWith('Individual') && this.customer?.customerName !== this.companyName)
			)) {
				this.customer.customerName = this.companyName
			}
			let customerRequest = this.customer;
			const firstContact = this.customer?.customerContacts?.[0];
			if (firstContact) {
				const { firstName, lastName, phone, email } = firstContact;
				if (!firstName && !lastName && (phone || email)) {
					this.customer.customerContacts[0] = await this.setContactName(firstContact, this.customer?.customerName)
				}
				if (!phone && !email) {
					customerRequest = omit(customerRequest,'customerContacts');
				}
			}
			if (form.isValid) {
				var res = await this.customerService.validateCustomer(customerRequest);
				form.errors = {};

				if (res.existedName)
					form.errors['companyName'] = ["Company name is not unique"]
				if (res.existedPhone)
					form.errors['companyPhone'] = ["Company phone is not unique"]
				if (res.existedEmailIndex?.length > 0) {
					form.errors['customerEmails'] = [];

					for (const i of res.existedEmailIndex) {
						form.errors['customerEmails'][i] = { email: ["Email is not unique"] }
					}
				}

				if (Object.keys(form.errors).length > 0) {
					form.errors$.next(form.errors);
				}
				else {
					let customerId;

					if (res.existedProspect) {
						const activeModal = this.modalService.open(CustomerInformationDialogComponent, {
							scrollable: true,
							size: "sm",
						});
						activeModal.componentInstance.customer = res.existedProspect;
						var updatedToCustomer = await activeModal.result;

						if (updatedToCustomer) {
							this.helperService.successMessage('Customer Successfully Updated.');
							customerId = res.existedProspect.customerId
						}
					}
					else {
						const customer = await this.customerService.create(customerRequest);
						this.helperService.successMessage('Customer Details Successfully Created.');
						customerId = customer.customerId
					}

					if (customerId) {

						const redirect = this.returnUrl ? [this.returnUrl] : ['/app/customers'];
						// If return callback URL find redirect there or redirect on customer edit page
						this.router.navigate(redirect, {
							queryParams: {
								...(this.returnUrl ? { customerId: customerId } : { id: customerId })
							}
						});
					}
				}
			}
		} catch (error) {
			console.log('Error while creating customer', error);
			this.helperService.errorMessage(error);
		}
	}

	/**
	 * GET location old state & patch form value
	 * We are using such functionality for create new customer from order create/update page
	 *
	 * @param state
	 */
	patchUsingLocationState(state: any) {
		const { firstName, lastName, customerName } = state || new Object();

		if (isNotEmpty(customerName)) { this.customer.customerName = customerName; }
		if (isNotEmpty(firstName)) { this.customer.customerContacts[0].firstName = firstName; }
		if (isNotEmpty(lastName)) { this.customer.customerContacts[0].lastName = lastName; }
	}
}

