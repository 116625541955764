import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'app-qty-select',
	templateUrl: './qty-select.component.html',
	styleUrls: ['./qty-select.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => QtySelectComponent),
			multi: true
		}
	],
	encapsulation: ViewEncapsulation.Emulated
})
export class QtySelectComponent implements OnInit {

	@Input() placeholder: string = '0';
	@Input() min: number = 0;
	@Input() max: number;
	@Input() isDisabled: boolean = false;

	@Output() oldAndNewValues = new EventEmitter<{ oldValueQty: number | string, newValueQty: number | string }>();
	errorMessage?: string = null;
	onChange: any = () => { };
	onTouched: any = () => { };

	private _value: number;
	oldValue: number;
	get value(): number {
		return this._value;
	}
	set value(updateValues: number) {
		this.errorMessage = null
		if (this.max !== undefined && this.max !== null && this.max < updateValues) {
			this.errorMessage = `Quantity should be lest then ${this.max}`
			return;
		}
		if (this.min !== undefined && this.min !== null && this.min > updateValues) {
			this.errorMessage = `Quantity should be more then ${this.min}`
			return;
		}

		if (updateValues === null || `${updateValues}` === '') {
			updateValues = 0;
		} else {
			updateValues = parseInt(`${updateValues}`)
		}

		this._value = updateValues;
		this.oldAndNewValues.emit({ oldValueQty: this.oldValue, newValueQty: updateValues });
		this.onChange(updateValues);
		this.onTouched(updateValues);
	}

	ngOnInit(): void {
	}

	writeValue(value: number) {
		this._value = value
	}

	registerOnChange(fn: (rating: number) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	changeQtyDown() {
		this.oldValue = this.value;
		const nextValue = parseInt(this.value + '') - 1;
		this.value = (this.min === null || this.min === undefined) ? Math.max(this.min, nextValue) : nextValue;
	}

	changeQtyUp() {
		this.oldValue = this.value;
		const nextValue = parseInt(this.value + '') + 1;
		this.value = nextValue;
	}
}
