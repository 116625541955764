import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import { EntityTypes } from '@models/entity-type.model';
import { Route, Routes, RouteStatusEnum } from '@models/route.model';
import { RouteItemStatus } from '@models/route-item.model';
import { RouteService } from '@app/services/route.service';
import { RouteItemStop, RouteItemType } from '@models/route-item-stop.model';
import { OrderStopType } from '@models/route-box.model';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ChargeTypeService } from '@app/shared/services/charge-type.service';
import { OrderPriorityEnum } from '@app/models/order.model';
import { getStopActionLabel, getStopTypeLabel, getStopTypeLabelColor } from '@app/@shared/utils';
import { isEqual } from 'lodash';

@UntilDestroy()
@Component({
    standalone: false,
    selector: 'app-route-details-horizontal',
    templateUrl: './route-details-horizontal.component.html',
    styleUrls: ['./route-details-horizontal.component.scss']
})
export class RouteDetailsHorizontalComponent implements OnInit {
    @Input() routes: Routes[] = [
        {
            route: {
                routeStopGroups: [
                    {
                        routeItemStops: [
                            {
                                orderStop: {
                                    priority: OrderPriorityEnum.MIDDLE
                                }
                            }
                        ]
                    }
                ]
            }
        }

    ];
    @Input() route: Route;
    @Input() loading: boolean = true;
    @Input() loadMoreLoading: boolean;
    @Input() isMerged: boolean;
    @Input() readonly: boolean;
    @Input() showCompleted: boolean;
    @Input() EntityTypes = EntityTypes;
    @Input() stopStatusTypes: any;
    @Input() isStopOpen: any;
    @Input() isEmptyData: any;
    @Input() selectedStop: RouteItemStop;

    @Output() onDrop = new EventEmitter<any>();
    @Output() onTogglePopup = new EventEmitter<any>();
    @Output() onUpdateRouteStopStatus = new EventEmitter<any>();
    @Output() onCopyStopText = new EventEmitter<any>();
    @Output() onShowDispatchSheet = new EventEmitter<any>();
    @Output() onShowBillOfLanding = new EventEmitter<any>();
    @Output() onEditPickupDelivery = new EventEmitter<any>();
    @Output() onOpenAssignTransferModal = new EventEmitter<any>();
    @Output() onShowAddUnassignedStopsDialog = new EventEmitter<any>();
    @Output() onSelectStop = new EventEmitter<any>();

    routeItemStatus = RouteItemStatus;
    OrderStopType = OrderStopType;
    RouteStatusEnum = RouteStatusEnum;
    getStopTypeLabel = getStopTypeLabel;
    getStopTypeLabelColor = getStopTypeLabelColor;
    getStopActionLabel = getStopActionLabel;

    @ViewChild('cardsContainer') cardsContainer: ElementRef;

    constructor(
        public util: UtilService,
        public routeService: RouteService,
        public chargeTypeService: ChargeTypeService,
    ) { }

    ngOnInit() { }

    drop($event, routeGroup) {
        this.onDrop.emit({ event: $event, newContainer: routeGroup, newStopIndex: null })
    }
    updateRouteStopStatus(stop: RouteItemStop, newStatus: RouteItemStatus) {
        this.onUpdateRouteStopStatus.emit({ stop, newStatus })
    }
    togglePopup(event, stop) {
        event.stopPropagation();
        this.onTogglePopup.emit(stop)
    }
    stopText(route, stop) {
        this.onCopyStopText.emit({ route, stop })
    }

    showDispatchSheet(stop: RouteItemStop) {
        this.onShowDispatchSheet.emit(stop)
    }

    showBillOfLanding(order: RouteItemStop) {
        this.onShowBillOfLanding.emit(order)
    }

    editPickupDelivery(stop) {
        this.onEditPickupDelivery.emit(stop)
    }

    openAssignTransferModal($event, stop) {
        $event.stopPropagation();
        this.onOpenAssignTransferModal.emit(stop)
    }

    showAddUnassignedStopsDialog(event, stopType: OrderStopType, orderNumber = '') {
        event.stopPropagation()
        event.preventDefault()
        this.onShowAddUnassignedStopsDialog.emit({ stopType, orderNumber })
    }

    scrollLeft($event) {
        $event.stopPropagation()
        this.cardsContainer.nativeElement.scrollTo({ left: (this.cardsContainer.nativeElement.scrollLeft - 200), behavior: 'smooth' });

    }
    scrollRight($event) {
        $event.stopPropagation()
        this.cardsContainer.nativeElement.scrollTo({ left: (this.cardsContainer.nativeElement.scrollLeft + 200), behavior: 'smooth' });
    }

    canDrop = () => {
        return !this.readonly && !this.showCompleted && this.route?.status !== RouteStatusEnum.COMPLETED
    }
    
    isSelected(stop) {
        return this.selectedStop ? isEqual(this.selectedStop, stop) : false
    }
    selectStop(event, stop) {
        event.stopPropagation()
        this.onSelectStop.emit(stop)
    }

    getContinuousIndex(groupIndex: number, stopIndex: number): number {
        let previousCount = 0;
        for (let i = 0; i < groupIndex; i++) {
            previousCount += this.route.routeStopGroups[i].routeItemStops.length;
        }
        return previousCount + stopIndex + 1;
    }
}