import { Component, Input } from '@angular/core';
import { Attachment, AttachmentPreview } from '@app/models/attachment.model';
import { ImagesService } from '@app/shared/services/images.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import mime from 'mime';

@Component({
    standalone: false,
    selector: 'app-files-viewer-modal',
    templateUrl: './files-viewer-modal.component.html',
    styleUrls: ['./files-viewer-modal.component.scss']
})
export class FilesViewerModalComponent {
    currentIndex: number = 0;
    loading: boolean = false;
    counts: number = 0;
    previewFiles: AttachmentPreview[] = [];

    @Input() isAttachments: boolean;
    @Input() title: string;

    _files: Attachment[] | any[] = [];
    @Input() set files(files: Attachment[] | any[]) {
        this._files = files;
        this.counts = files.length;
        this.loadFiles();
    }
    get files(): Attachment[] | any[] {
        return this._files;
    }

    constructor(
        public activeModal: NgbActiveModal,
        private readonly imagesService: ImagesService,
        private readonly modalService: NgbModal,
    ) { }

    get currentFile(): AttachmentPreview {
        return this.previewFiles?.[this.currentIndex] || null;
    }

    ngOnInit() { }

    async loadFiles() {
        this.loading = true;
        this.previewFiles = [];

        for (let i = 0; i < this.files.length; i++) {
            const file = this.files[i];
            let url = file?.url || file;

            try {
                if (this.isAttachments && file?.attachmentId) {
                    const res = await this.imagesService.downloadAttachmentById(file?.attachmentId);
                    const blob = new Blob([res], { type: mime.getType(file.name) });
                    // handle blob for PDF files
                    if (mime.getType(file.name) === 'application/pdf') {
                        url = await this.convertBlobToBase64(blob);
                    } else {
                        url = window.URL.createObjectURL(blob)
                    }
                }
                const type = file?.name ? this.getFileType(file?.name) : this.getFileType(file);
                this.previewFiles.push({
                    url,
                    name: file?.name || file?.split('/').pop() || '',
                    type,
                    isSupported: type !== 'unknown'
                });
            } catch (error) {
                console.error('Error loading file:', error);
            }
        }

        this.previewFiles = this.previewFiles.filter(file => !!file.url);
        this.loading = false;
    }


    nextFile() {
        if (this.currentIndex < this.counts - 1) {
            this.currentIndex++;
        }
    }

    prevFile() {
        if (this.currentIndex > 0) {
            this.currentIndex--;
        }
    }

    async convertBlobToBase64(blob: Blob): Promise<string> {
        let binary = '';
        const uint8Array = new Uint8Array(await blob.arrayBuffer());
        const len = uint8Array.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(uint8Array[i]);
        }
        return `data:application/pdf;base64,` + btoa(binary);
    }

    getFileType(file: string): string {
        if (typeof file !== 'string') return 'unknown';
        const extension = file?.split('.')?.pop()?.toLowerCase();
        if (['jpg', 'jpeg', 'png', 'gif', 'ico', 'svg'].includes(extension!)) return 'image';
        if (['pdf'].includes(extension!)) return 'pdf';
        if (['mp4', 'webm', 'mov', 'avi', 'mkv'].includes(extension!)) return 'video';
        if (['mp3', 'm4a', 'aac'].includes(extension!)) return 'audio';
        // if (['docx'].includes(extension!)) return 'docx';
        // if (['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'txt', 'csv'].includes(extension!)) return 'document';
        return 'unknown';
    }
}