import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LicenseType } from '@app/models/license-type';
import { ListRequest } from '@app/models/core/base';
import { LicenseTypeService } from '@app/shared/services/license-type.service';
import { debounceTime, Subject, switchMap } from 'rxjs';
import { HelperService } from '@app/shared/services/helper.service';

@Component({
    standalone: false,
    selector: 'app-license-type-select',
    templateUrl: './license-type-select.component.html',
    styleUrls: ['./license-type-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LicenseTypeSelectComponent),
            multi: true,
        },
    ],
})
export class LicenseTypeSelectComponent implements OnInit, ControlValueAccessor {
    @Input() createSameTime = true;

    licensesTypes: LicenseType[] = [];
    isDisabled = false;
    searchTerm$ = new Subject<string>();

    constructor(
        private licenseTypeService: LicenseTypeService,
        private helperService: HelperService
    ) { }

    selectedLicenseName: string | null = null;

    ngOnInit() {
        this.fetchLicenses('');

        this.searchTerm$
            .pipe(
                debounceTime(400),
                switchMap((searchTerm) => this.fetchLicenses(searchTerm))
            )
            .subscribe();
    }

    fetchLicenses(searchTerm:string) {
        const searchCriteria: ListRequest = {
            Page: 1,
            ItemsPerPage: 12,
            SearchTerm: searchTerm,
        };

        return this.licenseTypeService.getAll(searchCriteria).then((data) => {
            this.licensesTypes = data.list;
        });
    }

    onSearchChange(searchTerm: { term: string, items?: LicenseType[] }) {
        console.log(searchTerm?.term, 'searchTerm?.term')
        this.searchTerm$.next(searchTerm?.term);
    }

    addLicenseFn(name: string, types: LicenseType[]) {
        const newLicense: LicenseType = {
            name
        };
        if (this.createSameTime) {
            this.licenseTypeService.create(newLicense).then((createdLicense) => {
                this.licensesTypes.push(createdLicense);
                this.selectedLicenseName = createdLicense.name;
                this.onChange(this.selectedLicenseName);
            }).catch((error) => {
                this.helperService.errorMessage(error);
            });
        } else {
            this.licensesTypes = [...this.licensesTypes, newLicense];
            this.selectedLicenseName = name;
            this.onChange(this.selectedLicenseName);
        }
        return { name }
    }

    handleClear($event: any) {
        this.selectedLicenseName = null;
        this.onChange(null);
        this.searchTerm$.next('');
    }

    handleChange(event: any) {
        this.onChange(event?.name || '');
    }

    writeValue(value: string): void {
        this.selectedLicenseName = value || null;
    }

    onChange = (value: string) => { };
    onTouched = () => { };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
}