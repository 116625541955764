import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { UtilService } from 'src/app/services/util.service';
import { CustomerImportComponent } from "./customer-import/customer-import.component";
import { DatatableComponent } from '@app/@shared/datatable';
import { CustomerService } from '@app/shared/services/customer.service';
import { HelperService } from '@app/shared/services/helper.service';
import { Customer, CustomerSearchRequest, PipelineEnum, SourceEnum } from '@app/models/customer.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '@app/shared/services/app.service';
import { debounceTime } from 'rxjs/operators';
import { CustomerExportComponent } from './customer-export/customer-export.component';
import { AuthService } from '@app/shared/services/auth/auth.service';
import { ListTabsComponent } from '@app/@shared/list-tabs/list-tabs.component';
import { countBy, cloneDeep, flatMap, get, omitBy, uniqBy, includes } from 'lodash';
import { statesFilterOptions } from '@app/data/state';
import { AddressService } from '@app/shared/services/address.service';
import { CustomerMargeComponent } from './customer-marge/customer-marge.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { ViewedEntityTypeEnum, ViewedEntityTypeValueEnum, ViewEntityStatistic } from '@app/models/view-entity.modal';
import { ViewEntityService } from '@app/shared/services/view-entity.service';

@UntilDestroy()
@Component({
  	standalone: false,
	selector: 'app-customer-list',
	templateUrl: './customer-list.component.html',
	styleUrls: ['./customer-list.component.scss'],
	host: {
		'[class.page-container]': "true"
	}
})

export class CustomerListComponent implements OnInit, OnDestroy {

	@ViewChild('datatable') datatable: DatatableComponent;

	@Input() listTabs: ListTabsComponent;

	PipelineEnum = PipelineEnum;

	search: CustomerSearchRequest = {
		SearchTerm: "",
		Page: 1,
		ItemsPerPage: 20,
		SortBy: "customerId",
		SortDirection: "Descending",
		PipelineTypes: [PipelineEnum.PROSPECT, PipelineEnum.CUSTOMER, PipelineEnum.LEAD],
		Sources: [SourceEnum.WEB, SourceEnum.PORTAL],
		States: [],
		Cities: [],
		Entrepreneur: true,
		Zip: null,
		Countries: [],
		CountryIds: []
	}

	selectedCustomerIds: any;
	public progress: number;
	public message: string;
	searchTerm: string;
	userRoles: any[];
	lastCopiedText: string;
	stateOptions = statesFilterOptions
	zipCode: number;
	city: string;
	isEntrepreneur: boolean = true;
	pipelineOptions = [
		{ name: 'Customer', value: PipelineEnum.CUSTOMER, selected: true },
		{ name: 'Prospect', value: PipelineEnum.PROSPECT, selected: true },
		{ name: 'Lead', value: PipelineEnum.LEAD, selected: true }
	];
	sourceOptions = [
		{ name: 'Web', value: SourceEnum.WEB, selected: true },
		{ name: 'Portal', value: SourceEnum.PORTAL, selected: true }
	];
	countryOptions: any;
	mergeableCustomer: Customer[] = [];
	viewStatics:ViewEntityStatistic = {}


	constructor(
		private ngbModal: NgbModal,
		private api: ApiService,
		private customerService: CustomerService,
		private helperService: HelperService,
		private util: UtilService,
		private authService: AuthService,
		private appService: AppService,
		public activatedRoute: ActivatedRoute,
		public addressService: AddressService,
		private clipboard: Clipboard,
		public viewEntityService: ViewEntityService,
	) { }

	get isAdmin(): boolean {
		return this.userRoles.includes("COMPANY_ADMIN");
	}

	getData = (request) => {
		const filters = omitBy(this.search, (value) => value === null || value === 'null')
		request = Object.assign(
			filters,
			request
		);
		this.customerService.cancelPendingRequestGetAll();
		return this.customerService.getAllWithCancelRequest(request);
	}

	ngOnInit() {

		this.customerService.$customerDatatableRefresh.pipe(untilDestroyed(this)).subscribe(
            (res: any) => {
                if (res) {
                    this.datatable?.refresh();
                }
            }
        );
		
		this.addressService.getCountries().then((countries: any) => {
			this.countryOptions = countries?.map(state => {
			  return {
				name: state.name,
				value: state.countryId, // or any other value you want to assign
				selected: false // or any other boolean value based on your logic
			  };
			});
		  });

		var search = this.util.GetAndDeleteSheardData('customerSearch');
		if (Object.keys(search).length != 0 && search.constructor === Object) {
			this.search = search;
		}

		this.appService.$searchTerm
			.pipe(
				untilDestroyed(this),
				debounceTime(500)
			)
			.subscribe((text) => {
				this.searchTerm = text
				this.search.SearchTerm = text
				this.datatable?.refresh({ pageRest: true });
			})

		this.authService.$user
			.pipe(
				untilDestroyed(this),
			)
			.subscribe((user) => {
				this.userRoles = user?.roles;
			})
		if (this.activatedRoute?.snapshot?.queryParams?.id) {
			this.listTabs.navigateOpenTab(this.activatedRoute.snapshot.queryParams.id, '/app/customers');
		}
		this.handleRefreshViewedData()
		this.viewEntityService.$statistics.pipe(untilDestroyed(this)).subscribe(
			(res: any) => {
				if (res) {
					this.viewStatics = cloneDeep(res[ViewedEntityTypeEnum.CUSTOMER])
				}
			}
		);
	}

	countrySelectionUpdated(values) {
		this.search.Countries = flatMap(values?.map(r => r?.name));
		this.search.CountryIds = flatMap(values?.map(r => r?.value));
		this.datatable?.refresh(true);
	}

	reloadDatatable() {
		this.search.Entrepreneur = this.isEntrepreneur
		if (this.zipCode) {
			this.search.Zip = this.zipCode
		} else {
			delete this.search.Zip
		}
		if (this.city) {
			this.search.Cities = [this.city]
		} else {
			delete this.search.Cities
		}
		this.datatable?.refresh(true);
	}

	pipelineSelectionUpdated(values) {
		this.search.PipelineTypes = values?.map(r => r.value)
		this.datatable?.refresh(true);
	}

	sourceSelectionUpdated(values) {
		this.search.Sources = values?.map(r => r.value)
		this.datatable?.refresh(true);
	}

	stateSelectionUpdated(values) {
		this.search.States = flatMap(values?.map(r => [r.name, r.value]))
		this.datatable?.refresh(true);
	}

	onToggleUnreadList(event) {
		const isChecked = event?.target?.checked
		if (isChecked){
			this.search.viewedEntityType = ViewedEntityTypeValueEnum.CUSTOMER
		}else{
			delete this.search.viewedEntityType
		}
		this.datatable.refresh(true);
	}

	ngOnDestroy() {
		this.appService.searchTerm = ''
	}

	handleRowClick(row: Customer) {
		this.listTabs.openTab(row.customerId);
	}

	handleRowRightClick(row: Customer) {
        const url = `/app/customers/edit/${row.customerId}`;
        window.open(url, '_blank');
    }

	setSelectCustomers(event) {
		this.selectedCustomerIds = event
		this.mergeableCustomer = this.getDuplicateDataCustomer(this.datatable?.selection?.selected as Customer[])
	}

	getDuplicateDataCustomer(data: Customer[]) {
		const keysToCheck = ['customerName', 'mainAddress.fullAddress', 'firstEmail', 'phone'];
		let duplicateData = []
		keysToCheck.forEach(key => {
			const counts = countBy(data, key);
			const duplicates = data.filter((obj) => {
				const val = get(obj, key, null)
				return val && counts[val] > 1
			});
			duplicateData = uniqBy([...duplicateData, ...duplicates], 'customerId');
		});
		return duplicateData
	}

	openMergeCustomersModal() {
		const activeModal = this.ngbModal.open(CustomerMargeComponent, {
			size: 'md',
			scrollable: true
		})
		activeModal.componentInstance.customers = this.mergeableCustomer;
		activeModal.result.then(
			async (result) => {
				if (result && result?.mergeCustomerIds?.length > 0) {
					if (result?.parentCustomerId) {
						this.listTabs.reloadTab(result?.parentCustomerId)
					}
					result?.mergeCustomerIds?.forEach((id, index) => {
						this.listTabs.closeTab(id)
					});
					this.datatable?.clearSelection()
					this.datatable?.refresh()
				}
			})
	}

	exportCustomers() {
		this.ngbModal.open(CustomerExportComponent, {
			size: 'md',
		}).componentInstance.selectedCustomerIds = this.selectedCustomerIds;
	}

	deleteCustomer(row: Customer) {
		this.customerService.buildDelete(row?.customerId).then(() => {
			this.handleEntityViewed(row?.customerId)
			this.listTabs.closeTab(`${row?.customerId}`);
			this.datatable?.refresh();
			this.helperService.successMessage("Customers successfully deleted")
		})
	}

	/**
	 * Delete Multiple Customers
	 */
	deleteCustomers() {
		this.selectedCustomerIds?.map((id) => {
			this.listTabs.closeTab(`${id}`);
		})

		this.customerService.buildDelete(this.selectedCustomerIds).then(() => {
			this.handleEntityViewed(this.selectedCustomerIds)
			this.datatable?.refresh();
			this.helperService.successMessage("Customers successfully deleted")
		});
	}

	saveSearch() {
		this.util.SetSheardData("customerSearch", this.search);
	}

	openCustomersImportDialog() {

		this.ngbModal
			.open(CustomerImportComponent, {
				size: 'lg',
			})
			.closed
			.pipe(untilDestroyed(this))
			.subscribe((isReload) => {
				if (isReload) {
					this.datatable?.refresh();
				}
			});

	}


	public getPrimaryContact(row: Customer) {
		var result = row.customerContacts?.find(x => x.isPrimary);

		return result ?? row.customerContacts[0];
	}

	copyTextClass = (type) => {
		let classes
		if (type == 'body') {
			classes = 'p-0'
		}
		return classes
	}

	copyToClipboard(event, text: string) {
		event.preventDefault();
		event.stopPropagation();
		this.clipboard.copy(text);
		this.lastCopiedText = text
	}

	async deleteDuplicatesContacts() {
        try {
            await this.customerService.deleteDuplicatesContacts();
			this.datatable?.refresh();
            this.helperService.successMessage("Duplicate contacts have been successfully deleted.");
        } catch (error) {
            this.helperService.errorMessage(error);            
        }
    }
	
	async deleteDuplicatesAddresses() {
        try {
            await this.customerService.deleteDuplicatesAddresses();
			this.datatable?.refresh();
            this.helperService.successMessage("Duplicate addresses have been successfully deleted.");
        } catch (error) {
            this.helperService.errorMessage(error);            
        }
    }

	handleRefreshViewedData() {
		this.viewEntityService.getStatisticByType(ViewedEntityTypeValueEnum.CUSTOMER)
	}

	handleEntityViewed(customerIds: number[] | number) {
		const statistic = this.viewEntityService.dataStore.statistics[ViewedEntityTypeEnum.CUSTOMER]
		const ids = Array.isArray(customerIds) ? customerIds : [customerIds]
		const unViewedIds = ids?.filter(id => includes(statistic?.ids, id))
		if (unViewedIds?.length > 0) {
			this.viewEntityService.markAsViewByIds(unViewedIds, ViewedEntityTypeValueEnum.CUSTOMER).then(() => {
				this.handleRefreshViewedData()
			}).catch((error) => {
				this.helperService.errorMessage(error)
			})
		}
	}

}