import { Component, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ListTabsComponent } from '@app/@shared/list-tabs/list-tabs.component';
import { Customer } from '@app/models/customer.model';
import { CustomerService } from '@app/shared/services/customer.service';
import { HelperService } from '@app/shared/services/helper.service';

@UntilDestroy()
@Component({
  	standalone: false,
	selector: 'app-customer-layout',
	templateUrl: './customer-layout.component.html',
	styleUrls: ['./customer-layout.component.scss'],

})

export class CustomerLayoutComponent {

	@ViewChild('listTabs') listTabs: ListTabsComponent;

	constructor(
		private helperService: HelperService,
		private customerService: CustomerService,
	) {

	}

	getTabTitle = (value: Customer, key) => {
		return value?.customerName || 'Loading...';
	}

	updateTabData(data) {
		const tabId = this.listTabs.activeTab;
		this.listTabs.setTab(tabId, { data });
	}

	loadTabData = async (customerId: string) => {

		return await this.customerService.get(customerId).then((res) => {
			const customer = res;
			if (customer.customerEmails.length === 0) {
				customer.customerEmails.push({
					isActive: true,
				});
			}
			return customer;
		}).catch(() => {
			this.helperService.errorMessage("Customers not found.")
			this.listTabs.closeTab(customerId, true);
		})
	}
}