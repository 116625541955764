import { Component, Input, OnInit } from '@angular/core';
import { OrderStop } from '@app/models/order-stop.model';
import { Order } from '@app/models/order.model';
import { RouteItemStop, RouteItemType } from '@app/models/route-item-stop.model';
import { RouteStopGroup } from '@app/models/route-stop-group.model';
import { Route } from '@app/models/route.model';
import { ApiService } from '@app/services/api.service';
import { HelperService } from '@app/shared/services/helper.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { chain, cloneDeep, compact, flatten, groupBy, isEqual, map, sumBy, uniq } from 'lodash';
import { Clipboard } from '@angular/cdk/clipboard';
import { debounceTime, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RoutesService } from '@app/shared/services/router.service';
@UntilDestroy()
@Component({
  standalone: false,
  selector: 'app-copy-details-to-driver',
  templateUrl: './copy-details-to-driver.component.html',
  styleUrls: ['./copy-details-to-driver.component.scss']
})
export class CopyDetailsToDriverComponent implements OnInit {

  @Input() title: string;
  @Input() route: Route;

  routeStopGroups: RouteStopGroup[];

  private _routeStopGroupsSearch: any = {
    Page: 1,
    ItemsPerPage: 20,
    status: 0,
  };

  @Input()
  public get routeStopGroupsSearch(): any {
    return this._routeStopGroupsSearch;
  }
  public set routeStopGroupsSearch(value: any) {
    this._routeStopGroupsSearch = (value);

    if (value?.routeId) {
      this.isLoading = true;
      this.getRouteItemGroups(value);
    }
  }

  private _routeItemStop: RouteItemStop;
  @Input()
  public get routeItemStop(): RouteItemStop {
    return this._routeItemStop;
  }
  public set routeItemStop(value: RouteItemStop) {
    this._routeItemStop = value;

    if (value) {
      this.parseMessages();
      this.isLoading = false;
      this.isSingleStop = true;
    }
  }

  private _order: Order;
  @Input()
  public get order(): Order {
    return this._order;
  }
  public set order(value: Order) {
    this._order = value;
    if (value) {
      this.parseMessages();
      this.isLoading = false;
    }
  }

  private _orderStop: OrderStop;
  @Input()
  public get orderStop(): OrderStop {
    return this._orderStop;
  }
  public set orderStop(value: OrderStop) {
    this._orderStop = value;
    if (value) {
      this.parseMessages();
      this.isLoading = false;
    }
  }

  private _isGroup?: boolean;
  @Input()
  public get isGroup(): boolean {
    return this._isGroup;
  }
  public set isGroup(value: boolean) {
    this._isGroup = value;
    if (this._isGroup) {
      this.messageGroup();
    }
    else {
      this.parseMessages()
    }

  }

  content: any
  messages: any
  messagesClone: any;
  messageWithSameAddress: any
  isLoading?: boolean = true;
  isSingleStop?: boolean = false;
  hasSameAddress?: boolean = false;
  loadMoreLoading?: boolean = false;
  loadRouteGroup$ = new Subject();
  hasMorePage: boolean = false;
  blindStopsData: Array<{ orderStopId: number; customerName: string }> = [];
  orderItems: any = [];
 

  constructor(
    private api: ApiService,
    private activeModal: NgbActiveModal,
    private helperService: HelperService,
    private routesService: RoutesService,
    private clipboard: Clipboard
  ) { }

  ngOnInit(): void {

    this.loadRouteGroup$
      .pipe(
        debounceTime(400),
        untilDestroyed(this),
      )
      .subscribe((request: any = {}) => {
        this.getRouteItemGroups({ ...this.routeStopGroupsSearch, ...request })
      });

    this.copyMessage();
  }

  async parseMessages() {
    let messages = [];

    if (this._routeItemStop) {
      messages = await this.parseRouteItem(this._routeItemStop);
    } else if (this.routeStopGroups) {
      messages = this.parseRouteItemGroups(this.routeStopGroups);
    } else if (this._orderStop) {
      messages = this.parseOrderStop(this._orderStop);
    } else if (this._order) {
      messages = this.parseOrder(this._order);
    }
    this.messagesClone = cloneDeep(messages)
    this.messages = messages
    this.copyMessage();
    if (this.isGroup) {
      this.messageGroup()
    }

    const messageString = messages.map((msg) => {
      return this.groupMessageByComparer(msg)
    });

    const uniqueMessageString = uniq(messageString);

    this.hasSameAddress = messageString.length !== uniqueMessageString.length;
    
    if (this.isSingleStop) {
      const sameAddressString = this.messageWithSameAddress.map((address) => {
        return this.groupMessageByComparer(address);
      });

      const stopsSameAddress = [...messageString, ...sameAddressString];

      const uniqueStopSameAddress = uniq([...messageString, ...sameAddressString]);

      this.hasSameAddress = uniqueStopSameAddress.length !== stopsSameAddress.length;
    }
  }

  private messageGroup() {
    if (this.messageWithSameAddress?.length > 0) {
      const selectedMessage = this.messageWithSameAddress.filter((item: any) => !!item?.isSelected)
      this.messages = [...this.messagesClone, ...selectedMessage]
    }
    this.messagesClone = cloneDeep(this.messages)
    const messageGroup = groupBy(this.messages, (messageData) => {
      return this.groupMessageByComparer(messageData);
    });
    this.messages = Object.values(messageGroup)
    if (this.messageWithSameAddress?.length > 0) {
      this.messageWithSameAddress = this.messageWithSameAddress.filter((item: any) => !item?.isSelected)
    }
    this.copyMessage();
  }

  private getPhoneNumber(phone, extension) {
    if (phone && extension) {
      return `${phone} x ${extension}`
    }
    else if (phone) {
      return phone
    }
    return null
  }

  private parseStop(stop) {
    const isBlind = this.isBlindOrderStop(stop.orderStop);
    const stopLabel = this.getStopLabel(stop);

    const matchedBlindStop = this.blindStopsData.find(
      (bsd) => bsd.orderStopId === stop?.orderStop?.orderStopId
    );
  
    const customerName = matchedBlindStop?.customerName
      ? matchedBlindStop.customerName
      : isBlind
        ? 'Shimon\'s Express'
        : stop?.customer?.customerName;
    
    return {
      type: stopLabel,
      name: stop?.orderStop?.locationCompanyName || stop?.address?.name,
      locationContactName: stop?.orderStop?.locationContactName,
      locationContactNumber: this.getPhoneNumber(stop.orderStop?.locationContactNumber, stop?.orderStop?.locationContactNumberExtension),
      locationContactEmail: stop.orderStop?.locationContactEmail,
      fullAddress: stop.address?.fullAddress,
      addressDetails: stop?.orderStop?.addressDetails,
      referenceNumber: stop?.referenceNumber,
      orderNumber: stop?.orderNumber,
      customerName: customerName,
      routeItems: stop?.routeItemsText && !isBlind ? stop?.routeItemsText : [],
      stopId: stop?.orderStop?.orderStopId || stop?.transferStop?.transferStopId,
      // routeItems: !isBlind ? stop.routeItems?.map(({ orderItem, quantity }) => {
      //   return `${orderItem.itemName} - ${quantity} ${orderItem.itemTypeQty}`
      // }) : [],
    };
  }

  private async parseRouteItem(routeItemStop) {  
    this.loadBlindStopsDataByRouteItemStop(routeItemStop);
    routeItemStop.routeItemsText = this.routeItemTexts(routeItemStop, routeItemStop?.routeItems)
    const messageData = this.parseStop(routeItemStop);
    const currentMessageAddress = this.groupMessageByComparer(messageData);
    this.messageWithSameAddress = await this.parseRouteItemGroups(this.routeStopGroups)
      .filter((item) => {        
        return currentMessageAddress == this.groupMessageByComparer(item) && !isEqual(item, messageData)
      });
    return [messageData];
  }

  private parseRouteItemGroups(routeStopGroups) {
    const groupedStops = chain(routeStopGroups)
      .map('routeItemStops')
      .flatten()
      .filter((stop) => stop.status === 0)
      .compact()
      .map((value: any, key) => {
        value.routeItemsText = this.routeItemTexts(value, value?.routeItems)
        return value
      })
      .value();

    const routeItemStops = routeStopGroups?.flatMap((routeStopGroup: any) => routeStopGroup.routeItemStops) || [];
    for (const routeItemStop of routeItemStops) {
      this.loadBlindStopsDataByRouteItemStop(routeItemStop);
    }
    return groupedStops.map((stop) => {
      return this.parseStop(stop);
    })
  }

  private parseOrderStop(orderStop) {
    const stops = chain(this._order?.routes)
      .map('routeStopGroups')
      .flatten()
      .map('routeItemStops')
      .flatten()
      .filter((stop) => {
        return (stop?.orderStop?.orderStopId == orderStop?.orderStopId &&
          stop?.orderStop?.orderStopType == orderStop?.orderStopType &&
          stop?.orderStop?.isActive)
      })
      .compact()
      .value();

    this.orderItems = this._order?.orderItems;
    const groupedStops = this.menageGroupSameStopAndSameOrder(stops)

    this.loadBlindStopsDataByOrder();
    return groupedStops.map((stop) => {
      return this.parseStop(stop);
    })
  }

  private parseOrder(order) {
    const stops = chain(order?.routes)
      .map('routeStopGroups')
      .flatten()
      .map('routeItemStops')
      .flatten()
      .filter((routeItemStop) => {
        return (routeItemStop?.orderStop?.isActive && routeItemStop.status === 0)
      })
      .compact()
      .value();

    this.orderItems = this._order?.orderItems;
    const groupedStops = this.menageGroupSameStopAndSameOrder(stops)

    this.loadBlindStopsDataByOrder();
    return groupedStops.map((stop) => {
      return this.parseStop(stop);
    })
  }

  private routeItemTexts(stop, routeItems = null) {
    const isBlind = this.isBlindOrderStop(stop.orderStop);
    if (isBlind) {
      return []
    }
    var activeOrderItemStop = stop?.orderStop?.orderItemStops?.filter(x => x.isActive) || [];

    if (!activeOrderItemStop.length) {
      return [];
    }

    let orderItems = routeItems ? routeItems.map(x => x.orderItem) : this.orderItems;    

    const validOrderItemStops = activeOrderItemStop.filter(orderItemStop => {
      return orderItems?.some(
        item => item.orderItemId === orderItemStop.orderItemId
      );
    });

    let routeItemGroup: any = groupBy(cloneDeep(validOrderItemStops), (orderItemStop) => {
      const orderItem = orderItems?.find(
        item => item.orderItemId === orderItemStop.orderItemId
      );

      orderItemStop.orderItem = orderItem;
      return `${orderItemStop.orderItemStopId} ${orderItem?.itemName} ${orderItem?.itemTypeQty}`;
    });

    return map(routeItemGroup, (val, keys) => {
      const totalQty = sumBy(val, (item: any) => Number(item?.quantity) || 0)
      const { orderItem } = val[0]
      return `${orderItem?.itemName} - ${totalQty || 0} ${orderItem?.itemTypeQty}`

    })
  }

  private menageGroupSameStopAndSameOrder(stops: any) {
    const items = chain(cloneDeep(stops))
      .groupBy((stop) => {
        return `${stop?.type} ${stop?.orderNumber} ${stop?.orderStop?.orderStopId || stop?.transferStop?.transferStopId}`
      })
      .map((value: any, key) => {
        if (value?.length == 0) {
          value.routeItemsText = this.routeItemTexts(value[0])
          return value
        }
        if (value?.length > 0) {
          value[0].routeItemsText = this.routeItemTexts(value[0])
          return value[0]
        }
        return value
      })
      .value()
    return items
  }

  private isBlindOrderStop(orderStop) {
    return orderStop?.isBlindStop;
    // return Boolean(orderStop.isBlindStop || orderStop.blindCustomerName || orderStop.blindCustomer || orderStop.blindCustomer || orderStop.blindAddress || orderStop.blindAddressAddressId);
  }

  private groupMessageByComparer(messageData) {
    const parts = [
      messageData.type?.trim() || '',
      this.normalizeName(messageData.name?.trim() || ''),
      messageData.locationContactNumber?.trim() || '',
      this.normalizeAddress(messageData.fullAddress?.trim() || '')
    ];
  
    return parts.filter(part => part).join(' ');
  }
  
  private normalizeName(name: string): string {
    if (!name) return '';
    let result = name.toLowerCase();
  
    result = result.replace(/'/g, ''); // Remove apostrophes
    result = result.replace(/[^a-z0-9\s]/g, ''); // Remove non-alphanumeric characters except spaces
  
    // Remove common suffixes
    const suffixes = ['intl', 'international', 'inc', 'llc', 'corp', 'co', 'company', 'ltd', 'se'];
    const suffixRegex = new RegExp(`\\b(${suffixes.join('|')})\\b`, 'g');
    result = result.replace(suffixRegex, '');
  
    result = result.replace(/\s+/g, ' '); // Replace multiple spaces with single space
    result = result.trim();
    return result;
  }
  
  private normalizeAddress(address: string): string {
    if (!address) return '';
    let result = address.toLowerCase();
  
    // Expand common abbreviations
    const abbreviations = {
      '\\bst\\b': 'street',
      '\\bave\\b': 'avenue',
      '\\brd\\b': 'road',
      '\\bblvd\\b': 'boulevard',
      '\\bdr\\b': 'drive',
      '\\bln\\b': 'lane',
      '\\bct\\b': 'court',
      '\\bpl\\b': 'place',
      '\\bunit\\b': '',
      '\\bapt\\b': '',
      '\\bsuite\\b': '',
      '\\bste\\b': '',
      '\\bno\\b': '',
      '\\bse\\b': '',
    };
  
    for (const [abbr, full] of Object.entries(abbreviations)) {
      const regex = new RegExp(abbr, 'g');
      result = result.replace(regex, full);
    }
  
    // Remove unit numbers (e.g., "unit 203", "apt 3B")
    result = result.replace(/\b(unit|apt|suite|ste|no)\s*\S*/g, '');
    // Remove numbers following '#' (e.g., "#203")
    result = result.replace(/#\s*\S*/g, '');
    // Remove zip code extensions (e.g., "-5040" in "08701-5040")
    result = result.replace(/-\d{4}\b/, '');
  
    // Remove punctuation
    result = result.replace(/[^\w\s]/g, '');
  
    result = result.replace(/\s+/g, ' '); // Replace multiple spaces with single space
    result = result.trim();
    return result;
  }

  private convertMessageToTelegram(messageData) {
    const stopText = this.convertMessageToHtml(messageData);
    return this.convertMessageHtmlToTelegram(stopText)
  }

  private convertMessageHtmlToTelegram(html) {
    return html
      .replace(/<b class="[^"]+">/g, '**')
      .replace(/<b>/g, '**')
      .replace(/<\/b>/g, '**')
      .replace(/<br \/>/g, '\n')
      .replace(/<br>/g, '\n')
      .replace(/<hr \/>/g, '----------------\n')
      .replace(/<hr>/g, '----------------\n')
  }

  private convertMessageToHtml(messageData) {

    const formatMessageDetails = (data) => {
      const messageTypeClass = (data.type === 'Pickup From' || data.type === 'Pickup From Transfer') ? 'text-primary' : 'text-danger';
      const messageTypeLabel = `<b class="${messageTypeClass}">${data.type}:</b>`;
      const fullAddress = [data.name, data.fullAddress].filter(text => text).join(', ');
      const addressDetails = data.addressDetails ? ` [${data.addressDetails}]` : '';
      return `${messageTypeLabel} ${fullAddress} ${addressDetails}`;
    }

    if (messageData instanceof Array) {

      const firstMessages = messageData[0];
      let message = [
        formatMessageDetails(firstMessages),
        firstMessages?.locationContactName ? `<b>Contact Name:</b> ${firstMessages.locationContactName}` : null,
        firstMessages?.locationContactNumber ? `<b>Phone:</b> ${firstMessages.locationContactNumber}` : null
        // firstMessages?.locationContactEmail ? `<b>Email:</b> ${firstMessages.locationContactEmail}` : null,
      ]

      messageData.forEach((data) => {
        const extraData = [
          data.referenceNumber ? `<b>Reference:</b> ${data.referenceNumber}` : null,
          `[ <b>Order Number:</b> ${data.orderNumber} ]`,
          `<b>For:</b> ${data.customerName}`,
          data.routeItems?.length > 0 ? `<b>Items:</b> ${data.routeItems?.join(', ')}` : null
        ]
          .filter(text => text);

        // Add divider if more the 1 message in group
        if (messageData.length > 1) {
          extraData[0] = `<hr /> ${extraData[0]}`;
        }

        message = message.concat(extraData);
      })

      return message
        .filter(text => text)
        .join('<br /><br />')
    } else {
      const messageHtml = [
        formatMessageDetails(messageData),
        messageData?.locationContactName ? `<b>Contact Name:</b> ${messageData.locationContactName}` : null,
        messageData?.locationContactNumber ? `<b>Phone:</b> ${messageData.locationContactNumber}` : null,
        // messageData?.locationContactEmail ? `<b>Email:</b> ${messageData.locationContactEmail}` : null,
        messageData.referenceNumber ? `<b>Reference:</b> ${messageData.referenceNumber}` : null,
        `[ <b>Order Number:</b> ${messageData.orderNumber} ]`,
        `<b>For:</b> ${messageData.customerName}`,
        messageData.routeItems?.length > 0 ? `<b>Items:</b> ${messageData.routeItems?.join(', ')}` : null
      ]
      return messageHtml
        .filter(text => text)
        .join('<br /><br />')
    }
  }

  generateMessage(type: 'copy' | 'telegram' = 'copy') {
    const message = map(this.messages, (message) => this.convertMessageToTelegram(message))
    const sameAddressMessage = chain(this.messageWithSameAddress)
      .filter(({ isSelected }) => isSelected == true)
      .map((message) => this.convertMessageToTelegram(message))
      .value();

    if (type === 'telegram') {
      return [...message, ...sameAddressMessage]
    }
    else {
      return message?.join('\n\n\n') + '\n\n\n' + sameAddressMessage?.join('\n\n\n')
    }
  }

  private copyMessage() {
    let text = this.generateMessage('copy') as string

    if (text) {
      // Remove Markdown bold markers (e.g., **text**)
      text = text.replace(/\*\*(.*?)\*\*/g, '$1');
          
      // Trim any leading or trailing whitespace from the text
      text = text.trim();
    }

    this.clipboard.copy(text);
  }

  updateCopy() {
    if (this.isGroup) {
      this.messageGroup()
    }
    this.copyMessage();
  }

  close() {
    this.activeModal.close();
  }

  onSubmit() {
    if (this.route?.driver?.telegramUserId) {
      const messages = this.generateMessage('telegram')

      this.api.StgsTlgSendMessage({ driverIds: [this.route.driver?.driverId], messages: messages }, () => {
        this.helperService.successMessage("The messages has been sent.");
      }, error => {
        this.helperService.errorMessage(error != null ? error : 'An error has ocurred', 'Error sending to driver');
      });
    } else {
      this.helperService.errorMessage('Missing driver\'s telegram user id', 'Error sending to driver');
    }

    this.close();
    this.helperService.successMessage("Stop details has been successfully copied.");
  }


  getRouteItemGroups(request) {

    if (!request?.routeId) {
      return
    }

    this.routesService.getRouteItemGroups(request).then((resp) => {
      if (resp?.page > 1) {
        this.routeStopGroups = this.routeStopGroups.concat(resp.list)
      } else {
        this.routeStopGroups = resp.list || [];
      }      
      const dataCount = this.routeStopGroups?.map(x => x.routeItemStops?.length ?? 0).reduce((a, b) => a + b, 0);
      this.hasMorePage = dataCount < resp?.totalCount     
      this.parseMessages(); 
    }).catch((error) => {
      this.routeStopGroups = []
      this.helperService.errorMessage(error)
    }).finally(() => {
      this.loadMoreLoading = false
      this.isLoading = false;
    })
  }

  loadMoreRouteGroups() {    
        this.loadMoreLoading = true
        this.routeStopGroupsSearch.Page++;
        this.loadRouteGroup$.next(this.routeStopGroupsSearch);    
  }

  getStopLabel(stop: RouteItemStop): string {
    const hasTransferStop = stop.routeItems?.some(
      (item) => item?.transferStopId !== null
    ) ?? false;

    switch (stop.type) {
        case RouteItemType.Delivery:
            return hasTransferStop ? 'Delivery To Transfer' : 'Deliver To';
        case RouteItemType.Pickup:
            return hasTransferStop ? 'Pickup From Transfer' : 'Pickup From';
        case RouteItemType.DeliveryToTransfer:
            return 'Delivery To Transfer';
        case RouteItemType.PickupFromTransfer:
            return 'Pickup From Transfer';
    }
    return '';
  }

  private loadBlindStopsDataByRouteItemStop(routeItemStop: any) {
    const blindItemStops = routeItemStop.routeItems?.flatMap((routeItem: any) => routeItem.orderItemStops)?.filter(
      (orderItemStop: any) => orderItemStop.orderStop?.isBlindStop === true);

    for (const blindStop of blindItemStops) {
      const matchingStops = routeItemStop.routeItems?.flatMap(
        (routeItem: any) => routeItem.orderItemStops)?.filter(
          (orderItemStop: any) =>
            orderItemStop.orderStop?.orderStopId !== blindStop.orderStopId &&
            orderItemStop.orderItemId === blindStop.orderItemId
        )?.map((os: any) => ({
          orderStopId: os.orderStopId,
          customerName: blindStop.orderStop.blindCustomerName,
        }));

      this.blindStopsData.push(...matchingStops);
    }
  }

  private loadBlindStopsDataByOrder(){
    this.blindStopsData = chain(this._order?.orderStops)
      .filter((stop) => stop.isBlindStop === true)
      .map((blindStop) => {
        const activeItemStops = blindStop.orderItemStops?.filter(
          (itemStop) => itemStop.isActive
        ) ?? [];

        let result = [];

        for (const activeItemStop of activeItemStops) {
          const matchingStops = (this._order?.orderStops || [])
            .filter((os) =>
              os.orderStopId !== blindStop.orderStopId &&
              os.orderItemStops?.some(
                (ois) =>
                  ois.orderItem?.orderItemId === activeItemStop.orderItem?.orderItemId
              )
            )
            .map((os) => ({
              orderStopId: os.orderStopId,
              customerName: blindStop.blindCustomerName,
            }));

          result.push(...matchingStops);
        }

        return result;
      })
      .flatten()
      .value();
  }
}
