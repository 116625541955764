import { AfterViewInit, Directive, Host, Input, Optional } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { get, set } from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ValidationError } from 'yup';

@UntilDestroy()
@Directive({
  standalone: false,
  selector: '[appFormValidate]',
  exportAs: 'appFormValidate'
})
export class FormValidateDirective implements AfterViewInit {

  @Input() validationSchema
  errors: any = null;
  values: any;
  isValid: boolean;

  errors$ = new Subject<any>();


  constructor(
    @Optional()
    @Host()
    public ngForm: NgForm
  ) { }

  ngAfterViewInit(): void {
    this.ngForm.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(10)
      )
      .subscribe((value) => {
        const parsedValue = this.parseFormKeys(value);
        this.values = parsedValue;

        if (this.validationSchema) {
          this.validate(parsedValue);
        } else {
          this.errors = this.collectNgFormErrors();
          this.errors$.next(this.errors);
        }

      })
  }

  getControlErrors(name): string[] {
    return get(this.errors, name, [])
  }

  private async validate(value: any) {
    try {
      await this.validationSchema.validate(value, { abortEarly: false });
      this.errors = this.collectNgFormErrors();
      this.errors$.next(this.errors);
      this.isValid = this.errors === null;
    } catch (err) {
      const yupErrors = this.yupErrorToErrorObject(err);
      const formErrors = this.collectNgFormErrors();
      this.errors = { ...formErrors, ...yupErrors };
      this.errors$.next(this.errors);
      this.isValid = false;
    }
  }

  reset(data?: any) {
    // Reset individual form controls' touched & dirty states
    Object.keys(this.ngForm.controls).forEach((controlName) => {
      this.ngForm.controls[controlName].markAsPristine();
      this.ngForm.controls[controlName].markAsUntouched();
      this.ngForm.controls[controlName].setErrors(null); // Clear validation errors
    });

    this.errors = null;
    this.errors$.next(null);
    this.isValid = true;
  }

  private yupErrorToErrorObject(err: ValidationError, isFlatObject = false) {

    const object: any = {};
    err?.inner?.forEach((x) => {
      if (x.path !== undefined) {
        if (isFlatObject) {
          object[x.path] = x.errors
        } else {
          set(object, x.path, x.errors)
        }
      }
    });
    return object;
  }

  private parseFormKeys(values: any) {
    const object: any = {};
    for (const key in values) {
      if (Object.prototype.hasOwnProperty.call(values, key)) {
        const value = values[key];
        set(object, key, value)
      }
    }
    return object;
  }

  private collectNgFormErrors() {
    const formErrors = {};
    Object.keys(this.ngForm.controls || {}).forEach((controlName) => {
      const control = this.ngForm.controls[controlName];
      if (control.errors) {
        set(formErrors, controlName, Object.values(control.errors));
      }
    });
    return Object.keys(formErrors).length > 0 ? formErrors : null;
  }

}