import { Component, Input, OnInit } from '@angular/core';
import { Expense } from '@models/expense.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HelperService } from '@app/shared/services/helper.service';
import { ExpenseService } from '@app/shared/services/expense.service';

@Component({
    standalone: false,
    selector: 'app-expense-details',
    templateUrl: './expense-details.component.html',
    styleUrls: ['./expense-details.component.scss'],
})
export class ExpenseDetailsComponent implements OnInit {

    title = "Expense"
    loading = true;
    @Input() item: Expense

    constructor(
        private ngbActiveModal: NgbActiveModal,
        private expenseService: ExpenseService,
        private helperService: HelperService
    ) {
    }

    ngOnInit() {
        if (this.item?.expenseId) {
            this.loadEntity();
        } else {
            this.item = {};
            this.loading = false;
        }
    }

    loadEntity() {
        // this.expenseService.get(this.item?.expenseId).then((expense) => {
        //     this.item = expense;
        //     this.loading = false;
        // }).catch(error => {
        //     this.helperService.errorMessage(error);
        // });
    }

    async save() {
        // try {
        //     let resp: any;
        //     if (this.item?.expenseId) {
        //         resp = await this.expenseService.update(this.item.expenseId, this.item)
        //     } else {
        //         resp = await this.expenseService.create(this.item)
        //     }
        //     this.helperService.successMessage(`${this.title} successfully ${this.item?.expenseId ? 'updated' : 'created'}`);
        //     this.ngbActiveModal.close(resp)

        // } catch (error) {
        //     this.helperService.errorMessage(error);
        // }
    }

    close(isReload?: boolean) {
        this.ngbActiveModal.close(isReload);
    }
}
