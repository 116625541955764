// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.version-info {
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}
.version-info h3 {
  margin-bottom: 8px;
}
.version-info p {
  font-size: 16px;
  margin: 4px 0;
}
.version-info .loading-spinner {
  display: inline-block;
  width: 1em;
  height: 1em;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-top: 2px solid #333;
  border-radius: 50%;
  animation: spin 0.6s linear infinite;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/settings/version/version.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,yBAAA;AACJ;AACI;EACE,kBAAA;AACN;AAEI;EACE,eAAA;EACA,aAAA;AAAN;AAGI;EACI,qBAAA;EACA,UAAA;EACA,WAAA;EACA,oCAAA;EACA,0BAAA;EACA,kBAAA;EACA,oCAAA;AADR;AAIM;EACE;IAAK,yBAAA;EADX;AACF","sourcesContent":[".version-info {\n    padding: 16px;\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    background-color: #f9f9f9;\n  \n    h3 {\n      margin-bottom: 8px;\n    }\n  \n    p {\n      font-size: 16px;\n      margin: 4px 0;\n    }\n\n    .loading-spinner {\n        display: inline-block;\n        width: 1em;\n        height: 1em;\n        border: 2px solid rgba(0, 0, 0, 0.2);\n        border-top: 2px solid #333;\n        border-radius: 50%;\n        animation: spin 0.6s linear infinite;\n      }\n      \n      @keyframes spin {\n        to { transform: rotate(360deg); }\n      }\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
