import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiResponse } from '@app/models/core/base';
import { PricingModel } from '@app/models/pricing-model.model';
import { toParams } from 'src/utils/to-params';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PricingModelService {
  private basePath = 'PricingModel';

  private _reloadPricingModels: BehaviorSubject<any>;
  private _pricingModelDatatableRefresh: BehaviorSubject<boolean>;

  constructor(private http: HttpClient) {
    this._reloadPricingModels = new BehaviorSubject(null);
    this._pricingModelDatatableRefresh = new BehaviorSubject(false);
  }

  public get $reloadPricingModels(): Observable<boolean> {
    return this._reloadPricingModels.asObservable();
  }

  public set reloadPricingModels(value: boolean) {
    this._reloadPricingModels.next(value);
  }

  public get $pricingModelDatatableRefresh(): Observable<boolean> {
    return this._pricingModelDatatableRefresh.asObservable();
  }

  public set pricingModelDatatableRefresh(value: boolean) {
    this._reloadPricingModels.next(value);
  }

  getAllPricingModels(customerId?: string | number) {
    const params = customerId ? toParams({ customerId }) : {};
    return this.http.get<ApiResponse<PricingModel>>(`${this.basePath}`, { params }).toPromise()
      .then(({ data }) => data);
  }

  updatePricingModel(pricingModel: PricingModel) {
    return this.http.post<ApiResponse<PricingModel>>(`${this.basePath}`, pricingModel).toPromise();
  }

  revertToDefault(pricingModelId?: number, customerId?: string | number) {
    let url = `${this.basePath}/revert-to-default`;

    let params = new HttpParams();
  
    if (pricingModelId) {
      params = params.set('pricingModelId', pricingModelId.toString());
    }
  
    if (customerId) {
      params = params.set('customerId', customerId.toString());
    }
  
    return this.http.put<ApiResponse<PricingModel>>(url, {}, { params }).toPromise();
  }

  deleteCustomerPricingModel(customerId: string | number) {
    const params = new HttpParams().set('customerId', customerId.toString());
    return this.http.delete<ApiResponse<PricingModel>>(`${this.basePath}/customer-pricing-model`, { params }).toPromise();
  }

  generatePricingModelFromInvoices(pricingModel: PricingModel) {
    return this.http.post<ApiResponse<PricingModel>>(`${this.basePath}/generate-from-invoices`, pricingModel).toPromise()
      .then(({ data }) => data);
  }
}