import {
	Directive,
	EventEmitter,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

@Directive({
	standalone: false,
	selector: '[debounceClick]'
})
export class DebounceClickDirective implements OnInit, OnDestroy {

	@Input() debounceTime = 300;
	@Output() throttledClick = new EventEmitter();

	private click = new Subject();
	private subscription: Subscription;

	ngOnInit() {
		this.subscription = this.click
			.pipe(
				debounceTime(this.debounceTime),
				tap((e) => this.throttledClick.emit(e))
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	@HostListener('click', ['$event'])
	clickEvent(event: Event) {
		event.preventDefault();
		event.stopPropagation();
		this.click.next(event);
	}
}