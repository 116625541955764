import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FilesViewerModalComponent } from '@app/@shared/files-viewer-modal/files-viewer-modal.component';
import { Attachment } from '@app/models/attachment.model';
import { Order } from '@app/models/order.model';
import { HelperService } from '@app/shared/services/helper.service';
import { ImagesService } from '@app/shared/services/images.service';
import { OrderService } from '@app/shared/services/order.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy } from '@ngneat/until-destroy';
import { saveAs } from 'file-saver';
import mime from 'mime';

@UntilDestroy({ checkProperties: true })
@Component({
  	standalone: false,
	selector: 'app-attachment-details',
	templateUrl: './attachment-details.component.html',
	styleUrls: ['./attachment-details.component.scss']
})
export class AttachmentDetailsComponent implements OnInit {

	@Input() order: Order

	orderSheetFile: any = [];
	bolFile: any = [];
	orderImages: any = [];
	@Input() orderAttachments: Attachment[] = [];
	@Output() uploadOrderAttachments = new EventEmitter();

	constructor(
		private readonly helperService: HelperService,
		private readonly imagesService: ImagesService,
		private readonly orderService: OrderService,
		private readonly modalService: NgbModal,
	) { }

	ngOnInit(): void {
	}

	async uploadAttachments(files: any[]) {
		this.uploadOrderAttachments.emit(files);
	}

	/**
	 * Delete attachment
	 * 
	 * @param attachment 
	 */
	async deleteOrderAttachment(attachment: Attachment) {
		try {
			if (attachment) {
				await this.imagesService.deleteAttachment(attachment.attachmentId);
				this.helperService.successMessage("Order attachment deleted successfully.");
				const index = this.orderAttachments.indexOf(attachment, 0);
				if (index > -1) {
					this.orderAttachments.splice(index, 1);
				}

				if (this.order?.orderStops) {
					this.order.orderStops = this.order?.orderStops?.map(orderStop => {
						if (orderStop?.attachments) {
							orderStop.attachments = orderStop.attachments.filter(attachmentItem =>
								attachmentItem.attachmentId !== attachment?.attachmentId
							);
						}
						if (orderStop?.orderItemStops) {
							orderStop.orderItemStops.forEach(orderItemStop => {
								if (orderItemStop?.orderItem?.attachments) {
									orderItemStop.orderItem.attachments = orderItemStop.orderItem.attachments.filter(attachmentItem =>
										attachmentItem.attachmentId !== attachment?.attachmentId
									);
								}
							});
						}
						return orderStop;
					});
				}


			}
		} catch (error) {
			this.helperService.errorMessage(error);
		}
	}

	deleteBolFile(index) {
		this.bolFile.splice(index, 1)
	}

	deleteOrderSheetFile(index) {
		this.orderSheetFile.splice(index, 1)
	}

	uploadBOLFile(files) {
		for (let index = 0; index < files.length; index++) {
			const file = files[index];
			this.bolFile.push(file)
		}
	}

	uploadOrderSheetFile(files) {
		for (let index = 0; index < files.length; index++) {
			const file = files[index];
			this.orderSheetFile.push(file)
		}
	}

	uploadOrderImages(files) {
		for (let index = 0; index < files.length; index++) {
			const file = files[index];
			console.log(file);

			this.orderImages.push(file)
		}
	}

	openAttachment(attachment: Attachment) {
		this.imagesService.downloadAttachmentById(attachment?.attachmentId).then(res => {
			saveAs(new Blob([res]), attachment.name);
		}).catch((error) => { console.log(error) });
	}

	viewAttachment(attachment) {
		this.helperService.isLoading = true
		this.imagesService.downloadAttachmentById(attachment?.attachmentId).then((res) => {
			const blob = new Blob([res], { type: mime.getType(attachment.name) });
			const fileURL = window.URL.createObjectURL(blob);
			this.helperService.isLoading = false
			window.open(fileURL, '_blank');
		}).catch((error) => {
			this.helperService.isLoading = false
			this.helperService.errorMessage(error)
		})
	}

	async viewAttachmentModal(attachments) {
		const activeModal = this.modalService.open(FilesViewerModalComponent, {
			scrollable: true,
			size: 'xl',
			centered: true,
		});
		activeModal.componentInstance.isAttachments = true;
		activeModal.componentInstance.files = Array.isArray(attachments) ? attachments : [attachments];
		
	}

}
