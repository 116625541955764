// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .form-check-label {
  display: flex;
  width: 100%;
}
:host .form-check-input {
  margin-right: 8px;
  flex-shrink: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/form/checkbox/checkbox.component.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EAEA,WAAA;AADR;AAGI;EACI,iBAAA;EACA,cAAA;AADR","sourcesContent":[":host {\n    .form-check-label {\n        display: flex;\n        // align-items: center;\n        width: 100%;\n    }\n    .form-check-input {\n        margin-right: 8px;\n        flex-shrink: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
