import { Component, Input, Output, EventEmitter } from '@angular/core';
import { RouteItemStop, RouteItemType } from '@models/route-item-stop.model';
import { EntityTypes } from '@models/entity-type.model';
import { UtilService } from '@app/services/util.service';
import { OrderStopType } from '@app/models/route-box.model';

@Component({
  standalone: false,
  selector: 'app-confirm-stop-items-table',
  templateUrl: './confirm-stop-items-table.component.html',
  styleUrls: ['./confirm-stop-items-table.component.scss']
})
export class ConfirmStopItemsTableComponent {
  @Input() confirmStop: RouteItemStop;
  @Input() util: UtilService;
  @Input() EntityTypes = EntityTypes;
  @Input() RouteItemType = RouteItemType;
  @Input() showUnallocatedItems: boolean;

  @Input() getRemainingQty: (stopItem: any) => number;
  @Input() getPickupStatus: (stopItem: any) => string;
  @Input() getActivityDotColor: (stopItem: any) => string;

  @Output() changeQtyDownEvent = new EventEmitter<any>();
  @Output() changeQtyUpEvent = new EventEmitter<any>();
  @Output() editItemEvent = new EventEmitter<{ stopItem: any, index: number }>();
  @Output() addItemEvent = new EventEmitter<{ stopItem: any }>();

  changeQtyDown(stopItem) {
    this.changeQtyDownEvent.emit(stopItem);
  }

  changeQtyUp(stopItem) {
    this.changeQtyUpEvent.emit(stopItem);
  }

  editConfirmStopItem(stopItem, si) {
    this.editItemEvent.emit({ stopItem, index: si });
  }

  addConfirmStopItem(stopItem) {
    this.addItemEvent.emit({ stopItem });
  }
}
