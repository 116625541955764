import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { DatePipe } from '@angular/common';
import { UtilService } from '@app/services/util.service';
import moment from "moment"
import { OrderStopDateTypeEnum, OrderStopTimeTypeEnum } from '@app/models/order-stop.model';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { DateTimeService } from '@app/shared/services/datetime-service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DialogService } from '@app/@shared/dialogs/dialog.service';
import { find, some } from 'lodash';


@Component({
    standalone: false,
    selector: 'app-pickup-datetimepicker',
    templateUrl: './pickup-datetimepicker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./pickup-datetimepicker.component.scss'],
    providers: [DatePipe],
})
export class PickupDatetimepickerComponent implements OnInit {
    public isOpened = false;
    public calendarVisible = false;
    _minDateForLabel: Date
    _maxDateForLabel: Date

    today: Date;
    tomorrow: Date;

    showDateOptions: boolean = true;

    dateOptions: any[] = [
        {
            title: 'Today',
            value: moment()
        },
        {
            title: 'Tomorrow',
            value: moment().add(1, 'days')
        },
    ];


    OrderStopDateTypeEnum = OrderStopDateTypeEnum;
    OrderStopTimeTypeEnum = OrderStopTimeTypeEnum;
    timeError: string;


    public get dateType(): OrderStopDateTypeEnum {
        if (moment().isSame(this.date, 'day')) {
            return OrderStopDateTypeEnum.TODAY;
        } else if (moment().add(1, 'day').isSame(this.date, 'day')) {
            return OrderStopDateTypeEnum.TOMORROW;
        } else {
            return OrderStopDateTypeEnum.NONE;
        }
    }

    @Input() date: Date;

    @Input() timeOnly: boolean;
    @Input() dateOnly: boolean;
    @Output() dateTypeChange = new EventEmitter<any>();
    @Output() dateChange = new EventEmitter<any>();

    @Input() timeType: OrderStopTimeTypeEnum;
    @Input() timeTypeShow: boolean = true;
    @Input() time: Date;
    @Output() timeTypeChange = new EventEmitter<any>();
    @Output() timeChange = new EventEmitter<any>();

    timePart: Partial<NgbTimeStruct> = null;

    view: CalendarView = CalendarView.Month;
    viewDate: Date = new Date();
    events: CalendarEvent[] = [];
    lastSelectedDate: Date;
    clickedDate: Date;
    clickedColumn: number;
    timeToDisplay: Date;

    @Input()
    set minDateForLabel(minDateForLabel: Date) {
        this._minDateForLabel = minDateForLabel;
        if (minDateForLabel) {
            if ((minDateForLabel && moment().isBefore(minDateForLabel, 'date'))) {
                this.removeDateOption(false, 'Today');
            } else {
                this.addDateOption(true, 'Today');
            }
            if ((minDateForLabel && moment().add(1, 'day').isBefore(minDateForLabel, 'date'))) {
                this.removeDateOption(false, 'Tomorrow');
            } else {
                this.addDateOption(true, 'Tomorrow');
            }
        }
    }
    get minDateForLabel(): Date {
        return this._minDateForLabel;
    }

    @Input()
    set maxDateForLabel(maxDateForLabel: Date) {
        this._maxDateForLabel = maxDateForLabel;
        if (maxDateForLabel) {
            if ((maxDateForLabel && moment().isAfter(maxDateForLabel, 'date'))) {
                this.removeDateOption(false, 'Today');
            } else {
                this.addDateOption(true, 'Today');
            }
            if ((maxDateForLabel && moment().add(1, 'day').isAfter(maxDateForLabel, 'date'))) {
                this.removeDateOption(false, 'Tomorrow');
            } else {
                this.addDateOption(true, 'Tomorrow');
            }
        }
    }
    get maxDateForLabel(): Date {
        return this._maxDateForLabel;
    }


    constructor(
        private dialogService: DialogService,
        private dateTimeService: DateTimeService,
        private cd: ChangeDetectorRef
    ) {

    }

    ngOnInit() {
        this.lastSelectedDate = this.date;
        if (this.time) {
            let hour = moment(this.time).get('hour');
            const minute = moment(this.time).get('minute');
            if (this.timeType === OrderStopTimeTypeEnum.CUSTOM) {
                this.timePart = {
                    hour: hour,
                    minute: minute,
                    second: 0
                }
            }

            this.timeToDisplay = moment(this.date).set('hour', hour).set('minute', minute).toDate();
        }
    }

    removeDateOption(isShow, title) {
        this.showDateOptions = isShow;
        this.dateOptions = this.dateOptions.filter(item => item.title !== title);
    }

    addDateOption(isShow, title) {
        this.showDateOptions = isShow;

        const existingOption = find(this.dateOptions, { title });
    
        if (!existingOption) {
            if (title === 'Today') {
                this.dateOptions.push({
                    title: 'Today',
                    value: moment()
                });
            } else if (title === 'Tomorrow') {
                this.dateOptions.push({
                    title: 'Tomorrow',
                    value: moment().add(1, 'days')
                });
            }
        }
    }


    setTimeType(type) {
        this.timeType = type;
        this.time = null;
        this.timeTypeChange.emit(this.timeType);
        this.timeChange.emit(null);
        this.isOpened = false;
    }


    onTimeChange(time?: Partial<NgbTimeStruct>) {
        this.setTime();
    }

    async checkDateValidate(date) {
        let message = ''

        if (this.maxDateForLabel && moment(date).isAfter(this.maxDateForLabel, 'date')) {
            message = 'You have a pickup date selected after the delivery date. Are you sure want to select?'
        }
        else if (this.minDateForLabel && moment(date).isBefore(this.minDateForLabel, 'date')) {
            message = 'You have a delivery date selected before the pickup date. Are you sure want to select?'
        }
        if (message) {
            return this.dialogService.confirm({
                message: message,
            }, { size: 'sm' })
        }
        return true
    }

    async setDate(date: string | Date) {
        let saveDate
        if (date) {
            saveDate = moment(date).startOf('day').toDate();
        }
        try {
            await this.checkDateValidate(saveDate)
            this.date = saveDate
            this.lastSelectedDate = saveDate
            this.dateTypeChange.emit(this.dateType);
            this.dateChange.emit(this.date);
        } catch (error) {
            this.date = this.lastSelectedDate
            this.cd.detectChanges();
        }
    }

    setTime(time?: string) {
        switch (time) {
            case 'None':
                this.time = moment.utc(3, "HH").toDate();
                this.timeType = OrderStopTimeTypeEnum.NONE
                break;

            case 'Morning':
                this.time = moment.utc(9, "HH").toDate();
                this.timeType = OrderStopTimeTypeEnum.MORNING
                break;

            case 'Noon':
                this.time = moment.utc(15, "HH").toDate();
                this.timeType = OrderStopTimeTypeEnum.NOON
                break;

            case 'Evening':
                this.time = moment.utc(20, "HH").toDate();
                this.timeType = OrderStopTimeTypeEnum.EVENING
                break;

            default:

                if (this.timePart) {
                    const today = moment(this.date)
                    today.set('hour', this.timePart?.hour || 12);
                    today.set('minute', this.timePart?.minute || 0);
                    today.set('second', 0);

                    this.time = today.toDate();
                    this.timeToDisplay = this.time;
                    this.timeType = OrderStopTimeTypeEnum.CUSTOM
                    this.timeChange.emit(this.time);
                }

                break;
        }

        this.timeChange.emit(this.time);
        this.timeTypeChange.emit(this.timeType);
    }


    timeTypeToLabel(type) {
        switch (type) {
            case 'Morning':
                return "Morning 6:00 AM - 12:00 PM";

            case 'Noon':
                return "Afternoon 12:00 PM - 5:00 PM";

            case 'Evening':
                return "Evening 5:00 PM - 12:00 AM";


            default:
                return ""
        }
    }

}
