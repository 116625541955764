import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogService } from '@app/@shared/dialogs/dialog.service';
import { RouteBoxUIGroup } from '@app/helpers/route-box-ui-group';
import { Order, OrderStatusEnum } from '@app/models/order.model';
import { Route, Routes } from '@app/models/route.model';
import { VehicleLocation } from '@app/models/samsara/vehicle-location.model';
import { SamsaraService } from '@app/services/samsara.service';
import { AppService } from '@app/shared/services/app.service';
import { AuthService } from '@app/shared/services/auth/auth.service';
import { HelperService } from '@app/shared/services/helper.service';
import { RoutesService } from '@app/shared/services/router.service';
import { environment } from '@environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { includes, sumBy } from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  standalone: false,
  selector: 'app-right-side-bar',
  templateUrl: './right-side-bar.component.html',
  styleUrls: ['./right-side-bar.component.scss']
})
export class RightSideBarComponent implements OnInit {

  @Output() reloadOrder = new EventEmitter<boolean>();
  assignDriverTab: string = "My drivers";
  isSelectedOutsource: boolean;
  isAssignDriverButton: boolean = false;
  isAssignDriver: boolean;
  useTrimbleMaps: Boolean = environment.useTrimbleMaps;
  vehicleLocations: VehicleLocation[] = [];
  private _order: Order;
  unassignedStopsAll: RouteBoxUIGroup[];
  maxRouteAddresses: number;

  @Input()
  public get order(): Order {
    return this._order;
  }
  public set order(value: Order) {
    this._order = value;
    this.loadVehicleLocations()
    if (this.order?.orderNumber) {
      this.getUnassignedRoutes(this.order)
    }
  }

  orderStatusBar = [
    {
      title: 'Order Created',
      dateTime: 'Jun 14 8:25 AM',
      icon: 'order-created',
    },
    {
      title: 'Order Dispatched',
      dateTime: 'Jun 14 10:25 AM',
      icon: 'order-dispatched',
    },
    {
      title: 'Delivery #1 Picked up',
      dateTime: 'Jun 14 12:25 PM',
      icon: 'order-shipped',
    },
    {
      title: 'Predicted On Time Delivery',
      dateTime: 'Jun 14 12:35 PM',
      icon: 'order-waiting-for-dispatch',
    },
    {
      title: 'Delivery #2 Picked up',
      dateTime: 'Jun 14 12:35 PM',
      icon: 'order-shipped',
    },
  ]

  routes: Routes[] = [{
    selected: false
  }];

  selectedRoute: Route

  routesRequest: any = {
    page: 1,
    itemsPerPage: 10
  };
  routes$: Subject<any> = new Subject();
  totalRoutes: number;
  loading: boolean = true;


  constructor(
    private routesService: RoutesService,
    private samsaraService: SamsaraService,
    private helperService: HelperService,
    private dialogService: DialogService,
    private appService: AppService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {

    this.appService.$searchTerm
      .pipe(
        untilDestroyed(this),
        debounceTime(500)
      )
      .subscribe((text) => {
        if (this.isAssignDriver && this.assignDriverTab == 'My drivers') {
          this.searchTermChanged(text)
        }
      })


    this.authService.$user
      .pipe(untilDestroyed(this))
      .subscribe(user => {
        this.maxRouteAddresses = user?.company?.maxRouteAddresses;
      });

    this.routesService.assignOrderStopToRoute$
      .pipe(untilDestroyed(this))
      .subscribe(async (res) => {
        if (res && this.order?.orderNumber) {
          this.getUnassignedRoutes(this.order, res)
          this.isAssignDriver = true;
          this.routesService.assignOrderStopToRoute.next(false)
        }
      })

    this.routes$
      .pipe(
        debounceTime(500),
        untilDestroyed(this)
      ).subscribe(async (request: any) => {
        this.loading = true;
        request = Object.assign({}, this.routesRequest, request)
        this.routesService.cancelPendingRequestGetAll()
        await this.routesService.getAll(request).then((res: any) => {
          this.routes = res?.list;
          this.totalRoutes = res?.totalCount;
          this.loading = false;
        }).catch((error) => {
          this.loading = false;
        })
      });
    this.routes$.next(true);
  }

  backToAssignedRoutesList() {
    this.isAssignDriver = false
    if (this.order && this.order?.orderNumber) {
      this.getUnassignedRoutes(this.order);
    }
  }

  private getUnassignedRoutes(order, orderStopId?: string | number | boolean) {
    this.routesService.getUnassignedRoutes({
      page: 1,
      itemPerPage: 20,
      SearchTerm: order?.orderNumber,
      ...orderStopId && ({ orderStopId })
    }).then(async (data: any) => {
      this.unassignedStopsAll = await RouteBoxUIGroup.createGroups(data?.list);
      if (this.unassignedStopsAll?.length > 0) {
        this.isAssignDriverButton = !(order.orderStatus === OrderStatusEnum.DRAFT || order.orderStatus === OrderStatusEnum.QUOTE)
      } else {
        this.isAssignDriverButton = false
      }
    }).catch((error) => {
      this.helperService.errorMessage(error)
    })
  }

  private loadVehicleLocations() {
    if (this.order?.routes?.length > 0) {
      const samsaraIds = this.order?.routes?.map((route) => route?.truck?.samsaraId).filter(Boolean)
      if (samsaraIds?.length > 0) {
        this.samsaraService.getFleetLocations().pipe(
          map(vl => vl.filter(l => l.latitude !== 0 && l.longitude !== 0 && includes(samsaraIds, l.id)))
        ).subscribe(
          (locations) => {
            this.vehicleLocations = locations as any
          }
        );
      }
    } else {
      this.vehicleLocations = []
    }
  }

  searchTermChanged(search) {
    this.routesRequest.SearchTerm = search || [];
    this.routesRequest.page = 1;
    this.routes$.next(true);
  }

  pageChanged(event) {
    this.routesRequest.page = event;
    this.routes$.next(true);
  }

  // Remaining Unallocated Order Stop Item Check
  async remainingOrderCheck() {
    const remaining = await sumBy(this.order.orderItems, (element) => {
      const ois = element.orderItemStops != null ? element.orderItemStops.filter(oisInner => oisInner.isActive
        && oisInner.orderStop.orderStopType === 'Delivery') : [];

      let qtyPicked = 0;
      ois.forEach(oiss => {
        qtyPicked += parseInt(oiss.quantity, 10);
      });

      if (element.totalQuantity > qtyPicked) {
        return 1;
      }
      return 0;
    })

    if (remaining > 0) {
      this.dialogService.confirm({
        title: 'Confirm',
        message: `There ${remaining === 1 ? 'is' : 'are'} ${remaining} item${remaining === 1 ? '' : 's'} remaining unallocated. Are you sure you want to continue?`,
      }).then(() => {
        this.isAssignDriver = true
      }).catch(() => {
        // Nothing
      })
    } else {
      this.isAssignDriver = true
    }
  }

  selectRoutes(item) {
    const routeId = item?.route?.routeId;
    const isSelected = this.isSelectedRoute(routeId);
    if (isSelected) {
      this.selectedRoute = null;
    } else {
      this.selectedRoute = item?.route ?? null;
    }
  }

  isSelectedRoute(routeId: number): boolean {
    return this.selectedRoute?.routeId === routeId;
  }

  async onSubmit() {    
    if (!this.unassignedStopsAll || this.unassignedStopsAll?.length < 1) {
      this.helperService.addToast("Not available unassigned stop.", "Oops", 'warning')
      return
    } else if (this.selectedRoute) {
      await this.UploadRouteStop(this.selectedRoute, this.unassignedStopsAll)
    } else {
      this.helperService.addToast("Please Select Driver.", "Oops", 'warning')
    }
  }

  async UploadRouteStop(route, stops: RouteBoxUIGroup[]) {

    for (const stop of stops) {
      stop?.items?.forEach(element => {
        element.selected = true;
      });
    }

    route.routeStopGroups = []
    for (const stop of stops) {
      const uniquePickupBoxes = stop.pickupRouteBoxes
        .filter(b => b != null)
        .filter((box, index, self) => 
            self.findIndex(b => b.orderItemStopId === box.orderItemStopId) === index
        );

      // process Pickup
      for (const box of uniquePickupBoxes) {
        this.routesService.processNewPickupStop(box, box.items.filter(i => i.selected), route);
      }
      // process DropOff
      for (const box of stop.dropOffRouteBoxes.filter(b => b != null)) {
        this.routesService.processNewPickupStop(box, box.items.filter(i => i.selected), route);
      }
    }

    this.routesService.updateRouteStops(route.routeId, route?.routeStopGroups, true).then(() => {
      this.helperService.successMessage("Trip assigned driver successfully.");
      this.isAssignDriver = false
      this.reloadOrder.emit(true)
    }).catch((error) => {
      this.helperService.errorMessage(error)
    })

  }

  unassignRouteItemStop(route) {
    this.routesService.moveOrderOnRouteToUnassigned(route?.routeId, this.order?.orderId).then((res) => {
      this.reloadOrder.emit(true);
      this.helperService.successMessage(`All stops unassigned from the ${route?.driver?.fullName} driver.`);
    }).catch((error) => {
      this.helperService.errorMessage(error)
    })
  }
}
