import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { AttachmentsModule } from '@app/@shared/attachments/attachments.module';
import { UnassignedTripsComponent } from './unassigned-trips.component';
import { AttemptDialogComponent } from './attempt-dialog/attempt-dialog.component';
import { DialogsModule } from '../dialogs/dialogs.module';
import { UnassignedTripListItemComponent } from './unassigned-trip-list-item/unassigned-trip-list-item.component';
import { UnassignedTripGridItemComponent } from './unassigned-trip-grid-item/unassigned-trip-grid-item.component';
import { OrderPriorityModule } from '../order-priority/order-priority.module';
import { NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DateTimeInputModule } from '../date-time-input/date-time-input.module';
import { WaitingTimeInputModule } from '../waiting-time-input/waiting-time-input.module';

@NgModule({
  declarations: [
    UnassignedTripsComponent,
    AttemptDialogComponent,
    UnassignedTripListItemComponent,
    UnassignedTripGridItemComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    AttachmentsModule,
    DragDropModule,
    DialogsModule,
    OrderPriorityModule,
    NgbPopoverModule,
    NgbTooltipModule,
    DateTimeInputModule,
    WaitingTimeInputModule
  ],
  exports: [
     UnassignedTripsComponent
  ]
})
export class UnassignedTripsModule { }
