import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { BehaviorSubject, interval, Observable, Subject } from 'rxjs';
import { ListTabsService } from '@app/@shared/list-tabs/list-tabs.service';
import moment from "moment"
import { AutoCloseTimeMappings } from '@app/models/system.model';
import { forEach, isNull } from 'lodash';

@Injectable({ providedIn: 'root' })
export class AutoCloseListTabsService implements OnDestroy {

    private CHECK_INTERVAL = 15000; // 15 seconds in milliseconds
    private intervalId: any;

    autoCloseInActiveListTabSubject: BehaviorSubject<{ tabId: string; listId: string }> = new BehaviorSubject<{ tabId: string; listId: string }>({ tabId: '', listId: '' });

    get autoCloseInActiveListTab$(): Observable<{ tabId: string; listId: string }> {
        return this.autoCloseInActiveListTabSubject.asObservable();
    }

    constructor(
        private authService: AuthService
    ) {
        this.startIntervalAutoCloseListTabs();
    }

    private startIntervalAutoCloseListTabs(): void {
        this.intervalId = interval(this.CHECK_INTERVAL).subscribe(() => this.removeExpiredListTabs());
    }

    private removeExpiredListTabs(): void {
        const listTabs = JSON.parse(localStorage.getItem('list-tabs') || '{}');
        forEach(listTabs, (tabs, key) => {
            if (Array.isArray(tabs)) {
                listTabs[key] = tabs.filter((tab: any) => this.isTabExpiry(key, tab));
            }
        });
    }

    private isTabExpiry(key: string, tab: any): number {

        if (!tab?.lastViewTime || !key || !tab?.tabId) return;

        const {
            invoiceTabAutoClose,
            customerTabAutoClose,
            driverTabAutoClose,
            orderTabAutoClose,
            quoteTabAutoClose,
            outsourceTabAutoClose
        } = this.authService?.user?.company;

        const timeMapping = {
            'accounting': invoiceTabAutoClose,
            'customer': customerTabAutoClose,
            'driver': driverTabAutoClose,
            'order': orderTabAutoClose,
            'quote': quoteTabAutoClose,
            'outsource-company': outsourceTabAutoClose,
        };

        const autoCloseTime = timeMapping[key];
        if (isNull(AutoCloseTimeMappings[autoCloseTime])) return moment().valueOf();

        const expiryTime = moment(tab?.lastViewTime).add(AutoCloseTimeMappings[autoCloseTime], 'milliseconds').valueOf();

        if (moment().valueOf() > expiryTime) {
            this.autoCloseInActiveListTabSubject.next({ tabId: tab?.tabId, listId: key });
        }

    }

    ngOnDestroy(): void {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }
}
