import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { ExpensesRoutingModule } from './expenses-routing.module';
import { DatatableModule } from '@app/@shared/datatable';
import { DialogsModule } from '@app/@shared/dialogs/dialogs.module';
import { ExpenseListComponent } from './expense-list/expense-list.component';
import { ExpenseDetailsComponent } from './expense-details/expense-details.component';
import { NgChartsModule } from 'ng2-charts';
import { TruckExpenseListComponent } from './truck-expenses/truck-expenses-list.component';
import { RepeaterModule } from '@app/@shared/repeater/repeater.module';
import { FormValidateModule } from '@app/@shared/form-validate/form-validate.module';
import { ExcludeExpensesComponent } from './exclude-expenses/exclude-expenses.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { TrucksExpenseComponent } from './expense-list/trucks/trucks-expense.component';
import { ExpensesDateSelectorComponent } from './expenses-date-selector/expenses-date-selector.component';
import { OfficeExpensesComponent } from './expense-list/office/office-expenses.component';

@NgModule({
  declarations: [
    ExpenseListComponent,
    ExpenseDetailsComponent,
    TruckExpenseListComponent,
    ExcludeExpensesComponent,
    TrucksExpenseComponent,
    OfficeExpensesComponent,
    ExpensesDateSelectorComponent
  ],
  imports: [
    CommonModule,
    ExpensesRoutingModule,
    SharedModule,
    DatatableModule,
    DialogsModule,
    RepeaterModule,
    FormValidateModule,
    NgChartsModule,
    NgbDatepickerModule
  ]
})
export class ExpensesModule { }
