import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '@environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TelegramComponent } from './telegram/telegram.component';
import { QuickbooksService } from '@app/shared/services/quickbooks.service';
import { DatatableComponent } from '@app/@shared/datatable';
import { DialogService } from '@app/@shared/dialogs/dialog.service';
import { QuickbooksIntegrationStatusEnum } from '@app/models/quickbooks.model';
import { HelperService } from '@app/shared/services/helper.service';

interface Integration {
  image: string,
  name: string,
  description: string,
  isInstalled: boolean,
  buttonText: string,
  reconnectNeeded: boolean
  onClick?: any;
}

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit {

  title = "Integrations"
  activeTab = "all"
  quickbooksIntegrationStatus: QuickbooksIntegrationStatusEnum
  @ViewChild('datatable') datatable: DatatableComponent

  redirectQuickbooks = () => {
    const quickbooksAuthUrl = `https://appcenter.intuit.com/connect/oauth2?client_id=${environment.quickbooksClientId}&redirect_uri=${environment.quickbooksRedirectUrl}&scope=com.intuit.quickbooks.accounting&response_type=code&state=rushhour`;
    window.location.href = quickbooksAuthUrl;
  }

  disconnectQuickbooksDialog = () => {
    this.dialogService.confirm({
      message: "Are you sure you want to disconnect Quickbooks?",
      yesText: "Yes",
      noText: "No",
    }, { scrollable: false, size: "md", }).then(() => {
      this.disconnectQuickbooks();
    }).catch(() => {
      //Nothing
    });
  }

  telegramDialog = () => {
    this.ngbModal.open(TelegramComponent, {
      scrollable: false,
      size: "sm",
    })
  }

  data: Integration[] = [
    {
      image: '/assets/images/samsara.png',
      name: 'Samsara',
      description: 'Details',
      isInstalled: true,
      buttonText: 'View',
      reconnectNeeded: false
    },
    {
      image: '/assets/images/motive.png',
      name: 'Motive',
      description: 'Details',
      isInstalled: false,
      buttonText: 'Install',
      reconnectNeeded: false
    },
    {
      image: '/assets/images/gps-insights.png',
      name: 'GPS Insights',
      description: 'Details',
      isInstalled: false,
      buttonText: 'Install',
      reconnectNeeded: false
    },
    {
      image: '/assets/images/solera.png',
      name: 'Solera',
      description: 'Details',
      isInstalled: false,
      buttonText: 'Install',
      reconnectNeeded: false
    },
    {
      image: '/assets/images/quickbooks.svg',
      name: 'Quickbooks',
      description: 'Details',
      isInstalled: false,
      buttonText: 'Install',
      reconnectNeeded: false,
      onClick: this.redirectQuickbooks
    },
    {
      image: '/assets/images/telegram.svg',
      name: 'Telegram',
      description: 'Details',
      isInstalled: false,
      buttonText: 'Install',
      reconnectNeeded: false,
      onClick: this.telegramDialog
    }
  ]

  get quickbooksRow(): any {
    return this.data?.filter(x => x?.name === 'Quickbooks')[0]
  }

  constructor(
    private ngbModal: NgbModal,
    private quickbooksService: QuickbooksService,
    private dialogService: DialogService,
    private helperService: HelperService,
  ) { }

  ngOnInit(): void {
    this.getIntegrationStatus();
  }

  getIntegrationStatus() {
    this.quickbooksService.getIntegrationStatus().then(async ({ status }) => {
      await this.setQuickbooksButtonAction(status);
    }).catch(async () => {
      await this.setQuickbooksButtonAction(QuickbooksIntegrationStatusEnum.NotConnected);
    }).finally(() => {
      this.datatable.refresh();
    })
  }

  async disconnectQuickbooks() {
    await this.quickbooksService.disconnectIntegration().then(async ({ status }) => {
      await this.setQuickbooksButtonAction(status);
      this.helperService.successMessage("Quickbooks has been successfully disconnected.");
    }).catch((error) => {
      this.helperService.errorMessage(error);
    }).finally(() => {
      this.datatable.refresh();
    })
  }

  setQuickbooksButtonAction(status: QuickbooksIntegrationStatusEnum) {
    this.quickbooksIntegrationStatus = status;
    if (this.quickbooksIntegrationStatus === QuickbooksIntegrationStatusEnum.Connected) {
      this.quickbooksRow.buttonText = 'Disconnect';
      this.quickbooksRow.isInstalled = true;
      this.quickbooksRow.onClick = this.disconnectQuickbooksDialog;
    } else if (this.quickbooksIntegrationStatus === QuickbooksIntegrationStatusEnum.ConnectionExpired) {
      this.quickbooksRow.buttonText = 'Reconnect';
      this.quickbooksRow.reconnectNeeded = true;
      this.quickbooksRow.onClick = this.redirectQuickbooks;
    } else {
      this.quickbooksRow.buttonText = 'Install';
      this.quickbooksRow.isInstalled = false;
      this.quickbooksRow.onClick = this.redirectQuickbooks;
    }
  }

}
