// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  width: 100%;
}
:host .form-phone-input ::ng-deep .iti__flag-container {
  display: none;
}
:host .form-phone-input ::ng-deep .iti--allow-dropdown {
  width: 100%;
}
:host .form-phone-input ::ng-deep .iti--allow-dropdown input {
  padding: 8px 12px;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/forms/phone/phone.component.scss"],"names":[],"mappings":"AACA;EACE,cAAA;EACA,WAAA;AAAF;AAIM;EACE,aAAA;AAFR;AAIM;EACE,WAAA;AAFR;AAGQ;EACE,iBAAA;AADV","sourcesContent":["@import \"var\";\n:host {\n  display: block;\n  width: 100%;\n\n  .form-phone-input {\n    ::ng-deep {\n      .iti__flag-container {\n        display: none;\n      }\n      .iti--allow-dropdown {\n        width: 100%;\n        input {\n          padding: 8px 12px;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
