import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";

@Pipe({
    standalone: false,
    name: "getAppointmentColorAndTooltip",
    pure: true,
})
export class GetAppointmentColorAndTooltipPipe implements PipeTransform {
    transform(date: Date, timeType: 'Morning' | 'Noon' | 'Evening' | 'Custom', text: string): { class: string; tooltip: string } {
        
        const formattedDate = moment(date);
        const today = moment();

        const status = {
            now: { class: "text-success", tooltip: `For ${text}` },
            late: { class: "text-danger", tooltip: `Late ${text}` },
            upcoming: { class: "text-warning", tooltip: `Upcoming ${text}` },
        };

        const getStatus = (start: moment.Moment, end: moment.Moment) => {
            if (today.isBetween(start, end, null, "[)")) return status.now;
            return today.isBefore(start) ? status.upcoming : status.late;
        };

        if (timeType === "Custom") {
            if (formattedDate.isSame(today, "minute")) return status.now;
            return formattedDate.isBefore(today) ? status.late : status.upcoming;
        }

        const timeRanges = {
            Morning: { start: today.clone().set({ hour: 6, minute: 0 }), end: today.clone().set({ hour: 12, minute: 0 }) },
            Noon: { start: today.clone().set({ hour: 12, minute: 0 }), end: today.clone().set({ hour: 17, minute: 0 }) },
            Evening: { start: today.clone().set({ hour: 17, minute: 0 }), end: today.clone().set({ hour: 23, minute: 59 }) },
        };

        if (timeRanges[timeType]) {
            if (formattedDate.isSame(today, "day")) {
                return getStatus(timeRanges[timeType].start, timeRanges[timeType].end);
            }
            return formattedDate.isBefore(today) ? status.late : status.upcoming;
        }

        return { class: "", tooltip: "" };
    }
}
