import { Component, OnInit } from '@angular/core';
import { removeBinding } from '@app/@shared/utils';
import { Company } from '@app/models/company.model';
import { AuthService } from '@app/shared/services/auth/auth.service';
import { CompanyService } from '@app/shared/services/company.service';
import { HelperService } from '@app/shared/services/helper.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { omit } from 'lodash';

@UntilDestroy()
@Component({
    standalone: false,
    selector: 'app-drivers-settings',
    templateUrl: './drivers-settings.component.html',
    styleUrls: ['./drivers-settings.component.scss']
})
export class DriversSettingsComponent implements OnInit {

    submitLoading = false
    title = "Drivers Settings"
    setting: any = {
        isDefaultRate: false,
        isSetting: false,
        settingDetail: false,
    }
    company: Company = {
        defaultDriverWage: null
    };

    constructor(
        private companyService: CompanyService,
        private helperService: HelperService,
        private authService: AuthService,
    ) {
    }

    ngOnInit() {
        this.authService.$user
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                this.company = removeBinding(data?.company);
            })
    }

    onSubmit() {
        this.submitLoading = true;
        this.companyService.update(omit(this.company, 'companyId')).then((resp: any) => {
            this.company = resp
            this.helperService.successMessage("Drivers settings successfully updated")
            this.authService.user = {
                ...this.authService.user,
                company: this.company,
            }
            this.submitLoading = false;
        }).catch((error) => {
            this.helperService.errorMessage(error)
            this.submitLoading = false;
        });
    }


}
