import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@app/models/core/base';
import { Zone } from '@app/models/zone.model';
import { CrudService } from './core/crud.service';
import { firstValueFrom } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class ZoneService extends CrudService<Zone> {

    basePath = 'Zones'

      constructor(
        protected http: HttpClient
    ) {
        super(http)
    } 

    get(id: number) {
        return firstValueFrom(this.http.get<ApiResponse<Zone>>(`${this.basePath}/${id}`))
            .then(({ data }) => data);
    }

    update(id: number, request: Zone) {
        return firstValueFrom(this.http.put<ApiResponse<Zone>>(`${this.basePath}/${id}`, request))
            .then(({ data }) => data);
    }

    create(request: Zone) {
        return firstValueFrom(this.http.post<ApiResponse<Zone>>(`${this.basePath}`, request))
            .then(({ data }) => data);
    }

    delete(id: number) {
        return firstValueFrom(this.http.delete<ApiResponse<any>>(`${this.basePath}/${id}`))
            .then(({ data }) => data);
    }

}
