// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .form-check-input {
  cursor: pointer;
}
:host .broker-account-checkbox {
  margin-top: 38px;
}
:host .email-type-select {
  max-width: 140px;
}
:host .items-collapse-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
:host .items-collapse-btn .icon-down-arrow-up {
  transform: rotate(180deg);
}
:host .is-un-collapsed-items {
  max-height: 48px !important;
}
:host .is-items {
  max-height: 100% !important;
}
:host .items {
  max-height: 46px;
  overflow: hidden;
  transition: max-height 0.5s;
  padding-top: 4px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/outsource-company/outsource-company-details/outsource-company-information/outsource-company-information.component.scss"],"names":[],"mappings":"AAEI;EACI,eAAA;AADR;AAII;EACI,gBAAA;AAFR;AAII;EACI,gBAAA;AAFR;AAKI;EACI,eAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AAHR;AAIQ;EACI,yBAAA;AAFZ;AAMI;EACI,2BAAA;AAJR;AAOI;EACI,2BAAA;AALR;AAQI;EACI,gBAAA;EACA,gBAAA;EACA,2BAAA;EACA,gBAAA;AANR","sourcesContent":["@use \"common/var\" as *;\n:host {\n    .form-check-input {\n        cursor: pointer;\n    }\n\n    .broker-account-checkbox {\n        margin-top: 38px;\n    }\n    .email-type-select {\n        max-width: 140px;\n    }\n\n    .items-collapse-btn {\n        cursor: pointer;\n        display: flex;\n        align-items: center;\n        justify-content: space-between;\n        .icon-down-arrow-up {\n            transform: rotate(180deg);\n        }\n    }\n\n    .is-un-collapsed-items {\n        max-height: 48px !important;\n    }\n\n    .is-items {\n        max-height: 100% !important;\n    }\n\n    .items {\n        max-height: 46px;\n        overflow: hidden;\n        transition: max-height 0.5s;\n        padding-top: 4px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
