import { Component, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ListTabsComponent } from '@app/@shared/list-tabs/list-tabs.component';
import { HelperService } from '@app/shared/services/helper.service';
import { AccountingService } from '@app/shared/services/accounting.service';
import { Invoice } from '@app/models/invoice.model';

@UntilDestroy()
@Component({
  	standalone: false,
	selector: 'app-accounting-layout',
	templateUrl: './accounting-layout.component.html',
	styleUrls: ['./accounting-layout.component.scss'],

})

export class AccountingLayoutComponent {

	@ViewChild('listTabs') listTabs: ListTabsComponent;

	constructor(
		private helperService: HelperService,
		private accountingService: AccountingService,
	) {

	}

	getTabTitle = (value: Invoice, key) => {
		return value?.number ? `#${value?.number}` : 'Loading...';
	}

	updateTabData(data) {
		const newTabId = data.invoiceId;
		const tabId = this.listTabs.activeTab;
		this.listTabs.updateTabId(tabId, newTabId)
		this.listTabs.setTab(newTabId, { data });
	}

	closeTab() {
		this.listTabs.openTab('all')
	}

	loadTabData = async (invoiceId: string) => {
		let request = null;
		let invoiceFiltersStr = localStorage.getItem('invoice.filters');
		if (invoiceFiltersStr)
			request = JSON.parse(invoiceFiltersStr);
		else
			request = { byCustomer: true }

		// if (referenceId)
		request = { ...request, includeOrder: true }

		// if (tnxType)
		// 	request = { ...request, tnxType }
		return await this.accountingService.getInvoice(invoiceId, request).then((res) => {
			return res;
		}).catch(() => {
			this.helperService.errorMessage("Invoice not found.")
			this.listTabs.closeTab(invoiceId, true);
		})

	}
}