import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormValidateDirective } from '@app/@shared/form-validate/form-validate.directive';
import { OutsourceCompany, OutsourceCompanyFinance, OutsourceCompanyFinanceDiscountTypeEnum, OutsourceCompanyFinanceTypeOfWorkEnum } from '@app/models/outsource-company.model';
import { EnumFlagService } from '@app/shared/services/enum-flag.service';
import { HelperService } from '@app/shared/services/helper.service';
import { OutsourceCompanyFinanceService } from '@app/shared/services/outsource-company-finance.service';
import { cloneDeep, includes, map } from 'lodash';
import { number, object, string } from 'yup';
export interface OutsourceCompanyFinanceItem extends OutsourceCompanyFinance {
    isEdit?: boolean
    isDelete?: boolean
}
@Component({
    standalone: false,
    selector: 'app-outsource-company-finance',
    templateUrl: './outsource-company-finance.component.html',
    styleUrls: ['./outsource-company-finance.component.scss']
})
export class OutsourceCompanyFinanceComponent implements OnInit {

    private _outsourceCompany: OutsourceCompany = {};

    isCostFixed: boolean = false
    isCostDistance: boolean = false
    isCostHourly: boolean = false
    isCostPallets: boolean = false

    enumObj = OutsourceCompanyFinanceTypeOfWorkEnum;

    outsourceCompanyFinanceDiscount = [
        {
            name: "Fix",
            value: OutsourceCompanyFinanceDiscountTypeEnum.Value,
        },
        {
            name: "Percentage",
            value: OutsourceCompanyFinanceDiscountTypeEnum.Percent,
        }
    ]

    companyFinance: OutsourceCompanyFinanceItem = {
        costPallets: null,
        discountType: null,
    };

    isLoading: boolean;

    validationSchema = object().shape({
        typeOfWork: number().label("Type of Work").nullable().positive('Type of Work is a required field').required(),
        discount: number().label("Discount").nullable().required(),
        freeWaitingTimeMinutes: string().label("Free Waiting Time").nullable().required(),        
    });

    isEdit: boolean = false;

    initialCompanyFinance: OutsourceCompanyFinance = {
        costPallets: null,
        discountType: null,
    };

    @Input()
    public get outsourceCompany(): OutsourceCompany {
        return this._outsourceCompany;
    }

    public set outsourceCompany(value: OutsourceCompany) {
        this._outsourceCompany = value;
        if (value?.companyFinances[0]) {
            this.companyFinance = cloneDeep(value.companyFinances[0]);
            this.initialCompanyFinance = cloneDeep(value.companyFinances[0]);
            if (this.companyFinance?.typeOfWork) {
                this.costFieldsVisible(this.companyFinance?.typeOfWork);
            }
        }
    }

    @Output() outsourceCompanyChange = new EventEmitter();

    constructor(
        private enumFlagService: EnumFlagService,
        public outsourceCompanyFinanceService: OutsourceCompanyFinanceService,
        public helperService: HelperService,
    ) {
    }

    ngOnInit(): void { }

    async costFieldsVisible(values) {
        const selectedCostFields: number[] = this.enumFlagService.getFlagArrayForFlagNumberFomEnum(values, this.enumObj)
        this.isCostFixed = await includes(selectedCostFields, this.enumObj.Fixed);
        this.isCostDistance = await includes(selectedCostFields, this.enumObj.Distance);
        this.isCostHourly = await includes(selectedCostFields, this.enumObj.Hourly);
        this.isCostPallets = await includes(selectedCostFields, this.enumObj.Pallets);
    }

    editOutsourceFinance() {
        this.isEdit = true;
    }

    discardChanges(formValidate: FormValidateDirective) {
        this.companyFinance = cloneDeep(this.initialCompanyFinance);
        if (this.companyFinance?.typeOfWork) {
            this.costFieldsVisible(this.companyFinance?.typeOfWork);
        }
        this.isEdit = false;
        setTimeout(() => {
            formValidate.reset()
        }, 0)
    }

    handleSubmit(f) {
        if (f.isValid) {
            this.isLoading = true;
            if (this.companyFinance && this.companyFinance?.outsourceCompanyFinanceId) {
                this.outsourceCompanyFinanceService.update(this.companyFinance.outsourceCompanyFinanceId, this.companyFinance)
                    .then((data) => {
                        this.initialCompanyFinance = cloneDeep(this.companyFinance);
                        this.outsourceCompany.companyFinances = [this.companyFinance]
                        this.outsourceCompanyChange.emit(this.outsourceCompany);
                        this.helperService.successMessage('Outsource Finances Details Successfully Update.');
                        this.isLoading = false;
                        this.isEdit = false;
                    }).catch((error) => {
                        this.isLoading = false;
                        this.isEdit = true;
                        this.helperService.errorMessage(error)
                    })
            } else {
                this.companyFinance.outsourceCompanyId = this.outsourceCompany?.id
                this.outsourceCompanyFinanceService.create(this.companyFinance)
                    .then((data) => {
                        this.initialCompanyFinance = cloneDeep(this.companyFinance);
                        this.outsourceCompany.companyFinances = [this.companyFinance]
                        this.outsourceCompanyChange.emit(this.outsourceCompany)
                        this.isEdit = false;
                        this.isLoading = false;
                    }).catch((error) => {
                        this.isLoading = false;
                        this.isEdit = true;
                        this.helperService.errorMessage(error)
                    })
            }
        }
    }

}
