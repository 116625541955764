import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiPaginationResponse, ApiResponse } from '@app/models/core/base';
import { toParams } from 'src/utils/to-params';
import { Customer, CustomerSearchRequest } from '@app/models/customer.model';
import { Invoice } from '@app/models/invoice.model';
import { ChargeType } from '@app/models/charge-type.model';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class AccountingService {
    dataStore: {
        searchTermCustomers?: string,
        selectedCustomer?: Customer;
        activeTab: 'customer' | 'filter'
    }

    private basePath = 'Accounting'
    private _searchTermCustomers: BehaviorSubject<any>;
    private _selectedCustomer: BehaviorSubject<any>;

    constructor(
        protected http: HttpClient
    ) {
        this.dataStore = {
            searchTermCustomers: '',
            selectedCustomer: null,
            activeTab: 'customer',
        }
        this._searchTermCustomers = new BehaviorSubject(this.dataStore.searchTermCustomers);
        this._selectedCustomer = new BehaviorSubject(this.dataStore.selectedCustomer);
    }

    public get $searchTermCustomers(): Observable<string> {
        return this._searchTermCustomers.asObservable();
    }

    public set searchTermCustomers(value: string) {
        this.dataStore.searchTermCustomers = value;
        this._searchTermCustomers.next(value);
    }

    public get $selectedCustomer(): Observable<Customer> {
        return this._selectedCustomer.asObservable();
    }

    public get selectedCustomer(): Customer {
        return this.dataStore.selectedCustomer;
    }

    public set selectedCustomer(value: Customer) {
        this.dataStore.selectedCustomer = value;
        this._selectedCustomer.next(value);
    }

    getAll(request) {
        return firstValueFrom(this.http.get<ApiPaginationResponse<any>>(`${this.basePath}`, { params: toParams(request) }))
            .then((({ data }) => data));;
    }

    getSentEmails(invoiceId: string | number, request = { suggestCustomerPastSentEmails: false }) {
        return firstValueFrom(this.http.get<ApiResponse<any>>(`${this.basePath}/${invoiceId}/SentEmails`, { params: toParams(request) }))
            .then((({ data }) => data));
    }

    search(request) {
        return firstValueFrom(this.http.post<ApiPaginationResponse<any>>(`${this.basePath}`, request))
            .then((({ data }) => data));
    }

    getAccountingCustomers(request: CustomerSearchRequest) {
        return firstValueFrom(this.http.get<ApiPaginationResponse<Customer>>(`${this.basePath}/customers`, { params: toParams(request) }))
            .then((({ data }) => data));
    }

    getAccountingCustomerBasicDetails(request: CustomerSearchRequest) {
        return firstValueFrom(this.http.get<ApiPaginationResponse<Customer>>(`${this.basePath}/customers/basicDetails`, { params: toParams(request) }))
            .then((({ data }) => data));
    }

    getAllChargeTypes() {
        return firstValueFrom(this.http.get<ApiResponse<ChargeType[]>>(`${this.basePath}/chargeTypes`))
    }

    getInvoice(invoiceId: string | number, request = null) {
        return firstValueFrom(this.http.get<ApiResponse<Invoice>>(`${this.basePath}/invoice/${invoiceId}`, { params: toParams(request) }))
            .then((({ data }) => data));;
    }

    createInvoice(request) {
        return firstValueFrom(this.http.post<ApiResponse<Invoice>>(`${this.basePath}/invoice`, request))
    }

    invoiceApproveOrUnApprove(invoiceId, status) {
        return firstValueFrom(this.http.put<ApiResponse<any>>(`${this.basePath}/invoice/${invoiceId}/status?status=${status}`, {})).then((({ data }) => data));
    }

    checkInvoiceNo(invoiceNumber) {
        return firstValueFrom(this.http.get<ApiResponse<Invoice>>(`${this.basePath}/InvoiceCheck/${invoiceNumber}`))
    }

    getInvoicePayments(invoiceId) {
        return firstValueFrom(this.http.get<ApiResponse<any>>(`${this.basePath}/InvoicePayments/${invoiceId}`))
    }

    updateInvoice(invoiceId: string | number, request) {
        return firstValueFrom(this.http.put<ApiResponse<Invoice>>(`${this.basePath}/invoice/${invoiceId}`, request))
    }

    deleteInvoice(invoiceId) {
        return firstValueFrom(this.http.delete(`${this.basePath}/invoice/${invoiceId}`))
    }

    exportInvoices(request: any) {
        return firstValueFrom(this.http.post(`${this.basePath}/export`, request))
    }

    updatePayment(PaymentId: string | number, request) {
        return firstValueFrom(this.http.put(`${this.basePath}/Payment/${PaymentId}`, request))
    }

    createPayment(request) {
        return firstValueFrom(this.http.post(`${this.basePath}/Payment`, request))
    }

    getInvoiceContacts(invoiceId) {
        return firstValueFrom(this.http.get<ApiResponse<any>>(`${this.basePath}/${invoiceId}/contacts`)).then((({ data }) => data));
    }

    /**
     * Get dashboard invoice stats
     */
    getInvoiceStats(request?: Partial<any>) {
        return firstValueFrom(this.http.get<ApiResponse<any>>(`${this.basePath}/InvoiceStats`, { params: toParams(request) }));
    }

    // Function to reset the state to initial values
    resetState() {
        this.selectedCustomer = null
        this.searchTermCustomers = ''
    }
}
