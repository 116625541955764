import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ListTabsService, TabData, TabExtra, TabId } from './list-tabs.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { findIndex } from 'lodash';
import { moveItemInArray } from 'src/utils/array-menage';
import { ActivatedRoute, Router } from '@angular/router';
import { AutoCloseListTabsService } from '@app/shared/services/auto-close-list-tabs.service';

@UntilDestroy()
@Component({
    standalone: false,
    selector: 'app-list-tabs',
    templateUrl: 'list-tabs.component.html',
    styleUrls: ['./list-tabs.component.scss'],
})
export class ListTabsComponent implements OnInit {

    @Input('tabDetailsTemplate') tabDetailsTemplate: any;
    @Input() listId!: string;
    @Input() loadTabData: (tabId: TabId) => any | Promise<any>;
    @Input() getTabTitle = (value: any, key: TabId) => `ID : ${key}`;
    @Input() activeTab: TabId = 'all';
    @Output() activeTabChange = new EventEmitter<TabId>();

    tabs: any = [];

    constructor(
        private listTabsService: ListTabsService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private autoCloseListTabsService: AutoCloseListTabsService,
    ) {

    }

    ngOnInit(): void {
        if (this.listId === undefined) {
            throw new Error('`listId` is required');
        }
        if (this.tabDetailsTemplate === undefined) {
            throw new Error('`tabDetailsTemplate` is required');
        }
        this.listTabsService.initTabs(this.listId);
        this.listTabsService.tabChange$
            .pipe(untilDestroyed(this))
            .subscribe((listId) => {
                if (listId == this.listId) {
                    this.tabs = this.listTabsService.getAllTabs(this.listId);
                }
            })

        this.activatedRoute
            .queryParams
            .pipe(untilDestroyed(this))
            .subscribe(({ tab }) => {
                if (tab === 'all') {
                    this.activeTab = 'all';
                    this.router.navigate([], { queryParams: { tab: null }, queryParamsHandling: 'merge' });
                }
            })

        this.autoCloseListTabsService.autoCloseInActiveListTab$
            .pipe(untilDestroyed(this))
            .subscribe((data) => {                
                if (data?.listId == this.listId && data?.tabId == this.activeTab) {
                    this.openTab(data?.tabId, { setAsActiveTab: true });
                } else if (data?.listId && data?.tabId) {
                    this.listTabsService.closeTab(data?.listId, data?.tabId);
                }
            });
    }

    getChildPayload = (index) => {
        const tab = this.tabs[index]
        return { tab, index }
    }

    onDrop(event: any) {
        if (event?.currentIndex || event?.currentIndex == 0) {
            moveItemInArray(this.tabs, event?.previousIndex, event?.currentIndex);
            this.listTabsService.updateTabsIndex(this.listId, this.tabs)
            if (this.activeTab !== event?.item?.data?.tabId) {
                this.openTab(event?.item?.data?.tabId, { setAsActiveTab: true });
            }
        }
    }

    async navigateOpenTab(id, path) {
        this.router.navigate([path], {
            queryParams: {
                id: null,
            },
            queryParamsHandling: 'merge'
        });
        this.openTab(id, { setAsActiveTab: true });
    }


    async openTab(tabId: TabId, extra?: TabExtra) {
        tabId = `${tabId}` // Convert tabId to string.        
        const setAsActiveTab = extra?.setAsActiveTab ?? this.getIsOpenTab(tabId);
        if (setAsActiveTab) {
            this.activeTabChange.emit(this.activeTab);
            this.activeTab = tabId;
        }
        if (tabId !== 'all' && tabId) {
            this.listTabsService.openTab(this.listId, tabId);
            const tab = await this.getTab(tabId);
            const tabData = tab?.data;
            if (!tabData && typeof this.loadTabData === 'function') {
                const data = await this.loadTabData(tabId);
                if (data) {
                    const title = this.getTabTitle(data, tabId);
                    this.setTab(tabId, { title: title, data, extra });
                }
            } else if (tab && extra) {
                this.setTab(tabId, { ...tab, extra });
            }
        }
    }
    async reloadTab(tabId: TabId) {
        tabId = `${tabId}` // Convert tabId to string.     
        const tab = this.getTab(tabId)
        if (tabId !== 'all' && tabId && tab && typeof this.loadTabData === 'function') {
            const data = await this.loadTabData(tabId);
            if (data) {
                const title = this.getTabTitle(data, tabId);
                this.setTab(tabId, { title: title, data });
            }
        }
    }

    onClose($event: MouseEvent, tabId) {
        tabId = `${tabId}` // Convert tabId to string.
        $event?.preventDefault();
        $event?.stopPropagation();
        this.closeTab(tabId, true);
    }

    onCloseRedirectListPage(tabId) {
        tabId = `${tabId}`; // Convert tabId to string.
        this.activeTab = 'all';
        this.closeTab(tabId);
    }

    updateTabId(oldTabId: TabId, newTabId: TabId) {
        oldTabId = `${oldTabId}` // Convert tabId to string.
        newTabId = `${newTabId}` // Convert tabId to string.
        this.listTabsService.updateTabId(this.listId, oldTabId, newTabId);
        if (this.activeTab == oldTabId) {
            this.activeTab = newTabId;
        }
    }

    getIsOpenTab(tabId: TabId) {
        tabId = `${tabId}` // Convert tabId to string.
        return this.listTabsService.getTab(this.listId, tabId);
    }

    getTab(tabId: TabId) {
        tabId = `${tabId}` // Convert tabId to string.
        return this.listTabsService.getTab(this.listId, tabId);
    }

    getAllTabs() {
        return this.listTabsService.getAllTabs(this.listId);
    }

    updateTabExtra(tabId, extra: TabExtra) {
        const tabOldData = this.getTab(tabId)
        this.listTabsService.setTab(this.listId, tabId, {
            ...tabOldData,
            extra: {
                ...tabOldData.extra,
                ...extra
            }
        });
    }

    setTab(tabId: TabId, value: Partial<TabData>) {
        tabId = `${tabId}`; // Convert tabId to string.
        const tabOldData = this.getTab(tabId)
        const title = this.getTabTitle(value.data, tabId);
        this.listTabsService.setTab(this.listId, tabId, {
            ...tabOldData,
            title,
            ...value
        });
    }


    closeTab(tabId: TabId, checkActiveTab?: boolean) {
        tabId = `${tabId}` // Convert tabId to string.

        if (checkActiveTab && this.activeTab == tabId) {
            const index = findIndex(this.tabs, { tabId }) - 1;
            if (index >= 0) {
                this.openTab(this.tabs[index]?.tabId, { setAsActiveTab: true });
            } else {
                this.activeTab = 'all';
            }
        }

        this.listTabsService.closeTab(this.listId, tabId);
    }

    closeTabWithKey(key: string, tabId: TabId) {
        tabId = `${tabId}` // Convert tabId to string.
        this.listTabsService.closeTab(key, tabId);
    }

    closeAllTabs() {
        this.listTabsService.closeAllTabs(this.listId);
    }

}
