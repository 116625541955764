import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormPhoneInputModule } from '@app/@shared/forms';
import { AddCustomerContactDialogComponent } from './add-customer-contact-dialog.component';
import { SharedModule } from '@app/shared/shared.module';
import { FormValidateModule } from '@app/@shared/form-validate/form-validate.module';
import { PhoneIsoCodeExtensionInputModule } from '@app/@shared/phone-iso-code-extension-input/phone-iso-code-extension-input.module';

@NgModule({
	declarations: [
		AddCustomerContactDialogComponent
	],
	exports: [
		AddCustomerContactDialogComponent
	],
	imports: [
		CommonModule,
		FormValidateModule,
		FormsModule,
		ReactiveFormsModule,
		FormPhoneInputModule,
		PhoneIsoCodeExtensionInputModule,
	]
})
export class AddCustomerContactDialogModule { }
