import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditDeliveryDialogComponent } from './edit-delivery-dialog.component';
import { SharedModule } from '@app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { OrderStopItemModule } from '../order-stop-item/order-stop-item.module';
import { CustomerSelectModule } from '@app/@shared/customer-select/customer-select.module';
import { CheckboxGroupModule } from '@app/@shared/checkbox-group/checkbox-group.module';
import { FormValidateModule } from '@app/@shared/form-validate/form-validate.module';
import { FileUploadModule } from '@app/@shared/file-upload/file-upload.module';
import { TypeaheadSelectModule } from '@app/@shared/typeahead-select/typeahead-select.module';
import { FormPhoneInputModule } from '@app/@shared/forms';
import { PhoneIsoCodeExtensionInputModule } from '@app/@shared/phone-iso-code-extension-input/phone-iso-code-extension-input.module';



@NgModule({
  declarations: [
    EditDeliveryDialogComponent,
  ],
  exports: [
    EditDeliveryDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    OrderStopItemModule,
    CustomerSelectModule,
    CheckboxGroupModule,
    FormValidateModule,
    FileUploadModule,
    TypeaheadSelectModule,
    FormPhoneInputModule,
    PhoneIsoCodeExtensionInputModule,
  ],
})
export class EditDeliveryDialogModule { }
