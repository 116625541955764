import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { UtilService } from '@app/services/util.service';
import { RouteItemStop } from '@models/route-item-stop.model';
import { EntityTypes } from '@models/entity-type.model';
import { OrderItemStop } from '@models/order-item-stop.model';
import { RouteItem } from '@models/route-item.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { number, object, string } from 'yup';
import { FormValidateDirective } from '@app/@shared/form-validate/form-validate.directive';
import { PickupTypeEnum } from '@app/models/pickup-type';
import { NgForm } from '@angular/forms';

@Component({
    standalone: false,
    selector: 'app-confirm-stop-item',
    templateUrl: './confirm-stop-item-dialog.component.html',
    styleUrls: ['./confirm-stop-item-dialog.component.scss']
})
export class ConfirmStopItemDialogComponent {
    EntityTypes = EntityTypes;

    @Input() confirmSelectedStopItem: RouteItem;
    // @Input() confirmStop: RouteItemStop;
    // @Input() confirmEditItemIndex: number;

    // validationSchema = object().shape({
    //     itemName: string().label('Item name').nullable().required(),
    //     pickQuantity: number().integer().min(0).label('Qty').nullable(),

    // });
    validationSchema = object().shape({
        orderItemType: string().nullable(),
        itemName: string().nullable().required(),
        quantity: number().when('orderItemType', {
            is: orderItemType => (orderItemType !== PickupTypeEnum.FULL_LOAD) ,
            then: (schema) => schema.integer().min(1).label('Qty').required()
        }).nullable(),
        wight: number().nullable().positive('`Weight must be a positive number'),
        wightUnitOfMeasure: string().nullable(),
        sizeLength: number().when('orderItemType', {
            is: orderItemType => this.checkOrderItemTypeValidation(orderItemType),
            then: (schema) => schema.nullable().required(`Length is a required field`).positive('`Length must be a positive number').min(0)
        }),
        sizeWidth: number().when('orderItemType', {
            is: orderItemType => this.checkOrderItemTypeValidation(orderItemType),
            then: (schema) => schema.nullable().required(`Weight is a required field`).positive('`Weight must be a positive number').min(0)
        }),
        sizeHight: number().when('orderItemType', {
            is: orderItemType => this.checkOrderItemTypeValidation(orderItemType),
            then: (schema) => schema.nullable().required(`Height is a required field`).positive('`Height must be a positive number').min(0)
        }),
        sizeUnitOfMeasure: string().when('orderItemType', {
            is: orderItemType => this.checkOrderItemTypeValidation(orderItemType),
            then: (schema) => schema.nullable().required(`Size unit of measure is a required field`)
        }),
        sizeType: string().when('orderItemType', {
            is: (orderItemType) => (orderItemType === PickupTypeEnum.PALLETS || orderItemType === PickupTypeEnum.FULL_LOAD),
            then: (schema) => schema.nullable().required(`Type is a required field`)
        }),
    });

    constructor(
        public util: UtilService,
        public activeModal: NgbActiveModal,
    ) {
    }

    close() {
        this.activeModal.close();
    }

    checkOrderItemTypeValidation(orderItemType) {
        return (orderItemType === PickupTypeEnum.BOXES_CRATES || orderItemType === PickupTypeEnum.OTHER)
    }

    changeQtyDown(selectedStopItem) {
        if (!selectedStopItem.pickQuantity) {
            selectedStopItem.pickQuantity = 0;
        } else {
            selectedStopItem.pickQuantity = Math.max(0, selectedStopItem.pickQuantity - 1);
        }
    }

    changeQtyUp(selectedStopItem, allowUp = false) {
        if (!selectedStopItem.pickQuantity) {
            selectedStopItem.pickQuantity = 1;
        } else {
            if ((selectedStopItem.pickQuantity < selectedStopItem.quantityToPick) || allowUp) {
                selectedStopItem.pickQuantity++;
            }
        }
    }


    saveConfirmStopItem(form: FormValidateDirective) {
        this.touchAllControls(form.ngForm);
        if (!form.isValid) {
            return false;
        }
        if (this.confirmSelectedStopItem.isNewItem) {
            this.confirmSelectedStopItem.isActive = true;
            this.confirmSelectedStopItem.isNewItem = false;
        }
        //  else {
        //     const editStop = this.confirmStop.routeItems[this.confirmEditItemIndex];
        //     editStop.pickQuantity = this.confirmSelectedStopItem.pickQuantity;
        //     editStop.notes = this.confirmSelectedStopItem.notes;
        //     editStop.orderItem = this.confirmSelectedStopItem.orderItem;
        // }
        this.activeModal.close(this.confirmSelectedStopItem);
    }
    private touchAllControls(form: NgForm) {
        Object.keys(form.controls).forEach(field => {
            const control = form.control.get(field);
            control.markAsTouched({ onlySelf: true });
        });
    }
}
