import { AfterViewInit, Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgModel, ValidationErrors, Validator } from '@angular/forms';
import { splitPhoneNumberAndExtension } from '@app/@shared/utils';
import { UntilDestroy } from '@ngneat/until-destroy';
import { replace } from 'lodash';
import { CountryISO, PhoneNumberFormat, SearchCountryField, ChangeData } from 'ngx-intl-tel-input';

@UntilDestroy()
@Component({
  selector: 'app-phone-code-extension-input',
  templateUrl: './phone-code-extension-input.component.html',
  styleUrls: ['./phone-code-extension-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneCodeExtensionInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PhoneCodeExtensionInputComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.Emulated,
})
export class PhoneCodeExtensionInputComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {

  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates];
  intlTelData: ChangeData;

  @Input() disabled: boolean;
  @Input() placeholder: string = "(201) 555-0123"
  @Input() maxLength: number = 15;
  @Input() phoneLabel: string = 'Phone';
  @Input() extensionLabel: string = 'Extension';
  @Input() options: any[] = [];
  @Input() phonePlaceholder = 'Phone Number';
  @Input() extensionPlaceholder = 'Extension';
  @Input() phone = '';
  @Input() extension = '';

  @Output() onSelect = new EventEmitter<any>();
  @Output() onChangeValue = new EventEmitter<any>();
  @Output() phoneCountryCodeChange = new EventEmitter<any>();
  @Output() phoneCountryIsoChange = new EventEmitter<any>();
  @Output() phoneChange = new EventEmitter<any>();
  @Output() extensionChange = new EventEmitter<any>();

  @ViewChild('inputEl') inputEl: NgModel;

  private _phoneCountryCode: number;
  private _phoneCountryIso: string;
  private _extensionNumber: string;
  private _phoneNumber: string;

  onChange = (value: string) => { };
  onTouched = () => { };

  // Input getters and setters
  @Input()
  get phoneNumber(): string {
    return this._phoneNumber;
  }
  set phoneNumber(updatePhoneNumber: string) {
    this._phoneNumber = updatePhoneNumber;
    this.phoneChange.emit(updatePhoneNumber)
    this.handleValueChange()
  }

  @Input()
  get extensionNumber(): string {
    return this._extensionNumber;
  }
  set extensionNumber(updateExtension: string) {
    this._extensionNumber = updateExtension;
    this.extensionChange.emit(updateExtension)
    this.handleValueChange()
  }

  @Input()
  get phoneCountryIso(): string {
    return this._phoneCountryIso;
  }
  set phoneCountryIso(updatePhoneCountryIso: string) {
    this._phoneCountryIso = updatePhoneCountryIso || 'US';
    this.phoneCountryIsoChange.emit(this._phoneCountryIso);
  }

  @Input()
  get phoneCountryCode(): number {
    return this._phoneCountryCode;
  }
  set phoneCountryCode(updatePhoneCountryCode: number) {
    this._phoneCountryCode = updatePhoneCountryCode;
    this.phoneCountryCodeChange.emit(this._phoneCountryCode);
  }

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.phoneNumber ||
      changes?.phoneCountryCode ||
      changes?.phoneCountryIso
    ) {
      this.intlTelData = {
        number: this.phoneNumber?.replace(/\D/g, '') || '',
        dialCode: `+${this.phoneCountryCode || 1}`,
        countryCode: this.phoneCountryIso || 'US',
        nationalNumber: this.phoneNumber || '',
      };
      this.checkedIntlTelData();
    }

    if (changes['disabled'] && this.inputEl) {
      this.updateValidationState();
    }
  }

  ngOnInit(): void {
  }

  private updateValidationState(): void {
    if (this.disabled) {
      this.inputEl.control.disable({ emitEvent: false });
    } else {
      this.inputEl.control.enable({ emitEvent: false });
    }
    this.inputEl.control.updateValueAndValidity();
  }

  checkedIntlTelData() {
    this.phoneNumber = this.intlTelData?.number?.replace(/\D/g, '') || '';
    this.phoneCountryCode = Number(replace(this.intlTelData?.dialCode, '+', '')) || 1;
    this.phoneCountryIso = this.intlTelData?.countryCode || 'US';
  }

  handleValueChange() {
    let val = ''
    if (this._phoneNumber) {
      val += this._phoneNumber
    }
    if (this._phoneNumber && this._extensionNumber) {
      val += ` x ${this._extensionNumber}`
    }
    this.onChangeValue.emit(val);
    this.onChange(val);
    this.onTouched();
  }

  clearValue() {
    const value = null
    this.phoneNumber = value;
    this.extensionNumber = value;
    this.onChange(value);
    this.onChangeValue.emit(value);
  }

  writeValue(value: string) {
    const { phone, extension } = splitPhoneNumberAndExtension(value)
    this.phoneNumber = phone || '';
    this.extensionNumber = extension || '';
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!this.inputEl) return null;  
    const isEmpty = !this.inputEl.value;
    const isValid = this.inputEl.valid;
    return isValid || isEmpty ? null : { invalidNumber: `Please provide a valid number.` };
  }

}
