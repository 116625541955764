import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IImageUploadRequest } from '@app/models/image.model';
import { toFormData } from 'src/utils/to-form-data';
import { EntityType } from '@app/models/entity-type.model';
import { Attachment } from '@app/models/attachment.model';
import { DomSanitizer } from '@angular/platform-browser';
import { DataApiResponse } from '@app/helpers/api';
import { firstValueFrom } from 'rxjs';


@Injectable({
    providedIn: "root",
})
export class ImagesService {

    basePath = "Images";
    constructor(
        protected http: HttpClient,
        private readonly sanitizer: DomSanitizer
    ) {
    }

    upload(request: IImageUploadRequest) {
        return firstValueFrom(this.http
            .post(`${this.basePath}/upload`, toFormData(request)))
    }

    getListByOrderAttachments(orderId: number | string) {
        return firstValueFrom(this.http.get<DataApiResponse<Attachment[]>>(`Attachments/Order/${orderId}`))
            .then((res) => res?.data);
    }

    getListByInvoiceAttachments(invoiceId: number | string) {
        return firstValueFrom(this.http.get<DataApiResponse<Attachment[]>>(`Attachments/Invoice/${invoiceId}`))
            .then((res) => res?.data);
    }

    uploadAttachment(type: EntityType, id: number | string, file: File) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', `${type}`);
        formData.append('id', `${id}`);
        return firstValueFrom(this.http
            .post(`Attachments/upload/${type}/${id}`, formData))
    }

    downloadAttachmentById(attachmentId: number | string) {
        return firstValueFrom(this.http.get(`Attachments/${attachmentId}`, {
            responseType: 'blob',
        }))
    }

    previewAttachment(attachmentId: number | string) {
        return firstValueFrom(this.http.get(`Attachments/View/${attachmentId}`, {
            responseType: 'blob',
        })).then((res) => {
            const url = window.URL.createObjectURL(res);
            return this.sanitizer.bypassSecurityTrustResourceUrl(url);
        });
    }

    deleteAttachment(attachmentId: number) {
        return firstValueFrom(this.http.delete(`Attachments/${attachmentId}`))
    }

}
