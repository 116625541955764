import { Component, ElementRef, Input, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { RouteItemStop, RouteItemType } from '@app/models/route-item-stop.model';
import { RouteStopGroup } from '@app/models/route-stop-group.model';
import { HelperService } from '@app/shared/services/helper.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { chain, cloneDeep, groupBy, map, sumBy, uniq } from 'lodash';
import { debounceTime, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RoutesService } from '@app/shared/services/router.service';
import { SelectionModel } from '@angular/cdk/collections';
import { DocumentService } from '@app/shared/services/document.service';
import { DocumentViewMode } from '@app/models/document.model';
import { DomSanitizer } from '@angular/platform-browser';
@UntilDestroy()
@Component({
  selector: 'app-print-bol',
  templateUrl: './print-bol.component.html',
  styleUrls: ['./print-bol.component.scss']
})
export class PrintBolComponent implements OnInit {

  @Input() title: string;

  routeStopGroups: RouteStopGroup[];

  private _routeStopGroupsSearch: any = {
    Page: 1,
    ItemsPerPage: 20,
    status: 0,
  };

  @Input()
  public get routeStopGroupsSearch(): any {
    return this._routeStopGroupsSearch;
  }
  public set routeStopGroupsSearch(value: any) {
    this._routeStopGroupsSearch = (value);

    if (value?.routeId) {
      this.isLoading = true;
      this.getRouteItemGroups(value);
    } else {
      this.parseMessages()
      this.isLoading = false;
    }
  }

  messages: any
  messagesClone: any;
  isLoading?: boolean = true;
  loadMoreLoading?: boolean = false;
  loadRouteGroup$ = new Subject();
  hasMorePage: boolean = false;
  selection = new SelectionModel<number>(true, []);
  @ViewChild('printFrame', { static: false }) printFrame: ElementRef;

  constructor(
    private activeModal: NgbActiveModal,
    private helperService: HelperService,
    private routesService: RoutesService,
    private documentService: DocumentService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {

    this.loadRouteGroup$
      .pipe(
        debounceTime(400),
        untilDestroyed(this),
      )
      .subscribe((request: any = {}) => {
        this.getRouteItemGroups({ ...this.routeStopGroupsSearch, ...request })
      });

  }

  async parseMessages() {
    let messages = [];
    if (this.routeStopGroups) {
      messages = this.parseRouteItemGroups(this.routeStopGroups);
    }
    this.messagesClone = cloneDeep(messages)
    this.messages = messages
  }

  private getPhoneNumber(phone, extension) {
    if (phone && extension) {
      return `${phone} x ${extension}`
    }
    else if (phone) {
      return phone
    }
    return null
  }

  private parseStop(stop) {
    const isBlind = this.isBlindOrderStop(stop.orderStop);
    const stopLabel = this.getStopLabel(stop);

    return {
      type: stopLabel,
      name: stop?.orderStop?.locationCompanyName || stop?.address?.name,
      locationContactName: stop?.orderStop?.locationContactName,
      locationContactNumber: this.getPhoneNumber(stop.orderStop?.locationContactNumber, stop?.orderStop?.locationContactNumberExtension),
      locationContactEmail: stop.orderStop?.locationContactEmail,
      fullAddress: stop.address?.fullAddress,
      addressDetails: stop?.orderStop?.addressDetails,
      referenceNumber: stop?.referenceNumber,
      orderNumber: stop?.orderNumber,
      orderId: stop?.orderId,
      customerName: isBlind ? 'Shimon\'s Express' : stop?.customer?.customerName,
      routeItems: stop?.routeItemsText && !isBlind ? stop?.routeItemsText : [],
      stopId: stop?.orderStop?.orderStopId || stop?.transferStop?.transferStopId,
      // routeItems: !isBlind ? stop.routeItems?.map(({ orderItem, quantity }) => {
      //   return `${orderItem.itemName} - ${quantity} ${orderItem.itemTypeQty}`
      // }) : [],
    };
  }

  private parseRouteItemGroups(routeStopGroups) {
    const groupedStops = chain(routeStopGroups)
      .map('routeItemStops')
      .flatten()
      .filter((stop) => stop.status === 0)
      .compact()
      .map((value: any, key) => {
        value.routeItemsText = this.routeItemTexts(value, value?.routeItems)
        return value
      })
      .value();
    return groupedStops.map((stop) => {
      return this.parseStop(stop);
    })
  }

  private routeItemTexts(stop, routeItems) {
    const isBlind = this.isBlindOrderStop(stop.orderStop);
    if (isBlind) {
      return []
    }

    var activeRouteItems = routeItems?.filter(x => x.isActive) || [];

    if (!activeRouteItems.length) {
      return [];
    }

    let routeItemGroup: any = groupBy(cloneDeep(activeRouteItems), (routeItem) => {
      return `${routeItem?.orderItemStopId} ${routeItem?.orderItem?.itemName} ${routeItem?.orderItem?.itemTypeQty}`
    })

    return map(routeItemGroup, (val, keys) => {
      const totalQty = sumBy(val, (item: any) => Number(item?.quantity) || 0)
      const { orderItem } = val[0]
      return `${orderItem?.itemName} - ${totalQty || 0} ${orderItem?.itemTypeQty}`

    })
  }

  private isBlindOrderStop(orderStop) {
    return orderStop?.isBlindStop;
    // return Boolean(orderStop.isBlindStop || orderStop.blindCustomerName || orderStop.blindCustomer || orderStop.blindCustomer || orderStop.blindAddress || orderStop.blindAddressAddressId);
  }

  private convertMessageToHtml(messageData) {

    const formatMessageDetails = (data) => {
      const messageTypeClass = (data.type === 'Pickup From' || data.type === 'Pickup From Transfer') ? 'text-primary' : 'text-danger';
      const messageTypeLabel = `<b class="${messageTypeClass}">${data.type}:</b>`;
      const fullAddress = [data.name, data.fullAddress].join(', ');
      const addressDetails = data.addressDetails ? ` [${data.addressDetails}]` : '';
      return `${messageTypeLabel} ${fullAddress} ${addressDetails}`;
    }

    if (messageData instanceof Array) {

      const firstMessages = messageData[0];
      let message = [
        formatMessageDetails(firstMessages),
        firstMessages?.locationContactName ? `<b>Contact Name:</b> ${firstMessages.locationContactName}` : null,
        firstMessages?.locationContactNumber ? `<b>Phone:</b> ${firstMessages.locationContactNumber}` : null
        // firstMessages?.locationContactEmail ? `<b>Email:</b> ${firstMessages.locationContactEmail}` : null,
      ]

      messageData.forEach((data) => {
        const extraData = [
          data.referenceNumber ? `<b>Reference:</b> ${data.referenceNumber}` : null,
          `[ <b>Order Number:</b> ${data.orderNumber} ]`,
          `<b>For:</b> ${data.customerName}`,
          data.routeItems?.length > 0 ? `<b>Items:</b> ${data.routeItems?.join(', ')}` : null
        ]
          .filter(text => text);

        // Add divider if more the 1 message in group
        if (messageData.length > 1) {
          extraData[0] = `<hr /> ${extraData[0]}`;
        }

        message = message.concat(extraData);
      })

      return message
        .filter(text => text)
        .join('<br /><br />')
    } else {
      const messageHtml = [
        formatMessageDetails(messageData),
        messageData?.locationContactName ? `<b>Contact Name:</b> ${messageData.locationContactName}` : null,
        messageData?.locationContactNumber ? `<b>Phone:</b> ${messageData.locationContactNumber}` : null,
        // messageData?.locationContactEmail ? `<b>Email:</b> ${messageData.locationContactEmail}` : null,
        messageData.referenceNumber ? `<b>Reference:</b> ${messageData.referenceNumber}` : null,
        `[ <b>Order Number:</b> ${messageData.orderNumber} ]`,
        `<b>For:</b> ${messageData.customerName}`,
        messageData.routeItems?.length > 0 ? `<b>Items:</b> ${messageData.routeItems?.join(', ')}` : null
      ]
      return messageHtml
        .filter(text => text)
        .join('<br /><br />')
    }
  }

  close() {
    this.activeModal.close();
  }

  getRouteItemGroups(request) {

    if (!request?.routeId) {
      return
    }

    this.routesService.getRouteItemGroups(request).then((resp) => {
      if (resp?.page > 1) {
        this.routeStopGroups = this.routeStopGroups.concat(resp.list)
      } else {
        this.routeStopGroups = resp.list || [];
      }
      const dataCount = this.routeStopGroups?.map(x => x.routeItemStops?.length ?? 0).reduce((a, b) => a + b, 0);
      this.hasMorePage = dataCount < resp?.totalCount
      this.parseMessages();
    }).catch((error) => {
      this.routeStopGroups = []
      this.helperService.errorMessage(error)
    }).finally(() => {
      this.loadMoreLoading = false
      this.isLoading = false;
    })
  }

  loadMoreRouteGroups() {
    this.loadMoreLoading = true
    this.routeStopGroupsSearch.Page++;
    this.loadRouteGroup$.next(this.routeStopGroupsSearch);
  }

  getStopLabel(stop: RouteItemStop): string {
    const hasTransferStop = stop.routeItems?.some(
      (item) => item?.transferStopId !== null
    ) ?? false;

    switch (stop.type) {
      case RouteItemType.Delivery:
        return hasTransferStop ? 'Delivery To Transfer' : 'Deliver To';
      case RouteItemType.Pickup:
        return hasTransferStop ? 'Pickup From Transfer' : 'Pickup From';
      case RouteItemType.DeliveryToTransfer:
        return 'Delivery To Transfer';
      case RouteItemType.PickupFromTransfer:
        return 'Pickup From Transfer';
    }
    return '';
  }

  isAllChecked(): boolean {
    return this.messages.length > 0 && this.messages.every((message) => this.selection.isSelected(message.stopId));
  }

  checkAll(event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    if (isChecked) {
      this.messages.forEach((message) => this.selection.select(message.stopId));
    } else {
      this.selection.clear();
    }
  }

  toggleMessageSelection(stopId: number): void {
    this.selection.toggle(stopId);
  }

  onSubmit() {
    this.isLoading = true
    const selectedIds = this.selection?.selected;
    const selectedMessages = this.messages?.filter((message) => selectedIds?.includes(message?.stopId));
    const ids = uniq(map(selectedMessages, 'orderId'));
    this.documentService.downloadBillOfLandingBulk(DocumentViewMode.Html, { ids }).then((res) => {
      this.handlePrintContent(res);
    }).catch((error) => {
      this.isLoading = false
      this.helperService.errorMessage(error)
    })
  }

  async handlePrintContent(contents) {
    try {
      const doc = this.printFrame.nativeElement.contentDocument || this.printFrame.nativeElement.contentWindow;
      if (doc) {
        doc.open();

        const styles = `<style>
                @page { 
                    size: auto;   /* auto is the initial value */
                    margin: 8mm;  /* this affects the margin in the printer settings */
                    /* You can also specify margins here if you want a specific one */
                }
                </style>`;
        doc.write(styles);

        let sanitizedContent = this.sanitizer.sanitize(SecurityContext.HTML, contents);
        const content = `<html><head></head><body class="bol-document-print">${sanitizedContent}</body></html>`
        doc.write(content);
        // Clone the head from the main document and append it to the iframe
        const headClone = document.head.cloneNode(true);
        doc.head.appendChild(headClone);
        doc.close();

        // Wait for the content to be loaded before printing
        this.printFrame.nativeElement.onload = () => {
          const iframeWindow = this.printFrame.nativeElement.contentWindow || this.printFrame.nativeElement.contentDocument['parentWindow'];
          if (iframeWindow) {
            iframeWindow.focus();
            iframeWindow.print();
          }
          this.isLoading = false
        };
      }
    } catch (error) {
      this.isLoading = false
    }

  }

}
