import { Component, Input, OnInit, } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ImagesService } from '@app/shared/services/images.service';
import mime from 'mime';
import { FilesViewerModalComponent } from '../files-viewer-modal/files-viewer-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// Use dynamic import for pdf.js
import('pdfjs-dist/build/pdf.mjs').then(pdfjsLib => {
  pdfjsLib.GlobalWorkerOptions.workerSrc = '/assets/pdf-js/pdf.worker.min.mjs';
});

@Component({
  standalone: false,
  selector: 'app-attachment-preview',
  templateUrl: './attachment-preview.component.html',
  styleUrls: ['./attachment-preview.component.scss']
})

export class AttachmentPreviewComponent implements OnInit {
  fileType: 'image' | 'pdf' | 'video' | 'other' = 'other';
  isLoading: boolean = true;
  imageUrl: any;
  thumbnailUrl: SafeResourceUrl | string;
  originalUrl: string | Blob;

  @Input() fileName: string;
  @Input() size: number = 100;
  @Input() isPreview = false;
  @Input() showFileName = false;

  /*
  * Getter & Setter for attachmentId
  */
  _attachmentId: string;
  get attachmentId(): string {
    return this._attachmentId;
  }
  @Input() set attachmentId(value: string) {
    this._attachmentId = value;
    this.generateThumbnail(value);
  }

  constructor(
    private imagesService: ImagesService,
    private readonly sanitizer: DomSanitizer,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit(): void {
  }

  async generateThumbnail(attachmentId: string) {
    try {
      this.detectFileType();
      if (this.fileType === 'other' && this.fileName) {
        this.thumbnailUrl = null;
        this.isLoading = false;
        return;
      }
      const res = await this.imagesService.downloadAttachmentById(attachmentId);
      const blob = this.fileName ? new Blob([res], { type: mime.getType(this.fileName) }) : res;
      if (this.fileType === 'video') {
        this.generateVideoThumbnail(blob);
        this.originalUrl = URL.createObjectURL(blob)
      } else if (this.fileType === 'pdf') {
        this.originalUrl = blob;
        this.generatePDFThumbnail(blob);
      } else {
        this.thumbnailUrl = URL.createObjectURL(blob);
        this.originalUrl = URL.createObjectURL(blob)
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      console.error('Error loading attachment:', error);
    }
  }

  detectFileType() {
    const mineType = mime.getType(this.fileName)
    if (mineType?.startsWith('image/')) {
      this.fileType = 'image';
    } else if (mineType?.startsWith('video/')) {
      this.fileType = 'video';
    } else if (mineType === 'application/pdf') {
      this.fileType = 'pdf';
    } else {
      this.fileType = 'other';
    }
  }


  generateVideoThumbnail(blob: Blob) {
    const video = document.createElement('video');
    video.src = URL.createObjectURL(blob);
    video.crossOrigin = "anonymous";
    video.muted = true;
    video.currentTime = 1;

    video.onloadeddata = () => {
      const canvas = document.createElement('canvas');
      canvas.width = this.size; // Thumbnail size
      canvas.height = this.size;
      const ctx = canvas.getContext('2d');
      ctx?.drawImage(video, 0, 0, canvas.width, canvas.height);
      this.thumbnailUrl = this.sanitizer.bypassSecurityTrustUrl(canvas.toDataURL());
    };
  }

  async generatePDFThumbnail(blob: Blob) {
    const pdfjsLib = await import('pdfjs-dist/build/pdf.mjs');
    const pdf = await pdfjsLib.getDocument(URL.createObjectURL(blob)).promise;
    const page = await pdf.getPage(1);

    const scale = 1.5;
    const viewport = page.getViewport({ scale });

    const canvas = document.createElement('canvas');
    canvas.width = viewport.width;
    canvas.height = viewport.height;

    const ctx = canvas.getContext('2d');
    const renderContext = { canvasContext: ctx, viewport };
    await page.render(renderContext).promise;

    this.thumbnailUrl = this.sanitizer.bypassSecurityTrustUrl(canvas.toDataURL());
  }


  openModal() {
    let files = []
    if (this.thumbnailUrl) {
      files.push({
        name: this.fileName,
        url: this.originalUrl ||this.thumbnailUrl,
      })
    } else {
      files.push({
        name: this.fileName,
        attachmentId: this.attachmentId,
      })
    }
    const activeModal = this.modalService.open(FilesViewerModalComponent, {
      scrollable: true,
      size: 'xl',
      centered: true,
    });
    activeModal.componentInstance.isAttachments = !this.thumbnailUrl;
    activeModal.componentInstance.files = files;
  }
}
