import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { toParams } from 'src/utils/to-params';
import { ApiPaginationResponse, ApiResponse, PaginationRequest } from '@app/models/core/base';
import { User } from '@app/models/user.model';
import { firstValueFrom } from 'rxjs';  

@Injectable({
    providedIn: 'root'
})
export class CreditInquiriesService {
    basePath = "CreditInquiries";

    constructor(
        private http: HttpClient
    ) {

    }

    getAll(request?: any) {
        return firstValueFrom(this.http.get<ApiPaginationResponse<any>>(`${this.basePath}`, { params: toParams(request) }))
            .then(({ data }) => data);
    }

    create(request) {
        return firstValueFrom(this.http.post(`${this.basePath}`, request))
    }

}