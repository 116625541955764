import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@app/models/core/base';
import { Expense, NewExpense } from '@app/models/expense.model';
import { firstValueFrom } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class NewExpenseService {

    private basePath = 'Expenses'

    constructor(
        private http: HttpClient,
    ) {
    }

    addMultiple(request) {
        return firstValueFrom(this.http.post(`${this.basePath}/multiple`, request))
    }

    getOfficeExpenses(date: Date): Promise<Expense[]> {
        const params = { date: date.toISOString() };
        return firstValueFrom(this.http
            .get<ApiResponse<Expense[]>>(`${this.basePath}/OfficeExpenses`, { params }))
            .then((res) => res.data);
    }

    // Save office expenses
    saveOfficeExpenses(expenses: any[]): Promise<any> {
        return firstValueFrom(this.http.post(`${this.basePath}/OfficeExpenses`, expenses))
    }

    deleteExpense(expense: NewExpense, date: Date): Promise<any> {
        const dateStr = encodeURIComponent(date.toISOString());
        return firstValueFrom(this.http.post(`${this.basePath}/RemoveExpense?date=${dateStr}`, expense))
    }
}
