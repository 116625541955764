import { Component, Input, OnInit } from '@angular/core';
import { Company } from '@app/models/company.model';
import { AuthService } from '@app/shared/services/auth/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { navigation } from './navigation';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ViewEntityService } from '@app/shared/services/view-entity.service';
import {  ViewedEntityTypeEnum, ViewEntityStatistic, ViewEntityType } from '@app/models/view-entity.modal';
import { keyBy } from 'lodash';

@UntilDestroy()
@Component({
  standalone: false,
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  @Input() navCollapsed: boolean;
  navigation = navigation
  company: Company;
  currentUrl: string;

  constructor(
    private authService: AuthService,
    private viewEntityService: ViewEntityService,
    private router: Router,
  ) { }

  ngOnInit(): void {

    this.currentUrl = this.getPathWithoutQueryParams(this.router.url);

    this.router.events
      .pipe(
        untilDestroyed(this),
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        this.currentUrl = this.getPathWithoutQueryParams(this.router.url);
      });

    this.authService.$user
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.company = data.company;
      })
    this.viewEntityService.$statistics
      .pipe(untilDestroyed(this))
      .subscribe((statistics) => {
        this.navigation =  this.navigation?.map((navItem)=>{
            switch (navItem.path) {
              case '/app/quotes':
                navItem.count = statistics[ViewedEntityTypeEnum.QUOTE]?.count || 0
                break;
              // TODO: uncomment this when orders are implemented
              // case '/app/orders':
              //   navItem.count = statistics[ViewedEntityTypeEnum.ORDER]?.count || 0
              //   break;
              case '/app/customers':
                navItem.count = statistics[ViewedEntityTypeEnum.CUSTOMER]?.count || 0
                break;
              default:
                navItem.count = 0
                break;
            }
          return navItem
        })
      })

  }

  private getPathWithoutQueryParams(fullPath: string): string {
    const indexOfQuestionMark = fullPath.indexOf('?');
    return indexOfQuestionMark !== -1 ? fullPath.slice(0, indexOfQuestionMark) : fullPath;
  }

  isPathMatch(currentUrl: string, path: string): boolean {
    currentUrl = currentUrl.replace(/\/+$/, '');
    return currentUrl.startsWith(path);
  }

}
