export interface NavItem {
  path: string;
  title: string;
  icon?: string;
  open?: boolean;
  count?: number;
  query?: any;
  children?: NavItem[];
}

export const navigation: NavItem[] = [
  {
    path: '/app/dashboard',
    title: 'Dashboard',
    icon: 'dashboard',
  },
  {
    path: '/app/quotes',
    title: 'Quotes',
    icon: 'dollar-coin',
    query: {
      tab:'all'
    },
  },
  {
    path: '/app/orders',
    title: 'Orders',
    icon: 'box',
    query: {
      tab: 'all'
    },
  },
  {
    path: '/app/dispatch',
    title: 'Dispatch',
    icon: 'dispatch',
  },
  {
    path: '/app/customers',
    title: 'Customers',
    icon: 'peoples',
    query: {
      tab: 'all'
    },
  },
  {
    path: '/app/accounting',
    title: 'Accounting',
    icon: 'dollar-coin',
    query: {
      tab: 'all'
    },
  },
  {
    path: '/app/drivers',
    title: 'Drivers',
    icon: 'driver',
    query: {
      tab: 'all'
    },
  },
  {
    path: '/app/outsource',
    title: 'Outsource',
    icon: 'outsourcing',
    query: {
      tab: 'all'
    },
  },
  {
    path: '/app/settings',
    title: 'Settings',
    icon: 'settings',
    // open: false,
    // children: [
    //   {
    //     path: 'users',
    //     title: 'Users',
    //   },
    //   {
    //     path: 'charge-types',
    //     title: 'Charge types',
    //   },
    //   {
    //     path: 'trucks',
    //     title: 'Trucks',
    //   },
    //   {
    //     path: 'shipment-types',
    //     title: 'Shipment types',
    //   },
    //   {
    //     path: 'zones',
    //     title: 'Zones',
    //   },
    //   {
    //     path: 'Settings',
    //     title: 'Settings',
    //   },
    //   {
    //     path: 'warehouses',
    //     title: 'Warehouses',
    //   },
    // ],
  }
];