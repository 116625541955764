import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TypeaheadSelectModule } from '../typeahead-select/typeahead-select.module';
import { DirectivesModule } from '../directives/directives.module';
import { PhoneWithCodeInputComponent } from './phone-with-code-input.component';
import { NgbDropdownModule, NgbPopoverModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from '@app/shared/shared.module';


@NgModule({
  declarations: [
    PhoneWithCodeInputComponent,
  ],
  exports: [
    PhoneWithCodeInputComponent,
  ],
  imports: [
    CommonModule,
    DirectivesModule,
    SharedModule,
    FormsModule,
    TypeaheadSelectModule,
    NgbTypeaheadModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgbPopoverModule,
    NgSelectModule,
    NgbDropdownModule,
  ],
  providers: [provideNgxMask()]
})
export class PhoneWithCodeInputModule { }
