import { Component, forwardRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { environment } from '@environments/environment';
import { IFieldTypeEnum, style } from '../input-style';
import { IfieldsHandler } from '../ifields-handler';
import { AngularIfieldsComponent } from '@app/@shared/angular-cardknox-ifields';
@Component({
  standalone: false,
  selector: 'app-card-input',
  templateUrl: './card-input.component.html',
  styleUrls: ['./card-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CardInputComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.Emulated
})
export class CardInputComponent implements OnInit {
  disabled: boolean;
  onChange = (value: string) => { };
  onTouched = () => { };

  fieldOptions: any;

  ifieldHandler = new IfieldsHandler();

  @ViewChild('ifield') ifield?: AngularIfieldsComponent;

  @Input() name: string;
  @Input() placeholder: string;
  @Input() fieldType: IFieldTypeEnum;

  account = {
    xKey: environment.cardnoxKey,
    xSoftwareName: "vue-cardknox-ifields",
    xSoftwareVersion: "1.0.0"
  }


  private _value: string;
  get value(): string {
    return this._value;
  }
  set value(updateValue: string) {
    this._value = updateValue;
    this.onChange(updateValue);
    this.onTouched();
  }

  constructor() {

    this.fieldOptions = {
      iFieldstyle: style.input,
      autoFormat: true,
      autoFormatSeparator: ' ',
      enableLogging: false,
      autoSubmit: true,
      autoSubmitFormId: 'payment-form-' + this.name,
      placeholder: this.placeholder,
    }
  }

  get issuer() {
    return this.ifieldHandler.issuer || '';
  }

  ngOnInit(): void {
  }


  onToken(event) {
    console.log('onToken', event)
    this.ifieldHandler.onToken(event);
    this.value = event?.data?.xToken
  }


  writeValue(value: string) {
    this._value = value
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
