import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { OrderItemStop } from '@app/models/order-item-stop.model';
import { OrderStop, OrderStopTimeTypeEnum, OrderStopTypeEnum } from '@app/models/order-stop.model';
import { Order } from '@app/models/order.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { chain, cloneDeep, find, findIndex, map, some, uniq } from 'lodash';
import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { EditPickupItemDialogComponent } from '../../edit-pickup-item-dialog/edit-pickup-item-dialog/edit-pickup-item-dialog.component';
import { EditDeliveryItemDialogComponent } from '../../edit-delivery-item-dialog/edit-delivery-item-dialog/edit-delivery-item-dialog.component';
import { OrderService } from '@app/shared/services/order.service';
import { HelperService } from '@app/shared/services/helper.service';
import { InfoOrderDialogComponent } from '../../info-order-dialog/info-order-dialog.component';
import { EntityTypes } from '@app/models/entity-type.model';
import { RoutesService } from '@app/shared/services/router.service';
import { RouteItemStatus } from '@app/models/route-item.model';
import { ConfirmStopDialogComponent } from '@app/components/main/routes/routes-status-block/confirm-stop-dialog/confirm-stop-dialog.component';
import { ChargeType } from '@app/models/charge-type.model';
import { DateTimeService } from '@app/shared/services/datetime-service';
import { ChargeTypeService } from '@app/shared/services/charge-type.service';
import { CopyDetailsToDriverComponent } from '@app/@shared/copy-details-to-driver/copy-details-to-driver.component';
import { DocumentViewerComponent } from '@app/@shared/document-viewer/document-viewer.component';
import { DocumentService } from '@app/shared/services/document.service';
import { DocumentViewMode } from '@app/models/document.model';
import { EmailType } from '@app/models/sent-email.model';
import { saveAs } from "file-saver";
import { filterAndSortSentEmailsByEmailType } from '@app/@shared/utils';
import { OrderStopType } from '@app/models/route-box.model';
import { AddUnassignedStopsComponent } from '@app/components/main/routes/routes-status-block/add-unassigned-stops/add-unassigned-stops.component';
import { AssignTransferDialogComponent } from '@app/components/main/routes/route-details/assign-transfer-dialog/assign-transfer-dialog.component';
import { RouteItemDisplayStatus } from '@app/models/route-item-stop.model';

@Component({
  selector: 'app-order-stop',
  templateUrl: './order-stop.component.html',
  styleUrls: ['./order-stop.component.scss'],
  host: {
    "[class.pickup]": "type === 'pickup'",
    "[class.delivery]": "type === 'delivery'",
  }
})
export class OrderStopComponent implements OnInit {

  scrollContainer: HTMLElement;

  orderItemStops: OrderItemStop[] = [];

  @Input() view: 'dedicated' | 'standard' = 'standard';
  @Input() index: number = 0;
  @Input() type: 'pickup' | 'delivery' = 'pickup';
  chargeTypes: ChargeType[];

  get routeItem() {
    return this.orderStop.routeItems?.filter(({ isActive }) => isActive)[0]
  }

  stopStatusTypes: Record<RouteItemDisplayStatus, { class: string; text: string }> = {
    [RouteItemDisplayStatus.OnTime]: { class: 'on-time', text: 'On Time' },
    [RouteItemDisplayStatus.PickedUp]: { class: 'picked-up', text: 'Picked Up' },
    [RouteItemDisplayStatus.PickedUpFromTransfer]: { class: 'picked-up', text: 'Picked Up (T)' },
    [RouteItemDisplayStatus.Late]: { class: 'late', text: 'Late' },
    [RouteItemDisplayStatus.Delivered]: { class: 'delivered', text: 'Delivered' },
    [RouteItemDisplayStatus.DeliveredToTransfer]: { class: 'delivered', text: 'Delivered (T)' },
  }

  EntityTypes = EntityTypes;

  private _orderStop: OrderStop;

  @Input()
  public get orderStop(): OrderStop {
    return this._orderStop;
  }
  public set orderStop(value: OrderStop) {
    this._orderStop = value;
    this.type = value.orderStopType === OrderStopTypeEnum.DELIVERY ? 'delivery' : 'pickup'
  }

  @Input() order: Order

  @Output() onEdit = new EventEmitter()
  @Output() onDelete = new EventEmitter()
  @Output() onChange = new EventEmitter<OrderStop>()
  @Output() reloadOrder = new EventEmitter<boolean>();

  get orderStopTime(): string {
    switch (this.orderStop.timeType) {
      case OrderStopTimeTypeEnum.MORNING:
        return "Morning 6:00 AM - 12:00 PM";

      case OrderStopTimeTypeEnum.NOON:
        return "Afternoon 12:00 PM - 5:00 PM";

      case OrderStopTimeTypeEnum.EVENING:
        return "Evening 5:00 PM - 12:00 AM";

      default:
        return moment(this.orderStop.dateTimeToDisplay).format('hh:mm a');
    }
  }

  get isEditItemButtonVisible(): boolean {
    const isFullLoadItem = some(this.orderStop?.orderItemStops, (orderItemStop) => 
      orderItemStop?.orderItem?.orderItemType === 'Full Load'
    );
    return this.type === 'pickup' && !isFullLoadItem;
  }

  constructor(
    private ngbModal: NgbModal,
    private orderService: OrderService,
    private helperService: HelperService,
    private routesService: RoutesService,
    private chargeTypeService: ChargeTypeService,
    private dateTimeService: DateTimeService,
    private renderer: Renderer2,
    private documentService: DocumentService,
  ) { }

  onDrag(event: DragEvent) {
    this.autoScroll(event, this.scrollContainer);
  }


  private autoScroll(event, container: HTMLElement) {
    const scrollMargin = 50;
    const scrollStep = 5;
    const mouseY = event.clientY;
    const rect = container.getBoundingClientRect();


    if (mouseY < (rect.top + scrollMargin)) {
      // Scroll up
      container.scroll({
        top: container.scrollTop - scrollStep,
        behavior: 'auto' // Enable smooth scrolling
      });
    } else if (mouseY > (rect.bottom - scrollMargin)) {
      // Scroll down
      container.scroll({
        top: container.scrollTop + scrollStep,
        behavior: 'auto' // Enable smooth scrolling
      });
    }
  }

  ngOnInit(): void {
    this.chargeTypeService.perStopChargeTypes$
      .subscribe(chargeTypes => this.chargeTypes = chargeTypes);
  }

  ngAfterViewInit(): void {
    this.scrollContainer = this.renderer.selectRootElement('.order-body', true);
  }

  assignOrderStopToRoute() {
    if (this.order?.orderNumber && this.orderStop?.orderStopId) {
      this.routesService.assignOrderStopToRoute.next(this.orderStop?.orderStopId);
    }
  }
  async unassignRouteStopItemsFromOrderStop() {
    try {
      const routeIds = uniq(map(this.orderStop.routeItems, 'routeId'));
      for (const routeId of routeIds) {
        const stop = await this.getRouteItemGroups(routeId);
        if (stop) {
          try {
            await this.routesService.moveToUnassigned(stop, { isAttempt: false });
            this.helperService.successMessage("The route stop items have been successfully unassigned from the order stop.")
          } catch (error) {
            this.helperService.errorMessage(error)
          }
        }
      }
      this.reloadOrder.emit(true)
    } catch (error) {
      this.helperService.errorMessage(error)
    }
  }

  async getRouteItemGroups(routeId) {

    const routeStopGroups = await this.routesService.getRouteItemGroups({
      routeId,
      orderStopId: this.orderStop?.orderStopId,
    }).then((resp) => {
      this.setCorrectTimeForRouteItemStops(resp.list);
      return resp.list;
    }).catch((error) => {
      return [];
    });

    const stop: any = await chain(routeStopGroups)
      .map('routeItemStops')
      .flatten()
      .find((routeItemStops) => routeItemStops?.orderStop?.orderStopId == this.orderStop?.orderStopId)
      .value();

    return stop;
  }

  getActivityDotColor(type: string): string {
    switch (type) {
      case 'Unassigned':
        return 'text-danger';
      case 'Assigned':
        return 'text-primary';
      case 'Confirmed':
        return 'text-success';
    }
  }

  getActivityDate(date, orderStopCreatedAt) {
    const invalidDate = !date || date === '0001-01-01T00:00:00';
    return invalidDate ? orderStopCreatedAt : date;
  }

  getChildPayload = (index) => {
    return this.orderStop.orderItemStops[index];
  }

  shouldAcceptDrop = (sourceContainerOptions: any, payload: any) => {
    if (
      payload.orderStop.orderStopType === OrderStopTypeEnum.DELIVERY ||
      payload.orderStopId === this.orderStop.orderStopId ||
      this.orderStop.orderStopType === OrderStopTypeEnum.PICKUP
    ) {
      return false;
    }
    return true;
  }

  getOrderStopTitle() {
    // Check if there's a routeItem with a non-null transferStopId
    const hasTransfer = this.orderStop.routeItems?.some(
      (item) => item?.transferStopId !== null
    );

    if (hasTransfer) {
      const indexNumber = (this.index - 1) >= 1 ? (this.index - 1) : 1;

      if (this.type === 'pickup') {
        return `Pickup from Transfer - Delivery #${indexNumber}`;
      } else {
        return `Delivery for Transfer - Pickup #${indexNumber}`;
      }
    } else {
      // Use the existing logic if no transferStopId is found
      return `${this.type === 'pickup' ? 'Pickup' : 'Delivery'} #${this.index + 1}`;
    }
  }


  editOrderStop() {
    this.onEdit.emit()
  }

  deleteOrderStop() {
    this.onDelete.emit()
  }

  addEditItem(orderItemStop?: OrderItemStop) {
    const component = this.type === 'pickup' ? EditPickupItemDialogComponent : EditDeliveryItemDialogComponent

    const activeModal = this.ngbModal.open(component, {
      scrollable: true,
      size: "lg",
      windowClass: 'custom-style'
    });

    activeModal.componentInstance.order = cloneDeep(this.order);
    activeModal.componentInstance.orderStop = cloneDeep(this.orderStop);
    activeModal.componentInstance.orderItemStop = cloneDeep(orderItemStop || {});
  }

  async copyToDriver() {
    const route = find(this.order.routes, { routeId: this.routeItem?.routeId })
    const activeModal = this.ngbModal.open(CopyDetailsToDriverComponent, {
      scrollable: true,
      size: "dialog-centered",
    });
    activeModal.componentInstance.title = 'Order Stop details copied to clipboard';
    activeModal.componentInstance.order = this.order;
    activeModal.componentInstance.route = route || null;
    activeModal.componentInstance.orderStop = this.orderStop;
  }


  undoPickupDeliveryDialog() {
    this.orderService.undoOrderStopPickupDelivery(this.orderStop.orderStopId).then(() => {
      this.routeItem.status = this.routeItem.status === 1 ? 0 : 1;
      this.routeItem.isActive = false
      const foundIndex = findIndex(this.orderStop.routeItems, ({ routeItemId }) => routeItemId === this.routeItem?.routeItemId);
      if (foundIndex >= 0) {
        this.orderStop.routeItems.splice(foundIndex, 1, this.routeItem)
      }
      this.orderService.reloadRoutesData.next('update')
      this.helperService.successMessage(`Route stop is successfully undo.`);
    })
  }

  handleItemChange(value: OrderItemStop) {
    this.orderStop.orderItemStops = map(this.orderStop.orderItemStops, (item) => item.orderItemStopId == value.orderItemStopId ? value : item)
    this.onChange.emit(this.orderStop)
  }


  enterPredicate = (drag: CdkDrag<OrderItemStop>, drop: CdkDropList<OrderStop>) => {
    if (drop.data.orderStopType === OrderStopTypeEnum.PICKUP) {
      return false;
    }
    return true;
  }

  // async dropItem(dropResult: any) {
  //   const payload = dropResult.data as OrderItemStop;
  //   if (
  //     payload.orderStopId === this.orderStop.orderStopId ||
  //     this.orderStop.orderStopType === OrderStopTypeEnum.PICKUP
  //   ) {
  //     return;
  //   }

  //   const newOrderStopId = this.orderStop.orderStopId

  //   try {
  //     const order = cloneDeep(this.order);
  //     this.orderService.addOrderItemStopToOrderStop(order, payload, newOrderStopId);
  //     this.helperService.isLoading = true
  //     await this.orderService.saveOrder(order)
  //   } catch (error) {
  //     this.helperService.errorMessage(error)
  //   }
  //   this.helperService.isLoading = false

  // }


  async dropItem(dropResult: any) {

    if (dropResult.addedIndex !== null) {

      const payload = dropResult.payload as OrderItemStop;
      if (
        payload.orderStopId === this.orderStop.orderStopId ||
        this.orderStop.orderStopType === OrderStopTypeEnum.PICKUP
      ) {
        return;
      }

      const newOrderStopId = this.orderStop.orderStopId

      try {
        const order = cloneDeep(this.order);
        this.orderService.addOrderItemStopToOrderStop(order, payload, newOrderStopId);
        this.helperService.isLoading = true
        await this.orderService.saveOrder(order)
      } catch (error) {
        this.helperService.errorMessage(error)
      }
      this.helperService.isLoading = false
    }

  }


  /**
 * Order information
 */
  infoOrderStop() {
    const activeModal = this.ngbModal.open(InfoOrderDialogComponent, {
      scrollable: true,
      size: "lg",
      windowClass: 'custom-style'
    });
    activeModal.componentInstance.address = this.orderStop.address;
  }


  async updateRouteStopStatus() {
    const routeId = this.routeItem.routeId;
    this.helperService.isLoading = true
    const confirmStop = await this.getRouteItemGroups(routeId);
    if (!confirmStop?.waitingTime) {
      confirmStop.waitingTime = 0;
    }
    if (!confirmStop?.arrivalDate) {
      confirmStop.arrivalDate = moment().startOf('day').toDate();
    }
    confirmStop?.routeItems?.forEach(ri => {
      ri.pickQuantity = ri.quantity;
    });
    this.helperService.isLoading = false
    confirmStop.charges = this.chargeTypes.map(x => Object.assign({}, x));
    const activeModal = this.ngbModal.open(ConfirmStopDialogComponent, {
      scrollable: true,
      size: "lg",
    });
    activeModal.componentInstance.routeId = routeId;
    activeModal.componentInstance.confirmStop = confirmStop;
    activeModal.componentInstance.confirmStopNewStatus = RouteItemStatus.Done;
    activeModal.result.then(
      (result) => {
        if (result?.route) {
          this.routeItem.status = 1;
          const foundIndex = findIndex(this.orderStop.routeItems, ({ routeItemId }) => routeItemId === this.routeItem.routeItemId);
          if (foundIndex >= 0) {
            this.orderStop.routeItems.splice(foundIndex, 1, this.routeItem)
          }
          this.orderService.updateCacheData.next(this.order);
        }
      },
      () => { }
    );
  }


  setCorrectTimeForRouteItemStops(routeStopGroups) {
    for (let group of routeStopGroups) {
      for (let stop of group.routeItemStops) {
        stop.dateToDisplay = this.dateTimeService.combineDateAndTime(new Date(stop.date), new Date(stop.time));
      }
    }
  }

  async showBillOfLanding() {
    const order: Order = Object.assign({}, this.order);
    order.customer = {
      customerId: order.customerId
    }

    const activeModal = this.ngbModal.open(DocumentViewerComponent, {
      scrollable: true,
      size: "lg",
    });

    const content = await this.documentService.downloadBillOfLanding(order?.orderId, DocumentViewMode.Html, { deliveryId: this.orderStop?.orderStopId })

    if (!content?.changingThisBreaksApplicationSecurity) {
      activeModal.componentInstance.noResultsFound = true;
    }

    let sentEmailsBillOfLanding = await this.orderService.getSentEmails(order?.orderId,{ suggestCustomerPastSentEmails: true });
		sentEmailsBillOfLanding = await filterAndSortSentEmailsByEmailType(sentEmailsBillOfLanding,EmailType?.BillOfLanding);

    activeModal.componentInstance.content = content;
    activeModal.componentInstance.entity = this.order;
    activeModal.componentInstance.showSplitOrderStopItems = true;
    activeModal.componentInstance.showHighlightItems = true;

    if (sentEmailsBillOfLanding?.length > 0) {
      activeModal.componentInstance.emails = sentEmailsBillOfLanding?.map(se => ({ email: se?.to, type: se?.emailType, label: se?.to }));
      activeModal.componentInstance.selectedEmail = sentEmailsBillOfLanding?.filter(x => x?.isSelected).map(rse => rse?.to);
      activeModal.componentInstance.showCustomEmails = true;
    }
    else {
      activeModal.componentInstance.showEmail = true;
      activeModal.componentInstance.showPrint = true;
    }


    activeModal.result.then(
      (result) => {
        if (result) {
          if (result.type == "sendEmail") {
            this.documentService
              .emailBillOfLanding(
                result?.entity?.orderId,
                result?.email
              )
              .then(() => {
                this.helperService.successMessage(
                  "The bol has been sent"
                );              
              })
              .catch((error) => {
                this.helperService.errorMessage(error);
              });
          } else {
            this.documentService
              .downloadBillOfLanding(
                result?.entity?.orderId,
                DocumentViewMode.Pdf
              )
              .then(async (file) => {
                await saveAs(
                  file,
                  `bol-${result?.entity?.orderNumber}.pdf`
                );
              });
            this.documentService
              .downloadLabels(
                result?.entity?.orderId,
                DocumentViewMode.Pdf
              )
              .then(async (file) => {
                await saveAs(
                  file,
                  `labels-${result?.entity?.orderNumber}.pdf`
                );
              });
          }
        }
      },
      () => { }
    );
  }

  onOrderStopWarning(stop) {
    if (stop?.assignTransfer) {
      this.openAssignTransferModal(stop)
    } else if (stop?.assignPickup) {
      this.showAddUnassignedStopsDialog(stop ,OrderStopType.Pickup, stop?.orderNumber)
    } else if (stop?.assignDelivery) {
      this.showAddUnassignedStopsDialog(stop, OrderStopType.Delivery, stop?.orderNumber)
    }
  }

  async showAddUnassignedStopsDialog(stop, stopType, orderNumber = '') {

    const routeItem = stop?.routeItems[0];
    const routeCard = await this.routesService.get(routeItem?.routeId).then(async (res)=> {
      return await res  
    });    

    const orderItemId = routeItem.orderItemId || null;

    const activeModal = this.ngbModal.open(AddUnassignedStopsComponent, {
      // size: 'lg',
      scrollable: true,
    })
    activeModal.componentInstance.route = routeCard?.route;
    activeModal.componentInstance.routes = [routeCard];
    activeModal.componentInstance.search = {
      ...(stopType && { StopType: stopType }),
      ...(orderNumber && { OrderNumber: orderNumber }),
      ...(orderItemId && { orderItemId }),
      page: 1,
      itemsPerPage: 20
    }


    activeModal.result.then((result) => {
      if (result) {
        this.reloadOrder.emit(true)
      }
    }, (reason) => {
    });

  }

  openAssignTransferModal(stop) {
    const currRouteItem = stop?.getTransferRouteItem;
    const otherRouteItem = currRouteItem?.getTransferRouteItem;
    const orderItem = currRouteItem?.orderItem;
    const currPickupOff = currRouteItem?.type === 'Pickup';
    const request = {
      OrderNumber: stop.orderNumber,
      Customer: stop.customer,
      DropOffRouteItem: currPickupOff ? currRouteItem : otherRouteItem,
      PickupRouteItem: currPickupOff ? otherRouteItem : currRouteItem,
    };
    this.helperService.isLoading = true
    this.routesService.getTransferOptions(request).then((res: any) => {
      this.helperService.isLoading = false
      let routeTransfer = res?.data;
      routeTransfer.DeliveryTimeType = res?.DeliveryTimeType;
      routeTransfer.PickupTimeType = res?.PickupTimeType;
      const activeModal = this.ngbModal.open(AssignTransferDialogComponent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
        scrollable: true,
      })
      activeModal.componentInstance.routeTransfer = cloneDeep(routeTransfer);
      activeModal.componentInstance.stopStatusTypes = cloneDeep(this.stopStatusTypes);
      activeModal.result.then((result) => {
        if (result) {
          this.reloadOrder.emit(true)
        }
      }, (reason) => {

      });
    }).catch((error) => {
      this.helperService.isLoading = false
      this.helperService.errorMessage(error)
    })
  }


}
