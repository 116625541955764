import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OrderPriorityEnum } from '@app/models/order.model';
import { HelperService } from '@app/shared/services/helper.service';
import { OrderService } from '@app/shared/services/order.service';

@Component({
  standalone: false,
  selector: 'app-order-priority',
  templateUrl: './order-priority.component.html',
  styleUrls: ['./order-priority.component.scss'],
})
export class OrderPriorityComponent {

  @Input() orderId: string | number;
  @Input() orderStopId: string | number;
  @Input() type: 'order' | 'orderStop' = 'order';
  private _priority: OrderPriorityEnum = OrderPriorityEnum.MIDDLE;
  @Input() isTextInput: boolean = false;
  @Input() isTextCaption: boolean = false;
  @Input() isShowSuccessMessage = false;

  @Input()
  set priority(value: OrderPriorityEnum) {
    if (value !== null) {
      this._priority = value;
    } else {
      this._priority = OrderPriorityEnum.MIDDLE;
    }
  }

  @Output() priorityChange = new EventEmitter<OrderPriorityEnum>();

  get priority(): OrderPriorityEnum {
    return this._priority;
  }

  OrderPriorityEnum = OrderPriorityEnum

  constructor(
    private orderService: OrderService,
    private helperService: HelperService,
  ) {
  }

  async handleChangePriority(event) {
    event.stopPropagation();
    if (!this.priority) {
      this.priority = OrderPriorityEnum.MIDDLE;
    }
    let newPriority: OrderPriorityEnum;
    const oldPriority = this.priority;
    switch (this.priority) {
      case OrderPriorityEnum.LOWEST:
        newPriority = OrderPriorityEnum.LOW;
        break;
      case OrderPriorityEnum.LOW:
        newPriority = OrderPriorityEnum.MIDDLE;
        break;
      case OrderPriorityEnum.MIDDLE:
        newPriority = OrderPriorityEnum.HIGH;
        break;
      case OrderPriorityEnum.HIGH:
        newPriority = OrderPriorityEnum.HIGHEST;
        break;
      case OrderPriorityEnum.HIGHEST:
        newPriority = OrderPriorityEnum.LOWEST;
        break;
      default:
        newPriority = OrderPriorityEnum.MIDDLE;
    }
    this.priority = newPriority;
    try {
      if (this.orderStopId && this.type === 'orderStop') {
        await this.orderService.updateOrderStopPriority(this.orderStopId, newPriority)
      } else if (this.orderId && this.type === 'order') {
        await this.orderService.updateOrderPriority(this.orderId, newPriority)
      }
      this.priorityChange.emit(this.priority);
      if (this.isShowSuccessMessage) {
        this.helperService.successMessage(`${this.type === 'order' ? 'Order' : 'Order Stop'} priority successfully updated.`);
      }
    } catch (error) {
      this.priority = oldPriority;
      this.helperService.errorMessage(error);
    }
  }

  getTitleAndCssPriority(isTitle: boolean = false) {
    switch (this.priority) {
      case OrderPriorityEnum.LOWEST:
        return isTitle ? 'text-gray-400 icon-lowest' : 'Lowest';

      case OrderPriorityEnum.LOW:
        return isTitle ? 'text-gray-400 icon-low' : 'Low';

      case OrderPriorityEnum.MIDDLE:
        return isTitle ? 'text-primary icon-medium' : 'Middle';

      case OrderPriorityEnum.HIGH:
        return isTitle ? 'text-danger icon-high' : 'High';

      case OrderPriorityEnum.HIGHEST:
        return isTitle ? 'text-danger icon-highest' : 'Highest';

      default:
        return isTitle ? 'text-primary icon-medium' : 'Middle';
    }
  }

}
