// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
}
:host .image-container {
  width: 100%;
  height: 72vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/files-viewer-modal/image-viewer/image-viewer.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;AAAI;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AAER","sourcesContent":[":host {\n    width: 100%;\n    .image-container {\n        width: 100%;\n        height: 72vh;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        overflow: hidden;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
