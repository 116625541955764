import { Attachment } from '@models/attachment.model';
import { BaseModel } from '@models/base.model';
import { PickupTypeEnum } from './pickup-type';

export interface OrderItem extends BaseModel {
    orderItemId?: number;
    order?: string;
    orderItemType?: PickupTypeEnum;
    itemName?: string;
    itemDescription?: string;
    sizeUnitOfMeasure?: string;
    totalWeightUnitOfMeasure?: string;
    sizeType?: string;
    sizeLength?: number;
    sizeWidth?: number;
    sizeHight?: number;
    sizeTypeName?: string;
    wight?: number;
    wightUnitOfMeasure?: string;
    accessoriesNeeded?: string;
    isFragile?: boolean;
    isHazardous?: boolean;
    isRefrigerated?: boolean;
    isOverweight?: boolean;
    isOther?: boolean;
    orderItemStops?: any[];
    isActive?: boolean;
    routeItems?: any[];
    quantityToPick?: number;
    pickQuantity?: number;
    routePickupOrderItemStopId?: number;
    routeDeliveryOrderItemStopId?: number;
    totalQuantity?: number;
    pickedQuantity?: number;
    formatedMeasurements?: string;
    formatedWeight?: string;
    itemTypeQty?: string;
    createdTime?: string;
    createdUser?: string;
    updatedTime?: string;
    updatedUser?: string;
    formattedUnitOfMeasure?: string;
    attachments?: Attachment[];
    cbmWeight?: number;
    quantityOfTrucks?: number;
    weightUnitOfMeasure?: WeightUnitOfMeasureEnum;
    totalWeight?: number;
    containerNumber?: string;
    withTracker?: boolean;
    itemsPackedIn?: OrderItemPackedInEnum;
    fullLoadPalletSize?: any;
}



export enum OrderItemPackedInEnum {
    BOX_AND_CRATES = 0,
    BOXES = 1,
    PALLETS = 2
}

export enum WeightUnitOfMeasureEnum {
    POUND = 'Pound',
    KILOGRAM = 'Kilogram',
    // 3 = '3',
    //  4 = '4',
}