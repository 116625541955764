import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-warning-stops-departure-dialog',
    templateUrl: './warning-stops-departure-dialog.component.html',
    styleUrls: ['./warning-stops-departure-dialog.component.scss']
})
export class WarningStopsDepartureDialogComponent implements OnInit {

    @Input() messages;

    constructor (
        public readonly activeModal: NgbActiveModal
    ) {}

    ngOnInit() {
    }

}
