import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EnumFlagService, EnumObj } from '@app/shared/services/enum-flag.service';

interface Item {
    Name: string;
    Selected: boolean;
    Level: number;
}

@Component({
    standalone: false,
    selector: 'app-enum-flags-drop-down-select',
    templateUrl: './enum-flags-drop-down-select.component.html',
    styleUrl: './enum-flags-drop-down-select.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EnumFlagsDropDownSelectComponent),
            multi: true
        }
    ]
})
export class EnumFlagsDropDownSelectComponent implements ControlValueAccessor {

    @Input() enumObj?: EnumObj;
    @Input() label: string;
    @Input() disabled: boolean = false;

    value?: number;
    items: Item[] = [];

    onChange: any = () => { };

    constructor(
        private enumFlagService: EnumFlagService
    ) { }

    get selectedItems() {
        return this.items.filter(x => x.Selected);
    }

    writeValue(value: any) {
        this.initItems();
        this.value = value;
        this.parceFlag()
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched() { }

    setDisabledState() { }

    initItems() {
        if (this.enumObj)
            this.items = this.enumFlagService
                .getEnumAsArray(this.enumObj)
                .map<Item>(x => ({
                    Name: x.value,
                    Level: x.key,
                    Selected: false
                }));
    }

    itemSelectionUpdated(item: Item) {
        this.updateValue();
    }

    updateValue() {
        this.value = 0;
        this.selectedItems?.forEach(x => this.value += x?.Level)
        this.onChange(this.value);
    }

    parceFlag() {
        if (this.enumObj) {
            this.enumFlagService.parceValueToArray(this.enumObj, this.value)
                .forEach(x => {
                    const item = this.items?.filter(y => y?.Level == x?.key)[0];
                    if (item) {
                        item.Selected = true;
                    }
                })
        }
    }
}
