// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .permissions-checkbox {
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  display: flex;
}
:host .left-border {
  border-left: 1px solid #e5e7eb;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/settings/users/user-setting/add-edit-permission-dialog/add-edit-permission-dialog.component.scss"],"names":[],"mappings":"AAEI;EACI,yBAAA;EACA,kBAAA;EACA,aAAA;AADR;AAGI;EACI,8BAAA;AADR","sourcesContent":["@use \"common/var\" as *;\n:host {\n    .permissions-checkbox {\n        border: 1px solid $divider;\n        border-radius: 8px;\n        display: flex;\n    }\n    .left-border {\n        border-left: 1px solid $divider;\n    }\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
