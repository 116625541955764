import { Injectable } from '@angular/core';
import { ApiPaginationResponse, ApiResponse } from '@app/models/core/base';
import { toParams } from 'src/utils/to-params';
import { Customer, CustomerContactDetails, PipelineEnum, ValidateCustomer } from '@app/models/customer.model';
import { CrudService } from './core/crud.service';
import { PaymentMethod } from '@app/models/payment-method.model';
import { HttpClient } from '@angular/common/http';
import { toFormData } from 'src/utils/to-form-data';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class CustomerService extends CrudService<Customer> {

    basePath = 'Customers'

    private _reloadCustomers: BehaviorSubject<any>;
    private _customerDatatableRefresh: BehaviorSubject<boolean>;

    constructor(
        protected http: HttpClient
    ) {
        super(http)

        this._reloadCustomers = new BehaviorSubject(null);
        this._customerDatatableRefresh = new BehaviorSubject(false);
    }

    public get $reloadCustomers(): Observable<boolean> {
        return this._reloadCustomers.asObservable();
    }

    public set reloadCustomers(value: boolean) {
        this._reloadCustomers.next(value);
    }

    public get $customerDatatableRefresh(): Observable<boolean> {
        return this._reloadCustomers.asObservable();
    }

    public set customerDatatableRefresh(value: boolean) {
        this._reloadCustomers.next(value);
    }

    buildDelete(ids: number[] | string[] | any) {
        return this.http.delete(`${this.basePath}`, { params: toParams({ ids }) }).toPromise()
    }

    createPaymentMethod(customerId: string | number, request?: any) {
        return firstValueFrom(this.http.post<ApiResponse<PaymentMethod>>(`${this.basePath}/${customerId}/PaymentMethods`, request))
            .then((({ data }) => data));
    }

    updatePaymentMethod(paymentMethodId: string | number, request?: any) {
        return firstValueFrom(this.http.put<ApiResponse<PaymentMethod>>(`${this.basePath}/PaymentMethods/${paymentMethodId}`, request))
            .then((({ data }) => data));
    }

    deletePaymentMethod(paymentMethodId: string | number) {
        return firstValueFrom(this.http.delete(`${this.basePath}/PaymentMethods/${paymentMethodId}`))
    }

    uploadFile(request: any) {
        console.log(request);

        return firstValueFrom(this.http.post<ApiResponse<any>>(`${this.basePath}/import`, toFormData(request)))
    }

    exportCustomer(request: any) {
        this.downloadFile(`${this.basePath}/export`, {
            params: request,
            name: 'customers.csv'
        })
    }

    customExport(request: any) {
        this.downloadFile(`${this.basePath}/CustomExport`, {
            params: request,
            name: 'customers.csv'
        })
    }

    exportCustomerSample() {
        this.downloadFile(`${this.basePath}/export`, {
            params: { sample: 'true' },
            name: 'customers.csv'
        })
    }

    searchCustomerAddress(customerId, searchTerm) {
        return firstValueFrom(this.http.get<ApiPaginationResponse<PaymentMethod>>(`${this.basePath}/${customerId}/lookup_address`, {
            params: toParams({
                SearchTerm: searchTerm,
                Page: 1,
                ItemsPerPage: 30,
            })
        }))
            .then((({ data }) => data));
    }

    search(searchTerm, searchOnlyByCustomerName = false) {
        return firstValueFrom(this.http.get<ApiPaginationResponse<any>>(`${this.basePath}`, {
            params: toParams({
                SearchTerm: searchTerm,
                Page: 1,
                ItemsPerPage: 10,
                searchOnlyByCustomerName
            })
        }))
            .then((({ data }) => data));
    }

    getCustomerContactDetails(id: string | number) {
        return firstValueFrom(this.http.get<ApiResponse<CustomerContactDetails>>(`${this.basePath}/${id}/contacts`))
            .then((({ data }) => data));
    }

    validateCustomer(request?: any) {
        return firstValueFrom(this.http.post<ApiResponse<ValidateCustomer>>(`${this.basePath}/ValidateCustomer`, request))
            .then((({ data }) => data));
    }

    updatePipeline(id: string | number, pipeline: PipelineEnum) {
        return firstValueFrom(this.http.put<ApiResponse<any>>(`${this.basePath}/${id}/${pipeline}`, {}))
            .then((({ data }) => data));
    }

    getCustomerLocations(id: string | number, customerName: string) {
        return firstValueFrom(this.http.get<ApiResponse<{ address: any, companyName: string, customerId: number }[]>>(`${this.basePath}/${id}/locations?customerName=${customerName}`))
            .then(({ data }) => data);
    }

    mergeCustomers(request: {
        parentCustomerId: number;
        customerIds: number[];
    }) {
        return firstValueFrom(this.http.put<ApiResponse<any>>(`${this.basePath}/MergeCustomers`, request))
    }
    deleteDuplicatesAddresses() {
        return firstValueFrom(this.http.delete(`${this.basePath}/DeleteDuplicates/Addresses`));
    }

    deleteDuplicatesContacts() {
        return firstValueFrom(this.http.delete(`${this.basePath}/DeleteDuplicates/Contacts`));
    }
}
