import { Component, OnInit } from '@angular/core';
import { OrderStopTypeEnum } from '@app/models/order-stop.model';
import { Order } from '@app/models/order.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WarehouseSelectorComponent } from '../warehouse-selector/warehouse-selector.component';

@Component({
  standalone: false,
  selector: 'app-add-location',
  templateUrl: './add-location.component.html',
  styleUrls: ['./add-location.component.scss']
})
export class AddLocationComponent implements OnInit {
  hub: string;
  meetup: string;

  order: Order;
  warehouseForPickupDeliveryStopIds: any;

  pickupStops: any[] = [];
  deliveryStops: any[] = [];

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) {

  }

  onChange = (value: any) => {
    console.log(value);
  };

  onTouched = () => { };

  ngOnInit(): void {
    this.filterOrderStops();
  }

  private _value: any;
  get value(): any {
    return this._value;
  }
  set value(updateValue: any) {
    this._value = updateValue;
    this.onChange(updateValue);
    this.onTouched();
  }

  close() {
    this.activeModal.close(false);
  }

  done(){
    this.activeModal.close(true);
  }

  handleAddHub(value, orderStopId) {
    if (value === 'hub') {
      this.hub = value

      const modalRef = this.modalService.open(WarehouseSelectorComponent, {
        scrollable: true,
        size: "lg",
      });

      
      modalRef.componentInstance.pickup = this.pickupStops.find(x => x.orderStopId === orderStopId);
      modalRef.componentInstance.delivery = this.getDelivery(orderStopId);

      modalRef.result.then((selectedWarehouses) => {
        this.hub = selectedWarehouses;
      }).catch((error) => {
        console.log('Modal dismissed');
      });
    }
    if (value === 'meet-up') {
      this.meetup = value
    }

  }

  selectedZones(zone) {
    this.value = zone;
    // this.dropdown.close();
    // this.zoneChange.emit(this.value);
  }

  filterOrderStops() {
    if(this.order){
      this.pickupStops = this.order.orderStops.filter(stop => 
        stop.orderStopType === OrderStopTypeEnum.PICKUP && this.warehouseForPickupDeliveryStopIds.hasOwnProperty(stop.orderStopId)
      );
  
      this.deliveryStops = this.order.orderStops.filter(stop => 
        stop.orderStopType === OrderStopTypeEnum.DELIVERY && Object.values(this.warehouseForPickupDeliveryStopIds).includes(stop.orderStopId)
      );
    }
  }

  getDelivery(pickupStopId: number) {
    const deliveryStopId = this.warehouseForPickupDeliveryStopIds[pickupStopId];
    const deliveryStop = this.order.orderStops.find(stop => stop.orderStopId === deliveryStopId);
    return deliveryStop;
  }

  isDoneDisabled(): boolean {
    return this.pickupStops ? this.pickupStops.some(stop => !stop.warehouseId) : true;
  }
}
