// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

.company-logo {
  border-radius: 35px;
  overflow: hidden;
}
.company-logo .table-image {
  height: 70px;
  width: 70px;
  object-fit: cover;
}

.company-view-more {
  font-size: 12px;
  margin-top: 7px;
}

:host .table {
  max-height: calc(100% - 220px);
}

.dropdown-menu .dropdown-item {
  padding: 10px 14px 10px 6px;
}
.dropdown-menu .dropdown-item i {
  display: inline-block;
  font-size: 16px;
  text-align: center;
  width: 34px;
}

.new-transaction .dropdown-menu {
  overflow: hidden;
  padding: 0;
}
.new-transaction .dropdown-toggle::after {
  display: none;
}
.new-transaction .dropdown-item {
  border-bottom: 2px solid #f1f1f1;
  padding: 10px 6px;
}
.new-transaction .dropdown-item:last-of-type {
  border-bottom: none;
}
.new-transaction .dropdown-item i {
  display: inline-block;
  font-size: 16px;
  text-align: center;
  width: 34px;
}
.new-transaction .qb-icon {
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/accounting/accounting-details/accounting-details.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;;AAEA;EACI,mBAAA;EACA,gBAAA;AACJ;AAAI;EACI,YAAA;EACA,WAAA;EACA,iBAAA;AAER;;AAEA;EACI,eAAA;EACA,eAAA;AACJ;;AAEA;EACI,8BAAA;AACJ;;AAGI;EACI,2BAAA;AAAR;AAEQ;EACI,qBAAA;EACA,eAAA;EACA,kBAAA;EACA,WAAA;AAAZ;;AAMI;EACI,gBAAA;EACA,UAAA;AAHR;AAMI;EACI,aAAA;AAJR;AAOI;EACI,gCAAA;EACA,iBAAA;AALR;AAOQ;EACI,mBAAA;AALZ;AAQQ;EACI,qBAAA;EACA,eAAA;EACA,kBAAA;EACA,WAAA;AANZ;AAUI;EACI,cAAA;AARR","sourcesContent":[":host {\n    display: block;\n}\n\n.company-logo {\n    border-radius: 35px;\n    overflow: hidden;\n    .table-image {\n        height: 70px;\n        width: 70px;\n        object-fit: cover;\n    }\n}\n\n.company-view-more {\n    font-size: 12px;\n    margin-top: 7px;\n}\n\n:host .table {\n    max-height: calc(100% - 220px);\n}\n\n.dropdown-menu {\n    .dropdown-item {\n        padding: 10px 14px 10px 6px;\n\n        i {\n            display: inline-block;\n            font-size: 16px;\n            text-align: center;\n            width: 34px;\n        }\n    }\n}\n\n.new-transaction {\n    .dropdown-menu {\n        overflow: hidden;\n        padding: 0;\n    }\n\n    .dropdown-toggle::after {\n        display: none;\n    }\n\n    .dropdown-item {\n        border-bottom: 2px solid #f1f1f1;\n        padding: 10px 6px;\n\n        &:last-of-type {\n            border-bottom: none;\n        }\n\n        i {\n            display: inline-block;\n            font-size: 16px;\n            text-align: center;\n            width: 34px;\n        }\n    }\n\n    .qb-icon {\n        margin: 0 auto;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
