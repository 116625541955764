import { VehicleLocation } from '@models/samsara/vehicle-location.model';
import { Injectable } from "@angular/core";
import { haversineDistance } from '@app/helpers/util';
import { Route } from '@app/models/route.model';
import { environment } from '@environments/environment';
import DirectionsResult = google.maps.DirectionsResult;
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import { MarkerInfo } from '@app/@shared/route-map/route-map.component';

@Injectable({
    providedIn: "root"
})
export class TrimbleMapsService {

    private milesToKmCoefficient = 1.609;

    constructor() {}

    initMap(mapOptions: TrimbleMaps.MapOptions) {
        TrimbleMaps.setAPIKey(environment.trimbleMapsApiKey);
        return new TrimbleMaps.Map(mapOptions)
            .addControl(new TrimbleMaps.NavigationControl())
            .addControl(new TrimbleMaps.ScaleControl({maxWidth: 80, unit: 'imperial'}))
            .addControl(new TrimbleMaps.TrafficIncidentClickControl())
            .addControl(new TrimbleMaps.TrafficCameraClickControl());
    }

    getRoute(directions: DirectionsResult, routeId: number): TrimbleMaps.Route {
        TrimbleMaps.setAPIKey(environment.trimbleMapsApiKey);

        let stops = [];

        directions?.routes[0]?.legs.forEach(leg => {
            if (stops.length > 0) {
                stops.push(leg.end_location);
            }
            else {
                stops.push(leg.start_location);
                stops.push(leg.end_location);
            }
        });
        if(stops.length > 0){
            let route = new TrimbleMaps.Route({
                frameRoute: false,
                routeId: routeId.toString(),
                stops: stops
            });

            return route;
        }
    }

    setupUnassignedMarkers(map: TrimbleMaps.Map, unassignedMarkers: MarkerInfo[], unassignedTrimbleMapsMarkers: TrimbleMaps.Marker[] ) {
        unassignedMarkers.forEach(stop => {
            let content = '<svg width="24" height="24">' +
                '<img style="height: 24px" src="assets/images/route-start.png" alt="Pickup">' +
                '</svg>';

            if (stop.type === 'dropoff') {
                content = '<svg width="24" height="24">' +
                '<img style="height: 24px" src="assets/images/route-end.png" alt="Pickup">' +
                '</svg>';
            }

            const svgContent = document.createElement('div');
            svgContent.setAttribute("data-stop-type", stop.type.toString());
            svgContent.innerHTML = content;

            const dropOffMarker = new TrimbleMaps.Marker({
                draggable: false,
                element: svgContent
                }).setLngLat([stop.longitude, stop.latitude])
                .setPopup(new TrimbleMaps.Popup({
                    offset: 24
                }).setText(stop.label))
                .addTo(map);

            unassignedTrimbleMapsMarkers.push(dropOffMarker);
        });
    }

    setMarkerVisibility(isVisible: Boolean, unassignedTrimbleMapsMarkers: TrimbleMaps.Marker[], stopType?: string) {
        let display = (isVisible) ? '' : 'none';

        if (typeof stopType !== 'undefined') {
            unassignedTrimbleMapsMarkers.forEach(m => {
                let el = m.getElement();
                if (el.getAttribute('data-stop-type') === stopType) {
                    el.style.display = display;
                }});
        }
        else {
            for (let i = 0; i < unassignedTrimbleMapsMarkers.length; i++) {
                let marker = unassignedTrimbleMapsMarkers[i];
                let popup = marker.getPopup();

                if (isVisible === false && popup && popup.isOpen()) {
                    popup.remove();
                }

                let el = marker.getElement();
                el.style.display = display;
            }
        }
    }

    removeUnassignedMarkers(unassignedTrimbleMapsMarkers: TrimbleMaps.Marker[]) {
        unassignedTrimbleMapsMarkers.forEach(marker=>{
            marker.remove();
        });
    }

    addRadiusCircle(map : TrimbleMaps.Map, lng: number, lat: number, radius: number, isHaveCircle: Boolean, dontCreateMarkers: Boolean,
        editingMarkers: TrimbleMaps.Marker[], circleCenter: [number, number], circleCenterMarker: TrimbleMaps.Marker) {
        if(isHaveCircle){
            map.removeLayer("circle");
            map.removeSource("circle");
        }

        const bigCircle = this.createGeoJSONCircle([lng, lat], radius, 128);

        map.addSource("circle", bigCircle as TrimbleMaps.AnySourceData);

        map.addLayer({
            "id": "circle",
            "type": "fill",
            "source": "circle",
            "layout": {},
            "paint": {
                "fill-color": "grey",
                "fill-opacity": 0.6
            }
            });

        if(!dontCreateMarkers){
            return this.addMarkersForEdition(map, bigCircle.data.features[0].geometry.coordinates, false,
                editingMarkers, circleCenter, circleCenterMarker);
        }
    }

    removeRadiusCircle(map : TrimbleMaps.Map, isHaveCircle: Boolean, editingMarkers: TrimbleMaps.Marker[], circleCenterMarker: TrimbleMaps.Marker) {
        if(isHaveCircle){
            map.removeLayer("circle");
            map.removeSource("circle");
            editingMarkers.forEach(marker => marker.remove());
            circleCenterMarker?.remove();
        }
    }

    addTruckMarker (map: TrimbleMaps.Map, vehicleLocation: VehicleLocation, route: Route,
        truckMarkers : TrimbleMaps.Marker[]) {
        let content = '<svg width="8" height="8">' +
        '<img src="assets/images/map/truck.png" alt="truck">' +
        '</svg>';

        const svgContent = document.createElement('div');
        svgContent.innerHTML = content;
        svgContent.setAttribute("data-samsaraId", vehicleLocation.id.toString())

        const truckMarker = new TrimbleMaps.Marker({
            draggable: true,
            element: svgContent
            }).setLngLat([vehicleLocation.longitude, vehicleLocation.latitude])
            .setPopup(new TrimbleMaps.Popup({
                offset: 24
            }).setText(route?.driver.firstName + ' ' + route?.driver.lastName + ' '
            + route?.truck.plateNumber + ' ' + route?.truck.name))
            .addTo(map);

        truckMarkers.push(truckMarker);
    }

    updateTruckMarkerLocation(vehicalLocation: VehicleLocation, truckMarkers : TrimbleMaps.Marker[]) {
        let id = vehicalLocation.id.toString();
        truckMarkers.find(x=>x.getElement().getAttribute("data-samsaraId") === id)
        .setLngLat([vehicalLocation.longitude, vehicalLocation.latitude]);
    }

    drawPreviewCircle(map: TrimbleMaps.Map, isHavePreviewCircle: Boolean, circleCenter: [number, number], radius: number) {
        if(isHavePreviewCircle){
            map.removeLayer("previewCircle");
            map.removeSource("previewCircle");
        }

        const bigCircle = this.createGeoJSONCircle(circleCenter, radius, 128);

        map.addSource("previewCircle", bigCircle as TrimbleMaps.AnySourceData);

        map.addLayer({
            "id": "previewCircle",
            "type": "line",
            "source": "previewCircle",
            "layout": {},
            "paint": {
                "line-gap-width": 1,
                'line-opacity': 0.6
            }
            });
    }

    replaceEditingMarkers(map: TrimbleMaps.Map, circleCenter: [number, number], radius: number, editingMarkers: TrimbleMaps.Marker[], circleCenterMarker: TrimbleMaps.Marker) {
        let circlePoints = this.getCircleCoordinates(circleCenter, radius, 128);
        this.addMarkersForEdition(map, circlePoints, true, editingMarkers, circleCenter, circleCenterMarker);
    }

    scaleCircleAndGetRadius(map: TrimbleMaps.Map, newBorder : [number, number], circleCenter: [number, number], editingMarkers: TrimbleMaps.Marker[],
        isHaveCircle: Boolean, circleCenterMarker: TrimbleMaps.Marker) : number {
        const circleRadius = haversineDistance({lat: circleCenter[1], lng: circleCenter[0]},
            {lat: newBorder[1], lng: newBorder[0]});

        this.addRadiusCircle(map, circleCenter[0], circleCenter[1], circleRadius * this.milesToKmCoefficient, isHaveCircle,
            true, editingMarkers, circleCenter, circleCenterMarker);

        return circleRadius * this.milesToKmCoefficient;
    }

    private addMarkersForEdition(map: TrimbleMaps.Map, coordinates, isCircleDrag: Boolean, editingMarkers: TrimbleMaps.Marker[],
        circleCenter: [number, number], circleCenterMarker: TrimbleMaps.Marker) {
        if(editingMarkers.length>0){
            editingMarkers.forEach(marker => {marker.remove()});
        }

        let isEW = true;

        for (let i=0; i<coordinates[0].length; i = i+32){
            let content = '<svg width="8" height="8">' +
                '<circle stroke="#FFF" fill="white" cx="4" cy="4" r="4" />' +
                '</svg>';
            const svgContent = document.createElement('data-samsaraId');
            svgContent.innerHTML = content;
            const editMarker = new TrimbleMaps.Marker({
                draggable: true,
                element: svgContent
            }).setLngLat([coordinates[0][i][0], coordinates[0][i][1]])
                .addTo(map);
            editingMarkers.push(editMarker);
            editMarker.getElement().style.cursor = isEW ? 'ew-resize' : 'ns-resize';
            isEW = !isEW;
        }

        if(!isCircleDrag){
            if(circleCenterMarker){
                circleCenterMarker.remove();
            }
            return this.createCenterCircleMarker(map, circleCenterMarker, circleCenter);
        }
    }

    private createCenterCircleMarker(map: TrimbleMaps.Map, circleCenterMarker: TrimbleMaps.Marker, circleCenter: [number, number]) {
        let content = '<svg width="8" height="8">' +
            '<circle stroke="#FFF" fill="white" cx="4" cy="4" r="4" />' +
            '</svg>';
        const svgContent = document.createElement('div');
        svgContent.innerHTML = content;
        circleCenterMarker = new TrimbleMaps.Marker({
            draggable: true,
            element: svgContent
        }).setLngLat([circleCenter[0], circleCenter[1]])
            .addTo(map);

        circleCenterMarker.getElement().style.cursor = 'move';

        return circleCenterMarker;
    }

    //taken from https://stackoverflow.com/a/39006388
    private getCircleCoordinates(circleCenter: [number, number], radius: number, points: number) {

        const coords = {
        latitude: circleCenter[1],
        longitude: circleCenter[0]
        };
        const km = radius;
        const ret = [];
        const distanceX = km / (111.320 * Math.cos(coords.latitude * Math.PI / 180));
        const distanceY = km / 110.574;
        let theta, x, y;
        for (let i = 0; i < points; i++) {
            theta = (i / points) * (2 * Math.PI);
            x = distanceX * Math.cos(theta);
            y = distanceY * Math.sin(theta);
            ret.push([coords.longitude + x, coords.latitude + y]);
        }
        ret.push(ret[0]);

        return [ret];
    }

    //taken from https://stackoverflow.com/a/39006388
    private createGeoJSONCircle(circleCenter: [number, number], radius: number, points: number) {

        return {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: [{
                type: 'Feature',
                geometry: {
                    type: 'Polygon',
                    coordinates: this.getCircleCoordinates(circleCenter, radius, points)
                }
                }]
            }
        };
    }
}
