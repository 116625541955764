import { Component, Input, OnInit } from '@angular/core';
import { TypeOfTruck, TypeOfTruckTypeEnum } from '@app/models/type-of-truck.model';
import { HelperService } from '@app/shared/services/helper.service';
import { TypeOfTrucksService } from '@app/shared/services/type-of-truck.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { object, string } from 'yup';

@Component({
    standalone: false,
    selector: 'app-add-edit-type-of-truck-dialog',
    templateUrl: './add-edit-type-of-truck-dialog.component.html',
})
export class AddEditTypeOfTruckDialogComponent implements OnInit {

    loading = true;
    saveLoading = false;
    @Input() item: TypeOfTruck;
    title = "Type of Truck";

    types = [
        {
            value: TypeOfTruckTypeEnum.PickupTruck,
            label: 'Pickup Truck'
        },
        {
            value: TypeOfTruckTypeEnum.BoxTruck,
            label: 'Box Truck'
        },
        {
            value: TypeOfTruckTypeEnum.SemiTruck,
            label: 'Semi Truck'
        },
        {
            value: TypeOfTruckTypeEnum.RefrigeratorTruck,
            label: 'Refrigerator Truck'
        },
        {
            value: TypeOfTruckTypeEnum.FurnitureTruck,
            label: 'Furniture Truck'
        },
        {
            value: TypeOfTruckTypeEnum.FlatbedTruck,
            label: 'Flatbed Truck'
        },
    ]

    validationSchema = object().shape({
        name: string().nullable().required(),
        type: string().nullable().required(),
    });

    constructor(
        private ngbActiveModal: NgbActiveModal,
        private typeOfTrucksService: TypeOfTrucksService,
        private helperService: HelperService
    ) {
    }


    ngOnInit() {
        if (this.item?.typeOfTruckId) {
            this.loadEntity();
        } else {
            this.item = {};
            this.loading = false;
        }
    }

    loadEntity() {
        this.typeOfTrucksService.get(this.item?.typeOfTruckId).then((typeOfTruck) => {
            this.item = typeOfTruck;
            this.loading = false;
        }).catch(error => {
            this.helperService.errorMessage(error);
        });
    }

    async save(f) {
        if (f.isValid) {
            this.saveLoading = true;
            try {
                let resp: any;
                if (this.item?.typeOfTruckId) {
                    resp = await this.typeOfTrucksService.update(this.item.typeOfTruckId, this.item)
                } else {
                    resp = await this.typeOfTrucksService.create(this.item)
                }
                this.saveLoading = false;
                this.helperService.successMessage(`${this.title} successfully ${this.item?.typeOfTruckId ? 'updated' : 'created'}`);
                this.ngbActiveModal.close(resp)

            } catch (error) {
                this.helperService.errorMessage(error);
                this.saveLoading = false;
            }
        }
    }

    close(isReload?: boolean) {
        this.ngbActiveModal.close(isReload);
    }

}
