import { Component, Input } from '@angular/core';
import { formatDateTime } from '@app/helpers/util';
import { Invoice, InvoiceExportQbStatus, InvoiceSentEmailStatus } from '@app/models/invoice.model';
import { SentEmail } from '@app/models/sent-email.model';

@Component({
    standalone: false,
    selector: 'app-quick-books-email-icon',
    templateUrl: './quick-books-email-icon.component.html',
    styleUrls: ['./quick-books-email-icon.component.scss']
})
export class QuickBooksEmailIconComponent {
    @Input() invoice: Invoice;
    @Input() isPage: boolean; 

    getQBAndEmailColorOrText(status, statusType: 'qb' | 'email', returnType: 'color' | 'text', number) {
        if (((!number || number.startsWith('R')) && status === InvoiceSentEmailStatus.EditedAndNotSent) || 
            (statusType === 'qb' && status === InvoiceExportQbStatus.EditedAndNotExported)) {
            return returnType == 'color' ? 'text-danger' : 'Invoice has been edited and not sent';
        } else if (status === InvoiceExportQbStatus.EditedAndExported || status === InvoiceSentEmailStatus.EditedAndSent) {
            return returnType == 'color' ? 'text-primary' : 'Invoice has been edited and sent';
        } else {
            return returnType == 'color' ? (statusType === 'qb' ? 'text-success' : 'text-muted') : 'Invoice has been sent';
        }
    }

    getEmailSummary(sentEmails: SentEmail[]) {
        return sentEmails.map(se => `${se?.to} (${formatDateTime(se?.createdTime)})`).join(', ');
    }
}
