import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FilesViewerModalComponent } from '@app/@shared/files-viewer-modal/files-viewer-modal.component';
import { stateOptions } from '@app/data/state';
import { Driver } from '@app/models/driver.model';
import { UtilService } from '@app/services/util.service';
import { DriverService } from '@app/shared/services/driver.service';
import { cloneDeep } from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { object } from 'yup';


@Component({
  standalone: false,
  selector: 'app-driver-license',
  templateUrl: './driver-license.component.html',
  styleUrls: ['./driver-license.component.scss']
})
export class DriverLicenseComponent implements OnInit {
  title = 'Driver’s License';
  isEdit: boolean = false
  initialDriver: Driver;

  private _driver: Driver = {
    licenseNumber: '',
    licenseType: '',
    licenseCountry: '',
    licenseState: '',
    licenseExpires: new Date(),
  }

  validationSchema = object().shape({})

  @Input() isLoading: boolean = false;
  
  @Input()
  public get driver(): Driver {
    return this._driver;
  }
  public set driver(value: Driver) {
      this._driver = cloneDeep(value);
      this.initialDriver = cloneDeep(value);
  }

  @Output() driverChange = new EventEmitter()
  
  states = stateOptions;

  constructor(
    public driverService: DriverService,
    public util: UtilService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
  }

  discardChanges() {
    this.isEdit = false
    this.driver = this.initialDriver
  }

  editDriverLicense() {
    this.isEdit = true;
  } 

  onSubmit(f) {
    if(f.isValid && this.isEdit) {
        try {
          this.driverChange.emit(this.driver);
          this.isEdit = false;
        } catch (error) {
          this.isEdit = true;
        }
    }
  }

  async uploadLicenseFile(event) {
    const files = event.target.files;
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      const request = {
        file: file,
        type: 'driver',
        id: this.driver?.driverId,
        isFile: true,
      }
      this.driverService.uploadImage(request).then((resp: any) => {
        this.driver.licenseImage = resp?.data
      });
    }
  }

  async uploadInsuranceFile(event) {
    const files = event.target.files;
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      const request = {
        file: file,
        type: 'driver',
        id: this.driver?.driverId,
        isFile: true,
      }
      this.driverService.uploadImage(request).then((resp: any) => {
        this.driver.insurancenCardImage = resp?.data
      });
    }
  }

  openImageViewer(imageUrl: string, title: string) {
   const modalRef = this.modalService.open(FilesViewerModalComponent, {
      size: 'xl',
      centered: true,
    });
    modalRef.componentInstance.files = [{
      url: imageUrl,
      name: imageUrl?.split('/').pop()
    }]
    modalRef.componentInstance.title = title;
  }


}
