import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentViewerComponent } from '@app/@shared/document-viewer/document-viewer.component';
import { round } from '@app/helpers/util';
import { Attachment } from '@app/models/attachment.model';
import { CustomerContact } from '@app/models/customer-contact.model';
import { Customer } from '@app/models/customer.model';
import { DocumentViewMode } from '@app/models/document.model';
import { EntityTypes } from '@app/models/entity-type.model';
import { MarkupTypeEnum, Order, OrderStatusEnum, OrderTripStatusEnum } from '@app/models/order.model';
import { ShipmentType } from '@app/models/shipment-type.model';
import { DocumentService } from '@app/shared/services/document.service';
import { ShipmentTypeService } from '@app/shared/services/shipment-type.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject, of } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { CostLinesComponent } from '../../components/cost-lines/cost-lines.component';
import { CustomerContactService } from '@app/shared/services/customer-contact.service';
import { HelperService } from '@app/shared/services/helper.service';
import { AddressItem } from '@app/components/main/customers/customer-details/locations/locations.component';
import { CustomerAddressDialogComponent } from './customer-address-dialog/customer-address-dialog.component';
import { cloneDeep, chain, find, findIndex, flatten, get, includes, map, some, sumBy } from 'lodash';
import { DialogService } from '@app/@shared/dialogs/dialog.service';
import { saveAs } from "file-saver";
import { QuoteService } from '@app/shared/services/quote.service';
import { OrderService } from '@app/shared/services/order.service';
import { CustomerService } from '@app/shared/services/customer.service';
import { Location } from '@angular/common';
import { AddCustomerContactDialogComponent } from '../../components/add-customer-contact-dialog';
import { AuthService } from '@app/shared/services/auth/auth.service';
import { User } from '@app/models/user.model';
import { EmailType } from '@app/models/sent-email.model';
import { parsePhoneNumberAndExtension, formatPhoneNumberAndExtension, filterAndSortSentEmailsByEmailType } from '@app/@shared/utils';
import { CopyDetailsToDriverComponent } from '@app/@shared/copy-details-to-driver/copy-details-to-driver.component';
import { number, object, string } from 'yup';
import { patterns } from '@app/@shared/regex/regex-patterns.const';
import { FormValidateDirective } from '@app/@shared/form-validate/form-validate.directive';
import moment from "moment"
import { WarningStopsDepartureDialogComponent } from './warning-stops-departure-dialog/warning-stops-departure-dialog.component';
import { OrderDeletePaidInvoiceDialogComponent } from '../../components/order-delete-dialog/order-delete-paid-invoice-dialog/order-delete-paid-invoice-dialog.component';
import { OrderDeleteUnpaidInvoiceDialogComponent } from '../../components/order-delete-dialog/order-delete-unpaid-invoice-dialog/order-delete-unpaid-invoice-dialog.component';
import { ListTabsService } from '@app/@shared/list-tabs/list-tabs.service';
import { TnxStatus } from '@app/models/invoice.model';
import { InvoiceUpdateConfirmationDialogComponent } from '../../components/invoice-update-confirmation-dialog/invoice-update-confirmation-dialog.component';
import { OrderStopTypeEnum } from '@app/models/order-stop.model';
import { InvoiceCreationMode } from '@app/models/company.model';
import { InvoiceStatusMessages } from '@app/models/invoice.model';

@UntilDestroy()
@Component({
  	standalone: false,
	selector: 'app-order-info',
	templateUrl: './order-info.component.html',
	styleUrls: ['./order-info.component.scss']
})
export class OrderInfoComponent implements OnInit {

	private originalPrice: number;
	isEditNumber: boolean;
	isEditCustomer: boolean;
	isEditDetails: boolean;
	isQuoteApproved = false;
	otherAdditionalCharge = false;
	EntityTypes = EntityTypes;
	OrderStatusEnum = OrderStatusEnum;
	OrderTripStatusEnum = OrderTripStatusEnum;
	InvoiceCreationMode = InvoiceCreationMode;
	isQuote: boolean;

	private _order: Order;
	isEditContact: boolean;
	customsOrderNumber: string;
	userRoles: any[];
	public get order(): Order {
		return this._order;
	}
	public get invoiceCreationMode(): InvoiceCreationMode {
		return this.user?.company?.invoiceCreationMode;
	}
	@Input() public set order(value: Order) {
		this._order = value;

		if (value?.orderNumber) {
			this.customsOrderNumber = value?.orderNumber
		}

		if (value?.customerId && value?.customerId != this.selectedCustomer?.customerId) {
			this.processCustomer(this.order.customer);
		}

		if (value?.customerContactId) {
			this.processSelectedCustomerContact(this.order);
		} else {
			this.isEditContact = true
		}

		if (!this._order.orderId) {
			this.isEditCustomer = true
			this.isEditDetails = true
		}

		if (!this._order.paymentStatus) {
			this._order.paymentStatus = "Draft"
		}

		this.isQuoteApproved = this._order.orderStatus === OrderStatusEnum.ORDER
	}

	@Output() orderChange = new EventEmitter<Order>();
	@Output() onOrderPreviousNextRedirect = new EventEmitter<number>();
	@Output() reloadOrder = new EventEmitter();
	@Output() onSave = new EventEmitter();
	@Input() portOrder: boolean;
	@Input() orderAttachments: Attachment[] = [];
	@Output() uploadOrderAttachments = new EventEmitter();

	get isAdmin(): boolean {
		return this.userRoles.includes("COMPANY_ADMIN");
	}

	get showCopyToDriverBtn() {
		return some(this.order?.orderStops, stop =>
			some(stop?.routeItems, { isActive: true })
		);
	}

	public get showCloseAndCreateInvoiceBtn(): boolean {
		if (!this._order) {
			return false;
		}
	
		const isOrderStatusValid = this._order.orderStatus !== OrderStatusEnum.CLOSED;
	
		const isTripStatusValid =
			this._order.tripStatus === OrderTripStatusEnum.INTRANSIT ||
			(this._order.tripStatus === OrderTripStatusEnum.ASSIGNED &&
				!this._order.orderStops?.some(
					orderStop => orderStop?.activities?.some(a => a?.type == 'Unassigned')
				));
	
		return isOrderStatusValid && isTripStatusValid;
	}

	defaultAddress: AddressItem;
	shipmentTypes: ShipmentType[];
	selectedCustomer: Customer = {};
	selectedContact: CustomerContact = {};
	customerContacts: CustomerContact[];
	searchingCustomer: boolean;
	@ViewChild('orderNumberInput') orderNumberInput: ElementRef<HTMLElement>
	@ViewChild('isValid') contactValid: FormValidateDirective;
	@ViewChild('isValidPONumber') poNumberValid: FormValidateDirective;
	@ViewChild('isValidTrackingNumber') trackingNumberValid: FormValidateDirective;
	orderChange$ = new Subject();

	customerTerm: string;

	isDuplicateOrderNumber = false
	orderNumber$: Subject<any> = new Subject();

	user: User;

	selectedNoteTab: string = 'private';
	selectedDisplayNoteTab: string = 'private';

	validationSchema = object().shape({
		phoneNumber: string().matches(patterns.phone, { excludeEmptyString: true, message: 'Phone must be a number' }).nullable().max(20),
		extension: string().label('Ext').nullable().max(7),
		email: string().email().label('Email').nullable().max(256),
		purchaseOrderNumber: string().label('PO Number').nullable().max(256),
		trackingNumber: string().label('Tracking Number').nullable().max(256),
	});


	constructor(
		private shipmentTypeService: ShipmentTypeService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private documentService: DocumentService,
		private ngbModal: NgbModal,
		private customerContactService: CustomerContactService,
		private dialogService: DialogService,
		private helperService: HelperService,
		private quoteService: QuoteService,
		private orderService: OrderService,
		private customerService: CustomerService,
		private _location: Location,
		private listTabsService: ListTabsService,
		private authService: AuthService
	) { }

	ngOnInit(): void {
		this.getCustomerById();

		this.orderNumber$.pipe(
			debounceTime(300),
			switchMap((orderNumber: string) => {
				if ((this.order.orderNumber && this.order.orderNumber === orderNumber) || !orderNumber) {
					return of(false);
				}
				return this.quoteService.checkQuoteNo(orderNumber).then(({ data }) => {
					if (this.customsOrderNumber && !this.order?.orderId) {
						this.order.orderNumber = this.customsOrderNumber
						console.log(this.order.orderNumber);
						this.orderChange.emit(this.order);
					}
					return false;
				}).catch((error) => {
					this.helperService.errorMessage(error);
					return true;
				})
			}),
			untilDestroyed(this)
		).subscribe(response => {
			this.isDuplicateOrderNumber = response
		});

		this.orderChange$
			.pipe(
				untilDestroyed(this),
				debounceTime(500)
			)
			.subscribe(() => {
				this.orderChange.emit(this.order);
			})

		this.activatedRoute
			.data
			.pipe(untilDestroyed(this))
			.subscribe((data) => {
				this.isQuote = data?.type === 'quote'
			})

		this.authService.$user
			.pipe(untilDestroyed(this))
			.subscribe(user => {
				this.user = user;
				this.userRoles = user?.roles;
			});
	}

	orderNumberInputBlur() {
		if (!this.isDuplicateOrderNumber) {
			this.isEditNumber = false;
		} else {
			this.isEditNumber = true;
		}
	}

	async getCustomerById() {
		const customerId = this.activatedRoute.snapshot.queryParams?.customerId;
		if (customerId) {
			this.helperService.isLoading = true;
			try {
				const customer = await this.customerService.get(customerId);
				this.processCustomer(customer);
				this._location.replaceState(`app/${this.isQuote ? 'quotes' : 'orders'}/add`);
			} catch (error) {
				this.helperService.errorMessage(error);
			}
			this.helperService.isLoading = false;
		}
	}

	/**
	 * Process Customer Contact
	 * 
	 * @param order 
	 */
	processSelectedCustomerContact(order: Order) {
		if (order?.customerContact) {
			this.selectedContact = cloneDeep(parsePhoneNumberAndExtension(this.order?.customerContact));
		} else if (order?.customerContactId) {
			if (order?.customer?.customerContacts) {
				this.selectedContact = cloneDeep(parsePhoneNumberAndExtension(order?.customer?.customerContacts?.find(
					(contact: CustomerContact) => contact?.customerContactId === order?.customerContactId
				)))
			}
		}
		if (!this.selectedContact || !this.selectedContact?.firstName) {
			this.isEditContact = true;
		}
	}

	async onDeleteOrder() {

		const hasInvoice = await this.order?.hasInvoice;
		const invoicePaymentStatus = await this.order?.paymentStatus;
		const orderNumber = await this.order?.orderNumber;
		const invoiceNumber = await this.order?.invoice?.number ?? this.order?.invoice?.invoiceId;
		const type = await this.isQuote ? 'quote' : 'order';
		const invoiceId = await this.order?.invoice?.invoiceId;

		if (hasInvoice) {
			const isPaidInvoice = invoicePaymentStatus === "Paid" || invoicePaymentStatus === "Overdue" || invoicePaymentStatus === "PartialPaid";
			const dialogComponent = isPaidInvoice ? OrderDeletePaidInvoiceDialogComponent : OrderDeleteUnpaidInvoiceDialogComponent;

			const activeModal = this.ngbModal.open(dialogComponent);
			activeModal.componentInstance.message = `Are you sure you want to delete this ${type} #${orderNumber}?`;

			if (isPaidInvoice) {
				activeModal.componentInstance.invoiceNumber = invoiceNumber || "0000";
				activeModal.componentInstance.invoicePaymentStatus = InvoiceStatusMessages[invoicePaymentStatus];
			} else {
				activeModal.componentInstance.type = type;
			}

			activeModal.result.then(
				async (result) => {
					if (result) {
						this.deleteOrder(true, invoiceNumber, invoiceId)
					}
				},
				() => { }
			);
		} else {
			await this.dialogService.confirm({
				message: `Are you sure you want to delete this ${type} #${orderNumber}?`,
				noText: 'Cancel'
			}).then(() => {
				this.deleteOrder()
			}).catch((error) => {
				//
			})
		}
	}

	deleteOrder(isDeleteInvoice = false, invoiceNumber?: string | number, invoiceId?: string | number) {
		if (this.order?.orderId) {
			this.quoteService
				.delete(this.order?.orderId)
				.then(() => {
					if (isDeleteInvoice) {
						this.deleteInvoice(this.order?.orderId, invoiceNumber, invoiceId);
					}
					this.helperService.successMessage("Order Deleted Successfully");
					this.router.navigate([`/app/${this.isQuote ? 'quotes' : 'orders'}/`], { queryParams: { closeTabId: this.order?.orderId } })
				})
				.catch((error) => {
					this.helperService.errorMessage(error);
				});
		} else {
			this.router.navigate([`/app/${this.isQuote ? 'quotes' : 'orders'}/`]);
		}
	}

	deleteInvoice(orderId, invoiceNumber, invoiceId) {
		this.orderService.deleteInvoice(orderId).then(async () => {
			await this.listTabsService.handleInvoiceClosureAndNavigation(invoiceId);
			this.helperService.successMessage(`Invoice #${invoiceNumber} has been successfully deleted.`);
		}).catch((error) => {
			this.helperService.errorMessage(error);
		});
	}

	orderPreviousNextRedirect({ id }) {
		this.onOrderPreviousNextRedirect.emit(id);
	}

	closeOrder() {
		this.order.isRedirectInvoice = true;
		this.order.completeQuoteRouteItems = true;

		if (this.order.invoice) {
			let confirmationMessage = "";

			if (this.order.invoice.transactionPaymentStatus === TnxStatus.Paid) {
				confirmationMessage = `The invoice was already paid. Are you sure you want to update the invoice #${this.order.invoice?.number}?`;
			} else if (this.order.invoice.transactionPaymentStatus === TnxStatus.PartialPaid) {
				confirmationMessage = `The invoice was already partly paid. Are you sure you want to update the invoice #${this.order.invoice?.number}?`;
			} else {
				confirmationMessage = `Would you like to update the invoice #${this.order.invoice?.number}?`;
			}

			this.dialogService.confirm({
				message: confirmationMessage,
			})
				.then(() => {
					this.order.isUpdateInvoice = true;
					this.saveOrderContinues({ status: OrderStatusEnum.CLOSED, isUpdateInvoice: true, showConfirmationDialog: false});
				})
				.catch(() => {
					this.saveOrderContinues({ status: OrderStatusEnum.CLOSED, isUpdateInvoice: false, showConfirmationDialog: false});
				})
		} else {
			this.order.isUpdateInvoice = true;
			this.saveOrderContinues({ status: OrderStatusEnum.CLOSED, isUpdateInvoice: true, showConfirmationDialog: false});
		}
	}

	closeOrderBase(isReplace: boolean) {
		if(this.order?.orderStatus === OrderStatusEnum.DRAFT) return
		this.orderService.createInvoice(this.order.orderId, true, { isReplace }).then(async (res) => {
			await this.listTabsService.handleInvoiceClosureAndNavigation(this.order?.invoice?.invoiceId,res.invoiceId);
		}).catch((error) => {
			this.helperService.errorMessage(error);
		})
	}

	createInvoice(isCloseOrder = false) {
		if(this.order?.orderStatus === OrderStatusEnum.DRAFT) return
		if (this.order.invoice) {
			let confirmationMessage = "";

			if (this.order.invoice.transactionPaymentStatus === TnxStatus.Paid) {
				confirmationMessage = `The invoice was already paid. Are you sure you want to update the invoice #${this.order.invoice?.number}?`;
			} else if (this.order.invoice.transactionPaymentStatus === TnxStatus.PartialPaid) {
				confirmationMessage = `The invoice was already partly paid. Are you sure you want to update the invoice #${this.order.invoice?.number}?`;
			} else {
				confirmationMessage = `Would you like to update the invoice #${this.order.invoice?.number}?`;
			}
			this.dialogService.confirm({
				message: confirmationMessage,
			}).then(async () => {
				try {
					this.order.isRedirectInvoice = true;
					await this.saveAndCheckStatus(true, false);
				} catch (error) {
					this.helperService.errorMessage(error);
				}
			})
				.catch(() => {
					if (isCloseOrder) {
						this.orderService.closeOrder(this.order.orderId)
							.then(async (res) => {
								await this.listTabsService.handleInvoiceClosureAndNavigation(this.order?.invoice?.invoiceId,res.invoiceId);
							})
							.catch((error) => {
								this.helperService.errorMessage(error);
							})
					}
				})
		} else {
			this.orderService.createInvoice(this.order.orderId, isCloseOrder).then(async (res) => {
				await this.listTabsService.handleInvoiceClosureAndNavigation(this.order?.invoice?.invoiceId,res.invoiceId);
			}).catch((error) => {
				this.helperService.errorMessage(error);
			})
		}
	}

	async invoiceUpdateConfirmationDialog() {
		let reasonData: any = null;
        const activeModals = this.ngbModal.open(InvoiceUpdateConfirmationDialogComponent);
        activeModals.componentInstance.data = {
            message: `Order was changed. Would you like to update the Invoice?`,
            yesText: `Yes`,
            noText: `No`,
        };
        await activeModals.result.then(
            (reason) => {
                reasonData = reason;
            },
            (error) => {
                reasonData = null
            }
        );
        return reasonData
	}

	handleOrderChange(): void {
		this.orderChange$.next(true);
	}

	onOrderNumberChange() {
		this.orderNumber$.next(this.customsOrderNumber);
	}

	async showEmailQuote() {

		const activeModal = this.ngbModal.open(DocumentViewerComponent, {
			scrollable: true,
			size: "lg",
		});

		const content = await this.documentService.downloadQuote(this.order.orderId, DocumentViewMode.Html)

		if (!content?.changingThisBreaksApplicationSecurity) {
			activeModal.componentInstance.noResultsFound = true;
		}
		activeModal.componentInstance.content = content;
		activeModal.componentInstance.entity = this.order;
		activeModal.componentInstance.entityType = "order";

		let sentEmailsQuote = await this.orderService.getSentEmails(this.order?.orderId, { suggestCustomerPastSentEmails: true });
		sentEmailsQuote = await filterAndSortSentEmailsByEmailType(sentEmailsQuote, EmailType?.Quote);

		if (sentEmailsQuote?.length > 0) {
			activeModal.componentInstance.emails = sentEmailsQuote?.map(se => ({ email: se?.to, type: se?.emailType, label: se?.to }));
			activeModal.componentInstance.selectedEmail = sentEmailsQuote?.filter(x => x?.isSelected).map(rse => rse?.to);
			activeModal.componentInstance.showCustomEmails = true;
		}
		else {
			activeModal.componentInstance.showEmail = true;
		}

		activeModal.result.then(
			(result) => {
				if (result) {
					if (result.type == "sendEmail") {
						this.documentService
							.emailQuote(
								result?.entity?.orderId,
								result?.email
							)
							.then(() => {
								this.helperService.successMessage(
									"The quote has been sent"
								);
							})
							.catch((error) => {
								this.helperService.errorMessage(error);
							});
					} else {
						this.documentService
							.downloadQuote(
								result?.entity?.orderId,
								DocumentViewMode.Pdf
							)
							.then((file) => {
								saveAs(
									file,
									`quote-${result?.entity?.orderNumber}.pdf`
								);
							});
					}
				}
			},
			() => { }
		);
	}

	uploadAttachments(files: any[]) {
		this.uploadOrderAttachments.emit(files);
	}

	async updateCustomerContactData() {
		if (!this.selectedContact?.customerContactId) {
			throw new Error("Please select contact.");
		}
		if (!this.contactValid?.isValid && this.contactValid?.errors) {
			Object.keys(this.contactValid.ngForm.controls).forEach(key => {
				const control = this.contactValid.ngForm.controls[key];
				control.markAsTouched();
			});
			throw new Error("")
		}
		//
		const { customerContactId } = this.selectedContact;
		const contact = formatPhoneNumberAndExtension(this.selectedContact)
		const isEditCustomerContact = this.order?.customer?.customerContacts?.find((c) => c?.customerContactId == customerContactId && (c?.phone != contact?.phone || c?.email != contact?.email))
		if (isEditCustomerContact) {
			await this.customerContactService.update(customerContactId, contact);
			this.updateCustomerContactById(customerContactId);
		}
		// this.helperService.successMessage("Contact Details Successfully Updated.");
	}

	async saveAsDraft() {
		const hasInvoice = await this.order?.hasInvoice;
		const invoicePaymentStatus = await this.order?.paymentStatus;
		const orderId = await this.order?.orderId;
		const invoiceNumber = await this.order?.invoice?.number ?? this.order?.invoice?.invoiceId;
		const type = await this.isQuote ? 'quote' : 'order';
		const invoiceId = await this.order?.invoice?.invoiceId;

		if (hasInvoice) {
			const isPaidInvoice = invoicePaymentStatus === "Paid" || invoicePaymentStatus === "Overdue" || invoicePaymentStatus === "PartialPaid";
			const dialogComponent = isPaidInvoice ? OrderDeletePaidInvoiceDialogComponent : OrderDeleteUnpaidInvoiceDialogComponent;

			const activeModal = this.ngbModal.open(dialogComponent);
			activeModal.componentInstance.message = `Are you sure you want to save this ${type} as a draft?`;

			if (isPaidInvoice) {
				activeModal.componentInstance.invoiceNumber = invoiceNumber || "0000";
				activeModal.componentInstance.invoicePaymentStatus = InvoiceStatusMessages[invoicePaymentStatus];
			} else {
				activeModal.componentInstance.type = type;
			}

			activeModal.result.then(
				async (result) => {
					if (result) {
						await this.saveOrder(OrderStatusEnum.DRAFT, true)
						this.deleteInvoice(orderId, invoiceNumber, invoiceId)
					}
				},
				() => { }
			);
		} else {
			this.saveOrder(OrderStatusEnum.DRAFT, true)
		}
	}

	isUpdateInvoiceOnSave() {
		return this.order?.orderStatus !== OrderStatusEnum.QUOTE && !this.isQuote &&
			(this.invoiceCreationMode == InvoiceCreationMode.CreatedAndCompleted ||
				( this.order?.orderStatus !== OrderStatusEnum.DRAFT && this.invoiceCreationMode != InvoiceCreationMode.Manual &&
					(this.invoiceCreationMode == InvoiceCreationMode.Completed && this.order?.tripStatus === OrderTripStatusEnum.DELIVERED)
				)
			);
	}

	async saveAndCheckStatus(isUpdateInvoice = true, showConfirmationDialog = true, ) {
		this.order.isUpdateInvoice = isUpdateInvoice;	
		if (!this.isQuote && this.order.orderStatus === OrderStatusEnum.DRAFT) {
			this.saveOrderContinues({ status: OrderStatusEnum.ORDER, isUpdateInvoice, isCheckedDisruption: true, showConfirmationDialog});
			this.orderService.orderDatatableRefresh = true;
		} else {
			this.saveOrderContinues({ isUpdateInvoice, isCheckedDisruption: true, showConfirmationDialog});
		}
	}

	async saveOrderContinues({ status, close, isRedirectOrder, isApproveQuote, isUpdateInvoice, isCheckedDisruption,
		showConfirmationDialog = true }: { status?: OrderStatusEnum, close?: boolean, isRedirectOrder?: boolean,
		isApproveQuote?: boolean, isUpdateInvoice?: boolean, isCheckedDisruption?:boolean, showConfirmationDialog?: boolean 
	}) {
		if (isUpdateInvoice && (this.order?.invoice || this.order?.hasInvoice)) {
			let isUpdateInvoice = true;
			if(showConfirmationDialog){
				isUpdateInvoice = await this.invoiceUpdateConfirmationDialog();
			}
			if (isUpdateInvoice === true) {
				this.order.isUpdateInvoice = true			
			} else if (isUpdateInvoice === false) {
				this.order.isUpdateInvoice = false
			} else if (isUpdateInvoice === null) return			
		}
		
		try {
			if (this.isEditContact) {
				await this.updateCustomerContactData()
			}
		} catch (error) {
			if (error?.message) {
				this.helperService.errorMessage(error)
			}
			return
		}

		if (!this.poNumberValid?.isValid && this.poNumberValid?.errors) {
			return
		}

		if (!this.trackingNumberValid?.isValid && this.trackingNumberValid?.errors) {
			return
		}

		const remaining = await sumBy(this.order.orderItems, (element) => {
			const ois = element.orderItemStops != null ? element.orderItemStops.filter(oisInner => oisInner.isActive
				&& oisInner.orderStop.orderStopType === 'Delivery') : [];

			let qtyPicked = 0;
			ois.forEach(oiss => {
				qtyPicked += parseInt(oiss.quantity, 10);
			});

			if (element.totalQuantity > qtyPicked) {
				return 1;
			}
			return 0;
		})

		if (remaining > 0) {
			this.dialogService.confirm({
				title: 'Confirm',
				message: `There ${remaining === 1 ? 'is' : 'are'} ${remaining} item${remaining === 1 ? '' : 's'} remaining unallocated. Are you sure you want to continue?`,
			}).then(() => {
				this.saveOrder(status, close, isRedirectOrder, isApproveQuote, isCheckedDisruption);
			}).catch(() => {
				// Nothing
			})
		} else {
			this.saveOrder(status, close, isRedirectOrder, isApproveQuote, isCheckedDisruption);
		}
	}

	async saveOrder(status?: OrderStatusEnum, close?: boolean, isRedirectOrder?: boolean, isApproveQuote?: boolean, isCheckedDisruption?:boolean): Promise<void> {
  	var errorMessage = null;
	
    if (!this.order.date) {
			if (this.order.orderStatus == OrderStatusEnum.DRAFT || this.order.orderStatus == OrderStatusEnum.QUOTE) {
				errorMessage = 'Quote date is a required field';
			}
			else {
				errorMessage = 'Order date is a required field';
			}

			this.helperService.errorMessage(errorMessage);
			return;
		}

		if (this.customsOrderNumber && this.customsOrderNumber !== this.order?.orderNumber) {
			this.order.orderNumber = this.customsOrderNumber
		}

		if (status) {
			this.order.orderStatus = status;
		}

		if (isApproveQuote) {
			this.order.isApproveQuote = true;
			this.order.isApprovedQuoteRedirectToOrder = true;
		}
		
		if(close){
			this.order.isCloseTab = true;
		}

		if (isCheckedDisruption && this.order?.directions?.routes[0]?.legs?.length > 0) {
			const messages = await this.checkDisruptions(this.order);			
			if (messages?.length > 0) {
				const warningStopsDeparture = await this.warningStopsDepartureDialog(messages);
				if (warningStopsDeparture === null) return
			}
		}

		this.onSave.emit(this.order);
		this.isEditCustomer = false
		this.isEditDetails = false

	}

	async getOrderStopTitle(orderStop, type, index) {
		// Check if any routeItem has a non-null transferStopId
		const hasTransfer = orderStop.routeItems?.some(item => item?.transferStopId !== null);
	
		const indexNumber = Math.max(index, 1);
		const stopType = type === OrderStopTypeEnum.PICKUP ? 'Pickup' : 'Delivery';
	
		if (hasTransfer) {
			return type === OrderStopTypeEnum.PICKUP
				? `<strong class="text-primary">Pickup from Transfer - Delivery #${indexNumber}</strong>`
				: `<strong class="text-danger">Delivery for Transfer - Pickup #${indexNumber}</strong>`;
		}
	
		return `<strong class="${stopType === 'Pickup' ? 'text-primary' : 'text-danger'}">${stopType} #${index + 1}</strong>`;
	}
	
	async getOrderStopsByType(order, type) {
		return Promise.all(
			chain(order.orderStops)
				.filter({ orderStopType: type, isActive: true })
				.sortBy(['sortOrder', 'orderStopId'])
				.map(async (orderStop, index) => {
					orderStop.title = await this.getOrderStopTitle(orderStop, type, index);
					return {
						orderStopId: orderStop.orderStopId,
						title: orderStop.title
					};
				})
				.value()
		);
	}
	
	async checkDisruptions(order) {
		const pickups = await this.getOrderStopsByType(order, OrderStopTypeEnum.PICKUP);
		const deliveries = await this.getOrderStopsByType(order, OrderStopTypeEnum.DELIVERY);
		const orderStops = [...pickups, ...deliveries];		
	
		const directions = order?.directions?.routes[0]?.legs?.flatMap((direction) => {
			const startDate = moment(direction?.startLocationDateTime);
			const endDate = moment(direction?.endLocationDateTime);
			const daysDifference = moment.duration(endDate.diff(startDate)).asDays();
			const totalDistance = (direction?.distance?.value || 0) / 1609;
	
			const startStopName = get(find(orderStops, { orderStopId: direction?.startOrderStopId }), 'title') || 'first stop';
			const endStopName = get(find(orderStops, { orderStopId: direction?.endOrderStopId }), 'title') || 'latest stop';
	
			if (daysDifference > 2) {
				return `The interval between ${startStopName} and ${endStopName} exceeds <strong>${daysDifference.toFixed(0)} days</strong>.`;
			}
	
			if (totalDistance > 2000) {
				return `The distance from ${startStopName} to ${endStopName} exceeds <strong>${totalDistance.toFixed(2)} miles</strong>.`;
			}
	
			return null;
		});
	
		return directions.filter(Boolean);
	}
	

	async warningStopsDepartureDialog(messages) {
		let reasonData: any = null;
		const activeModals = this.ngbModal.open(WarningStopsDepartureDialogComponent);
		activeModals.componentInstance.messages = messages
		await activeModals.result.then(
			(reason) => {
				reasonData = reason;
			},
			(error) => {
				reasonData = null
			}
		);
		return reasonData
	}

	async showDispatchSheet() {

		const activeModal = this.ngbModal.open(DocumentViewerComponent, {
			scrollable: true,
			size: "lg",
		});

		const content = await this.documentService.downloadDispatchSheet(this.order?.orderId, DocumentViewMode.Html)

		if (!content?.changingThisBreaksApplicationSecurity) {
			activeModal.componentInstance.noResultsFound = true;
		}
		activeModal.componentInstance.showEmail = false;

		activeModal.componentInstance.content = content;
		activeModal.componentInstance.entity = this.order;
		activeModal.componentInstance.entityType = "order";
		activeModal.result.then(
			(result) => {
				if (result) {
					this.documentService
						.downloadDispatchSheet(
							result?.entity?.orderId,
							DocumentViewMode.Pdf
						)
						.then((file) => {
							saveAs(
								file,
								`order-sheet-${result?.entity?.orderNumber}.pdf`
							);
						});
				}
			},
			() => { }
		);
	}

	copyToDriver() {
		const activeModal = this.ngbModal.open(CopyDetailsToDriverComponent, {
			scrollable: true,
			size: "dialog-centered",
		});
		this.quoteService
			.copyOrderToDriver(this.order.orderId)
			.then((order) => {
				activeModal.componentInstance.title = 'Order details copied to clipboard';
				activeModal.componentInstance.order = order;
				activeModal.componentInstance.route = order?.routes[0] || null;
			});
	}

	async showBillOfLanding() {
		const order: Order = Object.assign({}, this.order);
		order.customer = {
			customerId: order.customerId
		}

		const activeModal = this.ngbModal.open(DocumentViewerComponent, {
			scrollable: true,
			size: "lg",
		});

		const content = await this.documentService.downloadBillOfLanding(order?.orderId, DocumentViewMode.Html)

		if (!content?.changingThisBreaksApplicationSecurity) {
			activeModal.componentInstance.noResultsFound = true;
		}

		let sentEmailsBillOfLanding = await this.orderService.getSentEmails(order?.orderId, { suggestCustomerPastSentEmails: true });
		sentEmailsBillOfLanding = await filterAndSortSentEmailsByEmailType(sentEmailsBillOfLanding, EmailType?.BillOfLanding);

		activeModal.componentInstance.content = content;
		activeModal.componentInstance.entity = this.order;
		activeModal.componentInstance.entityType = "order";
		activeModal.componentInstance.showSplitOrderStopItems = true;
		activeModal.componentInstance.showHighlightItems = true;
		activeModal.componentInstance.showHandleLabelItems = true;

		if (sentEmailsBillOfLanding.length > 0) {
			activeModal.componentInstance.emails = sentEmailsBillOfLanding.map(se => ({ email: se?.to, type: se?.emailType, label: se?.to }));
			activeModal.componentInstance.selectedEmail = sentEmailsBillOfLanding.filter(x => x?.isSelected).map(rse => rse?.to);
			activeModal.componentInstance.showCustomEmails = true;
		}
		else {
			activeModal.componentInstance.showEmail = true;
		}

		activeModal.componentInstance.showPrint = true;		
		activeModal.result.then(
			(result) => {
				if (result) {
					if (result.type == "sendEmail") {
						this.documentService
							.emailBillOfLanding(
								result?.entity?.orderId,
								{
									email: result?.email,
									splitOrderStopItems: !!result?.splitOrderStopItems,
									highlightItems: !!result?.highlightItems
								}
							)
							.then(() => {
								this.helperService.successMessage(
									"The bol has been sent"
								);
							})
							.catch((error) => {
								this.helperService.errorMessage(error);
							});
					} else {
						this.documentService
							.downloadBillOfLanding(
								result?.entity?.orderId,
								DocumentViewMode.Pdf,
								{
									splitOrderStopItems: !!result?.splitOrderStopItems,
									highlightItems: !!result?.highlightItems
								}
							)
							.then(async (file) => {
								await saveAs(
									file,
									`bol-${result?.entity?.orderNumber}.pdf`
								);
							});
						if (result?.isLabel) {
							this.documentService
								.downloadLabels(
									result?.entity?.orderId,
									DocumentViewMode.Pdf
								)
								.then(async (file) => {
									await saveAs(
										file,
										`labels-${result?.entity?.orderNumber}.pdf`
									);
								});
						}
					}
				}
			},
			() => { }
		);
	}

	editOrderNumber(): void {
		this.isEditNumber = true;
		setTimeout(() => {
			this.orderNumberInput.nativeElement.focus()
		}, 100);
	}

	shipmentTypeComparer(a: ShipmentType, b: ShipmentType) {
		return (a == null && b == null) || a?.shipmentTypeId === b?.shipmentTypeId;
	}

	customerResultFormatter(customer) {
		return customer.customerName;
	}

	async onContactSelect(contact: CustomerContact) {
		this.loadContactData(contact)
		this.orderChange$.next(true);
	}

	async onCustomerSelect(customer: Customer) {
		this.processCustomer(customer)
		this.orderChange$.next(true);
	}

	async loadContactData(contact: CustomerContact) {
		if (contact?.customerContactId) {
			this.selectedContact = cloneDeep(parsePhoneNumberAndExtension(contact));
			this.updateCustomerContactById(contact?.customerContactId);
		} else {
			this.selectedContact = {
				phone: null,
				phoneNumber: null,
				phoneExtension: null,
				email: null
			};
		}
	}

	/**
	 * Compares two CustomerContact objects based on their customerContactId property.
	 * 
	 * @param contact1 The first CustomerContact object.
	 * @param contact2 The second CustomerContact object.
	 * @returns A boolean indicating whether the objects are equal based on their customerContactId.
	 */
	compareCustomerContacts(contact1: CustomerContact, contact2: CustomerContact): boolean {
		// Check if both contact1 and contact2 are truthy, and compare their customerContactId properties.
		return contact1 && contact2 && contact1.customerContactId === contact2.customerContactId;
	}

	/**
	 * Update the specific customer contact within the order.
	 * @param customerContactId - The ID of the customer contact to update.
	 */
	updateCustomerContactById(customerContactId: number): void {
		if (customerContactId) {
			// Set the selected customer contact ID in the order
			this.order.customerContactId = customerContactId;

			// Update the customerContacts array if it exists
			if (this.order?.customer?.customerContacts) {
				this.order.customer.customerContacts = cloneDeep(this.order.customer.customerContacts.map(
					(contact: CustomerContact) => {
						if (contact.customerContactId === customerContactId) {
							return parsePhoneNumberAndExtension(this.selectedContact); // Replace the contact with the selectedContact
						}
						return parsePhoneNumberAndExtension(contact);
					}
				));
			}

			// Update the customerContact property if it exists
			if (this.order?.customerContact) {
				this.order.customerContact = cloneDeep(parsePhoneNumberAndExtension(this.selectedContact)); // Update the customerContact
			}
		} else {
			// Set null to the selected customer contact ID in the order
			this.order.customerContactId = null;

			// Update null to the customerContact property if it exists
			if (this.order?.customerContact) {
				this.order.customerContact = null; // Update the customerContact
			}
		}
	}

	async processCustomer(customer: Customer) {
		if (customer) {
			// Process primary customer cotact
			this.processPrimaryCustomerContact(customer);

			this.selectedCustomer = customer;
			this.order.customer = customer;
			this.order.customerId = customer?.customerId;
			this.customerContacts = customer?.customerContacts.filter((contact) => contact.firstName);
			this.defaultAddress = customer?.mainAddress
			if (this.defaultAddress?.addressId) {
				this.order.billToInfo = `${this.defaultAddress?.addressLine1} ${this.defaultAddress?.addressLine2 || ''},\n ${this.defaultAddress?.city}, ${this.defaultAddress?.state}, ${this.defaultAddress?.zip}`;
			} else if (!this.defaultAddress) {
				this.order.billToInfo = '';
			}
			this.getShipmentTypes();
		} else {
			this.selectedCustomer = {};
			this.selectedContact = {
				phone: null,
				phoneNumber: null,
				phoneExtension: null,
				email: null
			};
			this.order.customer = null
			this.order.customerId = null
			this.order.billToInfo = ''
			this.order.shipmentType = null
			this.customerContacts = []
			this.defaultAddress = {}
		}
	}

	/**
	 * Process the primary customer contact action based on conditions.
	 * @param customer - The customer object for which to process the action.
	 */
	processPrimaryCustomerContact(customer: Customer): void {
		let isPrimaryAction: boolean = false;
		if (customer) {
			if (customer.customerId !== this.order?.customerId) {
				isPrimaryAction = true;
			} else {
				if (!this.order.customerContactId) {
					isPrimaryAction = true;
				}
			}
		}
		if (isPrimaryAction) {
			const primaryContact = customer.customerContacts.find(
				(contact: CustomerContact) => !!contact.isPrimary
			);
			if (primaryContact) {
				this.onContactSelect(primaryContact);
			}
		}
	}

	updateAddress() {
		const activeModal = this.ngbModal.open(CustomerAddressDialogComponent, {
			size: 'dialog-centered',
		})
		activeModal.componentInstance.address = this.defaultAddress
		activeModal.result.then((result) => {
			if (result) {
				this.defaultAddress = result
				this.order.customer.mainAddress = result
				this.order.billToInfo = `${result?.addressLine1} ${result?.addressLine2 || ''},\n ${result?.city}, ${result?.state}, ${result?.zip}`;
				this.isEditCustomer = false
			}
		});
	}

	getShipmentTypes() {
		if (this.order?.customer?.customerId != null) {
			this.shipmentTypeService.getShipmentTypeList(this.order?.customer?.customerId).then((rep) => {
				this.shipmentTypes = rep || []
				if (!this.order?.shipmentType) {
					this.order.shipmentType = this.shipmentTypes.find(st => st.customerId === this.order?.customer?.customerId) ?? this.shipmentTypes[0];
				} else {
					this.order.shipmentType = this.shipmentTypes.find(st => st.shipmentTypeId === this.order.shipmentType?.shipmentTypeId)
				}
			})
		}
	}

	onPriceManualChange() {
		if (this.originalPrice !== this.order.price) {
			this.order.markupType = MarkupTypeEnum.VALUE;
			this.order.markupValue = 0;
			this.order.discountValue = 0;
			this.order.markupValue = round(this.order.price - this.getTotalPrice(), 2);
			this.originalPrice = this.order.price = round(this.getTotalPrice(), 2);
			// this.saveOrder();
		}
	}

	getTripStatusColor(color) {
		switch (color) {
			case 'Open':
				return 'danger';
			case 'Assigned':
				return 'primary';
			case 'InTransit':
				return 'success';
			case 'Delivered':
				return 'gray-400';
			default:
				return 'warning';
		}
	}

	getTripStatusName(name) {
		switch (name) {
			case 'Open':
				return 'Unassigned';
			case 'Assigned':
				return 'Dispatched';
			case 'InTransit':
				return 'On Route';
			case 'Delivered':
				return 'Complete';
			default:
				return 'Undefined';
		}
	}

	getTotalPrice() {
		return Number(this.getTotalCost()) + Number(this.getMarkupAmount()) - Number(this.getDiscountAmount());
	}

	getMarkupAmount() {
		return this.order.markupType === 'Value' ? Number(this.order.markupValue) : (this.getTotalCost() * (this.order.markupValue / 100));
	}

	getDiscountAmount() {
		return this.order.discountType === 'Value'
			? Number(this.order.discountValue)
			: ((this.getTotalCost() + this.getMarkupAmount()) * (this.order.discountValue / 100));
	}

	getTotalCost() {
		return this.order.costLines.map(item => item.totalCost).reduce((prev, next) => prev + next);
	}

	seeDetails() {
		const activeModal = this.ngbModal.open(CostLinesComponent, {
			scrollable: true,
			size: "lg",
		});
		activeModal.componentInstance.quote = this.order;

		activeModal.result.then(
			(result) => {
				if (result) {

					// console.log(unionWith(this.order.orderStops, result, isEqual));
					// this.order.orderStops = [...this.order.orderStops, result];
					// console.log(this.order.orderStops, 'before stopItem add-edit-order');
				}
			},
			() => { }
		);
	}

	/**
	 * On change customer dropdown term
	 */
	onChangedTerm(term: string) {
		this.customerTerm = term || '';
	}

	/**
	 * Create new customer from order info page
	 *
	 * @param name
	 * @returns
	 */
	createNewCustomer = async () => {
		try {
			const customerName = this.customerTerm || '';

			const chunks = customerName.split(/\s+/);
			const [firstName = null, lastName = null] = [chunks.shift(), chunks.join(' ')];

			this.router.navigate(['/app/customers/add'], {
				queryParams: {
					returnUrl: this.router.url
				},
				state: {
					firstName,
					lastName,
					customerName
				}
			});
		} catch (error) {
			this.helperService.errorMessage(error);
		}
	};

	/**
	 * Create new customer contact
	 * 
	 * @param $event 
	 */
	async createNewCustomerContact() {
		const modalRef = this.ngbModal.open(AddCustomerContactDialogComponent, {
			scrollable: true,
			size: "520px"
		});
		modalRef.componentInstance.customer = this.selectedCustomer;

		// Do something when the modal is closed
		modalRef.result.then(
			(contact: CustomerContact) => {
				if (contact && contact.customerContactId) {
					this.customerContacts.push(contact);
					this.selectedCustomer?.customerContacts?.push(contact);
					this.onContactSelect(contact);
				}
			},
			(reason) => {
				//
			}
		);
	}

	handleUndoStops() {
		this.helperService.isLoading = true
		this.quoteService.undoOrderAllStops(this.order.orderId).then(() => {
			this.reloadOrder.emit()
		}).catch((error) => {
			this.helperService.errorMessage(error)
		}).finally(() => {
			this.helperService.isLoading = false
		})
	}
}
