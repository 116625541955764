import { Component, forwardRef, Output, EventEmitter, ChangeDetectionStrategy, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-waiting-time-input',
  templateUrl: './waiting-time-input.component.html',
  styleUrls: ['./waiting-time-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WaitingTimeInputComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WaitingTimeInputComponent implements ControlValueAccessor {

  @Input() label: string = 'Waiting Time';
  @Input() placeholder: string = 'e.g., 1d 3h 15m';
  @Output() timeChanged = new EventEmitter<string>();

  hours: number = 0;
  minutes: number = 0;
  isEdit: boolean = false;

  private onChange: any = () => {};
  private onTouched: any = () => {};

  get value(): string {
    if (this.hours > 0 || this.minutes > 0) {
      const days = Math.floor(this.hours / 24);
      const remainingHours = this.hours % 24;

      const dayPart = days > 0 ? `${days}d ` : '';
      const hourPart = remainingHours > 0 ? `${remainingHours}h ` : '';
      const minutePart = this.minutes > 0 ? `${this.minutes}m` : '';

      return `${dayPart}${hourPart}${minutePart}`.trim();
    }
    return '';
  }

  set value(val: string) {
    const parsed = this.parseTime(val);
    this.hours = parsed.hours;
    this.minutes = parsed.minutes;
    this.normalizeTime();
    this.emitValue();
  }

  writeValue(value: string): void {
    if (value) {
      const parsed = this.parseTime(value);
      this.hours = parsed.hours;
      this.minutes = parsed.minutes;
      this.normalizeTime();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  updateTime(): void {
    this.normalizeTime();
    this.emitValue();
  }

  private parseTime(value: string): { hours: number; minutes: number } {
    const regex = /^(\d+)\.(\d+)$/;
    const matches = value?.match(regex);
    return {
      hours: matches ? parseInt(matches[1], 10) : 0,
      minutes: matches ? parseInt(matches[2], 10) : 0
    };
  }

  private normalizeTime(): void {
    if (this.minutes >= 60) {
      this.hours += Math.floor(this.minutes / 60);
      this.minutes %= 60;
    }
  }

  private emitValue(): void {
    const formattedValue = `${this.hours}.${this.minutes}`;
    this.onChange(formattedValue);
    this.onTouched();
    this.timeChanged.emit(formattedValue);
  }
}
