import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbCalendar, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from "moment"

export interface CalendarValue {
  repeatOptions: string[];
  selectedDates: string[];
  excludeDates: string[];
}

@Component({
  standalone: false,
  selector: 'app-calender-dialog',
  templateUrl: './calender-dialog.component.html',
  styleUrls: ['./calender-dialog.component.scss'],
})
export class CalenderDialogComponent implements OnInit {

  private _min: moment.Moment;
  @Input()
  public get min(): moment.Moment {
    return this._min;
  }
  public set min(value: Date | string | moment.Moment) {
    this._min = value ? moment(value) : null;
  }

  private _max: moment.Moment;
  @Input()
  public get max(): moment.Moment {
    return this._max;
  }
  public set max(value: Date | string | moment.Moment) {
    this._max = value ? moment(value) : null;
  }

  model: NgbDateStruct;
  date: { year: number; month: number; day: number };

  excludes = new SelectionModel<string>(true, []);
  selection = new SelectionModel<string>(true, []);
  selectionOption = new SelectionModel<string>(true, []);

  @Input() repeatOptions = [
    { name: 'Every Monday', value: 1 },
    { name: 'Every Tuesday', value: 2 },
    { name: 'Every Wednesday', value: 3 },
    { name: 'Every Thursday', value: 4 },
    { name: 'Every Friday', value: 5 },
    { name: 'Every Saturday', value: 6 },
    { name: 'Every Sunday', value: 7 },
    { name: 'Every Month', value: 'monthly' },
    { name: 'Every Other Week', value: 'otherWeek' },
  ]

  @Input()
  public set value(value: CalendarValue) {
    // value.repeatOptions.forEach((val) => this.selectionOption.select(val))
    // value.selectedDates.forEach((val) => this.selection.select(val))
    // value.excludeDates.forEach((val) => this.excludes.select(val))
    this.selectionOption.select(...value.repeatOptions)
    this.selection.select(...value.selectedDates)
    this.excludes.select(...value.excludeDates)
  }

  @Output() valueChange = new EventEmitter<CalendarValue>();

  constructor(
    public modal: NgbActiveModal,
    private calendar: NgbCalendar
  ) { }

  ngOnInit(): void {
  }

  handleValueChange() {
    let values = {
      repeatOptions: Array.from(this.selectionOption.selected.values()),
      selectedDates: Array.from(this.selection.selected.values()),
      excludeDates: Array.from(this.excludes.selected.values()),
    }
    this.valueChange.emit(values);
  }

  onChangeSelectionOption(value) {

    const selectionDeselect = this.selection.selected.filter((date) => {
      return value && this.calendar.getWeekday(this.toDate(date)) == value
    })

    const excludesDeselect = this.excludes.selected.filter((date) => {
      return value && this.calendar.getWeekday(this.toDate(date)) == value
    })

    this.selection.deselect(...selectionDeselect)
    this.excludes.deselect(...excludesDeselect)

    this.selectionOption.toggle(value)

    this.handleValueChange();
  }

  onDateSelection(date: NgbDate) {

    if (this.min && this.min.isAfter(this.parseDate(date))) {
      return false
    }
    if (this.max && this.max.isBefore(this.parseDate(date))) {
      return false
    }

    const dateStr = this.parseDate(date, true)

    const weekDay = this.calendar.getWeekday(date) as any
    if (this.selectionOption.isSelected(weekDay)) {
      if (this.isDateSelected(date)) {
        this.excludes.select(dateStr)
      } else {
        this.excludes.deselect(dateStr)
      }
    } else {
      this.selection.toggle(dateStr)
    }

    this.handleValueChange();
  }

  isDateSelected(date: NgbDate) {
    if (this.excludes.isSelected(this.parseDate(date, true))) {
      return false
    }
    if (this.min && this.min.isAfter(this.parseDate(date))) {
      return false
    }
    if (this.max && this.max.isBefore(this.parseDate(date))) {
      return false
    }
    for (let index = 0; index < this.selectionOption.selected.length; index++) {
      const element: any = this.selectionOption.selected[index];
      if (element && this.calendar.getWeekday(date) == element) {
        return true
      }
    }
    if (this.selection.isSelected(this.parseDate(date, true))) {
      return true
    }
    return false
  }

  parseDate(date: NgbDate, format?: false): moment.Moment
  parseDate(date: NgbDate, format?: true): string
  parseDate(date: NgbDate, format?: boolean) {
    const mDate = moment().set('y', date.year).set('M', date.month - 1).set('D', date.day);
    if (format) {
      return mDate.format('YYYY-MM-DD')
    }
    return mDate
  }

  toDate(date: string) {
    const mDate = moment(date)
    return new NgbDate(mDate.get('y'), mDate.get('M') + 1, mDate.get('D'));
  }

  filterExcludes(option) {
    const deselect = this.selection.selected.filter((item) => {
      const date = this.toDate(item);
      if (this.calendar.getWeekday(date) == option) {
        return false
      }
      return true
    })

    this.excludes.deselect(...deselect)
  }

}
