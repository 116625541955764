import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepicker, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from "moment"

export type IDate = Date | string | NgbDate | null;

@Component({
    standalone: false,
    selector: 'app-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatepickerComponent),
            multi: true
        }
    ],
    encapsulation: ViewEncapsulation.Emulated
})

export class DatepickerComponent implements OnInit {

    @Input() isCustomOptionBtn: boolean = false;
    @Input() isHiddenCalendar: boolean = false;
    @Input() showOptions: boolean = false;
    @Input() disabled: boolean;
    @Input() minDate = { year: 1960, month: 1, day: 1 };
    @Input() maxDate = null;

    @Input() options: any[];

    @Input() navigation: string = 'select';

    @ViewChild(NgbDatepicker, { static: true }) ngbDatepicker: NgbDatepicker;

    @Output() onDateChanged: EventEmitter<IDate> = new EventEmitter<IDate>();

    hoveredDate: NgbDate | null = null;
    date: NgbDate | null = null;
    selection = new SelectionModel<string>(false);

    private _value: NgbDateStruct = null;
    get value(): NgbDateStruct {
        return this._value;
    }
    set value(values: NgbDateStruct) {
        this._value = values;
        if (values) {
            const date = moment().set('year', values.year).set('month', values.month - 1).set('date', values.day).toDate();
            this.onChange(date);
        } else {
            this.onChange(null);
        }
        this.onTouched();
    }

    onChange = (value: Date) => { };
    onTouched = () => { };

    constructor(
        public readonly formatter: NgbDateParserFormatter,
        private calendar: NgbCalendar,
    ) {
    }

    ngOnInit() { }

    writeValue(value: IDate) {
        if (value) {
            const date = moment(value).toDate();
            this.date = new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
            this.updateCalender(this.parseDate(this.date).startOf('day'));
        } else {
            this.date = null;
            this.selection.clear();
        }
    }

    registerOnChange(fn: (value: Date) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    dateFormat(date: NgbDateStruct | null): string {
        if (date) {
            const formattedMonth = date.month.toString().padStart(2, '0');
            const formattedDate = date.day.toString().padStart(2, '0');
            return `${formattedMonth}/${formattedDate}/${date.year}`;
        }
        return '';
    }

    onDateSelection(date: NgbDate) {
        if (date) {
            const start = this.parseDate(date).startOf('day');
            this.updateCalender(start);
            this.date = date;
            this.value = date;
            this.onDateChanged.emit(date);
        } else {
            this.date = null;
        }
    }

    onDatesUpdated() {
        this.value = this.date;
        this.onDateChanged.emit(this.date);
    }

    isDateSelected(date: NgbDate) {
        return this.date && date.equals(this.date);
    }

    selectOption(option: any) {
        if (option) {
            const { value, title } = option;
            if (!this.selection.isSelected(title)) {
                this.selection.toggle(title);
                this.writeValue(
                    value ? value.toDate() : null,
                );
                this.onDatesUpdated();
                this.updateCalenderBasedOnSelectedDate();
            }
        }
    }

    updateCalenderBasedOnSelectedDate() {
        if (this.date) {
            const { year, month } = this.date;
            this.ngbDatepicker.navigateTo({ year: year, month: month });
        } else {
            this.ngbDatepicker.navigateTo({ year: new Date().getFullYear(), month: new Date().getMonth() + 1 });
        }
    }

    updateCalender(date: moment.Moment) {
        this.options?.forEach((range) => {
            const { title, value } = range;
            if (date.isSame(moment(value).startOf('day'))) {
                if (!this.selection.isSelected(title)) {
                    this.selection.toggle(title);
                }
            } else {
                this.selection.deselect(title);
            }
        });
    }

    clearDateSelection() {
        this.writeValue(null);
        this.onDatesUpdated();
        this.selection.clear();
    }

    parseDate(date: NgbDate): moment.Moment {
        if (date) {
            const mDate = moment().set('y', date.year).set('M', date.month - 1).set('D', date.day);
            return mDate;
        }
    }

}

