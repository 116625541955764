import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: false,
    name: 'decimalNumber'
})
export class DecimalNumber implements PipeTransform {

  transform(value: unknown): unknown {
      if (parseInt(value+'') == value){
          return parseInt(value + '')
      }
      else{
          return parseFloat(value+'').toFixed(2)
      }
  }

}
