import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormValidateDirective } from '@app/@shared/form-validate/form-validate.directive';
import { reverseAmount } from '@app/@shared/utils';
import { Customer } from '@app/models/customer.model';
import { AccountingService } from '@app/shared/services/accounting.service';
import { HelperService } from '@app/shared/services/helper.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { orderBy, sortBy } from 'lodash';
import moment from "moment"
import { number, object, string } from 'yup';

@Component({
  standalone: false,
  selector: 'app-receive-payment',
  templateUrl: './receive-payment.component.html',
  styleUrls: ['./receive-payment.component.scss']
})
export class ReceivePaymentComponent implements OnInit {

  title = "Customer Payment";
  payment: any = {
    customer: {
      mainAddress: {},
      customerPaymentMethods: [],
      invoices: [],
      defaultCC: {
        address: {},
      },
    },
    PaymentMethodType: "Cash",
    date: new Date()
  }
  invoiceSearch: string;
  selectedInvoice: any = {};
  selectedCustomer: Customer;
  credit = false;
  isLoading = true;
  @Input() customerId: any;
  @Input() invoiceId: any;
  @Input() customerName: string;

  private _paymentInfo: any;
  @Input()
  public get paymentInfo(): any {
    return this._paymentInfo;
  }
  public set paymentInfo(value: any) {
    this._paymentInfo = value;
    if (value) {
      this.payment.paymentAmount = value?.paymentAmount;
      this.payment.appliedPayments = value?.appliedPayments;
      this.payment.date = new Date(value?.date);
      this.payment.refrence = value?.refrence
      this.payment.notes = value?.notes
    }
  }

  validationSchema = object().shape({
    customer: object().shape({
      customerName: string().label('Company').nullable().required("Please select the required company.")
    }),
    invoiceSearch: string().label('invoice').nullable().required("Please select the required invoice."),
    amount: number().nullable().when([], {
      is: () => this.paymentInfo?.paymentId,
      then: (schema) => schema.min(0, 'Amount must be 0 or greater').required(),
      otherwise: (schema) => schema.positive().nullable()
    }),
    paymentMethodType: string().label('Method').nullable().required(),
  });
  isAddPaymentBtn: boolean = false;
  minDate: {
    year: number; month: number; day: number;
  };

  constructor(
    private router: Router,
    public ngbActiveModal: NgbActiveModal,
    private accountingService: AccountingService,
    private helperService: HelperService
  ) { }

  ngOnInit() {
    if (this.customerId && this.customerName) {
      this.isLoading = true
      this.getCustomer();
    }
    else {
      this.isLoading = false
    }
  }

  async getCustomer() {
    await this.accountingService.getAccountingCustomers({
      SearchTerm: this.customerName,
      Page: 1,
      ItemsPerPage: 20,
    }).then(({ list }) => {
      this.payment.customer = list.find((res) => res.customerId == this.customerId);
      this.selectedCustomer = list.find((res) => res.customerId == this.customerId);
      if (this.paymentInfo) {
        this.payment.customer.invoices = [this.paymentInfo?.appliedPayments[0]?.invoice];
        this.selectedInvoice = this.paymentInfo?.appliedPayments[0]?.invoice;
        this.invoiceSearch = this.selectedInvoice?.number;
        this.setMinDate();
      } else {
        this.payment.customer.invoices = this.payment?.customer?.invoices?.filter((x) => x.totalAmount !== 0)
        if (this.invoiceId) {
          this.selectedInvoice = this.payment?.customer?.invoices?.find((res) => res.invoiceId == this.invoiceId);
          this.invoiceSearch = this.selectedInvoice?.number;
          this.setMinDate()
        }
        const latestPayment = orderBy(this.selectedCustomer.payments, ['createdTime'], ['desc'])[0]
        if (latestPayment) {
          const type = latestPayment.paymentMethodType
          this.payment.PaymentMethodType = type == 0 ? 'Check' : type == 1 ? 'Cash' : 'PaymentMethod'
        }
      }
    }).catch((error) => {
      this.helperService.errorMessage(error)
    }).finally(() => {
      this.isLoading = false
    })
  }

  selectCustomer(e): void {
    this.payment.customer = e;
    this.payment.customer.invoices = this.payment?.customer?.invoices?.filter((x) => x.totalAmount !== 0)
  }

  selectInvoice(e): void {
    this.selectedInvoice = this.payment?.customer?.invoices?.find((res) => res.number == e);
    this.setMinDate()
  }

  convertReverseAmount(amount:number){
    return reverseAmount(amount)
  }

  setMinDate() {
    if (this.selectedInvoice?.date) {
      this.minDate = {
        year: moment(this.selectedInvoice?.date).year(),
        month: moment(this.selectedInvoice?.date).month() + 1, // moment.js months are 0-indexed, add 1 for consistency
        day: moment(this.selectedInvoice?.date).date()
      }
    }
  }

  close() {
    this.ngbActiveModal.close();
  }

  changePayment() {
    if (this.selectedCustomer) {
      this.router.navigate([`app/customers/edit/${this.selectedCustomer.customerId}`], { fragment: 'payment' });
      this.ngbActiveModal.close();
    } else {
      this.helperService.errorMessage("Please select a customer.")
    }
  }

  AddPayment(form: FormValidateDirective) {
    if (form.isValid && !this.isAddPaymentBtn) {
      this.isAddPaymentBtn = true;
      if (this.paymentInfo?.paymentId) {
        this.payment.appliedPayments = [{ appliedPaymentId: this.payment.appliedPayments[0]?.appliedPaymentId, amount: this.payment.paymentAmount }];
        this.accountingService.updatePayment(this.paymentInfo?.paymentId, this.payment).then((res) => {
          this.helperService.successMessage("Payment successfully updated.");
          this.ngbActiveModal.close(true);
        }).catch((error) => {
          this.helperService.errorMessage(error)
        }).finally(() => {
          this.isAddPaymentBtn = false;
        });
      } else {
        this.payment.PaymentMethod = this.payment.customer.defaultCC;
        this.payment.appliedPayments = [{ Invoice: this.selectedInvoice, Amount: Math.min(this.payment.paymentAmount, this.selectedInvoice.totalAmount - this.selectedInvoice.amountPaid) }];
        this.accountingService.createPayment(this.payment).then((res) => {
          this.helperService.successMessage("Payment successfully created.");
          this.ngbActiveModal.close(true);
        }).catch((error) => {
          this.helperService.errorMessage(error)
        }).finally(() => {
          this.isAddPaymentBtn = false;
        });
      }
    }
  }

}
