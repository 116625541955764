import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PricingModelListComponent } from './pricing-model-list/pricing-model-list.component';

const routes: Routes = [
  {
    path: '',
    component: PricingModelListComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PricingModelRoutingModule {}