import { Component, OnInit } from '@angular/core';
import { removeBinding } from '@app/@shared/utils';
import { Company } from '@app/models/company.model';
import { AutoCloseTimeEnum } from '@app/models/system.model';
import { AuthService } from '@app/shared/services/auth/auth.service';
import { CompanyService } from '@app/shared/services/company.service';
import { HelperService } from '@app/shared/services/helper.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { omit } from 'lodash';
@UntilDestroy()
@Component({
  standalone: false,
    selector: 'app-settings-systems',
    templateUrl: './systems.component.html',
    styleUrls: ['./systems.component.scss']
})
export class SystemsComponent implements OnInit {
    submitLoading = false;
    allTabAutoClose: AutoCloseTimeEnum | string = AutoCloseTimeEnum.Never;
    autoCloseTimeEnum = AutoCloseTimeEnum
    company: Company = {
        quoteTabAutoClose: 0,
        orderTabAutoClose: 0,
        customerTabAutoClose: 0,
        invoiceTabAutoClose: 0,
        driverTabAutoClose: 0,
        outsourceTabAutoClose: 0,
    };
    autoCloseTimeOptions = [
        {
            name: "Never",
            value: AutoCloseTimeEnum.Never,
        },
        {
            name: "5 Minutes",
            value: AutoCloseTimeEnum.FiveMin,
        },
        {
            name: "10 Minutes",
            value: AutoCloseTimeEnum.TenMin,
        },
        {
            name: "15 Minutes",
            value: AutoCloseTimeEnum.FifteenMin,
        },
        {
            name: "30 Minutes",
            value: AutoCloseTimeEnum.ThirtyMin,
        },
        {
            name: "1 Hour",
            value: AutoCloseTimeEnum.OneHour,
        },
        {
            name: "2 Hours",
            value: AutoCloseTimeEnum.TwoHours,
        },
        {
            name: "3 Hours",
            value: AutoCloseTimeEnum.ThreeHours,
        },
        {
            name: "6 Hours",
            value: AutoCloseTimeEnum.SixHours,
        },
        {
            name: "9 Hours",
            value: AutoCloseTimeEnum.NineHours,
        },
        {
            name: "12 Hours",
            value: AutoCloseTimeEnum.TwelveHours,
        },
        {
            name: "24 Hours",
            value: AutoCloseTimeEnum.TwentyFourHours,
        },
    ];

    constructor(
        private authService: AuthService,
        private helperService: HelperService,
        private companyService: CompanyService,
    ) { }

    ngOnInit() {
        this.authService.$user
            .pipe(untilDestroyed(this))
            .subscribe(async (data) => {
                this.company = await removeBinding(data?.company);
                this.updateAllTabAutoClose()
            })
    }

    updateAllTabAutoClose() {
        const tabValues = [
          this.company.quoteTabAutoClose,
          this.company.orderTabAutoClose,
          this.company.customerTabAutoClose,
          this.company.invoiceTabAutoClose,
          this.company.driverTabAutoClose,
          this.company.outsourceTabAutoClose,
        ];
      
        this.allTabAutoClose = tabValues.every((value) => value == tabValues[0])
          ? tabValues[0]
          : 'Custom';
    }

    handleAllTabsValue(value: any) {
        const keys = [
          'quoteTabAutoClose',
          'orderTabAutoClose',
          'customerTabAutoClose',
          'invoiceTabAutoClose',
          'driverTabAutoClose',
          'outsourceTabAutoClose',
        ];
      
        for (const key of keys) this.company[key] = value;
    }

    onSubmit() {
        if (!this.submitLoading) {
            this.submitLoading = true;
            this.companyService.update(omit(this.company, 'companyId')).then((resp: any) => {
                this.company = resp;
                this.helperService.successMessage("Systems settings successfully updated");
                this.authService.user = {
                    ...this.authService.user,
                    company: this.company,
                }
                this.submitLoading = false;
            }).catch((error) => {
                this.helperService.errorMessage(error)
                this.submitLoading = false;
            });
        }
    }
}
