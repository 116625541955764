import { Component, Input, OnInit } from '@angular/core';
import { patterns } from '@app/@shared/regex/regex-patterns.const';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { object, string } from 'yup';

@Component({
  	standalone: false,
	selector: 'app-add-contact',
	templateUrl: './add-contact.component.html',
	styleUrls: ['./add-contact.component.sass']
})
export class AddContactComponent implements OnInit {

	@Input()
	contact = {
		name: null,
		phoneNumber: null
	}

	title = "Contact";

	validationSchema = object().shape({
		name: string().nullable().required(),
		phoneNumber: string().nullable().matches(patterns.phone, { excludeEmptyString: true, message: 'Contact phone must be a number' }),
	});

	constructor(
		private readonly ngbActiveModal: NgbActiveModal,
	) { }

	ngOnInit(): void { }

	close() {
		this.ngbActiveModal.close()
	}

	onSubmit(f) {
		if (f.isValid) {
			this.ngbActiveModal.close(this.contact)
		}
	}
}
