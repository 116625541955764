import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { splitPhoneNumberAndExtension } from '@app/@shared/utils';
import { UntilDestroy } from '@ngneat/until-destroy';
import moment = require('moment');
import { Subject } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-date-time-input',
  templateUrl: './date-time-input.component.html',
  styleUrls: ['./date-time-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimeInputComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.Emulated,
})

export class DateTimeInputComponent implements OnInit {

  disabled: boolean;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  @Input() dateLabel: string = 'Date';
  @Input() timeLabel: string = 'Time';
  // @Input() max: Date | string;
  // @Input() min: Date | string;
  @Input() maxTime: string;
  @Input() minTime: string;
  @Input() minDate: any;
  @Input() maxDate: any;

  @Output() onChangeValue = new EventEmitter<Date | string>();


  onChange = (value: Date | string) => { };
  onTouched = () => { };

  // @Input() value = moment().toISOString();
  // @Output() valueChange = new EventEmitter<Date | string>();

  @Input() date = moment().startOf('day').toISOString();
  @Output() dateChange = new EventEmitter<Date | string>();

  @Input() time = moment().format('hh:mm A');
  @Output() timeChange = new EventEmitter<Date | string>();


  // Set get main date time value
  private _value = moment().toISOString();
  @Output() valueChange = new EventEmitter<Date | string>();
  @Input()
  get value(): string {
    return this._value;
  }
  set value(value: string) {
    this._value = value;
    this.handleValueSet()
    this.handleMinMaxDate()
  }

  // Set get min date time value
  private _min: string;
  @Input()
  get min(): string {
    return this._min;
  }
  set min(minValue: string) {
    this._min = minValue;
     this.handleMinMaxDate()
  }

  // Set get min date time value
  private _max: string;
  @Input()
  get max(): string {
    return this._max;
  }
  set max(maxValue: string) {
    this._max = maxValue;
    this.handleMinMaxDate()
  }

  private _time: string;
  @Input()
  get timeValue(): string {
    return this._time;
  }
  set timeValue(updateTime: string) {
    
    this._time = updateTime;
    this.timeChange.emit(updateTime)
    this.handleValueChange()
  }

  private _date: string | Date;
  @Input()
  get dateValue(): string | any {
    return this._date;
  }
  set dateValue(updateDate: string | any) {
    this._date = updateDate;
    this.dateChange.emit(updateDate)
    this.handleValueChange()
  }


  constructor() {

  }

  ngOnInit(): void {}

  handleValueSet() {
    if (this.value) {
      this.dateValue = moment(this.value).startOf('day').toISOString()
      this.timeValue = moment(this.value).format('hh:mm A')
    }
  }

  handleMinMaxDate() {
    if (this.max) {
      this.maxDate = {
        year: moment(this.max).year(),
        month: moment(this.max).month() + 1, // moment.js months are 0-indexed, add 1 for consistency
        day: moment(this.max).date()
      }
      if (this.value && moment(this.max).isSame(this.value, 'date')) {
        this.maxTime = moment(this.max).format('hh:mm A')
      } else {
        this.maxTime = null
      }
    }
    else {
      this.maxDate = null
      this.maxTime = null
    }
    if (this.min) {
      this.minDate = {
        year: moment(this.min).year(),
        month: moment(this.min).month() + 1, // moment.js months are 0-indexed, add 1 for consistency
        day: moment(this.min).date()
      }
      if (this.value && moment(this.min).isSame(this.value, 'date')) {
        this.minTime = moment(this.min).format('hh:mm A')
      }
      else {
        this.minTime = null
      }
    }
    else {
      this.minDate = null
      this.minTime = null
    }
  }

  handleValueChange() {
    let val = this.value
    if (this.dateValue && this.timeValue) {
      const time = moment(this.timeValue, 'hh:mm A')
      val = moment(this.dateValue).set('h', time.hours()).set('minutes', time.minutes()).toISOString()
      this.valueChange.emit(val);
    }

    //Update min/max time constraints based on the arrival/departure selection rules
    if (this.dateLabel === 'Arrival Date') {
      this.updateConstraints('Arrival')
      this.ensureTimeOrder('Arrival')
    } else if (this.dateLabel === 'Departure Date') {
      this.updateConstraints('Departure')
      this.ensureTimeOrder('Departure')
    }

    this.onChangeValue.emit(val);
    this.onChange(val);
    this.onTouched();
  } 

  updateConstraints(type: 'Arrival' | 'Departure') {
    const referenceMoment = moment(this.dateValue).set(
      'hour',
      moment(this.timeValue, 'hh:mm A').hours()
    ).set('minute', moment(this.timeValue, 'hh:mm A').minutes());
  
    if (type === 'Departure') {
      // Update maxTime for Arrival or minTime for Departure based on selected date
      if (moment(this.dateValue).isSame(moment(this.maxDate))) {
        this.maxTime = referenceMoment.format('hh:mm A');
      } else {
        this.maxTime = '11:59 PM';
      }
    } else if (type === 'Arrival') {
      // Set minTime for Arrival based on Departure's date and time
      if (moment(this.dateValue).isSame(moment(this.maxDate))) {
        this.minTime = referenceMoment.format('hh:mm A');
      } else {
        this.minTime = '12:00 AM';
      }
    }
  }

  ensureTimeOrder(type: 'Arrival' | 'Departure') {
    const arrivalMoment = moment(this.min)
      .set('hour', moment(this.minTime, 'hh:mm A').hours())
      .set('minute', moment(this.minTime, 'hh:mm A').minutes());
    
    const departureMoment = moment(this.max)
      .set('hour', moment(this.maxTime, 'hh:mm A').hours())
      .set('minute', moment(this.maxTime, 'hh:mm A').minutes());

    const referenceMoment = moment(this.dateValue).set(
        'hour',
        moment(this.timeValue, 'hh:mm A').hours()
        ).set('minute', moment(this.timeValue, 'hh:mm A').minutes());
    
    // Ensure times are in the correct order
    if (type === 'Departure' && referenceMoment.isBefore(arrivalMoment)) {
      this.timeValue = arrivalMoment.format('hh:mm A');
      this.value = arrivalMoment.toISOString();
      this.valueChange.emit(this.value);
    } else if (type === 'Arrival' && referenceMoment.isAfter(departureMoment)) {
      this.timeValue = departureMoment.format('hh:mm A');
      this.value = departureMoment.toISOString();
      this.valueChange.emit(this.value);
    }
  } 

  clearValue() {
    const value = null
    this.dateValue = value;
    this.timeValue = value;
    this.value = value;
    this.onChange(value);
    this.onChangeValue.emit(value);
  }

  writeValue(value: Date) {
    this.value = moment(value).toISOString()
    this.dateValue = moment(value).startOf('day').toISOString()
    this.timeValue = moment(value).format('hh:mm A')
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
