import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PricingModel } from '@app/models/pricing-model.model';
import { PricingModelService } from '@app/shared/services/pricing-model.service';

@Component({
  standalone: false,
  selector: 'app-pricing-add-ons-modal',
  templateUrl: './pricing-add-ons-modal.component.html',
  styleUrls: ['./pricing-add-ons-modal.component.scss']
})
export class PricingAddOnsModalComponent implements OnInit {
  @Input() pricingModel: PricingModel;

  pricingModelCopy: PricingModel; // for local edits

  constructor(
    public activeModal: NgbActiveModal,
    private pricingModelService: PricingModelService
  ) { }

  ngOnInit() {
    // Make a copy so we don’t mutate original until user clicks “Save”
    this.pricingModelCopy = { ...this.pricingModel };
  }

  onSave() {
    // Merge the changed values back
    this.pricingModel.fuelSurchargePercentage = this.pricingModelCopy.fuelSurchargePercentage;
    this.pricingModel.sameDayShippingPercentage = this.pricingModelCopy.sameDayShippingPercentage;

    // Actually call updatePricingModel to persist these changes:
    this.pricingModelService.updatePricingModel(this.pricingModel)
      .then((res) => {
        // Return the updated model
        this.activeModal.close(res.data);
      })
      .catch((error) => {
        // handle error
      });
  }

  onClose() {
    this.activeModal.dismiss('cancel');
  }
}
