import { AfterViewInit, Component, HostListener, NgZone, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HelperService } from '@app/shared/services/helper.service';
import { AuthService } from '@app/shared/services/auth/auth.service';
@UntilDestroy()
@Component({
    standalone: false,
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, AfterViewInit {

    navCollapsed = false;
    user: any = {};
    isLoading: boolean = false;
    isGlobalLoading: boolean = false;
    routePath: string;

    @ViewChild('outlet', { read: ViewContainerRef }) outletRef: ViewContainerRef;
    @ViewChild('content', { read: TemplateRef }) contentRef: TemplateRef<any>;

    windowWidth: number;

    // Listen for the window resize event
    @HostListener('window:resize', ['$event'])
    onResize(event: Event): void {
        const previousState = this.navCollapsed;
        this.windowWidth = window.innerWidth;
        this.routePath = this.router.url
        const pathname = this.routePath.split('?')[0]

        if (this.windowWidth <= 1670 && pathname === '/app/dispatch') {
            this.navCollapsed = true
        } else if (this.windowWidth <= 1368) {
            this.navCollapsed = true
        } else {
            this.navCollapsed = false
        }

        // Only trigger change detection if the state has changed
        if (this.navCollapsed !== previousState) {
            this.zone.run(() => {
                this.helperService.isNavCollapsed = this.navCollapsed;
            });
        }
    }

    constructor(
        private authService: AuthService,
        public util: UtilService,
        private router: Router,
        private helperService: HelperService,
        private zone: NgZone
    ) {
    }

    ngOnInit() {
        this.authService.$user
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.zone.run(() => {
                    this.rerender();
                });
            });   
        

        this.helperService.isLoading$
            .pipe(untilDestroyed(this))
            .subscribe({
                next: (isLoading: boolean) => {
                    this.zone.run(() => {
                        this.isGlobalLoading = isLoading;
                    });
                },
                error: (error: any) => {
                    this.zone.run(() => {
                        this.isGlobalLoading = false;
                    });
                }
            });
    }

    rerender() {
        this.outletRef?.clear();
        this.outletRef?.createEmbeddedView(this.contentRef);
    }

    ngAfterViewInit() {
        this.router.events
            .pipe(untilDestroyed(this))
            .subscribe((event) => {
                if (event instanceof NavigationStart) {
                    this.zone.run(() => {
                        this.isLoading = true;
                    });
                } else if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
                    this.zone.run(() => {
                        this.isLoading = false;
                    });
                }
            });
    }

    onCollapseToggle() {
        this.zone.run(() => {
            this.navCollapsed = !this.navCollapsed;
            this.helperService.isNavCollapsed = this.navCollapsed;
        });
    }

    logout() {
        this.authService.logout();
    }
}
