import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserDetailsComponent } from '../user-details/user-details.component';
import { CompanyUser } from '@models/company-user.model';
import { UserService } from '@app/shared/services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SettingService } from '@app/shared/services/setting.service';
import { HelperService } from '@app/shared/services/helper.service';
import { DatatableComponent } from '@app/@shared/datatable';
import { AppService } from '@app/shared/services/app.service';
import { debounceTime } from 'rxjs/operators';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { AuthService } from '@app/shared/services/auth/auth.service';
import { User } from '@app/models/user.model';
import { AdminSetPasswordComponent } from '../admin-set-password/admin-set-password.component';

@UntilDestroy()
@Component({
    standalone: false,
    selector: 'app-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, OnDestroy {

    disableUsers: boolean = false;
    title = "Users"
    @ViewChild('datatable') datatable: DatatableComponent
    selectedRoles: any[];
    roleName: any[];
    roles = [{
        name: 'COMPANY_ADMIN',
        selected: false
    }, {
        name: 'COMPANY_DISPATCHER',
        selected: false
    }, {
        name: 'COMPANY_SALES',
        selected: false
    }, {
        name: 'COMPANY_BILLING',
        selected: false
    },]
    selectedRolesName: string;
    currentUser: User;

    userRoles: any[];

    get isAdmin(): boolean {
        return this.userRoles.includes("GLOBAL_ADMIN") || this.userRoles.includes("COMPANY_ADMIN");
    }

    constructor(
        private userService: UserService,
        private settingService: SettingService,
        private helperService: HelperService,
        private modalService: NgbModal,
        private appService: AppService,
        private authService: AuthService,
    ) {
    }

    ngOnInit() {
        this.appService.$searchTerm
            .pipe(
                untilDestroyed(this),
                debounceTime(500)
            )
            .subscribe((text) => {
                this.datatable?.setSearchValue(text);
            })

        this.authService.$user
            .pipe(
                untilDestroyed(this),
            )
            .subscribe((user) => {
                this.currentUser = user;
                this.userRoles = user?.roles;
            })
    }

    ngOnDestroy() {
        this.appService.searchTerm = ''
    }

    getUserData = (request?) => {
        if (this.selectedRoles) {
            request.Roles = this.selectedRoles;
        }
        if (this.disableUsers) {
            request.DisabledUsers = this.disableUsers;
        }
        this.userService.cancelPendingRequestGetAllUsers();
        return this.userService.getAllUsers(request);
    }

    openUserModal(user: CompanyUser) {
        const modalRef = this.modalService.open(UserDetailsComponent, {
            size: 'lg',
        });
        modalRef.componentInstance.user = user ?? { roles: [] };
        modalRef.closed.pipe(untilDestroyed(this)).subscribe((isReload) => {
            if (isReload) {
                this.datatable?.refresh()
            }
        });
    }

    roleSelectionUpdated(values) {
        this.selectedRoles = values?.map(r => r.name)
        this.datatable?.refresh();
    }

    updateDisableUsers() {
        this.datatable?.refresh();
    }

    onDelete(user: CompanyUser) {
        this.settingService.deleteUser(user?.id).then((res) => {
            this.datatable?.refresh()
            this.helperService.successMessage("User successfully deleted.");
        }).catch((error) => {
            this.helperService.errorMessage(error)
        });
    }

    disableUser(user: CompanyUser) {
        this.settingService.disableUser(user?.id).then((res) => {
            this.helperService.successMessage('User disabled successfully')
            this.datatable?.refresh()
        }).catch((error) => {
            this.helperService.errorMessage(error);
        })
    }

    enableUser(user: CompanyUser) {
        this.settingService.enableUser(user?.id).then((res) => {
            this.helperService.successMessage('User enabled successfully')
            this.datatable?.refresh()
        }).catch((error) => {
            this.helperService.errorMessage(error);
        })
    }

    onChangePassword(user: CompanyUser) {
        const activeModal = this.modalService.open(ChangePasswordComponent)
        activeModal.componentInstance.user = user;
        activeModal.componentInstance.isChangePassword = user.id == this.currentUser.id;
    }

    onSetPassword(user: CompanyUser) {
        const activeModal = this.modalService.open(AdminSetPasswordComponent)
        activeModal.componentInstance.user = user;
    }

    onResetPassword(user: CompanyUser) {
        this.authService.forgotPassword({ UserName: user.email })
            .then((res) => {
                this.helperService.successMessage('Password reset link has been sent on email')
            }).catch((error) => {
                this.helperService.errorMessage(error?.messages[0], "Something went wrong when send reset password link")
            })
    }

    onInviteUser(user: CompanyUser) {
        this.settingService.inviteUser(user?.id).then((res) => {
            this.helperService.successMessage('The user invitation email was sent successfully.')
            this.datatable?.refresh()
        }).catch((error) => {
            this.helperService.errorMessage(error);
        })
    }

}
