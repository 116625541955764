import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@app/models/core/base';
import { toParams } from 'src/utils/to-params';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class QuickbooksService {

    basePath = 'Quickbooks'

    constructor(
        protected http: HttpClient
    ) {
    }

    getIntegrationStatus() {
        return firstValueFrom(this.http.get<ApiResponse<any>>(`${this.basePath}/IntegrationStatus`)).then((({ data }) => data));
    }

    connectIntegration(request?: any) {
        return firstValueFrom(this.http.post<ApiResponse<any>>(`${this.basePath}/AuthorizationCode`, request)).then((({ data }) => data));
    }

    disconnectIntegration(request?: any) {
        return firstValueFrom(this.http.put<ApiResponse<any>>(`${this.basePath}/DisconnectIntegration`, {})).then((({ data }) => data));
    }

}
