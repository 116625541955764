import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  standalone: false,
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
  shortName: string;

  @Input() src: string;
  @Input() type: 'user' | 'company' | 'default';
  @Input() size: 'small' | 'medium' | 'large' | 'extra-large' = 'medium';

  private _name: string;
  @Input()
  public get name(): string {
    return this._name;
  }
  public set name(value: string) {
    this._name = value;
    this.shortName = value?.charAt(0).toUpperCase()
  }

  @HostBinding('class') get classes(): string {
    const classes = [`size-${this.size}`];
    return classes.join(' ')
  }


  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

  handleLoadError(): void {
    this.src = null
  }

}
