import { Component, OnInit } from '@angular/core';
import { removeBinding } from '@app/@shared/utils';
import { Company, InvoiceCreationMode } from '@app/models/company.model';
import { AuthService } from '@app/shared/services/auth/auth.service';
import { CompanyService } from '@app/shared/services/company.service';
import { HelperService } from '@app/shared/services/helper.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { omit } from 'lodash';
import { array, number, object, string } from 'yup';

@UntilDestroy()
@Component({
    selector: 'app-settings-billing',
    templateUrl: './billing.component.html',
    styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {

    company: Company = {
        emailFromAccounting: '',
        invoiceNotes: '',
        defaultMarkupPercent: null,
        maxFreeWaitingTime: null,
        costPerMile: null,
        useNewPricing: false,
    };
    submitLoading = false;
    isEditable = false;
    costPerMile: any;

    validationSchema = object().shape({
        billingEmail: string().label('Billing Email').nullable().required().email(),
        invoiceFooterText: string().nullable(),
        defaultMarkup: number().nullable().positive(),
        FreeWaitingTime: number().nullable().positive(),
        costPerMile: number().nullable().positive(),
    });

    invoiceCreationModeOptions = [
        {
            name: "Manual",
            value: InvoiceCreationMode.Manual
        },
        {
            name: "Order was created",
            value: InvoiceCreationMode.CreatedAndCompleted
        },
        {
            name: "Order was completed",
            value: InvoiceCreationMode.Completed
        }
    ]

    constructor(
        private authService: AuthService,
        private helperService: HelperService,
        private companyService: CompanyService,
    ) {
    }

    ngOnInit() {

        this.authService.$user
            .pipe(untilDestroyed(this))
            .subscribe(async (data) => {
                this.company = await removeBinding(data?.company);

                this.company.invoiceCreationMode = typeof this.company?.invoiceCreationMode === 'string'
                    ? InvoiceCreationMode[this.company?.invoiceCreationMode as keyof typeof InvoiceCreationMode]
                    : this.company?.invoiceCreationMode;

                if (!this.company?.truckMonthlyExpenses) {
                    this.company.truckMonthlyExpenses = []
                }

                const sum = this.company?.truckMonthlyExpenses?.reduce((a, b) => a + b.price, 0) ?? 0;
                if (this.company?.truckMilesPerMonth != null) {
                    this.costPerMile = (sum / + (this.company?.truckMilesPerMonth)).toFixed(2);
                } else {
                    this.costPerMile = 0;
                }
            })

    }



    isEditableEmail() {
        this.isEditable = !this.isEditable
    }

    onSubmit(f) {
        if (f?.isValid) {
            this.submitLoading = true;
            this.companyService.update(omit(this.company, 'companyId')).then((resp: any) => {
                this.company = resp;
                this.helperService.successMessage("Billing settings successfully updated");
                this.authService.user = {
                    ...this.authService.user,
                    company: this.company,
                }
                this.submitLoading = false;
            }).catch((error) => {
                this.helperService.errorMessage(error)
                this.submitLoading = false;
            });
        }
    }
}
