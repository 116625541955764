import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { PricingModelService } from '@app/shared/services/pricing-model.service';
import { MileageCharge, PalletDiscount, PricingModel } from '@app/models/pricing-model.model';
import { Customer } from '@app/models/customer.model';
import { HelperService } from '@app/shared/services/helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddMileageChargeModalComponent } from '../add-mileage-charge/add-mileage-charge-modal.component';

@Component({
  selector: 'app-pricing-model-list',
  templateUrl: './pricing-model-list.component.html',
  styleUrls: ['./pricing-model-list.component.scss']
})
export class PricingModelListComponent implements OnInit {

  title = "Pricing Models";

  fixedPalletCounts: number[] = [1 , 2];
  palletCounts: number[] = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  pricingModel: PricingModel;
  selectedCustomer: Customer = null;
  isDirty: boolean = false;
  loading: boolean = false;
  isGeneratedFromInvoices: boolean = false;
  specificCustomer: boolean = false;

  @Input()
  public get customer(): Customer {
    return this.selectedCustomer;
  }
  public set customer(value: Customer) {
    this.specificCustomer = true;
    this.selectedCustomer = value;
  }

  constructor(
    private pricingModelService: PricingModelService,
    private cdr: ChangeDetectorRef,
    private helperService: HelperService,
    private ngbModal: NgbModal
  ) { }

  ngOnInit(): void {
    this.getPricingModels();

  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  selectCustomer() {
    this.getPricingModels();
  }

  getPricingModels() {
    this.loading = true;
    this.pricingModelService.getAllPricingModels(this.selectedCustomer?.customerId)
      .then((pricingModels) => {
        this.pricingModel = pricingModels;
        this.isDirty = false;
        this.isGeneratedFromInvoices = false; 
      })
      .catch((error) => {
        this.helperService.errorMessage("Failed to fetch pricing models.");
      })
      .finally(() => {
        this.loading = false;
      });
  }

  generateFromInvoices() {
    this.loading = true;
    this.pricingModel.customerId = this.selectedCustomer?.customerId;
    this.pricingModelService.generatePricingModelFromInvoices(this.pricingModel)
      .then((response) => {
        this.pricingModel = response;
        this.isDirty = true;
        this.isGeneratedFromInvoices = true;
        this.helperService.successMessage("Pricing model generated from invoices.");
      })
      .catch((error) => {
        this.helperService.errorMessage("Error generating pricing model from invoices.");
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onCustomerChange() {
    this.getPricingModels();
  }

  calculatePrice(palletCount: number, palletDiscount: PalletDiscount | undefined, mileage: MileageCharge): number {
    const palletMileagePrice = this.pricingModel.palletMileagePrices.find(
      (pmp) => pmp.palletCount === palletCount && mileage.minMileage >= pmp.minMileage && mileage.maxMileage <= pmp.maxMileage
    );
  
    if (palletMileagePrice) {
      return 0;
    }
  
    // If no price for the specified pallet count, use the price for 2 pallets
    const twoPalletPrice = this.pricingModel.palletMileagePrices.find(
      (pmp) => pmp.palletCount === 2 && mileage.minMileage >= pmp.minMileage && mileage.maxMileage <= pmp.maxMileage
    );
  
    if (!twoPalletPrice) {
      return 0;
    }
  
    // Calculate base price per pallet from the two-pallet price
    const basePalletPrice = twoPalletPrice.price / 2;

    let totalPrice = basePalletPrice * palletCount;

    const discountPercentage = palletDiscount?.discountPercentage || 0;

    const discountAmount = totalPrice * (discountPercentage / 100);
    totalPrice += discountAmount;

    return totalPrice;
  }

  onPercentageIncreaseChange(mileage: MileageCharge) {
    this.isDirty = true;
  }

  onDiscountChange(index: number) {
    this.isDirty = true;
  }

  onBasePriceChange() {
    this.isDirty = true;
  }

  updatePricingModel() {
    this.pricingModel.customerId = this.selectedCustomer?.customerId;
    this.pricingModelService.updatePricingModel(this.pricingModel)
      .then((res) => {
        this.pricingModel = res.data;
        this.helperService.successMessage("Pricing model updated successfully.");
        this.isDirty = false;
        this.isGeneratedFromInvoices = false;
      })
      .catch((error) => {
        if (error.status === 400 && error.error && error.error.Message) {
          this.helperService.errorMessage(error.error.Message);
        } else {
          this.helperService.errorMessage("Error updating pricing model.");
        }
      });
  }

  revertToDefaultPricingModel() {
    if (this.pricingModel?.isDefault) {
      this.helperService.successMessage("Pricing model is already the default.");
      return;
    }

    const pricingModelId = this.pricingModel?.id;
    const customerId = this.selectedCustomer?.customerId;

    this.pricingModelService.revertToDefault(pricingModelId, customerId)
      .then(() => {
        this.helperService.successMessage("Pricing model reverted to default successfully.");
        this.getPricingModels();
      })
      .catch((error) => {
        this.helperService.errorMessage("Error reverting pricing model to default.");
      });
  }

  deleteCustomerPricingModel() {
    const customerId = this.selectedCustomer?.customerId;

    if (!customerId) {
      this.helperService.errorMessage("No customer selected.");
      return;
    }

    this.pricingModelService.deleteCustomerPricingModel(customerId)
      .then(() => {
        this.helperService.successMessage("Customer-specific pricing model deleted successfully.");
        this.getPricingModels();
      })
      .catch((error) => {
        this.helperService.errorMessage("Error deleting customer-specific pricing model.");
      });
  }

  getEditablePriceForPalletMileageRange(mileage: MileageCharge, palletCount: number) {
    return (
      this.pricingModel.palletMileagePrices.find(
        (price) =>
          price.palletCount === palletCount &&
          price.minMileage === mileage.minMileage &&
          price.maxMileage === mileage.maxMileage
      ) || { price: 0 }
    );
  }
  
  onPriceChange() {
    this.isDirty = true;
  }

  getPalletDiscounts(): PalletDiscount[] {
    return this.pricingModel.palletDiscounts
      .filter(discount => discount.palletCount > 2)
      .sort((a, b) => a.palletCount - b.palletCount);
  }

  removeDiscount(){
    if(this.isDirty){
      this.helperService.successMessage("Please first save pending changes.");
      return;
    }

    for (const palletDiscount of this.pricingModel.palletDiscounts) {
      palletDiscount.discountPercentage = 0;
    }

    this.pricingModel.customerId = this.selectedCustomer?.customerId;
    this.updatePricingModel();
  }

  async openAddMileageChargeModal() {
    var pricingModelId = this.pricingModel.id;

    if(!pricingModelId){
      if(this.isDirty){
        this.helperService.successMessage("Please first save pending changes.");
        return;
      }
      this.pricingModel.customerId = this.selectedCustomer?.customerId;
      var res = await this.pricingModelService.updatePricingModel(this.pricingModel);
      pricingModelId = res.data.id;
    }

    const modalRef = this.ngbModal.open(AddMileageChargeModalComponent, { size: 'md' });
    modalRef.componentInstance.existingMileageCharges = this.pricingModel.mileageCharges;
    modalRef.componentInstance.pricingModelId = pricingModelId;
  
    modalRef.result.then((updatedPricingModel) => {
      if (updatedPricingModel) {
        this.pricingModel = updatedPricingModel;
        this.helperService.successMessage("Mileage Charge added successfully.");
        this.isDirty = false;
        this.isGeneratedFromInvoices = false;
      }
    }, (reason) => {
      // Handle modal dismissal if necessary
    });
  }

  async removeMileageRange(minMileage, maxMileage){
    var pricingModelId = this.pricingModel.id;

    if(!pricingModelId){
      if(this.isDirty){
        this.helperService.successMessage("Please first save pending changes.");
        return;
      }
      this.pricingModel.customerId = this.selectedCustomer?.customerId;
      var res = await this.pricingModelService.updatePricingModel(this.pricingModel);
      pricingModelId = res.data.id;
    }

    this.pricingModelService.removeMileageCharge(pricingModelId, {minMileage: minMileage, maxMileage: maxMileage, additionalChargePercentage: 0})
    .then((res) => {
      this.pricingModel = res;
      this.helperService.successMessage("Mileage Charge removed successfully.");
      this.isDirty = false;
      this.isGeneratedFromInvoices = false;
    })
    .catch((error) => {
      if (error.status === 400 && error.error && error.error.Message) {
        this.helperService.errorMessage(error.error.Message);
      } else {
        this.helperService.errorMessage("Error removing Mileage Charge.");
      }
    })
  }
}
