import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, output } from '@angular/core';
import { Attachable, AttachmentService } from '@app/services/attachment.service';
import { UtilService } from '@app/services/util.service';
import { Attachment } from '@models/attachment.model';
import { Order } from '@models/order.model';
import { SharedService } from '@app/services/shared.service';
import mime from 'mime';
import { EntityType } from '@models/entity-type.model';
import { OrderStop } from '@models/order-stop.model';
import { OrderItem } from '@models/order-item.model';
import { ImagesService } from '@app/shared/services/images.service';
import { HelperService } from '@app/shared/services/helper.service';
import { has } from 'lodash';

@Component({
    standalone: false,
    selector: 'app-files-viewer',
    templateUrl: './files-viewer.component.html',
    styleUrls: ['./files-viewer.component.scss']
})
export class FilesViewerComponent implements OnInit, OnChanges {
    @Input() order: Order;
    @Input() entityType: EntityType = EntityType.Order;
    @Input() loadSharedOrder = true;
    @Input() entity: Attachable;
    @Input() files: Attachment[];
    @Input() menageForm: 'inside' | 'outside' = 'inside';

    @Output() onChange = new EventEmitter();

    constructor(
        private attachmentService: AttachmentService,
        private util: UtilService,
        private shared: SharedService,
        private imagesService: ImagesService,
        private helperService: HelperService,
    ) {
    }

    ngOnInit() {
        if (this.loadSharedOrder) {
            this.shared.quote$.subscribe(n => {
                this.order = this.shared.getSharedQuote();
                this.loadOrder();
            });
        }
        this.loadOrder();
    }

    private async loadOrder() {
        if (this.order?.orderId != null) {
            try {
                this.files = await this.imagesService.getListByOrderAttachments(this.order.orderId);
            } catch (error) {
                this.helperService.errorMessage(error)
            }
        }
    }

    onRemove(f: Attachment) {
        this.files.splice(this.files.indexOf(f), 1);
        if (this.menageForm === 'outside') {
            this.onChange.emit(this.files)
        }
    }

    onSelect(event) {
        if (this.menageForm === 'inside') {
            this.util.mainLoaderOn();
            for (const file of event.addedFiles) {
                let id: number;
                if (this.order?.orderId != null) {
                    id = this.order?.orderId;
                } else {
                    if ((this.entity as OrderStop)?.orderStopId != null) {
                        id = (this.entity as OrderStop)?.orderStopId;
                    } else if ((this.entity as OrderItem)?.orderItemId != null) {
                        id = (this.entity as OrderItem)?.orderItemId;
                    }
                }
                this.attachmentService.uploadAttachment(this.entityType, id, file).subscribe({
                    next: (res) => {
                        this.files.push(res);
                        this.util.mainLoaderOff();
                        this.onChange.emit(this.files)
                    },
                    error: (err) => {
                        this.util.showError(err);
                        this.util.mainLoaderOff();
                    },
                    complete: () => {
                        this.util.mainLoaderOff()
                    }
                })
            }
        }
        else {
            event.addedFiles = event?.addedFiles?.map((files) => {
                files.url = URL.createObjectURL(files);
                return files;
            })
            this.files.push(...event.addedFiles);
            this.onChange.emit(this.files)
        }
    }

    onClick(f: Attachment, $event: MouseEvent) {
        $event.preventDefault();
        $event.stopPropagation();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.order != null && !changes.order.isFirstChange()) {
            this.loadOrder();
        }
    }
}
