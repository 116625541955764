import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { UtilService } from '@app/services/util.service';
import { RouteItemDisplayStatus, RouteItemStop, RouteItemType } from '@models/route-item-stop.model';
import { EntityType, EntityTypes } from '@models/entity-type.model';
import { RouteItem, RouteItemStatus } from '@models/route-item.model';
import moment from "moment"
import { ChargeType } from '@models/charge-type.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PickupTypeEnum } from '@app/models/pickup-type';
import { ConfirmStopItemDialogComponent } from '../confirm-stop-item-dialog/confirm-stop-item-dialog.component';
import { RoutesService } from '@app/shared/services/router.service';
import { HelperService } from '@app/shared/services/helper.service';
import { chain, cloneDeep, map, maxBy, omit, some } from 'lodash';
import { ChargeTypeDetailsComponent } from '@app/components/main/settings/charge-types/charge-type-details/charge-type-details.component';
import { Customer } from '@app/models/customer.model';
import { CustomerService } from '@app/shared/services/customer.service';
import { DialogService } from '@app/@shared/dialogs/dialog.service';
import { AttachmentService } from '@app/services/attachment.service';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { OrderService } from '@app/shared/services/order.service';
import { OrderStopType } from '@app/models/route-box.model';
import { QuoteService } from '@app/shared/services/quote.service';
import { OrderStopTypeEnum } from '@app/models/order-stop.model';
import { Order } from '@app/models/order.model';
import { AuthService } from '@app/shared/services/auth/auth.service';
import { FilesViewerModalComponent } from '@app/@shared/files-viewer-modal/files-viewer-modal.component';
import { Attachment } from '@app/models/attachment.model';

@Component({
    standalone: false,
    selector: 'app-confirm-stop-dialog',
    templateUrl: './confirm-stop-dialog.component.html',
    styleUrls: ['./confirm-stop-dialog.component.scss']
})
export class ConfirmStopDialogComponent implements OnInit {
    @Output() save: EventEmitter<RouteItemStop> = new EventEmitter<RouteItemStop>();
    EntityTypes = EntityTypes;
    @Input() routeId: number;
    @Input() title: string = "Confirm Stop";
    @Input() completedStopsCount: number;
    @Input() totalStopsCount: number;
    @Input() confirmStopNewStatus: RouteItemStatus;
    RouteItemType = RouteItemType;
    time = { hour: 13, minute: 30 };
    additionalCharges: ChargeType[];
    addressCustomer: Customer;
    confirmDisabled: boolean;
    minArrivalDateTime = null;
    attachments: any[] = []
    autogenerateReferenceNumber: string;
    customsReferenceNumber: string;
    isEditReferenceNumber: boolean;
    private shouldFocusInput = false;
    maxLengthReferenceNumber: number = 50;
    @ViewChild('customsReferenceNumberInput', { static: false }) customsReferenceNumberInput!: ElementRef;
    activities: any[] = []
    showUnallocatedItemsTable: boolean = false;
    defaultAddress = {
        fullAddress: null,
        name: null,
        addressLine2: null,
        zip: null
    }
    private _confirmStop: RouteItemStop = {
        address: { ...this.defaultAddress }
    };
    private _initialRouteItemsPickQuantity: any[];
    loading: boolean = false;
    order: Order;
    routeItemStatus = RouteItemStatus;

    isChargeTypesCollapse: boolean = true
    waitingTimePattern = /^(\d+)\.(\d+)$/
    waitingTime = 0
    get confirmStop(): RouteItemStop {
        return this._confirmStop;
    }
    set confirmStop(updateValues: RouteItemStop) {
        if (!updateValues?.arrivalDateTime) {
            updateValues.arrivalDateTime = moment().toISOString();
        } else {
            // TODO: RUS-3092 Temporarily remove timeZone in maxDepartureDateTime;
            updateValues.arrivalDateTime = updateValues.arrivalDateTime.toString().replace("T", " ").replace("Z", "");
        }
        if (!updateValues?.departureDateTime) {
            updateValues.departureDateTime = moment().toDate();
        } else {
            // TODO: RUS-3092 Temporarily remove timeZone in maxDepartureDateTime;
            updateValues.departureDateTime = updateValues.departureDateTime.toString().replace("T", " ").replace("Z", "");
        }
        if (updateValues?.referenceNumber) {
            const dashIndex = updateValues?.referenceNumber?.indexOf('-');
            this.maxLengthReferenceNumber = 50;
            if (dashIndex !== -1) {
                this.autogenerateReferenceNumber = updateValues?.referenceNumber?.slice(0, dashIndex).trim();
                this.customsReferenceNumber = updateValues?.referenceNumber?.slice(dashIndex + 1).trim();
                this.maxLengthReferenceNumber -= this.autogenerateReferenceNumber?.length;
            } else {
                this.autogenerateReferenceNumber = updateValues?.referenceNumber;
                this.maxLengthReferenceNumber -= this.autogenerateReferenceNumber?.length;
            }

            if (this.maxLengthReferenceNumber < 1) {
                this.maxLengthReferenceNumber = 0;
            }

        }
        if (!updateValues?.freeWaitingTime && this.authService?.user?.company?.maxFreeWaitingTime) {
            updateValues.freeWaitingTime = this.authService?.user?.company?.maxFreeWaitingTime;
        }
        this.additionalCharges = updateValues.charges
        this._confirmStop = updateValues;
        this.waitingTime = this.calculateTimeDifferenceBetweenArrivalAndDeparture()
        this.menageDeliveryStop()
    }

    get locationContactNames() {
        let names = [];

        if (this.addressCustomer) {
            const contactNames = map(this.addressCustomer.customerContacts, (contact) => {
                return `${contact?.firstName} ${contact?.lastName}`;
            });

            names = names.concat(contactNames)
        }

        if (this.confirmStop?.orderStop && !this.confirmStop?.transferStop) {
            var address = this.confirmStop?.address;
            if (address) {
                if (address.name)
                    names.push(address.name)
            }
        }

        return chain(names)
            .filter((name) => !!name)
            .uniq()
            .value()
    }

    get locationContactNumbers() {
        let numbers = [];

        if (this.addressCustomer) {
            const contactNumbers = this.addressCustomer.customerContacts.map(({ phone }) => phone)
            numbers = numbers.concat(contactNumbers)
        }

        if (this.confirmStop?.orderStop && !this.confirmStop?.transferStop) {
            var address = this.confirmStop?.address;
            if (address) {
                if (address.phoneNumber)
                    numbers.push(address.phoneNumber)
            }
        }

        return chain(numbers)
            .filter((phone) => !!phone)
            .uniq()
            .value()
    }

    get companyContactNames() {
        const names = [...this.locationContactNames, this.confirmStop?.customer?.customerName];
        return chain(names)
            .uniq()
            .value()
    }

    get companyContactNumbers() {
        const numbers = [...this.locationContactNumbers, this.confirmStop?.customer?.phone];
        return chain(numbers)
            .uniq()
            .value()
    }

    constructor(
        public util: UtilService,
        private helperService: HelperService,
        private routesService: RoutesService,
        public activeModal: NgbActiveModal,
        private modalService: NgbModal,
        private readonly customerService: CustomerService,
        private dialogService: DialogService,
        private attachmentService: AttachmentService,
        private orderService: OrderService,
        private renderer: Renderer2,
        private quoteService: QuoteService,
        private authService: AuthService
    ) {
    }

    ngOnInit(): void {
        this._initialRouteItemsPickQuantity = this.confirmStop.routeItems?.map(x => {
            return {
                routeItemId: x.routeItemId,
                pickQuantity: x.pickQuantity
            }
        });

        if (this.confirmStop?.orderStop && !this.confirmStop?.transferStop && this.confirmStop?.address?.customerId && !this.addressCustomer) {
            this.customerService.get(this.confirmStop.address.customerId).then((data) => {
                this.addressCustomer = data
            });
        } else if (!this.confirmStop?.orderStop && this.confirmStop?.transferStop && this.confirmStop?.customer?.customerId && !this.addressCustomer) {
            this.customerService.get(this.confirmStop?.customer?.customerId).then((data) => {
                this.addressCustomer = data
            });
        }

        if (this.confirmStop?.orderStop?.orderId) {
            this.orderService.getOrderActivities(this.confirmStop?.orderStop?.orderId).then((data: any) => {
                this.activities = data;
            });
        }

        this.loadUnallocatedItems();
    }
    get allAttachments() {
       return this.confirmStop.orderStop.attachments?.filter(x => !!x.attachmentId);
    }

    ngAfterViewChecked(): void {
        if (this.isEditReferenceNumber && this.shouldFocusInput  && this.customsReferenceNumberInput) {
            this.renderer.selectRootElement(this.customsReferenceNumberInput.nativeElement).focus();
            this.shouldFocusInput  = false;
        }
    }

    menageDeliveryStop() {
        if (this.confirmStop?.type === RouteItemType.Delivery) {
            const defaultDateTime = moment().hour(12).minute(0).second(0).toISOString();
            this.loading = true
            this.routesService.getRouteItemGroups({
                routeId: this.routeId,
                OrderNumber: this.confirmStop?.orderNumber,
            }).then((resp) => {
                let emptyRouteItem = this.confirmStop?.routeItems?.find(x => !x.routeItemId);

                if(emptyRouteItem) {
                    let orderStopId = emptyRouteItem["orderStopId"];
                    let routeItemStops = resp.list?.find(x => x.date == emptyRouteItem["groupDate"] && x.routeItemStops.find(ris => ris.orderStopId == orderStopId))?.routeItemStops;

                    let routeItemStop = routeItemStops?.find(ris => ris.orderStopId == orderStopId);

                    let routeItem = routeItemStop.routeItems.find(x => x.orderStopId == orderStopId && x.orderItemId == emptyRouteItem.orderItemId);
                    
                    emptyRouteItem.qtyToDeliver = routeItem.qtyToDeliver;
                    emptyRouteItem.qtyToPickup = routeItem.qtyToPickup;
                    emptyRouteItem.quantity = routeItem.quantity;
                    emptyRouteItem.routeItemId = routeItem.routeItemId;

                    routeItem.pickQuantity = emptyRouteItem.pickQuantity;

                    this._initialRouteItemsPickQuantity.push({
                        routeItemId: routeItem.routeItemId,
                        pickQuantity: routeItem.pickQuantity
                    })
                }

                const pickupStopsStops: any = chain(resp.list)
                    .map('routeItemStops')
                    .flatten()
                    .filter((routeItemStop) => (routeItemStop?.type === RouteItemType.Pickup) && !!routeItemStop?.departureDateTime)
                    .value()

                if (pickupStopsStops?.length > 0) {
                    const maxDepartureDateTime = maxBy(map(pickupStopsStops, 'departureDateTime'));
                    // TODO: RUS-3092 Temporarily remove timeZone in maxDepartureDateTime;
                    let minArrivalDateTime = maxDepartureDateTime?.replace("T", " ")?.replace("Z", "");
                    if(moment().isAfter(minArrivalDateTime,'day')) {
                        this.minArrivalDateTime = minArrivalDateTime
                        this.confirmStop.arrivalDateTime = this.confirmStop.arrivalDateTime || defaultDateTime
                        this.confirmStop.departureDateTime = this.confirmStop.departureDateTime || defaultDateTime
                    } else {
                        this.minArrivalDateTime = minArrivalDateTime
                        this.confirmStop.arrivalDateTime = this.confirmStop.arrivalDateTime || minArrivalDateTime
                        this.confirmStop.departureDateTime = this.confirmStop.departureDateTime || minArrivalDateTime
                    }
                } else {
                    this.minArrivalDateTime = defaultDateTime
                    this.confirmStop.arrivalDateTime = this.confirmStop.arrivalDateTime || defaultDateTime
                    this.confirmStop.departureDateTime = this.confirmStop.departureDateTime || defaultDateTime
                }

            
            }).catch((error) => {
                this.minArrivalDateTime = defaultDateTime
                this.confirmStop.arrivalDateTime = this.confirmStop.arrivalDateTime || defaultDateTime
                this.confirmStop.departureDateTime = this.confirmStop.departureDateTime || defaultDateTime
                console.log(error)
            }).finally(() => {
                this.loading = false
            })
        }
    }

    close() {
        this.activeModal.close();
    }

    handleChangeFile(files) {
        this.attachments = files
    }

    addCharge() {

        const ref = this.modalService.open(ChargeTypeDetailsComponent, {
            size: 'md'
        })
        ref.componentInstance.isApplicableToStop = true
        ref.componentInstance.item = { isApplicableToStop: true }
        ref.componentInstance.title = "Additional Charge"
        ref.result.then(
            (result) => {
                if (result) {
                    this.additionalCharges.push(result)
                }
            },
            () => { }
        );

    }

    calculateTimeDifferenceBetweenArrivalAndDeparture(): number {
        if (this.confirmStop.arrivalDateTime && this.confirmStop.departureDateTime) {
            const arrival = moment(this.confirmStop.arrivalDateTime);
            const departure = moment(this.confirmStop.departureDateTime);
            const diff = moment.duration(departure.diff(arrival));
            if (diff.asMilliseconds() > 0) {
                return diff.asMilliseconds()
            }
        }
        return 0
    }

    handleChangeDateTime($event: any): void {
        this.waitingTime = this.calculateTimeDifferenceBetweenArrivalAndDeparture()
    }

    waitingTimeValidationCheck(): boolean {
        const calculatedDiff = this.calculateTimeDifferenceBetweenArrivalAndDeparture();
        if (typeof this.waitingTime !== 'number' || calculatedDiff < this.waitingTime) {
            this.helperService.errorMessage("The Waiting time cannot exceed the time between Arrival and Departure. Please enter a value less than or equal to the available time.");
            return false
        }
        return true
    }

    changeQtyDown(stopItem) {
        if (stopItem.pickQuantity <= 1) {
            return
        }
        if (!stopItem.pickQuantity) {
            stopItem.pickQuantity = 0;
        } else {
            stopItem.pickQuantity = Math.max(0, stopItem.pickQuantity - 1);
        }
    }

    changeQtyUp(stopItem) {
        if (stopItem.type === RouteItemType.Delivery) {
            let otherStopsTotalQuantity = stopItem.orderItem.orderItemStops
                .filter(x => x.orderStop?.orderStopType == OrderStopTypeEnum.DELIVERY &&
                             x.isActive &&
                             x.orderItemStopId !== stopItem.orderItemStopId)
                .reduce((sum, stop) => sum + (stop.quantity || 0), 0);
    
            let totalPicked = (stopItem.pickQuantity || 0) + otherStopsTotalQuantity;
    
            if (totalPicked >= stopItem.qtyToPickup) {
                return;
            }
        }

        if (!stopItem.pickQuantity) {
            stopItem.pickQuantity = 1;
        } else {
            stopItem.pickQuantity++;
        }
    }

    editConfirmStopItem(stopItem: RouteItem, si) {
        const activeModal = this.modalService.open(ConfirmStopItemDialogComponent, {
            scrollable: true,
            size: "lg",
        });
        activeModal.componentInstance.confirmSelectedStopItem = cloneDeep(stopItem);
        activeModal.result.then(
            (result) => {
                if (result) {
                    this.confirmStop.routeItems[si] = result;
                }
            },
            () => { }
        );
    }

    newConfirmStopItem(confirmStop: RouteItemStop) {
        const duration = moment.duration(this.waitingTime || 0, 'milliseconds');
        
        const confirmSelectedStopItem: RouteItem = {
            isNewItem: true,
            isActive: false,
            pickQuantity: 1,
            type: confirmStop.type,
            date: confirmStop.date,
            time: confirmStop.time,
            waitingTime: `${Math.floor(duration.asHours()) || 0}.${duration.minutes() || 0}`,
            freeWaitingTime: this.authService?.user?.company?.maxFreeWaitingTime,
            orderItem: {
                itemName: 'Freight',
                orderItemType: PickupTypeEnum.BOXES_CRATES
            }
        };
        const activeModal = this.modalService.open(ConfirmStopItemDialogComponent, {
            scrollable: true,
            size: "lg",
        });
        activeModal.componentInstance.confirmSelectedStopItem = cloneDeep(confirmSelectedStopItem);
        activeModal.result.then(
            (result) => {
                if (result) {
                    this.confirmStop.routeItems.push(result);
                }
            },
            () => { }
        );

    }


    editReferenceNumber(): void {
        this.isEditReferenceNumber = true;
        this.shouldFocusInput = true;
    }

    async saveConfirmStop(newStatus: RouteItemStatus) {
        const isValidWaitingTime = this.waitingTimeValidationCheck();
        if (!isValidWaitingTime) return
        const duration = moment.duration(this.waitingTime || 0, 'milliseconds');
        this.confirmStop.waitingTime = `${Math.floor(duration.asHours()) || 0}.${duration.minutes() || 0}`

        let isZeroQuantity = false

        if (this.autogenerateReferenceNumber && this.customsReferenceNumber) {
            this.confirmStop.referenceNumber = `${this.autogenerateReferenceNumber}-${this.customsReferenceNumber}`
        } else {
            this.confirmStop.referenceNumber = this.autogenerateReferenceNumber || this.customsReferenceNumber
        }
        isZeroQuantity = some(this.confirmStop.routeItems, (stopItem) => {
            const qty = Number(stopItem.pickQuantity) || 0
            return (stopItem.orderItem.orderItemType !== 'Full Load' && qty === 0)
        })
        if (isZeroQuantity && newStatus == RouteItemStatus.Done) {
            this.helperService.errorMessage('Please set valid quantity')
            return
        }

        this.confirmDisabled = true;
        if (this.confirmStop.arrivalDateTime && newStatus == RouteItemStatus.Done) {
            this.confirmStop.date = this.confirmStop.arrivalDateTime as string;
        }
        const diff = moment(this.confirmStop.departureDateTime).diff(this.confirmStop.arrivalDateTime, 'milliseconds')
        if (diff < 0 || !this.confirmStop.departureDateTime && newStatus == RouteItemStatus.Done) {
            this.helperService.errorMessage(`Departure cannot be scheduled before arrival. Please select a later departure time.`)
            this.confirmDisabled = false;
            return
        }
        this.confirmStop.routeItems.forEach(ri => {
            const diff = ri.pickQuantity - ri.quantity;
            
            ri.quantity = ri.pickQuantity;

            if (ri.type == RouteItemType.Delivery){
                ri.qtyToDeliver = ri.qtyToDeliver + diff;

                const existingItemIndex = this._initialRouteItemsPickQuantity.findIndex(
                    item => item.routeItemId === ri.routeItemId
                );
        
                if (existingItemIndex !== -1) {
                    this._initialRouteItemsPickQuantity[existingItemIndex] = {
                        routeItemId: ri.routeItemId,
                        pickQuantity: ri.pickQuantity
                    };
                } else {
                    this._initialRouteItemsPickQuantity.push({
                        routeItemId: ri.routeItemId,
                        pickQuantity: ri.pickQuantity
                    });
                }
            }
        });

        let files = []
        if (this.confirmStop?.orderStop) {
            for (let index = 0; index < this.attachments.length; index++) {
                const attachment = this.attachments[index];
                if (attachment instanceof File) {
                    const id = this.confirmStop.orderStop?.orderStopId;
                    try {
                        const res = await firstValueFrom(
                            this.attachmentService.uploadAttachment(EntityTypes?.OrderStop, id, attachment)
                        );
                        files.push(res);
                    } catch (err) {
                        this.helperService.errorMessage(err);
                        this.helperService.isLoading = false;
                    }
                }
                else {
                    files.push(attachment);
                }
            }
            this.confirmStop.orderStop.attachments = files || [];
        }

        this.routesService.updateRouteStopStatus(this.routeId, this.confirmStop, newStatus, true, false).then(async (res) => {            
            var currentRouteItems = this.confirmStop.routeItems;

            if (res?.data === true) {
                try {
                    await this.dialogService.confirm({
                        message: 'Would you like to update the invoice with the latest data?',
                        yesText: 'Update',
                        noText: 'Keep Current'
                    })

                    await this.orderService.createInvoice(this.confirmStop.orderStop.orderId)
                } catch (error) {
                }
            }

            var differentRouteItem = newStatus == RouteItemStatus.Done ?
             currentRouteItems.find(x => x.type == RouteItemType.Delivery &&
                this._initialRouteItemsPickQuantity?.find(cri => cri.routeItemId == x.routeItemId)?.pickQuantity != x.pickQuantity) : false;

            if (newStatus == RouteItemStatus.Done){
                this.activeModal.close({ route: res, attachments: files, differentRouteItem });
            } 
            
            if (newStatus == RouteItemStatus.Done){
                this.helperService.successMessage(`Route Stop is ${newStatus}`);
            }
            else {
                this.helperService.successMessage(`Route stop updated`);
            }
            
            this.routesService.reloadRoute = { routeId: this.routeId }

            if (newStatus == RouteItemStatus.Done && this.totalStopsCount != null && this.completedStopsCount != null &&
                (this.completedStopsCount + 1) == this.totalStopsCount) {
                this.dialogService.confirm({
                    title: 'Complete Route Confirmation',
                    message: `Confirm that this is the end of the route and it can be archived.`,
                    yesText: 'Archive',
                    noText: 'Continue the route'
                }).then(() => {
                    this.routesService.updateRouteStatus(this.routeId, "Completed").then(() => {
                        this.routesService.reloadRoutes = true;
                        this.helperService.successMessage("Routes status successfully updated.")
                    }).catch((error) => {
                        this.helperService.errorMessage(error)
                        this.routesService.reloadRoutes = true;
                    }).finally(() => {
                        this.helperService.isLoading = false;
                    })
                }).catch(() => {
                    // Nothing
                })
            }

        }).catch((error) => {
            this.helperService.errorMessage(error);
        })
            .finally(() => {
                this.confirmDisabled = false;
            });
    }


    onChargeTypeSelected(chargeType: ChargeType) {
        if (chargeType.selected && chargeType.qty === 0) {
            if (!chargeType.isMoneyAmount) {
                chargeType.qty = 1;
            } else {
                chargeType.qty = 0;
            }
        }
    }

    setTime(minutes: number, hours = 0) {
        let departureDateTime = null
        if (this.confirmStop.departureDateTime) {
            this.confirmStop.departureDateTime = moment(this.confirmStop.departureDateTime).add(minutes, 'minutes').add(hours, 'hours').toISOString()
        } else if (this.confirmStop.arrivalDateTime) {
            this.confirmStop.departureDateTime = moment(this.confirmStop.arrivalDateTime).add(minutes, 'minutes').add(hours, 'hours').toISOString()
        }
        this.handleChangeDateTime(null);
    }

    getRemainingQty(stopItem) {
        const qtyToPickup = stopItem?.qtyToPickup ?? 0;
        const qtyToDeliver = stopItem?.qtyToDeliver ?? 0;
        const pickQuantity = stopItem?.pickQuantity ?? 0;
        const quantity = stopItem?.quantity ?? 0;

        if(stopItem.type == RouteItemType.Pickup){
            return pickQuantity - qtyToDeliver;
        }
        else {
            return qtyToPickup - (pickQuantity + (qtyToDeliver - quantity));
        }
    }

    getPickupStatus(stopItem) {
        const pickupStop = stopItem?.orderItemStops?.find(
            x => x.orderStop?.orderStopType === OrderStopType.Pickup
        );
    
        if (!pickupStop) {
            return null;
        }

        return this.activities.find(
            a => a.orderStopId === pickupStop.orderStopId
        )?.type ?? null;
    }

    getActivityDotColor(stopItem): string {
        let type = this.getPickupStatus(stopItem);
        switch (type) {
            case 'Unassigned':
                return 'text-danger';
            case 'Assigned':
                return 'text-primary';
            case 'Confirmed':
                return 'text-success';
        }
    }

    displayUnallocatedItems() {
        this.showUnallocatedItemsTable = true;;
    }

    loadUnallocatedItems(){
        this.quoteService.get(this.confirmStop?.orderStop?.orderId).then(res => {
            this.order = res;
            const routeItemIds = this.confirmStop.routeItems.map(item => item.orderItem.orderItemId);
            const unallocatedOrderItems = this.order.orderItems.filter(orderItem =>
                !routeItemIds.includes(orderItem.orderItemId)
            );

            let unallocatedItems = unallocatedOrderItems.map(x => {

                const pickupOrderItemStops = x.orderItemStops
                    .filter(stop => stop.orderStop?.orderStopType === OrderStopType.Pickup);

                const pickupQuantity = pickupOrderItemStops.reduce((sum, stop) => sum + stop.quantity, 0);

                const deliveryQuantity = x.orderItemStops
                    .filter(stop => stop.orderStop?.orderStopType === OrderStopType.Delivery)
                    .reduce((sum, stop) => sum + stop.quantity, 0);

                return {
                    orderItem: x,
                    pickQuantity: deliveryQuantity,
                    qtyToPickup: pickupQuantity,
                    isActive: true,
                    type: RouteItemType.Delivery,
                    displayStatus: RouteItemDisplayStatus.OnTime,
                    toPickUp: false,
                    dateType: this.confirmStop?.orderStop.dateType,
                    date: this.confirmStop?.orderStop.date,
                    groupDate: this.confirmStop?.groupDate,
                    timeType: this.confirmStop?.orderStop.timeType,
                    time: this.confirmStop?.orderStop.time,
                    address: {
                        fullAddress: this.confirmStop?.orderStop?.address?.fullAddress
                    },
                    orderItemStops: pickupOrderItemStops
                };
            });


            this.confirmStop["unallocatedItems"] = unallocatedItems.filter(x => this.getRemainingQty(x) > 0);

            
        })
    }

    async addConfirmStopItem(stopItem) {
        this.loading = true;
        const confirmStop = this.confirmStop;
        const orderStop = this.confirmStop.orderStop;
        const orderStopId = confirmStop?.orderStop.orderStopId;

        const orderItemStop = {
            quantity: this.getRemainingQty(stopItem),
            orderItemStopId: 0,
            orderStop: orderStop,
            orderItem: stopItem.orderItem,
            orderStopId: orderStopId
        };

        this.orderService.updateOrderItemStopQuantity(this.order, orderItemStop, orderItemStop.quantity);

        this.order.orderStops.find(x => x.orderStopId == orderStopId).orderItemStops.push(omit(cloneDeep(orderItemStop), 'orderItemStopId'));
        var updatedOrder = await this.orderService.saveOrder(this.order);
        var updatedOrderItem = updatedOrder.orderItems.find(x => x.orderItemId == stopItem.orderItem.orderItemId);
        var updatedOrderItemStop = updatedOrder.orderStops.find(x => x.orderStopId == orderStopId).orderItemStops
            .find(x => x.orderItemId == stopItem.orderItem.orderItemId);

        const quantity = this.getRemainingQty(stopItem);

        let newRouteStopGroup = {
            routeId: this.routeId,
            date: stopItem.groupDate,
            routeItemStops: [{
                type: orderStop.orderStopType,
                displayStatus: RouteItemDisplayStatus.OnTime,
                dateType: orderStop.dateType,
                date: stopItem.groupDate,
                timeType: orderStop.timeType,
                time: orderStop.time,
                address: {
                    fullAddress: orderStop.address.fullAddress
                },
                routeItems: [{
                    quantity: quantity,
                    date: stopItem.groupDate,
                    orderItem: updatedOrderItem,
                    orderItemStop: updatedOrderItemStop
                }]
            }]
        };

        await this.routesService.updateRouteStops(this.routeId, [newRouteStopGroup])

        this.routesService.reloadRoute = { routeId: this.routeId }

        let updatedConfirmStop = this.confirmStop;
        updatedConfirmStop.routeItems.push({
            ...stopItem,
            orderStopId: orderStop.orderStopId,
            orderItemId: stopItem.orderItem.orderItemId,
            pickQuantity: quantity,
            quantity: quantity,
            qtyToDeliver: quantity
        });

        updatedConfirmStop["unallocatedItems"] = updatedConfirmStop["unallocatedItems"].filter(x =>
            x.orderItem.orderItemId != stopItem.orderItem.orderItemId);

        this.confirmStop = updatedConfirmStop;
    }

    viewAttachmentsModal() {
        const activeModal = this.modalService.open(FilesViewerModalComponent, {
            scrollable: true,
            size: 'xl',
            centered: true,
        });
        activeModal.componentInstance.isAttachments = true;
        activeModal.componentInstance.files = this.allAttachments;
    }
}
