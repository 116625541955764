import { Component, Input, OnInit } from '@angular/core';
import { FormValidateDirective } from '@app/@shared/form-validate/form-validate.directive';
import { HelperService } from '@app/shared/services/helper.service';
import { RoutesService } from '@app/shared/services/router.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { object } from 'yup';
import moment from "moment"

@Component({
  standalone: false,
  selector: 'app-assign-transfer-dialog',
  templateUrl: './assign-transfer-dialog.component.html',
  styleUrls: ['./assign-transfer-dialog.component.scss']
})
export class AssignTransferDialogComponent implements OnInit {

  isStopItemsCollapse: boolean = true;
  @Input() routeTransfer: any;
  @Input() stopStatusTypes: any;

  validationSchema = object().shape({});
  submitLoading: boolean;

  constructor(
    public helperService: HelperService,
    public activeModal: NgbActiveModal,
    public routesService: RoutesService,
  ) { }

  ngOnInit(): void {
  }

  async handleInputChange(event: any, form: FormValidateDirective, key: string) {
    this.routeTransfer[key] = await event;
    form = await this.checkValidations(form);
    form.errors$.next(form.errors);
  }

  checkValidations(form: FormValidateDirective) {
    form.errors = {};

    if (this.routeTransfer?.address?.fullAddress) {
      delete form.errors['fullAddress'];
    } else {
      form.errors['fullAddress'] = ["Location is a required field"];
    }

    this.validateDateTime(form, 'deliveryDate', 'dropOffDateAndTime');
    this.validateDateTime(form, 'pickupDate', 'PickUpDateAndTime');
    this.validatePickupAfterDropOff(form);

    return form;
  }

  validateDateTime(form: FormValidateDirective, dateKey: string, errorKey: string) {
    if (this.routeTransfer[dateKey]) {
      delete form.errors[errorKey];
    } else {
      form.errors[errorKey] = [];
      form.errors[errorKey].push(`${dateKey} is a required field`);
    }
  }

  validatePickupAfterDropOff(form: FormValidateDirective) {
    if (this.routeTransfer.deliveryDate && this.routeTransfer.DeliveryTime && this.routeTransfer.pickupDate && this.routeTransfer.PickupTime) {
      const deliveryDateTime = moment(this.routeTransfer.deliveryDate).set({
        hour: moment(this.routeTransfer.DeliveryTime).hour(),
        minute: moment(this.routeTransfer.DeliveryTime).minute(),
        second: moment(this.routeTransfer.DeliveryTime).second()
      });
      const pickupDateTime = moment(this.routeTransfer.pickupDate).set({
        hour: moment(this.routeTransfer.PickupTime).hour(),
        minute: moment(this.routeTransfer.PickupTime).minute(),
        second: moment(this.routeTransfer.PickupTime).second()
      });
      if (pickupDateTime.isBefore(deliveryDateTime)) {
        form.errors['PickUpDateAndTime'] = ["Pickup date and time must be after drop off date and time"];
      } else {
        delete form.errors['PickUpDateAndTime'];
      }
    }
  }

  selectWarehouse(warehouse) {
    this.routeTransfer.address = warehouse?.address;
    this.routeTransfer.addressId = warehouse?.addressId;
    this.routeTransfer.warehouseId = warehouse?.warehouseId;
    this.routeTransfer.warehouse = warehouse;
  }

  async saveTransfer(form: FormValidateDirective) {
    form = await this.checkValidations(form);
    if (Object.keys(form.errors).length > 0) {
      form.errors$.next(form.errors);
    } else {
      this.submitLoading = true;
      this.routesService.createTransfer(this.routeTransfer).then((res) => {
        this.helperService.successMessage("New Transfer Successfully Created.")
        this.routesService.reloadRoutes = true;
        this.activeModal.close(true);
        this.submitLoading = false;
      }).catch((error) => {
        this.submitLoading = false;
        this.helperService.errorMessage(error)
      })
    }
  }

  close() {
    this.activeModal.close();
  }

}
