import { Company } from '@models/company.model';
import { Route } from '@models/route.model';
import { VehicleLocation } from '@models/samsara/vehicle-location.model';
import { BaseModel } from '@models/base.model';
import { Vehicle } from './samsara/vehicle.model';

export interface Truck extends BaseModel {
    truckId?: number;
    companyId?: number;
    company?: Company;
    name?: string;
    plateNumber?: string;
    status?: string;
    type?: string;
    routes?: Route[];
    createdTime?: string;
    createdUser?: string;
    updatedTime?: string;
    updatedUser?: string;
    samsaraId?: number;
    location?: VehicleLocation;
    vehicle?: Vehicle;
}

export enum TruckStatusEnum {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive',
    DELETED = 'Deleted'
}