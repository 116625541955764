import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validator,
  ValidationErrors,
  AbstractControl,
} from '@angular/forms';
import { getCountryWith } from '@app/data/country-with-mask-and-code';
import { environment } from '@environments/environment';

interface ICountryWithCode {
  isoCode: string;
  name: string,
  mask: string;
  phoneCode: string;
  flagName: string;
}

@Component({
  standalone: false,
  selector: 'app-phone-with-code-input',
  templateUrl: './phone-with-code-input.component.html',
  styleUrls: ['./phone-with-code-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneWithCodeInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PhoneWithCodeInputComponent),
      multi: true,
    },
  ],
})
export class PhoneWithCodeInputComponent implements OnInit, ControlValueAccessor, Validator {
  isInvalidPhone = false;
  countryList: ICountryWithCode[] = [];
  filteredCountries: ICountryWithCode[] = [];
  selectedCountry: ICountryWithCode;
  searchTerm: ''
  tempPhoneCountryCode: string
  s3BaseUrl = environment.s3BaseUrl
  isInitializing = true;
  isTouched = false;
  // Inputs
  @Input() phoneNumber = null;
  @Input() phonePlaceholder = 'Enter phone number';
  @Input() disabled: boolean = false;
  @Input() phoneCountryIso: string = 'US'
  @Input() phoneCountryCode: string = '1'

  // Outputs
  @Output() phoneNumberChange = new EventEmitter<string>();
  @Output() phoneCountryCodeChange = new EventEmitter<any>();
  @Output() phoneCountryIsoChange = new EventEmitter<any>();
  @Output() validationChange = new EventEmitter<ValidationErrors | null>();
  @Output() onBlur = new EventEmitter<any>();

  // ViewChild
  @ViewChild('intlInput') intlInput: ElementRef;
  @ViewChild('searchInput') searchInput: ElementRef;

  private onChange: (value: string) => void = () => { };
  private onTouched: () => void = () => { };

  constructor(
  ) {
    this.countryList = getCountryWith()
    this.filteredCountries = [...this.countryList]
  }

  ngOnInit(): void { }

  // Write value from the parent component
  writeValue(value: string): void {
    if (value !== undefined && value !== null) {
      this.handleInitiallySetData(value);
      this.onChange(value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Custom validator
  validate(control: AbstractControl): ValidationErrors | null {
    if (!this.isTouched) {
      return null;
    }

    const isValid = this.checkPhoneValidity();
    let errors = isValid ? null : { invalidPhone: 'Invalid phone number' };

    if (!this.selectedCountry) {
      errors = { invalidPhone: 'Please select a valid country' };
    }

    this.validationChange.emit(errors);
    return errors;
  }

  handleInitiallySetData(value) {
    if (this.phoneCountryIso) {
      this.selectedCountry = this.countryList.find(({ isoCode }) => isoCode == this.phoneCountryIso);
      this.tempPhoneCountryCode = this.selectedCountry?.phoneCode?.replace(/\D/g, '');
    } else if (this.phoneCountryCode) {
      this.tempPhoneCountryCode = this.phoneCountryCode;
      this.selectedCountry = this.countryList.find(({ phoneCode }) => phoneCode.replace(/\D/g, '') == this.phoneCountryCode);
    }

    if (!this.selectedCountry) {
      this.phoneCountryCode = '1';
      this.tempPhoneCountryCode = '1';
      this.phoneCountryIso = 'US';
      this.selectedCountry = this.countryList.find(({ isoCode }) => isoCode == 'US')
    }

    this.phoneNumber = this.getPhoneNumber(value, this.selectedCountry?.mask || '');
    this.onChange(this.phoneNumber); // Ensure Angular form control gets updated
  }

  getFlagUrl(flagName?: string): string | null {
    return flagName ? `${this.s3BaseUrl}/flags/${flagName}.svg` : null;
  }

  getPhoneNumber(phone, mask?: string) {
    phone = phone ? phone.replace(/\D/g, '') : ''
    if (mask) {
      const maxCountOfNumber = mask?.replace(/\D/g, '').length
      if (phone?.length != maxCountOfNumber) {
        phone = phone ? phone.slice(0, maxCountOfNumber) : ''
      }
    }
    return phone
  }

  handleChangeValue() {
    if (this.onChange) {
      this.onChange(this.phoneNumber);
    }
    this.phoneNumberChange.emit(this.phoneNumber);
  }

  onDropdownToggle(isOpen) {
    if (isOpen) {
      setTimeout(() => {
        this.searchInput.nativeElement.focus();
      }, 50);
    }
  }

  onFilterCountry(term = '') {
    term = this.searchTerm?.toLowerCase();
    if (!term) {
      this.filteredCountries = this.countryList
    } else {
      this.filteredCountries = this.countryList.filter((country) => {
        return (
          country.name.toLowerCase().includes(term) ||
          country.phoneCode.replace(/\D/g, '').includes(term) ||
          country.phoneCode.includes(`+${term}`) ||
          country.isoCode.toLocaleLowerCase().includes(term)
        )
      })
    }
  }

  handlePhoneValueChange(event) {
    this.handleChangeValue()
  }

  onSelectCountry(country: ICountryWithCode, isFocus = true) {
    this.onTouched()
    this.isTouched = true;
    this.selectedCountry = country || null;
    const phoneCountryCode = country?.phoneCode ? country?.phoneCode?.replace(/\D/g, '') : null;
    this.phoneCountryIso = country ? country.isoCode : '';

    if (country?.isoCode && isFocus && this.intlInput) { // Not need to now
      const inputElement = this.intlInput.nativeElement;
      inputElement.focus()
    }

    if (phoneCountryCode) {
      this.tempPhoneCountryCode = phoneCountryCode
    }

    // Change phone number base on country mask 
    const updatedPhoneNumber = this.getPhoneNumber(this.phoneNumber, this.selectedCountry?.mask)
    
    // emit out event 
    this.phoneNumber = updatedPhoneNumber
    this.phoneCountryIsoChange.emit(this.phoneCountryIso)
    this.phoneCountryCodeChange.emit(phoneCountryCode)
    // set change event
    this.handleChangeValue()
  
    // Reset filter country list
    this.searchTerm = ''
    this.onFilterCountry()
  }

  handleBlur() {
    this.isTouched = true;
    this.onTouched()
    this.handleChangeValue()
  }

  handleInputCountryCode(value) {
    this.tempPhoneCountryCode = value
    if (value) {
      const selectedCountry = this.countryList.find(({ phoneCode }) => phoneCode.replace(/\D/g, '') == value)
      if (selectedCountry) {
        this.onSelectCountry(selectedCountry, false)
      } else {
        this.onSelectCountry(null)
      }
    } else {
      this.onSelectCountry(null)
    }
  }


  checkPhoneValidity() {
    // Validate phone number against mask
    let isValid = true
    if (this.selectedCountry && this.phoneNumber) {
      const maskNumericValue = this.selectedCountry?.mask.replace(/\D/g, ''); // Remove non-numeric characters
      isValid = maskNumericValue.length === this.phoneNumber.length
    } else if (!this.selectedCountry && this.phoneNumber) {
      isValid = false
    }
    return isValid
  }
}