import { Component, EventEmitter, Input, Output } from '@angular/core';
import { cloneDeep, has, sumBy, uniqBy } from 'lodash';
import { OrderStopTypeEnum } from '@app/models/order-stop.model';
import { Clipboard } from '@angular/cdk/clipboard';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderService } from '@app/shared/services/order.service';
import { UtilService } from '@app/services/util.service';


@UntilDestroy()
@Component({
    standalone: false,
    selector: 'app-unassigned-trip-list-item',
    templateUrl: './unassigned-trip-list-item.component.html',
    styleUrls: ['./unassigned-trip-list-item.component.scss']
})
export class UnassignedTripListItemComponent {

    @Input() stop: any;
    @Output() onDelete = new EventEmitter();

    displayRoutesStops: any
    OrderStopTypeEnum = OrderStopTypeEnum
    total: number;
    isFragile: boolean;
    isRefrigerated: boolean;
    isHazardous: boolean;
    isOverweight: boolean;
    isOther: boolean;
    isCollapsed = true;
    stopCount = 0;
    tooltipHovered = false;

    constructor(
        private clipboard: Clipboard,
        private orderService: OrderService,
        public util: UtilService,
    ) {
    }

    get formattedSizeAndWeight() {
        return this.stop?.items.reduce((acc, orderItem) => {
            if (orderItem?.orderItemId) {
                acc[orderItem.orderItemId] = [this.util.getFormattedMeasurements(orderItem), this.util.getFormattedWeight(orderItem, orderItem.pickQuantity)].filter(Boolean).join(' | ')
            }
            return acc;
        }, {} as Record<string, string>);
    }

    ngOnInit() {
        const stop = cloneDeep(this.stop)
        this.total = sumBy(stop.items, (item: any) => Number(item.pickQuantity))
        this.stopCount = stop?.uniqPickupRouteBoxes?.length + stop?.uniqDropOffRouteBoxes?.length
        this.displayRoutesStops = uniqBy(stop.items, 'orderItemId')
        this.handleMapStopItems()
        this.stop.items.forEach((item) => {
            if (item.isFragile) {
                this.isFragile = true
            }
            if (item.isRefrigerated) {
                this.isRefrigerated = true
            }
            if (item.isHazardous) {
                this.isHazardous = true
            }
            if (item.isOverweight) {
                this.isOverweight = true
            }
            if (item.isOther) {
                this.isOther = true
            }
        });

        this.orderService.orderStopPriority$
            .pipe(
                untilDestroyed(this),
            )
            .subscribe((orderStopPriority) => {
                if (orderStopPriority?.orderStopId && orderStopPriority?.priority) {
                    if (this.stop?.uniqPickupRouteBoxes?.length > 0) {
                        this.stop?.uniqPickupRouteBoxes?.map((box) => {
                            if (box?.orderStopId === orderStopPriority?.orderStopId) {
                                box.orderStop.priority = orderStopPriority.priority
                            }
                            return box;
                        })
                    }
                    if (this.stop?.uniqDropOffRouteBoxes?.length > 0) {
                        this.stop?.uniqDropOffRouteBoxes?.map((box) => {
                            if (box?.orderStopId === orderStopPriority?.orderStopId) {
                                box.orderStop.priority = orderStopPriority.priority
                            }
                            return box;
                        })
                    }
                }
            })

            this.orderService.orderPriority$
            .pipe(
              untilDestroyed(this),
            )
            .subscribe((orderPriority) => {
              if (orderPriority?.orderId && orderPriority?.priority) {
                if (this.stop?.orderId === orderPriority?.orderId) {
                  this.stop.priority = orderPriority?.priority;
                }
              }
            })
    }

    handleMapStopItems() {
        const stop = cloneDeep(this.stop)
        const pickupItems = stop?.uniqPickupRouteBoxes
        const deliveryItems = stop?.uniqDropOffRouteBoxes
        this.displayRoutesStops = this.displayRoutesStops.map((item) => {
            item.displayOrderItems = pickupItems.concat(deliveryItems)

            if (item.displayOrderItems?.length > 3 && this.isCollapsed) {
                let displayOrderItems = []
                if (pickupItems?.length > 0) {
                    const sliceItems = deliveryItems?.length === 0 ? 3 : pickupItems?.length > 1 ? 2 : 1
                    const items = pickupItems.slice(0, sliceItems)
                    displayOrderItems = displayOrderItems.concat(items)
                }
                if (deliveryItems?.length > 0) {
                    const sliceItems = 3 - displayOrderItems?.length
                    const items = deliveryItems.slice(0, sliceItems)
                    displayOrderItems = displayOrderItems.concat(items)
                }
                item.displayOrderItems = displayOrderItems
            }
            return item
        })
    }

    handleToggleCollapse($event) {
        if (this.stopCount > 3) {
            $event.stopPropagation()
            $event.preventDefault()
            this.isCollapsed = !this.isCollapsed
            this.handleMapStopItems()
        }

    }

    handleDelete() {
        this.onDelete.emit();
    }

    openTooltip($event, tooltip, text) {
        $event?.stopPropagation()
        $event?.preventDefault()
        if (!tooltip.isOpen() && text) {
            tooltip.open({ text, tooltip });
        }
    }

    closeTooltip(tooltip, forceClose = false) {
        if (forceClose) {
            this.tooltipHovered = false
        }
        setTimeout(() => {
            if (!this.tooltipHovered) {
                if (tooltip.isOpen()) {
                    tooltip.close();
                }
            }
        }, 200);
    }

    copyToClipboard($event, tooltip, text) {
        $event?.stopPropagation()
        $event?.preventDefault()
        this.clipboard.copy(text)
        this.closeTooltip(tooltip, true)
    }
}
