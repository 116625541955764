import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ShipmentType } from '@models/shipment-type.model';
import { ShipmentTypeService } from '@app/shared/services/shipment-type.service';
import { HelperService } from '@app/shared/services/helper.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShipmentTypeDetailsComponent } from '../shipment-type-details/shipment-type-details.component';
import { DatatableComponent } from '@app/@shared/datatable';
import { AppService } from '@app/shared/services/app.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime } from 'rxjs/operators';

@UntilDestroy()
@Component({
  standalone: false,
    selector: 'app-shipment-type-list',
    templateUrl: './shipment-type-list.component.html',
    styleUrls: ['./shipment-type-list.component.scss'],
})
export class ShipmentTypeListComponent implements OnInit, OnDestroy {
    title = "Shipment Type"
    @ViewChild('datatable') datatable: DatatableComponent
    searchDate: Date;
    constructor(
        private shipmentTypeService: ShipmentTypeService,
        private helperService: HelperService,
        private router: Router,
        private ngbModal: NgbModal,
        private appService: AppService,
    ) {
    }

    ngOnInit() {
        this.appService.$searchTerm
            .pipe(
                untilDestroyed(this),
                debounceTime(500)
            )
            .subscribe((text) => {
                this.datatable?.setSearchValue(text);
            })
    }

    ngOnDestroy() {
        this.appService.searchTerm = ''
    }

    getData = (request) => {
        this.shipmentTypeService.cancelPendingRequestGetAll()
        return this.shipmentTypeService.getAllWithCancelRequest(request)
    }

    openAddEdit(item?: ShipmentType) {
        const ref = this.ngbModal.open(ShipmentTypeDetailsComponent, {
            size: 'xl',
            scrollable: true,
        })
        ref.componentInstance.item = item

        ref.closed.subscribe((isReload) => {
            if (isReload) {
                this.datatable?.refresh()
            }
        })
    }
    dateUpdated($event) {

    }
    onDelete(entity: ShipmentType) {
        this.shipmentTypeService.delete(entity.shipmentTypeId).then(() => {
            this.helperService.successMessage(`${this.title} Deleted Successfully`);
            this.datatable?.refresh()
        }).catch(error => {
            this.helperService.errorMessage(error);
        });

    }
}
