import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountingRoutingModule } from './accounting-routing.module';
import { AccountingListComponent } from './accounting-list/accounting-list.component';
import { AccountingDetailsComponent } from './accounting-details/accounting-details.component';
import { AddEditAccountingInvoiceComponent } from './add-edit-accounting-invoice/add-edit-accounting-invoice.component';
import { ReceivePaymentComponent } from './add-payment/receive-payment.component';
import { SharedModule } from '@app/shared/shared.module';
import { DatatableModule } from '@app/@shared/datatable';
import { DialogsModule } from '@app/@shared/dialogs/dialogs.module';
import { NgxSelectModule } from '@app/@shared/ngx-select';
import { CustomerListComponent } from './customer-list/customer-list.component';
import { FiltersComponent } from './filters/filters.component';
import { RepeaterModule } from '@app/@shared/repeater/repeater.module';
import { CustomerSelectModule } from '@app/@shared/customer-select/customer-select.module';
import { DocumentViewerModule } from '@app/@shared/document-viewer/document-viewer.module';
import { RatingModule } from '@app/@shared/rating/rating.module';
import { NgbPaginationModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { FormValidateModule } from '@app/@shared/form-validate/form-validate.module';
import { AttachmentsModule } from '@app/@shared/attachments/attachments.module';
import { AccountingInvoicePreviewComponent } from './accounting-invoice-preview/accounting-invoice-preview.component';
import { FileUploadModule } from '@app/@shared/file-upload/file-upload.module';
import { FormsModule } from '@angular/forms';
import { AttachmentPreviewModule } from '@app/@shared/attachment-preview/attachment-preview.module';
import { AddressLookupModule } from '@app/@shared/address-lookup/address-lookup.module';
import { PreviousNextRedirectButtonsModule } from '@app/@shared/previous-next-redirect-buttons/previous-next-redirect-buttons.module';
import { InvoicePaymentsModule } from '@app/@shared/invoice-payments/invoice-payments.module';
import { AccountingLayoutComponent } from './accounting-layout/accounting-layout.component';
import { ListTabsModule } from '@app/@shared/list-tabs/list-tabs.module';
import { FeesTypeDropdownComponent } from './add-edit-accounting-invoice/fees-type-dropdown/fees-type-dropdown.component';
import { QuickBooksEmailIconComponent } from './quick-books-email-icon/quick-books-email-icon.component';
import { DropDownSelectModule } from '@app/@shared/drop-down-select/drop-down-select.module';
import { DriverSelectModule } from '@app/@shared/driver-select/driver-select.module';
import { FilesViewerModalModule } from '@app/@shared/files-viewer-modal/files-viewer-modal.module';


@NgModule({
  declarations: [
    AccountingListComponent,
    AccountingDetailsComponent,
    AddEditAccountingInvoiceComponent,
    ReceivePaymentComponent,
    CustomerListComponent,
    FiltersComponent,
    AccountingInvoicePreviewComponent,
    AccountingLayoutComponent,
    FeesTypeDropdownComponent,
    QuickBooksEmailIconComponent
  ],
  imports: [
    CommonModule,
    AccountingRoutingModule,
    SharedModule,
    DatatableModule,
    DialogsModule,
    NgxSelectModule,
    RepeaterModule,
    CustomerSelectModule,
    DocumentViewerModule,
    RatingModule,
    NgbPaginationModule,
    FormValidateModule,
    AttachmentsModule,
    FileUploadModule,
    FormsModule,
    AttachmentPreviewModule,
    AddressLookupModule,
    PreviousNextRedirectButtonsModule,
    InvoicePaymentsModule,
    ListTabsModule,
    NgbTypeaheadModule,
    DropDownSelectModule,
    DriverSelectModule,
    FilesViewerModalModule,
  ],
  exports: [
    CustomerListComponent
  ]
})
export class AccountingModule { }
