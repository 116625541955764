import { Component, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ListTabsComponent } from '@app/@shared/list-tabs/list-tabs.component';
import { HelperService } from '@app/shared/services/helper.service';
import { Driver } from '@app/models/driver.model';
import { DriverService } from '@app/shared/services/driver.service';

@UntilDestroy()
@Component({
  	standalone: false,
	selector: 'app-driver-layout',
	templateUrl: './driver-layout.component.html',
	styleUrls: ['./driver-layout.component.scss'],

})

export class DriverLayoutComponent {

	@ViewChild('listTabs') listTabs: ListTabsComponent;

	constructor(
		private helperService: HelperService,
		private driverService: DriverService,
	) {

	}

	getTabTitle = (value: Driver, key) => {
		return value?.fullName || 'Loading...';
	}

	updateTabData(data) {
		const tabId = this.listTabs.activeTab;
		this.listTabs.setTab(tabId, { data });
	}

	loadTabData = async (driverId: string) => {
		return await this.driverService.get(driverId).then((res) => {
			return res;
		}).catch(() => {
			this.helperService.errorMessage("Drivers not found.")
			this.listTabs.closeTab(driverId, true);
		})
	}
}