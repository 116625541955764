import { Component, forwardRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { OrderStatusEnum } from '@app/models/order.model';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  	standalone: false,
	selector: 'app-order-status-select',
	templateUrl: './order-status-select.component.html',
	styleUrls: ['./order-status-select.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => OrderStatusSelectComponent),
			multi: true
		}
	],
	encapsulation: ViewEncapsulation.Emulated,
})
export class OrderStatusSelectComponent implements OnInit {

	// default enabled dropdown for customer multiple select
	disabled: boolean = false;

	public OrderStatusEnum: typeof OrderStatusEnum = OrderStatusEnum;
	public options: any = [];

	private _status: string | any;
	public get status(): string | any {
		return this._status;
	}
	public set status(value: string | any) {
		this._status = value;
		this.onChange(value);
		this.onTouched();
	}

	/*
	* Getter & Setter for multiple selection
	*/
	_multiple: boolean = true;
	get multiple(): boolean {
		return this._multiple;
	}
	@Input() set multiple(value: boolean) {
		this._multiple = value;
	}

	/*
	* Getter & Setter for dynamic placeholder
	*/
	_placeholder: string;
	get placeholder(): string {
		return this._placeholder;
	}
	@Input() set placeholder(value: string) {
		this._placeholder = value;
	}

	/*
	* Getter & Setter for check order
	*/
	_isOrder: boolean;
	get isOrder(): boolean {
		return this._isOrder;
	}
	@Input() set isOrder(value: boolean) {
		this._isOrder = value;
		this.generateOptions();
	}

	onChange = (value: string) => { };
	onTouched = () => { };

	constructor() { }

	ngOnInit(): void { }

	writeValue(status: string) {
		this._status = status
	}

	registerOnChange(fn: (value: string) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	/**
	 * Generate Options
	 * 
	 * @returns 
	 */
	generateOptions() {
		this.options = [
			...(this.isOrder) ? [
				{ value: OrderStatusEnum.ORDER, name: 'Order' },
				{ value: OrderStatusEnum.CLOSED, name: 'Closed' }
			] : [
				{ value: OrderStatusEnum.DRAFT, name: 'Draft' },
				{ value: OrderStatusEnum.QUOTE, name: 'Quote' }
			]
		];
	}
}
