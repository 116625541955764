// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .charge-type-datatable {
  height: calc(100vh - 290px);
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/settings/charge-types/charge-type-list/charge-type-list.component.scss"],"names":[],"mappings":"AACI;EACI,2BAAA;AAAR","sourcesContent":[":host {\n    .charge-type-datatable {\n        height: calc(100vh - 290px);\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
