import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Company } from '@app/models/company.model';
import { UtilService } from 'src/app/services/util.service';
import { find, findIndex, omit } from 'lodash';
import { HelperService } from '@app/shared/services/helper.service';
import { AuthService } from '@app/shared/services/auth/auth.service';
import { CompanyService } from '@app/shared/services/company.service';
import { TermsAndConditionsService } from '@app/shared/services/terms-and-conditions.service';
import { TermsAndCondition } from '@app/models/terms-and-condition.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddTermsConditionsDialogComponent } from './add-terms-conditions-dialog/add-terms-conditions-dialog.component';

@Component({
    standalone: false,
    selector: 'app-settings-terms-conditions',
    templateUrl: './terms-conditions.component.html',
    styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {
    company: Company = {
        termsAndConditions: '',
    };

    selectedTermsAndCondition: TermsAndCondition = {};
    title = "Terms & Conditions"
    submitLoading = false
    isSubmitBtn: boolean;
    termsAndConditions: TermsAndCondition[];
    editingTabs = {};


    private _activeTab: any = 'general';
    public get activeTab(): any {
        return this._activeTab;
    }
    public set activeTab(value: any) {
        this._activeTab = value;
        this.selectedTermsAndCondition = find(this.termsAndConditions, { termsAndConditionsId: value }) || {} as any
    }

    constructor(
        public util: UtilService,
        private ngbModal: NgbModal,
        private authService: AuthService,
        private helperService: HelperService,
        private companyService: CompanyService,
        private termsAndConditionsService: TermsAndConditionsService,
        private readonly cdr: ChangeDetectorRef,
    ) {
    }

    ngOnInit() {
        this.getCompany();
        this.getTermsAndConditions();
    }

    getTermsAndConditions() {
        this.termsAndConditionsService.getAll({
            page: 1,
            itemsPerPage: 20,
        }).then((res) => {
            this.termsAndConditions = res
        })
    }

    getCompany() {
        this.companyService.get().then((resp: any) => {
            this.company = resp
        });
    }

    changeGeneralValue(e) {
        this.isSubmitBtn = true
    }

    changeValue(e) {
        this.isSubmitBtn = true
    }

    addTermsAndConditions() {
        const ref = this.ngbModal.open(AddTermsConditionsDialogComponent, {
            size: 'xs',
            scrollable: true,
        })
        ref.closed.subscribe((res: TermsAndCondition) => {
            if (res) {
                this.termsAndConditions.push(res)
                this.activeTab = res.termsAndConditionsId
                this.isSubmitBtn = false
                this.handleToggleEdit(this.activeTab)
                this.cdr.detectChanges();
            }
        })
    }

    handleToggleEdit(tab: string) {
        this.editingTabs[tab] = {
            isEditing: !this.editingTabs[tab]?.isEditing,
            originalValue: tab === 'general' ? this.company.termsAndConditions : this.selectedTermsAndCondition?.content || '',
        }
    }

    resetEdit(tab: string) {
        const orgData = this.editingTabs[tab]
        if (tab === 'general') {
            this.company.termsAndConditions = orgData.originalValue
        } else {
            this.selectedTermsAndCondition.content = orgData.originalValue
        }
        this.handleToggleEdit(tab)

    }

    onDelete() {
        this.helperService.isLoading = true;
        this.termsAndConditionsService.delete(this.selectedTermsAndCondition?.termsAndConditionsId)
            .then(() => {
                this.activeTab = 'general';
                this.getTermsAndConditions();
                delete this.editingTabs[this.activeTab]
                this.cdr.detectChanges();
                this.helperService.successMessage("Terms and Conditions successfully deleted");
            })
            .catch((error) => {
                this.helperService.errorMessage(error)
            }).finally(() => {
                this.helperService.isLoading = false;
            })

    }

    onSubmit() {
        this.submitLoading = true;
        if (this.activeTab === 'general') {
            this.companyService.update(omit(this.company, 'companyId')).then((resp: any) => {
                this.company = resp
                this.helperService.successMessage("Terms and Conditions successfully updated")
                this.authService.user = {
                    ...this.authService.user,
                    company: this.company,
                }
                this.submitLoading = false;
                this.isSubmitBtn = false

                this.handleToggleEdit(this.activeTab)
            }).catch((error) => {
                this.helperService.errorMessage(error)
                this.submitLoading = false;
            });
        } else {

            const request = this.selectedTermsAndCondition

            this.termsAndConditionsService.update(request.termsAndConditionsId, request).then((res) => {
                const index = findIndex(this.termsAndConditions, { termsAndConditionsId: this.selectedTermsAndCondition.termsAndConditionsId })
                this.termsAndConditions.splice(index, 1, res);
                this.activeTab = res.termsAndConditionsId
                this.helperService.successMessage("Terms and Conditions successfully updated")
                this.isSubmitBtn = false
                this.submitLoading = false;

                this.handleToggleEdit(this.activeTab)
            }).catch((error) => {
                this.helperService.errorMessage(error)
                this.submitLoading = false;
            });
        }
    }
}
