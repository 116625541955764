import { Component, ElementRef, forwardRef, HostBinding, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ImageUploadTypeEnum } from '@app/models/image.model';
import { HelperService } from '@app/shared/services/helper.service';
import { ImagesService } from '@app/shared/services/images.service';

type FileValue = File | string

@Component({
  standalone: false,
  selector: 'app-avatar-upload',
  templateUrl: './avatar-upload.component.html',
  styleUrls: ['./avatar-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AvatarUploadComponent),
      multi: true
    }
  ]
})
export class AvatarUploadComponent implements OnInit {
  disabled: boolean;
  onChange: any = () => { };
  onTouched: any = () => { };
  previewUrl: string | SafeUrl;

  @ViewChild('fileInput') fileInput: ElementRef<any>;
  @Input() upload = false;
  @Input() uploadType: ImageUploadTypeEnum;
  @Input() type: 'user' | 'company' = 'user';
  @Input() name;
  @Input() size: 'small' | 'medium' | 'large' | 'extra-large' = 'medium';
  @Input() isEdit: boolean = true;

  private _value: FileValue;
  uploading: boolean;

  get value(): FileValue {
    return this._value;
  }
  set value(updateValues: FileValue) {
    this._value = updateValues;
    this.setFilePreview()
    this.onChange(updateValues);
    this.onTouched(updateValues);
  }

  get isFileSelected() {  
    return this.value;
  }

  @HostBinding('class') get classes(): string {
    const classes = [`size-${this.size}`];
    return classes.join(' ')
  }

  constructor(
    protected sanitizer: DomSanitizer,
    private imagesService: ImagesService,
    private helperService: HelperService,
  ) { }

  ngOnInit(): void {
  }

  handleDelete(): void {
    const input = this.fileInput.nativeElement

    input.value = ''
    if (!/safari/i.test(navigator?.userAgent)) {
      input.type = ''
      input.type = 'file'
    }
    this.value = null;
  }

  onFileChange(event) {
    if (event?.target?.files?.length != 0) {
      if (this.upload && event.target.files[0]) {
        this.handleUpload(event.target.files[0]);
      } else {
        this.value = event.target.files[0]
      }
    }
  }

  setFilePreview() {
    if (typeof this.value === 'string') {
      this.previewUrl = this.value;
    } else if (this.value instanceof File) {
      this.previewUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.value))
    } else {
      if (this.type === 'company') {
        this.previewUrl = '/assets/images/company-avtar.png';
      } else {
        this.previewUrl = '/assets/images/unknown-512.png';
      }
    }
  }

  writeValue(value: FileValue) {
    this._value = value
    this.setFilePreview()
  }

  registerOnChange(fn: (rating: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  async handleUpload(file) {
    if (file instanceof File) {
      this.uploading = true
      return await this.imagesService.upload({
        file,
        type: this.uploadType,
      }).then(({ data }: any) => {
        this.value = data as string
        this.uploading = false
      }).catch((error) => {
        this.uploading = false
        this.helperService.errorMessage(error, "File upload field, please try again")
      });

    }
  }
}
