import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@app/models/core/base';
import { ShipmentType } from '@app/models/shipment-type.model';
import { CrudService } from './core/crud.service';
import { firstValueFrom } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class ShipmentTypeService extends CrudService<ShipmentType>{

    basePath = 'ShipmentTypes'

    constructor(
        protected http: HttpClient
    ) {
        super(http)
    } 

    getShipmentTypeList(customerId: number) {
        return firstValueFrom(this.http.get<ApiResponse<ShipmentType[]>>(`${this.basePath}/List/${customerId}`))
            .then(({ data }) => data);
    }


    get(id: number) {
        return firstValueFrom(this.http.get<ApiResponse<ShipmentType>>(`${this.basePath}/${id}`))
            .then(({ data }) => data);
    }


    update(id: number, chargeType: ShipmentType) {
        return firstValueFrom(this.http.put<ApiResponse<ShipmentType>>(`${this.basePath}/${id}`, chargeType))
            .then(({ data }) => data);
    }

    create(chargeType: ShipmentType) {
        return firstValueFrom(this.http.post<ApiResponse<ShipmentType>>(`${this.basePath}`, chargeType) )
            .then(({ data }) => data);
    }

    delete(id: number) {
        return firstValueFrom(this.http.delete<ApiResponse<any>>(`${this.basePath}/${id}`))
            .then(({ data }) => data);
    }

}
