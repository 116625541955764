import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import { saveAs } from 'file-saver';
import { ReceivePaymentComponent } from '../add-payment/receive-payment.component';
import { AccountingService } from '@app/shared/services/accounting.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Invoice, InvoiceApproveStatus } from '@app/models/invoice.model';
import { DatatableComponent } from '@app/@shared/datatable';
import { HelperService } from '@app/shared/services/helper.service';
import { Customer } from '@app/models/customer.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '@app/shared/services/app.service';
import { debounceTime } from 'rxjs/operators';
import { CustomerListComponent } from '../customer-list/customer-list.component';
import { ListTabsComponent } from '@app/@shared/list-tabs/list-tabs.component';
import { reverseAmount } from '@app/@shared/utils';
import { AuthService } from '@app/shared/services/auth/auth.service';

@UntilDestroy()
@Component({
    standalone: false,
    selector: 'app-accounting-list',
    templateUrl: './accounting-list.component.html',
    styleUrls: ['./accounting-list.component.scss']
})
export class AccountingListComponent implements AfterContentChecked, OnInit, OnDestroy {

    SearchTerm: string;
    filters = { TnxTypes: ['Invoice','Payment'] }
    activeTab: 'customer' | 'filter' = 'customer';
    selectedCustomer: Customer;
    @ViewChild('datatable') datatable: DatatableComponent
    @ViewChild('customerList') customerList: CustomerListComponent;
    selectExportInvoices: any;
    userRoles: any[];
    invoiceApproveStatus = InvoiceApproveStatus

    @Input() listTabs: ListTabsComponent;
    previousSelectCustomerId: number;

    constructor(
        private modalService: NgbModal,
        private router: Router,
        public accountingService: AccountingService,
        private helperService: HelperService,
        private util: UtilService,
        private readonly cdr: ChangeDetectorRef,
        private appService: AppService,
        public activatedRoute: ActivatedRoute,
        private authService: AuthService
    ) {
    }

    mapTransactionsFilter = (request?: any) => {
        request = Object.assign({
            SortBy: 'createdTime',
            SortDirection: 'Descending',
            TnxTypes: this.activeTab === 'customer' ? ['Invoice', 'Payment'] : [],
            TnxStatus: []
        }, request)

        if (this.activeTab === 'filter') {
            request.TnxTypes = this.filters.TnxTypes;
        }

        if (this.selectedCustomer?.customerId) {
            request.Customers = [this.selectedCustomer.customerId];
        }

        if (this.accountingService.dataStore.activeTab === 'filter') {
            request = Object.assign(request, this.filters)
        }

        if (this.SearchTerm) {
            request.SearchTerm = this.SearchTerm
        } else {
            delete request.SearchTerm
        }

        return request;
    }

    getTransactions = async (request?: any) => {
        let hasFilter = false;

        if (this.selectedCustomer?.customerId) {
            hasFilter = true
        }

        if (this.activeTab === 'filter') {
            hasFilter = true
        }

        if (hasFilter) {
            localStorage.setItem('invoice.filters', JSON.stringify(request))
        } else {
            localStorage.removeItem('invoice.filters');
        }

        return this.accountingService.search(request)
    }

    isEligibleForApproval(row) {
        const isNotApprovedOrPaid =
            row?.transactionPaymentStatus !== 'Paid' &&
            row?.transactionPaymentStatus !== 'Approved' &&
            row?.status !== 'Approved' &&
            row?.status !== 'Paid';

        const isUserAdminOrAccounting = ["COMPANY_ADMIN", "GLOBAL_ADMIN", "Accounting"]?.some(role => this.userRoles?.includes(role));

        return isUserAdminOrAccounting && isNotApprovedOrPaid;
    }

    isEligibleForUnapprove(row) {
        return row?.transactionPaymentStatus !== 'Paid' && row?.transactionPaymentStatus !== 'PartialPaid' && row?.status === 'Approved';
    }

    ngOnInit() {

        this.authService.$user
            .pipe(untilDestroyed(this))
            .subscribe(user => {
                this.userRoles = user?.roles;
            });

        this.activatedRoute.queryParams
            .pipe(untilDestroyed(this))
            .subscribe((queryParams) => {
                const path = '/app/accounting';
                if(queryParams?.id && queryParams?.isSendInvoice) {
                    this.router.navigate([path], {
                        queryParams: {
                            id: null,
                            isSendInvoice: null,
                        },
                        queryParamsHandling: 'merge'
                    });
                    this.listTabs.openTab(queryParams?.id, { setAsActiveTab: true, isSendInvoice: queryParams?.isSendInvoice });
                } else if (queryParams?.id) {
                    this.listTabs.navigateOpenTab(this.activatedRoute.snapshot.queryParams.id, path);
                } else if (queryParams?.closeTabId) {
                    this.router.navigate([path], {
                        queryParams: {
                            closeTabId: null,
                        },
                        queryParamsHandling: 'merge'
                    });
                    this.listTabs.onCloseRedirectListPage(queryParams.closeTabId);
                    this.reloadAccounting();
                } else if (queryParams?.isReloadAccounting) {
                    this.router.navigate([path], {
                        queryParams: {
                            isReloadAccounting: null,
                        },
                        queryParamsHandling: 'merge'
                    });
                    this.reloadAccounting();
                }
            })

        this.accountingService.$selectedCustomer
            .pipe(untilDestroyed(this))
            .subscribe(
                (res: any) => {
                    this.selectedCustomer = res
                }
            );

        this.appService.$searchTerm
            .pipe(
                untilDestroyed(this),
                debounceTime(500)
            )
            .subscribe((text) => {
                if (text) {
                    if(this.activeTab === 'customer' && this.selectedCustomer?.customerId) this.previousSelectCustomerId = this.selectedCustomer?.customerId;
                    this.selectedCustomer = null;
                    this.accountingService.selectedCustomer = null;
                }
                this.accountingService.searchTermCustomers = text
                this.SearchTerm = text
                if(this.activeTab === 'filter') this.datatable?.refresh({ pageRest: true });
                // this.datatable?.setSearchValue(text);
            })


    }

    ngAfterContentChecked() {
        this.cdr.detectChanges();
    }
    handleCheckCollapsable = (row: Invoice) => {
        return row?.appliedPayments?.length > 0
    }

    handleRowClick(invoice: Invoice) {
        this.listTabs.openTab(invoice.id, { referenceId: invoice.referenceId, tnxType: invoice.tnxType, isEdit: false });
    }

    handleRowRightClick(invoice: Invoice) {
        const url = `/app/accounting/invoice/${invoice.id}`;
        window.open(url, '_blank');
    }

    handleApproveOrUnApproveInvoice(invoiceId, invoiceApproveStatus, type: 'Approved' | 'Disapprove') {
        this.accountingService.invoiceApproveOrUnApprove(invoiceId, invoiceApproveStatus).then((res) => {
            this.helperService.successMessage(`Invoice #${invoiceId} has been successfully ${type}.`);
            this.datatable?.refresh();
        }).catch((error) => {
            this.helperService.errorMessage(error);
        })
    }

    searchCustomer(event) {
        this.accountingService.searchTermCustomers = event
        this.datatable?.refresh(true);
    }

    selectCustomer(customer: Customer) {
        if (this.previousSelectCustomerId !== customer?.customerId) {
            this.accountingService.selectedCustomer = customer
            this.datatable?.refresh(true);
        } else {
            this.accountingService.selectedCustomer = customer
            this.datatable?.refresh();
        }
    }

    addInvoice() {
        this.router.navigateByUrl(`app/accounting/invoice/add`);
    }

    editInvoice(invoice?: Invoice) {
        this.listTabs.openTab(invoice.id, { isEdit: true });
    }

    deleteInvoice(invoice?: Invoice) {
        this.accountingService.deleteInvoice(invoice?.id).then(() => {
            this.listTabs.closeTab(`${invoice?.id}`);
            this.reloadAccounting();
            this.helperService.successMessage("Invoice successfully deleted")
        }).catch((error) => {
            this.helperService.errorMessage(error);
        });
    }

    searchUpdated() {
        this.datatable?.refresh(true);
    }

    switchTab(tab) {
        if (this.activeTab != tab) {
            this.activeTab = tab;
            this.selectedCustomer = null;
            this.accountingService.selectedCustomer = null;
            this.accountingService.searchTermCustomers = '';
            this.appService.searchTerm = '';
            this.datatable?.refresh(true);
        }
    }

    openCustomerPaymentModal(invoice?: any) {
        const activeModal = this.modalService.open(ReceivePaymentComponent,
            {
                modalDialogClass: 'service_type_modal customer-payment-modal'
            }
        );
        if (invoice && invoice?.customer) {
            activeModal.componentInstance.customerId = invoice?.customer?.customerId
            activeModal.componentInstance.customerName = invoice?.customer?.customerName
            activeModal.componentInstance.invoiceId = invoice?.id
        }
        activeModal.result.then(
            (result) => {
                if (result) {
                    this.reloadAccounting();
                }
            },
            () => { }
        );
    }

    openEditCustomerPaymentModal(paymentInfo: any, dueAmount?: number) {
        const activeModal = this.modalService.open(ReceivePaymentComponent,
            {
                modalDialogClass: 'service_type_modal customer-payment-modal'
            }
        );
        if (paymentInfo && paymentInfo?.customer) {
            paymentInfo.appliedPayments[0].invoice.balance = reverseAmount(paymentInfo.paymentAmount + reverseAmount(dueAmount))
            activeModal.componentInstance.customerId = paymentInfo?.customer?.customerId
            activeModal.componentInstance.customerName = paymentInfo?.customer?.customerName
            activeModal.componentInstance.invoiceId = paymentInfo?.id
            activeModal.componentInstance.paymentInfo = paymentInfo
        }
        activeModal.result.then(
            (result) => {
                if (result) {
                    this.reloadAccounting();
                }
            },
            () => { }
        );
    }

    reloadAccounting() { 
        this.datatable?.refresh(true);
        if (this.activeTab === 'customer') {
            this.customerList?.refresh();
        }
        if (this.accountingService?.selectedCustomer?.customerId) {
            this.accountingService.getAccountingCustomerBasicDetails({
                customers: [this.accountingService.selectedCustomer.customerId]
            }).then(({ list }) => {
                if (list?.length > 0) {
                    this.accountingService.selectedCustomer = list[0];
                }
            })
        }
    }

    setSelectInvoices(event) {
        this.selectExportInvoices = event
    }

    exportInvoices() {
        const ids = this.selectExportInvoices
        if (ids?.length > 0) {
            this.accountingService.exportInvoices(ids).then(async ({ data }: any) => {
                const blob = new Blob([data.fileData], { type: 'application/text' });
                saveAs(blob, 'export.iif');
                if (this.datatable && this.datatable?.dataStore?.length > 0) {
                    await this.datatable?.dataStore?.map((res) => {
                        if (ids.includes(res?.id)) {
                            res.inQb = true;
                        }
                        return res;
                    });
                }
                this.datatable?.clearSelection()
            }).catch(error => {
                this.helperService.errorMessage(error);
            });
        } else {
            this.helperService.addToast(`Please Select Invoice`, "Oops", 'warning')
        }
    }

    getBalanceColorOrTooltip(row: any, type: 'tooltip' | 'color'): string {
        const balance: number = row?.balance;
        const orderStatus: string = row?.orderStatus;
        const orderTripStatus: string = row?.orderTripStatus;
        const transactionPaymentStatus: string = row?.transactionPaymentStatus;
        switch (true) {
            case balance == 0 && transactionPaymentStatus === "Paid":
                return type === 'color' ? 'text-success' : 'The Invoice was fully paid';
            case balance > 0 && transactionPaymentStatus === "Paid":
                return type === 'color' ? 'text-success' : 'The Invoice was overpaid';
            case -balance > 0 && transactionPaymentStatus === "PartialPaid":
                return type === 'color' ? 'text-warning' : 'The Invoice was partly paid';
            case -balance > 0 && (orderTripStatus === 'Delivered' || orderStatus === 'Closed') && transactionPaymentStatus !== "Paid":
                return type === 'color' ? 'text-danger' : 'The Invoice was not paid';
            default:
                return type === 'color' ? 'text-body-color' : 'The Invoice was not paid, and the Order is not completed yet';
        }
    }


    ngOnDestroy() {
        this.appService.searchTerm = ''
    }
}
