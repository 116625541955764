import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ExpenseSubtype, NewExpense } from '@models/expense.model';
import { HelperService } from '@app/shared/services/helper.service';
import { DatatableComponent } from '@app/@shared/datatable';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TruckService } from '@app/shared/services/truck.service';
import { NewExpenseService } from '@app/shared/services/new-expense.service';
import { ExcludeExpensesComponent } from '../../exclude-expenses/exclude-expenses.component';
import { TruckStatusEnum } from '@app/models/truck.model';
import { ExpensesDateSelectorComponent } from '../../expenses-date-selector/expenses-date-selector.component';

@Component({
    selector: 'app-trucks-expense',
    templateUrl: './trucks-expense.component.html',
    styleUrls: ['./trucks-expense.component.scss'],
})
export class TrucksExpenseComponent implements OnInit {
    activeTrucksTab = 'finance';
    isLoading = false; // Tracks if data is being loaded
    editRows: Set<number> = new Set();

    allTrucksExpenses: any;
    ExpenseSubtype = ExpenseSubtype;
    @ViewChild('truckDatatable') truckDatatable: DatatableComponent;
    submitLoading = false;
    updatedExpenses: NewExpense[] = [];
    TruckStatusEnum = TruckStatusEnum;
    @ViewChild('expensesDateSelectorComponent') expensesDateSelectorComponent: ExpensesDateSelectorComponent;

    constructor(
        private truckService: TruckService,
        private helperService: HelperService,
        private newExpenseService: NewExpenseService,
        private ngbModal: NgbModal,
        private cdr: ChangeDetectorRef
    ) {

    }
    ngOnInit() {
    }

    ngAfterViewInit() {
        this.loadAllTrucksExpenses();
        this.cdr.detectChanges();
    }

    // Load all trucks expenses
    loadAllTrucksExpenses() {
        this.isLoading = true;
        this.truckService.getAllTrucksExpenses({ date: this.expensesDateSelectorComponent.date.toISOString() })
            .then((resp: any) => {
                this.allTrucksExpenses = resp;
            })
            .catch((error) => {
                this.helperService.errorMessage(error);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    // Handle date change
    onDateChange() {
        if (this.truckDatatable) {
            this.truckDatatable.refresh(true);
        }

        if (this.expensesDateSelectorComponent) {
            this.loadAllTrucksExpenses();
            this.updatedExpenses = [];
            this.editRows.clear();
        }
    }

    // Get data for trucks with expenses
    getTrucksWithExpenses = (request?: any) => {
        return this.truckService.getTrucksWithExpenses({ date: this.expensesDateSelectorComponent.date.toISOString(), ...request })
            .then((resp: any) => {
                return resp;
            })
            .catch((error) => {
                this.helperService.errorMessage(error);
            });
    }

    // Get expense amount for a specific truck and subtype
    getTrucksExpenseAmount(expenses: any[], expenseType: ExpenseSubtype, truckId: number): number {
        const date = this.expensesDateSelectorComponent.date;

        // Check if the updated expense exists in updatedExpenses
        const updatedExpense = this.updatedExpenses.find(exp =>
            exp.truckId === truckId &&
            exp.date && this.isSameYearAndMonth(exp.date, date) &&
            exp.subtype === expenseType
        );

        if (updatedExpense) {
            return updatedExpense.amount || 0;
        }

        // Check if the original expense exists in expenses
        const expense = expenses.find(exp =>
            exp.truckId === truckId &&
            exp.date && this.isSameYearAndMonth(exp.date, date) &&
            exp.subtype === expenseType
        );

        return expense ? expense.amount || 0 : 0;
    }

    // Handle changes in expense amounts
    changeTrucksExpenseAmount(
        newAmount: number,
        subtype: ExpenseSubtype,
        expenses: NewExpense[],
        truckId: number
    ) {
        const date = this.expensesDateSelectorComponent.date;

        const findExpense = (arr: NewExpense[], expenseSubtype: ExpenseSubtype) => arr.find(exp =>
            exp.truckId === truckId &&
            exp.date && this.isSameYearAndMonth(exp.date, date) &&
            exp.subtype === expenseSubtype
        );

        // Look for the expense in the updatedExpenses array first
        let expense = findExpense(this.updatedExpenses, subtype);

        if (expense) {
            // If found in updatedExpenses, update the amount
            expense.amount = newAmount;
        } else {
            // If not found in updatedExpenses, look for it in the original expenses array
            expense = findExpense(expenses, subtype);

            if (expense) {
                // If found in original expenses, create a copy, update the amount, and add it to updatedExpenses
                const updatedExpense = { ...expense, amount: newAmount };
                this.updatedExpenses.push(updatedExpense);
            } else {
                // If not found in either array, create a new expense and add it to updatedExpenses
                const newExpense: NewExpense = {
                    truckId,
                    date,
                    subtype,
                    amount: newAmount,
                };
                this.updatedExpenses.push(newExpense);
            }
        }

        // Handle odometer start and end to calculate miles
        if (subtype === ExpenseSubtype.OdometerStart || subtype === ExpenseSubtype.OdometerEnd) {
            const startExpense = findExpense(this.updatedExpenses, ExpenseSubtype.OdometerStart) || findExpense(expenses, ExpenseSubtype.OdometerStart);
            const endExpense = findExpense(this.updatedExpenses, ExpenseSubtype.OdometerEnd) || findExpense(expenses, ExpenseSubtype.OdometerEnd);

            if (startExpense && endExpense) {
                const milesAmount = (endExpense.amount || 0) - (startExpense.amount || 0);

                // Update the Miles value in updatedExpenses
                let milesExpense = this.updatedExpenses.find(exp =>
                    exp.truckId === truckId &&
                    this.isSameYearAndMonth(exp.date, date) &&
                    exp.subtype === ExpenseSubtype.TruckMiles
                );

                if (!milesExpense) {
                    milesExpense = findExpense(expenses, ExpenseSubtype.TruckMiles);

                    if (milesExpense) {
                        // If found in original expenses, create a copy, update the amount, and add it to updatedExpenses
                        const updatedMilesExpense = { ...milesExpense, amount: milesAmount };
                        this.updatedExpenses.push(updatedMilesExpense);
                    } else {
                        // If not found in either array, create a new expense and add it to updatedExpenses
                        const newMilesExpense: NewExpense = {
                            truckId,
                            date,
                            subtype: ExpenseSubtype.TruckMiles,
                            amount: milesAmount,
                        };
                        this.updatedExpenses.push(newMilesExpense);
                    }
                } else {
                    // If found in updatedExpenses, update the amount
                    milesExpense.amount = milesAmount;
                }
            }
        }
    }

    // Save updated expenses
    saveTrucksExpense(truckExpenses: any[]) {
        if (this.updatedExpenses.length > 0) {
            const truckIdsWithIncompleteOdometers = new Set<number>();

            // Collect truck IDs with incomplete odometer data
            this.updatedExpenses.forEach(expense => {
                if (expense.subtype === ExpenseSubtype.OdometerStart || expense.subtype === ExpenseSubtype.OdometerEnd) {
                    const pairedExpenseType = expense.subtype === ExpenseSubtype.OdometerStart ? ExpenseSubtype.OdometerEnd : ExpenseSubtype.OdometerStart;

                    // Check in updatedExpenses first
                    const pairedExpenseInUpdated = this.updatedExpenses.some(exp =>
                        exp.truckId === expense.truckId &&
                        this.isSameYearAndMonth(exp.date, expense.date) &&
                        exp.subtype === pairedExpenseType
                    );

                    // Flatten truckExpenses to get all original expenses
                    const originalExpenses = truckExpenses.flatMap(truck => truck.expenses);

                    // Check in original expenses if not found in updatedExpenses
                    const pairedExpenseInOriginal = pairedExpenseInUpdated || originalExpenses.some(exp =>
                        exp.truckId === expense.truckId &&
                        this.isSameYearAndMonth(exp.date, expense.date) &&
                        exp.subtype === pairedExpenseType
                    );

                    if (!pairedExpenseInOriginal) {
                        truckIdsWithIncompleteOdometers.add(expense.truckId!);
                    }
                }
            });

            if (truckIdsWithIncompleteOdometers.size > 0) {
                // Show error message if there are incomplete odometer entries
                this.helperService.errorMessage("Both Odometer Start and Odometer End values must be provided for each truck.");
                return;
            }

            this.submitLoading = true;

            this.newExpenseService.addMultiple(this.updatedExpenses).then(res => {
                // Handle successful response
                this.helperService.successMessage('Expenses saved successfully.');
                this.updatedExpenses = [];
                this.truckDatatable.refresh(true);
                this.loadAllTrucksExpenses();
                this.editRows.clear();
            })
                .catch(err => {
                    // Handle error
                    this.helperService.errorMessage(err);
                })
                .finally(() => {
                    this.submitLoading = false;
                });
        }
    }

    // Get overall expenses for a truck
    getOverallExpenses(expenses: NewExpense[]) {
        const total = expenses
            .filter(exp => exp.date && this.isSameYearAndMonth(exp.date, this.expensesDateSelectorComponent.date) &&
                exp.subtype !== ExpenseSubtype.TruckMiles && exp.subtype !== ExpenseSubtype.OdometerStart && exp.subtype !== ExpenseSubtype.OdometerEnd)
            .reduce((sum, expense) => sum + (expense.amount || 0), 0);

        return `$ ${total}`;
    }

    // Check if a row should be disabled
    isRowExcluded(row: any): boolean {
        function getYearMonth(date: Date): number {
            return date.getFullYear() * 12 + date.getMonth();
        }

        const currentDate = new Date(this.expensesDateSelectorComponent.date);
        const currentYearMonth = getYearMonth(currentDate);

        const excludeStartDate = row?.excludeExpensesStartDate
            ? new Date(row.excludeExpensesStartDate)
            : null;
        const excludeEndDate = row?.excludeExpensesEndDate
            ? new Date(row.excludeExpensesEndDate)
            : null;

        let excludeStartYearMonth: number | null = null;
        let excludeEndYearMonth: number | null = null;

        if (excludeStartDate) {
            excludeStartYearMonth = getYearMonth(excludeStartDate);
        }

        if (excludeEndDate) {
            excludeEndYearMonth = getYearMonth(excludeEndDate);
        }

        if (excludeStartYearMonth !== null && excludeEndYearMonth !== null) {
            return (
                currentYearMonth >= excludeStartYearMonth &&
                currentYearMonth <= excludeEndYearMonth
            );
        } else if (excludeStartYearMonth !== null) {
            return currentYearMonth >= excludeStartYearMonth;
        } else if (excludeEndYearMonth !== null) {
            return currentYearMonth <= excludeEndYearMonth;
        } else {
            return false;
        }
    }

    isRowDisabled(row: any): boolean {
        return !this.editRows.has(row.truckId) || this.isRowExcluded(row);
    }

    toggleEditRow(row: any) {
        if (this.isRowExcluded(row)) {
            return;
        }
        if (this.editRows.has(row.truckId)) {
            this.editRows.delete(row.truckId);
        } else {
            this.editRows.add(row.truckId);
        }
    }

    // Handle exclude expenses action
    onExcludeExpenses(row: any) {
        const activeModal = this.ngbModal.open(ExcludeExpensesComponent);
        activeModal.componentInstance.truck = row;

        // Refresh data when modal is closed
        activeModal.result.then(
            (result) => {
                if (result === true) {
                    this.truckDatatable.refresh(true);
                    this.loadAllTrucksExpenses();
                }
            },
            () => { }
        );
    }

    getEnumKey(enumValue: number): string {
        return ExpenseSubtype[enumValue];
    }

    // Helper method to check if two dates are in the same year and month
    private isSameYearAndMonth(date1: any, date2: any): boolean {
        const d1 = new Date(date1);
        const d2 = new Date(date2);
        return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth();
    }
}
