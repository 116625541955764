import { Component, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustomerService } from '@app/services/customer.service';
import { Address } from '@models/address.model';
import { ApiService } from '@app/services/api.service';
import Quill from 'quill';

import { VideoHandler, ImageHandler, Options } from 'ngx-quill-upload';

Quill?.register('modules/imageHandler', ImageHandler as any);
Quill?.register('modules/videoHandler', VideoHandler as any);

@Component({
    standalone: false,
    selector: 'app-address-info',
    templateUrl: './address-info.component.html',
    styleUrls: ['./address-info.component.scss']
})
export class AddressInfoComponent {
    @ViewChild('template') template: TemplateRef<any>;
    address: Address;
    modalRef: BsModalRef;

    modules = {
        imageHandler: {
            upload: this.imageUpload.bind(this),
            accepts: ['png', 'jpg', 'jpeg', 'jfif'] // Extensions to allow for images (Optional) | Default - ['jpg', 'jpeg', 'png']
        } as Options,
    };

    content: string;

    constructor(
        private modalService: BsModalService,
        private customerService: CustomerService,
        private api: ApiService,
    ) {
    }

    public show(address: Address) {
        this.address = address;
        this.content = address.instructions;
        this.modalRef = this.modalService.show(
            this.template,
            Object.assign({}, { class: 'service_type_modal' })
        );
    }

    close() {
        this.address.instructions = this.content;
        this.modalRef.hide();
    }

    imageUpload(image: File): Promise<string> {
        return new Promise((resolve, reject) => {
            this.api.UploadImage('address', this.address.addressId, image, imageUrl => {
                resolve(imageUrl);
            }, error => reject(error));
        })
    }
}
