import { Component, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface AttemptDialogResult {
    isAttempt: boolean;
    reason?: string;
    ReAttemptDate?: Date;
}

@Component({
    selector: 'app-attempt-dialog',
    templateUrl: './attempt-dialog.component.html',
    styleUrls: ['./attempt-dialog.component.scss']
})
export class AttemptDialogComponent {
    attemptReasonShown = false;
    attemptReason = '';
    reattemptDate = null;

    constructor(
        public ngbActiveModal: NgbActiveModal,
    ) {
    }

    close() {
        this.ngbActiveModal.close();
    }

    noClicked() {
        this.ngbActiveModal.close({ isConfirmed: true, value: { isAttempt: false } })
    }

    yesClicked() {
        this.attemptReasonShown = true;
    }

    cancelClicked() {
        this.ngbActiveModal.close({ isConfirmed: false })
    }

    get isValid(): boolean {
        return this.attemptReason.trim() === '';
    }

    okClicked() {
        this.ngbActiveModal.close({
            isConfirmed: true,
            value: { isAttempt: true, reason: this.attemptReason, ReAttemptDate: this.reattemptDate }
        })
    }
}
