import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormValidateDirective } from '@app/@shared/form-validate/form-validate.directive';
import { patterns } from '@app/@shared/regex/regex-patterns.const';
import { formatPhoneNumberAndExtension, parsePhoneNumberAndExtension } from '@app/@shared/utils';
import { customerEmailTypes } from '@app/data/customer';
import { stateOptions } from '@app/data/state';
import { Country } from '@app/models/address.model';
import { PipelineEnum } from '@app/models/customer.model';
import { EntityType } from '@app/models/entity-type.model';
import { ImageUploadTypeEnum } from '@app/models/image.model';
import { OutsourceCompany } from '@app/models/outsource-company.model';
import { Vehicle } from '@app/models/samsara/vehicle.model';
import { AddressService } from '@app/shared/services/address.service';
import { CustomerService } from '@app/shared/services/customer.service';
import { HelperService } from '@app/shared/services/helper.service';
import { TypeOfTrucksService } from '@app/shared/services/type-of-truck.service';
import { cloneDeep } from 'lodash';
import { array, number, object, string, ValidationError } from 'yup';

@Component({
    standalone: false,
    selector: 'app-outsource-company-information',
    templateUrl: './outsource-company-information.component.html',
    styleUrls: ['./outsource-company-information.component.scss']
})
export class OutsourceCompanyInformationComponent implements OnInit {

    defaultEmailItem = {
        isActive: true,
        emailType: null,
        email: '',
    }

    isEdit: boolean = false;
    isEmailItemsCollapse: boolean = true;
    isCompanyTypeOfTrucksCollapse: boolean = true;

    initialOutsourceCompany: OutsourceCompany;
    private _outsourceCompany: OutsourceCompany = {};

    customerEmailTypes = customerEmailTypes;
    states = stateOptions;
    ImageUploadTypeEnum = ImageUploadTypeEnum;
    PipelineEnum = PipelineEnum;
    typeOfTrucks: any;
    users: any[];
    countries: Country[];
    vehicles: Vehicle[];

    validationSchema = object().shape({
        outsourceCompanyName: string().nullable().max(256).required(),
        phone: string().label("Company Phone").nullable().required(),
          addressLine1: string().trim().label('House/Office').required().max(256),
          addressLine2: string().trim().label('Street').required().max(256),
          city: string().trim().label('City').required().max(256),
          state: string().label('State').required(),
          country: number().label('Country').nullable().required(),
          zip: string().trim().label('Zip Code').label('Zip code').required().max(32),
        companyEmails: array().of(
          object().shape({
            email: string().label('Email').required().email().max(256),
            emailType: string().nullable(),
          })
        ).test('unique-emails', 'Emails must be unique', function (arr: any[]) {
          const path = this.path;
          let errors = [];
          let valueCount = arr.reduce((acc, obj) => {
            acc[obj.email] = (acc[obj.email] || 0) + 1;
            return acc;
          }, {});
          arr.forEach((obj, index) => {
            if (obj.email && valueCount[obj.email] > 1) {
              const error = this.createError({ message: `Email is duplicated`, path: `${path}[${index}].email` });
              errors.push(error);
            }
          });
          if (errors?.length > 0) {
            return new ValidationError(errors);
          }
          return true;
        }),
        companyTypeOfTrucks: array().of(
            object().shape({
              park: number().label("park").nullable().required(),
              typeOfTruckId: number().label("Type of Truck").nullable().label("Type of Truck").required(),
            })
        ),
    });

    @Output() outsourceCompanyChange = new EventEmitter()


    @Input()
    public get outsourceCompany(): any {
        return this._outsourceCompany;
    }
    public set outsourceCompany(value: any) {
        this._outsourceCompany = cloneDeep(value);
        this.initialOutsourceCompany = cloneDeep(value);
    }

    constructor(
        public customerService: CustomerService,
        public helperService: HelperService,
        public addressService: AddressService,
        private typeOfTrucksService: TypeOfTrucksService,
    ) {
    }

    ngOnInit() {
        this.typeOfTrucksService.getAll()
            .then((res) => {
                this.typeOfTrucks = res;
            }).catch((error) => {
                this.helperService.errorMessage(error)
            })

        this.addressService.getCountries()
            .then(res => {
                this.countries = res;
            }).catch((error) => {
                this.countries = [];
                this.helperService.errorMessage(error)
            })
    }

    toggleEmailItemsCollapse() {
        this.isEmailItemsCollapse = !this.isEmailItemsCollapse;
    }

    toggleCompanyTypeOfTrucksCollapse() {
        this.isCompanyTypeOfTrucksCollapse = !this.isCompanyTypeOfTrucksCollapse;
    }

    editOutsourceInformation() {
        this.isEdit = true;
        this.isEmailItemsCollapse = false;
        this.isCompanyTypeOfTrucksCollapse = false;
    }

    discardChanges(form: FormValidateDirective) {
        this.isEdit = false
        this.isEmailItemsCollapse = true;
        this.isCompanyTypeOfTrucksCollapse = true;
        this.outsourceCompany = {...this.initialOutsourceCompany}
        setTimeout(() => {
            form.reset()
        }, 0)
    }

    setTypeOfTruckId(value, index) {
        this.outsourceCompany.companyTypeOfTrucks[index].typeOfTruckId = Number(value);
    }

    mainAddress(): any {
        const mainAddress = this.outsourceCompany?.addresses?.find(x => x?.entityType === EntityType?.OutsourceCompany);

        if (!mainAddress) {
            const newAddress = {
                entityType: 1,
                name: `${this.outsourceCompany.customerName} Main`,
                addressLine1: null,
                city: null,
                state: null,
                zip: null,
                addressDetails: null,
                outsourceCompanyId: this.outsourceCompany?.id
            };

            if (!this.outsourceCompany?.addresses) {
                this.outsourceCompany.addresses = [];
            }

            this.outsourceCompany.addresses.push(newAddress);
            return newAddress;
        } else {
            return mainAddress;
        }
    }

    handleSubmit(f) {
        if (f.isValid && this.isEdit) {
            try {
                this.outsourceCompanyChange.emit(this.outsourceCompany)
                this.isEdit = false;
                this.isEmailItemsCollapse = true;
                this.isCompanyTypeOfTrucksCollapse = true;
              } catch (error) {
                this.isEdit = true;
                this.isEmailItemsCollapse = false;
                this.isCompanyTypeOfTrucksCollapse = false;
              }
        }
    }

}
