import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TypeaheadSelectModule } from '../typeahead-select/typeahead-select.module';
import { DirectivesModule } from '../directives/directives.module';
import { PhoneWithCodeInputModule } from '../phone-with-code-input/phone-with-code-input.module';
import { PhoneIsoCodeExtensionInputComponent } from './phone-iso-code-extension-input/phone-iso-code-extension-input.component';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';


@NgModule({
  declarations: [
    PhoneIsoCodeExtensionInputComponent,
  ],
  exports: [
    PhoneIsoCodeExtensionInputComponent,
  ],
  imports: [
    CommonModule,
    DirectivesModule,
    FormsModule,
    TypeaheadSelectModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    PhoneWithCodeInputModule,
  ]
})
export class PhoneIsoCodeExtensionInputModule { }
