import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderStopComponent } from './order-stop/order-stop.component';
import { FormsModule } from '@angular/forms';
import { RepeaterModule } from '@app/@shared/repeater/repeater.module';
import { EditPickupDialogModule } from '../edit-pickup-dialog/edit-pickup-dialog.module';
import { EditDeliveryDialogModule } from '../edit-delivery-dialog/edit-delivery-dialog.module';
import { OrderStopItemModule } from '../order-stop-item/order-stop-item.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { InfoOrderDialogModule } from '../info-order-dialog/info-order-dialog.module';
import { DialogsModule } from '@app/@shared/dialogs/dialogs.module';
import { FileUploadModule } from '@app/@shared/file-upload/file-upload.module';
import { NgxSmoothDnDModule } from '@app/@shared/ngx-smooth-dnd';
import { CopyDetailsToDriverModule } from '@app/@shared/copy-details-to-driver/copy-details-to-driver.module';
import { EditOrderStopDateAndTimeDialogModule } from '../edit-order-stop-date-and-time-dialog/edit-order-stop-date-and-time-dialog.module';
import { OrderPriorityModule } from '@app/@shared/order-priority/order-priority.module';


@NgModule({
  declarations: [
    OrderStopComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RepeaterModule,
    EditDeliveryDialogModule,
    EditPickupDialogModule,
    OrderStopItemModule,
    DragDropModule,
    NgbDropdownModule,
    InfoOrderDialogModule,
    DialogsModule,
    FileUploadModule,
    NgxSmoothDnDModule,
    CopyDetailsToDriverModule,
    NgbTooltipModule,
    EditOrderStopDateAndTimeDialogModule,
    OrderPriorityModule
  ],
  exports: [
    OrderStopComponent,
  ]
})
export class OrderStopModule { }
