// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  padding-left: 116px;
  padding-bottom: 20px;
}
:host .datatable {
  min-height: auto !important;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/invoice-payments/invoice-payments.component.scss"],"names":[],"mappings":"AAEA;EACI,cAAA;EACA,mBAAA;EACA,oBAAA;AADJ;AAEI;EACI,2BAAA;AAAR","sourcesContent":["@use \"common/var\" as *;\n\n:host {\n    display: block;\n    padding-left: 116px;\n    padding-bottom: 20px;\n    .datatable {\n        min-height: auto !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
