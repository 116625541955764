import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttachmentPreviewComponent } from './attachment-preview.component';
import { FilesViewerModalModule } from '../files-viewer-modal/files-viewer-modal.module';
@NgModule({
  declarations: [
    AttachmentPreviewComponent
  ],
  exports: [
    AttachmentPreviewComponent
  ],
  imports: [
    CommonModule,
    FilesViewerModalModule,
  ]
})
export class AttachmentPreviewModule { }
