import { Directive, HostListener, ElementRef, Input } from '@angular/core';

@Directive({
	standalone: false,
	selector: '[preventMinusInput]'
})
export class PreventMinusDirective {

	@Input() max: number;
	@Input() min: number;
	@Input() allowDecimal: boolean = true;

	constructor(
		private readonly el: ElementRef<HTMLInputElement>
	) { }

	@HostListener('keydown', ['$event'])
	onKeyDown(event: KeyboardEvent): void {
		const currentValue = this.el.nativeElement.value;
		const allowedKeys = [
			'0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', '#', 'Tab'
		];

		if (this.allowDecimal) {
			allowedKeys.push('.');
		}

		if (!allowedKeys.includes(event.key)) {
			event.preventDefault();
		}

		if (event.key === '.' && currentValue.includes('.')) {
			event.preventDefault();
		}
	}

	@HostListener('input', ['$event.target'])
	onInput(inputElement: HTMLInputElement): void {
		let numericValue = +inputElement.value;

		this.validateAndSetMaxValue(numericValue, inputElement, this.max);

		if (isNaN(numericValue) || numericValue < 0) {
			inputElement.value = '0';
		}
	}

	/**
	 * Validate the numeric value and update the input element if it exceeds the maximum value.
	 * 
	 * @param numericValue The numeric value to be validated.
	 * @param inputElement The input element associated with the numeric value.
	 * @param max The maximum value allowed.
	 */
	validateAndSetMaxValue(numericValue: number, inputElement: HTMLInputElement, max: number): void {
		if (max && numericValue > max) {
			numericValue = max;
			inputElement.value = numericValue.toString();
		}
	}
}

