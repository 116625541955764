import { AfterViewInit, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgModel, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ApiPaginationData } from '@app/models/core/base';
import { Zone } from '@app/models/zone.model';
import { ZoneService } from '@app/shared/services/zone.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { from, of } from 'rxjs';
import { catchError, debounceTime, switchMap, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  standalone: false,
  selector: 'app-zone-select',
  templateUrl: './zone-select.component.html',
  styleUrls: ['./zone-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ZoneSelectComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.Emulated
})
export class ZoneSelectComponent implements OnInit, AfterViewInit, OnDestroy {

  searchTerm: string;
  search: string;
  zones: Zone[];
  searchingZone: boolean;
  disabled: boolean;
  hasMorePage: boolean = false;
  page: number = 1;

  @ViewChild('dropdown') dropdown: NgbDropdown;
  @ViewChild('searchInput') searchInput: NgModel;

  onChange = (value: Zone) => { };
  onTouched = () => { };

  @Output() zoneChange = new EventEmitter<any>();
  @Input() initialLoad: boolean;
  @Input() type: 'normal' | 'custom' = 'normal';


  private _value: Zone;
  get value(): Zone {
    return this._value;
  }
  set value(updateValue: Zone) {
    this._value = updateValue;
    this.onChange(updateValue);
    this.onTouched();
  }


  constructor(
    private zoneService: ZoneService,
  ) { }


  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.searchInput.valueChanges
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.searchingZone = true;
          this.zones = [];
        }),
        debounceTime(500),
        switchMap(term => {
          this.page = 1;
          this.searchTerm = term ?? '';
          const request = this.getZones()
          return from(request).pipe(
            catchError(() => of([]))
          )
        }),
      ).subscribe((val) => {
        // nothing
      })


    if (this.initialLoad) {
      this.getZones()
    }
  }

  loadMoreZone() {
    this.page++;
    this.getZones()
  }

  getZones() {
    this.searchingZone = true
    let request = {
      ...this.searchTerm ? { SearchTerm: this.searchTerm } : {},
      Page: this.page,
      ItemsPerPage: 20
    }
    let resp: Promise<ApiPaginationData<Zone>>;
    resp = this.zoneService.getAll(request)
    resp.then((resp) => {
      const { list, totalCount, itemsPerPage } = resp;
      this.hasMorePage = totalCount > (this.page * itemsPerPage);
      if (this.page > 1) {
        this.zones = this.zones.concat(list);
      } else {
        this.zones = list;
      }
    }).finally(() => {
      this.searchingZone = false
    })
    return resp;
  }

  selectedZones(zone) {
    this.value = zone;
    this.dropdown.close();
    this.zoneChange.emit(this.value);
  }

  writeValue(value: Zone) {
    this._value = value
  }

  registerOnChange(fn: (value: Zone) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  clearValue(): void {
    this.value = null;
    this.zoneChange.emit(true);
    this.searchTerm = 'a'
    this.getZones()
  }
  ngOnDestroy(): void {
    // nothing
  }

}
