import { Component, Input, OnInit } from '@angular/core';
import { HelperService } from '@app/shared/services/helper.service';
import { RoutesService } from '@app/shared/services/router.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  standalone: false,
  selector: 'app-invoice-actions-dialog',
  templateUrl: './invoice-actions-dialog.component.html',
  styleUrls: ['./invoice-actions-dialog.component.scss']
})
export class InvoiceActionsDialogComponent implements OnInit {

  _hasInvoice: boolean = false

  @Input()
  public set hasInvoice(value: boolean) {
    this._hasInvoice = value;
    this.selectedInvoiceAction = value ? "openInvoice" : "createInvoice"
  }

  public get hasInvoice(): boolean {
    return this._hasInvoice
  }

  @Input() isCloseAndCreateInvoice: boolean = false;
  selectedInvoiceAction: string = "createInvoice";

  constructor(
    public helperService: HelperService,
    public activeModal: NgbActiveModal,
    public routesService: RoutesService,
  ) { }

  invoiceActionsOptions = [
    {
      value: "createInvoice",
      name: "Create Invoice",
    },
    {
      value: "closeAndCreateInvoice",
      name: "Close & Create Invoice",
    },
    {
      value: "openInvoice",
      name: "Open the Invoice",
    },
    {
      value: "updateInvoice",
      name: "Update the Invoice",
    }
  ]

  ngOnInit(): void {
  }

  isHiddenCheck(value: string): boolean {

    let isHidden = false;

    switch (value) {
      case 'createInvoice':
        isHidden = !this.hasInvoice;
        break;
      case 'closeAndCreateInvoice':
        isHidden = !this.hasInvoice && this.isCloseAndCreateInvoice;
        break;
      case 'openInvoice':
      case 'updateInvoice':
        isHidden = this.hasInvoice;
        break;
      default:
        break;
    }

    return !isHidden;
  }

  cancel() {
    this.activeModal.close();
  }

  confirm() {
    this.activeModal.close(this.selectedInvoiceAction);
  }


}
