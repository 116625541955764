import { Directive, Input, Output, HostListener, EventEmitter } from '@angular/core';
import { DialogService } from '../dialog.service';
import { AlertComponent, AlertData } from '../alert/alert.component';

@Directive({
    standalone: false,
    selector: '[alertDialog]'
})
export class AlertDirective {

    data: AlertData = {
        closeText: 'Ok',
    };

    @Input() set message(value) {
        this.data.message = value;
    }
    @Input() set title(value) {
        this.data.title = value;
    }
    @Input() set closeText(value) {
        this.data.closeText = value;
    }

    @Output() callback = new EventEmitter<any>();

    constructor(private dialogService: DialogService) {

    }

    @HostListener('click', ['$event']) onClick($event) {
        $event.stopPropagation();

        const dialogRef = this.dialogService.open(AlertComponent, this.data, { size: 'sm' });

        dialogRef.closed.subscribe(result => {
            this.callback.emit();
        });
    }
}
