import { Component, OnInit, ViewChild } from '@angular/core';
import { PermissionService } from '@app/shared/services/permission.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AddEditPermissionDialogComponent } from '../add-edit-permission-dialog/add-edit-permission-dialog.component';
import { DatatableComponent } from '@app/@shared/datatable';
import { HelperService } from '@app/shared/services/helper.service';

@UntilDestroy()
@Component({
    standalone: false,
    selector: 'app-permission-list',
    templateUrl: './permission-list.component.html',
    styleUrls: ['./permission-list.component.scss']
})
export class PermissionListComponent implements OnInit {

    title = "Permissions";
    @ViewChild('datatable') datatable: DatatableComponent;
    constructor(
        private permissionService: PermissionService,
        private modalService: NgbModal,
        private helperService: HelperService,
    ) {
    }

    ngOnInit() {
    }

    getData = (request?) => {
        return this.permissionService.getAll(request)
    }

    addEditPermission(permission?) {
        const modalRef = this.modalService.open(AddEditPermissionDialogComponent, {
            size: 'sm',
        });
        modalRef.componentInstance.permission = permission ?? {}
        modalRef.closed.pipe(untilDestroyed(this)).subscribe((isReload) => {
            if (isReload) {
                this.datatable?.refresh()
            }
        });
    }

    onDelete(row) {
        this.permissionService.delete(row?.permissionId).then((res) => {
            this.datatable?.refresh();
            this.helperService.successMessage("Permission successfully deleted")
        }).catch((error) => {
            this.helperService.errorMessage(error)
        })
    }

}
