import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DriversRoutingModule } from './drivers-routing.module';
import { DriverListComponent } from './driver-list/driver-list.component';
import { SharedModule } from '@app/shared/shared.module';
import { DatatableModule } from '@app/@shared/datatable';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { DialogsModule } from '@app/@shared/dialogs/dialogs.module';
import { DriverProfessionalDetailsComponent } from './driver-details/driver-professional-details/driver-professional-details.component';
import { RatingModule } from '@app/@shared/rating/rating.module';
import { NgxSelectModule } from '@app/@shared/ngx-select';
import { FormValidateModule } from '@app/@shared/form-validate/form-validate.module';
import { FormPhoneInputModule } from '@app/@shared/forms';
import { DirectivesModule } from '@app/@shared/directives/directives.module';
import { DriverDetailsComponent } from './driver-details/driver-details.component';
import { DriverPersonalInformationComponent } from './driver-details/driver-personal-information/driver-personal-information.component';
import { DriverLicenseComponent } from './driver-details/driver-license/driver-license.component';
import { DriverDrivingExperienceComponent } from './driver-details/driver-driving-experience/driver-driving-experience.component';
import { DriverSettingsComponent } from './driver-details/driver-settings/driver-settings.component';
import { DriverPaymentComponent } from './driver-details/driver-payment/driver-payment.component';
import { DriverHistoryComponent } from './driver-details/driver-history/driver-history.component';
import { CreateDriverComponent } from './create-driver/create-driver.component';
import { DropDownSelectModule } from '@app/@shared/drop-down-select/drop-down-select.module';
import { DriverLayoutComponent } from './driver-layout/driver-layout.component';
import { ListTabsModule } from '@app/@shared/list-tabs/list-tabs.module';
import { SingleSelectModule } from '@app/@shared/single-select/single-select.module';
import { CustomerSelectModule } from '@app/@shared/customer-select/customer-select.module';
import { LicenseTypeSelectModule } from '@app/@shared/license-type-select/license-type-select.module';
import { FilesViewerModalModule } from '@app/@shared/files-viewer-modal/files-viewer-modal.module';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
	declarations: [
		DriverListComponent,
		DriverDetailsComponent,
		DriverPersonalInformationComponent,
		DriverLicenseComponent,
		DriverDrivingExperienceComponent,
		DriverProfessionalDetailsComponent,
		DriverSettingsComponent,
		DriverPaymentComponent,
		DriverHistoryComponent,
		CreateDriverComponent,
		DriverLayoutComponent
	],
	imports: [
		CommonModule,
		SharedModule,
		DriversRoutingModule,
		DatatableModule,
		AccordionModule,
		DialogsModule,
		RatingModule,
		NgxSelectModule,
		FormValidateModule,
		FormPhoneInputModule,
		DirectivesModule,
		DropDownSelectModule,
		ListTabsModule,
		SingleSelectModule,
		CustomerSelectModule,
		LicenseTypeSelectModule,
		FilesViewerModalModule,
		NgbModalModule,
	]
})
export class DriversModule { }
