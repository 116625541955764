import { Component, Input, OnInit, } from '@angular/core';
import { ChargeType } from '@app/models/charge-type.model';
import { EntityTypes } from '@app/models/entity-type.model';
import { RouteItemDisplayStatus, RouteItemStop, RouteItemType } from '@app/models/route-item-stop.model';
import { RouteItemStatus } from '@app/models/route-item.model';
import { ApiService } from '@app/services/api.service';
import { RouteService } from '@app/services/route.service';
import { HelperService } from '@app/shared/services/helper.service';
import { Route } from '@models/route.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmStopDialogComponent } from '../routes-status-block/confirm-stop-dialog/confirm-stop-dialog.component';
import { ChargeTypeService } from '@app/shared/services/charge-type.service';

@Component({
    selector: 'app-route-history',
    templateUrl: './route-history.component.html',
    styleUrls: ['./route-history.component.scss']
})

export class RouteHistoryComponent implements OnInit {
    @Input() routes: Route[];
    @Input() route: Route;
    chargeTypes: ChargeType[];
    EntityTypes = EntityTypes;

    stopStatusTypes: Record<RouteItemDisplayStatus, { class: string; text: string }> = {
        [RouteItemDisplayStatus.OnTime]: { class: 'on-time', text: 'On Time' },
        [RouteItemDisplayStatus.PickedUp]: { class: 'picked-up', text: 'Picked Up' },
        [RouteItemDisplayStatus.PickedUpFromTransfer]: { class: 'picked-up', text: 'Picked Up (T)' },
        [RouteItemDisplayStatus.Late]: { class: 'late', text: 'Late' },
        [RouteItemDisplayStatus.Delivered]: { class: 'delivered', text: 'Delivered' },
        [RouteItemDisplayStatus.DeliveredToTransfer]: { class: 'delivered', text: 'Delivered (T)' },
    }

    constructor(
        private api: ApiService,
        public chargeTypeService: ChargeTypeService,
        private modalService: NgbModal,
        public routeService: RouteService,
        private helperService: HelperService,
    ) {
    }

    ngOnInit() {
        this.chargeTypeService.perStopChargeTypes$
            .subscribe(chargeTypes => this.chargeTypes = chargeTypes);
    }

    getStopTypeLabel(stop: RouteItemStop): string {
        const hasTransferStop = stop.routeItems?.some(
            (item) => item?.transferStopId !== null
        );
    
        switch (stop.type) {
            case RouteItemType.Delivery:
                return hasTransferStop ? 'Delivery To Transfer' : 'Delivery';
            case RouteItemType.Pickup:
                return hasTransferStop ? 'Pickup From Transfer' : 'Pickup';
            case RouteItemType.DeliveryToTransfer:
                return 'Delivery To Transfer';
            case RouteItemType.PickupFromTransfer:
                return 'Pickup From Transfer';
        }
        return '';
    }

    getStopTypeLabelColor(stop: RouteItemStop): string {
        switch (stop.type) {
            case RouteItemType.Delivery:
                return "text-danger";
            case RouteItemType.Pickup:
                return "text-primary";
            case RouteItemType.DeliveryToTransfer:
                return "text-danger";
            case RouteItemType.PickupFromTransfer:
                return "text-primary";
        }

        return '';
    }

    getStopActionLabel(stop: RouteItemStop) {
        switch (stop.type) {
            case RouteItemType.Delivery:
                return 'Delivered';
            case RouteItemType.Pickup:
                return 'Picked Up';
            case RouteItemType.DeliveryToTransfer:
                return 'Delivered To Transfer';
            case RouteItemType.PickupFromTransfer:
                return 'Picked Up From Transfer';
        }
        return '';
    }

    updateRouteStopStatus(stop: RouteItemStop, newStatus: RouteItemStatus) {
        if (newStatus === RouteItemStatus.Done) {
            const confirmStop = stop;
            if (!confirmStop.waitingTime) {
                confirmStop.waitingTime = '';
            }
            if (!confirmStop.arrivalDate) {
                confirmStop.arrivalDate = new Date();
            }
            confirmStop.routeItems.forEach(ri => {
                ri.pickQuantity = ri.quantity;
            });
            confirmStop.charges = this.chargeTypes.map(x => Object.assign({}, x));
            const activeModal = this.modalService.open(ConfirmStopDialogComponent, {
                scrollable: true,
                size: "lg",
            });
            activeModal.componentInstance.routeId = this.route.routeId;
            activeModal.componentInstance.confirmStop = confirmStop;
            activeModal.componentInstance.confirmStopNewStatus = newStatus;
            activeModal.result.then(
                (result) => {
                    if (result) {
                        console.log(result);
                    }
                },
                () => { }
            );
        } else {
            this.api.UpdateRouteStopStatus(this.route.routeId, stop, newStatus, success => {
                this.helperService.successMessage("Route stop status successfully updated.");
                this.refreshRoutes();
            }, error => {
                this.helperService.errorMessage(error);
            });
        }
    }

    refreshRoutes() {
        this.routeService.reloadRoutes();
    }


}
