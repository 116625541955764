import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddEditOrderRoutingModule } from './add-edit-order-routing.module';
import { SharedModule } from '@app/shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { AttachmentsModule } from '@app/@shared/attachments/attachments.module';
import { NgxSelectModule } from '@app/@shared/ngx-select';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DocumentViewerModule } from '@app/@shared/document-viewer/document-viewer.module';
import { DialogsModule } from '@app/@shared/dialogs/dialogs.module';
import { RouteMapModule } from '@app/@shared/route-map/route-map.module';
import { CustomerSelectModule } from '@app/@shared/customer-select/customer-select.module';
import { AddEditOrderComponent } from './add-edit-order/add-edit-order.component';
import { OrderInfoComponent } from './order-info/order-info.component';
import { NgbDatepickerModule, NgbDropdownModule, NgbPopoverModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { RepeaterModule } from '@app/@shared/repeater/repeater.module';
import { PackageDetailsComponent } from './package-details/package-details.component';
import { AttachmentDetailsComponent } from './attachment-details/attachment-details.component';
import { FileUploadModule } from '@app/@shared/file-upload/file-upload.module';
import { CostLinesModule } from '../components/cost-lines/cost-lines.module';
import { FormValidateModule } from '@app/@shared/form-validate/form-validate.module';
import { RatingModule } from '@app/@shared/rating/rating.module';
import { RightSideBarComponent } from './right-side-bar/right-side-bar.component';
import { AddLocationComponent } from './add-location/add-location.component';
import { RouteOrderDetailModule } from '../components/route-order-detail/route-order-detail.module';
import { OrderBillingModule } from '../components/order-billing/order-billing.module';
import { CalenderDialogModule } from '@app/@shared/calender-dialog/calender-dialog.module';
import { CustomerAddressDialogComponent } from './order-info/customer-address-dialog/customer-address-dialog.component';
import { FormsModule } from '@angular/forms';
import { PickupOrderDetailModule } from '../components/pickup-order-detail/pickup-order-detail.module';
import { OrderRoutesModule } from '../components/order-routes/order-routes.module';
import { FormPhoneInputModule } from '@app/@shared/forms';
import { DirectivesModule } from '@app/@shared/directives/directives.module';
import { AddCustomerContactDialogModule } from '../components/add-customer-contact-dialog/add-customer-contact-dialog.module';
import { AttachmentPreviewModule } from '@app/@shared/attachment-preview/attachment-preview.module';
import { DropDownSelectModule } from '@app/@shared/drop-down-select/drop-down-select.module';
import { AdditionalChargesDropDownSelectModule } from '@app/@shared/additional-charges-drop-down-select/additional-charges-drop-down-select.module';
import { PreviousNextRedirectButtonsModule } from '@app/@shared/previous-next-redirect-buttons/previous-next-redirect-buttons.module';
import { OrderPriorityModule } from '@app/@shared/order-priority/order-priority.module';
import { WarningStopsDepartureDialogComponent } from './order-info/warning-stops-departure-dialog/warning-stops-departure-dialog.component';
import { WarehouseSelectorComponent } from './warehouse-selector/warehouse-selector.component';
import { OrderDeleteDialogModule } from '../components/order-delete-dialog/order-delete-dialog.module';
import { InvoiceUpdateConfirmationDialogComponent } from '../components/invoice-update-confirmation-dialog/invoice-update-confirmation-dialog.component';


@NgModule({
  declarations: [
    AddEditOrderComponent,
    OrderInfoComponent,
    PackageDetailsComponent,
    RightSideBarComponent,
    AttachmentDetailsComponent,
    AddLocationComponent,
    CustomerAddressDialogComponent,
    WarningStopsDepartureDialogComponent,
    WarehouseSelectorComponent,
    CustomerAddressDialogComponent,
    InvoiceUpdateConfirmationDialogComponent
  ],
  exports:[AddEditOrderComponent],  
  imports: [
    CommonModule,
    FormsModule,
    AddEditOrderRoutingModule,
    SharedModule,
    NgSelectModule,
    AttachmentsModule,
    NgxSelectModule,
    DragDropModule,
    DocumentViewerModule,
    DialogsModule,
    CustomerSelectModule,
    RouteMapModule,
    NgbTypeaheadModule,
    RepeaterModule,
    PickupOrderDetailModule,

    FileUploadModule,
    CostLinesModule,
    DocumentViewerModule,
    RatingModule,
    FormValidateModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbPopoverModule,
    RouteOrderDetailModule,
    OrderBillingModule,
    CalenderDialogModule,

    OrderRoutesModule,
    FormPhoneInputModule,
    DirectivesModule,
    AddCustomerContactDialogModule,
    AttachmentPreviewModule,
    DropDownSelectModule,
    AdditionalChargesDropDownSelectModule,
    PreviousNextRedirectButtonsModule,
    OrderPriorityModule,
    OrderDeleteDialogModule
  ]
})
export class AddEditOrderModule { }
