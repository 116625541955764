import {
    Component,
    Input,
} from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import { RouteService } from '@app/services/route.service';

@Component({
    standalone: false,
    selector: 'app-route-details-brief',
    templateUrl: './route-details-brief.component.html',
    styleUrls: ['./route-details-brief.component.scss']
})
export class RouteDetailsBriefComponent {
    @Input() previousStopAddressDetails: string;
    @Input() nextStopAddressDetails: string;
    @Input() previousStop: string;
    @Input() nextStop: string

    constructor(
        public util: UtilService,
        public routeService: RouteService,
    ) {
    }   


}
