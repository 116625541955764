import { AfterViewInit, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgModel, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ApiPaginationData } from '@app/models/core/base';
import { Driver, DriverStatusEnum } from '@app/models/driver.model';
import { DriverService } from '@app/shared/services/driver.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { from, of } from 'rxjs';
import { catchError, debounceTime, switchMap, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  standalone: false,
  selector: 'app-driver-select',
  templateUrl: './driver-select.component.html',
  styleUrls: ['./driver-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DriverSelectComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.Emulated
})
export class DriverSelectComponent implements OnInit, AfterViewInit, OnDestroy {

  searchTerm: string;
  search: string;
  drivers: Driver[];
  searchingDriver: boolean;
  disabled: boolean;
  hasMorePage: boolean = false;
  page: number = 1;

  @ViewChild('dropdown') dropdown: NgbDropdown;
  @ViewChild('searchInput') searchInput: NgModel;

  onChange = (value: Driver) => { };
  onTouched = () => { };

  @Output() driverChange = new EventEmitter<any>();
  @Input() initialLoad: boolean;
  @Input() type: 'normal' | 'custom' = 'normal';
  @Input() driverStatus: DriverStatusEnum = DriverStatusEnum.ACTIVE


  private _value: Driver;
  get value(): Driver {
    return this._value;
  }
  set value(updateValue: Driver) {
    this._value = updateValue;
    this.onChange(updateValue);
    this.onTouched();
  }


  constructor(
    private driverService: DriverService,
  ) { }


  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.searchInput.valueChanges
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.searchingDriver = true;
          this.drivers = [];
        }),
        debounceTime(500),
        switchMap(term => {
          this.page = 1;
          this.searchTerm = term ? term : '';
          const request = this.getDrivers()
          return from(request).pipe(
            catchError(() => of([]))
          )
        }),
      ).subscribe((val) => {
        // nothing
      })


    if (this.initialLoad) {
      this.getDrivers()
    }
  }

  loadMoreDriver() {
    this.page++;
    this.getDrivers()
  }

  getDrivers() {
    this.searchingDriver = true
    const request = {
      ...this.searchTerm ? { SearchTerm: this.searchTerm } : {},
      ...this.driverStatus ? { DriverStatus: this.driverStatus } : {},
      SortBy: 'createdTime',
      SortDirection: 'Descending' as 'Ascending' | 'Descending',
      Page: this.page,
      ItemsPerPage: 20,
    }
    this.driverService.cancelPendingRequestGetAll();
    let resp: Promise<ApiPaginationData<Driver>>;
    resp = this.driverService.getAll(request)
    resp.then((resp) => {
      const { list, totalCount, itemsPerPage } = resp;
      this.hasMorePage = totalCount > (this.page * itemsPerPage);
      if (this.page > 1) {
        this.drivers = this.drivers.concat(list);
      } else {
        this.drivers = list;
      }
    }).finally(() => {
      this.searchingDriver = false
    })
    return resp;
  }

  selectedDrivers(Driver) {
    this.value = Driver;
    this.dropdown.close();
    this.driverChange.emit(this.value);
  }

  writeValue(value: Driver) {
    this._value = value
  }

  registerOnChange(fn: (value: Driver) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  clearValue(): void {
    this.value = null;
    this.driverChange.emit(true);
    this.searchTerm = 'a';
    this.getDrivers()
  }

  ngOnDestroy(): void {
    // nothing
  }

}
