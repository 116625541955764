import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { OrderStop, OrderStopDateTypeEnum, OrderStopTimeTypeEnum, OrderStopTypeEnum } from '@app/models/order-stop.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Address, AddressCompanyInfo } from '@app/models/address.model';
import { HelperService } from '@app/shared/services/helper.service';
import { Order } from '@app/models/order.model';
import { cloneDeep, find, findIndex, flatMap, get, merge, minBy } from 'lodash';
import { OrderService } from '@app/shared/services/order.service';
import { object, string } from 'yup';
import { Customer } from '@app/models/customer.model';
import { FormValidateDirective } from '@app/@shared/form-validate/form-validate.directive';
import moment from "moment"
import { CustomerService } from '@app/shared/services/customer.service';
import { patterns } from '@app/@shared/regex/regex-patterns.const';
import { PhoneIsoCodeExtensionInputComponent } from '@app/@shared/phone-iso-code-extension-input/phone-iso-code-extension-input/phone-iso-code-extension-input.component';

@UntilDestroy()
@Component({
  	standalone: false,
	selector: 'edit-order-stop-address-dialog',
	templateUrl: './edit-order-stop-address-dialog.component.html'
})
export class EditOrderStopAddressDialogComponent implements OnInit {
	OrderStopTypeEnum = OrderStopTypeEnum;
	address: Address;
	companiesNames: { address: Address, companyName: string }[];
	@Input() orderStopType: OrderStopTypeEnum;
	defaultAddress = {
		fullAddress: null,
		name: null,
		addressLine2: null,
		zip: null
	}

	validationSchema = object().shape({
		address: object().shape({
			name: string().label('Company Name').nullable(),
			fullAddress: string().label('Select Address').nullable().required("Select address is a required field")
		}),
		locationContactNumber: string().nullable().matches(patterns.phoneWithExtension, { excludeEmptyString: true, message: 'Company phone must be a number' }),
	});

	private _orderStop: OrderStop;

	selectedCompany: AddressCompanyInfo;
	addressCustomer: Customer;
	minDeliveryDate: string;

	public get orderStop(): OrderStop {
		return this._orderStop;
	}

	@Input() public set orderStop(value: OrderStop) {
		if (!value?.orderStopId) {
			this._orderStop = merge({
				orderStopType: this.orderStopType,
				isActive: true,
				address: { ...this.defaultAddress },
				orderItemStops: [],
				dateType: OrderStopDateTypeEnum.NONE,
				date: moment().startOf('day').toDate(),
				timeType: OrderStopTimeTypeEnum.NOON,
				time: moment.utc(15, "HH").toDate(),
				attachments: [],
				referenceNumber: null,
				blindCustomer: null,
				blindAddress: null,
				blindCustomerName: '',
				blindAddressFullAddress: '',
			}, value);
		} else {
			this._orderStop = cloneDeep({
				...value,
				blindAddress: null,
				blindAddressAddressId: null,
				blindCustomer: null,
				blindCustomerCustomerId: null,
				dateType: OrderStopDateTypeEnum.NONE,
			});
		}
	}
	@ViewChild('locationContactPhoneExtensionInput', { static: false }) locationContactPhoneExtensionInput: PhoneIsoCodeExtensionInputComponent;
	@Input() order: Order;

	constructor(
		private readonly orderService: OrderService,
		private readonly activeModal: NgbActiveModal,
		private readonly helperService: HelperService,
		private readonly customerService: CustomerService,
	) {

	}

	ngOnInit(): void {
		this.orderService.order$.pipe(untilDestroyed(this)).subscribe(
			async (order) => {
				const deliveryOrderStops = order?.orderStops?.filter((orderStop) => {
					return orderStop.orderStopType === OrderStopTypeEnum.DELIVERY
				})
				this.minDeliveryDate = minBy(deliveryOrderStops, 'dateTimeToDisplay')?.dateTimeToDisplay || null
				if (!this.orderStop.orderStopId && this.minDeliveryDate) {
					this.orderStop = { ...this.orderStop, date: moment(this.minDeliveryDate).startOf('day').toDate() as any }
				}
			}
		);
	}

	close() {
		this.activeModal.close();
	}

	changeCustomerName(customerName) {
		this.orderStop.locationCompanyName = customerName;
	}

	changeCustomer({ address, companyName }) {
		var oldCompanyName = `${this.orderStop.locationCompanyName}`;
		let isOldCompanyName = companyName == this.orderStop.locationCompanyName ||
			this.companiesNames.find(x => x.address?.name == this.orderStop.locationCompanyName && x.address?.addressCustomerInfo?.customerName == companyName);

		this.orderStop.locationCompanyName = address?.name || companyName;

		if (address) {
			if (oldCompanyName == this.orderStop.locationCompanyName)
				this.orderStop.locationCompanyName += " ";

			this.setMainCompanyName(address);
			this.handleAddressChange(address)
		} else if (!isOldCompanyName) {
			this.orderStop.locationContactNumber = '';
			this.orderStop.locationContactEmail = '';
			this.locationContactPhoneExtensionInput.clearValue();
		}
	}

	handleAddressChange(address: Address) {
		this.orderStop.address = address;

		this.addCustomerAddressCompanyInfo(address);
		this.setAddress(address)
	}

	handleSameAddressesUpdated(addresses: Address[]) {
		this.companiesNames = addresses.filter(x => x.name).map(x => {
			var companyName = x.name || "";

			var customerName = x?.addressCustomerInfo?.customerName;
			if (customerName && customerName != companyName)
				companyName += ` (${customerName})`;

			return { address: x, companyName };
		});

		for (const address of addresses) {
			this.setMainCompanyName(address);
		}
	}

	setMainCompanyName(address: Address) {
		var addressCustomerName = address?.addressCustomerInfo?.customerName;
		if (addressCustomerName) {
			var existed = this.companiesNames.find(x => x.companyName == addressCustomerName);
			if (!existed)
				this.companiesNames.push({ address: null, companyName: addressCustomerName });
		}
	}

	addCustomerAddressCompanyInfo(address) {
		if (address?.addressId) {
			let addressName = address.name;
			let customerName = address.addressCustomerInfo?.customerName;

			this.orderStop.address.addressCompanyInfo = [];

			if (addressName) {
				this.orderStop.address.addressCompanyInfo.push({ companyName: addressName, phoneNumbers: [], emails: [] });
			}

			if (customerName != addressName) {
				this.orderStop.address.addressCompanyInfo.push({ companyName: customerName, phoneNumbers: [], emails: [] });
			}
		}
	}

	handleRemoveAddressInfo(address) {
		if (address === this.defaultAddress || (typeof address === 'string')) {
			this.orderStop.address = { ...this.defaultAddress }
			this.orderStop.locationContactNumber = null
			this.orderStop.locationContactEmail = null
			this.locationContactPhoneExtensionInput.clearValue();
		}
	}

	setAddress(address: Address) {
		if (!this.orderStop.address) {
			this.orderStop.address = { ...this.defaultAddress }
			this.orderStop.locationContactNumber = null
			this.orderStop.locationContactEmail = null
			this.locationContactPhoneExtensionInput.clearValue();
			return;
		}

		this.addressCustomer = null
		var companyName = address?.addressCompanyInfo &&
			address?.addressCompanyInfo.length &&
			address.addressCompanyInfo[0].companyName;
		if (address.customerId) {
			this.customerService.get(address.customerId).then((data) => {
				this.addressCustomer = data
				const contact = data?.customerContacts?.filter((item) => !!item.phone)[0]
				this.orderStop.locationContactNumber = contact.phone
				this.orderStop.locationContactCountryCode = contact.phoneCountryCode
				this.orderStop.locationContactCountryIso = contact.phoneCountryIso
				this.orderStop.locationContactEmail = data?.customerContacts?.filter((item) => !!item.email)[0]?.email
				address = this.setCustomerData(address, companyName);
				this.orderStop.address = address;
			})
		} else {
			address = this.setCustomerData(address, companyName);
			this.orderStop.address = address;
		}
	}

	setCustomerData(address: Address, companyName?: string) {
		const selectedAddressCompanyInfo = address.addressCompanyInfo?.find(aci => aci.companyName === companyName);
		this.selectedCompany = selectedAddressCompanyInfo;

		this.orderStop.locationCompanyName = address.name || selectedAddressCompanyInfo?.companyName || '';

		if (address.phoneNumber || address.email) {
			this.orderStop.locationContactNumber = address.phoneNumber || '';
			this.orderStop.locationContactEmail = address.email || '';
		}

		return address;
	}

	async saveAndClose(form: FormValidateDirective) {
		if (!form.isValid) {
			return false;
		}

		this.helperService.isLoading = true;
		try {
			await this.saveOrder();
			this.close();
		} catch (error) {
			const warehouseForPickupDeliveryStopIds = error?.error.data?.warehouseForPickupDeliveryStopIds;
			const otherMessage = error?.error?.otherMessages[0]?.message;

			if (otherMessage && warehouseForPickupDeliveryStopIds && Object.keys(warehouseForPickupDeliveryStopIds).length) {
				this.helperService.addToast(error?.error?.otherMessages[0]?.message, 'Select Hub', 'warning');
			}
			else {
				console.log('Error while save stops', error);
				this.helperService.errorMessage(error);
			}
		} finally {
			// If API failed to save, then remove order stop from order
			this.order.orderStops = this.order.orderStops.filter((orderStop: OrderStop) => !!orderStop.orderStopId);
		}
		this.helperService.isLoading = false;
	}

	async saveOrder() {
		if (!this.orderStop.address) {
			throw new Error('Please select pickup or delivery address');
		}

		const { orderStops = [] } = this.order;
		if (orderStops.length === 0) {
			this.order.orderStops.push(this.orderStop);
			this.order = await this.orderService.saveOrder(this.order);
			this.orderStop = this.order.orderStops[0];
		} else if (this.orderStop.orderStopId) {
			const orderItems = flatMap(get(this.order, 'orderStops', []), orderStop =>
				flatMap(get(orderStop, 'orderItemStops', []), orderItemStop =>
				  get(orderItemStop, 'orderItem', null)
				)
			);
			let { orderStops = [] } = this.order;
			const { orderStopId } = this.orderStop;
			const index = findIndex(this.order.orderStops, { orderStopId: orderStopId });
			orderStops.splice(index, 1, this.orderStop);

			orderStops?.map((orderStop) => {
				return orderStop?.orderItemStops?.map((orderItemStop) => {
					if (!orderItemStop?.orderItem && orderItemStop?.orderItemId) {
						orderItemStop.orderItem = orderItems?.find((orderItem) => orderItem?.orderItemId == orderItemStop?.orderItemId)
					}
					return orderItemStop;
				})
			})
			
			this.order.orderStops = orderStops;

			this.order = await this.orderService.saveOrder(this.order);
			this.orderStop = await find(this.order.orderStops, { orderStopId: orderStopId });

			this.activeModal.close(this.orderStop);
		} else {
			const { orderId } = this.order;
			// Create new order stop

			const orderStop = await this.orderService.createOrderStop(orderId, this.orderStop);
			if (orderStop) {
				delete orderStop.order
				this.orderStop = orderStop;
				this.order.orderStops.push(orderStop);
			}
			// Assign new order to the exit order page
			this.orderService.order = Object.assign({}, this.order);
		}
	}
}
