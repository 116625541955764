import { Component, Input } from '@angular/core';
import { NgxGalleryOptions } from '@kolkov/ngx-gallery';

@Component({
    standalone: false,
    selector: 'app-image-viewer',
    templateUrl: './image-viewer.component.html',
    styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent {
    @Input() imageUrl: string = '';

    galleryOptions: NgxGalleryOptions[] = [
        {
            width: '100%',
            height: '100%',
            thumbnails: false,
            previewZoom: true,
            previewArrows: false,
            previewSwipe: false,
            imageArrows: false,
            imageSwipe: false,
            imageAutoPlay: false,
            imageInfinityMove: false,
            imageSize: 'contain',
            closeIcon: 'fas fa-times',
            zoomInIcon: 'fas fa-search-plus',
            zoomOutIcon: 'fas fa-search-minus',
        }
    ];

    constructor() { }

}