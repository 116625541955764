import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Order } from '@app/models/order.model';
import { chain, cloneDeep, findIndex } from 'lodash';
import { OrderStop, OrderStopTypeEnum } from '@app/models/order-stop.model';
import { EditPickupDialogComponent } from '../edit-pickup-dialog/edit-pickup-dialog.component';
import { EditDeliveryDialogComponent } from '../edit-delivery-dialog/edit-delivery-dialog.component';
import { HelperService } from '@app/shared/services/helper.service';
import { OrderService } from '@app/shared/services/order.service';
import { QuoteService } from '@app/shared/services/quote.service';
import { EditPickupItemDialogComponent } from '../edit-pickup-item-dialog/edit-pickup-item-dialog/edit-pickup-item-dialog.component';
import { EditDeliveryItemDialogComponent } from '../edit-delivery-item-dialog/edit-delivery-item-dialog/edit-delivery-item-dialog.component';

@Component({
  	standalone: false,
	selector: 'app-pickup-order-detail',
	templateUrl: './pickup-order-detail.component.html',
	styleUrls: ['./pickup-order-detail.component.scss']
})
export class PickupOrderDetailComponent implements OnInit {

	pickups: OrderStop[] = [];
	deliveries: OrderStop[] = [];

	private _order: Order;
	public get order(): Order {
		return this._order;
	}
	@Input() public set order(value: Order) {
		value = this.updateOrderData(value);
		this._order = value;
	}

	@Output() onOrderChange = new EventEmitter<Order>()
	@Output() onOrderUpdate = new EventEmitter<number>()
	@Output() reloadOrder = new EventEmitter<boolean>();

	private _allowAddPickup: boolean = true;
	public get allowAddPickup(): boolean {
		const canAdd = this.deliveries?.length <= 1 || (this.deliveries?.length > 1 && this.pickups?.length === 0);
		return this._allowAddPickup && canAdd;
	}
	@Input() public set allowAddPickup(value: boolean) {
		this._allowAddPickup = value;
	}

	get showSwapButton() {
		if (((this.deliveries?.length >= 1 && this.pickups.length == 1) || (this.pickups.length >= 1 && this.deliveries?.length == 1)) &&
			this.pickups[0].orderItemStops?.length > 0 && this.deliveries[0].orderItemStops?.length > 0) {
			return true
		}
		return false
	}

	get allowAddDelivery(): boolean {
		return this.pickups?.length <= 1 || (this.pickups?.length > 1 && this.deliveries?.length === 0);
	}

	constructor(
		private helperService: HelperService,
		private modalService: NgbModal,
		private quoteService: QuoteService,
		private orderService: OrderService,
	) { }

	ngOnInit(): void {
	}

	handleReloadOrder() {
		this.reloadOrder.emit(true)
	}

	async swapAddress() {
		this.helperService.isLoading = true;
		this.quoteService.swapOrder(this.order?.orderId).then(async () => {
			this.onOrderUpdate.emit(this.order?.orderId)
		}).catch((error) => {
			this.helperService.errorMessage(error)
		}).finally(() => {
			this.helperService.isLoading = false;
		})
	}

	saveOrder(updateRequest?: Partial<OrderStop>) {
		this.order.orderStops = this.order.orderStops.map((orderStop) => {
			if (orderStop.orderStopId === updateRequest.orderStopId) {
				return updateRequest;
			}
			return orderStop;
		});
		this.onOrderChange.emit(this.order)
	}


	updateOrderData(order: Order) {

		order.orderStops.forEach(os => {
			os.date = new Date(os.date) as any;
			os.time = new Date(os.time) as any;
		});

		if (order.orderStops) {
			let pickupIndex = 0;
			let deliveryIndex = 0;
			let pickupTransferIndex = 0;
			let deliveryTransferIndex = 0;

			const hasTransfer = (stop) =>
				stop.routeItems?.some(item => item?.transferStopId !== null);

			const processStops = (stops, type, indexRef, transferIndexRef) =>
				chain(stops)
					.filter({
						orderStopType: type,
						isActive: true
					})
					.sortBy(['sortOrder', 'orderStopId'])
					.map(stop => {
						if (hasTransfer(stop)) {
							stop.transferIndex = ++transferIndexRef;
						} else {
							stop.index = ++indexRef;
							stop.title = `${type === OrderStopTypeEnum.PICKUP ? 'Pickup' : 'Delivery'} #${stop.index}`;
						}
						return stop;
					})
					.value();

			const pickups = processStops(order.orderStops, OrderStopTypeEnum.PICKUP, pickupIndex, pickupTransferIndex);
			const deliveries = processStops(order.orderStops, OrderStopTypeEnum.DELIVERY, deliveryIndex, deliveryTransferIndex);

			const findMatchingStop = (stop, targetArray) =>
				targetArray.find(os =>
					os.routeItems?.some(item =>
						item?.orderItemId === stop.routeItems?.[0]?.orderItemId
					)
				);

			this.pickups = pickups.map(stop => {
				if (hasTransfer(stop)) {
					const matchingDelivery = findMatchingStop(stop, deliveries);
					stop.title = `Pickup from Transfer #${stop?.transferIndex ?? '-'} - Delivery #${matchingDelivery?.index ?? '-'}`;
				}
				return stop;
			});

			this.deliveries = deliveries.map(stop => {
				if (hasTransfer(stop)) {
					const matchingPickup = findMatchingStop(stop, pickups);
					stop.title = `Delivery for Transfer #${stop?.transferIndex ?? '-'} - Pickup #${matchingPickup?.index ?? '-'}`;
				}
				return stop;
			});
		};

		order.dateType = 'None'

		return order
	}

	async deleteStop(orderStop: OrderStop) {
		this.helperService.isLoading = true;
		const initialOrder = cloneDeep(this.order)
		orderStop.isActive = false;
		orderStop.orderItemStops?.forEach(element => element.isActive = false);
		const index = findIndex(this.order.orderStops, { orderStopId: orderStop.orderStopId })
		this.order.orderStops.splice(index, 1, orderStop)
		try {
			await this.orderService.saveOrder(this.order);
		} catch (error) {
			this.orderService.order = initialOrder
			this.order = initialOrder
			this.helperService.errorMessage(error)
		} finally {
			this.helperService.isLoading = false;
		}
	}




	addEditOrderStop({ type, dialogType = 'stop', item }: {
		type: 'pickup' | 'delivery', dialogType?: 'stop' | 'item', item?: OrderStop
	}) {
		if (!item) {
			item = {}
		}
		if (!this.order?.customer) {
			this.helperService.errorMessage("You have not selected any customer yet.")
		} else if (this.order?.shipmentType) {

			let component: any;

			if (type === 'pickup') {
				component = dialogType === 'stop' ? EditPickupDialogComponent : EditPickupItemDialogComponent
			} else {
				component = dialogType === 'stop' ? EditDeliveryDialogComponent : EditDeliveryItemDialogComponent
			}

			const activeModal = this.modalService.open(component, {
				scrollable: true,
				size: "xl",
				windowClass: 'custom-style'
			});
			activeModal.componentInstance.order = cloneDeep(this.order);
			activeModal.componentInstance.orderStop = cloneDeep(item);
			activeModal.componentInstance.isEdit = Boolean(item?.orderStopId);

		} else {
			this.helperService.errorMessage("You have not any shipment type.")
		}
	}
}
