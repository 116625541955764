import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    standalone: false,
    selector: 'app-toggle',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent {
    @Input() state: boolean;
    @Output() toggleClick: EventEmitter<boolean> = new EventEmitter<boolean>();

    get title(): string {
        return this.state ? 'Enabled' : 'Disabled';
    }

    clicked() {
        this.state = !this.state;
        this.toggleClick.emit(this.state);
    }
}
