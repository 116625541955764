// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host form-phone-input ::ng-deep .form-group {
  margin-bottom: 0;
}
:host form-phone-input ::ng-deep .form-group app-form-input-error p {
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/orders/components/add-customer-contact-dialog/add-customer-contact-dialog.component.scss"],"names":[],"mappings":"AAKY;EACI,gBAAA;AAJhB;AAMwB;EACI,gBAAA;AAJ5B","sourcesContent":["@import \"var\";\n\n:host {\n    form-phone-input {\n        ::ng-deep {\n            .form-group {\n                margin-bottom: 0;                \n                    app-form-input-error {\n                        p {\n                            margin-bottom: 0;\n                        }\n                    }                \n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
