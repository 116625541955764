import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FilesViewerModalComponent } from '@app/@shared/files-viewer-modal/files-viewer-modal.component';
import { patterns } from '@app/@shared/regex/regex-patterns.const';
import { stateOptions } from '@app/data/state';
import { Country } from '@app/models/address.model';
import { Driver, DriverEmploymentEnum, DriverPayoutEnum } from '@app/models/driver.model';
import { ImageUploadTypeEnum } from '@app/models/image.model';
import { OutsourceCompany } from '@app/models/outsource-company.model';
import { AddressService } from '@app/shared/services/address.service';
import { DriverService } from '@app/shared/services/driver.service';
import { HelperService } from '@app/shared/services/helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { number, object, string } from 'yup';

@Component({
  standalone: false,
  selector: 'app-create-driver',
  templateUrl: './create-driver.component.html',
  styleUrls: ['./create-driver.component.scss'],
  host: {
    '[class.page-container]': "true"
  }
})
export class CreateDriverComponent implements OnInit {

  selectedOutsourceCompany: OutsourceCompany;

  driver: Driver = {
    firstName: "",
    middleName: "",
    lastName: "",
    phoneNumber: "",
    cellNumber: "",
    email: "",
    addressLine1: "",
    city: "",
    state: "",
    zip: null,
    employment: null,
    payout: null,
    ssn: "",
    gender: "",
    dob: null,
    licenseNumber: "",
    licenseType: "",
    licenseCountry: "",
    licenseState: "",
    licenseExpires: null,
  };
  DriverEmploymentEnum = DriverEmploymentEnum;
  DriverPayoutEnum = DriverPayoutEnum;
  ImageUploadTypeEnum = ImageUploadTypeEnum;
  states = stateOptions;
  submitLoading: boolean;

  validationSchema = object().shape({
    firstName: string().nullable().max(256).required(),
    lastName: string().nullable().max(256).required(),
    middleName: string().nullable().max(256),
    phoneNumber: string().nullable().matches(patterns.phone, { excludeEmptyString: true, message: 'Phone must be a number' }),
    cellNumber: string().nullable().matches(patterns.phone, { excludeEmptyString: true, message: 'Cell must be a number' }),
    email: string().label('Email').nullable().email().max(256),
    addressLine1: string().label("Address").nullable().max(256),
    zip: string().label("Zip code").nullable().max(32)
  });
  countries: Country[];

  constructor(
    private router: Router,
    public driverService: DriverService,
    private helperService: HelperService,
    private addressService: AddressService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.addressService.getCountries().then(res => {
			this.countries = res;
		}).catch((error) => {
			this.countries = [];
			this.helperService.errorMessage(error)
		})
  }

  onOutsourceCompanySelect(outsourceCompany: OutsourceCompany) {
    this.driver.outsourceCompany = outsourceCompany
    this.driver.outsourceCompanyId = outsourceCompany?.id
  }

  handleSubmit(f) {
    if (f.isValid) {
      this.submitLoading = true;
      this.driverService.create(this.driver).then((res) => {
        this.router.navigate(['/app/drivers'], { queryParams: { id: res.driverId } });
        this.helperService.successMessage('Driver Successfully Created.');
        this.submitLoading = false;
      }).catch((error) => {
        this.helperService.errorMessage(error);
        this.submitLoading = false;
      })
    }
  }

  async uploadLicenseFile(event) {
    const files = event.target.files;
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      const request = {
        file: file,
        type: ImageUploadTypeEnum.DRIVER,
      }
      this.driverService.uploadImage(request).then((resp: any) => {
        this.driver.licenseImage = resp?.data
      });
    }
  }

  async uploadInsuranceFile(event) {
    const files = event.target.files;
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      const request = {
        file: file,
        type: ImageUploadTypeEnum.DRIVER,
      }
      this.driverService.uploadImage(request).then((resp: any) => {
        this.driver.insurancenCardImage = resp?.data
      });
    }
  }

  openImageViewer(imageUrl: string, title: string) {
    const modalRef = this.modalService.open(FilesViewerModalComponent, {
      size: 'xl',
      centered: true,
    });
    modalRef.componentInstance.files = [{
      url: imageUrl,
      name: imageUrl?.split('/').pop()
    }]
    modalRef.componentInstance.title = title;
  }

}
