import { Component, OnInit, Input, Output, EventEmitter, forwardRef, ViewChild, } from "@angular/core";
import { debounceTime, distinctUntilChanged, filter, switchMap, } from "rxjs/operators";
import { Address } from "@models/address.model";
import { Observable } from "rxjs/internal/Observable";
import { from } from "rxjs/internal/observable/from";
import { of } from "rxjs/internal/observable/of";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Subject, merge } from "rxjs";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { isEqual } from "lodash";
import { CustomerService } from "@app/shared/services/customer.service";

@UntilDestroy()
@Component({
    standalone: false,
    selector: "app-address-lookup",
    templateUrl: "./address-lookup.component.html",
    styleUrls: ["./address-lookup.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AddressLookupComponent),
            multi: true
        }
    ],
})
export class AddressLookupComponent implements OnInit {
    private _value: any;
    private _searchResult: Address[];


    @ViewChild('instance', { static: true }) instance: NgbTypeahead;

    @Output() addressUpdated = new EventEmitter<Address>();
    @Output() sameAddressesUpdated = new EventEmitter<Address[]>();

    @Input() customerId = 0;
    @Input() disabled = false;

    @Input() placeholder: string = 'Enter address';
    @Input() isFullAddress: boolean = false;
    @Input() initialLoading: boolean = false;
    @Input() addCustomerName: boolean = false;
    @Input() options: Partial<Address>[];

    @Input() defaultValue: Partial<Address> = {
        name: null,
        zip: null
    }

    focus$ = new Subject<string>();
    click$ = new Subject<string>();
    searching: boolean;
    searchingSameAddresses: boolean;
    onChange: any = () => { };
    onTouched: any = () => { };

    get value(): any {
        return this._value;
    }
    set value(updateValues: any) {
        updateValues = this.isFullAddress ? ((typeof updateValues === 'object') ? updateValues.fullAddress : updateValues) : updateValues;
        this._value = updateValues
        this.onChange(updateValues);
        this.onTouched(updateValues);
    }

    get isAddressSelected() {
        return !isEqual(this._value, this.defaultValue)
    }

    formatter = (x: { fullAddress: string }) => this.isFullAddress ? ((typeof x === 'object') ? x.fullAddress : x) : x.fullAddress;

    search = (text$: Observable<string>) => {

        const debouncedText$ = text$.pipe(
            debounceTime(200),
            distinctUntilChanged()
        );

        const clicksWithClosedPopup$ = this.click$.pipe(filter(() => (Boolean(this.options) || this.initialLoading) && !this.instance.isPopupOpen()));
        const inputFocus$ = this.focus$.pipe(filter(() => Boolean(this.options) || this.initialLoading));

        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$)
            .pipe(
                switchMap((term) => {

                    if (this.options?.length > 0) {
                        let data: Partial<Address>[] = [];
                        if (!term) {
                            data = this.options
                        } else {
                            data = this.options.filter((v) => {
                                return v.fullAddress?.toLowerCase().indexOf(term?.toLowerCase()) > -1 ||
                                    v.addressLine1?.toLowerCase().indexOf(term?.toLowerCase()) > -1 ||
                                    v.addressLine2?.toLowerCase().indexOf(term?.toLowerCase()) > -1 ||
                                    v.name?.toLowerCase().indexOf(term?.toLowerCase()) > -1
                            })
                        }
                        return of(data);

                    } else {
                        if (!term) {
                            return of([]);
                        }
                        this.searching = true
                        return from(this.customerService.searchCustomerAddress(this.customerId, term).then((res) => {
                            this._searchResult = res?.list || [];
                            return this._searchResult.filter((value, index, array) => array.findIndex(x => x.fullAddress?.replace(/\s/g, '')?.toLowerCase() == value.fullAddress?.replace(/\s/g, '')?.toLowerCase()) === index);
                        }).catch((error) => {
                            return of([]);
                        }).finally(() => {
                            this.searching = false
                        }))

                    }
                }),
            );
    }


    constructor(private customerService: CustomerService,) { }

    ngOnInit() {
    }

    clearValue() {
        this.value = this.defaultValue;
        this.addressUpdated.emit(this.value);
    }

    writeValue(value: any) {
        this._value = value || this.defaultValue
    }

    registerOnChange(fn: (rating: number) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    selectAddress(e): void {
        const value = this.isFullAddress ? e?.item?.fullAddress : e?.item
        this.onChange(value);
        this.addressUpdated.emit(value);

        this.searchingSameAddresses = true;
        this.customerService.searchCustomerAddress(this.customerId, e?.item?.fullAddress).then((res) => {
            this.sameAddressesUpdated.emit(res?.list || []);
        }).finally(() => {
            this.searchingSameAddresses = false
        })
    }

    getSearchResultValue(address: Address) {
        var result = address.fullAddress;

        var customerName = address?.addressCustomerInfo?.customerName;
        if (customerName && this.addCustomerName)
            result += ` (${customerName})`;

        return result
    }

}
