import { lowerCase } from "lodash"


export function getCountryWith() {
    let CountryList = [
        {
            "isoCode": "US",
            "flagName": "us",
            "name": "United States",
            "mask": "(999) 999-9999",
            "phoneCode": "+1 "
        },
        {
            "isoCode": "CA",
            "flagName": "ca",
            "name": "Canada",
            "mask": "(999) 999-9999",
            "phoneCode": "+1 "
        },
        {
            "isoCode": "BS",
            "flagName": "bs",
            "name": "Bahamas",
            "mask": "999-9999",
            "phoneCode": "+1 (242) "
        },
        {
            "isoCode": "BB",
            "flagName": "bb",
            "name": "Barbados",
            "mask": "999-9999",
            "phoneCode": "+1 (246) "
        },
        {
            "isoCode": "AI",
            "flagName": "ai",
            "name": "Anguilla",
            "mask": "999-9999",
            "phoneCode": "+1 (264) "
        },
        {
            "isoCode": "AG",
            "flagName": "ag",
            "name": "Antigua and Barbuda",
            "mask": "999-9999",
            "phoneCode": "+1 (268) "
        },
        {
            "isoCode": "VG",
            "flagName": "vg",
            "name": "British Virgin Islands",
            "mask": "999-9999",
            "phoneCode": "+1 (284) "
        },
        {
            "isoCode": "VI",
            "flagName": "vi",
            "name": "U.S. Virgin Islands",
            "mask": "999-9999",
            "phoneCode": "+1 (340) "
        },
        {
            "isoCode": "KY",
            "flagName": "ky",
            "name": "Cayman Islands",
            "mask": "999-9999",
            "phoneCode": "+1 (345) "
        },
        {
            "isoCode": "BM",
            "flagName": "bm",
            "name": "Bermuda",
            "mask": "999-9999",
            "phoneCode": "+1 (441) "
        },
        {
            "isoCode": "GD",
            "flagName": "gd",
            "name": "Grenada",
            "mask": "999-9999",
            "phoneCode": "+1 (473) "
        },
        {
            "isoCode": "TC",
            "flagName": "tc",
            "name": "Turks and Caicos Islands",
            "mask": "999-9999",
            "phoneCode": "+1 (649) "
        },
        {
            "isoCode": "MS",
            "flagName": "ms",
            "name": "Montserrat",
            "mask": "999-9999",
            "phoneCode": "+1 (664) "
        },
        {
            "isoCode": "MP",
            "flagName": "mp",
            "name": "Northern Mariana Islands",
            "mask": "999-9999",
            "phoneCode": "+1 (670) "
        },
        {
            "isoCode": "GU",
            "flagName": "gu",
            "name": "Guam",
            "mask": "999-9999",
            "phoneCode": "+1 (671) "
        },
        {
            "isoCode": "AS",
            "flagName": "as",
            "name": "American Samoa",
            "mask": "999-9999",
            "phoneCode": "+1 (684) "
        },
        {
            "isoCode": "SX",
            "flagName": "sx",
            "name": "Sint Maarten",
            "mask": "999-9999",
            "phoneCode": "+1 (721) "
        },
        {
            "isoCode": "LC",
            "flagName": "lc",
            "name": "Saint Lucia",
            "mask": "999-9999",
            "phoneCode": "+1 (758) "
        },
        {
            "isoCode": "DM",
            "flagName": "dm",
            "name": "Dominica",
            "mask": "999-9999",
            "phoneCode": "+1 (767) "
        },
        {
            "isoCode": "VC",
            "flagName": "vc",
            "name": "Saint Vincent and the Grenadines",
            "mask": "999-9999",
            "phoneCode": "+1 (784) "
        },
        {
            "isoCode": "PR",
            "flagName": "pr",
            "name": "Puerto Rico",
            "mask": "999-9999",
            "phoneCode": "+1 (787) "
        },
        {
            "isoCode": "DO",
            "flagName": "do",
            "name": "Dominican Republic",
            "mask": "999-9999",
            "phoneCode": "+1 (809) "
        },
        {
            "isoCode": "TT",
            "flagName": "tt",
            "name": "Trinidad and Tobago",
            "mask": "999-9999",
            "phoneCode": "+1 (868) "
        },
        {
            "isoCode": "KN",
            "flagName": "kn",
            "name": "Saint Kitts and Nevis",
            "mask": "999-9999",
            "phoneCode": "+1 (869) "
        },
        {
            "isoCode": "JM",
            "flagName": "jm",
            "name": "Jamaica",
            "mask": "999-9999",
            "phoneCode": "+1 (876) "
        },
        {
            "isoCode": "EG",
            "flagName": "eg",
            "name": "Egypt",
            "mask": "9999 999 9999",
            "phoneCode": "+20 "
        },
        {
            "isoCode": "SS",
            "flagName": "ss",
            "name": "South Sudan",
            "mask": "9999 999 999",
            "phoneCode": "+211 "
        },
        {
            "isoCode": "EH",
            "flagName": "eh",
            "name": "Western Sahara",
            "mask": "9999-999999",
            "phoneCode": "+212 "
        },
        {
            "isoCode": "MA",
            "flagName": "ma",
            "name": "Morocco",
            "mask": "9999-999999",
            "phoneCode": "+212 "
        },
        {
            "isoCode": "DZ",
            "flagName": "dz",
            "name": "Algeria",
            "mask": "9999 99 99 99",
            "phoneCode": "+213 "
        },
        {
            "isoCode": "TN",
            "flagName": "tn",
            "name": "Tunisia",
            "mask": "99 999 999",
            "phoneCode": "+216 "
        },
        {
            "isoCode": "LY",
            "flagName": "ly",
            "name": "Libya",
            "mask": "999-9999999",
            "phoneCode": "+218 "
        },
        {
            "isoCode": "GM",
            "flagName": "gm",
            "name": "Gambia",
            "mask": "999 9999",
            "phoneCode": "+220 "
        },
        {
            "isoCode": "SN",
            "flagName": "sn",
            "name": "Senegal",
            "mask": "99 999 99 99",
            "phoneCode": "+221 "
        },
        {
            "isoCode": "MR",
            "flagName": "mr",
            "name": "Mauritania",
            "mask": "99 99 99 99",
            "phoneCode": "+222 "
        },
        {
            "isoCode": "ML",
            "flagName": "ml",
            "name": "Mali",
            "mask": "99 99 99 99",
            "phoneCode": "+223 "
        },
        {
            "isoCode": "GN",
            "flagName": "gn",
            "name": "Guinea",
            "mask": "999 99 99 99",
            "phoneCode": "+224 "
        },
        {
            "isoCode": "CI",
            "flagName": "ci",
            "name": "Ivory Coast",
            "mask": "99 99 99 99",
            "phoneCode": "+225 "
        },
        {
            "isoCode": "BF",
            "flagName": "bf",
            "name": "Burkina Faso",
            "mask": "99 99 99 99",
            "phoneCode": "+226 "
        },
        {
            "isoCode": "NE",
            "flagName": "ne",
            "name": "Niger",
            "mask": "99 99 99 99",
            "phoneCode": "+227 "
        },
        {
            "isoCode": "TG",
            "flagName": "tg",
            "name": "Togo",
            "mask": "99 99 99 99",
            "phoneCode": "+228 "
        },
        {
            "isoCode": "BJ",
            "flagName": "bj",
            "name": "Benin",
            "mask": "99 99 99 99",
            "phoneCode": "+229 "
        },
        {
            "isoCode": "MU",
            "flagName": "mu",
            "name": "Mauritius",
            "mask": "9999 9999",
            "phoneCode": "+230 "
        },
        {
            "isoCode": "LR",
            "flagName": "lr",
            "name": "Liberia",
            "mask": "999 999 9999",
            "phoneCode": "+231 "
        },
        {
            "isoCode": "SL",
            "flagName": "sl",
            "name": "Sierra Leone",
            "mask": "(999) 999999",
            "phoneCode": "+232 "
        },
        {
            "isoCode": "GH",
            "flagName": "gh",
            "name": "Ghana",
            "mask": "999 999 9999",
            "phoneCode": "+233 "
        },
        {
            "isoCode": "NG",
            "flagName": "ng",
            "name": "Nigeria",
            "mask": "9999 999 9999",
            "phoneCode": "+234 "
        },
        {
            "isoCode": "TD",
            "flagName": "td",
            "name": "Chad",
            "mask": "99 99 99 99",
            "phoneCode": "+235 "
        },
        {
            "isoCode": "CF",
            "flagName": "cf",
            "name": "Central African Republic",
            "mask": "99 99 99 99",
            "phoneCode": "+236 "
        },
        {
            "isoCode": "CM",
            "flagName": "cm",
            "name": "Cameroon",
            "mask": "9 99 99 99 99",
            "phoneCode": "+237 "
        },
        {
            "isoCode": "CV",
            "flagName": "cv",
            "name": "Cape Verde",
            "mask": "999 99 99",
            "phoneCode": "+238 "
        },
        {
            "isoCode": "ST",
            "flagName": "st",
            "name": "Sao Tome and Principe",
            "mask": "999 9999",
            "phoneCode": "+239 "
        },
        {
            "isoCode": "GQ",
            "flagName": "gq",
            "name": "Equatorial Guinea",
            "mask": "999 999 999",
            "phoneCode": "+240 "
        },
        {
            "isoCode": "GA",
            "flagName": "ga",
            "name": "Gabon",
            "mask": "99 99 99 99",
            "phoneCode": "+241 "
        },
        {
            "isoCode": "CG",
            "flagName": "cg",
            "name": "Republic of the Congo",
            "mask": " 99 999 9999",
            "phoneCode": "+242 "
        },
        {
            "isoCode": "CD",
            "flagName": "cd",
            "name": "Democratic Republic of the Congo",
            "mask": "9999 999 999",
            "phoneCode": "+243 "
        },
        {
            "isoCode": "AO",
            "flagName": "ao",
            "name": "Angola",
            "mask": "999 999 999",
            "phoneCode": "+244 "
        },
        {
            "isoCode": "GW",
            "flagName": "gw",
            "name": "Guinea-Bissau",
            "mask": "999 999 999",
            "phoneCode": "+245 "
        },
        {
            "isoCode": "IO",
            "flagName": "io",
            "name": "British Indian Ocean Territory",
            "mask": "999 9999",
            "phoneCode": "+246 "
        },
        {
            "isoCode": "SC",
            "flagName": "sc",
            "name": "Seychelles",
            "mask": "9 999 999",
            "phoneCode": "+248 "
        },
        {
            "isoCode": "SD",
            "flagName": "sd",
            "name": "Sudan",
            "mask": "999 999 9999",
            "phoneCode": "+249 "
        },
        {
            "isoCode": "RW",
            "flagName": "rw",
            "name": "Rwanda",
            "mask": "9999 999 999",
            "phoneCode": "+250 "
        },
        {
            "isoCode": "ET",
            "flagName": "et",
            "name": "Ethiopia",
            "mask": "999 999 9999",
            "phoneCode": "+251 "
        },
        {
            "isoCode": "SO",
            "flagName": "so",
            "name": "Somalia",
            "mask": "9 9999999",
            "phoneCode": "+252 "
        },
        {
            "isoCode": "DJ",
            "flagName": "dj",
            "name": "Djibouti",
            "mask": "99 99 99 99",
            "phoneCode": "+253 "
        },
        {
            "isoCode": "KE",
            "flagName": "ke",
            "name": "Kenya",
            "mask": "9999 999999",
            "phoneCode": "+254 "
        },
        {
            "isoCode": "TZ",
            "flagName": "tz",
            "name": "Tanzania",
            "mask": "9999 999 999",
            "phoneCode": "+255 "
        },
        {
            "isoCode": "UG",
            "flagName": "ug",
            "name": "Uganda",
            "mask": "9999 999999",
            "phoneCode": "+256 "
        },
        {
            "isoCode": "BI",
            "flagName": "bi",
            "name": "Burundi",
            "mask": "99 99 99 99",
            "phoneCode": "+257 "
        },
        {
            "isoCode": "MZ",
            "flagName": "mz",
            "name": "Mozambique",
            "mask": "99 999 9999",
            "phoneCode": "+258 "
        },
        {
            "isoCode": "ZM",
            "flagName": "zm",
            "name": "Zambia",
            "mask": "999 9999999",
            "phoneCode": "+260 "
        },
        {
            "isoCode": "MG",
            "flagName": "mg",
            "name": "Madagascar",
            "mask": "999 99 999 99",
            "phoneCode": "+261 "
        },
        {
            "isoCode": "RE",
            "flagName": "re",
            "name": "Reunion",
            "mask": "9999 99 99 99",
            "phoneCode": "+262 "
        },
        {
            "isoCode": "YT",
            "flagName": "yt",
            "name": "Mayotte",
            "mask": "9999 99 99 99",
            "phoneCode": "+262 "
        },
        {
            "isoCode": "ZW",
            "flagName": "zw",
            "name": "Zimbabwe",
            "mask": "999 999 9999",
            "phoneCode": "+263 "
        },
        {
            "isoCode": "NA",
            "flagName": "na",
            "name": "Namibia",
            "mask": "999 999 9999",
            "phoneCode": "+264 "
        },
        {
            "isoCode": "MW",
            "flagName": "mw",
            "name": "Malawi",
            "mask": "9999 99 99 99",
            "phoneCode": "+265 "
        },
        {
            "isoCode": "LS",
            "flagName": "ls",
            "name": "Lesotho",
            "mask": "9999 9999",
            "phoneCode": "+266 "
        },
        {
            "isoCode": "BW",
            "flagName": "bw",
            "name": "Botswana",
            "mask": "99 999 999",
            "phoneCode": "+267 "
        },
        {
            "isoCode": "SZ",
            "flagName": "sz",
            "name": "Swaziland",
            "mask": "9999 9999",
            "phoneCode": "+268 "
        },
        {
            "isoCode": "KM",
            "flagName": "km",
            "name": "Comoros",
            "mask": "999 99 99",
            "phoneCode": "+269 "
        },
        {
            "isoCode": "ZA",
            "flagName": "za",
            "name": "South Africa",
            "mask": "999 999 9999",
            "phoneCode": "+27 "
        },
        {
            "isoCode": "SH",
            "flagName": "sh",
            "name": "Saint Helena",
            "mask": "99999",
            "phoneCode": "+290 "
        },
        {
            "isoCode": "ER",
            "flagName": "er",
            "name": "Eritrea",
            "mask": "99 999 999",
            "phoneCode": "+291 "
        },
        {
            "isoCode": "AW",
            "flagName": "aw",
            "name": "Aruba",
            "mask": "999 9999",
            "phoneCode": "+297 "
        },
        {
            "isoCode": "FO",
            "flagName": "fo",
            "name": "Faroe Islands",
            "mask": "999999",
            "phoneCode": "+298 "
        },
        {
            "isoCode": "GL",
            "flagName": "gl",
            "name": "Greenland",
            "mask": "99 99 99",
            "phoneCode": "+299 "
        },
        {
            "isoCode": "GR",
            "flagName": "gr",
            "name": "Greece",
            "mask": "999 999 9999",
            "phoneCode": "+30 "
        },
        {
            "isoCode": "NL",
            "flagName": "nl",
            "name": "Netherlands",
            "mask": "99 99999999",
            "phoneCode": "+31 "
        },
        {
            "isoCode": "BE",
            "flagName": "be",
            "name": "Belgium",
            "mask": "9999 99 99 99",
            "phoneCode": "+32 "
        },
        {
            "isoCode": "FR",
            "flagName": "fr",
            "name": "France",
            "mask": "99 99 99 99 99",
            "phoneCode": "+33 "
        },
        {
            "isoCode": "ES",
            "flagName": "es",
            "name": "Spain",
            "mask": "999 99 99 99",
            "phoneCode": "+34 "
        },
        {
            "isoCode": "GI",
            "flagName": "gi",
            "name": "Gibraltar",
            "mask": "99999999",
            "phoneCode": "+350 "
        },
        {
            "isoCode": "PT",
            "flagName": "pt",
            "name": "Portugal",
            "mask": "999 999 999",
            "phoneCode": "+351 "
        },
        {
            "isoCode": "LU",
            "flagName": "lu",
            "name": "Luxembourg",
            "mask": "999 999 999",
            "phoneCode": "+352 "
        },
        {
            "isoCode": "IE",
            "flagName": "ie",
            "name": "Ireland",
            "mask": "999 999 9999",
            "phoneCode": "+353 "
        },
        {
            "isoCode": "IS",
            "flagName": "is",
            "name": "Iceland",
            "mask": "999 9999",
            "phoneCode": "+354 "
        },
        {
            "isoCode": "AL",
            "flagName": "al",
            "name": "Albania",
            "mask": "999 999 9999",
            "phoneCode": "+355 "
        },
        {
            "isoCode": "MT",
            "flagName": "mt",
            "name": "Malta",
            "mask": "9999 9999",
            "phoneCode": "+356 "
        },
        {
            "isoCode": "CY",
            "flagName": "cy",
            "name": "Cyprus",
            "mask": "99 999999",
            "phoneCode": "+357 "
        },
        {
            "isoCode": "FI",
            "flagName": "fi",
            "name": "Finland",
            "mask": "999 9999999",
            "phoneCode": "+358 "
        },
        {
            "isoCode": "BG",
            "flagName": "bg",
            "name": "Bulgaria",
            "mask": "999 999 999",
            "phoneCode": "+359 "
        },
        {
            "isoCode": "HU",
            "flagName": "hu",
            "name": "Hungary",
            "mask": "(99) 999 9999",
            "phoneCode": "+36 "
        },
        {
            "isoCode": "LT",
            "flagName": "lt",
            "name": "Lithuania",
            "mask": "(9-999) 99999",
            "phoneCode": "+370 "
        },
        {
            "isoCode": "LV",
            "flagName": "lv",
            "name": "Latvia",
            "mask": "99 999 999",
            "phoneCode": "+371 "
        },
        {
            "isoCode": "EE",
            "flagName": "ee",
            "name": "Estonia",
            "mask": "9999 9999",
            "phoneCode": "+372 "
        },
        {
            "isoCode": "MD",
            "flagName": "md",
            "name": "Moldova",
            "mask": "9999 99 999",
            "phoneCode": "+373 "
        },
        {
            "isoCode": "AM",
            "flagName": "am",
            "name": "Armenia",
            "mask": "999 999999",
            "phoneCode": "+374 "
        },
        {
            "isoCode": "BY",
            "flagName": "by",
            "name": "Belarus",
            "mask": "9 999 999-99-99",
            "phoneCode": "+375 "
        },
        {
            "isoCode": "AD",
            "flagName": "ad",
            "name": "Andorra",
            "mask": "999 999",
            "phoneCode": "+376 "
        },
        {
            "isoCode": "MC",
            "flagName": "mc",
            "name": "Monaco",
            "mask": "99 99 99 99 99",
            "phoneCode": "+377 "
        },
        {
            "isoCode": "SM",
            "flagName": "sm",
            "name": "San Marino",
            "mask": "99 99 99 99",
            "phoneCode": "+378 "
        },
        {
            "isoCode": "UA",
            "flagName": "ua",
            "name": "Ukraine",
            "mask": "999 999 9999",
            "phoneCode": "+380 "
        },
        {
            "isoCode": "RS",
            "flagName": "rs",
            "name": "Serbia",
            "mask": "999 9999999",
            "phoneCode": "+381 "
        },
        {
            "isoCode": "ME",
            "flagName": "me",
            "name": "Montenegro",
            "mask": "999 999 999",
            "phoneCode": "+382 "
        },
        {
            "isoCode": "XK",
            "flagName": "xk",
            "name": "Kosovo",
            "mask": "999 999 999",
            "phoneCode": "+383 "
        },
        {
            "isoCode": "HR",
            "flagName": "hr",
            "name": "Croatia",
            "mask": "999 999 9999",
            "phoneCode": "+385 "
        },
        {
            "isoCode": "SI",
            "flagName": "si",
            "name": "Slovenia",
            "mask": "999 999 999",
            "phoneCode": "+386 "
        },
        {
            "isoCode": "BA",
            "flagName": "ba",
            "name": "Bosnia and Herzegovina",
            "mask": "999 999 999",
            "phoneCode": "+387 "
        },
        {
            "isoCode": "MK",
            "flagName": "mk",
            "name": "Macedonia",
            "mask": "999 999 999",
            "phoneCode": "+389 "
        },
        {
            "isoCode": "VA",
            "flagName": "va",
            "name": "Vatican",
            "mask": "999 999 9999",
            "phoneCode": "+39 "
        },
        {
            "isoCode": "IT",
            "flagName": "it",
            "name": "Italy",
            "mask": "999 999 9999",
            "phoneCode": "+39 "
        },
        {
            "isoCode": "RO",
            "flagName": "ro",
            "name": "Romania",
            "mask": "9999 999 999",
            "phoneCode": "+40 "
        },
        {
            "isoCode": "CH",
            "flagName": "ch",
            "name": "Switzerland",
            "mask": "999 999 99 99",
            "phoneCode": "+41 "
        },
        {
            "isoCode": "CZ",
            "flagName": "cz",
            "name": "Czech Republic",
            "mask": "999 999 999",
            "phoneCode": "+420 "
        },
        {
            "isoCode": "SK",
            "flagName": "sk",
            "name": "Slovakia",
            "mask": "9999 999 999",
            "phoneCode": "+421 "
        },
        {
            "isoCode": "LI",
            "flagName": "li",
            "name": "Liechtenstein",
            "mask": "999 999 999",
            "phoneCode": "+423 "
        },
        {
            "isoCode": "AT",
            "flagName": "at",
            "name": "Austria",
            "mask": "9999 999999",
            "phoneCode": "+43 "
        },
        {
            "isoCode": "GB",
            "flagName": "gb",
            "name": "United Kingdom",
            "mask": "99999 99999",
            "phoneCode": "+44 "
        },
        {
            "isoCode": "JE",
            "flagName": "je",
            "name": "Jersey",
            "mask": "99999 999999",
            "phoneCode": "+44 "
        },
        {
            "isoCode": "IM",
            "flagName": "im",
            "name": "Isle of Man",
            "mask": "99999 999999",
            "phoneCode": "+44 "
        },
        {
            "isoCode": "GG",
            "flagName": "gg",
            "name": "Guernsey",
            "mask": "99999 999999",
            "phoneCode": "+44 "
        },
        {
            "isoCode": "DK",
            "flagName": "dk",
            "name": "Denmark",
            "mask": "99 99 99 99",
            "phoneCode": "+45 "
        },
        {
            "isoCode": "SE",
            "flagName": "se",
            "name": "Sweden",
            "mask": "999-999 99 99",
            "phoneCode": "+46 "
        },
        {
            "isoCode": "SJ",
            "flagName": "sj",
            "name": "Svalbard and Jan Mayen",
            "mask": "999 99 999",
            "phoneCode": "+47 "
        },
        {
            "isoCode": "NO",
            "flagName": "no",
            "name": "Norway",
            "mask": "999 99 999",
            "phoneCode": "+47 "
        },
        {
            "isoCode": "PL",
            "flagName": "pl",
            "name": "Poland",
            "mask": "999 999 999",
            "phoneCode": "+48 "
        },
        {
            "isoCode": "DE",
            "flagName": "de",
            "name": "Germany",
            "mask": "99999 9999999",
            "phoneCode": "+49 "
        },
        {
            "isoCode": "FK",
            "flagName": "fk",
            "name": "Falkland Islands",
            "mask": "99999",
            "phoneCode": "+500 "
        },
        {
            "isoCode": "BZ",
            "flagName": "bz",
            "name": "Belize",
            "mask": "999-9999",
            "phoneCode": "+501 "
        },
        {
            "isoCode": "GT",
            "flagName": "gt",
            "name": "Guatemala",
            "mask": "9999 9999",
            "phoneCode": "+502 "
        },
        {
            "isoCode": "SV",
            "flagName": "sv",
            "name": "El Salvador",
            "mask": "9999 9999",
            "phoneCode": "+503 "
        },
        {
            "isoCode": "HN",
            "flagName": "hn",
            "name": "Honduras",
            "mask": "9999-9999",
            "phoneCode": "+504 "
        },
        {
            "isoCode": "NI",
            "flagName": "ni",
            "name": "Nicaragua",
            "mask": "9999 9999",
            "phoneCode": "+505 "
        },
        {
            "isoCode": "CR",
            "flagName": "cr",
            "name": "Costa Rica",
            "mask": "9999 9999",
            "phoneCode": "+506 "
        },
        {
            "isoCode": "PA",
            "flagName": "pa",
            "name": "Panama",
            "mask": "9999-9999",
            "phoneCode": "+507 "
        },
        {
            "isoCode": "PM",
            "flagName": "pm",
            "name": "Saint Pierre and Miquelon",
            "mask": "999 99 99",
            "phoneCode": "+508 "
        },
        {
            "isoCode": "HT",
            "flagName": "ht",
            "name": "Haiti",
            "mask": "99 99 9999",
            "phoneCode": "+509 "
        },
        {
            "isoCode": "PE",
            "flagName": "pe",
            "name": "Peru",
            "mask": "999 999 999",
            "phoneCode": "+51 "
        },
        {
            "isoCode": "MX",
            "flagName": "mx",
            "name": "Mexico",
            "mask": "999 999 999 9999",
            "phoneCode": "+52 "
        },
        {
            "isoCode": "CU",
            "flagName": "cu",
            "name": "Cuba",
            "mask": "99 9999999",
            "phoneCode": "+53 "
        },
        {
            "isoCode": "AR",
            "flagName": "ar",
            "name": "Argentina",
            "mask": "999 99-9999-9999",
            "phoneCode": "+54 "
        },
        {
            "isoCode": "BR",
            "flagName": "br",
            "name": "Brazil",
            "mask": "(99) 99999-9999",
            "phoneCode": "+55 "
        },
        {
            "isoCode": "CL",
            "flagName": "cl",
            "name": "Chile",
            "mask": "9 9999 9999",
            "phoneCode": "+56 "
        },
        {
            "isoCode": "CO",
            "flagName": "co",
            "name": "Colombia",
            "mask": "999 9999999",
            "phoneCode": "+57 "
        },
        {
            "isoCode": "VE",
            "flagName": "ve",
            "name": "Venezuela",
            "mask": "9999-9999999",
            "phoneCode": "+58 "
        },
        {
            "isoCode": "MF",
            "flagName": "mf",
            "name": "Saint Martin",
            "mask": "9999 99 99 99",
            "phoneCode": "+590 "
        },
        {
            "isoCode": "BL",
            "flagName": "bl",
            "name": "Saint Barthelemy",
            "mask": "9999 99 99 99",
            "phoneCode": "+590 "
        },
        {
            "isoCode": "BO",
            "flagName": "bo",
            "name": "Bolivia",
            "mask": "99999999",
            "phoneCode": "+591 "
        },
        {
            "isoCode": "GY",
            "flagName": "gy",
            "name": "Guyana",
            "mask": "999 9999",
            "phoneCode": "+592 "
        },
        {
            "isoCode": "EC",
            "flagName": "ec",
            "name": "Ecuador",
            "mask": "999 999 9999",
            "phoneCode": "+593 "
        },
        {
            "isoCode": "PY",
            "flagName": "py",
            "name": "Paraguay",
            "mask": "9999 999999",
            "phoneCode": "+595 "
        },
        {
            "isoCode": "SR",
            "flagName": "sr",
            "name": "Suriname",
            "mask": "999-9999",
            "phoneCode": "+597 "
        },
        {
            "isoCode": "UY",
            "flagName": "uy",
            "name": "Uruguay",
            "mask": "999 999 999",
            "phoneCode": "+598 "
        },
        {
            "isoCode": "AN",
            "flagName": "an",
            "name": "Netherlands Antilles",
            "mask": "999 9999",
            "phoneCode": "+599 "
        },
        {
            "isoCode": "CW",
            "flagName": "cw",
            "name": "Curacao",
            "mask": "999 9999",
            "phoneCode": "+599 "
        },
        {
            "isoCode": "MY",
            "flagName": "my",
            "name": "Malaysia",
            "mask": "999-999 9999",
            "phoneCode": "+60 "
        },
        {
            "isoCode": "CC",
            "flagName": "cc",
            "name": "Cocos Islands",
            "mask": "9999 999 999",
            "phoneCode": "+61 "
        },
        {
            "isoCode": "CX",
            "flagName": "cx",
            "name": "Christmas Island",
            "mask": "9999 999 999",
            "phoneCode": "+61 "
        },
        {
            "isoCode": "AU",
            "flagName": "au",
            "name": "Australia",
            "mask": "9999 999 999",
            "phoneCode": "+61 "
        },
        {
            "isoCode": "ID",
            "flagName": "id",
            "name": "Indonesia",
            "mask": "9999-999-999",
            "phoneCode": "+62 "
        },
        {
            "isoCode": "PH",
            "flagName": "ph",
            "name": "Philippines",
            "mask": "9999 999 9999",
            "phoneCode": "+63 "
        },
        {
            "isoCode": "PN",
            "flagName": "pn",
            "name": "Pitcairn",
            "mask": "99 999999",
            "phoneCode": "+64 "
        },
        {
            "isoCode": "NZ",
            "flagName": "nz",
            "name": "New Zealand",
            "mask": "999 999 9999",
            "phoneCode": "+64 "
        },
        {
            "isoCode": "SG",
            "flagName": "sg",
            "name": "Singapore",
            "mask": "9999 9999",
            "phoneCode": "+65 "
        },
        {
            "isoCode": "TH",
            "flagName": "th",
            "name": "Thailand",
            "mask": "999 999 9999",
            "phoneCode": "+66 "
        },
        {
            "isoCode": "TL",
            "flagName": "tl",
            "name": "East Timor",
            "mask": "9999 9999",
            "phoneCode": "+670 "
        },
        {
            "isoCode": "AQ",
            "flagName": "aq",
            "name": "Antarctica",
            "mask": "9 99999",
            "phoneCode": "+672 "
        },
        {
            "isoCode": "BN",
            "flagName": "bn",
            "name": "Brunei",
            "mask": "999 9999",
            "phoneCode": "+673 "
        },
        {
            "isoCode": "NR",
            "flagName": "nr",
            "name": "Nauru",
            "mask": "999 9999",
            "phoneCode": "+674 "
        },
        {
            "isoCode": "PG",
            "flagName": "pg",
            "name": "Papua New Guinea",
            "mask": "9999 9999",
            "phoneCode": "+675 "
        },
        {
            "isoCode": "TO",
            "flagName": "to",
            "name": "Tonga",
            "mask": "999 9999",
            "phoneCode": "+676 "
        },
        {
            "isoCode": "SB",
            "flagName": "sb",
            "name": "Solomon Islands",
            "mask": "99 99999",
            "phoneCode": "+677 "
        },
        {
            "isoCode": "VU",
            "flagName": "vu",
            "name": "Vanuatu",
            "mask": "999 9999",
            "phoneCode": "+678 "
        },
        {
            "isoCode": "FJ",
            "flagName": "fj",
            "name": "Fiji",
            "mask": "999 9999",
            "phoneCode": "+679 "
        },
        {
            "isoCode": "PW",
            "flagName": "pw",
            "name": "Palau",
            "mask": "999 9999",
            "phoneCode": "+680 "
        },
        {
            "isoCode": "WF",
            "flagName": "wf",
            "name": "Wallis and Futuna",
            "mask": "99 99 99",
            "phoneCode": "+681 "
        },
        {
            "isoCode": "CK",
            "flagName": "ck",
            "name": "Cook Islands",
            "mask": "99 9999",
            "phoneCode": "+682 "
        },
        {
            "isoCode": "NU",
            "flagName": "nu",
            "name": "Niue",
            "mask": "999 9999",
            "phoneCode": "+683 "
        },
        {
            "isoCode": "WS",
            "flagName": "ws",
            "name": "Samoa",
            "mask": "99 99999",
            "phoneCode": "+685 "
        },
        {
            "isoCode": "KI",
            "flagName": "ki",
            "name": "Kiribati",
            "mask": "99999999",
            "phoneCode": "+686 "
        },
        {
            "isoCode": "NC",
            "flagName": "nc",
            "name": "New Caledonia",
            "mask": "99 99 99",
            "phoneCode": "+687 "
        },
        {
            "isoCode": "TV",
            "flagName": "tv",
            "name": "Tuvalu",
            "mask": "999999",
            "phoneCode": "+688 "
        },
        {
            "isoCode": "PF",
            "flagName": "pf",
            "name": "French Polynesia",
            "mask": "99 99 99 99",
            "phoneCode": "+689 "
        },
        {
            "isoCode": "TK",
            "flagName": "tk",
            "name": "Tokelau",
            "mask": "9999",
            "phoneCode": "+690 "
        },
        {
            "isoCode": "FM",
            "flagName": "fm",
            "name": "Micronesia",
            "mask": "999 9999",
            "phoneCode": "+691 "
        },
        {
            "isoCode": "MH",
            "flagName": "mh",
            "name": "Marshall Islands",
            "mask": "999-9999",
            "phoneCode": "+692 "
        },
        {
            "isoCode": "RU",
            "flagName": "ru",
            "name": "Russia",
            "mask": "9 (999) 999-99-99",
            "phoneCode": "+7 "
        },
        {
            "isoCode": "KZ",
            "flagName": "kz",
            "name": "Kazakhstan",
            "mask": "9 (999) 999 9999",
            "phoneCode": "+7 "
        },
        {
            "isoCode": "JP",
            "flagName": "jp",
            "name": "Japan",
            "mask": "999 9999-9999",
            "phoneCode": "+81 "
        },
        {
            "isoCode": "KR",
            "flagName": "kr",
            "name": "South Korea",
            "mask": "999-9999-9999",
            "phoneCode": "+82 "
        },
        {
            "isoCode": "VN",
            "flagName": "vn",
            "name": "Vietnam",
            "mask": "999 999 99 99",
            "phoneCode": "+84 "
        },
        {
            "isoCode": "KP",
            "flagName": "kp",
            "name": "North Korea",
            "mask": "9999 999 9999",
            "phoneCode": "+850 "
        },
        {
            "isoCode": "HK",
            "flagName": "hk",
            "name": "Hong Kong",
            "mask": "9999 9999",
            "phoneCode": "+852 "
        },
        {
            "isoCode": "MO",
            "flagName": "mo",
            "name": "Macau",
            "mask": "9999 9999",
            "phoneCode": "+853 "
        },
        {
            "isoCode": "KH",
            "flagName": "kh",
            "name": "Cambodia",
            "mask": "999 999 999",
            "phoneCode": "+855 "
        },
        {
            "isoCode": "LA",
            "flagName": "la",
            "name": "Laos",
            "mask": "999 99 999 999",
            "phoneCode": "+856 "
        },
        {
            "isoCode": "CN",
            "flagName": "cn",
            "name": "China",
            "mask": "999 9999 9999",
            "phoneCode": "+86 "
        },
        {
            "isoCode": "BD",
            "flagName": "bd",
            "name": "Bangladesh",
            "mask": "99999-999999",
            "phoneCode": "+880 "
        },
        {
            "isoCode": "TW",
            "flagName": "tw",
            "name": "Taiwan",
            "mask": "9999 999 999",
            "phoneCode": "+886 "
        },
        {
            "isoCode": "TR",
            "flagName": "tr",
            "name": "Turkey",
            "mask": "9999 999 99 99",
            "phoneCode": "+90 "
        },
        {
            "isoCode": "IN",
            "flagName": "in",
            "name": "India",
            "mask": "99999 99999",
            "phoneCode": "+91 "
        },
        {
            "isoCode": "PK",
            "flagName": "pk",
            "name": "Pakistan",
            "mask": "9999 9999999",
            "phoneCode": "+92 "
        },
        {
            "isoCode": "AF",
            "flagName": "af",
            "name": "Afghanistan",
            "mask": "999 999 9999",
            "phoneCode": "+93 "
        },
        {
            "isoCode": "LK",
            "flagName": "lk",
            "name": "Sri Lanka",
            "mask": "999 999 9999",
            "phoneCode": "+94 "
        },
        {
            "isoCode": "MM",
            "flagName": "mm",
            "name": "Myanmar",
            "mask": "99 999 9999",
            "phoneCode": "+95 "
        },
        {
            "isoCode": "MV",
            "flagName": "mv",
            "name": "Maldives",
            "mask": "999-9999",
            "phoneCode": "+960 "
        },
        {
            "isoCode": "LB",
            "flagName": "lb",
            "name": "Lebanon",
            "mask": "99 999 999",
            "phoneCode": "+961 "
        },
        {
            "isoCode": "JO",
            "flagName": "jo",
            "name": "Jordan",
            "mask": "99 9999 9999",
            "phoneCode": "+962 "
        },
        {
            "isoCode": "SY",
            "flagName": "sy",
            "name": "Syria",
            "mask": "9999 999 999",
            "phoneCode": "+963 "
        },
        {
            "isoCode": "IQ",
            "flagName": "iq",
            "name": "Iraq",
            "mask": "9999 999 9999",
            "phoneCode": "+964 "
        },
        {
            "isoCode": "KW",
            "flagName": "kw",
            "name": "Kuwait",
            "mask": "999 99999",
            "phoneCode": "+965 "
        },
        {
            "isoCode": "SA",
            "flagName": "sa",
            "name": "Saudi Arabia",
            "mask": "999 999 9999",
            "phoneCode": "+966 "
        },
        {
            "isoCode": "YE",
            "flagName": "ye",
            "name": "Yemen",
            "mask": "9999 999 999",
            "phoneCode": "+967 "
        },
        {
            "isoCode": "OM",
            "flagName": "om",
            "name": "Oman",
            "mask": "9999 9999",
            "phoneCode": "+968 "
        },
        {
            "isoCode": "PS",
            "flagName": "ps",
            "name": "Palestine",
            "mask": "9999 999 999",
            "phoneCode": "+970 "
        },
        {
            "isoCode": "AE",
            "flagName": "ae",
            "name": "United Arab Emirates",
            "mask": "999 999 9999",
            "phoneCode": "+971 "
        },
        {
            "isoCode": "IL",
            "flagName": "il",
            "name": "Israel",
            "mask": "999-999-9999",
            "phoneCode": "+972 "
        },
        {
            "isoCode": "BH",
            "flagName": "bh",
            "name": "Bahrain",
            "mask": "9999 9999",
            "phoneCode": "+973 "
        },
        {
            "isoCode": "QA",
            "flagName": "qa",
            "name": "Qatar",
            "mask": "9999 9999",
            "phoneCode": "+974 "
        },
        {
            "isoCode": "BT",
            "flagName": "bt",
            "name": "Bhutan",
            "mask": "99 99 99 99",
            "phoneCode": "+975 "
        },
        {
            "isoCode": "MN",
            "flagName": "mn",
            "name": "Mongolia",
            "mask": "9999 9999",
            "phoneCode": "+976 "
        },
        {
            "isoCode": "NP",
            "flagName": "np",
            "name": "Nepal",
            "mask": "999-9999999",
            "phoneCode": "+977 "
        },
        {
            "isoCode": "IR",
            "flagName": "ir",
            "name": "Iran",
            "mask": "9999 999 9999",
            "phoneCode": "+98 "
        },
        {
            "isoCode": "TJ",
            "flagName": "tj",
            "name": "Tajikistan",
            "mask": "999 99 9999",
            "phoneCode": "+992 "
        },
        {
            "isoCode": "TM",
            "flagName": "tm",
            "name": "Turkmenistan",
            "mask": "9 99 999999",
            "phoneCode": "+993 "
        },
        {
            "isoCode": "AZ",
            "flagName": "az",
            "name": "Azerbaijan",
            "mask": "999 999 99 99",
            "phoneCode": "+994 "
        },
        {
            "isoCode": "GE",
            "flagName": "ge",
            "name": "Georgia",
            "mask": "999 99 99 99",
            "phoneCode": "+995 "
        },
        {
            "isoCode": "KG",
            "flagName": "kg",
            "name": "Kyrgyzstan",
            "mask": "9999 999 999",
            "phoneCode": "+996 "
        },
        {
            "isoCode": "UZ",
            "flagName": "uz",
            "name": "Uzbekistan",
            "mask": "9 99 999 99 99",
            "phoneCode": "+998 "
        }
    ]
    return CountryList
}