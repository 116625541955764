import { Component, Input, OnInit } from '@angular/core';
import { ChargeType } from '@models/charge-type.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HelperService } from '@app/shared/services/helper.service';
import { ChargeTypeService } from '@app/shared/services/charge-type.service';
import { object, string } from 'yup';

@Component({
    standalone: false,
    selector: 'app-charge-type-details',
    templateUrl: './charge-type-details.component.html',
    styleUrls: ['./charge-type-details.component.scss'],
})
export class ChargeTypeDetailsComponent implements OnInit {

    loading = true;
    saveLoading = false;
    @Input() item: ChargeType;
    @Input() title: string = "Charge Type";
    @Input() isApplicableToStop: boolean = false;

    validationSchema = object().shape({
        name: string().nullable().required(),
    });

    constructor(
        private ngbActiveModal: NgbActiveModal,
        private chargeTypeService: ChargeTypeService,
        private helperService: HelperService
    ) {
    }

    ngOnInit() {
        if (this.item?.chargeTypeId) {
            this.loadEntity();
        } else {
            this.item = { ...this.item };
            this.loading = false;
        }
    }

    loadEntity() {
        this.chargeTypeService.get(this.item?.chargeTypeId).then((chargeType) => {
            this.item = chargeType;
            this.loading = false;
        }).catch(error => {
            this.helperService.errorMessage(error);
        });
    }

    async save(f) {
        if (f.isValid) {
            this.saveLoading = true;
            try {
                let resp: any;
                if (this.item?.chargeTypeId) {
                    resp = await this.chargeTypeService.update(this.item.chargeTypeId, this.item)
                } else {
                    resp = await this.chargeTypeService.create(this.item)
                }
                
                this.helperService.successMessage(`${this.title} successfully ${this.item?.chargeTypeId ? 'updated' : 'created'}`);
                this.ngbActiveModal.close(resp)
                this.saveLoading = false;

            } catch (error) {
                this.helperService.errorMessage(error);
                this.saveLoading = false;
            }
        }
    }

    close(isReload?: boolean) {
        this.ngbActiveModal.close(isReload);
    }
}
