import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { ViewedEntityTypeEnum, ViewEntityStatisticData, ViewEntityStatistic, CustomerMessageStatistic, ViewedEntityTypeValueEnum } from '@app/models/view-entity.modal';
import { ApiResponse } from '@app/models/core/base';
import { isArray, keyBy } from 'lodash';
interface CustomerStaticsStore {
  [x: number]: CustomerMessageStatistic
}

const defaultStatistics = {
  [ViewedEntityTypeEnum.ORDER]: { count: 0, type: ViewedEntityTypeEnum.ORDER, ids: [] },
  [ViewedEntityTypeEnum.QUOTE]: { count: 0, type: ViewedEntityTypeEnum.QUOTE, ids: [] },
  [ViewedEntityTypeEnum.CUSTOMER]: { count: 0, type: ViewedEntityTypeEnum.CUSTOMER, ids: [] },
  [ViewedEntityTypeEnum.CUSTOMER_MESSAGE]: { count: 0, type: ViewedEntityTypeEnum.CUSTOMER_MESSAGE, ids: [] },
}
@Injectable({
  providedIn: 'root'
})
export class ViewEntityService {
  dataStore: {
    statistics?: ViewEntityStatisticData,
    customerStatics: CustomerStaticsStore
  }


  basePath = 'ViewedEntities';
  private _statistics: BehaviorSubject<ViewEntityStatisticData>;
  private _customerStatics: BehaviorSubject<CustomerStaticsStore>;
  constructor(
    private http: HttpClient
  ) {
    this.dataStore = {
      statistics: defaultStatistics,
      customerStatics: {}
    }
    this._statistics = new BehaviorSubject(this.dataStore.statistics);
    this._customerStatics = new BehaviorSubject(this.dataStore.customerStatics);
  }

  public get $statistics(): Observable<ViewEntityStatisticData> {
    return this._statistics.asObservable();
  }

  public get statistics(): ViewEntityStatisticData {
    return this.dataStore.statistics;
  }

  public set statistics(value: ViewEntityStatistic[]) {
    const val = keyBy(value, 'type') as any
    this.dataStore.statistics = val
    this._statistics.next(val);
  }

  updateViewStatisticByType(value: ViewEntityStatistic) {
    if (!value?.ids){
      value.ids = []
    }
    this.statistics[value?.type] = value
    this.dataStore.statistics = this.statistics
    this._statistics.next(this.statistics);
  }

  get $customerStatics(): Observable<CustomerStaticsStore> {
    return this._customerStatics.asObservable();
  };
  setCustomerStatics(data: CustomerMessageStatistic | CustomerMessageStatistic[], key?: number) {
    if (key && !isArray(data)) {
      this.dataStore.customerStatics[key] = data
    } else if (isArray(data)) {
      data?.forEach((item) => {
        this.dataStore.customerStatics[item?.id] = item
      })
    }else{
      return
    }
    this._customerStatics.next(this.dataStore.customerStatics);
  }

  getStatistics() {
    return firstValueFrom(this.http.get<ApiResponse<ViewEntityStatistic[]>>(`${this.basePath}/Statistics`))
  }

  getStatisticByType(type: ViewedEntityTypeValueEnum) {
    return firstValueFrom(this.http.get<ApiResponse<ViewEntityStatistic>>(`${this.basePath}/Statistics/${type}`)).then((resp) => {
      this.updateViewStatisticByType(resp?.data)
      return resp
    })
  }

  getViewedDataByType(type: ViewedEntityTypeValueEnum, ids:number[]) {
    let params = new HttpParams();
    ids.forEach(id => {
      params = params.append('ids', id.toString());
    });
    return firstValueFrom(this.http.get(`${this.basePath}/${type}`, { params }))
  }



  getCountCustomerMessage(ids: number[]) {
    let params = new HttpParams();
    ids.forEach(id => {
      params = params.append('ids', id.toString());
    });
    return firstValueFrom(this.http.get<ApiResponse<CustomerMessageStatistic[]>>(`${this.basePath}/CustomersWithMessages`, { params })).then((res) => {
      this.setCustomerStatics(res?.data)
      return res
    })
  }

  markAsView(id: number, type: ViewedEntityTypeValueEnum) {
    return firstValueFrom(this.http.put(`${this.basePath}/${id}/${type}`, {}))
  }

  markAsViewByIds(ids: number[], type:ViewedEntityTypeValueEnum) {
    return firstValueFrom(this.http.put(`${this.basePath}/${type}`, ids))
  }

  resetState() {
    this.dataStore.customerStatics = {}
    this.dataStore.statistics = defaultStatistics
  }

  async onInit() {
    this.getStatistics().then(({ data }) => [
      this.statistics = data
    ]).catch(() => {
      // nothing
    })
  }
}
