import { Component, OnInit } from '@angular/core';
import { SettingService } from '@app/shared/services/setting.service';

@Component({
    selector: 'app-version',
    templateUrl: './version.component.html',
    styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {
    apiVersion: string = '';
    uiVersion: string = '0.1.0.0';
    loading: boolean = true;

    constructor(private settingService: SettingService) { }

    ngOnInit(): void {
        this.getApiVersion();
    }

    ngAfterViewInit(): void {
        const scrollContainer = document.getElementById('scroll-container');
        if (scrollContainer) {
            scrollContainer.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    getApiVersion(): void {
        this.settingService.getApiVersion().then(version => {
            this.apiVersion = version;
            this.loading = false;
        }).catch(error => {
            console.error('Error fetching API version:', error);
            this.apiVersion = 'Error loading version';
            this.loading = false;
        });
    }
}
