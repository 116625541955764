import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, type OnInit } from '@angular/core';
import { OrderStop, OrderStopDateTypeEnum, OrderStopTimeTypeEnum, OrderStopTypeEnum } from '@app/models/order-stop.model';
import { HelperService } from '@app/shared/services/helper.service';
import { OrderService } from '@app/shared/services/order.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { every, filter, isEmpty, some } from 'lodash';
import moment = require('moment');

@Component({
    standalone: false,
    selector: 'app-edit-order-stop-date-and-time-dialog',
    templateUrl: './edit-order-stop-date-and-time-dialog.component.html',
    styleUrl: './edit-order-stop-date-and-time-dialog.component.scss',
})
export class EditOrderStopDateAndTimeDialogComponent implements OnInit {

    @Input() orderStopId: any;
    @Input() orderId: any;
    @Input() orderStops: OrderStop[];
    @Input() date: string;
    @Input() dateType: OrderStopDateTypeEnum;
    @Input() time: string;
    @Input() timeType: OrderStopTimeTypeEnum;

    loading: boolean = false;
    isMultiple: boolean = false;
    selectionOrderStopIds = new SelectionModel<string | number>(true, []);

    constructor(
        private activeModal: NgbActiveModal,
        private orderService: OrderService,
        private helperService: HelperService,
    ) { }

    ngOnInit(): void { }

    isPickupStops(): boolean {
        const pickupStops = filter(this.orderStops, { orderStopType: OrderStopTypeEnum.PICKUP });
        return !isEmpty(pickupStops)
    }

    isDeliveryStops(): boolean {
        const deliveryStops = filter(this.orderStops, { orderStopType: OrderStopTypeEnum.DELIVERY });
        return !isEmpty(deliveryStops)
    }

    isAllChecked(): boolean {
        return !isEmpty(this.orderStops) && every(this.orderStops, (orderStop) => this.selectionOrderStopIds.isSelected(orderStop.orderStopId));
    }

    isAllPickupChecked(): boolean {
        const pickupStops = filter(this.orderStops, { orderStopType: OrderStopTypeEnum.PICKUP });
        return !isEmpty(pickupStops) && every(pickupStops, (orderStop) => this.selectionOrderStopIds.isSelected(orderStop.orderStopId));
    }

    isAllDeliveryChecked(): boolean {
        const deliveryStops = filter(this.orderStops, { orderStopType: OrderStopTypeEnum.DELIVERY });
        return !isEmpty(deliveryStops) && every(deliveryStops, (orderStop) => this.selectionOrderStopIds.isSelected(orderStop.orderStopId));
    }

    get isAllIndeterminate(): boolean {
        const selectedCount = this.selectionOrderStopIds.selected.length;
        return selectedCount > 0 && selectedCount < this.orderStops.length;
    }

    get isPickupsIndeterminate(): boolean {
        const pickupStops = filter(this.orderStops, { orderStopType: OrderStopTypeEnum.PICKUP });
        return some(pickupStops, (orderStop) => this.selectionOrderStopIds.isSelected(orderStop.orderStopId)) &&
            !this.isAllPickupChecked();
    }

    get isDeliveriesIndeterminate(): boolean {
        const deliveryStops = filter(this.orderStops, { orderStopType: OrderStopTypeEnum.DELIVERY });
        return some(deliveryStops, (orderStop) => this.selectionOrderStopIds.isSelected(orderStop.orderStopId)) &&
            !this.isAllDeliveryChecked();
    }

    checkAll(event: Event): void {
        const isChecked = (event.target as HTMLInputElement).checked;
        isChecked ? this.orderStops.forEach((orderStop) => this.selectionOrderStopIds.select(orderStop.orderStopId))
            : this.selectionOrderStopIds.clear();
    }

    checkAllPickups(event: Event): void {
        const isChecked = (event.target as HTMLInputElement).checked;
        const pickupStops = filter(this.orderStops, { orderStopType: OrderStopTypeEnum.PICKUP });
        isChecked ? pickupStops?.forEach((orderStop) => this.selectionOrderStopIds.select(orderStop?.orderStopId))
            : pickupStops?.forEach((orderStop) => this.selectionOrderStopIds.deselect(orderStop?.orderStopId));
    }

    checkAllDeliveries(event: Event): void {
        const isChecked = (event.target as HTMLInputElement).checked;
        const deliveryStops = filter(this.orderStops, { orderStopType: OrderStopTypeEnum.DELIVERY });
        isChecked ? deliveryStops.forEach((orderStop) => this.selectionOrderStopIds.select(orderStop.orderStopId))
            : deliveryStops.forEach((orderStop) => this.selectionOrderStopIds.deselect(orderStop.orderStopId));
    }

    toggleOrderStopIdSelection(orderStopId: any): void {
        this.selectionOrderStopIds.toggle(orderStopId);
    }

    close() {
        this.activeModal.close();
    }

    save() {
        this.loading = true;
        const request = {
            date: moment(this.date).startOf('day').format('YYYY-MM-DD'),
            dateType: this.dateType,
            time: this.time,
            timeType: this.timeType,
            orderStopIds: this.isMultiple ? this.selectionOrderStopIds?.selected : [this.orderStopId]
        }
        this.orderService.updateOrderStopsDateAndTime(this.orderId, request).then((res) => {
            this.helperService.successMessage(`Successfully Updated Order ${this.selectionOrderStopIds?.selected?.length > 1 ? 'Stops' : 'Stop'} Date and Time`);        
            this.activeModal.close(res);
        }).catch((error) => {
            this.helperService.errorMessage(error);
        }).finally(()=> {
            this.loading = false;
        })
    }

}
