import { Component, Input, OnInit } from '@angular/core';
import { CostLine } from '@app/models/cost-line.model';
import { Order } from '@app/models/order.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  standalone: false,
  selector: 'app-order-see-details',
  templateUrl: './order-see-details.component.html',
  styleUrls: ['./order-see-details.component.scss']
})
export class OrderSeeDetailsComponent implements OnInit {
  @Input() quote: Order;
  aggregatedLines: CostLine[] = [];


  doughnutChart = {
    data: {
      datasets: [{
        data: [3506, 6494, 4335, 8754, 7765, 5656],
        backgroundColor: [
          'rgba(29, 161, 242, 1)',
          'rgba(201, 232, 252, 1)',
          '#FF4E4E',
          "green",
          '#0178FA',
          '#00BAD7'
        ]
      }]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      cutoutPercentage: 84,
      hover: { mode: null },
      plugins: { tooltip: { enabled: false} },
      elements: {
        arc: { borderWidth: 0 },
      },
    },
  };

  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.activeModal.close();
  }
}
