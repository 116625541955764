import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilService } from '@app/services/util.service';
import { RouteItemStop, RouteItemType } from '@models/route-item-stop.model';
import { EntityType, EntityTypes } from '@models/entity-type.model';
import { RouteItem, RouteItemStatus } from '@models/route-item.model';
import * as moment from 'moment';
import { ChargeType } from '@models/charge-type.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PickupTypeEnum } from '@app/models/pickup-type';
import { ConfirmStopItemDialogComponent } from '../confirm-stop-item-dialog/confirm-stop-item-dialog.component';
import { RoutesService } from '@app/shared/services/router.service';
import { HelperService } from '@app/shared/services/helper.service';
import { chain, cloneDeep, map, maxBy, some } from 'lodash';
import { ChargeTypeDetailsComponent } from '@app/components/main/settings/charge-types/charge-type-details/charge-type-details.component';
import { Customer } from '@app/models/customer.model';
import { CustomerService } from '@app/shared/services/customer.service';
import { DialogService } from '@app/@shared/dialogs/dialog.service';
import { AttachmentService } from '@app/services/attachment.service';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { OrderService } from '@app/shared/services/order.service';


@Component({
    selector: 'app-confirm-stop-dialog',
    templateUrl: './confirm-stop-dialog.component.html',
    styleUrls: ['./confirm-stop-dialog.component.scss']
})
export class ConfirmStopDialogComponent implements OnInit {
    @Output() save: EventEmitter<RouteItemStop> = new EventEmitter<RouteItemStop>();
    EntityTypes = EntityTypes;
    @Input() routeId: number;
    @Input() completedStopsCount: number;
    @Input() totalStopsCount: number;
    @Input() confirmStopNewStatus: RouteItemStatus;
    RouteItemType = RouteItemType;
    time = { hour: 13, minute: 30 };
    additionalCharges: ChargeType[];
    addressCustomer: Customer;
    confirmDisabled: boolean;
    minArrivalDateTime = null;
    attachments: any[] = []
    defaultAddress = {
        fullAddress: null,
        name: null,
        addressLine2: null,
        zip: null
    }
    private _confirmStop: RouteItemStop = {
        address: { ...this.defaultAddress }
    };
    private _initialRouteItemsPickQuantity: any[];
    loading: boolean = false;
    isChargeTypesCollapse: boolean = true
    waitingTimePattern = /^(\d+)\.(\d+)$/
    get confirmStop(): RouteItemStop {
        return this._confirmStop;
    }
    set confirmStop(updateValues: RouteItemStop) {
        if (!updateValues?.arrivalDateTime) {
            updateValues.arrivalDateTime = moment().toISOString();
        }
        if (!updateValues?.departureDateTime) {
            updateValues.departureDateTime = moment().toDate();
        }
        this.additionalCharges = updateValues.charges
        this._confirmStop = updateValues;
        this.menageDeliveryStop()
    }

    get locationContactNames() {
        let names = [];

        if (this.addressCustomer) {
            const contactNames = map(this.addressCustomer.customerContacts, (contact) => {
                return `${contact?.firstName} ${contact?.lastName}`;
            });

            names = names.concat(contactNames)
        }

        if (this.confirmStop?.orderStop && !this.confirmStop?.transferStop) {
            var address = this.confirmStop?.address;
            if (address) {
                if (address.name)
                    names.push(address.name)
            }
        }

        return chain(names)
            .filter((name) => !!name)
            .uniq()
            .value()
    }

    get locationContactNumbers() {
        let numbers = [];

        if (this.addressCustomer) {
            const contactNumbers = this.addressCustomer.customerContacts.map(({ phone }) => phone)
            numbers = numbers.concat(contactNumbers)
        }

        if (this.confirmStop?.orderStop && !this.confirmStop?.transferStop) {
            var address = this.confirmStop?.address;
            if (address) {
                if (address.phoneNumber)
                    numbers.push(address.phoneNumber)
            }
        }

        return chain(numbers)
            .filter((phone) => !!phone)
            .uniq()
            .value()
    }

    get companyContactNames() {
        const names = [...this.locationContactNames, this.confirmStop?.customer?.customerName];
        return chain(names)
            .uniq()
            .value()
    }

    get companyContactNumbers() {
        const numbers = [...this.locationContactNumbers, this.confirmStop?.customer?.phone];
        return chain(numbers)
            .uniq()
            .value()
    }

    constructor(
        public util: UtilService,
        private helperService: HelperService,
        private routesService: RoutesService,
        public activeModal: NgbActiveModal,
        private modalService: NgbModal,
        private readonly customerService: CustomerService,
        private dialogService: DialogService,
        private attachmentService: AttachmentService,
        private orderService: OrderService,
    ) {
    }

    ngOnInit(): void {
        this._initialRouteItemsPickQuantity = this.confirmStop.routeItems?.map(x => {
            return {
                routeItemId: x.routeItemId,
                pickQuantity: x.pickQuantity
            }
        });

        if (this.confirmStop?.orderStop && !this.confirmStop?.transferStop && this.confirmStop?.address?.customerId && !this.addressCustomer) {
            this.customerService.get(this.confirmStop.address.customerId).then((data) => {
                this.addressCustomer = data
            });
        } else if (!this.confirmStop?.orderStop && this.confirmStop?.transferStop && this.confirmStop?.customer?.customerId && !this.addressCustomer) {
            this.customerService.get(this.confirmStop?.customer?.customerId).then((data) => {
                this.addressCustomer = data
            });
        }
    }

    menageDeliveryStop() {
        if (this.confirmStop?.type === RouteItemType.Delivery) {
            this.loading = true
            this.routesService.getRouteItemGroups({
                routeId: this.routeId,
                status: 1,
                OrderNumber: this.confirmStop?.orderNumber,
            }).then((resp) => {
                const pickupStopsStops: any = chain(resp.list)
                    .map('routeItemStops')
                    .flatten()
                    .filter((routeItemStop) => (routeItemStop?.type === RouteItemType.Pickup) && !!routeItemStop?.departureDateTime)
                    .value()

                if (pickupStopsStops?.length > 0) {
                    this.minArrivalDateTime = maxBy(map(pickupStopsStops, 'departureDateTime'))
                    this.confirmStop.arrivalDateTime = this.minArrivalDateTime
                    this.confirmStop.departureDateTime = this.minArrivalDateTime
                }
            }).catch((error) => {
                console.log(error)
            }).finally(() => {
                this.loading = false
            })
        }
    }

    close() {
        this.activeModal.close();
    }

    handleChangeFile(files) {
        this.attachments = files
    }

    addCharge() {

        const ref = this.modalService.open(ChargeTypeDetailsComponent, {
            size: 'md'
        })
        ref.componentInstance.isApplicableToStop = true
        ref.componentInstance.item = { isApplicableToStop: true }
        ref.componentInstance.title = "Additional Charge"
        ref.result.then(
            (result) => {
                if (result) {
                    this.additionalCharges.push(result)
                }
            },
            () => { }
        );

    }

    calculateTimeDifferenceBetweenArrivalAndDeparture(): string | null {
        const arrival = moment(this.confirmStop.arrivalDateTime);
        const departure = moment(this.confirmStop.departureDateTime);
        const diff = moment.duration(departure.diff(arrival));
        if (diff.asMilliseconds() < 0) {
            return null;
        }
        const hours: number = Math.floor(diff.asHours());
        const minutes: number = diff.minutes();
        return `${hours || 0}.${minutes || 0}`;
    }

    handleChangeDateTime($event: any): void {
        this.confirmStop.waitingTime = this.calculateTimeDifferenceBetweenArrivalAndDeparture();
    }

    waitingTimeValidationCheck(): boolean {
        const calculatedDiff = this.calculateTimeDifferenceBetweenArrivalAndDeparture();
        const userWaitingTime = this.confirmStop?.waitingTime;
        if (
            userWaitingTime &&
            calculatedDiff &&
            this.waitingTimePattern.test(userWaitingTime) &&
            this.convertToDuration(userWaitingTime).asMinutes() > this.convertToDuration(calculatedDiff).asMinutes()
        ) {
            this.helperService.errorMessage("The Waiting time cannot exceed the time between Arrival and Departure. Please enter a value less than or equal to the available time.");
            return true
        }
        return false
    }

    convertToDuration(waitingTime: string): moment.Duration {
        const match = waitingTime?.match(this.waitingTimePattern);
        if (match) {
            const hours = parseInt(match[1], 10);
            const minutes = parseInt(match[2], 10);
            return moment.duration({ hours, minutes });
        }
        return moment.duration(0);
    }

    changeQtyDown(stopItem) {
        if (stopItem.pickQuantity <= 1) {
            return
        }
        if (!stopItem.pickQuantity) {
            stopItem.pickQuantity = 0;
        } else {
            stopItem.pickQuantity = Math.max(0, stopItem.pickQuantity - 1);
        }
    }

    changeQtyUp(stopItem) {
        if (!stopItem.pickQuantity) {
            stopItem.pickQuantity = 1;
        } else {
            stopItem.pickQuantity++;
        }
    }

    editConfirmStopItem(stopItem: RouteItem, si) {
        const activeModal = this.modalService.open(ConfirmStopItemDialogComponent, {
            scrollable: true,
            size: "lg",
        });
        activeModal.componentInstance.confirmSelectedStopItem = cloneDeep(stopItem);
        activeModal.result.then(
            (result) => {
                if (result) {
                    this.confirmStop.routeItems[si] = result;
                }
            },
            () => { }
        );
    }

    newConfirmStopItem(confirmStop: RouteItemStop) {
        const confirmSelectedStopItem: RouteItem = {
            isNewItem: true,
            isActive: false,
            pickQuantity: 1,
            type: confirmStop.type,
            date: confirmStop.date,
            time: confirmStop.time,
            orderItem: {
                itemName: 'Freight',
                orderItemType: PickupTypeEnum.BOXES_CRATES
            }
        };
        const activeModal = this.modalService.open(ConfirmStopItemDialogComponent, {
            scrollable: true,
            size: "lg",
        });
        activeModal.componentInstance.confirmSelectedStopItem = cloneDeep(confirmSelectedStopItem);
        activeModal.result.then(
            (result) => {
                if (result) {
                    this.confirmStop.routeItems.push(result);
                }
            },
            () => { }
        );

    }

    async saveConfirmStop() {

        const isInValidWaitingTime = await this.waitingTimeValidationCheck();
        if (isInValidWaitingTime) return

        let isZeroQuantity = false

        isZeroQuantity = some(this.confirmStop.routeItems, (stopItem) => {
            const qty = Number(stopItem.pickQuantity) || 0
            return (stopItem.orderItem.orderItemType !== 'Full Load' && qty === 0)
        })
        if (isZeroQuantity) {
            this.helperService.errorMessage('Please set valid quantity')
            return
        }

        this.confirmDisabled = true;
        if (this.confirmStop.arrivalDateTime) {
            this.confirmStop.date = this.confirmStop.arrivalDateTime as string;
        }
        const diff = moment(this.confirmStop.departureDateTime).diff(this.confirmStop.arrivalDateTime, 'milliseconds')
        if (diff < 0 || !this.confirmStop.departureDateTime) {
            this.helperService.errorMessage(`Departure cannot be scheduled before arrival. Please select a later departure time.`)
            this.confirmDisabled = false;
            return
        }
        this.confirmStop.routeItems.forEach(ri => {
            ri.quantity = ri.pickQuantity;
        });

        let files = []

        if (this.confirmStop?.orderStop) {
            for (let index = 0; index < this.attachments.length; index++) {
                const attachment = this.attachments[index];
                if (attachment instanceof File) {
                    const id = this.confirmStop.orderStop?.orderStopId;
                    try {
                        const res = await firstValueFrom(
                            this.attachmentService.uploadAttachment(EntityTypes?.OrderStop, id, attachment)
                        );
                        files.push(res);
                    } catch (err) {
                        this.helperService.errorMessage(err);
                        this.helperService.isLoading = false;
                    }
                }
                else {
                    files.push(attachment);
                }
            }
            this.confirmStop.orderStop.attachments = files || [];
        }

        this.confirmStop.charges = this.confirmStop.charges.filter(c => c.selected);

        this.routesService.updateRouteStopStatus(this.routeId, this.confirmStop, this.confirmStopNewStatus, true).then(async (res) => {            
            var currentRouteItems = this.confirmStop.routeItems;

            if(res?.data === true){
                try {
                    await this.dialogService.confirm({
                        message: 'Would you like to update the invoice with the latest data?',
                        yesText: 'Update',
                        noText: 'Keep Current'
                    })

                    await this.orderService.createInvoice(this.confirmStop.orderStop.orderId)
                } catch (error) {
                }
            }

            var differentRouteItem = currentRouteItems.find(x => x.type == RouteItemType.Delivery &&
                this._initialRouteItemsPickQuantity?.find(cri => cri.routeItemId == x.routeItemId)?.pickQuantity != x.pickQuantity);

            this.activeModal.close({ route: res, attachments: files, differentRouteItem });

            this.helperService.successMessage(`Route Stop is ${this.confirmStopNewStatus}`);

            this.routesService.reloadRoute = { routeId: this.routeId }

            if (this.totalStopsCount != null && this.completedStopsCount != null && (this.completedStopsCount + 1) == this.totalStopsCount) {
                this.dialogService.confirm({
                    title: 'Complete Route Confirmation',
                    message: `Confirm that this is the end of the route and it can be archived.`,
                    yesText: 'Archive',
                    noText: 'Continue the route'
                }).then(() => {
                    this.routesService.updateRouteStatus(this.routeId, "Completed").then(() => {
                        this.routesService.reloadRoutes = true;
                        this.helperService.successMessage("Routes status successfully updated.")
                    }).catch((error) => {
                        this.helperService.errorMessage(error)
                        this.routesService.reloadRoutes = true;
                    }).finally(() => {
                        this.helperService.isLoading = false;
                    })
                }).catch(() => {
                    // Nothing
                })
            }

        }).catch((error) => {
            this.helperService.errorMessage(error);
        })
            .finally(() => {
                this.confirmDisabled = false;
            });
    }


    onChargeTypeSelected(chargeType: ChargeType) {
        if (chargeType.selected && chargeType.qty === 0) {
            if (!chargeType.isMoneyAmount) {
                chargeType.qty = 1;
            } else {
                chargeType.qty = 0;
            }
        }
    }

    setTime(minutes: number, hours = 0) {
        let departureDateTime = null
        if (this.confirmStop.departureDateTime) {
            this.confirmStop.departureDateTime = moment(this.confirmStop.departureDateTime).add(minutes, 'minutes').add(hours, 'hours').toISOString()
        } else if (this.confirmStop.arrivalDateTime) {
            this.confirmStop.departureDateTime = moment(this.confirmStop.arrivalDateTime).add(minutes, 'minutes').add(hours, 'hours').toISOString()
        }
        this.handleChangeDateTime(null);
    }
}
