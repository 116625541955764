import { Injectable } from '@angular/core';
import swal, { SweetAlertResult } from 'sweetalert2';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { formatDate, formatDateTime } from '@app/helpers/util';
import { DocumentService, DocumentViewMode } from '@app/services/document.service';
import { map } from 'rxjs/operators';
import moment from "moment"
import { CustomerEmailType } from '@models/customer-email.model';
import { Order } from '@models/order.model';
import { saveAs } from 'file-saver';
import { Invoice } from '@models/invoice.model';
import { OrderTrip } from '@models/order-trip.model';
import { RouteItem } from '@models/route-item.model';
import { getRouteItemWeight, round } from '@app/helpers/util';
import { LoadType } from '@models/shipment-type.model';
import { OrderStop } from '@models/order-stop.model';
import { PaymentTypeEnum } from '@models/payment-type.model';
import { LoaderService } from '@app/components/global/spinner/services/loader.service';
import { PickupType } from '../models/pickup-type';
import { OrderItem } from '@app/models/order-item.model';

export interface SizeType {
    text: string;
    img: string;
}

export interface PaymentTypeDescription {
    name: string;
    children: Record<number, { name: string; logo: string }>;
}

@Injectable({
    providedIn: 'root',
})
export class UtilService {

    public readonly customerEmailTypes: Readonly<CustomerEmailType[]> = Object.freeze([
        'Accounting',
        'Billing',
    ]);

    public readonly pickupTypes: Readonly<PickupType[]> = Object.freeze([
        { class: 'bnc', label: 'Boxes & Crates' },
        { class: 'pallet', label: 'Pallets' },
        { class: 'fl', label: 'Full Load' },
        { class: 'cfs', label: 'CFS' }
    ]);

    public readonly sizeTypes: Readonly<Map<LoadType, SizeType>> = Object.freeze(new Map<LoadType, SizeType>([
        ['PalletStandard', { text: 'Standard - 40”L 48”W 75”H', img: null }],
        ['PalletSmall', { text: 'Small - 42”L 42”W 75”H', img: null }],
        ['PalletLarge', { text: 'Large - 48”L 48”W 75”H', img: null }],
        ['LoadTrailer', { text: '53’ Tractor Trailer', img: 'Tractor.svg' }],
        ['LoadTruck', { text: '26’ Box Truck', img: 'Truck.svg' }],
        ['LoadVan', { text: 'Sprinter Van', img: 'Sprinter.svg' }],
    ]));

    public readonly paymentTypes: Readonly<Record<PaymentTypeEnum, PaymentTypeDescription>> = Object.freeze({
        [PaymentTypeEnum.CREDIT_CARD]: {
            name: 'Credit Card',
            children: {
                0: {
                    name: 'Visa',
                    logo: 'https://www.freepnglogos.com/uploads/visa-logo-png-5.png',
                },
                1: {
                    name: 'American Express',
                    logo: '',
                },
                2: {
                    name: 'MasterCard',
                    logo: 'https://www.freepnglogos.com/uploads/discover-png-logo/mastercard-discover-logo-png-22.png',
                },
                3: {
                    name: 'Discover',
                    logo: 'https://www.freepnglogos.com/uploads/discover-png-logo/discover-careers-pmg-logo-0.png',
                },
                4: {
                    name: 'Gift Card',
                    logo: '',
                },
            },
        },
        [PaymentTypeEnum.ACH]: {
            name: 'ACH',
            children: {
                0: {
                    name: 'ACH',
                    logo: '',
                },
            },
        },
        [PaymentTypeEnum.PAYPAL]: {
            name: 'PayPal',
            children: {
                0: {
                    name: 'PayPal',
                    logo: 'https://www.freepnglogos.com/uploads/paypal-logo-png-2.png',
                },
            },
        },
        [PaymentTypeEnum.CASH]: {
            name: 'Cash',
            children: {
                0: {
                    name: 'Cash',
                    logo: null,
                },
            },
        },
    });

    constructor(
        private loader: LoaderService,
        private router: Router,
        private location: Location,
        private documentService: DocumentService,
    ) {
    }

    private sharedData: any = {};

    public CompanyInfo: any = {};

    navigate(url) {
        this.router.navigateByUrl(url);
    }

    navigateToRoute(commands: (string | number)[]) {
        this.router.navigate(commands);
    }

    replaceState(url) {

        this.location.replaceState(url);
    }

    showSuccess(message, title = 'Success') {
        return this.showSwal(message, title, 'success');
    }

    showError(message, title = 'Error') {
        return this.showSwal(message, title, 'error');
    }

    showWarning(message, title = 'Warning') {
        return this.showSwal(message, title, 'warning');
    }

    showMessage(message, title) {
        return this.showSwal(message, title, 'info');
    }

    showSuccessToast(title = 'Saved Successfully') {
        return swal.fire({
            title,
            icon: 'success',
            // position: 'bottom-start',
            showConfirmButton: false,
            timer: 1500,
        });
    }

    showErrorToast(title = 'E') {
        return swal.fire({
            title,
            icon: 'warning',
            showConfirmButton: false,
            timer: 1500,
        });
    }

    showConfirm(message, title = 'Please Confirm', confirmText, success) {
        message = this.getSwalMessage(message);

        swal.fire({
            icon: 'warning',
            title,
            html: message,
            showCancelButton: true,
            confirmButtonText: confirmText,
        }).then((result) => {
            if (result.value) {
                success();
            }
        });
    }

    async showConfirmWith(message, title = 'Please Confirm', confirmText: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve) => {
            message = this.getSwalMessage(message);

            const result = await swal.fire({
                icon: 'warning',
                title,
                html: message,
                showCancelButton: true,
                confirmButtonText: confirmText,
            })

            resolve(!!result.value);
        });
    }

    showSwal(message, title, type): Promise<SweetAlertResult> {
        message = this.getSwalMessage(message);

        return swal.fire({
            icon: type,
            title,
            html: message,
        });
    }

    getSwalMessage(message) {
        let text = '';
        if (Array.isArray(message)) {
            message.forEach(item => {
                text += item + '<br>';
            });
        } else if (typeof message === 'object' && Array.isArray(message.messages)) {
            // This usually means that the entire error response object was sent in
            message.messages.forEach(item => {
                text += item + '<br>';
            });
        } else if (typeof message === 'object' && message !== null) {
            // This is usually a .net object validation
            Object.keys(message).forEach(item => {
                text += message[item] + '<br>';
            });
        } else {
            text = message;
        }

        return text;
    }

    SetSheardData(key: string, value: any = {}) {
        this.sharedData[key] = value;
    }

    GetAndDeleteSheardData(key: string, defaultValue: any = {}) {

        let res = defaultValue;
        if (this.sharedData[key]) {
            res = this.sharedData[key];
            delete this.sharedData[key];
        }

        return res;
    }

    getUrlWithParams(url: string, data: any): string {
        const params = new URLSearchParams();
        for (const key in data) {
            if (Array.isArray(data[key])) {
                // tslint:disable-next-line:forin
                for (const value in data[key]) {
                    params.append(key, data[key][value]);
                }
            } else if (data[key] !== '') {
                params.set(key, data[key]);
            }
        }
        return `${url}?${params}`;
    }

    getUsStates() {
        return ['Alabama', 'Alaska', 'American Samoa', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Federated States of Micronesia', 'Florida', 'Georgia', 'Guam', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Marshall Islands', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Northern Mariana Islands', 'Ohio', 'Oklahoma', 'Oregon', 'Palau', 'Pennsylvania', 'Puerto Rico', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virgin Island', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'];
    }

    getUsStatesShort() {
        return ['AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FSM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'USVI', 'VA', 'WA', 'WV', 'WI', 'WY'];
    }

    mainLoaderOn() {
        this.loader.mainLoaderOn();
    }

    mainLoaderOff() {
        this.loader.mainLoaderOff();
    }

    GetValueWithComme(val, addComme = true) {

        console.log(val);
        if (val) {
            return val + (addComme ? ',' : '');
        }

        return '';
    }

    copyMessage(text) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = text;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    //
    GetItemTypeQty(itemType) {
        if (itemType === 'BoxesAndCrates' || itemType === 'Boxes & Crates') {
            return 'Box(es)';
        } else if (itemType === 'Pallets') {
            return 'Pallet(s)';
        } else if (itemType === 'FullLoad' || itemType === 'Full Load') {
            return 'Load(s)';
        } else if (itemType === 'Cfs' || itemType === 'CFS') {
            return 'Cfs';
        } else {
            return itemType;
        }
    }

    getFormattedMeasurements(orderItem) {
        if (orderItem?.formatedMeasurements){
            return orderItem.formatedMeasurements;
        }
        if(orderItem?.sizeType){
            return this.sizeTypes.get(orderItem.sizeType as any)?.text;
        }
        let value = '';

        if (orderItem?.sizeLength) {
            value += `${orderItem.sizeLength} L `;
        }
        if (orderItem?.sizeWidth) {
            value += `${orderItem.sizeWidth} W `;
        }
        if (orderItem?.sizeHight) {
            value += `${orderItem.sizeHight} H `;
        }

        if (orderItem?.sizeUnitOfMeasure === 'Inches') {
            value += `in`;
        } else if (orderItem.sizeUnitOfMeasure === 'Centimeters') {
            value += 'cm';
        }

        return value.trim();
    }

    getFormattedWeight(orderItem, qty = 1) {

        let value = '';

        if (orderItem.wight) {
            value += `${(qty * orderItem.wight) || 0} `;
        }else{
            return value;
        }
        if (orderItem.formattedUnitOfMeasure) {
            value += `${orderItem.formattedUnitOfMeasure} `;
            return value.trim();
        }

        if (orderItem.wightUnitOfMeasure === 'Inches') {
            value += `in`;
        } else if (orderItem.sizeUnitOfMeasure === 'Centimeters') {
            value += 'cm';
        } else if (orderItem.sizeUnitOfMeasure === 'Pounds') {
            value += 'lb';
        } else if (orderItem.sizeUnitOfMeasure === 'Kilograms') {
            value += 'kg';
        }

        return value.trim();
    }

    formatDate(dateStr: string): string {
        return formatDate(dateStr);
    }

    formatDateTime(dateStr: string): string {
        return formatDateTime(dateStr);
    }

    getBillOfLanding(orderId: number, mode: DocumentViewMode) {
        this.mainLoaderOn();
        return this.documentService.downloadBillOfLanding(orderId, mode).pipe(
            map(res => this.handleBlob(mode, res))
        );
    }

    getLabels(orderId: number, mode: DocumentViewMode) {
        this.mainLoaderOn();
        return this.documentService.downloadLabels(orderId, mode).pipe(
            map(res => this.handleBlob(mode, res))
        );
    }

    getQuote(orderId: number, mode: DocumentViewMode) {
        this.mainLoaderOn();
        return this.documentService.downloadQuote(orderId, mode).pipe(
            map(res => this.handleBlob(mode, res))
        );
    }

    getDispatchSheet(orderId: number, mode: DocumentViewMode) {
        this.mainLoaderOn();
        return this.documentService.downloadDispatchSheet(orderId, mode).pipe(
            map(res => this.handleBlob(mode, res))
        );
    }

    getInvoice(invoiceId: number, mode: DocumentViewMode) {
        this.mainLoaderOn();
        return this.documentService.downloadInvoice(invoiceId, mode).pipe(
            map(res => this.handleBlob(mode, res))
        );
    }

    private handleBlob(mode: DocumentViewMode | DocumentViewMode.Html, res: Blob) {
        this.mainLoaderOff();
        if (mode === DocumentViewMode.Pdf) {
            return Promise.resolve(new Blob([res], { type: 'application/pdf' }));
        } else if (mode === DocumentViewMode.Html) {
            return res.text();
        }
    }

    setDateTime(date: Date, time: string) {
        let momentTime = moment(time, 'hh:mm A');
        let utcDate = moment.utc(date);

        utcDate.set({
            hour: momentTime.hour(),
            minute: momentTime.minute(),
            second: 0,
            millisecond: 0
        });

        return utcDate.toDate();
    }

    parseDateTime(date: Date, time: string) {
        let momentDate = moment(date).format("YYYY-MM-DD");

        return moment(momentDate + ' ' + time);
    }

    parseDateTimeJS(date, time) {
        var timeParts = time.split(':');
        var hours = parseInt(timeParts[0], 10);
        var minutes = parseInt(timeParts[1].slice(0, 2), 10);
        var period = timeParts[1].slice(2).trim().toUpperCase();
        if (period === 'PM' && hours < 12) {
            hours += 12; // Adding 12 hours for PM time
        } else if (period === 'AM' && hours === 12) {
            hours = 0; // Converting 12 AM to 0 hours
        }
        date.setHours(hours);
        date.setMinutes(minutes);

        return date;
    }

    sendBol(orderId: number, email: string) {
        this.mainLoaderOn();
        this.documentService.emailBillOfLanding(orderId, email).subscribe(
            res => {
                this.showSuccess('The document has been sent');
            },
            error => {
                this.showError(error.messages != null ? error.messages : 'An error has occured', 'Error');
                console.log(error);
                this.mainLoaderOff();
            },
            () => this.mainLoaderOff()
        );
    }

    sendInvoice(invoiceId: number, email: string) {
        this.mainLoaderOn();
        this.documentService.emailInvoice(invoiceId, email).subscribe(
            res => {
                this.showSuccess('The invoice has been sent');
            },
            error => {
                this.showError(error.messages != null ? error.messages : 'An error has occured', 'Error');
                console.log(error);
                this.mainLoaderOff();
            },
            () => this.mainLoaderOff()
        );
    }

    sendQuote(orderId: number, email: string) {
        this.mainLoaderOn();
        this.documentService.emailQuote(orderId, email).subscribe(
            res => {
                this.showSuccess('The quote has been sent');
            },
            error => {
                this.showError(error.messages != null ? error.messages : 'An error has occured', 'Error');
                console.log(error);
                this.mainLoaderOff();
            },
            () => this.mainLoaderOff()
        );
    }

    downloadQuote(order: Order) {
        this.mainLoaderOn();
        this.getQuote(order.orderId, DocumentViewMode.Pdf).subscribe(
            async (res) => {
                const pdf = await res as Blob;
                saveAs(pdf, `quote-${order.orderNumber}.pdf`);
                this.mainLoaderOff();
            }, error => {
                this.showError(error.messages != null ? error.messages : 'An error has occured', 'Error');
                console.log(error);
            },
            () => this.mainLoaderOff()
        );
    }

    downloadDispatch(order: Order) {
        this.mainLoaderOn();
        this.getDispatchSheet(order.orderId, DocumentViewMode.Pdf).subscribe(
            async (res) => {
                const pdf = await res as Blob;
                saveAs(pdf, `dispatch-${order.orderNumber}.pdf`);
                this.mainLoaderOff();
            }, error => {
                this.showError(error.messages != null ? error.messages : 'An error has occured', 'Error');
                console.log(error);
            },
            () => this.mainLoaderOff()
        );
    }

    downloadInvoice(invoice: Invoice) {
        this.mainLoaderOn();
        this.getInvoice(invoice.invoiceId, DocumentViewMode.Pdf).subscribe(
            async (res) => {
                const pdf = await res as Blob;
                saveAs(pdf, `invoice-${invoice.number}.pdf`);
                this.mainLoaderOff();
            }, error => {
                this.showError(error.messages != null ? error.messages : 'An error has occured', 'Error');
                console.log(error);
            },
            () => this.mainLoaderOff()
        );
    }

    downloadBol(order: Order) {
        this.mainLoaderOn();
        this.getBillOfLanding(order.orderId, DocumentViewMode.Pdf).subscribe(
            async (res) => {
                const pdf = await res as Blob;
                saveAs(pdf, `bol-${order.orderNumber}.pdf`);
                this.mainLoaderOff();
            }, error => {
                this.showError(error.messages != null ? error.messages : 'An error has occured', 'Error');
                console.log(error);
            },
            () => this.mainLoaderOff()
        );
    }

    downloadLabels(order: Order) {
        this.mainLoaderOn();
        this.getLabels(order.orderId, DocumentViewMode.Pdf).subscribe(
            async (res) => {
                const pdf = await res as Blob;
                saveAs(pdf, `labels-${order.orderNumber}.pdf`);
                this.mainLoaderOff();
            }, error => {
                this.showError(error.messages != null ? error.messages : 'An error has occured', 'Error');
                console.log(error);
            },
            () => this.mainLoaderOff()
        );
    }

    showBol(order: Order, bolDocumentViewer: any) {
        this.getBillOfLanding(order.orderId, DocumentViewMode.Html).subscribe(
            async (res) => {
                const html = await res as string;
               // bolDocumentViewer.show(html, order);
            }, error => {
                console.log(error);
                this.mainLoaderOff();
                this.showError(error.messages != null ? error.messages : 'An error has occured', 'Error');
            }, () => this.mainLoaderOff()
        );
    }

    showQuote(order: Order, quoteDocumentViewer: any) {
        this.getQuote(order.orderId, DocumentViewMode.Html).subscribe(
            async (res) => {
                const html = await res as string;
                // quoteDocumentViewer.show(html, order);
            }, error => {
                console.log(error);
                this.mainLoaderOff();
                this.showError(error.messages != null ? error.messages : 'An error has occured', 'Error');
            }, () => this.mainLoaderOff()
        );
    }

    showDispatchSheet(order: Order, dispatchDocumentViewer: any) {
        this.getDispatchSheet(order.orderId, DocumentViewMode.Html).subscribe(
            async (res) => {
                const html = await res as string;
               // dispatchDocumentViewer.show(html, order, false);
            }, error => {
                console.log(error);
                this.mainLoaderOff();
                this.showError(error.messages != null ? error.messages : 'An error has occured', 'Error');
            }, () => this.mainLoaderOff()
        );
    }

    showInvoice(invoice: Invoice, invoiceDocumentViewer: any) {
        this.getInvoice(invoice.invoiceId, DocumentViewMode.Html).subscribe(
            async (res) => {
                const html = await res as string;
               // invoiceDocumentViewer.show(html, invoice, true, 'Accounting');
            }, error => {
                console.log(error);
                this.mainLoaderOff();
                this.showError(error.messages != null ? error.messages : 'An error has occured', 'Error');
            }, () => this.mainLoaderOff()
        );
    }

    countRemaining(quote: Order) {
        let count = 0;

        quote.orderItems.forEach(element => {

            const ois = element.orderItemStops != null ? element.orderItemStops.filter(oisInner => oisInner.isActive
                && oisInner.orderStop.orderStopType === 'Delivery') : [];

            let qtyPicked = 0;
            ois.forEach(oiss => {
                qtyPicked += parseInt(oiss.quantity, 10);
            });

            if (element.totalQuantity > qtyPicked) {
                count++;
            }
        });

        return count;
    }

    getRouteItemWeight(trip: OrderTrip | RouteItem): number {
        return getRouteItemWeight(trip);
    }

    getOrderStopId(orderStop: OrderStop) {
        return orderStop.orderStopId || orderStop.tempOrderStopId;
    }

    addPickedItems(quote: Order, delivery: OrderStop) {
        quote.orderItems.forEach(oi => {
            const find = delivery.orderItemStops.filter(oitem => oitem.orderItem.orderItemId === oi.orderItemId);
            const findOrOrder = oi.orderItemStops.filter(ois => ois.orderStop.orderStopType === 'Delivery'
                && this.getOrderStopId(ois.orderStop) === this.getOrderStopId(delivery));
            if (oi.pickQuantity > 0) {
                if (find.length > 0) {
                    find[0].quantity = oi.pickQuantity;
                    find[0].isActive = true;

                    findOrOrder[0].quantity = oi.pickQuantity;
                    findOrOrder[0].isActive = true;
                } else {
                    delivery.orderItemStops.push({
                        isActive: true,
                        orderItem: {
                            orderItemId: oi.orderItemId,
                            accessoriesNeeded: oi.accessoriesNeeded,
                            formatedMeasurements: oi.formatedMeasurements,
                            formatedWeight: oi.formatedWeight,
                            isActive: oi.isActive,
                            isFragile: oi.isFragile,
                            isHazardous: oi.isHazardous,
                            isOther: oi.isOther,
                            isOverweight: oi.isOverweight,
                            isRefrigerated: oi.isRefrigerated,
                            itemDescription: oi.itemDescription,
                            itemName: oi.itemName,
                            itemTypeQty: oi.itemTypeQty,
                            orderItemType: oi.orderItemType,
                            sizeHight: oi.sizeHight,
                            sizeLength: oi.sizeLength,
                            sizeType: oi.sizeType,
                            sizeUnitOfMeasure: oi.sizeUnitOfMeasure,
                            sizeWidth: oi.sizeWidth,
                            wight: oi.wight,
                            wightUnitOfMeasure: oi.wightUnitOfMeasure,
                        },
                        quantity: oi.pickQuantity
                    });

                    oi.orderItemStops.push({
                        isActive: true,
                        orderStop: {
                            orderStopId: delivery.orderStopId,
                            tempOrderStopId: delivery.tempOrderStopId,
                            orderStopType: delivery.orderStopType,
                            isActive: delivery.isActive,
                        },
                        quantity: oi.pickQuantity
                    });
                }
            } else {
                if (find.length > 0) {
                    find[0].isActive = false;
                    findOrOrder[0].isActive = false;
                }
            }
        });
    }
}
