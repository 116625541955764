import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[onlyDigits]'
})
export class OnlyDigitsDirective {
  @Input() maxLength: number = 7;  // Default maxLength to 7

  // Prevent non-digit characters on keypress and handle maxLength
  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent): void {
    const inputElement = event.target as HTMLInputElement;

    // Only apply to elements with the 'main-input' class
    if (!inputElement.classList.contains('only-digits-input')) {
      return;
    }

    const currentLength = inputElement.value.length;

    // If maxLength is exceeded, prevent the input
    if (currentLength >= this.maxLength) {
      event.preventDefault();
      return;
    }

    const regex = /^[0-9]*$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!regex.test(inputChar)) {
      event.preventDefault();
    }
  }

  // Handle paste event to allow only digits and respect maxLength
  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedData = clipboardData.getData('text');

    // Remove any non-digit characters from pasted content
    const cleanedData = pastedData.replace(/\D/g, '');

    // Prevent the default paste behavior
    event.preventDefault();

    // Get the input element and its selection range
    const inputElement = event.target as HTMLInputElement;
    const currentSelectionStart = inputElement.selectionStart ?? 0;
    const currentSelectionEnd = inputElement.selectionEnd ?? 0;

    // If the input is fully selected, replace its content
    const newValue =
      currentSelectionStart === 0 && currentSelectionEnd === inputElement.value.length
        ? cleanedData
        : inputElement.value.slice(0, currentSelectionStart) +
        cleanedData +
        inputElement.value.slice(currentSelectionEnd);

    // Ensure the new value doesn't exceed maxLength
    const truncatedValue = newValue.slice(0, this.maxLength);

    // Set the updated value
    inputElement.value = truncatedValue;

    // Trigger Angular form update
    inputElement.dispatchEvent(new Event('input'));
  }

}
