import { AfterContentChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { SamsaraService } from '@app/services/samsara.service';
import { Subject } from 'rxjs/internal/Subject';
import { interval, Subscription } from 'rxjs';
import { VehicleLocation } from '@models/samsara/vehicle-location.model';
import { debounceTime, map } from 'rxjs/operators';
import { RouteCommand, RouteService } from '@app/services/route.service';
import { Route, Routes } from '@models/route.model';
import { RouteBoxUIGroup } from '@app/helpers/route-box-ui-group';
import { WarehouseService } from '@app/services/warehouse.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '@environments/environment';
import { AddEditRouteDialogComponent } from './add-edit-route-dialog/add-edit-route-dialog.component';
import { HelperService } from '@app/shared/services/helper.service';
import { MarkersDisplay, RoutesService } from '@app/shared/services/router.service';
import moment from "moment"
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { Zone } from '@app/models/zone.model';
import { AppService } from '@app/shared/services/app.service';
import { ChargeTypeService } from '@app/shared/services/charge-type.service';
import { cloneDeep } from 'lodash';
import { OrderTripStatusEnum } from '@app/models/order.model';

export enum DateKeyEnum {
    TODAY = 'Today',
    TOMORROW = 'Tomorrow',
}
@UntilDestroy()
@Component({
    standalone: false,
    selector: 'app-routes',
    templateUrl: './routes.component.html',
    styleUrls: ['./routes.component.scss']
})

export class RoutesComponent implements AfterContentChecked, OnInit, OnDestroy {
    dateOptions: any[] = [
        {
            title: DateKeyEnum.TODAY,
            value: moment()
        },
        {
            title: DateKeyEnum.TOMORROW,
            value: moment().add(1, 'days')
        }
    ];
    selectedZone: Zone;
    selectedRouteHistory: Route;
    activeBtn = '';
    useTrimbleMaps: Boolean = environment.useTrimbleMaps;
    vehicleLocations: VehicleLocation[] = [];
    private locationsSubscription = new Subscription();
    searchTerm = '';
    dateType = 'None';
    date: Date = null;
    // today: Date = moment().startOf('day').toDate();
    // tomorrow: Date = moment().add(1, 'days').startOf('day').toDate();
    loading: boolean = true;
    loadMoreLoading: boolean;
    reloadedRouteId?: number = null;
    completedRoutes: boolean = false;
    routes: Routes[];
    routesRequest: any = {
        page: 1,
        separateCompletedDirections: true,
        itemsPerPage: 12
    };
    routes$: Subject<any> = new Subject();
    totalRoutes: number;
    dateRange: any[] = [];

    isMapFixed: boolean = true;
    viewType: 'grid' | 'list' = 'grid';

    scrollEvent$ = new Subject();

    unassignedMarkersCount: number = 0;
    unassignedPickupsCount: number = 0;
    unassignedDropOffsCount: number = 0;

    displayMapMarkers:MarkersDisplay = {
        showTrucks: true,
        showRoutes: true,
        showUnassigned: false,
        showPickups: false,
        showDropoffs: false,
    }
    isDriversBrokers = false;
    isNavCollapsed: boolean;

    constructor(
        private samsaraService: SamsaraService,
        private routeService: RouteService,
        private appService: AppService,
        private router: Router,
        private chargeTypeService: ChargeTypeService,
        private warehouseService: WarehouseService,
        private modalService: NgbModal,
        public helperService: HelperService,
        public routesService: RoutesService,
        private activatedRoute: ActivatedRoute,
        private readonly cdr: ChangeDetectorRef,
        private renderer: Renderer2,
    ) {

        const isFixed = localStorage.getItem('route.isMapFixed')

        this.isMapFixed = isFixed !== 'no';
    }

    toggleMapPin() {
        this.isMapFixed = !this.isMapFixed
        localStorage.setItem('route.isMapFixed', this.isMapFixed ? 'yes' : 'no')
    }
    toggleView() {
        this.routesService.routeViewType = this.viewType === 'grid' ? 'list' : 'grid'
    }

    ngOnInit() {
        this.routesService.allRoutes = []
        const routesItemsDocument = document.querySelector('.routes-items');
        const routesContainerDocument = document.querySelector('.routes-container');

        if (routesItemsDocument) {
            this.addScrollListener(routesItemsDocument);
        }

        if (routesContainerDocument) {
            this.addScrollListener(routesContainerDocument);
        }
        this.loadVehicleLocations();
        this.chargeTypeService.getPerStopChargeTypes();
        this.warehouseService.loadList();

        this.routesService.$routeViewType.pipe(untilDestroyed(this),)
            .subscribe((view) => {
                this.viewType = view
            })


        this.appService.$searchTerm
            .pipe(
                untilDestroyed(this),
                debounceTime(500)
            )
            .subscribe((text) => {
                this.searchTermChanged(text)
            })

        if (this.activatedRoute.snapshot.queryParams) {
            this.routesRequest = Object.assign(this.routesRequest, this.activatedRoute.snapshot.queryParams);
            if (this.activatedRoute.snapshot.queryParams.Date) {
                this.date = this.activatedRoute.snapshot.queryParams.Date
            }
        }

        this.locationsSubscription = interval(10000)
            .pipe(
                untilDestroyed(this)
            )
            .subscribe(() => {                
                // this.samsaraService.getFleetLocations().pipe(
                //     map(vl => vl.filter(l => l.latitude !== 0 && l.longitude !== 0))
                // ).subscribe(() => { });
            });

        this.helperService.isNavCollapsed$.pipe(untilDestroyed(this)).subscribe(
            (isNavCollapsed: boolean) => {          
                this.isNavCollapsed = isNavCollapsed
            }
        );
        
        this.routesService.$reloadRoutes.pipe(untilDestroyed(this)).subscribe(
            (res: any) => {
                if (res) {
                    this.routesRequest.page = 1;
                    this.routes$.next(true);
                    this.loadVehicleLocations();
                }
            }
        );
        this.routesService.$allRoutes.pipe(untilDestroyed(this)).subscribe(
            (res: Routes[]) => {
                if (res) {
                    this.routes = res
                }
            }
        );
        this.routesService.$markersDisplay.pipe(untilDestroyed(this)).subscribe(
            (res) => {
                this.displayMapMarkers = res
            }
        );

        this.routes$
            .pipe(
                debounceTime(500),
                untilDestroyed(this)
            ).subscribe(async (request: any) => {
                request = Object.assign({}, this.routesRequest, request)
                if (request.Date) {
                    request.DateRange = [moment(request.Date).startOf('day').toISOString(), moment(request.Date).endOf('day').toISOString()];
                    delete request.Date;
                } else {
                    request.DateRange = [];
                    delete request.Date;
                }
                this.routesService.cancelPendingRequestGetAll();
                if (!this.loadMoreLoading) {
                    this.loading = true;
                }

                // Currently search only routes with ToDo items 
                if (request?.SearchTerm?.length > 0) {
                    request['routeItemStatus'] = 0
                }

                await this.routesService.getAll(request, true).then(({ list, totalCount }) => {
                    // this.routes = (request.page > 1) ? this.routes.concat(list) : list;
                    this.totalRoutes = totalCount;
                    this.dateRange = request.DateRange;

                }).catch((error) => {
                    this.helperService.errorMessage(error)
                }).finally(() => {
                    this.reloadedRouteId = null;
                    this.loading = false;
                    this.loadMoreLoading = false
                    this.cdr.detectChanges();
                    this.checkScrollAbility(this.isMapFixed ? routesItemsDocument : routesContainerDocument);
                })

            });

        this.routes$.next(true);
        this.routesService.resetToggleRoutes()

        this.scrollEvent$
            .pipe(
                debounceTime(200),
                untilDestroyed(this),
            )
            .subscribe(() => {
                this.loadMoreRoutes();
            });

        this.routesService.$assignStopsToRoute.pipe(untilDestroyed(this)).subscribe(
            (res: any) => {
                if (res?.stops && res?.routeId) {
                    this.assignStops(this.routes?.map(rs => rs?.route).find(r => r?.routeId === res?.routeId), res?.stops);
                }
            }
        );

        this.routesService.$reloadRoute.pipe(untilDestroyed(this)).subscribe(
            async (res: any) => {
                var routeId = res?.routeId;

                if (routeId) {
                    await this.routesService.get(routeId, { searchTerm: this.appService.searchTerm, separateCompletedDirections: true }).then((routeResponse) => {
                        if (routeResponse != null) {
                            var dataStoreIndex = this.routesService.dataStore.allRoutes.findIndex(x => x.route?.routeId === routeResponse?.route?.routeId);
                            if (dataStoreIndex >= 0) {
                                this.routesService.dataStore.allRoutes[dataStoreIndex] = routeResponse;
                                this.routesService.allRoutes = this.routesService.dataStore.allRoutes;
                            }
                            if (this.routesService.dataStore?.currentRoute?.routeId === routeResponse?.route?.routeId) {
                                this.routesService.currentRoute = routeResponse.route
                            }
                            this.loadVehicleLocations();


                        }
                    }).catch((error) => {
                        this.reloadedRouteId = null;
                    })
                }
            }
        );

        const orderId = this.activatedRoute.snapshot.queryParams.orderId
        const status = this.activatedRoute.snapshot.queryParams.tripStatus
        if (orderId && OrderTripStatusEnum.OPEN !== status) {
            this.appService.searchTerm = orderId
        }

    }

    ngAfterContentChecked() {
        this.cdr.detectChanges();
    }

    ngOnDestroy() {
        // Reset selected data on route change
        this.appService.searchTerm = ''
        this.routesService.allRoutes = []
        this.routesService.currentRoute = null
        this.routesService.currentRouteStop = null
        this.routesService.unassignedStops = []
        this.routesService.toggleMarkersDisplay({
            showTrucks: true,
            showRoutes: true,
            showUnassigned: false,
            showPickups: false,
            showDropoffs: false,
        })
    }

    toggleMapMarkers(){
        this.routesService.toggleMarkersDisplay(this.displayMapMarkers)
    }

    private loadVehicleLocations() {
        this.samsaraService.getFleetLocations().pipe(
            map(vl => vl.filter(l => l.latitude !== 0 && l.longitude !== 0))
        ).subscribe(
            (locations) => {
                this.vehicleLocations = locations
            }
        );
    }

    private assignStops(route: Route, stops: RouteBoxUIGroup[]) {
        for (const stop of stops) {
            stop?.items?.forEach(element => {
                element.selected = true;
            });
        }
        this.UploadRouteStops(route, stops);
    }

    UploadRouteStops(route: Route, stops: RouteBoxUIGroup[]) {
        for (const stop of stops) {
            const uniquePickupBoxes = stop.pickupRouteBoxes
                .filter(b => b != null)
                .filter((box, index, self) => 
                    self.findIndex(b => b.orderItemStopId === box.orderItemStopId) === index
                );

            for (const box of uniquePickupBoxes) {
                this.routesService.processNewPickupStop(box, box.items.filter(i => i.selected), route);
            }
            // process DropOff
            for (const box of stop.dropOffRouteBoxes.filter(b => b != null)) {
                this.routesService.processNewPickupStop(box, box.items.filter(i => i.selected), route);
            }
        }
        // update to back end
        this.routesService.updateRouteStops(route.routeId, route.routeStopGroups).then((res) => {
            this.routeService.sendRouteCommand(RouteCommand.SetupMap);
            this.routes$.next(true);
        }).catch((error) => {
            this.helperService.errorMessage(error)
        });
    }

    showSelectedRouteHistory(event) {
        this.selectedRouteHistory = event
    }

    searchTermChanged(search) {
        // this.router.navigate(['app/dispatch'], {
        //     queryParams: {
        //         // SearchTerm: search ? search : null,
        //     },
        //     queryParamsHandling: 'merge'
        // });
        this.routesRequest.SearchTerm = search || [];
        this.routesRequest.page = 1;
        this.routes$.next(true);
    }

    onZoneSelect(zone) {
        this.selectedZone = zone == true ? null : zone
        this.routesRequest.Zone = zone.name || [];
        this.routesRequest.page = 1;
        this.routes$.next(true);
    }

    dateChanged(date) {
        this.router.navigate(['app/dispatch'], {
            queryParams: {
                Date: date ? date.toDateString() : null,
            },
            queryParamsHandling: 'merge'
        });
        this.date = date;
        this.routesRequest.Date = date != null ? date.toDateString() : null;
        this.routesRequest.page = 1;
        this.routes$.next(true);
    }

    checkScrollAbility(element) {
        if (element && (element.clientHeight >= element.scrollHeight) && (this.routes.length < this.totalRoutes) && (this.routes.length !== 0) && !this.loadMoreLoading) {
            this.scrollEvent$.next(true); // Trigger loading more content if not scrollable
        }
    }
    addScrollListener(document) {
        setTimeout(() => {
            this.renderer.listen(document, 'scroll', this.onRoutesScroll.bind(this));
        }, 100);
    }

    onRoutesScroll(event: any) {
        const dropdownElement = event.target;
        const offset = 50;
        if (dropdownElement.scrollTop + dropdownElement.clientHeight >= dropdownElement.scrollHeight - offset) {
            this.scrollEvent$.next(true);
        }
    }

    loadMoreRoutes() {
        if (this.routes.length < this.totalRoutes && this.routes.length !== 0 && !this.loadMoreLoading) {
            this.loadMoreLoading = true
            this.routesRequest.page++;
            this.routes$.next(this.routesRequest);
        }
    }


    addRoute() {
        const activeModal = this.modalService.open(AddEditRouteDialogComponent, {
            scrollable: true,
            size: 'sm'
        });
        activeModal.result.then(
            (result) => {
                if (result) {
                    this.routesService.reloadRoutes = true
                }
            },
            () => { }
        );
    }

    reloadDatatable() {
        if (this.completedRoutes) {
            this.routesRequest.completedRoutes = this.completedRoutes
        } else {

          delete this.routesRequest.completedRoutes
        }
        this.routesService.reloadRoutes = true
    }

}
