import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { PricingModelRoutingModule } from './pricing-model-routing.module';
import { PricingModelListComponent } from './pricing-model-list/pricing-model-list.component';
import { DatatableModule } from '@app/@shared/datatable';
import { DialogsModule } from '@app/@shared/dialogs/dialogs.module';
import { CustomerSelectModule } from '@app/@shared/customer-select/customer-select.module';
import { FormValidateModule } from '@app/@shared/form-validate/form-validate.module';

@NgModule({
  declarations: [PricingModelListComponent],
  exports: [PricingModelListComponent],
  imports: [
    CommonModule,
    PricingModelRoutingModule,
    SharedModule,
    DatatableModule,
    DialogsModule,
    CustomerSelectModule,
    FormValidateModule,
  ],
})
export class PricingModelsModule {}