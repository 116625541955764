import { AfterViewInit, Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from "@angular/core";
import { NG_VALUE_ACCESSOR, NgModel } from "@angular/forms";
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

@Component({
	selector: 'form-phone-input',
	templateUrl: './phone.component.html',
	styleUrls: ['./phone.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => FormPhoneInputComponent),
			multi: true
		}
	]
})
export class FormPhoneInputComponent implements OnInit, AfterViewInit {

	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
	PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates];

	onChange: any = () => { };
	onTouched: any = () => { };

	@ViewChild('inputEl') inputEl: NgModel;

	@Output() onChanged = new EventEmitter<string>();

	@Input() iconShow: boolean = false;
	@Input() disabled: boolean = false;
	@Input() type: string = 'text';
	@Input() name: string = 'phoneNumber';
	@Input() required = false
	@Input() label: string
	@Input() placeholder: string = "(201) 555-0123"
	@Input() maxLength: number = 15;


	_phoneNumber: string = '';
	@Input() set phoneNumber(val: any) {			
		this._phoneNumber = val?.number;
		this.onChange(val?.number);
		//
	}
	get phoneNumber(): string {
		return this._phoneNumber;
	}


	ngOnInit(): void { }

	ngAfterViewInit(): void {
		this.inputEl?.statusChanges?.subscribe((e) => {
			if (this.inputEl?.touched) {
				this.onTouched();
			}
		});
	}


	writeValue(phone: string) {
		this._phoneNumber = phone;
	}

	registerOnChange(fn: (rating: number) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}
}
