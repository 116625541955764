// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .default-card {
  margin-bottom: 8px;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
}
:host .default-card .radio-input {
  height: 0;
  width: 0;
}
:host .default-card .card-text {
  margin-bottom: 0;
}
:host .highlight-card {
  border: 1px solid #00bad7;
  background-color: rgba(0, 186, 215, 0.1);
  transition: all 0.3s ease;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/customers/customer-list/customer-marge/customer-marge.component.scss"],"names":[],"mappings":"AAEI;EACI,kBAAA;EACA,gBAAA;EACA,WAAA;EACA,eAAA;AADR;AAEQ;EACI,SAAA;EACA,QAAA;AAAZ;AAEQ;EACE,gBAAA;AAAV;AAGI;EACI,yBAAA;EACA,wCAAA;EACA,yBAAA;AADR","sourcesContent":["@use \"common/var\" as *;\n:host {\n    .default-card {\n        margin-bottom: 8px;\n        overflow: hidden;\n        width: 100%;\n        cursor: pointer;\n        .radio-input {\n            height: 0;\n            width: 0;\n        }\n        .card-text{\n          margin-bottom: 0;\n        }\n    }\n    .highlight-card {\n        border: 1px solid $primary;\n        background-color: rgba($color: $primary, $alpha: 0.1);\n        transition: all 0.3s ease;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
