// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .exclude-expenses-form .form-label {
  font-size: 14px;
}
:host .exclude-expenses-form .form-control {
  min-height: 48px;
  font-size: 14px;
  border: 2px solid #aab8c1;
}
:host .exclude-expenses-form .btn {
  justify-content: center;
  font-size: 16px;
  line-height: 1rem;
  padding: 14px 20px;
}
:host .input-group {
  display: flex;
}
:host .input-group .form-control {
  flex: 1;
}
:host .input-group .btn {
  margin-left: -1px;
}
:host .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
:host .divider {
  border-bottom: 1px solid #e5e5e5;
  margin: 15px 0;
}
:host .modal-footer {
  display: flex;
  justify-content: flex-end;
}
:host .modal-footer .btn {
  margin-left: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/settings/expenses/exclude-expenses/exclude-expenses.component.scss"],"names":[],"mappings":"AAEI;EACE,eAAA;AADN;AAII;EACE,gBAAA;EACA,eAAA;EACA,yBAAA;AAFN;AAKI;EACE,uBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;AAHN;AAOE;EACE,aAAA;AALJ;AAOI;EACE,OAAA;AALN;AAQI;EACE,iBAAA;AANN;AAUE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AARJ;AAWE;EACE,gCAAA;EACA,cAAA;AATJ;AAYE;EACE,aAAA;EACA,yBAAA;AAVJ;AAYI;EACE,iBAAA;AAVN","sourcesContent":[":host {\n  .exclude-expenses-form {\n    .form-label {\n      font-size: 14px;\n    }\n\n    .form-control {\n      min-height: 48px;\n      font-size: 14px;\n      border: 2px solid #aab8c1;\n    }\n\n    .btn {\n      justify-content: center;\n      font-size: 16px;\n      line-height: 1rem;\n      padding: 14px 20px;\n    }\n  }\n\n  .input-group {\n    display: flex;\n\n    .form-control {\n      flex: 1;\n    }\n\n    .btn {\n      margin-left: -1px;\n    }\n  }\n\n  .modal-header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n\n  .divider {\n    border-bottom: 1px solid #e5e5e5;\n    margin: 15px 0;\n  }\n\n  .modal-footer {\n    display: flex;\n    justify-content: flex-end;\n\n    .btn {\n      margin-left: 15px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
