import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingModule } from '@app/shared/modules/loading/loading.module';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { FilesViewerModalComponent } from './files-viewer-modal.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';

@NgModule({
    declarations: [
        FilesViewerModalComponent,
        ImageViewerComponent,
        PdfViewerComponent,
    ],
    exports: [
        FilesViewerModalComponent,
        ImageViewerComponent,
        PdfViewerComponent,
    ],
    imports: [
        CommonModule,
        NgbModule,
        LoadingModule,
        PdfViewerModule,
        NgxGalleryModule,
    ],

})
export class FilesViewerModalModule { }