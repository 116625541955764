import { AfterViewInit, Component, forwardRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { Address } from '@app/models/address.model';
import { OrderService } from '@app/shared/services/order.service';

@UntilDestroy()
@Component({
  	standalone: false,
	selector: 'app-select-multiple-address',
	templateUrl: './select-multiple-address.component.html',
	styleUrls: ['./select-multiple-address.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SelectMultipleAddressComponent),
			multi: true
		}
	],
	encapsulation: ViewEncapsulation.Emulated,
})
export class SelectMultipleAddressComponent implements AfterViewInit, OnInit {

	// default enabled dropdown for address multiple select
	disabled: boolean = false;
	loading: boolean = false;

	onChange = (value: string) => { };
	onTouched = () => { };

	/*
	* Getter & Setter for dynamic placeholder
	*/
	_placeholder: string;
	get placeholder(): string {
		return this._placeholder;
	}
	@Input() set placeholder(value: string) {
		this._placeholder = value;
	}

	private _value: string | any;
	public get value(): string | any {
		return this._value;
	}
	public set value(val: string | any) {
		this._value = val;
		this.onChange(val);
		this.onTouched();
	}

	@Input() pickUpAddresses: boolean;
	@Input() deliveryAddresses: boolean;
	@Input() isOrder: boolean;
	@Input() usedPickUpAddresses: string[];
	@Input() usedDeliveryAddresses: string[];
	@Input() dateRange: any[];

	public addresses: Address[] = [];
	public searchTerm$: BehaviorSubject<string | null> = new BehaviorSubject(null);

	constructor(
		private readonly orderService: OrderService
	) { }

	ngOnInit(): void {
		this.searchTerm$.next(null);
	}

	ngAfterViewInit() {
		this.searchTerm$
			.pipe(
				debounceTime(300), // Delay between API calls
				distinctUntilChanged(), // Only call API when the search term changes
				tap(() => this.searchOrderAddresses()),
				untilDestroyed(this)
			)
			.subscribe();
	}

	/**
	 * Custom search function
	 * 
	 * @param term 
	 * @param item 
	 * @returns 
	 */
	customSearchAddressFn(term: string, item: any): boolean {
		// Implement your custom search logic here
  		term = term.toLowerCase();

		const fieldsToSearch = ['fullAddress'];

		for (const field of fieldsToSearch) {
			const fieldValue = item[field]?.toLowerCase(); // Get the value of the current field

			if (fieldValue && fieldValue.includes(term)) {
				return true; // Return true if the field value contains the search term
			}
		}

		return false; // Return false if none of the fields match the search term
  	}

	/**
	 * On order addresses search in self multiple dropdown
	 * 
	 * @param item 
	 */
	onSearchAddress(item: any) {
		if (item) {
			this.searchTerm$.next(item?.term);
		}
	}

	/**
	 * Search addresses from orders
	 * 
	 * @param filter 
	 */
	private async searchOrderAddresses() {
		this.loading = true;
		
		// address search term
		const searchTerm = this.searchTerm$.getValue() || null;

		// address filter request with pagination & search term
		const request = {
			// ToDo make it pagination dynamic
			Page: 1, 
			ItemsPerPage: 20,
			pickUpAddresses: (this.pickUpAddresses || false),
			deliveryAddresses: (this.deliveryAddresses || false),
			isOrder: (this.isOrder || false),
			fromAddress: (this.usedPickUpAddresses || []),
			ToAddress: (this.usedDeliveryAddresses || []),
			dateRange: (this.dateRange || []),
			...(searchTerm ? { SearchTerm: searchTerm } : {})
		}

		try {
			this.addresses = [];
			this.addresses = await this.orderService.searchOrderAddresses(request);
		} catch (error) {
			console.error('Error while retrieving order addresses!', error);
		} finally {
			this.loading = false;
		}
	}

	writeValue(value: string) {
		this._value = value
	}

	registerOnChange(fn: (value: string) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
