// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .user-datatable {
  height: calc(100vh - 280px);
}
:host .setting-dropdown .dropdown-item {
  display: flex;
  align-items: center;
}
:host .setting-dropdown .dropdown-item .icon {
  margin-right: 16px;
}
:host .roles {
  min-width: 328px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/settings/users/user-list/user-list.component.scss"],"names":[],"mappings":"AACI;EACI,2BAAA;AAAR;AAGQ;EACI,aAAA;EACA,mBAAA;AADZ;AAEY;EACI,kBAAA;AAAhB;AAII;EACI,gBAAA;AAFR","sourcesContent":[":host {\n    .user-datatable{\n        height: calc(100vh - 280px);\n    }\n    .setting-dropdown {\n        .dropdown-item {\n            display: flex;\n            align-items: center;\n            .icon {\n                margin-right: 16px;\n            }\n        }\n    }\n    .roles {\n        min-width: 328px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
