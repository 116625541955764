import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OrderItem } from '@models/order-item.model';
import { UtilService } from '@app/services/util.service';
import { OrderItemStop } from '@models/order-item-stop.model';
import { PickupTypeEnum } from '@app/models/pickup-type';

@Component({
    standalone: false,
    selector: 'app-order-item',
    templateUrl: './order-item.component.html',
    styleUrls: ['./order-item.component.scss']
})
export class OrderItemComponent {
    @Input() stop: OrderItemStop = {
        quantity: 1,
        orderItem: {
            itemName: 'Freight',
            orderItemType: PickupTypeEnum.BOXES_CRATES,
            attachments: [],
        }
    };

    @Output() itemChange: EventEmitter<OrderItemStop> = new EventEmitter<OrderItemStop>();

    constructor(
        public util: UtilService,
    ) {
    }

    changeQtyDown() {
        if (!this.stop.quantity) {
            this.stop.quantity = 0;
        } else {
            this.stop.quantity = Math.max(0, this.stop.quantity - 1);
        }
    }

    changeQtyUp() {
        if (!this.stop.quantity) {
            this.stop.quantity = 1;
        } else {
            this.stop.quantity++;
        }
    }

    update() {
        this.itemChange.emit(this.stop);
    }
}
