import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { plans } from '@app/data/plan';
import { PaymentMethod } from '@app/models/payment-method.model';
import {  SubscriptionPlans } from '@app/models/plan.model';
import { User } from '@app/models/user.model';
import { AuthService } from '@app/shared/services/auth/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { number, object, string } from 'yup';

@UntilDestroy()
@Component({
  standalone: false,
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  planDetails = plans;
  errorMessage: any = null;
  selectedPlan: SubscriptionPlans;
  tax = 156.26;
  processingFee = 250;
  paymentMethod: PaymentMethod = {
    maskedNumber:'',
    address:{
      zip: null
    }   
  };

  user: User;

  validationSchema = object().shape({
    //maskedNumber: number().nullable().label("Card Number").required().max(16),
    //cvv: number().nullable().label("Cvv").required().max(4),
    expiryMonth: number().nullable().positive().min(1).max(12).required(),
    expiryYear: number().nullable().positive().min(23).max(99).required(),
    zipCode: string().nullable().required().matches(/^[0-9]/, { message: 'Zip code must be a number' }).max(6),
  });

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.authService.$user
      .pipe(untilDestroyed(this))
      .subscribe(user => {
        this.user = user;
      });
    this.route.queryParams.subscribe(params => {
      this.selectedPlan = this.planDetails.find(res => res.subscriptionPlanId == params.id)
      if(!this.selectedPlan){
        this.router.navigate(['/400']);
      }
    });
  }

  onSubmit() {

  }

}