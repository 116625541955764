import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './core/crud.service';
import { CustomerContact, ValidateContact } from '@app/models/customer-contact.model';
import { ApiResponse } from '@app/models/core/base';
import { firstValueFrom } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class CustomerContactService extends CrudService<CustomerContact>{

    override basePath = 'CustomerContacts';

    constructor(protected http: HttpClient) {
        super(http);
    }

    validateContact(request?: any) {
        return firstValueFrom(this.http.post<ApiResponse<ValidateContact>>(`${this.basePath}/ValidateContact`, request))
            .then((({ data }) => data));
    }

}
