import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AddressLookupComponent } from '@app/@shared/address-lookup/address-lookup.component';
import { OrderStop } from '@app/models/order-stop.model';
import { Order } from '@app/models/order.model';
import { HelperService } from '@app/shared/services/helper.service';
import { OrderService } from '@app/shared/services/order.service';
import { WarehouseService } from '@app/shared/services/warehouse.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  standalone: false,
  selector: 'app-warehouse-selector',
  templateUrl: './warehouse-selector.component.html',
  styleUrls: ['./warehouse-selector.component.scss']
})
export class WarehouseSelectorComponent implements OnInit {
  @Input() pickup: OrderStop;
  @Input() delivery: OrderStop;
  warehouses: any[] = [];
  selectedPickupWarehouse: any;
  selectedDeliveryWarehouse: any;
  showFormFor: 'pickup' | 'delivery' | null = null;
  newPickupWarehouse = { name: '', address: null };
  newDeliveryWarehouse = { name: '', address: null };
  @ViewChild('pickupAddressLookup') pickupAddressLookup: AddressLookupComponent;
  @ViewChild('deliveryAddressLookup') deliveryAddressLookup: AddressLookupComponent;

  warehousesRequest: any = {
    page: 1,
    itemsPerPage: 500
  };

  constructor(
    public activeModal: NgbActiveModal,
    private warehouseService: WarehouseService,
    private orderService: OrderService,
    private helperService: HelperService
  ) { }

  ngOnInit() {
    this.getWarehouses();
  }

  async getWarehouses() {
    this.warehouses = (await this.warehouseService.getAll(this.warehousesRequest)).list
  }

  showNewWarehouseForm(type: 'pickup' | 'delivery') {
    this.showFormFor = type;
  }

  selectAddress(event: any, type: 'pickup' | 'delivery') {
    if (type === 'pickup') {
      this.newPickupWarehouse.address = event;
    } else if (type === 'delivery') {
      this.newDeliveryWarehouse.address = event;
    }
  }

  isPickupFormInvalid(): boolean {
    return !this.newPickupWarehouse.name || !this.newPickupWarehouse.address;
  }

  isDeliveryFormInvalid(): boolean {
    return !this.newDeliveryWarehouse.name || !this.newDeliveryWarehouse.address;
  }

  async addWarehouse(type: 'pickup' | 'delivery', form: NgForm) {
    const newWarehouse = type === 'pickup' ? this.newPickupWarehouse : this.newDeliveryWarehouse;

    try {
      let result = (await this.warehouseService.create(newWarehouse)).data

      this.warehouses.push(result);

      if (type === 'pickup') {
        this.pickup.warehouseId = result.warehouseId;
        this.newPickupWarehouse = { name: '', address: '' };
      } else if (type === 'delivery') {
        this.delivery.warehouseId = result.warehouseId;;
        this.newDeliveryWarehouse = { name: '', address: '' }; 
      }

      form.resetForm();
      this.resetAddressLookup(type);
    } catch (error) {
      this.helperService.errorMessage(error)
    }

  }

  resetAddressLookup(type: 'pickup' | 'delivery') {
    if (type === 'pickup') {
      this.newPickupWarehouse.address = '';
    } else if (type === 'delivery') {
      this.newDeliveryWarehouse.address = '';
    }
  }

  async save() {
    try {
      await this.orderService.validateQuoteWarehouses(this.pickup, this.delivery);
      this.helperService.successMessage("Warehouses assigned successfully")
      this.activeModal.close();
    } catch (error) {
      this.helperService.errorMessage(error)
      this.pickup.warehouseId = null;
      this.delivery.warehouseId = null;
    }
  }

  closeModal() {
    this.activeModal.dismiss();
  }
}
