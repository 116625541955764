import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { EntityTypes } from '@app/models/entity-type.model';
import { OrderTrip } from '@app/models/order-trip.model';
import { SizeType, UtilService } from '@app/services/util.service';
import { OrderService } from '@app/shared/services/order.service';

@Component({
 	standalone: false,
	selector: 'app-order-trips',
	templateUrl: './order-trips.component.html',
	styleUrls: ['./order-trips.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => OrderTripsComponent),
			multi: true
		}
	],
})
export class OrderTripsComponent implements OnInit, OnChanges {

	/*
	* Getter & Setter for order trips 
	*/
	_trips: OrderTrip[] = [
		{
			pickupOrderStop: {
				attachments: []
			},
			deliveryOrderStop: {
				attachments: []
			}
		}
	];
	get trips(): OrderTrip[] {
		return this._trips;
	}
	@Input() set trips(trips: OrderTrip[]) {
		this._trips = trips;
	}

	EntityTypes = EntityTypes;

	tripStatusTypes = new Map([
		['Open', { class: 'is-opened' }],
		['Assigned', { class: 'is-assigned' }],
		['InTransit', { class: 'is-in-transit' }],
		['Delivered', { class: 'is-delivered' }],
	]);

	constructor(
		public util: UtilService,
		private orderService: OrderService,
	) { }

	get stopDetails() {
		return this.trips.reduce((acc, trip) => {
			if (!trip || !trip.orderItem) return acc;

			const orderItem = trip.orderItem;
			const matchingStop = orderItem.orderItemStops?.find(stop => stop.orderItemId === orderItem.orderItemId);
			const item = matchingStop?.orderItem || orderItem;
			const formattedWeight = item?.wight ? `${(trip.quantity * item?.wight) || 0} ${item?.formattedUnitOfMeasure || 'kg'}` : '';
			const formattedMeasurements = item?.formatedMeasurements || this.util?.sizeTypes.get(item.sizeType as any)?.text;
			
			if (item?.orderItemId) {
				acc[item.orderItemId] = {
					qtyWithItemType: `${trip.quantity} ${item.itemTypeQty || ''}`,
					sizeLabel: [formattedMeasurements, , formattedWeight].filter(Boolean).join(' | ')
				};
			}
			return acc;
		}, {} as Record<string, { qtyWithItemType: string; sizeLabel: string }>);
	}

	ngOnInit(): void { }

	ngOnChanges(changes: SimpleChanges): void { }
}
