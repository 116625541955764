import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from '@app/shared/services/auth/auth.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';

@Component({
    standalone: false,
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
    registerForm: FormGroup;
    showPassword = false;
    errorMessage: any = null;
    return = '';

    constructor(
        private api: ApiService,
        private route: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder,
        private util: UtilService,
        private authService: AuthService,
    ) {
        this.route.queryParams.subscribe(params => this.return = params.return ?? '/');
    }

    ngOnInit() {
        this.registerForm = this.formBuilder.group({
            firstName: ['', [Validators.required, this.noWhitespaceValidator]],
            lastName: ['', [Validators.required, this.noWhitespaceValidator]],
            termAndCondition: [false, [Validators.required, this.termAndConditionValidator]],
            companyName: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.compose(
                [
                    Validators.minLength(8),
                    Validators.required,
                    //CustomValidator.strongPassword(),
                ]
            )],
        });
    }

    toggleShowPassword() {
        this.showPassword = !this.showPassword;
    }

    public termAndConditionValidator(control: AbstractControl): ValidationErrors | null {
        const isValid = control.value === true;
        return isValid ? null : { invalid: true };
    }


    public noWhitespaceValidator(control: FormControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    }

    onRegister() {
        this.errorMessage = null;
        if (this.registerForm.valid) {
            this.authService.register(this.registerForm.value).then(success => {
                this.router.navigate([this.return]);
                this.util.mainLoaderOff();
            }, error => {
                this.errorMessage = error?.error?.messages[0]
            });
        }
    }
}
