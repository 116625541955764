import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HelperService } from '@app/shared/services/helper.service';
import { TruckService } from '@app/shared/services/truck.service';

@Component({
  standalone: false,
  selector: 'app-exclude-expenses',
  templateUrl: './exclude-expenses.component.html',
  styleUrls: ['./exclude-expenses.component.scss'],
})
export class ExcludeExpensesComponent implements OnInit {
  @Input() truck: any;
  errorMessage: any = null;
  excludeExpensesForm: FormGroup;
  isSubmitting: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private readonly activeModal: NgbActiveModal,
    private readonly truckService: TruckService,
    private readonly helperService: HelperService
  ) {
    this.excludeExpensesForm = this.formBuilder.group({
      excludeExpensesStartDate: [null],
      excludeExpensesEndDate: [null],
    });
  }

  ngOnInit(): void {
    // Initialize form with existing dates
    this.excludeExpensesForm.patchValue({
      excludeExpensesStartDate: this.truck.excludeExpensesStartDate
        ? this.convertDateStringToNgbDate(this.truck.excludeExpensesStartDate)
        : null,
      excludeExpensesEndDate: this.truck.excludeExpensesEndDate
        ? this.convertDateStringToNgbDate(this.truck.excludeExpensesEndDate)
        : null,
    });
  }

  convertDateStringToNgbDate(dateString: string): NgbDateStruct {
    const date = new Date(dateString);
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
  }

  convertNgbDateToString(ngbDate: NgbDateStruct): string {
    return `${ngbDate.year}-${('0' + ngbDate.month).slice(-2)}-${('0' + ngbDate.day).slice(-2)}`;
  }

  close() {
    this.activeModal.close();
  }

  onSubmit() {
    this.errorMessage = null;
    this.isSubmitting = true;

    if (this.excludeExpensesForm.valid) {
      const startDate: NgbDateStruct | null = this.excludeExpensesForm.value.excludeExpensesStartDate;
      const endDate: NgbDateStruct | null = this.excludeExpensesForm.value.excludeExpensesEndDate;

      const request = {
        truckId: this.truck.truckId,
        excludeExpensesStartDate: startDate ? this.convertNgbDateToString(startDate) : null,
        excludeExpensesEndDate: endDate ? this.convertNgbDateToString(endDate) : null,
      };

      this.truckService
        .updateExcludeExpenses(request)
        .then((res) => {
          this.helperService.successMessage('Exclude expenses dates have been updated successfully');
          this.activeModal.close(true);
        })
        .catch((error) => {
          this.errorMessage = this.helperService.errorMessage(error, 'Something went wrong, please try again.');
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    }
  }
}
