// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .icon-star,
:host .icon-star-fill {
  font-size: 16px;
}
:host .one-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/customers/customer-details/contacts/contacts.component.scss"],"names":[],"mappings":"AAEC;;EAEC,eAAA;AADF;AAGC;EACC,gBAAA;EACA,uBAAA;EACA,iBAAA;AADF","sourcesContent":["@use \"common/var\" as *;\n:host {\n\t.icon-star,\n\t.icon-star-fill {\n\t\tfont-size: 16px;\n\t}\n\t.one-lines {\n\t\toverflow: hidden;\n\t\ttext-overflow: ellipsis;\n\t\ttext-wrap: nowrap;\n\t  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
