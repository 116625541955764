// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}
:host .overwritten {
  text-decoration: line-through;
}
:host .rate-list-item {
  border-radius: 4px;
  border: 1px solid #e5e7eb;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
:host .rate-list-item .row {
  align-items: center;
}
:host .red-point {
  position: absolute;
  bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/auxiliary/cost-lines/cost-lines.component.scss"],"names":[],"mappings":"AACA;EACI,cAAA;AAAJ;AACI;EACI,6BAAA;AACR;AAEI;EACI,kBAAA;EACA,yBAAA;EACA,mBAAA;EACA,iBAAA;EACA,oBAAA;AAAR;AACQ;EACI,mBAAA;AACZ;AAGI;EACI,kBAAA;EACA,SAAA;AADR","sourcesContent":["@use \"common/var\" as *;\n:host {\n    display: block;\n    .overwritten {\n        text-decoration: line-through;\n    }\n\n    .rate-list-item {\n        border-radius: 4px;\n        border: 1px solid $divider;\n        margin-bottom: 10px;\n        padding-top: 10px;\n        padding-bottom: 10px;\n        .row {\n            align-items: center;\n        }\n    }\n\n    .red-point {\n        position: absolute;\n        bottom: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
