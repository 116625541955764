import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrdersRoutingModule } from './orders-routing.module';
import { OrderListComponent } from './order-list/order-list.component';
import { SharedModule } from '@app/shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { NgChartsModule } from 'ng2-charts';
import { AttachmentsModule } from '@app/@shared/attachments/attachments.module';
import { NgxSelectModule } from '@app/@shared/ngx-select';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DatatableModule } from '@app/@shared/datatable';
import { NgbDropdownModule, NgbModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { DocumentViewerModule } from '@app/@shared/document-viewer/document-viewer.module';
import { DialogsModule } from '@app/@shared/dialogs/dialogs.module';
import { RouteMapModule } from '@app/@shared/route-map/route-map.module';
import { CustomerSelectModule } from '@app/@shared/customer-select/customer-select.module';
import { OrderSeeDetailsComponent } from './order-see-details/order-see-details.component';
import { PortStatusModule } from '@app/@shared/port-status/port-status.module';
import { environment } from '@environments/environment';
import { AgmCoreModule, GoogleMapsScriptProtocol } from '@agm/core';
import { DriverSelectModule } from '@app/@shared/driver-select/driver-select.module';
import { AddressSelectModule } from '@app/@shared/address-select/address-select.module';
import { CalenderDialogModule } from '@app/@shared/calender-dialog/calender-dialog.module';
import { OrderTripsModule } from '@app/@shared/order-trips/order-trips.module';
import { OrderStatusSelectModule } from '@app/@shared/order-status-select/order-status-select.module';
import { DateRangePickerModule } from '@app/@shared/components/form/date-range-picker';
import { DirectivesModule } from '@app/@shared/directives/directives.module';
import { CopyDetailsToDriverModule } from '@app/@shared/copy-details-to-driver/copy-details-to-driver.module';
import { ListTabsModule } from '@app/@shared/list-tabs/list-tabs.module';
import { OrderLayoutComponent } from './order-layout/order-layout.component';
import { AddEditOrderModule } from './add-edit-order/add-edit-order.module';
import { DropDownSelectModule } from '@app/@shared/drop-down-select/drop-down-select.module';
import { SelectCustomerNameComponent } from './components/select-customer-name/select-customer-name.component';
import { InvoiceActionsDialogComponent } from './order-list/invoice-actions-dialog/invoice-actions-dialog.component';
import { OrderDeleteDialogModule } from './components/order-delete-dialog/order-delete-dialog.module';
import { FilesViewerModalModule } from '@app/@shared/files-viewer-modal/files-viewer-modal.module';

@NgModule({
	declarations: [
		OrderListComponent,
		OrderSeeDetailsComponent,
		OrderLayoutComponent,
		InvoiceActionsDialogComponent,
		SelectCustomerNameComponent
	],
	imports: [
		CommonModule,
		OrdersRoutingModule,
		SharedModule,
		NgSelectModule,
		AccordionModule,
		NgChartsModule,
		AttachmentsModule,
		NgxSelectModule,
		DragDropModule,
		DatatableModule,
		NgbModule,
		NgbDropdownModule,
		DocumentViewerModule,
		DialogsModule,
		CustomerSelectModule,
		AddressSelectModule,
		RouteMapModule,
		NgbProgressbarModule,
		CopyDetailsToDriverModule,
		PortStatusModule,
		DriverSelectModule,
		AgmCoreModule.forRoot({
			apiKey: environment.googleKey,
			libraries: ['map'],
			language: 'en',
			protocol: GoogleMapsScriptProtocol.HTTPS,
		}),
		CalenderDialogModule,
		OrderTripsModule,
		OrderStatusSelectModule,
		DateRangePickerModule,
		DirectivesModule,
		ListTabsModule,
		AddEditOrderModule,
		DropDownSelectModule,
		OrderDeleteDialogModule,
		FilesViewerModalModule,
	]
})
export class OrdersModule { }
