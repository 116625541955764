// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .route-user-avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-bottom: 5px;
  margin-right: 10px;
  position: relative;
}
:host .route-user-avatar img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
:host .route-user-avatar .user-avatar-status {
  background-color: #28be7e;
  border-radius: 50%;
  display: block;
  height: 10px;
  position: absolute;
  width: 10px;
  bottom: 1px;
  right: 1px;
}
:host .assign-transfer-table {
  border: 1px solid #00bad7;
  border-radius: 4px;
}
:host .assign-transfer-table .item {
  border-top: 1px solid #e5e7eb;
}
:host .assign-transfer-inner .route {
  border: 1px solid #e5e7eb;
  border-radius: 4px;
}
:host .drop-off-fields ::ng-deep .date-picker, :host .drop-off-fields ::ng-deep .time-picker,
:host .pick-up-fields ::ng-deep .date-picker,
:host .pick-up-fields ::ng-deep .time-picker {
  width: 50% !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/orders/order-list/invoice-actions-dialog/invoice-actions-dialog.component.scss"],"names":[],"mappings":"AAEI;EACI,YAAA;EACA,WAAA;EACA,kBAAA;EACA,kBAAA;EACA,kBAAA;EACA,kBAAA;AADR;AAGQ;EACI,YAAA;EACA,iBAAA;EACA,WAAA;AADZ;AAIQ;EACI,yBAAA;EACA,kBAAA;EACA,cAAA;EACA,YAAA;EACA,kBAAA;EACA,WAAA;EACA,WAAA;EACA,UAAA;AAFZ;AAKI;EACI,yBAAA;EACA,kBAAA;AAHR;AAIQ;EACI,6BAAA;AAFZ;AAMQ;EACI,yBAAA;EACA,kBAAA;AAJZ;AAUY;;;EACI,qBAAA;AANhB","sourcesContent":["@use \"common/var\" as *;\n:host{\n    .route-user-avatar {\n        height: 40px;\n        width: 40px;\n        border-radius: 50%;\n        margin-bottom: 5px;\n        margin-right: 10px;\n        position: relative;\n    \n        img {\n            height: 100%;\n            object-fit: cover;\n            width: 100%;\n        }\n    \n        .user-avatar-status {\n            background-color: #28be7e;\n            border-radius: 50%;\n            display: block;\n            height: 10px;\n            position: absolute;\n            width: 10px;\n            bottom: 1px;\n            right: 1px;\n        }\n    }\n    .assign-transfer-table {\n        border: 1px solid $primary;\n        border-radius: 4px;\n        .item {\n            border-top: 1px solid $divider;\n        }\n    }\n    .assign-transfer-inner {\n        .route {\n            border: 1px solid $divider;\n            border-radius: 4px;\n        }\n    }\n    .drop-off-fields,\n    .pick-up-fields {\n        ::ng-deep{\n            .date-picker,.time-picker{\n                width: 50% !important;\n            }\n        }\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
