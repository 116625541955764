import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  standalone: false,
  selector: 'app-expenses-date-selector',
  templateUrl: './expenses-date-selector.component.html',
  styleUrls: ['./expenses-date-selector.component.scss']
})
export class ExpensesDateSelectorComponent implements OnInit {
  @Input() isLoading: boolean = false;
  @Output() dateChange = new EventEmitter<Date>();

  months = [
    { value: 0, name: 'January' },
    { value: 1, name: 'February' },
    { value: 2, name: 'March' },
    { value: 3, name: 'April' },
    { value: 4, name: 'May' },
    { value: 5, name: 'June' },
    { value: 6, name: 'July' },
    { value: 7, name: 'August' },
    { value: 8, name: 'September' },
    { value: 9, name: 'October' },
    { value: 10, name: 'November' },
    { value: 11, name: 'December' }
  ];
  years: number[] = [];
  selectedMonth: number;
  selectedYear: number;
  date: Date;

  constructor() {
    const now = new Date();
    this.selectedMonth = now.getMonth();
    this.selectedYear = now.getFullYear();
    this.date = new Date(this.selectedYear, this.selectedMonth, 10);

    const startYear = 2010;
    const endYear = now.getFullYear();
    for (let year = startYear; year <= endYear; year++) {
      this.years.push(year);
    }
  }

  ngOnInit(): void {
    // this.emitDateChange();
  }

  onDateChange() {
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();

    if (this.selectedYear === currentYear && this.selectedMonth > currentMonth) {
      this.selectedMonth = currentMonth;
    }

    this.date = new Date(this.selectedYear, this.selectedMonth, 10);
    this.emitDateChange();
  }

  changeMonth(offset: number) {
    const newDate = new Date(this.selectedYear, this.selectedMonth + offset, 10);
    this.selectedMonth = newDate.getMonth();
    this.selectedYear = newDate.getFullYear();
    this.onDateChange();
  }

  isFutureMonth(month: number): boolean {
    const now = new Date();
    return this.selectedYear === now.getFullYear() && month > now.getMonth();
  }

  isNextMonthInFuture(): boolean {
    const now = new Date();
    const nextMonth = new Date(this.selectedYear, this.selectedMonth + 1, 1);
    return nextMonth > now || this.isLoading;
  }

  isPreviousMonthDisabled(): boolean {
    return this.isLoading;
  }

  private emitDateChange() {
    this.dateChange.emit(this.date);
  }
}