import { EmailType, SentEmail } from "@app/models/sent-email.model";
import { camelCase, chain, first, omit, startCase } from "lodash";

/**
 * Removed two way data binding for ngModel property
 * 
 * @param json 
 * @returns 
 */
export function removeBinding(json: object) {
	return JSON.parse(JSON.stringify(json));
}

/**
 *  Convert a camelCase string to title case. 
 * 
 * @param value
 * @returns 
 */
export function titleCase(value: string) {
	return startCase(camelCase(value));
}

/**
 * Check value not empty.
 * @param item
 * @returns {boolean}
 */
export function isNotEmpty(item: any): boolean {
	return !isEmpty(item);
}

export function convertPoundsToKg(pounds: number): number {
	const kgPerPound = 0.453592;
	return pounds * kgPerPound;
}

/**
 * Check value empty.
 * @param item
 * @returns {boolean}
 */
export function isEmpty(item: any) {
	if (item instanceof Array) {
		item = item.filter((val) => !isEmpty(val));
		return item.length === 0;
	} else if (item && typeof item === 'object') {
		for (var key in item) {
			if (item[key] === null || item[key] === undefined || item[key] === '') {
				delete item[key];
			}
		}
		return Object.keys(item).length === 0;
	} else {
		return !item || (item + '').toLocaleLowerCase() === 'null' || (item + '').toLocaleLowerCase() === 'undefined';
	}
}

export function splitPhoneNumberAndExtension(mergedPhoneExtension: string) {
	const xSymbolIndex = mergedPhoneExtension?.indexOf(' x ');
	let phoneNumber = mergedPhoneExtension
	let phoneExtension = ''
	if (xSymbolIndex !== -1) {
		 phoneNumber = mergedPhoneExtension?.slice(0, xSymbolIndex).trim();
		 phoneExtension = mergedPhoneExtension?.slice(xSymbolIndex + 3).trim() || '';
	} 
	return { phone: phoneNumber, extension: phoneExtension };
}

export function parsePhoneNumberAndExtension(contact: any, isLocation: boolean = false) {
	const phone = contact?.phone || contact?.locationContactNumber
	const extension = !isLocation ? contact?.phoneExtension : contact?.locationContactNumberExtension;
	const xSymbolIndex = phone?.indexOf(' x ');

	if (xSymbolIndex !== -1) {
		const phoneNumber = phone?.slice(0, xSymbolIndex).trim();
		const phoneExtension = extension || phone?.slice(xSymbolIndex + 3).trim();

		if(!isLocation){
			contact.phoneNumber = phoneNumber;
			contact.phoneExtension = phoneExtension;
		}
		else{
			contact.locationContactNumber = phoneNumber;
			contact.locationContactNumberExtension = phoneExtension;
		}
	} else {
		if (!isLocation){
			contact.phoneNumber = phone;
			contact.phoneExtension =  extension || ''; // If no internal number is found
		}
		else {
			contact.locationContactNumber = phone;
			contact.locationContactNumberExtension =  extension || ''; // If no internal number is found
		}
	}
	return contact;
}

export function reverseAmount(amount: number): number {
	if (amount >= 0) {
		return -Math.abs(amount); // Converts positive to negative
	} else {
		return Math.abs(amount); // Converts negative to positive
	}
}

export function formatPhoneNumberAndExtension(contact: any, column = 'phone') {
	if (column == "locationContactNumber"){
		if (contact?.locationContactNumber && contact?.locationContactNumberExtension) {
			contact.locationContactNumber = `${contact?.locationContactNumber} x ${contact?.locationContactNumberExtension}`
		} else {
			contact.locationContactNumber = contact?.locationContactNumber || contact?.locationContactNumberExtension
		}
	} else if (column !== "phone") {
		if (contact?.phoneNumber && contact?.phoneExtension) {
			contact.locationContactNumber = `${contact?.phoneNumber} x ${contact?.phoneExtension}`
		} else {
			contact.locationContactNumber = contact?.phoneNumber || contact?.phoneExtension
		}
	} else {
		if (contact?.phoneNumber && contact?.phoneExtension) {
			contact.phone = `${contact?.phoneNumber} x ${contact?.phoneExtension}`
		} else {
			contact.phone = contact?.phoneNumber || contact?.phoneExtension
		}
	}
	return omit(contact, ['phoneNumber', 'phoneExtension']);
}

export function filterAndSortSentEmailsByEmailType(sentEmails: SentEmail[], emailType: EmailType) {
	const latestTimeSec = ((Date.parse(first(sentEmails)?.createdTime || '')) / 1000) - 10;
	return chain(sentEmails)
	  .filter((email: SentEmail) => email.emailType === emailType)
	  .map((email: SentEmail) => ({
		...email,
		...!email?.isSelected && {isSelected: ((Date.parse(email.createdTime) / 1000) > latestTimeSec)},
	  }))
	  .orderBy(['createdTime'], ['desc'])
	  .uniqBy('to')
	  .value() || [];
}

