import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { WaitingTimeInputComponent } from './waiting-time-input.component';
import { DirectivesModule } from '../directives/directives.module';

@NgModule({
  declarations: [
    WaitingTimeInputComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule
  ],
  exports: [
    WaitingTimeInputComponent
  ]
})
export class WaitingTimeInputModule { }
