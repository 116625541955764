import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  standalone: false,
  selector: 'app-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxGroupComponent),
      multi: true
    }
  ],
 // encapsulation: ViewEncapsulation.Emulated
})
export class CheckboxGroupComponent implements OnInit {
  disabled: boolean;
  onChange: any = () => { };
  onTouched: any = () => { };


  private _options: any[];

  @Input()
  public get options(): any[] {
    return this._options;
  }
  public set options(value: any[]) {
    if (typeof value[0] === 'string') {
      this._options = value.map((item) => ({ [this.valueKey]: item, [this.labelKey]: item }))
    } else {
      this._options = value;
    }
  }
  @Input() valueKey = 'value';
  @Input() labelKey = 'label';

  private _value: any = {};
  get value(): any {
    return this._value;
  }
  set value(updateValues: any) {
    this._value = updateValues;
    const valuesArray = []
    for (const key in updateValues) {
      if (Object.prototype.hasOwnProperty.call(updateValues, key)) {
        valuesArray.push(updateValues[key])
      }
    }
    this.onChange(valuesArray);
    this.onTouched(valuesArray);
  }

  constructor() { }

  ngOnInit(): void {
  }

  writeValue(value: any[]) {
    const obj = {};
    if (value) {
      for (const ids of value) {
        obj[ids] = true
      }
    }
    this._value = obj
  }

  registerOnChange(fn: (rating: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onCheckboxChange(event: any) {

  }

}
