import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@app/@shared/datatable';
import { Customer } from '@app/models/customer.model';
import { Order } from '@app/models/order.model';
import { HelperService } from '@app/shared/services/helper.service';
import { OrderService } from '@app/shared/services/order.service';
import { QuoteService } from '@app/shared/services/quote.service';

@Component({
  standalone: false,
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.sass']
})
export class OrdersComponent implements OnInit {

  @ViewChild("datatable") datatable: DatatableComponent;
  orders: Order[] = [];
  totalCount = 0;

  @Input() customer: Customer;

  constructor(
    private orderService: OrderService,
    private quoteService: QuoteService,
    private helperService: HelperService
  ) {

  }

  ngOnInit(): void {
  }

  getData = (request) => {
    request = {
      IsOrder: true,
      // OrderStatus: 'Order',
      SortBy: 'DEFAULT',
      SortDirection: 'Descending',
      Customers: [this.customer.customerId],
      ...request,
    }
    this.orderService.cancelPendingRequestOrderBasicDetails();
    return this.orderService.getOrderBasicDetails(request)
  }

  onDelete(order) {
    this.quoteService
        .delete(order?.orderId)
        .then(() => {
            this.datatable?.refresh();
            this.helperService.successMessage("Order Deleted Successfully");
        })
        .catch((error) => {
            this.helperService.errorMessage(error);
        });
}

}





