import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UtilService } from '@app/services/util.service';
import { Order } from '@models/order.model';
import { CostLine, CostLineDescriptions, CostType } from '@models/cost-line.model';


@Component({
    standalone: false,
    selector: 'app-cost-lines',
    templateUrl: './cost-lines.component.html',
    styleUrls: ['./cost-lines.component.scss']
})
export class CostLinesComponent implements OnChanges {
   
    @Input('quote') quote: Order;

    aggregatedLines: CostLine[] = [];

    CostLineDescription = CostLineDescriptions;

    colors = ['green', 'red', 'blue', 'purple', 'orange'];
    colorCodes = [
        '#2ccc71',
        '#cc0000',
        '#00caff',
        '#5a51de',
        '#f39c13',
    ];

    public doughnutOptions = {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 70,
        hover: { mode: null },
        plugins: { tooltip: { enabled: false} },
        elements: {
            arc: { borderWidth: 0 },
        },
    };

    constructor(
        public util: UtilService,
    ) {
    }

    getTotalCost() {
        return this.quote.costLines.filter(line => !line.isOverwritten).map(item => item.totalCost).reduce((prev, next) => prev + next);
    }

    getMarkupAmount() {
        return this.quote.markupType === 'Value' ? Number(this.quote.markupValue) : (this.getTotalCost() * (this.quote.markupValue / 100));
    }

    getMarkupPercent() {
        return this.quote.markupType === 'Percent'
            ? Number(this.quote.markupValue)
            : (this.getMarkupAmount() / this.getTotalCost()).toFixed(2);
    }

    getDiscountAmount() {
        return this.quote.discountType === 'Value'
            ? Number(this.quote.discountValue)
            : ((this.getTotalCost() + this.getMarkupAmount()) * (this.quote.discountValue / 100));
    }

    getDiscountPercent() {
        return this.quote.discountType === 'Percent'
            ? Number(this.quote.discountValue)
            : (this.getDiscountAmount() / (this.getTotalCost() + this.getMarkupAmount())).toFixed(2);
    }

    getTotalPrice() {
        return Number(this.getTotalCost()) + Number(this.getMarkupAmount()) - Number(this.getDiscountAmount());
    }

    getPercentCost() {
        const total = this.getTotalCost() + this.getMarkupAmount();
        const cost = this.getTotalCost();
        const per = cost / total;
        return per * 100;
    }

    getPercentMarkup() {
        const total = this.getTotalCost() + this.getMarkupAmount();
        const markup = this.getMarkupAmount();
        const per = markup / total;
        return per * 100;
    }

    getDiffAmount() {
        return this.getTotalPrice() - this.getTotalCost();
    }

    getDiffPercent() {
        return (this.getDiffAmount() / this.getTotalPrice()).toFixed(2);
    }

    getPointPercet() {
        const total = this.getTotalPrice();
        const fullBar = this.getTotalCost() + this.getMarkupAmount();
        const per = total / fullBar;
        return (per * 96).toFixed(0);
    }

    getCostBreakdown() {
        return {
            datasets:{
                data: this.aggregatedLines.map(cl => cl.totalCost),
                backgroundColor: this.aggregatedLines.map(cl => this.colorCodes[this.aggregatedLines.indexOf(cl) % this.colorCodes.length]) 
            }
        }
    }


    getAggregatedCostLines(): CostLine[] {
        if (this.quote?.costLines == null) {
            return [];
        }
        const types: Record<CostType, CostLine> = {} as Record<CostType, CostLine>;
        this.quote.costLines.forEach(cl => {
            if (types[cl.costType] == null) {
                types[cl.costType] = { ...cl };
                types[cl.costType].totalCost = this.quote?.costLines
                    .filter(x => x.costType === cl.costType)
                    .reduce((a, b) => a + b.totalCost, 0);
            }
        });
        return Object.values(types);
    }

    getAggregatedIndex(line: CostLine) {
        return this.aggregatedLines.findIndex(cl => cl.costType === line.costType);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.quote) {
            this.aggregatedLines = this.getAggregatedCostLines();
        }
    }
}
