import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserService } from '../user.service';
import { AppStorage, CookiesStorage } from '../storage/app-storage';
import { LoginInput } from '@app/models/auth';
import { User } from '@app/models/user.model';
import { CompanyService } from '../company.service';
import { RoutesService } from '../router.service';
import { AccountingService } from '../accounting.service';
import { OrderService } from '../order.service';
import { AppService } from '../app.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    dataStore: {
        token: string
        user: User
        isLoggedIn: boolean
        isInit: boolean
    };

    private _token: BehaviorSubject<string>;
    private _user: BehaviorSubject<User>;
    private _isLoggedIn: BehaviorSubject<boolean>;
    private channel: BroadcastChannel;

    $logout: Subject<any> = new Subject();
    registerSuccess: any = null;

    constructor(
        @Inject(AppStorage) private appStorage: Storage,
        @Inject(CookiesStorage) private cookiesStorage: Storage,
        private http: HttpClient,
        private router: Router,
        private userService: UserService,
        private companyService: CompanyService,
        private routesService: RoutesService,
        private orderService: OrderService,
        private accountingService: AccountingService,
        private appService: AppService,
    ) {
        this.dataStore = {
            token: null,
            user: null,
            isLoggedIn: false,
            isInit: false,
        };
        this._token = new BehaviorSubject(this.dataStore.token);
        this._user = new BehaviorSubject(this.dataStore.user);
        this._isLoggedIn = new BehaviorSubject(this.dataStore.isLoggedIn);

        // Initialize the BroadcastChannel
        this.channel = new BroadcastChannel('app-channel');

        // Subscribe to incoming messages (optional callback registration)
        this.channel.onmessage = (event) => {
            if (event.data === 'logout') {
                window.location.href = '/auth/login'; // Redirect to login page
            } else if (event.data === 'isLoggedIn') {
                window.location.href = '/'; // Redirect to dashboard page
            }
        };
    }

    public get $isLoggedIn(): Observable<any> {
        return this._isLoggedIn.asObservable();
    }

    public get isLoggedIn(): boolean {
        return this.dataStore.isLoggedIn;
    }

    public set isLoggedIn(value: boolean) {
        this.dataStore.isLoggedIn = value;
        this._isLoggedIn.next(value);
    }


    public get $token(): Observable<string> {
        return this._token.asObservable();
    }

    public get token(): string {
        return this.dataStore.token;
    }

    public set token(value: string) {
        this.dataStore.token = value;
        const keepMeSignedIn = this.appStorage.getItem('keepMeSignedIn')
        if (value) {
            this.cookiesStorage.setItem('token', this.dataStore.token)
            if (keepMeSignedIn === 'true') {
                this.appStorage.setItem('token', this.dataStore.token);
            }
        } else {
            this.appStorage.removeItem('token');
            this.cookiesStorage.removeItem('token');
            this.appStorage.removeItem('keepMeSignedIn');
        }

        this._token.next(this.dataStore.token);
    }

    public get user(): User {
        return this.dataStore.user;
    }

    public get $user(): Observable<User> {
        return this._user.asObservable();
    }

    public set user(value: User) {
        this.dataStore.user = value;
        this._user.next(this.dataStore.user);
    }



    login(request: LoginInput) {
        return this.http.post('Auth/Login', request).toPromise()
            .then(async (data: any) => {
                if (data) {
                    if (request?.keepMeSignedIn) {
                        this.appStorage.setItem('keepMeSignedIn', 'true')
                    }
                    else {
                        this.appStorage.removeItem('keepMeSignedIn')
                    }
                    this.removeLocalStorageData()

                    this.token = data.data;
                    await this.setUserCompany();
                    this.isLoggedIn = true;
                }
                return data;
            }).catch((error) => {
                this.isLoggedIn = false;
                this.token = null;
                this.user = null;
                throw error;
            });
    }

    register(request: any, login = true) {
        return this.http.post('Tenants', request)
            .toPromise()
            .then(async (data: any) => {
                if (data && login) {
                    this.removeLocalStorageData()
                    this.token = data.data;
                    await this.setUserCompany();
                    this.isLoggedIn = true;
                }
                return data;
            }).catch((error) => {
                if (login) {
                    this.isLoggedIn = false;
                    this.token = null;
                    this.user = null;
                }
                throw error;
            });
    }

    forgotPassword(request: any) {
        return this.http.post('Auth/ForgotPassword', request).toPromise();
    }

    setPassword(request: any) {
        return this.http.post('Auth/ResetPassword', request).toPromise();
    }

    changePassword(request: any) {
        return this.http.post('Auth/ChangePassword', request).toPromise();
    }

    adminSetPassword(request: any) {
        return this.http.post('Auth/AdminSetPassword', request).toPromise();
    }


    logout() {
        return new Promise((resolve) => {
            this.appStorage.removeItem('token');
            this.appStorage.removeItem('keepMeSignedIn');
            this.cookiesStorage.removeItem('token');
            this.isLoggedIn = false;
            this.token = null;
            this.user = null;
            this.$logout.next(true);
            this.channel.postMessage('logout');
            this.router.navigate(['/auth/login']);
            resolve(true);
        });
    }

    setAuth(token) {
        this.token = token;
        if (token) {
            this.isLoggedIn = true;
            return this.userService.getUserData().then((res: any) => { this.user = res; });
        }
    }

    async setUserCompany() {
        this.user = await this.userService.getUserData();
        if (this.user?.company) {
            this.companyService.selectedCompany = { companyId: this.user?.company?.companyId, companyName: this.user?.company?.companyName, maxRouteAddresses: this.user?.company?.maxRouteAddresses }
        }
    }

    removeLocalStorageData() {
        this.appService.resetState()
        this.companyService.resetState()
        this.routesService.resetState()
        this.orderService.resetState()
        this.accountingService.resetState()
        localStorage.removeItem('invoice.filters');
        localStorage.removeItem('list-tabs');
        this.channel.postMessage('isLoggedIn');
    }

    async init() {
        try {
            this.dataStore.isInit = true;
            let token = this.appStorage.getItem('token');
            if (!token) {
                token = this.cookiesStorage.getItem('token');
            }
            await this.setAuth(token);
            await this.setUserCompany();
        } catch (error) {
            this.isLoggedIn = false;
        }
    }
}
