import { Component, HostListener } from '@angular/core';
import { NgbDropdownConfig, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { LoaderService } from './components/global/spinner/services/loader.service';
import { ContextMenuService } from '@perfectmemory/ngx-contextmenu';
import { AutoCloseListTabsService } from './shared/services/auto-close-list-tabs.service';

@Component({
  standalone: false,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  
  title = 'Rush Hour Company Portal';

  mainLoaderStatus$: BehaviorSubject<boolean>;

  @HostListener('wheel', ['$event'])
    onWheel(event: WheelEvent) {
      this.contextMenuService.closeAll();
  }

  constructor(
    private _loaderService: LoaderService,
    private config: NgbModalConfig,
    private ngbDropdownConfig: NgbDropdownConfig,
    private contextMenuService: ContextMenuService<any>,
    private autoCloseListTabsService: AutoCloseListTabsService,
    ) {
    this.mainLoaderStatus$ = this._loaderService.mainLoaderOn$;

    ngbDropdownConfig.container = 'body';
    config.centered = true;
  }

  ngOnInit(): void {
    
  }
}
