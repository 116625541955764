export interface Expense {
  type?: 'Trucks' | 'Drivers' | 'Office' | 'Misc'
  expenseId?: number;
  name?: string;
  total?: string;
  insurance?: string;
  gas?: string;
  dueAmount?: number;
}

export const ExportTypes = ['Trucks', 'Drivers', 'Office', 'Misc']

export interface NewExpense {
  expenseId?: number;
  type?: ExpenseType;
  subtype?: ExpenseSubtype;
  amount?: number;
  truckId?: number;
  date?: Date;
  name?: string;
  isEditing ?: boolean;

  validationError?: string;
  isNew?: boolean;
  isDefault?: boolean;
}

export enum ExpenseType {
  Trucks = 0,
  Drivers = 1,
  Office = 2,
  Misc = 3,
}

export enum ExpenseSubtype {
  TruckGas = 0,
  TruckInsurance = 1,
  TruckMaintenance = 2,
  TruckPayment = 3,
  TruckTrailer = 4,
  TruckLicense = 5,
  TruckTool = 6,
  TruckOther = 7,
  TruckMiles = 8,
  OdometerStart = 9,
  OdometerEnd = 10,

  // Office-related subtypes starting from 100
  OfficeGPS = 100,
  OfficeInternet = 101,
  OfficeOther = 102,
}

export enum Status {
  Active = 0,
  Inactive = 1,
  Deleted = 2,
}