// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .trip-list {
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  margin: 10px 0;
  align-items: center;
  padding: 20px;
  display: flex;
}
:host .trip-list .pickup-item,
:host .trip-list .delivery-item,
:host .trip-list .trip-status {
  width: 33.33%;
  padding: 0 12px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}
:host .trip-list .pickup-item {
  border-right: 1px solid #e5e7eb;
}
:host .trip-list .trip-status {
  border-left: 1px solid #e5e7eb;
}
:host .trip-list .delivery-chain {
  margin: 0 20px;
}
:host .trip-list .common-btn {
  width: 56px;
}
:host .trip-list .common-btn ::ng-deep .upload-label {
  font-size: 12px;
}
:host .trip-list .icon-small-truck {
  font-size: 46px;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/order-trips/order-trips.component.scss"],"names":[],"mappings":"AAGI;EACI,yBAAA;EACA,kBAAA;EACA,cAAA;EACA,mBAAA;EACA,aAAA;EACA,aAAA;AAFR;AAIQ;;;EAGI,aAAA;EACA,eAAA;EACA,oBAAA;EACA,8BAAA;EACA,mBAAA;AAFZ;AAKQ;EACI,+BAAA;AAHZ;AAKQ;EACI,8BAAA;AAHZ;AAMQ;EACI,cAAA;AAJZ;AAOQ;EACI,WAAA;AALZ;AAOgB;EACI,eAAA;AALpB;AASQ;EACI,eAAA;AAPZ","sourcesContent":["@use \"common/var\" as *;\n\n:host {\n    .trip-list {\n        border: 1px solid $divider;\n        border-radius: 4px;\n        margin: 10px 0;\n        align-items: center;\n        padding: 20px;\n        display: flex;\n\n        .pickup-item,\n        .delivery-item,\n        .trip-status {\n            width: 33.33%;\n            padding: 0 12px;\n            display: inline-flex;\n            justify-content: space-between;\n            align-items: center;\n        }\n\n        .pickup-item {\n            border-right: 1px solid $divider;\n        }\n        .trip-status {\n            border-left: 1px solid $divider;\n        }\n\n        .delivery-chain {\n            margin: 0 20px;\n        }\n\n        .common-btn {\n            width: 56px;\n            ::ng-deep {\n                .upload-label {\n                    font-size: 12px;\n                }\n            }\n        }\n        .icon-small-truck {\n            font-size: 46px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
