import moment from "moment"

import { ShipmentType } from '@models/shipment-type.model';
import { OrderTrip } from '@models/order-trip.model';
import { RouteItem } from '@models/route-item.model';
import LatLngLiteral = google.maps.LatLngLiteral;
import { OrderItem } from '@models/order-item.model';

export const DateFormat = 'MM-DD-Y';
export const TimeFormat = 'h:mm A';
export const DateTimeFormat = 'MM-DD-Y h:mm A';

export const ShipmentTypeComparer =
    (a: ShipmentType, b: ShipmentType) =>
        (a == null && b == null) || a?.shipmentTypeId === b?.shipmentTypeId;

export const round = (num, prec = 0) => {
    const roundedNum = Math.round((num + Number.EPSILON) * (10 ** prec)) / (10 ** prec);
    return Math.abs(roundedNum) < 0.000001 ? 0 : roundedNum;
};

export const getRouteItemWeight = (trip: OrderTrip | RouteItem | OrderItem): number => {
    const weight = (trip as OrderTrip).orderItem?.wight ?? (trip as RouteItem).orderItem?.wight ?? (trip as OrderItem).wight;
    const totalQty = (trip as OrderTrip).orderItem?.totalQuantity ??
        (trip as RouteItem).orderItem?.totalQuantity ?? (trip as OrderItem).totalQuantity;
    const qty = (trip as OrderTrip).quantity ?? (trip as RouteItem).quantity ?? (trip as OrderItem).pickQuantity;
    if (weight != null && qty != null && qty > 0 && totalQty != null && totalQty > 0 && weight > 0) {
        return round(weight / totalQty * qty, 2);
    } else {
        return weight;
    }
}

export function includesLC(a: string, b: string): boolean {
    if (a == null) {
        return false;
    }
    return a.toLocaleLowerCase().trim().includes(b);
}

export function formatDate(dateStr: string): string {
    return moment.utc(dateStr).local().format(DateFormat);
}

export function formatDateTime(dateStr: string): string {
    return moment.utc(dateStr).local().format(DateTimeFormat);
}

export function haversineDistance(p1: LatLngLiteral, p2: LatLngLiteral) {
    const R = 3958.8; // Radius of the Earth in miles
    const rlat1 = p1.lat * (Math.PI / 180); // Convert degrees to radians
    const rlat2 = p2.lat * (Math.PI / 180); // Convert degrees to radians
    const difflat = rlat2 - rlat1; // Radian difference (latitudes)
    const difflon = (p2.lng - p1.lng) * (Math.PI / 180); // Radian difference (longitudes)

    return 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) +
        Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));
}
