// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host #dropzone {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  min-height: unset !important;
}
:host ngx-dropzone-preview {
  height: unset !important;
  min-height: unset !important;
  margin: 2px !important;
}
:host ngx-dropzone-label {
  overflow-wrap: anywhere !important;
  margin-bottom: 7px !important;
}
:host .file-preview {
  height: 140px;
  width: 140px;
  object-fit: cover;
}
:host .attachment-preview ::ng-deep .image {
  height: 140px;
  width: 140px;
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/auxiliary/files-viewer/files-viewer.component.scss"],"names":[],"mappings":"AACI;EACI,WAAA;EACA,eAAA;EACA,kBAAA;EACA,4BAAA;AAAR;AAGI;EACI,wBAAA;EACA,4BAAA;EACA,sBAAA;AADR;AAII;EACI,kCAAA;EACA,6BAAA;AAFR;AAKI;EACI,aAAA;EACA,YAAA;EACA,iBAAA;AAHR;AAQY;EACI,aAAA;EACA,YAAA;EACA,iBAAA;AANhB","sourcesContent":[":host {\n    #dropzone {\n        width: 100%;\n        margin-top: 5px;\n        margin-bottom: 5px;\n        min-height: unset !important;\n    }\n\n    ngx-dropzone-preview {\n        height: unset !important;\n        min-height: unset !important;\n        margin: 2px !important;\n    }\n\n    ngx-dropzone-label {\n        overflow-wrap: anywhere !important;\n        margin-bottom: 7px !important;\n    }\n\n    .file-preview {\n        height: 140px;\n        width: 140px;\n        object-fit: cover;\n    }\n\n    .attachment-preview {\n        ::ng-deep {\n            .image {\n                height: 140px;\n                width: 140px;\n                object-fit: cover;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
