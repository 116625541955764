import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '@app/models/user.model';
import { map } from 'lodash';
import { SettingService } from '@app/shared/services/setting.service';
import { HelperService } from '@app/shared/services/helper.service';
import { stateOptions } from '@app/data/state';
import { patterns } from '@app/@shared/regex/regex-patterns.const';
import { countryOptions } from '@app/data/countries';
import { array, object, string } from 'yup';
import { removeBinding } from '@app/@shared/utils';
import { AuthService } from '@app/shared/services/auth/auth.service';
import { UserService } from '@app/shared/services/user.service';

@Component({
  	standalone: false,
	selector: 'app-user-details',
	templateUrl: './user-details.component.html',
	styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {

	defaultUserRole = {
		role: null,
	}
	states = stateOptions;
	countries = countryOptions;

	userRoles = [
		{
			role: null
		}
	]
	roles = ['COMPANY_ADMIN', 'COMPANY_DISPATCHER', 'COMPANY_SALES', 'Accounting', 'COMPANY_BILLING', 'Other'];
	loading: boolean;

	validationSchema = object().shape({
		userRoles: array()
			.of(
				object().shape({
					role: string().label('role').nullable().required(),
				})
			),
		firstName: string().nullable().required(),
		middleName: string().nullable(),
		lastName: string().nullable().required(),
		email: string().label('Email').nullable().email().required(),
		cellPhone: string().nullable().matches(patterns.phone, { excludeEmptyString: true, message: 'Company phone must be a number' }),
		phone: string().nullable().label('home phone').matches(patterns.phone, { excludeEmptyString: true, message: 'Company phone must be a number' }),
		addressLine1: string().label('address').nullable(),
		city: string().nullable(),
		state: string().nullable(),
		country: string().nullable(),
		zip: string().label('Zip Code').nullable().max(6),
	});

	/*
	* Getter & Setter for User
	*/
	private _user: User;
	get user(): User {
		return this._user;
	}
	@Input() set user(value: User) {
		this._user = removeBinding(value);
		this.userRoles = removeBinding(value)
		?.roles?.filter((role) => role !== "GLOBAL_ADMIN")
		?.map((role) => ({ role }));
	}

	constructor(
		public activeModal: NgbActiveModal,
		private settingService: SettingService,
		private helperService: HelperService,
		private authService: AuthService,
		private userService: UserService,
	) {

	}

	ngOnInit() {
	}

	isRoleSelected(role: string, currentIndex: number): boolean {
		return this.userRoles?.some(
			(item, idx) => idx !== currentIndex && item.role === role
		);
	}

	close() {
		this.activeModal.close();
	}

	submit(f) {

		if (f.isValid) {
			this.loading = true;
			this.user.roles = map(this.userRoles, 'role');
			if (this.user?.id) {
				this.settingService.updateUser(this.user?.id, this.user).then((res) => {
					if (this.user?.id === this.authService.user.id) {
						this.userService.getUserData().then((updatedUser) => {
							this.authService.user = {
								...this.authService.user,
								...updatedUser
							}
						})
					}
					this.loading = false;
					this.activeModal.close(true);
					this.helperService.successMessage("User details updated successfully");
				}).catch(({ error }) => {
					this.loading = false;
					if (error?.title) {
						this.helperService.errorMessage("Please select role.");
					} else {
						this.helperService.errorMessage(error);
					}
				})
			} else {
				this.settingService.createUser(this.user).then((res) => {
					this.loading = false;
					this.activeModal.close(true);
					this.helperService.successMessage("User saved successfully");
				}).catch(({ error }) => {
					this.loading = false;
					if (error?.title) {
						this.helperService.errorMessage("Please select role.");
					} else {
						this.helperService.errorMessage(error);
					}
				})
			}
		}
	}

}
