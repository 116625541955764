import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  standalone: false,
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RatingComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RatingComponent implements OnInit {

  onChange: any = () => { };
  onTouched: any = () => { };
  stars: number[] = [];

  @Input() startCount = 5
  @Input() readonly = false
  @Input() value

  private _rating: number;
  get rating(): number {
    return this._rating || 0;
  }
  set rating(rating: number) {
    this._rating = rating;
    this.onChange(rating);
    this.onTouched(rating);
  }

  get width() {
    const per = (this.rating * 100) / this.startCount;
    return `${per}%`;
  }

  constructor() {
    this.stars = Array(this.startCount)
  }

  ngOnInit(): void {
  
    if (this.value) {      
      this.rating = this.value;
    }
  }

  selectStar(index) {
    this.rating = index + 1;
  }

  writeValue(value: number) {
    this._rating = value
  }

  registerOnChange(fn: (rating: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

}
