import { AfterViewInit, Component, forwardRef, OnInit, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Driver } from '@app/models/driver.model';
import { DriverService } from '@app/shared/services/driver.service';
import { ListRequest } from '@app/models/core/base';

@UntilDestroy()
@Component({
  	standalone: false,
	selector: 'app-select-multiple-driver',
	templateUrl: './select-multiple-driver.component.html',
	styleUrls: ['./select-multiple-driver.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SelectMultipleDriverComponent),
			multi: true
		}
	],
	encapsulation: ViewEncapsulation.Emulated,
})
export class SelectMultipleDriverComponent implements AfterViewInit, OnInit {

	// default enabled dropdown for driver multiple select
	disabled: boolean = false;
	loading: boolean = false;

	onChange = (value: string) => { };
	onTouched = () => { };

	private _value: string | any;
	public get value(): string | any {
		return this._value;
	}
	public set value(updateValue: string | any) {
		this._value = updateValue;
		this.onChange(updateValue);
		this.onTouched();
	}

	public drivers: Driver[] = [];
	public searchTerm$: BehaviorSubject<string | null> = new BehaviorSubject(null);

	constructor(
		private readonly driverService: DriverService,
	) { }

	ngOnInit(): void {
		this.searchTerm$.next(null);
	}

	ngAfterViewInit() {
		this.searchTerm$
			.pipe(
				debounceTime(300),
				debounceTime(300), // Delay between API calls
				distinctUntilChanged(), // Only call API when the search term changes
				tap(() => this.getDrivers()),
				untilDestroyed(this)
			)
			.subscribe();
	}

	/**
	 * Custom search function
	 * 
	 * @param term 
	 * @param item 
	 * @returns 
	 */
	customSearchDriverFn(term: string, item: any): boolean {
		// Implement your custom search logic here
  		term = term.toLowerCase();

		const fieldsToSearch = ['fullName'];

		for (const field of fieldsToSearch) {
			const fieldValue = item[field]?.toLowerCase(); // Get the value of the current field

			if (fieldValue && fieldValue.includes(term)) {
				return true; // Return true if the field value contains the search term
			}
		}

		return false; // Return false if none of the fields match the search term
  	}

	/**
	 * On driver search in self multiple dropdown
	 * 
	 * @param item 
	 */
	onSearchDriver(item: any) {
		if (item) {
			this.searchTerm$.next(item?.term);
		}
	}

	/**
	 * Get drivers list API
	 * 
	 * @param filter 
	 */
	private async getDrivers(filter?: Partial<ListRequest>) {
		this.loading = true;

		// driver search term
		const searchTerm = this.searchTerm$.getValue() || null;

		// driver filter request with pagination & search term
		const request = {
			// ToDo make it pagination dynamic
			Page: 1, 
			ItemsPerPage: 20,
			...(searchTerm ? { SearchTerm: searchTerm } : {}),
			...filter,
		}

		try {
			this.drivers = [];
			this.driverService.cancelPendingRequestGetAll();
			const response = await this.driverService.getAll(request);
			this.drivers = response?.list;
		} catch (error) {
			console.error('Error while retrieving driver list!', error);
		} finally {
			this.loading = false;
		}
	}

	writeValue(value: string) {
		this._value = value
	}

	registerOnChange(fn: (value: string) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
