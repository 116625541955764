import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Customer } from '@app/models/customer.model';
import { CustomerContactService } from '@app/shared/services/customer-contact.service';
import { HelperService } from '@app/shared/services/helper.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { formatPhoneNumberAndExtension } from '@app/@shared/utils';
import { object, string } from 'yup';
import { patterns } from '@app/@shared/regex/regex-patterns.const';
@Component({
	selector: 'add-customer-contact-dialog',
	templateUrl: './add-customer-contact-dialog.component.html',
	styleUrls: ['./add-customer-contact-dialog.component.scss']
})
export class AddCustomerContactDialogComponent implements OnInit {
	private _customer: Customer;
	get customer(): Customer {
		return this._customer;
	}
	@Input() set customer(value: Customer) {
		this._customer = value;
	}

	contact = {
		contactName: '',
		phoneNumber: null,
		phoneExtension: null,
		email: ''
	}

	validationSchema = object().shape({
		phoneNumber: string().matches(patterns.phone, { excludeEmptyString: true, message: 'Phone must be a number' })
			.when('email', {
				is: (email) => !email,
				then: (schema) => schema.required('Either phone number or email is required').max(20),
				otherwise: (schema) => schema.nullable().max(20)
			}),
		contactName: string().label('Name'),
		phoneExtension: string().label('Ext').nullable().max(7),
		email: string().email().label('Email').nullable().max(256),
	});

	constructor(
		private readonly activeModal: NgbActiveModal,
		private readonly fb: FormBuilder,
		private readonly customerContactService: CustomerContactService,
		private readonly helperService: HelperService,
	) { }

	ngOnInit(): void { }

	/**
	 * 
	 */
	async onSubmit(form) {
		if (!form.isValid) {
			return;
		}
		try {

			const { contactName, phoneNumber, email, phoneExtension } = form.values;
			const phone = formatPhoneNumberAndExtension({ phoneNumber, phoneExtension })?.phone
			const excludeCustomerId = this.customer?.customerId
			form.errors = {};

			if (phoneNumber) {
				const phoneRes = await this.customerContactService.validateContact({phone, excludeCustomerId});
				if (phoneRes?.existedPhone) {
					const existedCustomerName = phoneRes?.existedProspect?.customerName?.toLowerCase();
					form.errors['phone'] = [`This phone number is already associated with ${existedCustomerName || 'a different customer'}.`]
				}
			}

			if(email) {
				const emailRes = await this.customerContactService.validateContact({email, excludeCustomerId});
				if (emailRes?.existedEmail) {
					const existedCustomerName = emailRes?.existedProspect?.customerName?.toLowerCase();
					form.errors['email'] = [`This email is already associated with "${existedCustomerName || 'a different customer'}".`]
				}
			}		

			if (Object.keys(form.errors).length > 0) {
				form.errors$.next(form.errors);
			} else {

				let firstName = null;
				let lastName = null;

				if (!contactName) {
					const number = this.customer?.customerContacts?.length || 1
					firstName = `Contact ${number}`;
					lastName = this.customer?.customerName;
					this.contact.contactName = `${firstName} ${lastName}`;
				} else {
					const chunks = contactName.split(/\s+/);
					const match = contactName.match(/(Contact \d+)\s*(.*)/);
					if (match) {
						firstName = match[1];
						lastName = match[2];
					} else {
						firstName = chunks.shift();
						lastName = chunks.join(' ');
					}
				}

				const contact = await this.customerContactService.create({
					customerId: this.customer.customerId,
					active: true,
					firstName: firstName,
					lastName: lastName,
					...phoneNumber && { phone },
					...email && { email }
				});

				this.activeModal.close(contact);
			}
		} catch (error) {
			console.log('Error while creating new customer contact', error);
			this.helperService.errorMessage(error);
		}
	}

	close() {
		this.activeModal.close();
	}
}
