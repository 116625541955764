import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  standalone: false,
  selector: 'app-drop-down-select',
  templateUrl: './drop-down-select.component.html',
  styleUrls: ['./drop-down-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropDownSelectComponent),
      multi: true
    }
  ]
})
export class DropDownSelectComponent implements ControlValueAccessor {
  selectedOptionsNames: any[] = [];

  @Input() bgColor: string;
  @Input() textColor: string;
  @Input() titleSize: 'body-2' | 'caption' | 'input' = 'caption';
  @Input() label: string;
  @Input() autoCloseDropDown: boolean | string = true;

  @Output() selectedOptions = new EventEmitter<any>();

  private _options: any[] = [];
  get options(): any[] {
    return this._options;
  }

  @Input() set options(values: any[]) {
    this._options = values || [];
    this.selectedOptionsNames = this._options.filter(item => item?.selected);
  }

  constructor() { }

  onChange: (value: any) => void = () => { };
  onTouched: () => void = () => { };

  optionSelectionUpdated() {
    const selectedOptions = this.options?.filter(r => r?.selected);
    this.selectedOptionsNames = selectedOptions;
    this.selectedOptions.emit(selectedOptions);
    this.onChange(selectedOptions);
  }

  writeValue(value: any[]): void {
    if (value) {
      this._options.forEach(option => {
        option.selected = value.some(val => val.name === option.name);
      });
      this.selectedOptionsNames = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}