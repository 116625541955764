import { DatePipe } from '@angular/common';
import { Inject, InjectionToken, LOCALE_ID, Optional, Pipe, PipeTransform } from '@angular/core';
import moment from "moment"

export const DATE_PIPE_DEFAULT_FORMAT = new InjectionToken<string>('DATE_PIPE_DEFAULT_FORMAT');

@Pipe({
  standalone: false, name: 'date', pure: true
})
export class CustomDatePipe extends DatePipe {
  constructor(
    @Inject(LOCALE_ID) locale: string,
    @Inject(DATE_PIPE_DEFAULT_FORMAT) @Optional() private defaultFormat?: string
  ) {
    super(locale)
  }

  transform(value: Date | string | number, format?: string, timezone?: string, locale?: string): string
    | null;
  transform(value: null | undefined, format?: string, timezone?: string, locale?: string): any;
  transform(
    value: Date | string | number | null | undefined, format?: string, timezone?: string,
    locale?: string): string | null;
  transform(
    value: Date | string | number | null | undefined, format, timezone?: string,
    locale?: string): string | null {

    return super.transform(value, format || this.defaultFormat || 'MM/dd/yyyy', timezone, locale);
  }

}


@Pipe({
  standalone: false, name: 'dateTime', pure: true
})
export class CustomDateTimePipe extends CustomDatePipe {
  constructor(
    @Inject(LOCALE_ID) locale: string,
  ) {
    super(locale, 'MM/dd/yyyy hh:mm a')
  }

}


@Pipe({
  standalone: false, name: 'time', pure: true
})
export class CustomTimePipe extends CustomDatePipe {

  constructor(
    @Inject(LOCALE_ID) locale: string,
  ) {
    super(locale, 'hh:mm a')
  }
}