import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IntegrationsRoutingModule } from './integrations-routing.module';
import { IntegrationsComponent } from './integrations.component';
import { SharedModule } from '@app/shared/shared.module';
import { DatatableModule } from '@app/@shared/datatable';
import { QuickbooksAuthorizationComponent } from '../quickbooks-authorization/quickbooks-authorization.component';
import { TelegramComponent } from './telegram/telegram.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { DialogsModule } from '@app/@shared/dialogs/dialogs.module';


@NgModule({
  declarations: [
    IntegrationsComponent,
    QuickbooksAuthorizationComponent,
    TelegramComponent,
  ],
  exports: [
    TelegramComponent
  ],
  imports: [
    CommonModule,
    IntegrationsRoutingModule,
    SharedModule,
    DatatableModule,
    NgbModalModule,
    DialogsModule
  ]
})
export class IntegrationsModule { }
