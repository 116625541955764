import { Component, Input, OnInit } from '@angular/core';
import { removeBinding } from '@app/@shared/utils';
import { CREDIT_CARD_TYPES, PAYMENT_TYPES } from '@app/data/payment-method';
import { stateOptions } from '@app/data/state';
import { PaymentMethod } from '@app/models/payment-method.model';
import { PaymentTypeEnum } from '@app/models/payment-type.model';
import { HelperService } from '@app/shared/services/helper.service';
import { TenantService } from '@app/shared/services/tenant.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { find } from 'lodash';
import { number, object, string } from 'yup';

declare let getTokens: any;

export interface PaymentMethodItem extends PaymentMethod {
  paymentTypeName?: string;
  expiryMonth?: string;
  expiryYear?: string;
  expiryDisplayDate?: string;
  paymentTypeLogo?: string;
}


@Component({
  standalone: false,
  selector: 'app-add-edit-payment-method-dialog',
  templateUrl: './add-edit-payment-method-dialog.component.html',
  styleUrls: ['./add-edit-payment-method-dialog.component.scss']
})


export class AddEditPaymentMethodDialogComponent implements OnInit {
  
  @Input() tenantId: any

  /*
	* Getter & Setter for Payment Method
	*/
	private _paymentMethod: PaymentMethodItem = {
    maskedNumber: null,
    address: {
      zip: null
    }
  };
	get paymentMethod(): PaymentMethodItem {
		return this._paymentMethod;
	}
	@Input() set paymentMethod(value: PaymentMethodItem) {
		this._paymentMethod = removeBinding(value);
	}

  states = stateOptions;

  validationSchema = object().shape({
    // maskedNumber: number().nullable().label("Card Number").required().max(16),
    //cvv: number().nullable().label("Cvv").required().max(4),
    expiryMonth: number().nullable().positive().min(1).max(12).required(),
    expiryYear: number().nullable().positive().min(23).max(99).required(),
    name: string().nullable().required(),
    address: string().nullable().required(),
    city: string().nullable().required(),
    state: string().nullable().required(),
    zipCode: string().nullable().required().matches(/^[0-9]/, { message: 'Zip code must be a number' }).max(6),
  });

  constructor(
    public activeModal: NgbActiveModal,
    public tenantService: TenantService,
    private helperService: HelperService,
  ) { }


  ngOnInit(): void {
    console.log(this.paymentMethod);

  }

  savePaymentMethod(f) {

    if (f.isValid) {

      getTokens(success => {
        const paymentMethodRequest = {
          ...this.paymentMethod,
          //cvv: '123',
          expDate:`${this.paymentMethod?.expiryMonth}/${this.paymentMethod?.expiryYear}`,
          tenantId: this.tenantId ?? null
          // maskedNumber: (document.getElementById('xCardNum') as HTMLInputElement).value,
          // cvv: (document.getElementById('xCVV') as HTMLInputElement).value,
        }

        console.log(paymentMethodRequest);

        if (paymentMethodRequest && paymentMethodRequest?.paymentMethodId) {
          this.tenantService.updateTenantPaymentMethods().then((res) => {
            this.helperService.successMessage("Payment Method Updated Successfully");
            this.activeModal.close(true);
          }).catch((error) => {
            this.helperService.errorMessage(error);
          })
        } else {

          this.tenantService.createTenantPaymentMethods(paymentMethodRequest).then((res) => {          
            this.helperService.successMessage("Payment Method Created Successfully");
            this.activeModal.close(true);
          }).catch((error) => {
            this.helperService.errorMessage(error);
          })

        }
      }, error => {
        console.log('error', error);
      }, 30000);
    }
  }

  close() {
    this.activeModal.close();
  }

}
