import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiPaginationResponse, ApiResponse } from '@app/models/core/base';
import { toParams } from 'src/utils/to-params';
import { Role } from '@app/models/user.model';
import { firstValueFrom } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class RolesService {

    private basePath = 'Roles'

    constructor(
        private http: HttpClient,
    ) {
    }

    getAll(request?: any) {
        return firstValueFrom(this.http.get<ApiPaginationResponse<Role>>(`${this.basePath}`, { params: toParams(request) }))
            .then(({ data }) => data);
    }

    get(id: number) {
        return firstValueFrom(this.http.get<ApiResponse<Role>>(`${this.basePath}/${id}`))
            .then(({ data }) => data);
    }

    update(id: number | string, request: any) {
        return firstValueFrom(this.http.put<ApiResponse<Role>>(`${this.basePath}/${id}`, request))
            .then(({ data }) => data);
    }

    create(request: any) {
        return firstValueFrom(this.http.post<ApiResponse<Role>>(`${this.basePath}`, request))
            .then(({ data }) => data);
    }

    delete(id: number | string) {
        return firstValueFrom(this.http.delete<ApiResponse<Role>>(`${this.basePath}/${id}`))
            .then(({ data }) => data);
    }

    updateRolePermissions(id: string, request = []) {
        return firstValueFrom(this.http.put<ApiResponse<Role>>(`${this.basePath}/UpdateRolePermissions/${id}`, request))
            .then(({ data }) => data);
    }

}
