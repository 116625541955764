import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';

@Component({
    standalone: false,
    selector: 'app-pickup-time',
    templateUrl: './pickup-time.component.html',
    styleUrls: ['./pickup-time.component.scss'],
})
export class PickupTimeComponent implements OnInit {
    public isOpened: boolean = false;
    public dayPart: string = 'PM';
    timePart: string;

    @Input() timeType: string;
    @Input() time: Date;
    @Input() showTypes: boolean = true;
    @Output() timeTypeUpdated = new EventEmitter<any>();
    @Output() timeUpdated = new EventEmitter<any>();

    constructor(private util: UtilService) {
    }

    ngOnInit() {

        if (this.time) {

            var hour = this.time.getHours();
            var minute = this.time.getMinutes();
            this.dayPart = (hour > 11) ? 'PM' : 'AM';

            if (hour > 12) {
                hour -= 12;
            } else if (hour == 0) {
                hour = 12;
            }

            this.timePart = hour + ':' + minute;
        }
    }

    setTimeType(type) {
        this.timeType = type;
        this.time = null;
        this.timePart = '';
        this.timeTypeUpdated.emit(this.timeType);
        this.timeUpdated.emit(null);
        this.isOpened = false;
    }

    setActive(part) {
        this.dayPart = part;
    }

    enterTime() {

        if (this.validTime(this.timePart)) {

            var spl = this.timePart.split(':');
            var hour = parseInt(spl[0]);
            var minute = parseInt(spl[1]);

            if (this.dayPart == 'PM' && hour == 12) {
                hour = hour;

            } else if (this.dayPart == 'AM' && hour == 12) {

                hour = 0;

            } else if (this.dayPart == 'PM') {

                hour += 12;
            }

            var today = new Date();
            today.setHours(hour);
            today.setMinutes(minute);
            this.time = today;

            this.timeType = 'Custom';
            this.timeUpdated.emit(this.time);
            this.timeTypeUpdated.emit('Custom');
            this.isOpened = false;

        } else {
            this.util.showError('Invlid Time');
        }

    }

    validTime(inputStr) {
        if (!inputStr || inputStr.length < 1) {
            return false;
        }
        var time = inputStr.split(':');
        return time.length === 2
            && parseInt(time[0], 10) >= 1
            && parseInt(time[0], 10) <= 12
            && parseInt(time[1], 10) >= 0
            && parseInt(time[1], 10) <= 59;
    }
}
