import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { LicenseTypeSelectComponent } from './license-type-select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        LicenseTypeSelectComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [
        LicenseTypeSelectComponent
    ]
})
export class LicenseTypeSelectModule { }
