import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Directive({
  standalone: false,
  selector: '[comingSoon]',
})
export class ComingSoonDirective extends NgbTooltip {

  @HostListener('click', ["$event"])
  onClick($event) {
    $event.stopPropagation();
    $event.preventDefault();
  }

  ngOnInit(): void {
    this.ngbTooltip = "Coming Soon"
    this.container = "body"
    super.ngOnInit()
  }

}
