import { BaseModel } from '@models/base.model';
import { ChargeType } from '@models/charge-type.model';
import { Driver } from './driver.model';

export enum LineType
{
    Item = 1,
    Charge = 2,
}

export interface InvoiceLine extends BaseModel {
    invoiceLineId?: number;
    driver?: Driver;
    driverId?: number;
    lineType?: LineType;
    isActive?: boolean;
    itemName?: string;
    itemType?: string;
    quantity?: number;
    measurement?: string;
    weight?: string;
    pickupAddress?: string;
    deliveryAddress?: string;
    chargeType?: ChargeType;
    charge?: string;
    amount?: number;
    isEdit?: boolean;
}
