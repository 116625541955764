import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TrimbleMapsService } from '../../../../services/trimble-maps.service';
import  TrimbleMaps from '@trimblemaps/trimblemaps-js';
import DirectionsResult = google.maps.DirectionsResult;

@Component({
    standalone: false,
    selector: 'app-route-trimble-map',
    templateUrl: './route-trimble-map.component.html',
    styleUrls: ['./route-trimble-map.component.scss']
})

export class RouteTrimbleMapComponent implements OnInit {
    @Input() directions: DirectionsResult
    @Input() mapStyle: string = "TRANSPORTATION";
    @Input() mapCenterLon: number = -74.13450816226678;
    @Input() mapCenterLat: number = 41.10702178173934;
    @Input() mapCenterZoom: number = 10;
    @ViewChild("map", {static: true}) elementRef: ElementRef;

    private route: TrimbleMaps.Route;
    private map: TrimbleMaps.Map;

    constructor(private trimbleMapsService: TrimbleMapsService) {}

    ngOnInit() {
        this.route = this.trimbleMapsService.getRoute(this.directions, 0);
        this.initMap();
        this.setMapOnLoadEvent();
    }

    private initMap() {
        const mapOptions: TrimbleMaps.MapOptions =  {
            container: this.elementRef.nativeElement,
            style: TrimbleMaps.Common.Style[this.mapStyle],
            center: [this.mapCenterLon, this.mapCenterLat],
            zoom: this.mapCenterZoom,
            hash: false
        };
        this.map = this.trimbleMapsService.initMap(mapOptions);
    }

    private setMapOnLoadEvent() {
        this.map.on("load", () => {
            this.route.addTo(this.map);
            this.route.frameRoute();
        });
    }
}
