import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { splitPhoneNumberAndExtension } from '@app/@shared/utils';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-phone-extension-input',
  templateUrl: './phone-extension-input.component.html',
  styleUrls: ['./phone-extension-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneExtensionInputComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.Emulated,
})
export class PhoneExtensionInputComponent implements OnInit {

  @Input() disabled: boolean;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  @Input() phoneLabel: string = 'Phone';
  @Input() extensionLabel: string = 'Extension';
  @Input() options: any[] = [];
  @Input() phonePlaceholder = 'Phone Number';
  @Input() extensionPlaceholder = 'Extension';



  onChange = (value: string) => { };
  onTouched = () => { };

  @Output() onSelect = new EventEmitter<any>();
  @Output() onChangeValue = new EventEmitter<any>();

  @Input() phone = '';
  @Output() phoneChange = new EventEmitter<any>();

  @Input() extension = '';
  @Output() extensionChange = new EventEmitter<any>();

  private _extension: string | any;
  @Input()
  get extensionValue(): string | any {
    return this._extension;
  }
  set extensionValue(updateExtension: string | any) {
    this._extension = updateExtension;
    this.extensionChange.emit(updateExtension)
    this.handleValueChange()
  }

  private _phoneNumber: string | any;
  @Input()
  get phoneNumber(): string | any {
    return this._phoneNumber;
  }
  set phoneNumber(updatePhoneNumber: string | any) {
    const { phone, extension } = splitPhoneNumberAndExtension(updatePhoneNumber)
    this._extension = extension || '';
    this.extensionChange.emit(this._extension)

    this._phoneNumber = phone;
    this.phoneChange.emit(phone)
    this.handleValueChange()
  }

  constructor() {

  }

  ngOnInit(): void {
  }

  handleValueChange() {
    let val = ''
    if (this.phoneNumber) {
      val += this.phoneNumber
    }
    if (this.extensionValue) {
      val += ` x ${this.extensionValue}`
    }

    if (typeof val == "string") {
      this.onChangeValue.emit(val);
    }
    this.onChange(val);
  }

  clearValue() {
    const value = null
    this.phoneNumber = value;
    this.extensionValue = value;
    this.onChange(value);
    this.onChangeValue.emit(value);
  }

  writeValue(value: string) {
    const { phone, extension } = splitPhoneNumberAndExtension(value)
    if (phone || extension) {
      this.phoneNumber = phone
      this.extensionValue = extension
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
