import { Component, OnInit, ViewChild } from '@angular/core';
import { Expense } from '@models/expense.model';
import { ExpenseService } from '@app/shared/services/expense.service';
import { HelperService } from '@app/shared/services/helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExpenseDetailsComponent } from '../expense-details/expense-details.component';
import { DatatableComponent } from '@app/@shared/datatable';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, omit } from 'lodash';
import { expensesData, expensesLinesData } from '@app/data/expenses';
import { Router } from '@angular/router';
import { Company } from '@app/models/company.model';
import { array, number, object, string } from 'yup';
import { removeBinding } from '@app/@shared/utils';
import { AuthService } from '@app/shared/services/auth/auth.service';
import { CompanyService } from '@app/shared/services/company.service';

@UntilDestroy()
@Component({
    selector: 'app-expense-list',
    templateUrl: './expense-list.component.html',
    styleUrls: ['./expense-list.component.scss'],
})
export class ExpenseListComponent implements OnInit {
    company: Company = {
        truckMonthlyExpenses: [],
        truckMilesPerMonth: null,
    }

    validationSchema = object().shape({
        truckMonthlyExpenses: array().nullable()
            .of(
                object().shape({
                    name: string().label('Name').nullable().required(),
                    price: number().label('Price').nullable().positive().required(),
                    type: string().label('Type').nullable().required(),
                })
            ),
        truckMilesPerMonth: number().nullable().positive(),
    });

    truckExpensesTypes: string
    searchDate: Date;
    title = "Expense";
    lineChart: any;
    @ViewChild('datatable') datatable: DatatableComponent
    activeTab = 'trucks';
    doughnutChart: any;
    expensesChartCounts = expensesData;
    expensesChartLines = expensesLinesData;
    expensesLabels = [
        {
            name: 'Truck',
            color: 'success',
        },
        {
            name: 'Drivers',
            color: 'yellow',
        },
        {
            name: 'Office',
            color: 'warning',
        },
        {
            name: 'Other',
            color: 'primary',
        },
    ]
    submitLoading: boolean;

    constructor(
        private expenseService: ExpenseService,
        private router: Router,
        private helperService: HelperService,
        private companyService: CompanyService,
        private authService: AuthService,
        private ngbModal: NgbModal,
    ) {
        this.doughnutChart = {
            data: {
                datasets: [{
                    data: map(this.expensesChartCounts, "count"),
                    backgroundColor: map(this.expensesChartCounts, "color"),
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                cutout: '84%',
                hover: { mode: null },
                plugins: { tooltip: { enabled: false} },
                elements: {
                    arc: { borderWidth: 0 },
                },
            },
        };

        this.lineChart = {
            data: {
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                datasets: this.expensesChartLines
            },
            options: {
                responsive: false,
                maintainAspectRatio: false,
                elements: {
                    point: {
                        radius: 0,
                    },
                    line: {
                        tension: 0.5,
                        fill: false,
                        borderWidth: 1
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            stepSize: 5000,
                            callback: function (value) {
                                var ranges = [
                                    { divider: 1e6, suffix: 'M' },
                                    { divider: 1e3, suffix: 'k' }
                                ];
                                function formatNumber(n) {
                                    for (var i = 0; i < ranges.length; i++) {
                                        if (n >= ranges[i].divider) {
                                            return (n / ranges[i].divider).toString() + ranges[i].suffix;
                                        }
                                    }
                                    return n;
                                }
                                return formatNumber(value);
                            }
                        }
                    }]
                }
            },
        }
    }

    getData = (request?: any) => {
        return this.expenseService.getAll(request);
    }

    ngOnInit() {
        this.authService.$user
            .pipe(untilDestroyed(this))
            .subscribe(async (data) => {
                this.company = await removeBinding(data?.company);

                if (!this.company?.truckMonthlyExpenses) {
                    this.company.truckMonthlyExpenses = []
                }

            })
    }

    onSubmit(f) {
        if (f?.isValid) {
            this.submitLoading = true;
            this.companyService.update(omit(this.company, 'companyId')).then((resp: any) => {
                this.company = resp;
                this.helperService.successMessage("Expense successfully updated");
                this.authService.user = {
                    ...this.authService.user,
                    company: this.company,
                }
                this.submitLoading = false;
            }).catch((error) => {
                this.helperService.errorMessage(error)
                this.submitLoading = false;
            });
        }
    }

    handleRowClick(row: Expense) {
        this.router.navigateByUrl('/app/settings/expenses/truck');
    }

    getFixedCostPerMile() {
        const sum = this.company?.truckMonthlyExpenses?.reduce((a, b) => a + b.price, 0) ?? 0;
        if (this.company?.truckMilesPerMonth != null) {
            return `\$${(sum / this.company?.truckMilesPerMonth).toFixed(2)}`;
        }
        return 'N/A';
    }

    openAddEdit(item?: Expense) {
        const ref = this.ngbModal.open(ExpenseDetailsComponent, {
            size: 'md'
        })
        ref.componentInstance.item = item

        ref.closed.pipe(untilDestroyed(this)).subscribe((isReload) => {
            if (isReload) {
                this.datatable.refresh()
            }
        })
    }
}
