import { Customer, SourceEnum } from '@models/customer.model';
import { OrderStop } from '@models//order-stop.model';
import { OrderItem } from '@models/order-item.model';
import { OrderTrip } from '@models/order-trip.model';
import { BaseModel, CompanyBase, TenantBase } from '@models/base.model';
import { Route } from '@models/route.model';
import { SentEmail } from '@models/sent-email.model';
import { CostLine } from '@models/cost-line.model';
import { ShipmentType } from '@models/shipment-type.model';
import { CustomerContact } from './customer-contact.model';
import { RouteItemCharges } from './charge-type.model';
import { Driver } from './driver.model';
import { Attachment } from './attachment.model';
import { Tag } from './tag.model';
import DirectionsResult = google.maps.DirectionsResult;
import { Invoice } from './invoice.model';
import { ViewedEntityTypeValueEnum } from './view-entity.modal';

export enum OrderStatusEnum {
    DRAFT = 'Dreft',
    ORDER = 'Order',
    QUOTE = 'Quote',
    DELETED = 'Deleted',
    CLOSED = 'Closed',
    UNASSIGNED = 'Unassigned',
    DISPATCHED = 'Dispatched',
    ON_ROUTE = 'On Route',
}

export enum MarkupTypeEnum {
    PERCENT = 'Percent',
    VALUE = 'Value',
}

export type OrderUpdateType = 'PickupDraft' | 'PickupDone' | 'DeliveryDraft' | 'DeliveryDone' | 'Pricing' | 'Recurrence' | 'OrderRouteBehavior';

export enum OrderUpdateTypeEnum {
    PickupDraft = 'PickupDraft',
    PickupDone = 'PickupDone',
    DeliveryDraft = 'DeliveryDraft',
    DeliveryDone = 'DeliveryDone',
    Pricing = 'Pricing',
    Recurrence = 'Recurrence',
    OrderRouteBehavior = 'OrderRouteBehavior',
}


export interface Order extends BaseModel, TenantBase, CompanyBase {
    isOrder?: boolean; //this is temporary field 
    isUpdateInvoice?: boolean; //this is temporary field 
    isDispatchRouteStopItem?: boolean; //this is temporary field 
    isApproveQuote?: boolean; //this is temporary field 
    isApprovedQuoteRedirectToOrder?: boolean; //this is temporary field 
    isCloseTab?: boolean; //this is temporary field 
    source?:SourceEnum;
    orderId?: number;
    parentOrderId?: number | null;
    parentOrder?: Order | null;
    childOrders?: Order[];
    setupStep?: string;
    invoice?: Invoice;
    paymentStatus?: string;
    dateType?: string;
    isQuoteSent?: boolean;
    isInvoiceSent?: boolean;
    isBillOfLandingSent?: boolean;
    orderNumber?: string;
    purchaseOrderNumber?: string;
    customerId?: number;
    customer?: Customer;
    billToInfo?: string;
    orderStatus?: OrderStatusEnum;
    date?: string;
    shipmentTypeId?: number;
    shipmentType?: ShipmentType;
    addresssString?: string;
    directionPolyline?: string;
    tripStatus?: OrderTripStatusEnum;
    directionsJson?: string;
    hasInvoice?: boolean;
    directions?: DirectionsResult;
    markupType?: MarkupTypeEnum;
    markupValue?: number;
    discountType?: string;
    discountValue?: number;
    discountDescription?: string;
    price?: number;
    newPriceInfo?: number;
    oldPriceInfo?: number;
    notes?: string;
    commonNotes?: string;
    pickupCount?: number;
    previousOrderId?: number;
    nextOrderId?: number;
    deliveryCount?: number;
    itemCount?: number;
    isRecurring?: boolean;
    isRecurringOrderCreated?: boolean;
    recursSaturday?: boolean;
    recursFriday?: boolean;
    recursThursday?: boolean;
    recursWednesday?: boolean;
    recursTuesday?: boolean;
    recursMonday?: boolean;
    recursSunday?: boolean;
    recursEveryMonth?: boolean;
    recursEveryOtherWeek?: boolean;
    miles?: number;
    minutes?: number;
    isCreditCardPaymentAuthorized?: boolean;
    creditCardAuthorizedAmount?: number;
    creditCardAuthorizationReferenceNumber?: string;
    userProfile?: string;
    type?: OrderTypeEnum;
    customerContactId?: number;
    customerContact?: CustomerContact;
    routeItemCharges?: RouteItemCharges[];
    orderStops?: OrderStop[];
    orderItems?: OrderItem[];
    attachments?: Attachment[];
    tags?: Tag[];
    recurringOrderDays?: RecurringOrderDay[];
    recurringOrderSkipDays?: RecurringOrderSkipDay[];
    costLines?: CostLine[];
    routes?: Route[];
    orderTrips?: OrderTrip[];
    trackingNumber?: string;
    useNewPricingModel?: boolean;
    isRedirectInvoice?: boolean;
    completeQuoteRouteItems?: boolean;
}

export interface RecurringOrderDay extends BaseModel, TenantBase, CompanyBase {
    recurringOrderDayId?: number;
    orderId?: number;
    order?: Order;
    date?: string;
}

export interface RecurringOrderSkipDay extends BaseModel, TenantBase, CompanyBase {
    recurringOrderSkipDayId?: number;
    orderId?: number;
    order?: Order;
    date?: string;
}

export interface OrderBasicDetail {
    customerName?: string;
    addressFrom?: string;
    addressTo?: string;
    orderForDriver?: string;
    balance?: number;
    orderId?: number;
    customerId?: number;
    date?: string;
    orderNumber?: string;
    price?: number;
    tripStatus?: string;
}
export interface OrderCounts {
    unassignedOrdersCount?: number;
    dispatchedOrdersCount?: number;
    pendingOrdersCount?: number;
    ordersCount?: number;
    packagesCount?: number;
    tripsCount?: number;
    onRouteCount?: number;
    completeCount?: number;
    unpaidOrders?: number;
    draftsCount?: number;
    quotesCount?: number;
}


export interface OrderListFilters {
    SearchTerm?: string;
    OrderStatus?: OrderStatusEnum[];
    OrderPaymentStatus?: OrderPaymentStatus[];
    GroupBy?: string;
    paymentStatus?: string;
    attention?: boolean;
    Customer?: Customer;
    Driver?: Driver;
    Date?: {
        start: Date;
        end: Date;
    };
    orderType?: 'all' | 'port';
    orderPortStatus?: OrderPortStatusEnum[];
    selectedOrderStatus?: [];
    // New fields for filters
    TripStatus?: OrderTripStatusEnum[];
    Customers?: [];
    Drivers?: [];
    FromAddress?: [],
    ToAddress?: [],
    Sources?: SourceEnum[],
    hasInvoice?: boolean;
    viewedEntityType?: ViewedEntityTypeValueEnum;
}

export interface CreateInvoiceInput {
    isReplace?: boolean
}

export enum OrderPortStatusEnum {
    FLIGHT_DEPARTED = 'Flight Departed',
    FLIGHT_ARRIVED = 'Flight Arrived',
    DOC_READY = 'DOC Ready',
    FREIGHT_READY = 'Freight Ready',
    PROCESSED = 'Processed',
    PICKED_UP = 'Picked Up',
}

export enum OrderTripStatusEnum {
    OPEN = 'Open',
    ASSIGNED = 'Assigned',
    INTRANSIT = 'InTransit',
    DELIVERED = 'Delivered',
}

export enum OrderPriorityEnum {
    LOWEST = 'Lowest',
    LOW = 'Low',
    MIDDLE = 'Middle',
    HIGH = 'High',
    HIGHEST = 'Highest'
}

export enum OrderTypeEnum {
    DEFAULT = 0,
    FULL_ROUTE = 1,
}

export enum OrderPaymentStatus {
    NotPaidAndCompleted = 1,
    NotPaidAndAssigned = 2,
    NotPaidAndUnassigned = 3,
    Paid = 4,
    PartlyPaid = 5,
}